import { insertInArray } from '../utils/insert';

class TimeZone {

    get(): string {
        const dateArray = new Date().toString().split(' ')
        console.log('date', new Date())
        const a = insertInArray(dateArray[5].split(''), 0, '(')
        const b = insertInArray(a, 4, ' ')
        const c = insertInArray(b, 8, ':')
        const zone = insertInArray(c, 12, ')').join('')

        return zone;
    }
}

const TimeZoneService = new TimeZone();

export default TimeZoneService;
