import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';

import CorefyWithdraw from '../../../../../../service/model/payment/CorefyWithdraw';
import { OnlyLetterService } from '../../../../../../service/Validator/OnlyLetter';
import { PaymentMethod } from '../../../PaymentMethod/types';
import WithdrawService from '../../service/Withdrawal';
import CorefyWithdrawService, { CorefyWithdrawState } from './CorefyWithdraw';
import BonusService from '../../../../../../service/Bonus';
import StatusFormPopupService, { StatusForm } from '../../../Deposit/DepositPopup/service/StatusForm';

export enum CorefyInputTip {
    NONE,
    NAME,
    SURNAME,
    NON_EMPTY,
}

class CardForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(0, 1);

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.withdraw.input.amount',
    });

    card = new InputService({
        title: 'profile.balance.withdraw.input.card',
        validator: Validator.maskCardNumber,
        validatorText: 'profile.balance.withdraw.input.card',
    });

    name = new InputService({
        title: 'popup.payment.name.account',
        validator: Validator.OnlyLetter,
    });

    surname = new InputService({
        title: 'popup.payment.surname.account',
        validator: Validator.OnlyLetter,
    });

    handler = {
        onAmount: this.onAmount.bind(this),
        onCard: this.onCard.bind(this),
        onName: this.onName.bind(this),
        onNameActive: this.onNameActive.bind(this),
        onSurname: this.onSurname.bind(this),
        onSurnameActive: this.onSurnameActive.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.card.value.subscribe(this.handler.onCard);
        this.name.value.subscribe(this.handler.onName);
        this.name.active.subscribe(this.handler.onNameActive);
        this.surname.value.subscribe(this.handler.onSurname);
        this.surname.active.subscribe(this.handler.onSurnameActive);
    }

    valid = new BehaviorSubject<boolean>(false);

    tip = new BehaviorSubject<CorefyInputTip>(CorefyInputTip.NONE);

    error = new BehaviorSubject<string>('');

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        const limit = this.paymentMethod.limit.withdraw;
        const isFee = WithdrawService.fee.getValue();
        const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
        this.limitValidator.setLimit(isFee ? isMin : limit.min, limit.max);
    }

    onAmount(value: string) {
        const isValue = this.amount.value.getValue();
        if (this.paymentMethod && isValue) {
            if (!value.indexOf('0', 0)) {
                const current = value.length;
                const done = value.slice(0, current - 1);
                this.amount.value.next(done);
            }
            const isFee = WithdrawService.fee.getValue();
            const isMin = Math.round(this.paymentMethod.limit.withdraw.min * 1.25);

            if (isFee && +isValue < isMin) {
                this.amount.error.next('profile.balance.withdraw.input.amount');
                this.error.next('popup.payment.min.fee')
            } else {
                this.amount.error.next(null);
                this.error.next('');
                this.checkValid();
            }
        } else {
            this.amount.error.next(null);
            this.error.next('');
            this.checkValid();
        }
    }

    onName(value: string) {
        this.checkValid();
        if (!OnlyLetterService.validate(value) && this.name.getValue()) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.name.value.next(name);
        } else {
            this.name.error.next(null);
        }
    }

    onNameActive(active: boolean) {
        if (active) {
            this.tip.next(CorefyInputTip.NAME);
        } else {
            this.tip.next(CorefyInputTip.NONE);
        }
    }

    onSurname(value: string) {
        this.checkValid();
        if (!OnlyLetterService.validate(value) && this.surname.getValue()) {
            const current = value.length;
            const surname = value.slice(0, current - 1);
            this.surname.value.next(surname);
        } else {
            this.surname.error.next(null);
        }
    }

    onSurnameActive(active: boolean) {
        if (active) {
            this.tip.next(CorefyInputTip.SURNAME);
        } else {
            this.tip.next(CorefyInputTip.NONE);
        }
    }

    onCard(value: string) {
        if (value && value.length <= 0) {
            this.valid.next(false);
        } else {
            this.checkValid();
        }
    }

    checkValid() {
        const valid = this.name.isValid() && this.surname.isValid() && this.card.isValid() && this.amount.isValid();
        this.valid.next(valid);
    }

    send() {
        const valid = this.valid.getValue();

        if (valid && this.paymentMethod) {
            const amount = parseInt(this.amount.getValue());
            const card = this.card.getValue().split(' ').join('');
            const request = new CorefyWithdraw(
                this.paymentMethod,
                this.surname.getValue(),
                this.name.getValue(),
                card,
                amount
            );
            PaymentService.corefy.withdraw(request);
            CorefyWithdrawService.state.next(CorefyWithdrawState.AWAIT);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const status = CorefyWithdrawService.state.getValue();
        const isBonuses = BonusService.list.getValue().length;
        const fee = WithdrawService.fee.getValue();
        console.log('SUBMIT fee', fee);

        if (isBonuses) {
            CorefyWithdrawService.state.next(CorefyWithdrawState.BONUS_REJECT);
            StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
        } else {
            if (!!fee) {
                if (status === CorefyWithdrawState.HAS_FEE) {
                    this.send();
                }
            } else {
                this.send();
            }
        }
    }

    reset() {
        this.amount.value.next('');
        this.name.value.next('');
        this.surname.value.next('');
        this.card.value.next('');

        this.amount.error.next(null);
        this.name.error.next(null);
        this.surname.error.next(null);
        this.card.error.next(null);

        this.amount.active.next(false);
        this.name.active.next(false);
        this.surname.active.next(false);
        this.card.active.next(false);

        this.valid.next(false);
        this.tip.next(CorefyInputTip.NONE);
        this.error.next('');
    }
}

const CardFormService = new CardForm();

export default CardFormService;
