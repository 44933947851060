import { PaykassmaPayment } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaPaymentCheck';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import { translate as t } from 'service/Language';

import InputService from '../../../../../../component/Input/service/Input';
import AuthService from '../../../../../../service/Auth';
import GoogleService from '../../../../../../service/Google';
import { BkashTransactionService } from '../../../../../../service/Validator/BkashTransaction';

import { NagadTransactionService } from '../../../../../../service/Validator/NagadTransaction';

import { RocketTransactionService } from '../../../../../../service/Validator/RocketTransaction';
import { PaymentMethod } from '../../../PaymentMethod/types';

import Validator from '../../../../../../service/Validator/Validator';

import AmountFormService from './AmountForm';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';

import WellxPayDepositService from './WellxpayDeposit';
import {InvoiceState} from '../../Paykassma/service/PaykassmaDeposit';
import {WellxPayDepositStepTwo} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendWellxPayDepositStepTwo';


enum TransactionError {
    NOERROR,
    AMOUNT,
    SYMBOL,
    NUMBER,
    NAN,
    LETTER,
}

class TransactionFrom {
    limitValidator = new Validator.limit(1, 1000000);

    amount = new InputService({
        title: 'profile.balance.deposit.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.deposit.input.amount',
    });

    transaction = new InputService({
        title: 'profile.balance.deposit.input.number.transaction',
        validator: Validator.phonePeTransaction,
        validatorText: 'profile.balance.deposit.input.number.transaction',
    });

    valid = new BehaviorSubject<boolean>(false);

    errorTip = new BehaviorSubject<TransactionError>(TransactionError.NOERROR);

    paymentMethod: PaymentMethod | null = null;

    handler = {
        onChange: this.onChange.bind(this),
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
        this.transaction.value.subscribe(this.handler.onChange);
    }

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        this.setLimit(paymentMethod.limit.deposit.min, paymentMethod.limit.deposit.max);
    }

    goBack() {
        WellxPayDepositService.reset();
    }

    reset() {
        this.amount.value.next('');
        this.transaction.value.next('');
        this.transaction.error.next(null);
        this.errorTip.next(TransactionError.NOERROR);
        this.valid.next(false);
    }

    setLimit(min: number, max: number) {
        if (this.limitValidator) {
            this.limitValidator.setLimit(min, max);
        }
    }

    onAmount(value: string) {
        if (!this.limitValidator.validate(value) && this.amount.getValue()) {
            this.amount.error.next(t('profile.balance.deposit.input.amount'));
            this.valid.next(false);
        } else {
            this.amount.error.next(null);
        }
    }

    onAmountActive(active: boolean) {
        if (active) {
            this.errorTip.next(TransactionError.AMOUNT);
        } else {
            this.errorTip.next(TransactionError.NOERROR);
        }
    }

    onChange(transaction: string) {
        if (transaction) {
            this.errorTip.next(TransactionError.NOERROR);
            if (transaction.length > 23) {
                this.deleteUsedValue(transaction);
            }
            if (this.paymentMethod?.id === ChannelType.WELLXPAY_H2H_BKASH) {
                this.validationBkash(transaction);
            }
            if (this.paymentMethod?.id === ChannelType.WELLXPAY_H2H_NAGAD) {
                this.validationNagad(transaction);
            }
            if (this.paymentMethod?.id === ChannelType.WELLXPAY_H2H_ROCKET) {
                this.validationRocket(transaction);
            }
            if (this.paymentMethod?.id === ChannelType.WELLXPAY_H2H_UPAY) {
                this.validationUpay(transaction);
            }
        } else {
            this.errorTip.next(TransactionError.NOERROR);
            this.transaction.defaultTitle.valueOf();
        }
    }

    validationBkash(transaction: string) {
        if (!BkashTransactionService.symbolCheck(transaction)) {
            this.deleteUsedValue(transaction);
            this.errorTip.next(TransactionError.SYMBOL);
        }
        if (!BkashTransactionService.lengthCheck(transaction)) {
            this.transaction.error.next(t('profile.balance.deposit.input.validation.length'));
        }
        if (BkashTransactionService.validate(transaction)) {
            this.transaction.error.next(null);
            this.errorTip.next(TransactionError.NOERROR);
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    validationNagad(transaction: string) {
        if (!NagadTransactionService.symbolCheck(transaction)) {
            this.deleteUsedValue(transaction);
            this.errorTip.next(TransactionError.SYMBOL);
        }
        if (!NagadTransactionService.lengthCheck(transaction)) {
            this.transaction.error.next(t('profile.balance.deposit.input.validation.length.nagad'));
        }
        if (NagadTransactionService.validate(transaction)) {
            this.transaction.error.next(null);
            this.errorTip.next(TransactionError.NOERROR);
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    validationRocket(transaction: string) {
        if (!RocketTransactionService.numberCheck(transaction)) {
            this.deleteUsedValue(transaction);
            this.errorTip.next(TransactionError.NAN);
        }
        if (!RocketTransactionService.symbolCheck(transaction)) {
            this.deleteUsedValue(transaction);
            this.errorTip.next(TransactionError.SYMBOL);
        }
        if (!RocketTransactionService.lengthCheck(transaction)) {
            this.transaction.error.next(t('profile.balance.deposit.input.validation.length'));
        }
        if (RocketTransactionService.validate(transaction)) {
            this.transaction.error.next(null);
            this.errorTip.next(TransactionError.NOERROR);
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    validationUpay(transaction: string) {
        if (!NagadTransactionService.symbolCheck(transaction)) {
            this.deleteUsedValue(transaction);
            this.errorTip.next(TransactionError.SYMBOL);
        }
        if (!NagadTransactionService.lengthCheck(transaction)) {
            this.transaction.error.next(t('profile.balance.deposit.input.validation.length.nagad'));
        }
        if (NagadTransactionService.validate(transaction)) {
            this.transaction.error.next(null);
            this.errorTip.next(TransactionError.NOERROR);
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    deleteUsedValue(transaction: string) {
        const current = transaction.length;
        const value = transaction.slice(0, current - 1);
        this.transaction.value.next(value);
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();
        if (this.paymentMethod) {
            let amount;
           const isTransactionStatus = WellxPayDepositService.transaction.getValue() === InvoiceState.TRANSACTION
            if (isTransactionStatus) {
                amount = this.amount.getValue();
            } else {
                amount = AmountFormService.amount.getValue();
            }

            const payment: WellxPayDepositStepTwo = {
                currencyId: this.paymentMethod.currency.ISO,
                channelType: this.paymentMethod.id,
                transactionId: this.transaction.getValue().trim(),
            };

            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            WellxPayDepositService.depositStepTwo(payment);

            GoogleService.sentTransactionId(
                userId,
                this.paymentMethod.title,
                WellxPayDepositService.invoice.id,
                amount,
                this.paymentMethod.currency.name,
                payment.transactionId,
            );
            this.valid.next(false);
        }
    }
}

const TransactionFromService = new TransactionFrom();
export { TransactionError };
export default TransactionFromService;
