import Input, { InputSize } from 'component/Input/Input';
import RxComponent from 'component/RxComponent/RxComponent';
import { translate as t } from 'service/Language';
import { formatCurrencySign } from 'utils/format';
import MaskedInput from 'component/Input/MaskedInput';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import WithdrawalButton from '../../WithdrawalButton/WithdrawalButton';
import AttentionIcon from 'assets/svg/attention.svg';
import { createRef } from 'react';
import WithdrawService from '../../../service/Withdrawal';
import CashixFormService, { AmountError, CashixInputTip } from '../../service/CashixForm';
import CashixWithdrawService, { CashixWithdrawState } from '../../service/CashixWithdraw';
import InfoIcon from 'assets/svg/info.svg';

import './Phone.scss';

type PhoneProps = {
    paymentMethod: PaymentMethod;
};
type PhoneState = {
    value: string;
    amountLimit: AmountError,
    tip: CashixInputTip;
    completed: boolean,
    errorAmount: string | null,
    errorPhone: string | null,
};

class Phone extends RxComponent<PhoneProps, PhoneState> {
    state = {
        value: '',
        tip: CashixInputTip.NONE,
        amountLimit: AmountError.NONE,
        completed: false,
        errorAmount: null,
        errorPhone: null,
    };

    handler = {
        onTip: this.onTip.bind(this),
        onErrorAmount: this.onErrorAmount.bind(this),
        getValue: this.getValue.bind(this),
        onPhoneError: this.onPhoneError.bind(this),
        onAutoCompleted: this.onAutoCompleted.bind(this),
        onAmountLimit: this.onAmountLimit.bind(this),
        clickOutside: this.clickOutside.bind(this),
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    close() {
    }

    clickOutside(event: PointerEvent) {
        if (this.ref.self.current && !this.ref.self.current.contains(event.target as Node)) {
            this.close();
        }
    }
    componentWillUnmount() {
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }
    onErrorAmount(errorAmount: string | null) {
        this.setState({errorAmount});
    }

    onPhoneError(errorPhone: string | null) {
        this.setState({ errorPhone });
    }

    onAmountLimit(amountLimit: AmountError) {
        this.setState({amountLimit});
    }

    onTip(tip: CashixInputTip) {
        this.setState({ tip });
    }

    onAutoCompleted(completed: boolean) {
        this.setState({ completed });
    }

    getValue(value: string) {
        this.setState({ value });
    }

    componentDidMount() {
        const {paymentMethod} = this.props;

        CashixFormService.setPaymentMethod(paymentMethod);
        this.subscribe(CashixFormService.amount.value, {next: this.handler.getValue});
        this.subscribe(CashixFormService.amount.error, {next: this.handler.onErrorAmount});
        this.subscribe(CashixFormService.phone.error, {next: this.handler.onPhoneError});
        this.subscribe(CashixFormService.tip, {next: this.handler.onTip});
        this.subscribe(CashixFormService.amountLimit, {next: this.handler.onAmountLimit});
        document.addEventListener('pointerdown', this.handler.clickOutside);
    }

    render() {
        const { paymentMethod } = this.props;
        const { amountLimit, value, tip, errorAmount,  errorPhone } = this.state;

        const status = CashixWithdrawService.state.getValue();

        const min = formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency);
        const max = formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency);

        const hint = `${t('popup.payment.amount.min')} ${min} — ${t('popup.payment.amount.max')} ${max}`;

        const fee = WithdrawService.fee.getValue();

        const isInit = status === CashixWithdrawState.INIT;
        if (fee && isInit) {
            CashixWithdrawService.state.next(CashixWithdrawState.HAS_FEE)
        }
        const minFee = Math.ceil(paymentMethod.limit.withdraw.min * 1.25)
        const amountFee = formatCurrencySign(minFee, 0, paymentMethod.currency);
        const allFeeText = `${amountFee} ${t('popup.payment.min.fee')}`;

        return (
            <div className="cashix-withdrawal">
                <div className="cashix-withdrawal__header">
                    <img src={paymentMethod.icon} alt={paymentMethod.slug}/>
                    <h2 className="cashix-withdrawal__header_title">{t(paymentMethod.title)}</h2>
                </div>
                <form className="cashix-withdrawal-form" onSubmit={(e) => CashixFormService.onSubmit(e)}>
                    <div className="cashix-withdrawal-form-block">
                        <Input
                            className="cashix-withdrawal-form-block__input"
                            inputService={CashixFormService.amount}
                            name="amount"
                            type="number"
                        />
                        <span className="cashix-withdrawal-form-block__currency">{paymentMethod.currency.sign}</span>
                        {!!errorAmount ? (
                            <div className="cashix-withdrawal-form-block__error">
                                <img src={AttentionIcon} alt="attention"/>
                                <span className="cashix-withdrawal-form-block__error_text">
                                    {amountLimit && amountLimit === AmountError.AMOUNT_MIN ? t('popup.payment.amount.min') + ` ${min}` : null}
                                    {fee && amountLimit === AmountError.AMOUNT_FEE ? allFeeText : null}
                                    {amountLimit && amountLimit === AmountError.AMOUNT_MAX ? t('popup.payment.amount.max') + ` ${max}` : null}
                                    {amountLimit && amountLimit === AmountError.AMOUNT_WITHDRAWAL ? t('popup.payment.amount.withdrawal.max') : null}
                                    </span>
                            </div>
                        ) : null}
                        {tip === CashixInputTip.AMOUNT && !errorAmount ? (
                            <span className="cashix-withdrawal-form-block__tip">
                                {hint}
                            </span>
                        ) : null}
                    </div>
                    <div className="cashix-withdrawal-form-block">
                        <MaskedInput
                            className="cashix-withdrawal-form-block__input"
                            inputService={CashixFormService.phone}
                            name="tel"
                            mask="+88 \00000000000"
                            placeholder="+88 00000000000"
                            size={InputSize.medium}
                        />
                        {errorPhone ? (
                            <div className="cashix-withdrawal-form-block__error">
                                <img src={AttentionIcon} alt="attention"/>
                               <span className="cashix-withdrawal-form-block__error_text" >{t('popup.payment.phone.error')}</span>
                            </div>
                        ) : null}
                    </div>
                    <div className="Info">
                        <img className="Info__img" src={InfoIcon} alt="info" />
                        <span className="Info__text">{t('popup.withdrawal.note.info')}</span>
                    </div>
                    {((status === CashixWithdrawState.HAS_FEE || status === CashixWithdrawState.AWAIT) && fee ) ?
                        <FeeNotice amount={value} fee={fee} /> : null}
                    {status === CashixWithdrawState.HAS_FEE ? (
                        <>
                            <WithdrawalButton
                                buttonTitle="profile.balance.withdraw.fee.agree"
                                variation={true}
                                paymentMethod={paymentMethod}
                                amount={value}
                            />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel"/>
                        </>
                    ) : (
                        <WithdrawalButton
                            buttonTitle="profile.balance.withdraw.button"
                            paymentMethod={paymentMethod}
                        />
                    )}
                </form>
            </div>
        );
    }
}

export default Phone;
