import { translate as t } from 'service/Language';
import './QRcode.scss';

type QRcodeProps = {
    link: string;
};

function QRcode(props: QRcodeProps) {
    const { link } = props;

    return (
        <div className="qrcode">
            <div className="qrcode__title">{t("profile.balance.deposit.qr.title")}
            </div>
            {!!link && (
                <img
                    src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(link)}`}
                    alt="qrcode"
                    className="qrcode__img"
                />
            )}
        </div>
    );
}

export default QRcode;
