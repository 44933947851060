import { ListType } from 'enums/payment';
import AuthService from 'service/Auth';
import GoogleService from 'service/Google';
import { translate as t } from 'service/Language';
import PaymentService from '../../../../service/Payment';
import Currency from '../../../../service/model/Currency';
import WithdrawService from '../Withdrawal/service/Withdrawal';
import DepositService from '../Deposit/service/Deposit';
import { PaymentMethod } from './types';

import './PaymentMethod.scss';


type PaymentMethodItemProps = {
    paymentMethod: PaymentMethod;
    type: ListType;
    userCurrency: Currency;
};

function CriptoPaymentMethod(props: PaymentMethodItemProps) {
    const { paymentMethod, type, userCurrency } = props;

    const handlePickMethod = () => {
        const clientInfo = AuthService.auth.getValue();
        if (clientInfo) {
            if (type === ListType.DEPOSIT) {
                GoogleService.pickDepositMethod(clientInfo.userId.toString(), paymentMethod.title);
            } else {
                GoogleService.pickWithdrawMethod(clientInfo.userId.toString(), paymentMethod.title);
            }
        }
        PaymentService.coinspaid.requestRateMin({
            currencyId: userCurrency.ISO,
            channelType: paymentMethod.id,
        });

        if (type === ListType.DEPOSIT) {
            DepositService.pickMethod({
                ...paymentMethod,
                currency: userCurrency,
            });

            PaymentService.coinspaid.deposit({
                currencyId: userCurrency.ISO,
                channelType: paymentMethod.id,
                amount: 0,
            });
        } else {
            WithdrawService.pickMethod({
                ...paymentMethod,
                currency: userCurrency,
            });
        }
    };

    return (
        <button onClick={handlePickMethod} className="payment-method-item">
            <img className="payment-method-item__icon" alt="payIcon" src={paymentMethod.icon} />
            <span className="payment-method-item__title">{t(paymentMethod.title)}</span>
        </button>
    );
}

export default CriptoPaymentMethod;
