/* eslint-disable max-len */

import { Icon } from 'types';

function UploadDocuments({ color = '#3959D9', width = 40, height = 32 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.7044 8.71637C39.8726 8.86544 39.9777 9.07066 39.999 9.29167C40.0003 9.31944 40.0003 9.34724 39.999 9.37501V24.8889C39.9971 25.1239 39.9 25.3486 39.7291 25.5139L33.3656 31.7361C33.1966 31.9032 32.9668 31.9981 32.7265 32H8.67875C8.43766 32 8.20643 31.9063 8.03594 31.7396C7.86546 31.5729 7.76969 31.3469 7.76966 31.1111L7.76966 23.5139H0.909088C0.667992 23.5139 0.436762 23.4202 0.266279 23.2535C0.0957959 23.0868 2.4084e-05 22.8607 0 22.625L9.66219e-07 0.888896C2.50712e-05 0.653151 0.0957969 0.427069 0.26628 0.260374C0.436763 0.0936799 0.667993 2.46224e-05 0.909089 6.96619e-06L31.3202 8.27335e-06C31.5473 -0.000948934 31.7664 0.081185 31.9347 0.230257C32.1029 0.379329 32.208 0.584551 32.2293 0.805564C32.2306 0.833326 32.2306 0.861134 32.2293 0.888897V8.48612L39.0899 8.48612C39.317 8.48516 39.5361 8.5673 39.7044 8.71637ZM38.1809 23.5556V10.2639H32.2293L32.2293 16.4028C32.2274 16.6378 32.1303 16.8625 31.9594 17.0278L25.596 23.25C25.4269 23.4171 25.1971 23.512 24.9568 23.5139H9.58779V30.2222H31.3629L31.3629 24.4444C31.3629 24.2087 31.4587 23.9826 31.6291 23.8159C31.7996 23.6492 32.0308 23.5556 32.2719 23.5556H38.1809ZM33.181 29.4167L37.357 25.3333H33.181L33.181 29.4167ZM30.4112 15.2222V1.77779L1.81813 1.77778L1.81812 21.7361L23.7636 21.7361V16.1111C23.7636 15.8754 23.8594 15.6493 24.0299 15.4826C24.2004 15.3159 24.4316 15.2223 24.6727 15.2222L30.4112 15.2222ZM25.5817 20.7639L29.4311 17H25.5817V20.7639Z"
                fill="#3F4250"
            />
            <path
                d="M21.1693 11.1903C21.1675 11.0735 21.1421 10.9583 21.0947 10.8512C21.0473 10.744 20.9787 10.647 20.893 10.5658C20.8073 10.4845 20.706 10.4206 20.595 10.3776C20.484 10.3346 20.3654 10.3134 20.246 10.3153H15.7859V5.95416C15.7876 5.83637 15.7654 5.71943 15.7204 5.61012C15.6755 5.50082 15.6088 5.40133 15.5242 5.31745C15.4396 5.23356 15.3388 5.16695 15.2277 5.12149C15.1166 5.07603 14.9973 5.05261 14.8768 5.05261C14.7563 5.05261 14.6371 5.07602 14.5259 5.12149C14.4148 5.16695 14.314 5.23356 14.2294 5.31745C14.1448 5.40133 14.0781 5.50082 14.0332 5.61012C13.9883 5.71943 13.966 5.83637 13.9677 5.95416V10.3153H9.49343C9.37297 10.3136 9.25337 10.3354 9.14159 10.3793C9.0298 10.4232 8.92808 10.4884 8.8423 10.5711C8.75651 10.6538 8.68837 10.7524 8.64188 10.8611C8.59538 10.9697 8.57141 11.0864 8.57141 11.2042C8.57141 11.322 8.59538 11.4386 8.64188 11.5473C8.68837 11.6559 8.75651 11.7545 8.8423 11.8372C8.92808 11.9199 9.0298 11.9851 9.14159 12.029C9.25337 12.073 9.37297 12.0947 9.49343 12.093H13.9677V16.4542C13.966 16.5719 13.9883 16.6889 14.0332 16.7982C14.0781 16.9075 14.1448 17.007 14.2294 17.0909C14.314 17.1747 14.4148 17.2414 14.5259 17.2868C14.6371 17.3323 14.7563 17.3557 14.8768 17.3557C14.9973 17.3557 15.1166 17.3323 15.2277 17.2868C15.3388 17.2414 15.4396 17.1747 15.5242 17.0909C15.6088 17.007 15.6755 16.9075 15.7204 16.7982C15.7654 16.6889 15.7876 16.5719 15.7859 16.4542V12.093H20.246C20.3678 12.0949 20.4887 12.0728 20.6016 12.0281C20.7145 11.9834 20.817 11.917 20.9031 11.8328C20.9892 11.7486 21.0572 11.6483 21.1029 11.5379C21.1486 11.4276 21.1712 11.3093 21.1693 11.1903Z"
                fill={color}
            />
            <path
                d="M21.1693 11.1903C21.1675 11.0735 21.1421 10.9583 21.0947 10.8512C21.0473 10.744 20.9787 10.647 20.893 10.5658C20.8073 10.4845 20.706 10.4206 20.595 10.3776C20.484 10.3346 20.3654 10.3134 20.246 10.3153H15.7859V5.95416C15.7876 5.83637 15.7654 5.71943 15.7204 5.61012C15.6755 5.50082 15.6088 5.40133 15.5242 5.31745C15.4396 5.23356 15.3388 5.16695 15.2277 5.12149C15.1166 5.07603 14.9973 5.05261 14.8768 5.05261C14.7563 5.05261 14.6371 5.07602 14.5259 5.12149C14.4148 5.16695 14.314 5.23356 14.2294 5.31745C14.1448 5.40133 14.0781 5.50082 14.0332 5.61012C13.9883 5.71943 13.966 5.83637 13.9677 5.95416V10.3153H9.49343C9.37297 10.3136 9.25337 10.3354 9.14159 10.3793C9.0298 10.4232 8.92808 10.4884 8.8423 10.5711C8.75651 10.6538 8.68837 10.7524 8.64188 10.8611C8.59538 10.9697 8.57141 11.0864 8.57141 11.2042C8.57141 11.322 8.59538 11.4386 8.64188 11.5473C8.68837 11.6559 8.75651 11.7545 8.8423 11.8372C8.92808 11.9199 9.0298 11.9851 9.14159 12.029C9.25337 12.073 9.37297 12.0947 9.49343 12.093H13.9677V16.4542C13.966 16.5719 13.9883 16.6889 14.0332 16.7982C14.0781 16.9075 14.1448 17.007 14.2294 17.0909C14.314 17.1747 14.4148 17.2414 14.5259 17.2868C14.6371 17.3323 14.7563 17.3557 14.8768 17.3557C14.9973 17.3557 15.1166 17.3323 15.2277 17.2868C15.3388 17.2414 15.4396 17.1747 15.5242 17.0909C15.6088 17.007 15.6755 16.9075 15.7204 16.7982C15.7654 16.6889 15.7876 16.5719 15.7859 16.4542V12.093H20.246C20.3678 12.0949 20.4887 12.0728 20.6016 12.0281C20.7145 11.9834 20.817 11.917 20.9031 11.8328C20.9892 11.7486 21.0572 11.6483 21.1029 11.5379C21.1486 11.4276 21.1712 11.3093 21.1693 11.1903Z"
                fill={color}
            />
        </svg>
    );
}

export default UploadDocuments;
