import { NavLink } from 'react-router-dom';

import { translate as t } from '../../service/Language';
import Illustration from './images/Illustration.svg';

import './LostConnection.scss';

function LostConnection() {
    return (
        <div className="connection">
            <img className="connection__logo" src={Illustration} />
            <div className="connection__title">{t('connection.lost')}</div>
            <div className="connection__description">{t('connection.description')}</div>
            <button className="connection__button" onClick={() => window.location.reload()}>
                {t('connection.button.reload')}
            </button>
        </div>
    );
}

export default LostConnection;
