import Validator from '../interface/Validator';

class JazzcashTransaction implements Validator {
    validate(value: string): boolean {
        return this.symbolCheck(value) && this.lengthCheck(value);
    }

    numberCheck(value: string): boolean {
        return new RegExp('^[Z0-9]+$').test(value);
    }

    symbolCheck(value: string): boolean {
        return new RegExp('^[A-Za-z0-9]+$').test(value);
    }

    lengthCheck(value: string): boolean {
        return value.length === 12;
    }
}

const JazzcashTransactionService = new JazzcashTransaction();

export { JazzcashTransactionService };
export default JazzcashTransaction;
