/* eslint-disable max-len */

import { Icon } from 'types';

function MessageIcon({ color = '#3F4250', width = 100, height = 100 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="15" fill="#F3F3F6" />
            <path
                opacity="0.5"
                d="M36 36H72C76.4183 36 80 39.5817 80 44V68.7346C80 71.8546 77.4708 74.3838 74.3508 74.3838C73.8884 74.3838 73.5508 74.8208 73.6675 75.2682L76.0012 84.2176C76.1299 84.7109 75.5242 85.0578 75.1638 84.6973L65.1473 74.6769C64.9597 74.4892 64.7053 74.3838 64.44 74.3838H36C31.5817 74.3838 28 70.8021 28 66.3838V44C28 39.5817 31.5817 36 36 36Z"
                fill="url(#paint0_linear_1:173)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29 30H65C69.4183 30 73 33.5817 73 38V60.3838C73 64.8021 69.4183 68.3838 65 68.3838H36.56C36.2947 68.3838 36.0403 68.4892 35.8527 68.6769L25.8362 78.6973C25.4758 79.0578 24.8701 78.7109 24.9988 78.2176L27.3325 69.2682C27.4492 68.8208 27.1116 68.3838 26.6492 68.3838C23.5292 68.3838 21 65.8546 21 62.7346V38C21 33.5817 24.5817 30 29 30ZM33 53C35.2091 53 37 51.2091 37 49C37 46.7909 35.2091 45 33 45C30.7909 45 29 46.7909 29 49C29 51.2091 30.7909 53 33 53ZM51 49C51 51.2091 49.2091 53 47 53C44.7909 53 43 51.2091 43 49C43 46.7909 44.7909 45 47 45C49.2091 45 51 46.7909 51 49ZM61 53C63.2091 53 65 51.2091 65 49C65 46.7909 63.2091 45 61 45C58.7909 45 57 46.7909 57 49C57 51.2091 58.7909 53 61 53Z"
                fill="url(#paint1_linear_1:173)"
            />
            <path
                opacity="0.5"
                d="M36 36H72C76.4183 36 80 39.5817 80 44V68.7346C80 71.8546 77.4708 74.3838 74.3508 74.3838C73.8884 74.3838 73.5508 74.8208 73.6675 75.2682L76.0012 84.2176C76.1299 84.7109 75.5242 85.0578 75.1638 84.6973L65.1473 74.6769C64.9597 74.4892 64.7053 74.3838 64.44 74.3838H36C31.5817 74.3838 28 70.8021 28 66.3838V44C28 39.5817 31.5817 36 36 36Z"
                fill="url(#paint2_linear_1:173)"
            />
            <path
                d="M65 30H29C24.5817 30 21 33.5817 21 38V62.7346C21 65.8546 23.5292 68.3838 26.6492 68.3838C27.1116 68.3838 27.4492 68.8208 27.3325 69.2682L24.9988 78.2176C24.8701 78.7109 25.4758 79.0578 25.8362 78.6973L35.8527 68.6769C36.0403 68.4892 36.2947 68.3838 36.56 68.3838H65C69.4183 68.3838 73 64.8021 73 60.3838V38C73 33.5817 69.4183 30 65 30Z"
                fill="url(#paint3_linear_1:173)"
            />
            <circle cx="33" cy="49" r="4" fill="#F3F3F6" />
            <circle cx="47" cy="49" r="4" fill="#F3F3F6" />
            <circle cx="61" cy="49" r="4" fill="#F3F3F6" />
            <defs>
                <linearGradient
                    id="paint0_linear_1:173"
                    x1="78.8182"
                    y1="46.9756"
                    x2="35.1824"
                    y2="57.806"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1:173"
                    x1="22.1818"
                    y1="40.7223"
                    x2="65.6969"
                    y2="51.7779"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1:173"
                    x1="78.8182"
                    y1="46.9756"
                    x2="35.1824"
                    y2="57.806"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_1:173"
                    x1="22.1818"
                    y1="40.7223"
                    x2="65.6969"
                    y2="51.7779"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default MessageIcon;
