import { toast, ToastContainer, ToastPosition, TypeOptions } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './Notification.scss';

type PropsType = {
    type?: TypeOptions;
    message?: string;
};

type ToastConfig = {
    pauseOnHover?: boolean;
    position?: ToastPosition | undefined;
    autoClose: number;
};

const Notification = ({ type = 'default', message }: PropsType) => {
    const config: ToastConfig = {
        pauseOnHover: true,
        position: 'bottom-center',
        autoClose: 1000,
    };
    if (type === 'default') {
        toast(message, config);
    } else if (type === 'error') {
        toast.error(message, config);
    } else if (type === 'success') {
        toast.success(message, config);
    } else if (type === 'warning') {
        toast.warn(message, config);
    }
    return <ToastContainer className="Toastify" />;
};

export default Notification;
