import { formatCurrencySign } from 'utils/format';

import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { translate as t } from '../../../../../../../service/Language';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import AmountFormService, { PaykassmaInputTip } from '../../service/AmountForm';
import PaykassmaDepositService, { DepositState } from '../../service/PaykassmaDeposit';
import Submit from '../Button/Submit';
import SumSelector from '../SummSelector/SumSelector';
import DisabledAmount from './DisabledAmount/DisabledAmount';

import './Amount.scss';

type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {
    errorTip: PaykassmaInputTip;
};

class Amount extends RxComponent<AmountProps, AmountState> {
    state = {
        errorTip: PaykassmaInputTip.NONE,
    };

    handler = {
        onErrorTip: this.onErrorTip.bind(this),
    };

    componentDidMount() {
        const { paymentMethod } = this.props;

        AmountFormService.reset();
        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setValue(paymentMethod.defaultValue);
        this.subscribe(AmountFormService.errorTip, { next: this.handler.onErrorTip });
    }

    onErrorTip(errorTip: PaykassmaInputTip) {
        this.setState({ errorTip });
    }

    render() {
        const { paymentMethod, lock } = this.props;
        const { errorTip } = this.state;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        const amount = PaykassmaDepositService.state.getValue() === DepositState.AMOUNT;

        return (
            <div className="deposit-mpesa">
                <form className="deposit-mpesa__form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    {amount ? (
                        <div className="deposit-mpesa-container">
                            <MaskedInput
                                mask="00000000000"
                                inputService={AmountFormService.amount}
                                className="deposit-mpesa-container__input"
                                name="amount"
                                hint={hint}
                                enabled={!lock}
                            />
                            <span className="deposit-mpesa-container__currency">{paymentMethod.currency.sign}</span>
                        </div>
                    ) : (
                        <DisabledAmount hint={hint} />
                    )}
                    <div className="deposit-mpesa__sum">
                        <SumSelector paymentMethod={paymentMethod} disabled={lock} />
                    </div>
                    <MaskedInput
                        className="phone-withdrawal__account"
                        inputService={AmountFormService.phone}
                        name="phone-number"
                        mask="+254 000000000"
                        type="tel"
                        placeholder="+254 000000000"
                    />
                    {errorTip === PaykassmaInputTip.PHONE_MPESA && (
                        <div className="deposit-mpesa__tip">
                            <div>{t('popup.payment.mpesa.phone')}</div>
                        </div>
                    )}
                    <Submit />
                </form>
            </div>
        );
    }
}

export default Amount;
