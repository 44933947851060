import { BehaviorSubject } from 'rxjs';

import AmountFormService from './AmountForm';
import { PaymentMethod } from '../../../PaymentMethod/types';
import PaymentService from '../../../../../../service/Payment';
import StatusFormPopupService, { StatusForm } from '../../DepositPopup/service/StatusForm';


enum DepositState {
    AMOUNT,
    WAIT,
    LINK,
    FAILED,
    SUCCESS,
}

class PayesDeposit {
    amount = 0;

    link = new BehaviorSubject('');

    state = new BehaviorSubject<DepositState>(DepositState.AMOUNT);

    request(paymentMethod: PaymentMethod, name: string,  surname: string, amount: number) {
        this.state.next(DepositState.WAIT);
        PaymentService.payes.deposit({
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
            name,
            surname,
            amount,
        });
    }

    setAmount(amount: number) {
        this.amount = amount;
    }

    getAmount(): number {
        return this.amount;
    }

    reset() {
        this.amount = 0;
        this.link.next('');
        this.state.next(DepositState.AMOUNT);
        AmountFormService.reset();
    }

    onError() {
        this.state.next(DepositState.FAILED);
        StatusFormPopupService.status.next(StatusForm.FAILED);
    }

    onReceive(link: string) {
        const state = this.state.getValue();
        if (state === DepositState.WAIT) {
            const currentArray = link.split('#');
            this.link.next(currentArray[0]);
            window.open(this.link.getValue(), '_self')
            StatusFormPopupService.status.next(StatusForm.SUCCESS);
        }
    }

    back() {
        this.reset();
    }
}

const PayesDepositService = new PayesDeposit();

export { DepositState };
export default PayesDepositService;
