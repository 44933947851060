import Chatwoot from '../../Header/Intercom/Chatwoot';
import logo from './image/logo.svg';

import './Header.scss';

function Header() {
    return (
        <div className='ban-header'>
            <div className='ban-header__logo'>
                <img src={logo} alt='' />
            </div>
            <Chatwoot />
        </div>
    );
}

export default Header;
