import {
    Direction,
    Operation,
    PaykassmaCheckResponse,
    Status, WellxPayDepositTwoResponse,
    WellxPayInvoiceResponse
} from '@ay_tsarbet/newbet-core/dist/types';
import { BehaviorSubject, map, Subject } from 'rxjs';

import History from './model/History';
import WSBet from './WSBet';
import {SystemTypes} from '../enums/payment';

const MAX_DEPOSIT_TRANSACTIONS = 10;
const MAX_WITHDRAW_TRANSACTIONS = 25;

export enum MethodType {
    NONE,
    GENERAL,
    CRYPTO,
    BOTH,
}

class Operations {
    allList = new BehaviorSubject<History[]>([]);

    list = new BehaviorSubject<History[]>([]);

    allDeposite = new BehaviorSubject<History[]>([]);

    isSuccessDep = new BehaviorSubject<MethodType>(MethodType.NONE);

    invoice = new Subject<PaykassmaCheckResponse | WellxPayDepositTwoResponse>();

    haveSuccessTransaction = this.list.pipe(map(Operations.checkOneSuccessTransaction));

    static checkOneSuccessTransaction(list: History[]): boolean {
        const listSuccessTransaction = list.filter(
            (item) => item.type === Direction.REFILL && item.status === Status.SUCCESS
        );
        return !!listSuccessTransaction.length;
    }

    requestHistory() {
        WSBet.operations.request();
    }

    onReceive(operations: Operation[]) {
        let countOfDeposit = 0;
        let countOfWithdraw = 0;
        const mappedOperations = operations
            .sort((a, b) => a.creationDate - b.creationDate)
            .reduceRight((prev: History[], curr: Operation): History[] => {
                if (curr.direction === Direction.REFILL) {
                    if (countOfDeposit < MAX_DEPOSIT_TRANSACTIONS) {
                        const operation = History.fromObject(curr);
                        prev.push(operation);
                        countOfDeposit++;
                    }
                }
                if (curr.direction === Direction.WITHDRAW) {
                    if (countOfWithdraw < MAX_WITHDRAW_TRANSACTIONS) {
                        const operation = History.fromObject(curr);
                        prev.push(operation);
                        countOfWithdraw++;
                    }
                }
                return prev;
            }, []);
        this.list.next(mappedOperations);

        const mappedAllOperations = operations
            .sort((a, b) => a.creationDate - b.creationDate)
            .reduceRight((prev: History[], curr: Operation): History[] => {
                if (curr.direction === Direction.REFILL) {
                        const operation = History.fromObject(curr);
                        prev.push(operation);
                        countOfDeposit++;
                }
                if (curr.direction === Direction.WITHDRAW) {
                        const operation = History.fromObject(curr);
                        prev.push(operation);
                        countOfWithdraw++;
                }
                return prev;
            }, []);
        this.allList.next(mappedAllOperations);

        let countDepositList = 0;
        const mappedDepositList = operations.reduce((prev: History[], curr: Operation): History[] => {
            if (curr.direction === Direction.REFILL) {
                if (countDepositList < operations.length) {
                    const operation = History.fromObject(curr);
                    prev.push(operation);
                    countDepositList++;
                }
            }
            return prev;
        }, []);
        this.allDeposite.next(mappedDepositList);
    }

    onInvoiceData(data: PaykassmaCheckResponse) {
        this.invoice.next(data);
    }
    onInvoiceDataWellx(data: WellxPayDepositTwoResponse) {
        this.invoice.next(data);
    }

    sendGAClientRelation(clientId: string, invoiceId: string) {
        WSBet.operations.sendGAClientId(clientId, invoiceId);
    }

    getSuccessDep() {
        const listSuccessTransaction = this.allDeposite.getValue().filter(
            (item) => item.type === Direction.REFILL && item.status === Status.SUCCESS
        );
        if(listSuccessTransaction.length) {
            const isCripto = listSuccessTransaction.filter(item => item.paymentMethod?.system === SystemTypes.COINSPAID).length
            const isGeneral = listSuccessTransaction.filter(item => item.paymentMethod?.system !== SystemTypes.COINSPAID).length
            if(isCripto) {
                this.isSuccessDep.next( MethodType.CRYPTO)
            }
            if(isGeneral) {
                this.isSuccessDep.next( MethodType.GENERAL)
            }
            if(isCripto && isGeneral) {
                this.isSuccessDep.next( MethodType.BOTH)
            }
        } else {
            this.isSuccessDep.next(MethodType.NONE)
        }
    }
}

const OperationsService = new Operations();

export default OperationsService;
