/* eslint-disable max-len */

import { Icon } from 'types';

function ConnectedIcon({ color = '#3959D9', width = 8, height = 10 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <mask id="path-1-inside-1" fill="white">
                <path d="M6 1C6 0.447716 6.44771 0 7 0C7.55228 0 8 0.447715 8 1V9C8 9.55228 7.55228 10 7 10C6.44771 10 6 9.55229 6 9V1ZM3 4C3 3.44772 3.44772 3 4 3C4.55228 3 5 3.44772 5 4V9C5 9.55229 4.55228 10 4 10C3.44772 10 3 9.55228 3 9V4ZM2 7C2 6.44772 1.55228 6 1 6C0.447715 6 0 6.44772 0 7V9C0 9.55229 0.447715 10 1 10C1.55228 10 2 9.55229 2 9V7Z" />
            </mask>
            <path
                d="M6 1C6 0.447716 6.44771 0 7 0C7.55228 0 8 0.447715 8 1V9C8 9.55228 7.55228 10 7 10C6.44771 10 6 9.55229 6 9V1ZM3 4C3 3.44772 3.44772 3 4 3C4.55228 3 5 3.44772 5 4V9C5 9.55229 4.55228 10 4 10C3.44772 10 3 9.55228 3 9V4ZM2 7C2 6.44772 1.55228 6 1 6C0.447715 6 0 6.44772 0 7V9C0 9.55229 0.447715 10 1 10C1.55228 10 2 9.55229 2 9V7Z"
                fill={color}
            />
            <path
                d="M7 1V9H9V1H7ZM7 9V1H5V9H7ZM4 4V9H6V4H4ZM4 9V4H2V9H4ZM-1 7V9H1V7H-1ZM3 9V7H1V9H3ZM1 11C2.10457 11 3 10.1046 3 9H1V11ZM-1 9C-1 10.1046 -0.104569 11 1 11V9H-1ZM1 5C-0.104569 5 -1 5.89543 -1 7H1V5ZM1 7H3C3 5.89543 2.10457 5 1 5V7ZM4 9H2C2 10.1046 2.89543 11 4 11V9ZM4 9V11C5.10457 11 6 10.1046 6 9H4ZM4 4H6C6 2.89543 5.10457 2 4 2V4ZM4 2C2.89543 2 2 2.89543 2 4H4V2ZM7 9H7H5C5 10.1046 5.89543 11 7 11V9ZM7 9H7V11C8.10457 11 9 10.1046 9 9H7ZM7 1L7 1H9C9 -0.104569 8.10457 -1 7 -1V1ZM7 -1C5.89543 -1 5 -0.104568 5 1H7L7 1V-1Z"
                fill={color}
                mask="url(#path-1-inside-1)"
            />
        </svg>
    );
}

export default ConnectedIcon;
