import FakeJackpotService from '../../../service/FakeJackpot';
import BDT from '../images/BDT.svg';

import './CarouselUser.scss';

function CarouselUser() {
    const winnerList = FakeJackpotService.jackpot.getValue().winnerList;
    return (
        <div className="fake-user">
            {winnerList.map((winner) => (
                <div className="fake-user__block" key={winner.toString()}>
                    <div className="fake-user__name">{`${winner.name} `}</div>
                    <div className="fake-user__amount-block">
                        <div className="fake-user__amount">{winner.amount}</div>
                        <div className="fake-user__currency-block">
                            <img className="fake-user__currency" src={BDT} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default CarouselUser;
