import { RecoveryPasswordType } from '@ay_tsarbet/newbet-core/dist/connection/actions/actionEnums';
import { RecoverPasswordData } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendRecoverPassword';

import RecoveryInterface from '../interface/Recovery';

interface RecoveryProviderInterface {
    sendOtp: (phone: string) => void;
    recoveryPassword: (data: RecoverPasswordData) => void;
    recoveryOtp: (code: string, type: RecoveryPasswordType) => void;
    sendNewPassword: (newPassword: string) => void;
}

class RecoveryProvider implements RecoveryInterface {
    handler: RecoveryProviderInterface;

    constructor(handler: RecoveryProviderInterface) {
        this.handler = handler;
    }

    byPhone(phone: string) {
        const data = {
            type: RecoveryPasswordType.PHONE_URL,
            value: phone,
        };
        this.handler.recoveryPassword(data);
    }

    byEmail(email: string) {
        const data = {
            type: RecoveryPasswordType.EMAIL,
            value: email,
        };
        this.handler.recoveryPassword(data);
    }

    sendPhoneToken(code: string) {
        this.handler.recoveryOtp(code, RecoveryPasswordType.PHONE_URL);
    }

    sendEmailToken(code: string) {
        this.handler.recoveryOtp(code, RecoveryPasswordType.EMAIL);
    }

    setPassword(password: string) {
        this.handler.sendNewPassword(password);
    }
}

export default RecoveryProvider;
