import { translate as t } from 'service/Language';

import Email from './SupportItem/images/Email.svg';
import Phone from './SupportItem/images/Phone.svg';
import SupportItem from './SupportItem/SupportItem';
import { SupportItemModel } from './SupportItem/types';

import './Support.scss';

function Support() {
    const supportItemArray: Array<SupportItemModel> = [
        {
            id: 1,
            image: Email,
            title: 'info.support.email.title',
            description: 'footer.email',
            notice: '',
        },
        {
            id: 1,
            image: Phone,
            title: 'info.support.phone.title',
            description: 'info.support.phone.description',
            notice: 'info.support.phone.notice',
        },
    ];

    const renderSupportItem = (supportItem: SupportItemModel) => (
        <SupportItem supportItem={supportItem} key={supportItem.id} />
    );

    return (
        <div className="support">
            <div className="support__list">{supportItemArray.map(renderSupportItem)}</div>
        </div>
    );
}

export default Support;
