import Directa24Interface from '../../interface/payment/Directa24';
import { Directa24DepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendDirecta24DepositRequest';
import { Directa24WithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendDirecta24Withdrawal';

interface Directa24Handler {
    deposit(request: Directa24DepositRequest): void;

    withdraw(request: Directa24WithdrawalRequest): void;
}

class Directa24Provider implements Directa24Interface {
    handler: Directa24Handler;

    constructor(handler: Directa24Handler) {
        this.handler = handler;
    }

    deposit(request: Directa24DepositRequest): void {
        this.handler.deposit(request);
    }

    withdraw(request: Directa24WithdrawalRequest): void {
        this.handler.withdraw(request);
    }
}

export type { Directa24Handler };
export default Directa24Provider;
