import Jackpot from '../ItemBlock/image/jackpot.svg';
import Money from '../ItemBlock/image/money.svg';
import Payment from '../ItemBlock/image/payment.svg';
import Phone from '../ItemBlock/image/phone.svg';
import Star from '../ItemBlock/image/star.svg';
import TopGame from '../ItemBlock/image/topGame.svg';

type BlockType = {
    id: number;
    logo: string;
    title: string;
};

class BlockAviator {
    AVIATOR_LIST_LEFT: BlockType[] = [
        { id: 1, logo: Payment, title: 'wheel.aviator.block.title.payment' },
        { id: 2, logo: Jackpot, title: 'wheel.aviator.block.title.jackpot' },
        { id: 3, logo: Money, title: 'wheel.aviator.block.title.payouts' },
    ];

    AVIATOR_LIST_RIGHT: BlockType[] = [
        { id: 1, logo: TopGame, title: 'wheel.aviator.block.title.games' },
        { id: 2, logo: Phone, title: 'wheel.aviator.block.title.support' },
        { id: 3, logo: Star, title: 'wheel.aviator.block.title.license' },
    ];
}

const BlockAviatorService = new BlockAviator();
export type { BlockType };
export default BlockAviatorService;
