import classNames from 'classnames';
import { translate as t } from 'service/Language';
import Spinner from 'svg/spinner.svg';
import useObservable from '../../../../../../utils/useObservable';
import AmountFormService from '../service/AmountForm';
import ConstantposDepositService, {DepositState} from '../service/ConstantposDeposit';

import './Submit.scss';


function Submit() {
    const valid = useObservable<boolean>(AmountFormService.valid, false);
    const status = useObservable(ConstantposDepositService.state, ConstantposDepositService.state.getValue());

    const _className = classNames({
        'deposit-submit': true,
        'deposit-submit__active': valid,
    });

    const isAwait = status === DepositState.WAIT

    return (
        <button type="submit" className={_className} disabled={!valid || isAwait}>
            { isAwait ? (
                <img className="deposit-submit__wait" src={Spinner} alt="" />
            ) : (
                t('profile.balance.deposit.button.next')
            )}
        </button>
    );
}

export default Submit;
