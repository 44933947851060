/* eslint-disable max-len */

import { Icon } from 'types';

function FireLoupe({ color = '#9FA0A8', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8618 4.04063C10.5223 4.04063 8.27857 4.97 6.62428 6.62428C4.97 8.27857 4.04063 10.5223 4.04063 12.8618C4.04063 15.2013 4.97 17.445 6.62428 19.0993C8.27857 20.7535 10.5223 21.6829 12.8618 21.6829C15.1925 21.6829 17.4281 20.7605 19.0806 19.1179C19.0882 19.1097 19.0959 19.1016 19.1039 19.0937C19.1112 19.0864 19.1186 19.0793 19.1261 19.0723C20.7636 17.4206 21.6829 15.1886 21.6829 12.8618C21.6829 10.5223 20.7535 8.27857 19.0993 6.62428C17.445 4.97 15.2013 4.04063 12.8618 4.04063ZM5.41703 5.41703C7.39149 3.44256 10.0694 2.33331 12.8618 2.33331C15.6541 2.33331 18.332 3.44256 20.3065 5.41703C22.281 7.39149 23.3902 10.0694 23.3902 12.8618C23.3902 15.3662 22.4979 17.7786 20.8894 19.674L25.4172 24.2099C25.7502 24.5436 25.7497 25.0841 25.4161 25.4172C25.0824 25.7502 24.5419 25.7497 24.2088 25.4161L19.6828 20.8819C17.786 22.4952 15.37 23.3902 12.8618 23.3902C10.0694 23.3902 7.39149 22.281 5.41703 20.3065C3.44256 18.332 2.33331 15.6541 2.33331 12.8618C2.33331 10.0694 3.44256 7.39149 5.41703 5.41703Z"
                fill={color}
            />
            <path
                d="M18.1712 12.1691C17.8494 11.4853 17.3817 10.8717 16.7973 10.3664L16.3151 9.94865C16.2987 9.93485 16.279 9.92506 16.2577 9.92013C16.2364 9.91521 16.2141 9.9153 16.1928 9.9204C16.1716 9.92549 16.152 9.93544 16.1357 9.94937C16.1195 9.9633 16.1071 9.98078 16.0997 10.0003L15.8842 10.5839C15.75 10.9501 15.5031 11.3241 15.1534 11.6918C15.1302 11.7153 15.1037 11.7215 15.0855 11.7231C15.0672 11.7247 15.0391 11.7215 15.0142 11.6996C14.991 11.6808 14.9794 11.6527 14.9811 11.6245C15.0424 10.6825 14.7441 9.62004 14.0911 8.46368C13.5509 7.50291 12.8002 6.75338 11.8622 6.23075L11.1778 5.85051C11.0883 5.80043 10.974 5.86615 10.9789 5.96474L11.0154 6.71583C11.0402 7.22907 10.9773 7.68286 10.8281 8.05997C10.6458 8.52157 10.384 8.95032 10.0492 9.33525C9.81628 9.60277 9.55223 9.84474 9.26207 10.0566C8.56324 10.5639 7.995 11.2138 7.5999 11.9578C7.20578 12.7083 7.00048 13.5346 7 14.3723C7 15.1108 7.15412 15.8259 7.45904 16.5003C7.75347 17.1497 8.17836 17.739 8.71023 18.2357C9.24716 18.7364 9.87026 19.1307 10.5646 19.4046C11.2839 19.6894 12.0462 19.8333 12.8333 19.8333C13.6205 19.8333 14.3828 19.6894 15.102 19.4061C15.7947 19.1339 16.4245 18.7369 16.9564 18.2372C17.4934 17.7365 17.9143 17.1513 18.2076 16.5019C18.5121 15.8293 18.6683 15.1053 18.6667 14.3738C18.6667 13.6102 18.5009 12.8685 18.1712 12.1691Z"
                fill={color}
            />
        </svg>
    );
}

export default FireLoupe;
