/* eslint-disable max-len */

import { Icon } from 'types';

function ActiveSearch({ color = '#2EBA4D', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
            <path
                d="M20.1887 9.55771C20.6159 9.98495 20.6159 10.6775 20.1887 11.1046L12.8511 18.4423C12.4239 18.8693 11.7315 18.8693 11.3043 18.4423L7.81134 14.9491C7.38409 14.5221 7.38409 13.8295 7.81134 13.4025C8.23837 12.9753 8.93094 12.9753 9.35797 13.4025L11.9362 15.9807C12.0143 16.0588 12.1409 16.0588 12.219 15.9807L18.6418 9.55771C19.0691 9.13068 19.7616 9.13068 20.1887 9.55771ZM28 14C28 21.7385 21.7374 28 14 28C6.26151 28 0 21.7374 0 14C0 6.26151 6.26257 0 14 0C21.7385 0 28 6.26257 28 14ZM25.8125 14C25.8125 7.47061 20.5285 2.1875 14 2.1875C7.47061 2.1875 2.1875 7.47147 2.1875 14C2.1875 20.5294 7.47147 25.8125 14 25.8125C20.5294 25.8125 25.8125 20.5285 25.8125 14Z"
                fill={color}
            />
        </svg>
    );
}

export default ActiveSearch;
