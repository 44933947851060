import classNames from 'classnames';
import SearchResult from 'component/SearchResult/SearchResult';
import { VFC } from 'react';
import GamesService, { MIN_SEARCH_QUERY_LENGTH } from 'service/Games';
import useObservable from 'utils/useObservable';

import { gameCategoryOrder } from '../../../../../../service/model/GameCategory';
import SideLink from './SideLink/SideLink';

import './SideLinkList.scss';

interface SideLinkListProps {
    active?: boolean;
}

const SideLinkList: VFC<SideLinkListProps> = ({ active }) => {
    const searchText = useObservable(GamesService.searchText, GamesService.searchText.getValue());
    return (
        <div className="side-link-list">
            <div
                onClick={() => GamesService.resetProviderFilter(null)}
                className={classNames('side-link-list__container', {
                    'side-link-list__container_inactive': !active,
                })}
            >
                {gameCategoryOrder.map((category) => (
                    <SideLink category={category} key={category.id} />
                ))}
            </div>
            {searchText.length >= MIN_SEARCH_QUERY_LENGTH && (
                <div className="side-link-list__search-result">
                    <SearchResult />
                </div>
            )}
        </div>
    );
};

export default SideLinkList;
