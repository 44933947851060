import Currency, { currencyList } from 'service/model/Currency';

import RxComponent from '../../../../../component/RxComponent/RxComponent';
import Select, { SelectSize } from '../../../../../component/Select/Select';
import { OptionInterface } from '../../../../../component/Select/Select.type';
import GeoDataService from '../../../../../service/GeoData';
import WheelRegistrationFormService from '../service/registration';
import CurrencyOption from './CurrencyOption';
import classNames from 'classnames';

import './CurrencyList.scss';

type SelectProps = {
    type?: string;
};

type SelectState = {
    currency: Currency | null;
};

class CurrencyList extends RxComponent<SelectProps, SelectState> {
    handler = {
        setCurrency: this.setCurrency.bind(this),
    };

    state: SelectState = {
        currency: null,
    };

    setCurrency(currency: Currency | null) {
        this.setState({ currency });
    }

    componentDidMount() {
        GeoDataService.requestGeoData();
        this.subscribe(WheelRegistrationFormService.currency, { next: this.handler.setCurrency });
    }

    render() {
        const { currency } = this.state;
        const { type } = this.props;

        const options = new Map(
            currencyList.map(currency => [currency, new CurrencyOption(currency)]),
        );
        const onSelect = (currencyOption: OptionInterface<Currency>) => {
            WheelRegistrationFormService.selectCurrency(currencyOption.getValue());
        };
        const current = currency ? options.get(currency) : null;

        const classComponent = classNames('currency-list', {
            crazytime: type === 'crazytime' || type === 'roulette' || type === 'bonanza' || type === 'olympus',
        });

        return (
            <Select
                className={classComponent}
                onSelect={onSelect}
                list={Array.from(options.values())}
                current={current || null}
                size={type && type !== 'crazytime' && type !== 'roulette' && type !== 'bonanza' && type !== 'olympus' ? SelectSize.xsmall : SelectSize.average}
            />
        );
    }
}

export default CurrencyList;
