import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';
import { PaymentMethod } from '../../../PaymentMethod/types';
import Directa24WithdrawService, { Directa24WithdrawState } from './Directa24Withdraw';
import WithdrawService from '../../service/Withdrawal';
import Directa24Withdraw from '../../../../../../service/model/payment/Directa24Withdraw';
import { AmountError, PixErrorTip } from '../../../Deposit/Directa24/Pix/service/AmountForm';
import { OnlyLetterService } from '../../../../../../service/Validator/OnlyLetter';
import { CheckBrazilLettersService } from '../../../../../../service/Validator/CheckBrazilLetters';
import BalanceService from '../../../../../../service/Balance';
import BonusService from '../../../../../../service/Bonus';
import StatusFormPopupService, {StatusForm} from '../../../Deposit/DepositPopup/service/StatusForm';

export enum Directa24InputTip {
    NONE,
    AMOUNT,
    NAME,
    SURNAME,
    CPF,
    NON_EMPTY,
}

class PixForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(1, 1000000);

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.withdraw.input.amount',
    });

    numberCPF = new InputService({
        title: 'popup.payment.pix.cpf',
        validator: Validator.cpf,
        validatorText: 'popup.payment.pix.cpf',
    });

    name = new InputService({
        title: 'popup.payment.pix.withdrawal.pix',
        validator: Validator.OnlyLetter,
        validatorText: 'popup.payment.pix.withdrawal.pix',
    });

    tip = new BehaviorSubject<Directa24InputTip>(Directa24InputTip.NONE);

    errorLetters = new BehaviorSubject<PixErrorTip>(PixErrorTip.NONE);

    amountLimit = new BehaviorSubject<AmountError>(AmountError.NONE);

    autoCompleted = new BehaviorSubject<boolean>(false);

    valid = new BehaviorSubject<boolean>(false);

    balance = new BehaviorSubject(0);

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        onNumberCPF: this.onNumberCPF.bind(this),
        onNumberCPFActive: this.onNumberCPFtActive.bind(this),
        onName: this.onName.bind(this),
        onNameActive: this.onNameActive.bind(this),
        onBalance: this.onBalance.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
        this.numberCPF.value.subscribe(this.handler.onNumberCPF);
        this.numberCPF.active.subscribe(this.handler.onNumberCPFActive);
        this.name.value.subscribe(this.handler.onName);
        this.name.active.subscribe(this.handler.onNameActive);
        BalanceService.balance.subscribe(this.handler.onBalance);
    }

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        const limit = this.paymentMethod.limit.withdraw;
        const isFee = WithdrawService.fee.getValue();
        const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
        this.limitValidator.setLimit(isFee ? isMin : limit.min, limit.max);
    }

    onAmount(value: string) {
        const isValue = this.amount.value.getValue();
        if (this.paymentMethod && isValue) {
            const fee = WithdrawService.fee.getValue();
            if(fee && +value < this.paymentMethod.limit.withdraw.min * 1.25) {
                this.amountLimit.next(AmountError.AMOUNT_FEE);
                this.amount.error.next('profile.balance.withdraw.input.amount');
            }
            else {
                this.amountLimit.next(AmountError.NONE);
                this.amount.error.next(null);
            }
            if(!fee && +value < this.paymentMethod.limit.withdraw.min) {
                this.amountLimit.next(AmountError.AMOUNT_MIN);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }
            if(+value > this.paymentMethod.limit.withdraw.max) {
                this.amountLimit.next(AmountError.AMOUNT_MAX);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }
            if( this.balance.getValue() < +value) {
                this.amountLimit.next(AmountError.AMOUNT_WITHDRAWAL);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }

        } else {
            this.amount.error.next(null);
        }
        this.checkValid();
    }

    onBalance(balance: number) {
        this.balance.next(balance)
    }

    onAmountActive(active: boolean) {
        if (active) {
            this.tip.next(Directa24InputTip.AMOUNT);
        } else {
            this.tip.next(Directa24InputTip.NONE);
        }
    }

    onNumberCPF(value: string) {
        this.checkValid();
    }

    onNumberCPFtActive(active: boolean) {
        if (active) {
            this.tip.next(Directa24InputTip.CPF);
        } else {
            this.tip.next(Directa24InputTip.NONE);
        }
    }

    onName(value: string) {
        if (this.name.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.name.value.next(name);
            this.valid.next(false);
            if (CheckBrazilLettersService.validate(value)) {
                this.errorLetters.next(PixErrorTip.NAME)
            } else {
                this.errorLetters.next(PixErrorTip.NONE)
            }

        } else {
            this.name.error.next(null);
            this.errorLetters.next(PixErrorTip.NONE)
        }
        this.checkValid();
    }

    onNameActive(active: boolean) {
        if (active) {
            this.tip.next(Directa24InputTip.NAME);
        } else {
            this.tip.next(Directa24InputTip.NONE);
        }
    }

    checkValid() {
        if (this.paymentMethod) {
            const isAmount = this.amount.isValid() && +this.amount.getValue() < this.balance.getValue();
            const isnumberCPF = this.numberCPF.isValid();
            const isName = this.name.isValid();

            if (isAmount && isnumberCPF && isName) {
                this.valid.next(true);
            } else this.valid.next(false);
        }else this.valid.next(false)
    }

    send() {
        const valid = this.valid.getValue();

        if (valid && this.paymentMethod) {
            const amount = parseInt(this.amount.getValue());
            const numberCPF = this.numberCPF.getValue();
            const request = new Directa24Withdraw(
                this.paymentMethod,
                '',
                this.name.getValue(),
                '',
                numberCPF,
                amount,
            );
            PaymentService.directa24.withdraw(request);
            Directa24WithdrawService.state.next(Directa24WithdrawState.AWAIT);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const status = Directa24WithdrawService.state.getValue();

        const fee = WithdrawService.fee.getValue();
        const isBonuses = BonusService.list.getValue().length;

        if (isBonuses) {
            Directa24WithdrawService.state.next(Directa24WithdrawState.BONUS_REJECT);
            StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
        } else {
            if (!!fee) {
                if (status === Directa24WithdrawState.HAS_FEE) {
                    this.send();
                }
            } else {
                this.send();
            }
        }
    }

    reset() {
        this.amount.value.next('');
        this.name.value.next('');
        this.numberCPF.value.next('');

        this.amount.error.next(null);
        this.name.error.next(null);
        this.numberCPF.error.next(null);

        this.amount.active.next(false);
        this.name.active.next(false);
        this.numberCPF.active.next(false);

        this.amount.autoCompleted.next(false);
        this.name.autoCompleted.next(false);
        this.numberCPF.autoCompleted.next(false);

        this.valid.next(false);
        this.tip.next(Directa24InputTip.NONE);
        this.amountLimit.next(AmountError.NONE);
    }
}

const PixFormService = new PixForm();

export default PixFormService;
