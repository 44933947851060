/* eslint-disable max-len */

import { Icon } from 'types';

function CasinoPopular({ color, width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0)">
                <path
                    d="M10.9117 5.83332C10.9637 5.76778 10.9171 5.67114 10.8334 5.67114H5.11064C5.08036 5.67114 5.0517 5.68486 5.03272 5.70846L4.99779 5.75189C4.97806 5.77642 4.97104 5.8088 4.97882 5.8393L6.37171 11.2936C6.39217 11.3737 6.4955 11.3958 6.54692 11.331L10.9117 5.83332Z"
                    fill={color ?? 'url(#paint0_linear)'}
                />
                <path
                    d="M4.38853 6.76466C4.36814 6.68482 4.26536 6.66252 4.21372 6.72673L0.13084 11.803C0.0782111 11.8684 0.124791 11.9656 0.208763 11.9656H5.58798C5.65325 11.9656 5.70102 11.9041 5.68488 11.8409L4.38853 6.76466Z"
                    fill={color ?? 'url(#paint1_linear)'}
                />
                <path
                    d="M0.760384 12.7803C0.670728 12.7803 0.626372 12.8891 0.690505 12.9518L9.89016 21.9387C9.96919 22.0159 10.0966 21.9269 10.0512 21.8262L6.00397 12.8392C5.98782 12.8033 5.95213 12.7803 5.91279 12.7803H0.760384Z"
                    fill={color ?? 'url(#paint2_linear)'}
                />
                <path
                    d="M12.1547 23.828C12.1501 23.8381 12.1439 23.8473 12.1364 23.8554L12.07 23.9258C12.0317 23.9666 11.9674 23.9679 11.9273 23.9288L11.8532 23.8564C11.8443 23.8477 11.8371 23.8374 11.8319 23.826L6.92106 12.9213C6.89126 12.8551 6.93966 12.7803 7.01224 12.7803H16.9915C17.0641 12.7803 17.1125 12.8552 17.0826 12.9214L12.1547 23.828Z"
                    fill={color ?? 'url(#paint3_linear)'}
                />
                <path
                    d="M13.9063 21.8679C13.8606 21.9687 13.9881 22.0581 14.0672 21.9808L23.3099 12.9518C23.374 12.8891 23.3297 12.7803 23.24 12.7803H18.092C18.0528 12.7803 18.0172 12.8032 18.001 12.839L13.9063 21.8679Z"
                    fill={color ?? 'url(#paint4_linear)'}
                />
                <path
                    d="M23.7916 11.9656C23.8756 11.9656 23.9222 11.8684 23.8696 11.803L19.6017 6.49674C19.5489 6.4311 19.4436 6.45616 19.426 6.53855L18.2937 11.8448C18.2805 11.907 18.3279 11.9656 18.3915 11.9656H23.7916Z"
                    fill={color ?? 'url(#paint5_linear)'}
                />
                <path
                    d="M18.7666 5.792C18.7799 5.72978 18.7324 5.67114 18.6688 5.67114H13.1374C13.0536 5.67114 13.0069 5.7681 13.0593 5.83358L17.4254 11.2947C17.4781 11.3606 17.5837 11.3356 17.6013 11.2531L18.7666 5.792Z"
                    fill={color ?? 'url(#paint6_linear)'}
                />
                <path
                    d="M7.20055 11.8035C7.14852 11.869 7.19519 11.9656 7.27886 11.9656H16.7223C16.8061 11.9656 16.8528 11.8687 16.8004 11.8032L12.0622 5.87671C12.0221 5.82653 11.9457 5.82666 11.9058 5.87698L7.20055 11.8035Z"
                    fill={color ?? 'url(#paint7_linear)'}
                />
                <path
                    d="M1.00531 3.13541C0.98768 3.05039 1.07599 2.98339 1.15095 3.02492L2.24266 3.62974C2.27321 3.64667 2.3102 3.64621 2.34034 3.62855L3.41746 2.99717C3.49142 2.95382 3.58128 3.01864 3.56566 3.10407L3.33811 4.34822C3.33175 4.38303 3.3436 4.41872 3.36942 4.44245L4.29196 5.29046C4.3553 5.34868 4.32253 5.45574 4.23792 5.46701L3.00558 5.63111C2.97109 5.6357 2.94143 5.65821 2.92724 5.69054L2.42029 6.84602C2.38548 6.92535 2.27536 6.9267 2.23869 6.84824L1.70461 5.70551C1.68966 5.67353 1.65947 5.65176 1.62489 5.64801L0.389029 5.51412C0.304175 5.50493 0.268887 5.3987 0.330836 5.33894L1.2331 4.4686C1.25834 4.44424 1.26935 4.40828 1.26216 4.37363L1.00531 3.13541Z"
                    fill={color ?? 'url(#paint8_linear)'}
                />
                <path
                    d="M22.9947 3.13541C23.0123 3.05039 22.924 2.98339 22.8491 3.02492L21.7573 3.62974C21.7268 3.64667 21.6898 3.64621 21.6597 3.62855L20.5825 2.99717C20.5086 2.95382 20.4187 3.01864 20.4343 3.10407L20.6619 4.34822C20.6683 4.38303 20.6564 4.41872 20.6306 4.44245L19.708 5.29046C19.6447 5.34868 19.6775 5.45574 19.7621 5.46701L20.9944 5.63111C21.0289 5.6357 21.0586 5.65821 21.0728 5.69054L21.5797 6.84602C21.6145 6.92535 21.7246 6.9267 21.7613 6.84824L22.2954 5.70551C22.3103 5.67353 22.3405 5.65176 22.3751 5.64801L23.611 5.51412C23.6958 5.50493 23.7311 5.3987 23.6692 5.33894L22.7669 4.4686C22.7417 4.44424 22.7306 4.40828 22.7378 4.37363L22.9947 3.13541Z"
                    fill={color ?? 'url(#paint9_linear)'}
                />
                <path
                    d="M15.9561 0.0946154C15.95 0.00795096 15.8469 -0.031531 15.7861 0.0295064L14.9016 0.918489C14.8768 0.943366 14.8412 0.95336 14.8074 0.944881L13.6007 0.641882C13.5179 0.621079 13.4491 0.708702 13.4874 0.78638L14.0443 1.91773C14.0599 1.94939 14.0582 1.98702 14.0399 2.0171L13.384 3.09207C13.3389 3.16587 13.3995 3.25951 13.4839 3.24648L14.7127 3.05671C14.7471 3.0514 14.7817 3.06466 14.8041 3.09173L15.6055 4.0591C15.6605 4.12552 15.7667 4.09576 15.7806 4.01003L15.983 2.7614C15.9887 2.72646 16.0118 2.69702 16.044 2.68367L17.1951 2.20657C17.2742 2.17381 17.2792 2.06179 17.2034 2.02183L16.0998 1.4399C16.0689 1.42362 16.0485 1.39216 16.046 1.35684L15.9561 0.0946154Z"
                    fill={color ?? 'url(#paint10_linear)'}
                />
                <path
                    d="M8.04387 0.0946154C8.05004 0.00795096 8.15312 -0.031531 8.21385 0.0295064L9.09842 0.918489C9.12317 0.943366 9.15884 0.95336 9.19261 0.944881L10.3993 0.641882C10.4821 0.621079 10.5509 0.708702 10.5126 0.78638L9.95568 1.91773C9.94009 1.94939 9.94178 1.98702 9.96013 2.0171L10.616 3.09207C10.6611 3.16587 10.6005 3.25951 10.5161 3.24648L9.2873 3.05671C9.25291 3.0514 9.21829 3.06466 9.19586 3.09173L8.39454 4.0591C8.33952 4.12552 8.23333 4.09576 8.21943 4.01003L8.01696 2.7614C8.01129 2.72646 7.98821 2.69702 7.956 2.68367L6.80486 2.20657C6.72582 2.17381 6.7208 2.06179 6.79658 2.02183L7.90023 1.4399C7.93111 1.42362 7.95147 1.39216 7.95398 1.35684L8.04387 0.0946154Z"
                    fill={color ?? 'url(#paint11_linear)'}
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="0.545463"
                    y1="5.26829"
                    x2="20.7739"
                    y2="10.0959"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="0.545463"
                    y1="5.26829"
                    x2="20.7739"
                    y2="10.0959"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="0.545463"
                    y1="5.26829"
                    x2="20.7739"
                    y2="10.0959"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="0.545463"
                    y1="5.26829"
                    x2="20.7739"
                    y2="10.0959"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear"
                    x1="0.545463"
                    y1="5.26829"
                    x2="20.7739"
                    y2="10.0959"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear"
                    x1="0.545463"
                    y1="5.26829"
                    x2="20.7739"
                    y2="10.0959"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear"
                    x1="0.545463"
                    y1="5.26829"
                    x2="20.7739"
                    y2="10.0959"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear"
                    x1="0.545463"
                    y1="5.26829"
                    x2="20.7739"
                    y2="10.0959"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear"
                    x1="0.545463"
                    y1="5.26829"
                    x2="20.7739"
                    y2="10.0959"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear"
                    x1="0.545463"
                    y1="5.26829"
                    x2="20.7739"
                    y2="10.0959"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear"
                    x1="0.545463"
                    y1="5.26829"
                    x2="20.7739"
                    y2="10.0959"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear"
                    x1="0.545463"
                    y1="5.26829"
                    x2="20.7739"
                    y2="10.0959"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default CasinoPopular;
