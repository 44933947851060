import useObservable from 'utils/useObservable';

import { PaymentMethod } from '../../../PaymentMethod/types';
import Balance from '../../Balance/Balance';
import ResultForm from '../ResultForm/ResultForm';
import Directa24WithdrawService, { Directa24WithdrawState } from '../service/Directa24Withdraw';
import Pix from './Pix/Pix';
import { useEffect } from 'react';


type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const {paymentMethod} = props;
    const state = useObservable(Directa24WithdrawService.state, Directa24WithdrawService.state.getValue());

    useEffect(() => {
        Directa24WithdrawService.reset();
    }, []);

    if (paymentMethod) {
        if (
            state === Directa24WithdrawState.INIT ||
            state === Directa24WithdrawState.AWAIT ||
            state === Directa24WithdrawState.HAS_FEE
        ) {
            return (
                <>
                    <Balance />
                    <Pix paymentMethod={paymentMethod} />
                </>
            );
        } else {
            return <ResultForm/>;
        }
    }

    return null;
}

export default Form;
