import { translate as t } from 'service/Language';
import Failed from './image/failed.svg';

import './FailedPage.scss';
import PayesWithdrawService, { PayesWithdrawState } from '../../../service/PayesWithdraw';
import PayesFormService from '../../../service/PayesForm';

function FailedPage() {
    function onClickHandler() {
        PayesWithdrawService.state.next(PayesWithdrawState.INIT)
        PayesFormService.name.autoCompleted.next(true);
        PayesFormService.surname.autoCompleted.next(true);
        PayesFormService.walletNumber.autoCompleted.next(true);
        PayesFormService.amount.autoCompleted.next(true);

    }
    return (
        <div className="result-payer">
            <div className="result-payer-container">
                <img className="result-payer-container__img" src={Failed} alt="failed" />
                <p className="result-payer-container__title ">{t('popup.payment.error.title')}</p>
                <span className="result-payer-container__text">{t('popup.payment.error.text')}</span>
                <button className="result-payer-container__btn" onClick={onClickHandler}>
                    {t('popup.payment.error.back')}
                </button>
            </div>
        </div>
    );
}

export default FailedPage;
