import React from "react";
import patternLeftMobile from "./images/pattern-left-mobile.png";
import patternRightMobile from "./images/pattern-right-mobile.png";
import patternLeftTablet from "./images/pattern-left-tablet.png";
import patternRightTablet from "./images/pattern-right-tablet.png";
import patternLeftDesktop from "./images/pattern-left-desktop.png";
import patternRightDesktop from "./images/pattern-right-desktop.png";

import cup from "./images/cup.png";

import Logo from "./images/logo.svg";
import RouteConfig from "appConstants/RouteConfig";
import rocket from "./images/rocket.svg";
import support from "./images/support.svg";
import money from "./images/money.svg";

import { translate as t } from "../../service/Language";
import { NavLink, useLocation } from "react-router-dom";

import "./LandingCricket.scss";

export default function LandingCricket() {
  const location = useLocation();

  return (
    <div className="landingCricket__wrapper">
      <div className="landingCricket">
        <div className="landingCricket__header">
          <img className="landingCricket__logo" src={Logo} alt="Logo" />
        </div>

        <div className="landingCricket__content-desktop">
          <div className="landingCricket__elips">
            <img className="landingCricket__cup" src={cup} alt="Logo" />
          </div>
        </div>

        <div className="landingCricket__content">
          <div className="landingCricket__main-title">
            {t("landing.cricket.title.main")}
          </div>

          <div className="landingCricket__content-mobile">
            <div className="landingCricket__content-flex">
              <img className="landingCricket__cup" src={cup} alt="Logo" />
            </div>
            <div className="landingCricket__elips"></div>

            <div className="landingCricket__text">
              {t("landing.cricket.text.main")}
            </div>

            <div className="landingCricket__button-wrapper">
              <NavLink
                className="landingCricket__button"
                to={{
                  hash: RouteConfig.hash.registration,
                  search: location.search,
                }}
              >
                {t("landing.cricket.bet.main")}
              </NavLink>
            </div>
          </div>

          <div className="landingCricket__text-flex">
            <div className="landingCricket__content-desktop-tablet">
              <div className="landingCricket__text">
                {t("landing.cricket.text.main")}
              </div>
            </div>

            <div className="landingCricket__content-desktop">
              <NavLink
                className="landingCricket__button"
                to={{
                  hash: RouteConfig.hash.registration,
                  search: location.search,
                }}
              >
                {t("landing.cricket.bet.main")}
              </NavLink>
            </div>
          </div>

          <div className="landingCricket__content-tablet">
            <div className="landingCricket__content-flex">
              <img className="landingCricket__cup-mobile" src={cup} alt="cup" />
            </div>
          </div>
        </div>

        <div className="landingCricket__description">
          <div className="landingCricket__main-title-second">
            {t("landing.cricket.title.content")}
          </div>

          <div className="landingCricket__about-block">
            <div className="landingCricket__about">
              <img className="landingCricket__icon" src={rocket} alt="rocket" />
              <div className="landingCricket__about-text">
                {t("landing.cricket.odd.content")}
              </div>
            </div>

            <div className="landingCricket__about">
              <img className="landingCricket__icon" src={money} alt="money" />
              <div className="landingCricket__about-text">
                {t("landing.cricket.payment.main")}
              </div>
            </div>

            <div className="landingCricket__about">
              <img
                className="landingCricket__icon"
                src={support}
                alt="support"
              />
              <div className="landingCricket__about-text">
                {t("landing.cricket.support.main")}
              </div>
            </div>
          </div>

          <div className="landingCricket__button-wrapper">
            <NavLink
              className="landingCricket__button"
              to={{
                hash: RouteConfig.hash.registration,
                search: location.search,
              }}
            >
              {t("landing.cricket.bet.main")}
            </NavLink>
          </div>
        </div>
      </div>

      <div className="landingCricket__pattern-left">
        <div className="landingCricket__content-desktop">
          <img src={patternLeftDesktop} alt="pattern" />
        </div>

        <div className="landingCricket__content-tablet">
          <img src={patternLeftMobile} alt="pattern" />
        </div>

        <div className="landingCricket__content-mobile">
          <img src={patternLeftMobile} alt="pattern" />
        </div>
      </div>

      <div className="landingCricket__pattern-right">
        <div className="landingCricket__content-desktop">
          <img src={patternRightDesktop} alt="pattern" />
        </div>

        <div className="landingCricket__content-tablet">
          <img src={patternRightMobile} alt="pattern" />
        </div>

        <div className="landingCricket__content-mobile">
          <img src={patternRightMobile} alt="pattern" />
        </div>
      </div>
    </div>
  );
}
