import { translate as t } from 'service/Language';

import AttentionIcon from './image/attention.svg';
import Time from './image/time.svg';
import Wallet from './image/wallet.svg';
import Info from './image/info.svg';

import './Attention.scss';

type AttentionProps = {
    title: string;
    type: 'time' | 'attention' | 'wallet' | 'info';
};

function Attention({ title, type }: AttentionProps) {
    const getImage = ()=> {
        if(type === 'attention') {
            return AttentionIcon
        }
        if(type === 'time') {
            return Time
        }
        if(type === 'wallet') {
            return Wallet
        }
        if(type === 'info') {
            return Info
        }
    }


    return (
        <div className="attention">
            <img className="attention__img" src={getImage()} alt="attention" />
            <div className="attention__text">{t(title)}</div>
        </div>
    );
}

export default Attention;
