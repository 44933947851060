import useObservable from 'utils/useObservable';

import { PaymentMethod } from '../../../PaymentMethod/types';
import Balance from '../../Balance/Balance';
import ResultForm from '../ResultForm/ResultForm';
import AnindaHavaleWithdrawService, { AnindaHavaleWithdrawState } from '../service/AnindaHavaleWithdraw';
import Card from './Card/Card';
import { useEffect } from 'react';

type FormProps = {
    paymentMethod: PaymentMethod;
};

export type BankTRYType = {
    id: number;
    name: string;
    value: number;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(AnindaHavaleWithdrawService.state, AnindaHavaleWithdrawService.state.getValue());

    useEffect(()=> {
        AnindaHavaleWithdrawService.reset();
    },[])

    if (paymentMethod) {
        if (state === AnindaHavaleWithdrawState.CARD || state === AnindaHavaleWithdrawState.AWAIT || state === AnindaHavaleWithdrawState.HAS_FEE) {
            return (
                <>
                    <Balance />
                    <Card paymentMethod={paymentMethod} />
                </>
            );
        } else {
            return <ResultForm />;
        }
    }

    return null;
}

export default Form;
