import { NewsLettersStatus } from '@ay_tsarbet/newbet-core/dist/connection/actions/actionEnums';
/*eslint max-len: ["error", { "code": 140 }]*/
import { RegistrationQuickEmailPasswordData } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendRegistrationQuickEmailPassword';

import GoogleService from '../Google';
import RegistrationInterface from '../interface/Registration';
import LanguageService from '../Language';
import Currency from '../model/Currency';
import UserTrackService from '../UserTrack';

interface RegistrationProviderHandler {
    byEmailAndPassword: (data: RegistrationQuickEmailPasswordData) => void;
    sendCurrencyByGeo: () => void;
}

class RegistrationProvider implements RegistrationInterface {
    handler: RegistrationProviderHandler;

    constructor(handler: RegistrationProviderHandler) {
        this.handler = handler;
    }

    byEmailAndPassword(email: string, password: string, currency: Currency, news: boolean) {
        const language = LanguageService.current.getValue();

        const trackData = UserTrackService.get();
        const valid = trackData.isValid();

        this.handler.byEmailAndPassword({
            email,
            password,
            currencyCode: currency.ISO,
            newsletters: news ? NewsLettersStatus.EMAIL_AND_SMS_AGREE : NewsLettersStatus.EMAIL_AND_SMS_AGREE,
            timeZone: new Date().getTimezoneOffset() / -60,
            language: language.title,
            clickId: (valid && trackData.clickId) || '',
            utm_medium: (valid && trackData.utmMedium) || '',
            utm_source: (valid && trackData.utmSource) || '',
            utm_campaign: (valid && trackData.utmCampaign) || '',
            domain: window.location.origin,
        });
    }
}

export default RegistrationProvider;
