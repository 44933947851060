import {
    Direct24DepositCode,
    Direct24DepositResponse,
    Direct24WithdrawResponse
} from '@ay_tsarbet/newbet-core/dist/types';
import { Subject } from 'rxjs';
import Directa24Interface from '../interface/payment/Directa24';
import WSBet from '../WSBet';
import { Directa24DepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendDirecta24DepositRequest';
import { Directa24WithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendDirecta24Withdrawal';
import Directa24DepositService from '../../page/Profile/Balance/Deposit/Directa24/Pix/service/Directa24Deposit';

enum Directa24Error {
    UNKNOWN,
    DEPOSIT,
    WITHDRAW,
}

class Directa24 implements Directa24Interface {
    error = new Subject<Directa24Error>();

    withdrawResult = new Subject<void>();

    deposit(request: Directa24DepositRequest) {
        console.log('deposit', request);
        WSBet.payment.directa24.deposit(request);
    }

    withdraw(withdraw: Directa24WithdrawalRequest) {
        console.log('withdraw', withdraw);
        WSBet.payment.directa24.withdraw(withdraw);
    }

    onDepositReceive(data: Direct24DepositResponse) {
        console.log('Directa24 | DEPOSIT RESPONSE', data);
     if (data.code >= 0) {
            Directa24DepositService.onReceive(data.message);
         console.log('data.message', data.message)
        } else {
            Directa24DepositService.onError();
        }
    }
    onWithdrawReceive(response: Direct24WithdrawResponse) {
        console.log('Directa24  | WITHDRAW RESPONSE', response);
        if (response.errorCode >= Direct24DepositCode.success) {
            this.withdrawResult.next();
        } else {
            this.error.next(Directa24Error.WITHDRAW);
        }
    }
}

export { Directa24Error };
export default Directa24;
