import { translate as t } from 'service/Language';

import Form from '../../Form/Form';
import LoginButton from '../../Form/LoginButton/LoginButton';
import WheelRegistrationFormService from '../../Form/service/registration';
import SignUpButton from '../../Form/SignUpButton/SignUpButton';

import './WheelRegistrationCrazyTime.scss';

function WheelRegistrationCrazyTime() {
    return (
        <div className='crazy-time-registration'>
            <div className='crazy-time-registration__header'>{t('wheel.popup.again.header')}</div>
            <div className='crazy-time-registration__subheader'>{t('wheel.win.125.fs')}</div>
            <div className='crazy-time-registration__content'>
                <div className='crazy-time-registration__block'>
                    <div className='crazy-time-registration__bonus'>
                        {t('wheel.popup.registration.bonus')}
                    </div>
                    <div className='crazy-time-registration__freespins'>
                        {t('wheel.popup.again.freespins.win')}
                    </div>
                </div>
                <div className='crazy-time-registration__balloon' />
            </div>
            <div className='crazy-time-registration__registration'>
                {t('wheel.popup.registration.desc.crazy')}
            </div>
            <form
                className='crazy-time-registration__form'
                onSubmit={event => WheelRegistrationFormService.submit(event)}
            >
                <Form type='crazytime' />
                <SignUpButton type='crazytime' />
                <LoginButton type='crazytime' />
            </form>
        </div>
    );
}

export default WheelRegistrationCrazyTime;
