import { BehaviorSubject } from 'rxjs';
import PaymentService from 'service/Payment';
import StatusFormPopupService, { StatusForm } from '../../../Deposit/DepositPopup/service/StatusForm';
import CardFormService from './CardForm';


enum InstapayWithdrawState {
    CARD,
    AWAIT,
    HAS_FEE,
    ACCEPT,
    REJECT,
    BONUS_REJECT,
}

class InstapayWithdraw {
    state = new BehaviorSubject<InstapayWithdrawState>(InstapayWithdrawState.CARD);

    handler = {
        onWithdraw: this.onWithdraw.bind(this),
        onError: this.onError.bind(this),
    };

    constructor() {
        PaymentService.instapay.withdrawResult.subscribe(this.handler.onWithdraw);
        PaymentService.instapay.error.subscribe(this.handler.onError);
    }

    onWithdraw() {
        this.state.next(InstapayWithdrawState.ACCEPT);
        StatusFormPopupService.status.next(StatusForm.SUCCESS);
    }

    onError() {
        this.state.next(InstapayWithdrawState.REJECT);
        StatusFormPopupService.status.next(StatusForm.FAILED);
    }

    reset() {
        this.state.next(InstapayWithdrawState.CARD);
        StatusFormPopupService.status.next(StatusForm.INIT);
        CardFormService.reset();
    }
}

const InstapayWithdrawService = new InstapayWithdraw();

export { InstapayWithdrawState };
export default InstapayWithdrawService;
