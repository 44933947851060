import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import PayfixWithdraw from 'service/model/payment/PayfixWithdraw';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';

import { OnlyLetterService } from '../../../../../../service/Validator/OnlyLetter';
import { PayfixErrorTip, PayfixInputTip } from '../../../Deposit/Payfix/service/AmountForm';
import { PaymentMethod } from '../../../PaymentMethod/types';
import WithdrawService from '../../service/Withdrawal';
import PayfixWithdrawService, { PayfixWithdrawState } from './PayfixWithdraw';
import { CheckTurkeyLettersService } from '../../../../../../service/Validator/CheckTurkeyLetters';
import { AccountPayfixService } from '../../../../../../service/Validator/AccountPayfix';
import BonusService from '../../../../../../service/Bonus';
import StatusFormPopupService, {StatusForm} from '../../../Deposit/DepositPopup/service/StatusForm';
import OperationsService from '../../../../../../service/Operations';
import History from '../../../../../../service/model/History';
import { ChannelType, Direction, Status } from '@ay_tsarbet/newbet-core/dist/types';

class CardForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(0, 1);

    handler = {
        onName: this.onName.bind(this),
        onNameActive: this.onNameActive.bind(this),
        onSurname: this.onSurname.bind(this),
        onSurnameActive: this.onSurnameActive.bind(this),
        onAccount: this.onAccount.bind(this),
        onAccountActive: this.onAccountActive.bind(this),
        onAmount: this.onAmount.bind(this),
        valid: this.checkValid.bind(this),
        active: this.checkValid.bind(this),
        onTransactionList: this.onTransactionList.bind(this),
    };

    constructor() {
        this.accountName.value.subscribe(this.handler.onAccount);
        this.amount.value.subscribe(this.handler.onAmount);
        this.name.value.subscribe(this.handler.onName);
        this.name.active.subscribe(this.handler.onNameActive);
        this.surname.value.subscribe(this.handler.onSurname);
        this.surname.active.subscribe(this.handler.onSurnameActive);
        this.accountName.active.subscribe(this.handler.onAccountActive);
        OperationsService.list.subscribe(this.handler.onTransactionList)
    }

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
    });

    name = new InputService({
        title: 'profile.balance.deposit.input.name',
        validator: Validator.OnlyLetter,
        validatorText: 'profile.balance.deposit.input.name',
    });

    surname = new InputService({
        title: 'profile.balance.deposit.input.surname',
        validator: Validator.OnlyLetter,
        validatorText: 'profile.balance.deposit.input.surname',
    });

    accountName = new InputService({
        title: 'profile.balance.deposit.input.account.number',
        validator: Validator.accountPayfix,
        validatorText: 'profile.balance.deposit.input.account.number',
    });

    valid = new BehaviorSubject<boolean>(false);

    tranSuccess = new BehaviorSubject<string>('');

    tip = new BehaviorSubject<PayfixInputTip>(PayfixInputTip.NONE);

    errorLetters = new BehaviorSubject<PayfixErrorTip>(PayfixErrorTip.NONE);

    error = new BehaviorSubject<string>('');

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        const limit = this.paymentMethod.limit.withdraw;
        const isFee = WithdrawService.fee.getValue();
        const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
        this.limitValidator.setLimit(isFee ? isMin : limit.min, limit.max);
    }

    onTransactionList(transactionList: History[]) {
        const tran =  transactionList.filter(trn => trn.type === Direction.WITHDRAW
            && trn.paymentMethod?.id === ChannelType.TRY_PAYFIX
            && trn.status === Status.SUCCESS
        )
        if(tran.length)  {
            const time = new Date(tran[0].confirmed)
            if(this.checkLessHour(time)) {
                const confirmed = time.toUTCString()
                this.tranSuccess.next(confirmed)
            } else {
                this.tranSuccess.next('')
            }
        } else {
            this.tranSuccess.next('')
        }
    }

    checkLessHour(timeConfirm: Date): boolean {
        const time = timeConfirm.getTime();
        const currentTime = new Date().getTime();

        const oneHourInMillis = 60 * 60 * 1000; // 1 hour in milliseconds
        const timeDiffInMillis = Math.abs(currentTime - time);

        return timeDiffInMillis <= oneHourInMillis
    }

    send() {
        const valid = this.valid.getValue();

        if (valid && this.paymentMethod) {
            const amount = parseInt(this.amount.getValue());
            const data = {
                currencyId: this.paymentMethod.currency.ISO,
                channelType: this.paymentMethod.id,
                amount,
                name: this.name.getValue(),
                surname: this.surname.getValue(),
                accountName: this.accountName.getValue(),
            };

            const request = new PayfixWithdraw(data);
            PaymentService.payfix.withdraw(request);
            PayfixWithdrawService.state.next(PayfixWithdrawState.AWAIT);
        }
    }

    onNameActive(active: boolean) {
        active ? this.tip.next(PayfixInputTip.NAME)
            : this.tip.next(PayfixInputTip.NONE)
    }

    onSurnameActive(active: boolean) {
        active ? this.tip.next(PayfixInputTip.SURNAME)
            : this.tip.next(PayfixInputTip.NONE)
    }
    onAccountActive(active: boolean) {
        active ? this.tip.next(PayfixInputTip.ACCOUNT_NUMBER_PAYFIX)
            : this.tip.next(PayfixInputTip.NONE)
    }

    onName(value: string) {
        if (this.name.getValue() &&
            !OnlyLetterService.validate(value)
        ) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.name.value.next(name);
            if(CheckTurkeyLettersService.validate(value)) {
                this.errorLetters.next(PayfixErrorTip.NAME)
            } else{
                this.errorLetters.next(PayfixErrorTip.NONE)
            }

        } else {
            this.name.error.next(null);
            this.errorLetters.next(PayfixErrorTip.NONE)
            this.checkValid();
        }
    }

    onSurname(value: string) {
        if (this.surname.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const surname = value.slice(0, current - 1);
            this.surname.value.next(surname);
            if(CheckTurkeyLettersService.validate(value)) {
                this.errorLetters.next(PayfixErrorTip.SURNAME)
            } else{
                this.errorLetters.next(PayfixErrorTip.NONE)
            }
        } else {
            this.surname.error.next(null);
            this.errorLetters.next(PayfixErrorTip.NONE)
            this.checkValid();
        }
    }

    onAccount(value: string) {
        if (value && !AccountPayfixService.validate(value)) {
                this.accountName.error.next('profile.balance.deposit.input.account.number');
            } else {
                this.accountName.error.next(null);
        }
        this.checkValid();
    }

    onAmount(value: string) {
        const isValue = this.amount.value.getValue();
        if (this.paymentMethod && isValue) {
            if(!value.indexOf('0', 0)) {
                const current = value.length;
                const done = value.slice(0, current - 1);
                this.amount.value.next(done);
            }
            const isFee = WithdrawService.fee.getValue();
            const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25) ;

            if(isFee && +isValue < isMin) {
                this.amount.error.next('profile.balance.withdraw.input.amount');
                this.error.next( 'popup.payment.min.fee')
            } else {
                this.amount.error.next(null);
                this.error.next('');
                this.checkValid();
            }
        } else {
            this.amount.error.next(null);
            this.error.next('');
            this.checkValid();
        }
    }

    checkValid() {
        const valid =
            this.accountName.isValid() && this.name.isValid() && this.surname.isValid() && this.amount.isValid();
        if (valid) {
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();
        const status = PayfixWithdrawService.state.getValue();
        const fee = WithdrawService.fee.getValue();
        const isBonuses = BonusService.list.getValue().length;
        const isTranSuccess = this.tranSuccess.getValue().length;

        if (isBonuses) {
            PayfixWithdrawService.state.next(PayfixWithdrawState.BONUS_REJECT);
            StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
        }
        if (isTranSuccess) {
            PayfixWithdrawService.state.next(PayfixWithdrawState.TIME_REJECT);
            StatusFormPopupService.status.next(StatusForm.TIME_REJECT);
        } else {
            if (!!fee) {
                if (status === PayfixWithdrawState.HAS_FEE) {
                    this.send();
                }
            } else {
                this.send();
            }
        }
    }

    reset() {
        this.amount.value.next('');
        this.name.value.next('');
        this.surname.value.next('');
        this.accountName.value.next('');

        this.amount.error.next(null);
        this.name.error.next(null);
        this.surname.error.next(null);
        this.accountName.error.next(null);

        this.amount.active.next(false);
        this.name.active.next(false);
        this.surname.active.next(false);
        this.accountName.active.next(false);

        this.valid.next(false);
        this.tip.next(PayfixInputTip.NONE);
        this.error.next('');
    }
}

const CardFormService = new CardForm();

export default CardFormService;
