import { PaycosDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaycosDepositRequest';
import { PaycosWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaycosWithdraw';

import PaycosInterface from '../../interface/payment/Paycos';

interface PaycosHandler {
    deposit(request: PaycosDepositRequest): void;
    withdraw(request: PaycosWithdrawRequest): void;
}

class PaycosProvider implements PaycosInterface {
    handler: PaycosHandler;

    constructor(handler: PaycosHandler) {
        this.handler = handler;
    }

    deposit(request: PaycosDepositRequest): void {
        this.handler.deposit(request);
    }

    withdraw(request: PaycosWithdrawRequest): void {
        this.handler.withdraw(request);
    }
}

export type { PaycosHandler };
export default PaycosProvider;
