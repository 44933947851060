import classNames from 'classnames';
import { useEffect, useState } from 'react';

import LandingLayout from '../../layout/Langing/Landing';
import { translate as t } from '../../service/Language';
import android from './images/android_icon.svg';
import phone_icon from './images/phone_icon.png';

import './LandingAPK.scss';

function LandingApk({}) {
    const [isHiddenButton, setHiddenButton] = useState(false);

    const scrollRejecter = () => {
        if (window.scrollY <= 320) {
            setHiddenButton(true);
        } else {
            setHiddenButton(false);
        }
    };

    useEffect(() => {
        scrollRejecter();
        document.addEventListener('scroll', scrollRejecter);
        return () => document.removeEventListener('scroll', scrollRejecter);
    }, []);

    const classNameButton = classNames({
        'header-navigate__item__link': true,
        hidden: isHiddenButton,
    });

    const classNameButtonAbsolute = classNames({
        'landingAPK__button-wrapper': true,
        hidden: !isHiddenButton,
    });

    return (
        <LandingLayout>
            <>
                <div className="landingAPK">
                    <div className="landingAPK-container">
                        <div className="landingAPK-container__title">
                            <span>{t('landingAPK.page.mobile').toUpperCase()}</span>
                            <span className="landingAPK-container__title-blue"> GLORY CASINO </span>
                            <span>{t('landingAPK.page.mobile.app').toUpperCase()}</span>
                        </div>
                        <div className="landingAPK-container__text-wrapper">
                            <div className="landingAPK-container__text">{t('landingAPK.page.text')}</div>
                        </div>
                        <div className="landingAPK-container__icon-wrapper">
                            <img
                                className="landingAPK-container__icon-wrapper____icon"
                                src={phone_icon}
                                alt="phone_icon"
                            />
                        </div>
                        <div className="landingAPK-container__text-extra">{t('landingAPK.page.text.extra')}</div>
                        <div className="landingAPK-container__steps">
                            <div className="landingAPK-container__steps-vr" />
                            <div className="landingAPK-container__steps-item">
                                <div className="landingAPK-container__steps-item-number">1</div>
                                <div className="landingAPK-container__steps-item-title">
                                    {t('landingAPK.page.step.one')}
                                </div>
                            </div>
                            <div className="landingAPK-container__steps-item">
                                <div className="landingAPK-container__steps-item-number">2</div>
                                <div className="landingAPK-container__steps-item-title">
                                    {t('landingAPK.page.step.two')}
                                </div>
                            </div>
                            <div className="landingAPK-container__steps-item">
                                <div className="landingAPK-container__steps-item-number">3</div>
                                <div className="landingAPK-container__steps-item-title">
                                    {t('landingAPK.page.step.three')}
                                </div>
                            </div>
                            <div className="landingAPK-container__steps-item">
                                <div className="landingAPK-container__steps-item-number">4</div>
                                <div className="landingAPK-container__steps-item-title">
                                    {t('landingAPK.page.step.four')}
                                </div>
                            </div>
                            <div className="landingAPK-container__steps-item">
                                <div className="landingAPK-container__steps-item-number">5</div>
                                <div className="landingAPK-container__steps-item-title">
                                    {t('landingAPK.page.step.five')}
                                </div>
                            </div>
                            <div className="landingAPK-container__steps-item">
                                <div className="landingAPK-container__steps-item-number">6</div>
                                <div className="landingAPK-container__steps-item-title">
                                    {t('landingAPK.page.step.six')}
                                </div>
                            </div>
                            <div className="landingAPK-container__steps-item">
                                <div className="landingAPK-container__steps-item-number">7</div>
                                <div className="landingAPK-container__steps-item-title">
                                    {t('landingAPK.page.step.seven')}
                                </div>
                            </div>
                        </div>
                        <div className={classNameButton}>
                            <a
                                className="landingAPK-container__button"
                                href="https://glory.casino/apk/Glory_Casino.apk"
                                download="glory_casino"
                                target="_blank" rel="noreferrer"
                            >
                                {t('landingAPK.page.download')}
                                <img src={android} alt="img" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className={classNameButtonAbsolute}>
                    <a
                        className="landingAPK__button"
                        href="https://glory.casino/apk/Glory_Casino.apk"
                        download="glory_casino"
                        target="_blank" rel="noreferrer"
                    >
                        {t('landingAPK.page.download')}
                        <img src={android} alt="img" />
                    </a>
                </div>
            </>
        </LandingLayout>
    );
}

export default LandingApk;
