import SvgImage from '../../../../svg';
import useObservable from '../../../../utils/useObservable';
import Input, { InputSize } from '../../../Input/Input';
import RegistrationFormService from '../service/RegistrationForm';

import './Password.scss';

function Password() {
    const visible = useObservable(RegistrationFormService.visible, RegistrationFormService.visible.getValue());

    return (
        <div className="password-input">
            <Input
                type={visible ? 'text' : 'password'}
                className="password-input__field"
                name="password"
                inputService={RegistrationFormService.password}
                size={InputSize.small}
            />
            <button
                type="button"
                className="password-input__eye"
                onClick={() => RegistrationFormService.togglePassword()}
            >
                <SvgImage name={visible ? 'OpenEye' : 'LockEye'} />
            </button>
        </div>
    );
}

export default Password;
