import { translate as t } from 'service/Language';

import { useEffect, useMemo, useState } from 'react';
import GoogleService from 'service/Google';
import PopupHelper from 'service/PopupHelper';
import classNames from 'classnames';

import './WheelCrazyTime.scss';

const WheelCrazyTime = () => {
    const [angle, setAngle] = useState(0);
    const [idle, setIdle] = useState(true);
    const [attempts, setAttempts] = useState(2);

    useEffect(() => {
        if (!idle) {
            if (attempts === 2) {
                setAngle(785);
            } else {
                setAngle(1825);
            }
        }
    }, [attempts, idle]);

    const spin = {
        transform: `rotate(${angle}deg)`,
    };

    const handleClick = () => {
        setIdle(false);
        setTimeout(() => {
            setAttempts(prevState => {
                if (prevState === 2) {
                    GoogleService.landingSpin('');
                    setIdle(true);
                    PopupHelper.showWheelWin('crazytime', handleClick);
                    return 1;
                } else {
                    GoogleService.landingSpinAgain('');
                    PopupHelper.showWheelRegistration('crazytime');
                    return 0;
                }
            });
        }, 4000);
    };

    const scoreboardClass = classNames('WheelCrazyTime__scoreboard', {
        WheelCrazyTime__scoreboard_idle: idle,
    });

    return (
        <div className='WheelCrazyTime'>
            <div className='WheelCrazyTime__elements'>
                <div className='WheelCrazyTime__elements__logo' />
                <div className='WheelCrazyTime__elements__game' />
                <div className='WheelCrazyTime__elements__title'>{t('wheels.title.roll')}</div>
                {/* <div className='WheelCrazyTime__elements__arrow' /> */}
                <div className='WheelCrazyTime__elements__center' />
                <div className='WheelCrazyTime__elements__balloon' />
                <div className='WheelCrazyTime__attempt'>
                    <div className='WheelCrazyTime__attempt_block'>
                        <div className='WheelCrazyTime__attempt_block_title'>
                            {t('wheel.attempts')}
                        </div>
                        <div className='WheelCrazyTime__attempt_block_number'>{attempts}</div>
                    </div>
                </div>
                <div className='WheelCrazyTime__elements__palmleft' />
                <div className='WheelCrazyTime__elements__palmright' />
            </div>
            <div className={scoreboardClass}>
                <div className='WheelCrazyTime__disk_wrapper'>
                    <div className='WheelCrazyTime__disk' style={spin}>
                        <div className='WheelCrazyTime__disk_name' />
                    </div>
                </div>
            </div>
            <div className='WheelCrazyTime__frame'>
                {/* <div className='WheelCrazyTime__elements__arrow' /> */}
                <div className='WheelCrazyTime__frame_lights first' />
                <div className='WheelCrazyTime__frame_lights second' />
            </div>
            <div className='WheelCrazyTime__frame_bg first'></div>
            <div className='WheelCrazyTime__frame_bg second'></div>

            <div className='WheelCrazyTime__button_wrapper'>
                <button
                    className='WheelCrazyTime__button'
                    disabled={!idle || attempts === 0}
                    onClick={handleClick}
                >
                    <span className='WheelCrazyTime__button_text'>{t('wheel.aviator.button')}</span>
                </button>
            </div>
        </div>
    );
};

export default WheelCrazyTime;
