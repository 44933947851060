import { format, fromUnixTime } from 'date-fns';

import { translate as t } from '../../../../service/Language';
import { getCurrency } from '../../../../service/model/Currency';
import { formatCurrencySign } from '../../../../utils/format';
import img from '../image/i_icon.svg';

import './NotificationItem.scss';

type NotificationProps = {
    type: number;
    amount: number;
    datetime: number;
    isRead: number;
    currency: number;
};

function NotificationItem({ type, amount, datetime, isRead, currency }: NotificationProps) {
    const currentCurrency = getCurrency(currency);
    return (
        <div className="notification-item">
            {!isRead ? <div className="notification-item__point" /> : null}
            <div className="notification-item__icon">
                <img src={img} alt="icon" />
            </div>
            <div className="notification-item__info">
                <div className="notification-item__title">
                    {type === 1
                        ? t('profile.balance.history.model.type.deposit')
                        : t('profile.balance.history.model.type.withdraw')}
                </div>
                <div className="notification-item__text">
                    {type === 1 ? (
                        <span>
                            <span>{t('notification.item.deposit')}</span>
                            <span className="notification-item__text-amount">
                                +{formatCurrencySign(amount, 2, currentCurrency)}
                            </span>
                        </span>
                    ) : (
                        <span>
                            <span>{t('notification.item.withdraw')}</span>
                            <span className="notification-item__text-amount">
                                -{formatCurrencySign(amount, 2, currentCurrency)}
                            </span>
                        </span>
                    )}
                </div>
                <div className="notification-item__date">{format(fromUnixTime(datetime), 'dd/MM/yyyy')}</div>
            </div>
        </div>
    );
}

export default NotificationItem;
