import Validator from "../interface/Validator";

class AccountUpay implements Validator {
  validate(value: string): boolean {
    return value.length > 5;
  }
}
const AccountUpayService = new AccountUpay();

export { AccountUpayService };

export default AccountUpay;
