import { BehaviorSubject } from 'rxjs';
import PaymentService from 'service/Payment';

import { PaymentMethod } from '../../../PaymentMethod/types';

enum EpulWithdrawState {
    CARD,
    AWAIT,
    HAS_FEE,
    ACCEPT,
    REJECT,
}

class EpulWithdraw {
    amount = 0;

    state = new BehaviorSubject<EpulWithdrawState>(EpulWithdrawState.CARD);

    handler = {
        onWithdraw: this.onWithdraw.bind(this),
        onError: this.onError.bind(this),
    };

    constructor() {
        PaymentService.epul.withdrawResult.subscribe(this.handler.onWithdraw);
        PaymentService.epul.error.subscribe(this.handler.onError);
    }

    setAmount(amount: number) {
        this.amount = amount;
    }

    onWithdraw() {
        this.state.next(EpulWithdrawState.ACCEPT);
    }

    onError() {
        this.state.next(EpulWithdrawState.REJECT);
    }

    reset() {
        this.state.next(EpulWithdrawState.CARD);
        this.amount = 0;
    }
}

const EpulWithdrawService = new EpulWithdraw();

export { EpulWithdrawState };
export default EpulWithdrawService;
