import { translate as t } from 'service/Language';
import UIService from 'service/UI';

import PopupService from '../../../../../service/Popup';

import './WheelWinTeen.scss';

type WheelWinProps = {
    spin: () => void;
};

function WheelWinTeen({ spin }: WheelWinProps) {
    const spinAgain = () => {
        UIService.unlockScroll();
        PopupService.close();
        spin();
    };
    return (
        <div className="win-teen">
            <div className="win-teen__header">{t('wheel.popup.again.header')}</div>
            <div className="win-teen__description">{t('wheel.popup.again.desc')}</div>
            <div className="win-teen__title">{t('wheel.popup.again.win')}</div>
            <div className="win-teen__again">{t('wheel.popup.again.win.desc')}</div>
            <button className="win-teen__button" onClick={spinAgain}>
                {t('wheel.popup.again.button')}
            </button>
            <div className="win-teen__things" />
        </div>
    );
}

export default WheelWinTeen;
