/* eslint-disable max-len */

import { Icon } from 'types';

function StarActive({ color = '#FC4665', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
            <path
                d="M12.1957 3.8458C12.3158 3.59191 12.5015 3.37814 12.7319 3.22868C12.9622 3.07921 13.2281 3 13.4993 3C13.7705 3 14.0364 3.07921 14.2667 3.22868C14.4971 3.37814 14.6828 3.59191 14.8029 3.8458L17.2599 9.04304L22.7537 9.87608C23.0223 9.91652 23.2747 10.0346 23.4823 10.217C23.6899 10.3994 23.8445 10.6388 23.9285 10.9082C24.0126 11.1775 24.0227 11.466 23.9578 11.7411C23.893 12.0162 23.7556 12.2668 23.5614 12.4646L19.5844 16.5117L20.5229 22.2233C20.5685 22.5024 20.5385 22.7892 20.4361 23.0513C20.3338 23.3135 20.1633 23.5405 19.9438 23.7068C19.7244 23.8731 19.4647 23.972 19.1943 23.9924C18.9238 24.0127 18.6533 23.9537 18.4132 23.8219L13.4993 21.1272L8.58536 23.8253C8.34508 23.9573 8.07417 24.0165 7.80334 23.996C7.53251 23.9756 7.27258 23.8764 7.05299 23.7096C6.8334 23.5429 6.66294 23.3153 6.56091 23.0526C6.45888 22.7899 6.42936 22.5026 6.4757 22.2233L7.41261 16.5117L3.43882 12.4663C3.24461 12.2686 3.10727 12.0181 3.0423 11.7432C2.97734 11.4682 2.98736 11.1798 3.07122 10.9105C3.15508 10.6412 3.30944 10.4018 3.51685 10.2193C3.72426 10.0367 3.97644 9.91846 4.24488 9.87776L9.73712 9.04472L12.1957 3.84748V3.8458Z"
                fill={color}
            />
        </svg>
    );
}

export default StarActive;
