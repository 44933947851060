/* eslint-disable max-len */

import { Icon } from 'types';

function BigRegby({ color = '#3959D9', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.77986 4.87031C11.4372 -0.45562 18.822 -1.58712 22.2456 2.33891C23.947 4.2914 24.4323 7.14909 23.6077 10.3804C22.6521 13.7485 20.7789 16.7788 18.2009 19.1272C14.3546 22.7427 9.71494 24.432 6.06169 23.9058C4.39514 23.7118 2.85548 22.913 1.72998 21.6586C-1.69364 17.7273 0.122547 10.1962 5.77986 4.87031ZM14.5633 15.9379C18.8011 10.8172 21.0035 4.67033 19.3647 2.51259C19.1265 2.18002 18.7877 1.93439 18.3992 1.81264H18.3157C18.1942 1.77871 18.0703 1.75408 17.9452 1.73896C16.932 1.69706 15.9267 1.93547 15.0382 2.42838C12.9559 3.4441 10.6387 5.40711 8.52501 7.96482C6.52056 10.3268 4.9399 13.0231 3.85407 15.9327C3.07123 18.2535 2.97207 20.1429 3.59312 21.1954C4.19175 21.6594 4.87957 21.9928 5.61285 22.1743C7.90395 22.0691 11.4319 19.7219 14.5633 15.9379ZM6.83929 6.10176C9.44875 3.67036 12.3714 2.17574 15.0121 1.74945L14.8608 1.81787C12.7054 2.87042 10.3255 4.88606 8.15968 7.50692C6.10021 9.93797 4.47715 12.7127 3.36348 15.7063C2.787 17.1583 2.56159 18.7277 2.7059 20.2849C0.179938 16.9536 1.97003 10.6909 6.84451 6.10176H6.83929ZM17.1206 17.9009C19.4703 15.7692 21.1806 13.0162 22.0577 9.95414H22.0681C22.7466 7.28592 22.3917 4.97556 21.0818 3.45988C20.7953 3.13185 20.4672 2.84341 20.1058 2.60205C21.374 5.36501 19.2395 11.2277 14.9443 16.4168C12.4444 19.4061 9.82453 21.4796 7.6378 22.3743C10.5761 22.3006 14.0988 20.7481 17.1206 17.9009Z"
                fill={color}
            />
        </svg>
    );
}

export default BigRegby;
