import { useEffect } from 'react';
import { translate as t } from 'service/Language';

import PaymentChoice from '../../../../../../component/PaymentChoice/PaymentChoice';
import QRcode from '../../../../../../component/QRcode/QRcode';
import GoogleService from '../../../../../../service/Google';
import OperationsService from '../../../../../../service/Operations';
import useObservable from '../../../../../../utils/useObservable';
import { PaymentMethod } from '../../../PaymentMethod/types';
import FailedTransactionPage from '../../Paykassma/ResultPage/FailedPage/FailedPage';
import PaymentHeader from '../UpiFast/PaymentHeader/PaymentHeader';
import PaykassmaDepositService, { DepositState } from '../../Paykassma/service/PaykassmaDeposit';
import Amount from './Amount/Amount';

import './Form.scss';


type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(PaykassmaDepositService.state, PaykassmaDepositService.state.getValue());
    const link = useObservable(PaykassmaDepositService.link, PaykassmaDepositService.link.getValue());

    useEffect(() => {
        const gaSub = OperationsService.invoice.subscribe({
            next: (data) => {
                const clientId = GoogleService.getClientId();
                const invoiceId = data.id.toString();
                OperationsService.sendGAClientRelation(clientId, invoiceId);
            },
        });
        return () => {
            gaSub.unsubscribe();
            PaykassmaDepositService.reset();
        };
    }, []);

    return (
        <div className="deposit-form">
            {state === DepositState.QR ?
                <PaymentHeader />
                :
                <div className="deposit-form__header">
                    <img className="deposit-form__img" src={paymentMethod.icon} alt={paymentMethod.title}/>
                    <h2 className="deposit-form__title">{t(paymentMethod.title)}</h2>
                </div>
            }
            {(state === DepositState.AMOUNT || state === DepositState.WAIT) ? (
                <Amount paymentMethod={paymentMethod} lock={state !== DepositState.AMOUNT} />
            ) : null}
            {state === DepositState.LINK ? <PaymentChoice link={link} /> : null}
            {state === DepositState.QR ? (
                <div className="deposit-form__qr">
                    <QRcode link={link} />
                </div>
            ) : null}
            {state === DepositState.FAILED ? <FailedTransactionPage /> : null}
        </div>
    );
}

export default Form;
