import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import { BankTRY, defaultBank } from 'service/model/BankTRY';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';

import HavaleHeraWithdraw from '../../../../../../service/model/payment/HavaleHeraWithdraw';
import { OnlyLetterService } from '../../../../../../service/Validator/OnlyLetter';
import { HavaleHeraErrorTip, HavaleHeraInputTip } from '../../../Deposit/HavaleHera/service/AmountForm';
import { PaymentMethod } from '../../../PaymentMethod/types';
import WithdrawService from '../../service/Withdrawal';
import HavaleHeraWithdrawService, { HavaleHeraWithdrawState } from './HavaleHeraWithdraw';
import { CheckTurkeyLettersService } from '../../../../../../service/Validator/CheckTurkeyLetters';
import BonusService from '../../../../../../service/Bonus';
import StatusFormPopupService, {StatusForm} from '../../../Deposit/DepositPopup/service/StatusForm';

class CardForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(0, 1);

    handler = {
        onName: this.onName.bind(this),
        onNameActive: this.onNameActive.bind(this),
        onSurname: this.onSurname.bind(this),
        onSurnameActive: this.onSurnameActive.bind(this),
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        valid: this.checkValid.bind(this),
        active: this.checkValid.bind(this),
        onIbanActive: this.onIbanActive.bind(this),
        onIban: this.onIban.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
        this.name.value.subscribe(this.handler.onName);
        this.name.active.subscribe(this.handler.onNameActive);
        this.surname.value.subscribe(this.handler.onSurname);
        this.surname.active.subscribe(this.handler.onSurnameActive);
        this.iban.value.subscribe(this.handler.onIban);
        this.iban.active.subscribe(this.handler.onIbanActive);
    }

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.withdraw.input.amount',
    });

    name = new InputService({
        title: 'profile.balance.deposit.input.name',
        validator: Validator.OnlyLetter,
        validatorText: 'profile.balance.deposit.input.validation.empty',
    });

    surname = new InputService({
        title: 'profile.balance.deposit.input.surname',
        validator: Validator.OnlyLetter,
        validatorText: 'profile.balance.deposit.input.validation.empty',
    });

    iban = new InputService({
        title: 'profile.balance.deposit.input.iban',
        validator: Validator.turkeyIban,
        validatorText: 'profile.balance.deposit.input.iban',
    });

    bank = new BehaviorSubject<BankTRY>(defaultBank);

    valid = new BehaviorSubject<boolean>(false);

    tip = new BehaviorSubject<HavaleHeraInputTip>(HavaleHeraInputTip.NONE);

    errorLetters = new BehaviorSubject<HavaleHeraErrorTip>(HavaleHeraErrorTip.NONE);

    error = new BehaviorSubject<string>('');

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        const limit = this.paymentMethod.limit.withdraw;
        const isFee = WithdrawService.fee.getValue();
        const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
        this.limitValidator.setLimit(isFee ? isMin : limit.min, limit.max);
    }

    send() {
        const valid = this.valid.getValue();

        if (valid && this.paymentMethod) {
            const amount = parseInt(this.amount.getValue()) * 100;
            const userName = this.name.getValue() + this.surname.getValue();
            const data = {
                name: this.name.getValue(),
                surname: this.surname.getValue(),
                userName,
                note: 'Note',
                banksId: this.bank.getValue().id,
                currencyId: this.paymentMethod.currency.ISO,
                channelType: this.paymentMethod.id,
                amount,
                iban: this.iban.getValue(),
            };

            const request = new HavaleHeraWithdraw(data);
            PaymentService.havaleHera.withdraw(request);
            HavaleHeraWithdrawService.state.next(HavaleHeraWithdrawState.AWAIT);
        }
    }

    onSelectBank(bank: BankTRY) {
        this.bank.next(bank);
    }

    onName(value: string) {
        if (this.name.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.name.value.next(name);
            this.valid.next(false);
            if (CheckTurkeyLettersService.validate(value)) {
                this.errorLetters.next(HavaleHeraErrorTip.NAME)
            } else {
                this.errorLetters.next(HavaleHeraErrorTip.NONE)
            }

        } else {
            this.name.error.next(null);
            this.errorLetters.next(HavaleHeraErrorTip.NONE)
        }
        this.checkValid();
    }

    onNameActive(active: boolean) {
        if (active) {
            this.tip.next(HavaleHeraInputTip.NAME);
        } else {
            this.tip.next(HavaleHeraInputTip.NONE);
        }
    }

    onSurname(value: string) {
        if (this.surname.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.surname.value.next(name);
            this.valid.next(false);
            if (CheckTurkeyLettersService.validate(value)) {
                this.errorLetters.next(HavaleHeraErrorTip.SURNAME)
            } else {
                this.errorLetters.next(HavaleHeraErrorTip.NONE)
            }
        } else {
            this.name.error.next(null);
            this.errorLetters.next(HavaleHeraErrorTip.NONE)
        }
        this.checkValid()
    }

    onSurnameActive(active: boolean) {
        if (active) {
            this.tip.next(HavaleHeraInputTip.SURNAME);
        } else {
            this.tip.next(HavaleHeraInputTip.NONE);
        }
    }

    onAmount(value: string) {
        const isValue = this.amount.value.getValue();
        if (this.paymentMethod && isValue) {
            if(!value.indexOf('0', 0)) {
                const current = value.length;
                const done = value.slice(0, current - 1);
                this.amount.value.next(done);
            }
            const isFee = WithdrawService.fee.getValue();
            const isMin = this.paymentMethod.limit.withdraw.min * 1.25;
            if(isFee && +isValue < isMin) {
                this.amount.error.next('profile.balance.withdraw.input.amount');
                this.error.next( 'popup.payment.min.fee')
            } else {
                this.amount.error.next(null);
                this.error.next('');
                this.checkValid();
            }
        } else {
            this.amount.error.next(null);
            this.error.next('');
            this.checkValid();
        }
    }

    onAmountActive(active: boolean) {
        this.tip.next(HavaleHeraInputTip.NONE);
    }

    onIban(value: string) {
        this.checkValid();
        if (value && value.length !== 26) {
            this.tip.next(HavaleHeraInputTip.IBAN);
            this.valid.next(false);
        } else {
            this.tip.next(HavaleHeraInputTip.NONE);
        }
    }

    onIbanActive(isActive: boolean) {
        if (isActive) {
            this.tip.next(HavaleHeraInputTip.IBAN);
        } else {
            this.tip.next(HavaleHeraInputTip.NONE);
        }
    }

    checkValid() {
        const valid = this.iban.isValid() && this.name.isValid() && this.surname.isValid() && this.amount.isValid();
        if (valid) {
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();
        const status = HavaleHeraWithdrawService.state.getValue();
        const fee = WithdrawService.fee.getValue();
        const isBonuses = BonusService.list.getValue().length;

        if (isBonuses) {
            HavaleHeraWithdrawService.state.next(HavaleHeraWithdrawState.BONUS_REJECT);
            StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
        } else {
            if (!!fee) {
                if (status === HavaleHeraWithdrawState.HAS_FEE) {
                    this.send();
                }
            } else {
                this.send();
            }
        }
    }

    reset() {
        this.amount.value.next('');
        this.name.value.next('');
        this.surname.value.next('');
        this.iban.value.next('');

        this.amount.error.next(null);
        this.name.error.next(null);
        this.surname.error.next(null);
        this.iban.error.next(null);

        this.amount.active.next(false);
        this.name.active.next(false);
        this.surname.active.next(false);
        this.iban.active.next(false);

        this.valid.next(false);
        this.tip.next(HavaleHeraInputTip.NONE);
        this.errorLetters.next(HavaleHeraErrorTip.NONE);
    }
}

const CardFormService = new CardForm();

export default CardFormService;
