import { NavLink } from 'react-router-dom';

import { translate as t } from '../../../../service/Language';
import Play from '../image/box.svg';

import './EmptyList.scss';

function EmptyList() {
    return (
        <div className="empty-list">
            <img className="empty-list__img" src={Play} alt="play" />

            <div className="empty-list__title">{t('profile.game.title')}</div>
            <div className="empty-list__description">{t('profile.game.description')}</div>

            <NavLink to="/" className="empty-list__button">
                {t('profile.game.button')}
            </NavLink>
        </div>
    );
}

export default EmptyList;
