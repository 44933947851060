import { EpulDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendEpulDepositRequest';
import { EpulWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendEpulWithdraw';
import { EpulDepositResponse, EpulWithdrawCode, EpulWithdrawResponse } from '@ay_tsarbet/newbet-core/dist/types';
import EpulDepositService from 'page/Profile/Balance/Deposit/Epul/service/EpulDeposit';
import { BehaviorSubject, Subject } from 'rxjs';

import EpulInterface from '../interface/payment/Epul';
import WSBet from '../WSBet';

enum EpulError {
    UNKNOWN,
    DEPOSIT,
    WITHDRAW,
}

class Epul implements EpulInterface {
    error = new Subject<EpulError>();

    redirect = new BehaviorSubject<string | null>(null);

    withdrawResult = new Subject<void>();

    deposit(request: EpulDepositRequest) {
        WSBet.payment.epul.deposit(request);
    }

    withdraw(withdraw: EpulWithdrawRequest) {
        console.log('withdraw Epul', withdraw);
        WSBet.payment.epul.withdraw(withdraw);
    }

    onWithdraw(response: EpulWithdrawResponse) {
        if (response.errorCode >= EpulWithdrawCode.success) {
            this.withdrawResult.next();
        } else {
            this.error.next(EpulError.WITHDRAW);
        }
    }

    onReceiveDeposit(data: EpulDepositResponse) {
        if (!data.errorCode) {
            EpulDepositService.onReceive(data.message);
        }
    }
}

export { EpulError };
export default Epul;
