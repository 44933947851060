import { BehaviorSubject } from 'rxjs';

import GoogleService from '../../../service/Google';

class SortAndFilter {
    toggle = {
        sort: new BehaviorSubject(false),
        filter: new BehaviorSubject(false),
    };

    ref: {
        button: HTMLButtonElement | null;
        list: HTMLDivElement | null;
    } = {
        button: null,
        list: null,
    };

    toggleSort() {
        this.toggle.sort.next(!this.toggle.sort.getValue());
    }

    toggleFilter() {
        this.toggle.filter.next(!this.toggle.filter.getValue());
        if (this.toggle.filter.getValue()) {
            GoogleService.openProviderList();
        }
    }

    setButtonRef(element: HTMLButtonElement | null) {
        this.ref.button = element;
    }

    setListRef(element: HTMLDivElement | null) {
        this.ref.list = element;
    }

    onDocumentClick(event: PointerEvent) {
        const target = event.target as Node;
        let hit = false;

        if (this.ref.button && this.ref.button.contains(target)) {
            hit = true;
        }

        if (this.ref.list && this.ref.list.contains(target)) {
            hit = true;
        }

        if (!hit && this.toggle.filter.getValue()) {
            this.toggleFilter();
        }
    }
}

const SortAndFilterService = new SortAndFilter();

export default SortAndFilterService;
