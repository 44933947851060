import classNames from 'classnames';
import { useState, VFC } from 'react';

import './ImageLoader.scss';

interface ImageLoaderProps {
    src?: string;
    className?: string;
    imageClassName?: string;
}

const ImageLoader: VFC<ImageLoaderProps> = ({ src, imageClassName, className = '' }) => {
    const [isLoading, setIsLoading] = useState(!!src);
    const [errorLoading, setErrorLoading] = useState(false);

    const _className = classNames({
        'image-loader-container': true,
        [className]: !!className,
    });

    return (
        <div className={_className}>
            <div
                className={classNames('image-loader-backplate', {
                    'image-loader-backplate_loading': isLoading,
                    'image-loader-backplate_loaded': !isLoading && !errorLoading,
                })}
            />
            {src && (
                <img
                    className={classNames('image-loader-image', imageClassName, {
                        'image-loader-image_loaded': !isLoading && !errorLoading,
                    })}
                    src={src}
                    onLoad={() => setIsLoading(false)}
                    onError={() => {
                        setIsLoading(false);
                        setErrorLoading(true);
                    }}
                />
            )}
        </div>
    );
};

export default ImageLoader;
