import { translate as t } from 'service/Language';
import AcceptCheckbox from '../../Form/AcceptCheckbox/AcceptCheckbox';

import Form from '../../Form/Form';
import LoginButton from '../../Form/LoginButton/LoginButton';
import PromotionCheckbox from '../../Form/PromotionCheckbox/PromotionCheckbox';
import WheelRegistrationFormService from '../../Form/service/registration';
import SignUpButton from '../../Form/SignUpButton/SignUpButton';

import './WheelRegistationSlots.scss';

function WheelRegistationSlots() {
    return (
        <div className='slots-registration'>
            <div className='slots-registration__coins'></div>
            <div className='slots-registration__top'>
                <div className='slots-registration__cong'>{t('wheel.popup.reg.header')}</div>
                <div className='slots-registration__won'>{t('wheel.popup.reg.title')}</div>
                <div className='slots-registration__title'>{t('wheel.slots.bonus')}</div>
            </div>
            <form
                className='slots-registration__form'
                onSubmit={event => WheelRegistrationFormService.submit(event)}
            >
                <Form type='slots' />
                <SignUpButton type='slots' title='wheel.popup.reg.sign' />
                <LoginButton type='slots' />
            </form>
        </div>
    );
}

export default WheelRegistationSlots;
