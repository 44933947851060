import { translate as t } from 'service/Language';

import { profileRouteList } from '../Profile';
import Account from './image/Account.svg';
import Bonus from './image/Bonus.svg';
import GameHistory from './image/GameHistory.svg';
import Transaction from './image/Transaction.svg';
import NavigationItem from './NavigationItem/NavigationItem';

import './Navigation.scss';

type RouteNavigationType = {
    title: string;
    route: string;
};

type ProfileNavigationData = {
    title: string;
    icon: string;
    route: RouteNavigationType[];
};

function Navigation() {
    const navigationData: ProfileNavigationData[] = [
        {
            title: t('profile.balance.history.title'),
            icon: Transaction,
            route: [
                {
                    title: t('profile.balance.history.title'),
                    route: profileRouteList.balanceHistory,
                },
            ],
        },
        {
            title: t('profile.navigation.account'),
            icon: Account,
            route: [{ title: t('profile.navigation.personal'), route: profileRouteList.account }],
        },
        {
            title: t('profile.navigation.games'),
            icon: GameHistory,
            route: [{ title: 'game-history', route: profileRouteList.gameHistory }],
        },
        {
            title: t('profile.navigation.bonus'),
            icon: Bonus,
            route: [{ title: 'bonus', route: profileRouteList.bonus }],
        },
    ];

    const renderNavigationItems = (item: ProfileNavigationData, index: number) => {
        return <NavigationItem key={index} title={item.title} icon={item.icon} route={item.route} />;
    };

    return <div className="profile-navigation">{navigationData.map(renderNavigationItems)}</div>;
}
export type { ProfileNavigationData, RouteNavigationType };
export default Navigation;
