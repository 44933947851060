import Button from './Button';
import List from './List';

import './Filter.scss';

function Filter() {
    return (
        <div className="provider-filter">
            <Button />
            <List className="provider-filter__list" />
        </div>
    );
}

export default Filter;
