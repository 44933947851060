import classNames from 'classnames';

import GamesService, { GameFilter } from '../../../service/Games';
import GoogleService from '../../../service/Google';
import GameProvider from '../../../service/model/GameProvider';
import RxComponent from '../../RxComponent/RxComponent';
import ProviderFilterService from '../service/ProviderFilter';
import SortAndFilterService from '../service/SortAndFilter';

import './Alphabet.scss';

type ProviderListProps = {};
type ProviderListState = {
    filter: GameFilter;
    providerList: GameProvider[];
};

type AlphabetRecord = {
    char: string;
    providerList: GameProvider[];
};

class Alphabet extends RxComponent<ProviderListProps, ProviderListState> {
    state = {
        providerList: [],
        filter: GamesService.filter.getValue(),
    };

    handler = {
        setFilter: this.setFilter.bind(this),
        setProviders: this.setProviders.bind(this),
    };

    setFilter(filter: GameFilter) {
        this.setState({ filter });
    }

    setProviders(providerList: GameProvider[]) {
        this.setState({ providerList });
    }

    componentDidMount() {
        this.subscribe(GamesService.filter, { next: this.handler.setFilter });
        this.subscribe(ProviderFilterService.list, { next: this.handler.setProviders });
    }

    pickProvider(provider: GameProvider) {
        GamesService.setProviderFilter(provider);
        SortAndFilterService.toggleFilter();
        GoogleService.filterProvider(provider.name);
    }

    validateImage(src: string, callback: (valid: boolean) => void) {
        const img = new Image();
        img.onload = () => callback(true);
        img.onerror = () => callback(false);
        img.src = src;
    }

    render() {
        const { providerList, filter } = this.state;

        const GLORY_COVERS = [
            "https://diswdgcu9cfva.cloudfront.net/providers_logo/mini/glory_cover_1.svg",
            "https://diswdgcu9cfva.cloudfront.net/providers_logo/mini/glory_cover_2.svg",
            "https://diswdgcu9cfva.cloudfront.net/providers_logo/mini/glory_cover_4.svg",
            "https://diswdgcu9cfva.cloudfront.net/providers_logo/mini/glory_cover_4.svg",
            "https://diswdgcu9cfva.cloudfront.net/providers_logo/mini/glory_cover_5.svg",
            "https://diswdgcu9cfva.cloudfront.net/providers_logo/mini/glory_cover_6.svg",
            "https://diswdgcu9cfva.cloudfront.net/providers_logo/mini/glory_cover_7.svg",
            "https://diswdgcu9cfva.cloudfront.net/providers_logo/mini/glory_cover_8.svg",
        ];

        const alphabet = new Map<string, AlphabetRecord>();

        providerList.forEach((provider: GameProvider) => {
            const char: string = provider.name.charAt(0);
            let record = alphabet.get(char);
            if (!record) {
                record = {
                    char: char,
                    providerList: [],
                };
                alphabet.set(char, record);
            }
            record.providerList.push(provider);
        });

        let recordList = Array.from(alphabet.values());
        recordList = recordList.sort((a, b) => (a.char > b.char ? 1 : -1));

        return (
            <div className="provider-alphabet">
                {recordList.map((record: AlphabetRecord) => {
                    return (
                        <div className="provider-alphabet__record" key={record.char}>
                            <div className="provider-alphabet__char">{record.char}</div>
                            <div className="provider-alphabet__list">
                                {record.providerList.map((provider) => {
                                    const className = classNames({
                                        'provider-alphabet__provider': true,
                                        'provider-alphabet__provider__active': provider === filter.provider,
                                    });

                                    return (
                                        <button key={provider.name} className={className} onClick={() => this.pickProvider(provider)}>
                                            <img
                                                src={provider.logoImage}
                                                alt={provider.name}
                                                className="provider-alphabet__provider-logo"
                                                onError={(e) => {
                                                    e.currentTarget.src = GLORY_COVERS[Math.floor(Math.random() * GLORY_COVERS.length)];
                                                }}
                                            />
                                            <div className="provider-alphabet__provider-name">{provider.name}</div>
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default Alphabet;
