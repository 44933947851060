import classNames from 'classnames';
import { translate as t } from 'service/Language';

import useObservable from '../../utils/useObservable';
import ResetFormService from './service/ResetForm';

import './Submit.scss';

function Submit() {
    const active = useObservable(ResetFormService.valid, false);

    const buttonClass = classNames({
        'reset-submit': true,
        'reset-submit____active': active,
    });

    return (
        <button type="submit" className={buttonClass}>
            {t('recovery.button.save')}
        </button>
    );
}

export default Submit;
