import classNames from 'classnames';
import { translate as t } from 'service/Language';

import useObservable from '../../../../utils/useObservable';
import PersonalDataFormService from '../PersonalData/service/PersonalDataForm';

import './SaveButton.scss';

function SaveButton() {
    const enabled = useObservable(PersonalDataFormService.canSave, false);

    const className = classNames('personal-submit', { 'personal-submit____active': enabled });

    return (
        <button type="submit" className={className}>
            {t('profile.account.button')}
        </button>
    );
}

export default SaveButton;
