/* eslint-disable max-len */

import { Icon } from 'types';

function BigVideo({ color = '#3959D9', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12ZM24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM10.6402 6.23179C10.3421 5.98339 9.92723 5.92984 9.57584 6.09442C9.22446 6.259 9 6.61199 9 7.00001V17C9 17.388 9.22446 17.741 9.57584 17.9056C9.92723 18.0702 10.3421 18.0166 10.6402 17.7682L16.6402 12.7682C16.8682 12.5782 17 12.2968 17 12C17 11.7032 16.8682 11.4218 16.6402 11.2318L10.6402 6.23179Z"
                fill={color}
            />
        </svg>
    );
}

export default BigVideo;
