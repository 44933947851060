import colors from 'appConstants/colors';
import RouteConfig from 'appConstants/RouteConfig';
import { NavLink } from 'react-router-dom';
import { translate as t } from 'service/Language';
import SvgImage from 'svg';

import RxComponent from '../../../component/RxComponent/RxComponent';
import ScrollService from '../../../component/ScrollTopButton/service/Scroll';
import SortAndFilterMenu from '../../../component/SortAndFilterMenu/SortAndFilterMenu';
import GamesService, { GameFilter } from '../../../service/Games';
import { GameList as GameListType } from '../../../service/model/Game';
import { gameCategory } from '../../../service/model/GameCategory';
import GameList from '../GameList/GameList';
import ZeroScreen from './ZeroScreen/ZeroScreen';

import './Category.scss';

type CategoryProps = {};
type CategoryState = {
    games: GameListType;
    filter: GameFilter;
};

class Category extends RxComponent<CategoryProps, CategoryState> {
    state = {
        games: [],
        filter: GamesService.filter.getValue(),
    };

    handler = {
        setGames: this.setGames.bind(this),
        setFilter: this.setFilter.bind(this),
    };

    setGames(games: GameListType) {
        this.setState({ games });
    }

    setFilter(filter: GameFilter) {
        const prevFilter = this.state.filter;
        if (prevFilter.category !== filter.category) {
            ScrollService.scrollToSmoothly(0, 500);
        }
        this.setState({ filter });
    }

    componentDidMount() {
        this.subscribe(GamesService.filteredGames, { next: this.handler.setGames });
        this.subscribe(GamesService.filter, { next: this.handler.setFilter });
    }

    render() {
        const { filter, games } = this.state;
        let { category } = filter;
        if (!category) {
            category = gameCategory.Popular;
        }

        return (
            <div className="game-category">
                <div className="game-category__header">
                    <div className="game-category__header_left">
                        {window.location.pathname !== RouteConfig.casino.root && (
                            <NavLink to={RouteConfig.casino.root} className="game-category__backurl">
                                <SvgImage color={colors.brightGray} width={24} height={24} name="LeftFlatArrow" />
                            </NavLink>
                        )}
                        <h2 className="game-category__title">{t(category.name)}</h2>
                    </div>
                    <SortAndFilterMenu />
                </div>
                {games.length ? <GameList games={games} /> : <ZeroScreen />}
            </div>
        );
    }
}

export default Category;
