/* eslint-disable max-len */

import { Icon } from 'types';

function CasinoArcade({ color, width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                d="M20.0359 12.9694L21.0664 10.9082C21.1152 10.8107 21.1406 10.7029 21.1406 10.5938V2.05308C21.1406 0.920812 20.2198 0 19.0875 0H4.91245C3.78019 0 2.85938 0.920812 2.85938 2.05308V10.5938C2.85938 10.7029 2.88478 10.8107 2.93353 10.9082L3.96408 12.9694C3.30947 13.3278 2.85938 14.015 2.85938 14.8125V15.5156H21.1406V14.8125C21.1406 14.015 20.6905 13.3278 20.0359 12.9694ZM16.9219 12.7031V12C16.9219 11.6114 16.6074 11.2969 16.2188 11.2969C15.8301 11.2969 15.5156 11.6114 15.5156 12V12.7031H14.1094V12C14.1094 11.6114 13.7949 11.2969 13.4062 11.2969C13.0176 11.2969 12.7031 11.6114 12.7031 12V12.7031H9.89062V11.2969H8.48438V12.7031H5.82638L6.95138 7.07812H17.0487L18.1737 12.7031H16.9219ZM17.3339 5.67188H6.66614L5.25989 4.26562H18.7401L17.3339 5.67188Z"
                fill={color ?? 'url(#paint0_linear)'}
            />
            <path
                d="M10.9502 10.0205C10.9502 10.987 10.1667 11.7705 9.2002 11.7705C8.2337 11.7705 7.4502 10.987 7.4502 10.0205C7.4502 9.05401 8.2337 8.27051 9.2002 8.27051C10.1667 8.27051 10.9502 9.05401 10.9502 10.0205Z"
                fill={color ?? 'url(#paint1_linear)'}
            />
            <path
                d="M2.85938 16.9219V23.2969C2.85938 23.6855 3.17386 24 3.5625 24C11.6743 24 12.3257 24 20.4375 24C20.8261 24 21.1406 23.6855 21.1406 23.2969V16.9219H2.85938ZM15.5156 22.5938H8.48438V20.3812C8.48438 20.0248 8.77481 19.7344 9.1312 19.7344H14.8688C15.2252 19.7344 15.5156 20.0248 15.5156 20.3812V22.5938Z"
                fill={color ?? 'url(#paint2_linear)'}
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="3.27486"
                    y1="5.26829"
                    x2="19.0396"
                    y2="8.13411"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="3.27486"
                    y1="5.26829"
                    x2="19.0396"
                    y2="8.13411"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="3.27486"
                    y1="5.26829"
                    x2="19.0396"
                    y2="8.13411"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default CasinoArcade;
