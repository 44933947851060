import { NavLink } from 'react-router-dom';
import { translate as t } from 'service/Language';

import RouteConfig from '../../../appConstants/RouteConfig';

import './ForgotPassword.scss';

function ForgotPassword() {
    return (
        <NavLink to={{ hash: RouteConfig.hash.recovery }} className="forgot-password">
            {t('login.recovery')}
        </NavLink>
    );
}

export default ForgotPassword;
