import Validator from '../interface/Validator';

class AccountBankalfallah implements Validator {
    validate(value: string): boolean {
        return value.length === 14;
    }
}
const AccountBankalfallahService = new AccountBankalfallah();

export { AccountBankalfallahService };

export default AccountBankalfallah;
