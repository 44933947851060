import {
    CoinspaidDepositCode,
    CoinspaidDepositRateResponse, CoinspaidDepositResponse, CoinspaidWithdrawResponse,
} from '@ay_tsarbet/newbet-core/dist/types';
import {Subject} from 'rxjs';
import WSBet from '../WSBet';
import ConstantposInterface from '../interface/payment/Constantpos';

import CoinspaidDepositService, {
    DepositState
} from '../../page/Profile/Balance/Deposit/Coinspaid/service/CoinspaidDeposit';
import {CoinsPaidWithdrawRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendCoinsPaidWithdraw';
import {CoinsPaidRateRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sentCoinsPaidRateRequest';
import {CoinsPaidDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sentCoinsPaidDepositRequest';
import CoinspaidWithdrawService, {
    CryptoWithdrawState
} from '../../page/Profile/Balance/Withdrawal/Coinspaid/service/CoinspaidWithdrawal';

enum CoinPaidError {
    UNKNOWN,
    DEPOSIT,
    WITHDRAW,
}

class CoinsPaid implements ConstantposInterface {
    error = new Subject<CoinPaidError>();

    withdrawResult = new Subject<void>();

    requestRateMin(request: CoinsPaidRateRequest) {
        console.log('requestRateMin', request);
        WSBet.payment.coinspaid.requestRateMin(request);
    }
    deposit(request: CoinsPaidDepositRequest) {
        console.log('deposit', request);
        CoinspaidDepositService.state.next(DepositState.WAIT)
        WSBet.payment.coinspaid.deposit(request);
    }

    withdraw(withdraw: CoinsPaidWithdrawRequest) {
        console.log('withdraw', withdraw);
        WSBet.payment.coinspaid.withdraw(withdraw);
    }

    onRateReceive(data: CoinspaidDepositRateResponse) {
        console.log('CoinsPaid | DEPOSIT RESPONSE', data);
     if (!!data) {
         CoinspaidDepositService.state.next(DepositState.DATA)
         CoinspaidDepositService.onRateReceive(data);
         console.log('data', data)
        } else {
         CoinspaidDepositService.onError();
        }
    }
    onDepositReceive(data: CoinspaidDepositResponse) {
        console.log('CoinsPaid | DEPOSIT RESPONSE', data);
     if (data.code >= 0) {
         CoinspaidDepositService.state.next(DepositState.DATA);
         CoinspaidDepositService.onReceive(data);
         console.log('data.message', data.message)
        } else {
         CoinspaidDepositService.onError();
        }
    }
    onWithdrawReceive(response: CoinspaidWithdrawResponse) {
        console.log('CoinsPaid  | WITHDRAW RESPONSE', response);
        if (response.errorCode >= CoinspaidDepositCode.success) {
            this.withdrawResult.next();
        } else {
            this.error.next(CoinPaidError.WITHDRAW);
        }
    }
}

export { CoinPaidError };
export default CoinsPaid;
