import classNames from 'classnames';
import { translate as t } from 'service/Language';

import useObservable from '../../../../../utils/useObservable';
import PasswordFormService from '../service/PasswordFormService';

import './ChangeButton.scss';

function ChangeButton() {
    const active = useObservable<boolean>(PasswordFormService.active, PasswordFormService.active.value);

    const buttonClass = classNames({
        'change-button__title': true,
        'change-button__title____active': active,
    });

    return (
        <div className="change-button">
            <button className={buttonClass} disabled={!active} type="submit">
                {t('profile.settings.password.change.button')}
            </button>
        </div>
    );
}

export default ChangeButton;
