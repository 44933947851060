import { RegistrationResponseStatus } from '@ay_tsarbet/newbet-core';
import { Step10Data } from '@ay_tsarbet/newbet-core/dist/types';
import { BehaviorSubject } from 'rxjs';

import UserDataService from '../../../../../../service/UserData';
import WSBet from '../../../../../../service/WSBet';
import EmailInputService from '../NewEmail/service/NewEmail';
import OldPasswordService from '../OldPasswordInput/service/OldPassword';
import AuthService from '../../../../../../service/Auth';

export enum EmailChangeResult {
    INIT,
    SUCCESS,
    ALREADY_IN_USE,
    ERROR_PASSWORD,
}
class ChangeEmail {
    public status = new BehaviorSubject<EmailChangeResult>(EmailChangeResult.INIT);

    change(email: string) {
        const userData = UserDataService.data.getValue();

        if (userData) {
            const newUserData = userData.copy();
            newUserData.email = email;
            UserDataService.pendingData = newUserData;
            WSBet.userData.fullUpdate(newUserData);
            AuthService.sendConfirmEmail();
        }
    }

    onReceive(data: Step10Data) {
        if (data.status === RegistrationResponseStatus.userDataUpdateSuccess) {
            this.status.next(EmailChangeResult.SUCCESS);
        }
        if (data.status === RegistrationResponseStatus.emailAlreadyInUse) {
            this.status.next(EmailChangeResult.ALREADY_IN_USE);
            EmailInputService.error.next('registration.error.email_registered');
        }
        if (data.status === RegistrationResponseStatus.invalidUpdatePassword) {
            this.status.next(EmailChangeResult.ERROR_PASSWORD);
            OldPasswordService.error.next('profile.settings.password.change.error');
        }
    }
}

const ChangeEmailService = new ChangeEmail();

export default ChangeEmailService;
