import RouteConfig from 'appConstants/RouteConfig';
import BonusWidget from 'component/BonusWidget/BonusWidget';
import Header from 'component/Header/Header';
import Search from 'component/Search/Search';
import SearchResult from 'component/SearchResult/SearchResult';
import { useState } from 'react';
import { Route } from 'react-router-dom';
import AuthService from 'service/Auth';
import GamesService from 'service/Games';
import BonusService from '../../../service/Bonus';

import { useIsDesktop } from 'utils/useBreakpoints';
import useObservable from 'utils/useObservable';

import MainBanner from '../Banners/MainBanner/MainBanner';
import Casino from '../Casino';
import TopBar from './CategoryNavigation/CategoryNavigation';
import SideBar from './SideBar/SideBar';
import TopMenu from './TopMenu/TopMenu';

import './CasinoLayout.scss';

function CasinoLayout() {
    const isDesktop = useIsDesktop();
    const [searchFocused, setSearchFocused] = useState(false);
    const searchText = useObservable(GamesService.searchText, GamesService.searchText.getValue());

    const showSearchResults = !isDesktop && (searchFocused || !!searchText.length);

    const isAuth = useObservable(AuthService.auth, AuthService.auth.getValue());
    const bonuses = useObservable(BonusService.list, BonusService.list.getValue());
    const isFirstDepositBonus = bonuses.find(bonus => bonus.channelType === 50)

    return (
        <div className="casino-layout">
            <Header />
            {!isDesktop && (
                <div className="casino-layout__search">
                    <Search onFocus={() => setSearchFocused(true)} onBlur={() => setSearchFocused(false)} />
                </div>
            )}
            {showSearchResults ? (
                <div className="casino-layout__search-result">
                    <SearchResult />
                </div>
            ) : (
                <>
                    {!isDesktop && <TopMenu />}
                    {isAuth && !isDesktop && isFirstDepositBonus && <BonusWidget />}
                    <Route path={RouteConfig.casino.root} exact>
                        {!isDesktop && <MainBanner />}
                        {!isDesktop && <TopBar />}
                    </Route>
                    <div className="casino-layout__screen">
                        {isDesktop && <SideBar />}
                        <Casino />
                    </div>
                </>
            )}
        </div>
    );
}

export default CasinoLayout;
