import { Route, Switch } from 'react-router-dom';

import RouteConfig from '../../../appConstants/RouteConfig';
import WelcomeBonus from './WelcomeBonus/WelcomeBonus';

function Promo() {
    const welcomeBonus = `${RouteConfig.info.root}${RouteConfig.info.promo.root}`;
    return (
        <div>
            <Switch>
                <Route path={welcomeBonus} component={WelcomeBonus} />
            </Switch>
        </div>
    );
}

export default Promo;
