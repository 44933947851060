/* eslint-disable max-len */

import { Icon } from 'types';

function BigKibersport({ color = '#3959D9', width = 28, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.25463 7.29819C9.25463 8.38744 10.123 9.32108 11.2466 9.32108C12.3704 9.32108 13.2386 8.38744 13.2386 7.29819C13.2386 6.20895 12.3193 5.2753 11.2466 5.2753C10.1739 5.2753 9.25463 6.15707 9.25463 7.29819ZM10.2762 7.29819C10.2762 6.7795 10.6847 6.31268 11.2466 6.31268C11.8085 6.31268 12.217 6.72764 12.217 7.29819C12.217 7.81688 11.8085 8.28371 11.2466 8.28371C10.7358 8.28371 10.2762 7.86875 10.2762 7.29819Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.622295 15.8047C2.25688 20.836 6.85387 24 11.8085 24C13.0343 24 14.3113 23.7925 15.5882 23.3776C18.6529 22.3402 21.1045 20.1098 22.4837 17.1533L22.7902 16.479L14.0559 12.122L22.2794 6.46829L21.9218 5.84587C19.1125 1.02205 13.2897 -1.15645 8.02856 0.607096C1.89924 2.73372 -1.47169 9.52856 0.622295 15.8047ZM8.53934 2.11129C9.56089 1.74821 10.6847 1.5926 11.7574 1.5926C15.0774 1.5926 18.2952 3.25242 20.2872 6.10521L11.0423 12.3295L20.7471 17.2052C19.4702 19.4355 17.5291 21.0953 15.0774 21.9252C9.66315 23.7407 3.84034 20.7841 2.05262 15.3379C0.2649 9.89164 3.17618 3.97858 8.53934 2.11129Z"
                fill={color}
            />
            <path d="M23.1476 9.89164H20.3383V12.7444H23.1476V9.89164Z" fill={color} />
            <path d="M25.1907 9.89164H28V12.7444H25.1907V9.89164Z" fill={color} />
        </svg>
    );
}

export default BigKibersport;
