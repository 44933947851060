import Validator from '../interface/Validator';

class LettersWithSpace implements Validator {
    validate(value: string): boolean {
        return !this.firstSpace(value) && this.onlyLetters(value) && this.lengthCheck(value);
    }

    onlyLetters(value: string): boolean {
        return new RegExp('^[A-Za-z- ]+$').test(value);
    }
    firstSpace(value: string): boolean {
        return new RegExp('^[ ]+$').test(value[0]);
    }

    lengthCheck(value: string): boolean {
        return value.length <= 40;
    }
}
const LettersWithSpaceService = new LettersWithSpace();

export { LettersWithSpaceService };
export default LettersWithSpace;
