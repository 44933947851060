import { NavLink } from 'react-router-dom';

import GamesService from '../../../../service/Games';
import { translate as t } from '../../../../service/Language';
import ScrollService from '../../../ScrollTopButton/service/Scroll';
import FooterService from '../../service/Footer';

type CasinoLinkItemProps = {
    className: string;
};

function CasinoLinkItem({ className }: CasinoLinkItemProps) {
    return (
        <>
            {FooterService.linkList.map((item) => (
                <NavLink
                    key={item.id}
                    to={item.path}
                    className={className}
                    exact
                    onClick={() => ScrollService.scrollToSmoothly(0, 500)}
                >
                    {t(item.title)}
                </NavLink>
            ))}
            <button className={className} onClick={() => GamesService.loadAviatorGame()}>
                {t('footer.aviator')}
            </button>
        </>
    );
}

export default CasinoLinkItem;
