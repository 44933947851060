/* eslint-disable max-len */

import { Icon } from 'types';

function Loader({ color = 'white', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                d="M9.84629 3.26733C10.1683 3.18813 10.4047 2.90377 10.3982 2.5722L10.3799 1.6426C10.372 1.23693 10.0095 0.928616 9.61291 1.01442C8.26119 1.30688 7.00005 1.84255 5.87759 2.57334C5.53961 2.79338 5.50129 3.26416 5.77926 3.55636L6.41786 4.22766C6.64849 4.47011 7.02067 4.50425 7.30589 4.32925C8.08258 3.85272 8.93724 3.49088 9.84629 3.26733Z"
                fill={color}
            />
            <path
                d="M3.26644 9.8604C3.18755 10.184 2.90158 10.4215 2.56856 10.4139L1.64048 10.3925C1.23608 10.3832 0.929276 10.0217 1.01421 9.62625C1.29617 8.31336 1.8075 7.08563 2.50421 5.98707C2.72064 5.64579 3.19218 5.60257 3.48737 5.87856L4.16489 6.51201C4.4086 6.73987 4.44652 7.11036 4.27555 7.39686C3.82415 8.15325 3.4807 8.98152 3.26644 9.8604Z"
                fill={color}
            />
            <path
                d="M4.29342 16.6268C4.46126 16.9056 4.43096 17.2658 4.20098 17.496L3.53946 18.1582C3.24891 18.449 2.76657 18.4149 2.54411 18.0692C1.8326 16.9635 1.30973 15.7249 1.02098 14.3989C0.935022 14.0041 1.23992 13.642 1.64375 13.6308L2.57112 13.6049C2.90471 13.5956 3.1922 13.8325 3.2721 14.1565C3.48957 15.0384 3.83719 15.869 4.29342 16.6268Z"
                fill={color}
            />
            <path
                d="M9.85573 20.7288C10.1729 20.8065 10.4078 21.0834 10.4078 21.41V22.345C10.4078 22.755 10.0442 23.0715 9.64326 22.9859C8.28937 22.6969 7.02581 22.1641 5.90077 21.4355C5.56317 21.2169 5.52277 20.7479 5.79799 20.4546L6.43115 19.7799C6.66102 19.5349 7.03479 19.499 7.32148 19.6741C8.09671 20.1476 8.94925 20.5069 9.85573 20.7288Z"
                fill={color}
            />
            <path
                d="M16.6013 19.7212C16.8918 19.5477 17.2683 19.5893 17.4951 19.8404L18.1139 20.5254C18.3821 20.8223 18.3349 21.2877 17.9967 21.5016C16.9076 22.1901 15.6921 22.6966 14.3928 22.9783C13.9988 23.0638 13.6373 22.7599 13.6251 22.357L13.597 21.4306C13.5868 21.0961 13.8245 20.8074 14.1496 20.7277C15.0241 20.5131 15.8483 20.1706 16.6013 19.7212Z"
                fill={color}
            />
            <path
                d="M20.7313 14.1452C20.8103 13.823 21.0948 13.5863 21.4266 13.5928L22.356 13.6111C22.7615 13.6191 23.0698 13.9814 22.9842 14.3778C22.6924 15.7305 22.1571 16.9925 21.4264 18.1158C21.2056 18.4552 20.7324 18.4923 20.4407 18.2115L19.7719 17.5676C19.5322 17.3368 19.4992 16.9671 19.6729 16.6834C20.148 15.9072 20.5086 15.0533 20.7313 14.1452Z"
                fill={color}
            />
            <path
                d="M19.6751 7.31409C19.5019 7.03081 19.5346 6.66195 19.7734 6.4312L20.4414 5.78556C20.733 5.50378 21.207 5.54055 21.428 5.88049C22.1579 7.00318 22.6926 8.26437 22.9842 9.61602C23.0698 10.0125 22.7615 10.3747 22.356 10.3827L21.4266 10.401C21.0948 10.4075 20.8103 10.1709 20.7313 9.8486C20.509 8.94196 20.1491 8.08933 19.6751 7.31409Z"
                fill={color}
            />
        </svg>
    );
}

export default Loader;
