/* eslint-disable max-len */

import { Icon } from 'types';

function LibraIcon({ color = '#3F4250', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                d="M20.6984 11.7658L18.206 8.54106C18.1886 8.37702 18.083 8.22912 17.9196 8.17054L13.6325 6.63167C13.6481 6.53815 13.6583 6.44287 13.6583 6.34472C13.6583 5.41534 12.9149 4.65905 12.0014 4.65905C11.3797 4.65905 10.8373 5.00967 10.554 5.52653L6.37227 4.02546C6.14584 3.94389 5.89769 4.06481 5.81772 4.29539C5.79838 4.35088 5.79121 4.40769 5.79404 4.46318L3.35507 7.61786H3.32595C3.14581 7.61786 3 7.76642 3 7.94947C3 9.83321 4.43505 11.3657 6.19908 11.3657C7.9629 11.3657 9.39773 9.83299 9.39773 7.94947C9.39773 7.77504 9.26474 7.63334 9.09655 7.62052L7.29949 5.29596L10.3467 6.38959C10.3665 7.14588 10.8773 7.77836 11.5668 7.96937V18.5443H8.41597C8.17607 18.5443 7.98137 18.7424 7.98137 18.9864C7.98137 19.2305 8.17607 19.4286 8.41597 19.4286H15.5866C15.8265 19.4286 16.0212 19.2305 16.0212 18.9864C16.0212 18.7424 15.8265 18.5443 15.5866 18.5443H12.436V7.96959C12.762 7.87939 13.0484 7.69082 13.2617 7.43614L17.2073 8.85232L14.9571 11.7632H14.9282C14.7481 11.7632 14.6023 11.9117 14.6023 12.0948C14.6023 13.9783 16.0373 15.5105 17.8016 15.5105C19.565 15.5105 21 13.9781 21 12.0948C20.9998 11.9203 20.867 11.7791 20.6984 11.7658ZM6.22472 4.97894L8.26495 7.61808H4.1845L6.22472 4.97894ZM6.19908 10.7027C4.94309 10.7027 3.89897 9.71384 3.69188 8.41947H8.70585C8.49876 9.71384 7.45486 10.7027 6.19908 10.7027ZM17.827 9.12402L19.8674 11.7632H15.7866L17.827 9.12402ZM17.8014 14.8475C16.5451 14.8475 15.501 13.8589 15.2939 12.5648H20.3079C20.1008 13.8589 19.0571 14.8475 17.8014 14.8475Z"
                fill={color}
            />
        </svg>
    );
}

export default LibraIcon;
