import { NavLink, useLocation } from 'react-router-dom';

import RouteConfig from '../../../../../appConstants/RouteConfig';
import GoogleService from '../../../../../service/Google';
import { translate as t } from '../../../../../service/Language';
import UI from '../../../../../service/UI';

import './AuthBlock.scss';

function AuthBlock() {
    const location = useLocation();
    return (
        <div className="auth-block">
            <div className="auth-block__sign-up-wrapper" onClick={() => UI.slideMenu.next(false)}>
                <NavLink
                    to={{ hash: RouteConfig.hash.registration, search: location.search }}
                    className="auth-block__sign-up"
                    onClick={() => GoogleService.slideMenuRegistration()}
                >
                    {t('header.sign.up')}
                </NavLink>
            </div>
            <div className="auth-block__sign-up-wrapper" onClick={() => UI.slideMenu.next(false)}>
                <NavLink
                    to={{ hash: RouteConfig.hash.auth, search: location.search }}
                    className="auth-block__sign-in"
                    onClick={() => GoogleService.slideMenuLogin()}
                >
                    {t('header.sign.in')}
                </NavLink>
            </div>
        </div>
    );
}

export default AuthBlock;
