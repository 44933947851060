import { formatCurrencySign } from 'utils/format';

import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import AmountFormService from '../../service/AmountForm';
import PaykassmaDepositService, { DepositState } from '../../service/PaykassmaDeposit';
import SumSelector from '../../SummSelector/SumSelector';
import Submit from '../Button/Submit';
import DisabledAmount from './DisabledAmount/DisabledAmount';

import './Amount.scss';

type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {};

class Amount extends RxComponent<AmountProps, AmountState> {
    componentDidMount() {
        const { paymentMethod } = this.props;

        AmountFormService.reset();
        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setValue(paymentMethod.defaultValue);
    }

    render() {
        const { paymentMethod, lock } = this.props;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        const amount = PaykassmaDepositService.state.getValue() === DepositState.AMOUNT;

        return (
            <div className="deposit-amount-wavepay">
                <form className="deposit-amount-wavepay__form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    {amount ? (
                        <div className="deposit-amount-wavepay__input_wrapper">
                            <MaskedInput
                                mask="00000000000"
                                inputService={AmountFormService.amount}
                                className="deposit-amount-wavepay__input"
                                name="amount"
                                hint={hint}
                                enabled={!lock}
                            />
                            <span className="deposit-amount-wavepay__input_currency">
                                {paymentMethod.currency.sign}
                            </span>
                        </div>
                    ) : (
                        <DisabledAmount hint={hint} />
                    )}
                    <div className="deposit-amount-wavepay__sum">
                        <SumSelector paymentMethod={paymentMethod} disabled={lock} />
                    </div>
                    <Submit />
                </form>
            </div>
        );
    }
}

export default Amount;
