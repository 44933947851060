import Input, { InputSize } from 'component/Input/Input';
import RxComponent from 'component/RxComponent/RxComponent';
import { translate as t } from 'service/Language';
import { formatCurrencySign } from 'utils/format';
import MaskedInput from 'component/Input/MaskedInput';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import WithdrawalButton from '../../WithdrawalButton/WithdrawalButton';
import Attention from '../../../../../../../assets/svg/attention.svg';
import Back from './image/back.png';
import { AmountError } from '../../../../Deposit/Directa24/Pix/service/AmountForm';
import { createRef } from 'react';
import { PayesInputTip, PayesLettersTip } from '../../../../Deposit/Payes/service/AmountForm';
import PayesFormService from '../../service/PayesForm';
import PayesWithdrawService, { PayesWithdrawState } from '../../service/PayesWithdraw';

import './M10.scss';
import WithdrawService from '../../../service/Withdrawal';


type EManatProps = {
    paymentMethod: PaymentMethod;
};
type EManatState = {
    value: string;
    amountLimit: AmountError,
    errorLetters: PayesLettersTip,
    tip: PayesInputTip;
    completed: boolean,
    errorAmount: string | null,
    errorWallet: string | null,
};

class M10 extends RxComponent<EManatProps, EManatState> {
    state = {
        value: '',
        tip: PayesInputTip.NONE,
        amountLimit: AmountError.NONE,
        errorLetters: PayesLettersTip.NONE,
        completed: false,
        errorAmount: null,
        errorWallet: null,
    };

    handler = {
        onTip: this.onTip.bind(this),
        onErrorAmount: this.onErrorAmount.bind(this),
        onErrorLetters: this.ErrorLetters.bind(this),
        getValue: this.getValue.bind(this),
        onWalletError: this.onWalletError.bind(this),

        onAutoCompleted: this.onAutoCompleted.bind(this),
        onAmountLimit: this.onAmountLimit.bind(this),
        clickOutside: this.clickOutside.bind(this),
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    close() {
        this.setState({errorLetters: PayesLettersTip.NONE});
    }

    clickOutside(event: PointerEvent) {
        if (this.ref.self.current && !this.ref.self.current.contains(event.target as Node)) {
            this.close();
        }
    }
    componentWillUnmount() {
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }
    onErrorAmount(errorAmount: string | null) {
        this.setState({errorAmount});
    }

    onWalletError(errorWallet: string | null) {
        this.setState({ errorWallet });
    }

    onAmountLimit(amountLimit: AmountError) {
        this.setState({amountLimit});
    }

    onTip(tip: PayesInputTip) {
        this.setState({ tip });
    }

    ErrorLetters(errorLetters: PayesLettersTip) {
        this.setState( { errorLetters });
    }

    onAutoCompleted(completed: boolean) {
        this.setState({ completed });
    }

    getValue(value: string) {
        this.setState({ value });
    }

    componentDidMount() {
        const {paymentMethod} = this.props;

        PayesFormService.setPaymentMethod(paymentMethod);
        this.subscribe(PayesFormService.amount.value, {next: this.handler.getValue});
        this.subscribe(PayesFormService.amount.error, {next: this.handler.onErrorAmount});
        this.subscribe(PayesFormService.walletNumber.error, {next: this.handler.onWalletError});
        this.subscribe(PayesFormService.tip, {next: this.handler.onTip});
        this.subscribe(PayesFormService.errorLetters, {next: this.handler.onErrorLetters});
        this.subscribe(PayesFormService.amountLimit, {next: this.handler.onAmountLimit});
        document.addEventListener('pointerdown', this.handler.clickOutside);
    }

    render() {
        const { paymentMethod } = this.props;
        const { amountLimit, errorLetters, value, tip, errorAmount, errorWallet } = this.state;

        const status = PayesWithdrawService.state.getValue();

        const min = formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency);
        const max = formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency);

        const hint = `${t('popup.payment.amount.min')} ${min} — ${t('popup.payment.amount.max')} ${max}`;

        const fee = WithdrawService.fee.getValue();

        const isInit = status === PayesWithdrawState.INIT;
        if (fee && isInit) {
            PayesWithdrawService.state.next(PayesWithdrawState.HAS_FEE)
        }
        const minFee = Math.ceil(paymentMethod.limit.withdraw.min * 1.25)
        const amountFee = formatCurrencySign(minFee, 0, paymentMethod.currency);
        const allFeeText = `${amountFee} ${t('popup.payment.min.fee')}`;

        return (
            <div className="emanat-withdrawal">
                <div className="emanat-withdrawal__header">
                    <img src={paymentMethod.icon} alt={paymentMethod.slug}/>
                    <h2 className="emanat-withdrawal__header_title">{t(paymentMethod.title)}</h2>
                </div>
                <form className="emanat-withdrawal-form" onSubmit={(e) => PayesFormService.onSubmit(e)}>
                    <div className="emanat-withdrawal-form-block">
                        <Input
                            className="emanat-withdrawal-form-block__input"
                            inputService={PayesFormService.amount}
                            name="amount"
                            type="number"
                        />
                        <span className="emanat-withdrawal-form-block__currency">{paymentMethod.currency.sign}</span>
                        {!!errorAmount ? (
                            <div className="emanat-withdrawal-form-block__error">
                                <img src={Attention} alt="attention"/>
                                <span className="emanat-withdrawal-form-block__error_text">
                                    {amountLimit && amountLimit === AmountError.AMOUNT_MIN ? t('popup.payment.amount.min') + ` ${min}` : null}
                                    {fee && amountLimit === AmountError.AMOUNT_FEE ? allFeeText : null}
                                    {amountLimit && amountLimit === AmountError.AMOUNT_MAX ? t('popup.payment.amount.max') + ` ${max}` : null}
                                    {amountLimit && amountLimit === AmountError.AMOUNT_WITHDRAWAL ? t('popup.payment.amount.withdrawal.max') : null}
                                    </span>
                            </div>
                        ) : null}
                        {tip === PayesInputTip.AMOUNT && !errorAmount ? (
                            <span className="emanat-withdrawal-form-block__tip">
                                {hint}
                            </span>
                        ) : null}
                    </div>
                    <div className="emanat-withdrawal-form-block">
                        <Input
                            className="emanat-withdrawal-form-block__input"
                            inputService={PayesFormService.name}
                            name="name"
                            size={InputSize.medium}
                        />
                        {tip === PayesInputTip.NAME ? (
                            <span className="emanat-withdrawal-form-block__tip">
                                {t('popup.balance.deposit.input.name')}
                            </span>
                        ) : null}
                        {
                            errorLetters === PayesLettersTip.NAME ?
                                <div className="emanat-withdrawal-form-block-letters" ref={this.ref.self}>
                                    <img className="emanat-withdrawal-form-block-letters__img" src={Back} alt="back"/>
                                    <span
                                        className="emanat-withdrawal-form-block-letters__text">{t('popup.payment.azer.error.letters')}</span>
                                </div>
                                : null
                        }
                    </div>
                    <div className="emanat-withdrawal-form-block">
                        <Input
                            className="emanat-withdrawal-form-block__input"
                            inputService={PayesFormService.surname}
                            name="name"
                            size={InputSize.medium}
                        />
                        {tip === PayesInputTip.SURNAME ? (
                            <span className="emanat-withdrawal-form-block__tip">
                                {t('popup.balance.deposit.input.name')}
                            </span>
                        ) : null}
                        {
                            errorLetters === PayesLettersTip.SURNAME ?
                                <div className="emanat-withdrawal-form-block-letters" ref={this.ref.self}>
                                    <img className="emanat-withdrawal-form-block-letters__img" src={Back} alt="back"/>
                                    <span
                                        className="emanat-withdrawal-form-block-letters__text">{t('popup.payment.azer.error.letters')}</span>
                                </div>
                                : null
                        }
                    </div>
                    <div className="emanat-withdrawal-form-block">
                        <MaskedInput
                            className="emanat-withdrawal-form-block__input"
                            inputService={PayesFormService.walletNumber}
                            placeholder="000 000 000"
                            mask="000 000 000"
                            name="wallet"
                            size={InputSize.medium}
                        />
                        {tip === PayesInputTip.WALLET && !errorWallet ? (
                            <span className="emanat-withdrawal-form-block__tip">
                                {t('popup.payment.wallet.m10')}
                            </span>
                        ) : null}
                        {errorWallet ? (
                            <div className="emanat-withdrawal-form-block__error">
                                <img src={Attention} alt="attention"/>
                               <span className="emanat-withdrawal-form-block__error_text" >{t('popup.payment.wallet.emanat.error')}</span>
                            </div>
                        ) : null}
                    </div>
                    {((status === PayesWithdrawState.HAS_FEE || status === PayesWithdrawState.AWAIT) && fee ) ?
                        <FeeNotice amount={value} fee={fee} /> : null}
                    {status === PayesWithdrawState.HAS_FEE ? (
                        <>
                            <WithdrawalButton
                                buttonTitle="profile.balance.withdraw.fee.agree"
                                variation={true}
                                paymentMethod={paymentMethod}
                                amount={value}
                            />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel"/>
                        </>
                    ) : (
                        <WithdrawalButton
                            buttonTitle="profile.balance.withdraw.button"
                            paymentMethod={paymentMethod}
                        />
                    )}
                </form>
            </div>
        );
    }
}

export default M10;
