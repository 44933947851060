import { useEffect } from 'react';
import GoogleService from 'service/Google';
import { translate as t } from 'service/Language';
import OperationsService from 'service/Operations';
import useObservable from 'utils/useObservable';

import { PaymentMethod } from '../../../PaymentMethod/types';
import FailedPage from '../FailedPage/FailedPage';
import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import AnindaHavaleDepositService, { DepositState } from '../service/AnindaHavaleDeposit';
import Amount from './Amount/Amount';

import './Form.scss';


type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(AnindaHavaleDepositService.state, AnindaHavaleDepositService.state.getValue());

    useEffect(() => {
        const gaSub = OperationsService.invoice.subscribe({
            next: (data) => {
                const clientId = GoogleService.getClientId();
                const invoiceId = data.id.toString();
                OperationsService.sendGAClientRelation(clientId, invoiceId);
            },
        });
        return () => {
            gaSub.unsubscribe();
            AnindaHavaleDepositService.reset();
        };
    }, []);
    console.log('AnindaHavale,', paymentMethod)
    return (
        <div className="deposit-form">
            {state !== DepositState.SUCCESS && state !== DepositState.FAILED ? <div className="deposit-form__header">
                <img className="deposit-form__img" src={paymentMethod.icon} alt={paymentMethod.title}/>
                <h2 className="deposit-form__title">{t(paymentMethod.title)}</h2>
            </div> : null}
            {(state === DepositState.AMOUNT || state === DepositState.WAIT) && (
                <Amount paymentMethod={paymentMethod} lock={state !== DepositState.AMOUNT} />
            )}

            {state === DepositState.SUCCESS ? <SuccessPage /> : null}
            {state === DepositState.FAILED ? <FailedPage /> : null}
        </div>
    );
}

export default Form;
