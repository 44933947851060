import { translate as t } from 'service/Language';
import { formatCurrencySign } from 'utils/format';

import Input, { InputSize } from '../../../../../../../component/Input/Input';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import PaymentService from '../../../../../../../service/Payment';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import AmountFormService from '../../service/AmountForm';
import PaykassmaDepositService, { DepositState } from '../../service/PaykassmaDeposit';
import SumSelector from '../../SummSelector/SumSelector';
import Submit from '../Button/Submit';
import DisabledAmount from './DisabledAmount/DisabledAmount';
import VideoInstruction from '../../Other/VideoInstruction/VideoInstruction';

import './Amount.scss';


type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {};

class Amount extends RxComponent<AmountProps, AmountState> {
    handler = {
        onInvoiceError: this.onInvoiceError.bind(this),
    };

    onInvoiceError() {
        AmountFormService.amount.error.next(t('profile.balance.deposit.invoice.error'));
    }

    componentDidMount() {
        const { paymentMethod } = this.props;

        AmountFormService.reset();
        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setValue(paymentMethod.defaultValue);
        PaykassmaDepositService.resetInvoice();
        this.subscribe(PaymentService.paykassma.invoice, {
            next: (invoice) => PaykassmaDepositService.onReceiveInvoice(invoice),
        });
        this.subscribe(PaymentService.paykassma.error, { next: this.handler.onInvoiceError });
    }

    render() {
        const { paymentMethod, lock } = this.props;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        const amount = PaykassmaDepositService.state.getValue() === DepositState.AMOUNT;

        return (
            <div className="deposit-amount-nagad">
                <VideoInstruction method={paymentMethod} />
                <form className="deposit-amount-nagad__form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    <MaskedInput
                        mask="00000000000"
                        inputService={AmountFormService.walletNumber}
                        name="iban"
                        size={InputSize.medium}
                    />
                    {amount ? (
                        <MaskedInput
                            mask="00000000000"
                            inputService={AmountFormService.amount}
                            className="deposit-amount-nagad__input"
                            name="amount"
                            hint={hint}
                            enabled={!lock}
                        />
                    ) : (
                        <DisabledAmount hint={hint} />
                    )}
                    <div className="deposit-amount-nagad__sum">
                        <SumSelector paymentMethod={paymentMethod} disabled={lock} />
                    </div>
                    <Submit />
                </form>
            </div>
        );
    }
}

export default Amount;
