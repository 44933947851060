/* eslint-disable max-len */

import { Icon } from 'types';

function MailIcon({ width = 100, height = 100 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="15" fill="#F3F3F6" />
            <g clipPath="url(#clip0_1:192)">
                <rect opacity="0.5" x="27" y="34" width="52" height="38" rx="8" fill="url(#paint0_linear_1:192)" />
                <path
                    d="M20 36C20 31.5817 23.5817 28 28 28H64C68.4183 28 72 31.5817 72 36V58C72 62.4183 68.4183 66 64 66H28C23.5817 66 20 62.4183 20 58V36Z"
                    fill="url(#paint1_linear_1:192)"
                />
                <path
                    d="M12 27L37 42.4412L45.4745 47.6754C45.7966 47.8744 46.2034 47.8744 46.5255 47.6754L55 42.4412L80 27"
                    stroke="#F3F3F6"
                    strokeWidth="2.5"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_1:192"
                    x1="28.1818"
                    y1="42.3415"
                    x2="70.0413"
                    y2="56.0118"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1:192"
                    x1="21.1818"
                    y1="36.3415"
                    x2="63.0413"
                    y2="50.0118"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <clipPath id="clip0_1:192">
                    <rect width="72" height="72" fill="white" transform="translate(14 14)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default MailIcon;
