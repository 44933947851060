import PayesInterface from '../../interface/payment/Payes';
import { PayesDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPayesDepositRequest';
import { PayesWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPayesWithdrawal';

interface PayesHandler {
    deposit(request: PayesDepositRequest): void;

    withdraw(request: PayesWithdrawalRequest): void;
}

class PayesProvider implements PayesInterface {
    handler: PayesHandler;

    constructor(handler: PayesHandler) {
        this.handler = handler;
    }

    deposit(request: PayesDepositRequest): void {
        this.handler.deposit(request);
    }

    withdraw(request: PayesWithdrawalRequest): void {
        this.handler.withdraw(request);
    }
}

export type { PayesHandler };
export default PayesProvider;
