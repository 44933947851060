import { BehaviorSubject } from 'rxjs';

import { PaymentMethod } from '../../PaymentMethod/types';
import AnindaHavaleWithdrawService from '../AnindaHavale/service/AnindaHavaleWithdraw';
import AzarpayWithdrawService from '../Azarpay/service/AzarpayWithdraw';
import CorefyWithdrawService from '../Corefy/service/CorefyWithdraw';
import EpulWithdrawService from '../Epul/service/EpulWithdraw';
import HavaleHeraWithdrawService from '../HavaleHera/service/HavaleHeraWithdraw';
import InstapayWithdrawService from '../Instapay/service/InstapayWithdraw';
import PaycosWithdrawService from '../Paycos/service/PaycosWithdraw';
import PaykassmaWithdrawService from '../Paykassma/service/PaykassmaWithdraw';
import Directa24DepositService from '../../Deposit/Directa24/Pix/service/Directa24Deposit';

enum WithdrawState {
    PHONE,
    AWAIT,
    HAS_FEE,
    ACCEPT,
    REJECT,
}

class Withdraw {
    method = new BehaviorSubject<PaymentMethod | null>(null);

    fee = new BehaviorSubject<number | null>(null);

    pickMethod(method: PaymentMethod) {
        this.method.next(method);
    }

    setFee(fee: number) {
        this.fee.next(fee);
    }

    back() {
        this.reset();
        EpulWithdrawService.reset();
        PaycosWithdrawService.reset();
        PaykassmaWithdrawService.reset();
        InstapayWithdrawService.reset();
        AnindaHavaleWithdrawService.reset();
        HavaleHeraWithdrawService.reset();
        CorefyWithdrawService.reset();
        AzarpayWithdrawService.reset();
        Directa24DepositService.reset();
    }

    reset() {
        this.method.next(null);
    }
}

const WithdrawService = new Withdraw();

export { WithdrawState };
export default WithdrawService;
