import Storage from './Storage';

class LogChannel {
    parent: LogChannel | null = null;

    childs: LogChannel[] = [];

    constructor(public name: string) {}

    addChild(channel: LogChannel) {
        this.childs.push(channel);
        channel.parent = this;
    }
}

const logChannel: Record<string, LogChannel> = {
    GLOBAL: new LogChannel('Global'),
    WSBet: new LogChannel('WebSocket'),
    step1: new LogChannel('step1'),
    step3: new LogChannel('step3'),
    step10: new LogChannel('step10'),
    step13: new LogChannel('step13'),
    step20: new LogChannel('step20'),
    step21: new LogChannel('step21'),
    step24: new LogChannel('step24'),
    step25: new LogChannel('step25'),
    step40: new LogChannel('step40'),
    step45: new LogChannel('step45'),
    step71: new LogChannel('step71'),
    step200: new LogChannel('step200'),
    step201: new LogChannel('step201'),
    step202: new LogChannel('step202'),
    step203: new LogChannel('step203'),
    onBalanceUpdate: new LogChannel('onBalanceUpdate'),
    onBonusBalanceUpdate: new LogChannel('onBonusBalanceUpdate'),
};

const channelList = Object.values(logChannel);

logChannel.GLOBAL.addChild(logChannel.WSBet);
logChannel.WSBet.addChild(logChannel.step1);

/**
 * %s → Formats the value as a string
 * %i or %d → Formats the value as an integer
 * %f → Formats the value as a floating point value
 * %o → Formats the value as an expandable DOM element. As seen in the Elements panel
 * %O → Formats the value as an expandable JavaScript object
 * %c → Applies CSS style rules to the output string as specified by the second parameter
 */

class Log {
    subscribedChannels: LogChannel[] = [];

    static STORAGE_KEY = 'LOG_CHANNEL';

    constructor() {
        let subNames: string[] = [];
        try {
            subNames = Storage.get(Log.STORAGE_KEY) as string[];
        } catch (e) {}

        const map = new Map(channelList.map(channel => [channel.name, channel]));

        if (subNames && subNames.length) {
            subNames.forEach(name => {
                const channel = map.get(name);
                if (channel) {
                    this.subscribedChannels.push(channel);
                }
            });
        }
    }

    writeIntoChannel(channel: LogChannel, data: any) {
        if (this.subscribedChannels.includes(channel)) {
            console.log(...data);
        }
    }

    subscribeFor(channel: LogChannel) {
        if (!this.subscribedChannels.includes(channel)) {
            this.subscribedChannels.push(channel);
            channel.childs.forEach(child => this.subscribeFor(child));
        }
    }

    unsubscribeFrom(channel: LogChannel) {
        const index = this.subscribedChannels.indexOf(channel);
        if (index !== -1) {
            this.subscribedChannels.splice(index);
            channel.childs.forEach(child => this.unsubscribeFrom(child));
        }
    }

    save() {
        Storage.set(
            Log.STORAGE_KEY,
            this.subscribedChannels.map(channel => channel.name),
        );
    }
}

const LogService = new Log();

function log(channel: LogChannel, ...data: any) {
    LogService.writeIntoChannel(channel, data);
}

export { logChannel as LC, log };
export default LogService;
