import { CorefyDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendCorefyDepositRequest';
import { CorefyWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendCorefyWithdrawal';

import CorefyInterface from '../../interface/payment/Corefy';

interface CorefyHandler {
    deposit(request: CorefyDepositRequest): void;

    withdraw(request: CorefyWithdrawalRequest): void;
}

class CorefyProvider implements CorefyInterface {
    handler: CorefyHandler;

    constructor(handler: CorefyHandler) {
        this.handler = handler;
    }

    deposit(request: CorefyDepositRequest): void {
        this.handler.deposit(request);
    }

    withdraw(request: CorefyWithdrawalRequest): void {
        this.handler.withdraw(request);
    }
}

export type { CorefyHandler };
export default CorefyProvider;
