import { BehaviorSubject } from 'rxjs';
import PaymentService from 'service/Payment';
import StatusFormPopupService, { StatusForm } from '../../../Deposit/DepositPopup/service/StatusForm';
import WellxPayFormService from './WellxPayForm';


enum WellxPayWithdrawState {
    INIT,
    COMPLETE,
    AWAIT,
    HAS_FEE,
    ACCEPT,
    REJECT,
    BONUS_REJECT,
}

class WellxPayWithdraw {
    state = new BehaviorSubject<WellxPayWithdrawState>(WellxPayWithdrawState.INIT);

    handler = {
        onWithdraw: this.onWithdraw.bind(this),
        onError: this.onError.bind(this),
    };

    constructor() {
        PaymentService.wellxpay.withdrawResult.subscribe(this.handler.onWithdraw);
        PaymentService.wellxpay.error.subscribe(this.handler.onError);
    }

    onWithdraw() {
        this.state.next(WellxPayWithdrawState.ACCEPT);
        StatusFormPopupService.status.next(StatusForm.SUCCESS);
    }

    onError() {
        this.state.next(WellxPayWithdrawState.REJECT);
        StatusFormPopupService.status.next(StatusForm.FAILED);
    }

    reset() {
        this.state.next(WellxPayWithdrawState.INIT);
        StatusFormPopupService.status.next(StatusForm.INIT);
        WellxPayFormService.reset();
    }
}

const WellxPayWithdrawService = new WellxPayWithdraw();

export { WellxPayWithdrawState };
export default WellxPayWithdrawService;
