import { useEffect, useState } from 'react';

// Components
import CountrySelect from 'component/CountrySelect';

// Services
import ChatwootService from 'service/Chatwoot';
import UserData from 'service/model/UserData';
import AuthService from 'service/Auth';
import Storage from 'service/Storage';
import UserTrackService from 'service/UserTrack';
import CountrySupportService from 'service/CountrySupport';
import PopupHelper from 'service/PopupHelper';
import CountriesService from 'service/api/Counties';

// Utils
import { useIsDesktop } from 'utils/useBreakpoints';

import './Chatwoot.scss';

type ChatwootProps = {
    userData?: UserData;
};

const Chatwoot = (props: ChatwootProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const isAuthenticated = AuthService.auth.getValue();
    const isDesktop = useIsDesktop();

    useEffect(() => {
        CountriesService.getList().then(response => {
            CountrySupportService.setCountries(response);
        });
    }, []);

    const handleClick = () => {
        try {
            const currencyFromLink = Storage.get(UserTrackService.STORAGE_CURRENCY_LINK_KEY);
            const countryCode = Storage.get(CountrySupportService.STORAGE_COUNTRY_SUPPORT);
            if (!isAuthenticated && !countryCode && !currencyFromLink) {
                if (isDesktop) {
                    setIsOpen(true);
                } else {
                    PopupHelper.showCountrySelectModal();
                }
            } else {
                if (!ChatwootService.isInitialized) {
                    ChatwootService.init();
                } else {
                    ChatwootService.show();
                }
            }
        } catch (error) {
            if (!isAuthenticated) {
                if (isDesktop) {
                    setIsOpen(true);
                } else {
                    PopupHelper.showCountrySelectModal();
                }
            } else {
                if (!ChatwootService.isInitialized) {
                    ChatwootService.init();
                } else {
                    ChatwootService.show();
                }
            }
        }
    };

    const handleSelect = () => {
        console.log('handleSelect');
        setIsOpen(false);
        setTimeout(() => {
            if (!ChatwootService.isInitialized) {
                ChatwootService.init();
            } else {
                ChatwootService.show();
            }
        }, 100);
    };

    return (
        <div className='Chatwoot__wrapper'>
            <button onClick={handleClick} className='Chatwoot' />
            {isOpen && <CountrySelect onSelect={handleSelect} />}
        </div>
    );
};

export default Chatwoot;

// import BalanceService from 'service/Balance';
// import IntercomService from 'service/Intercom';
// import UserData from 'service/model/UserData';
// import UserDataService from 'service/UserData';
// import useObservable from 'utils/useObservable';

// import './Chatwoot.scss';

// type IntercomProps = {
//     userData?: UserData;
// };

// const Intercom = (props: IntercomProps) => {
//     const balance = useObservable(BalanceService.balance, BalanceService.balance.getValue());
//     const userData = useObservable(UserDataService.data, UserDataService.data.getValue());

//     return (
//         <button onClick={() => IntercomService.show({ userData, balance })} className='Chatwoot' />
//     );
// };

// export default Intercom;
