import Input, { InputSize } from 'component/Input/Input';

import ErrorAdapter from '../../../../../component/Registration/Form/service/ErrorAdapter';
import RxComponent from '../../../../../component/RxComponent/RxComponent';
import RegistrationService, { RegistrationError } from '../../../../../service/Registration';
import WheelRegistrationFormService from '../service/registration';

type EmailProps = {
    type?: string;
};

class EmailWheel extends RxComponent<EmailProps, any> {
    handler = {
        onError: this.onError.bind(this),
    };

    onError(error: RegistrationError) {
        WheelRegistrationFormService.email.error.next(ErrorAdapter.toString(error));
    }

    componentDidMount() {
        this.subscribe(RegistrationService.error, { next: this.handler.onError });
    }

    render() {
        const { type } = this.props;

        return (
            <>
                {type && type !== 'crazytime' && type !== 'roulette' && type !== 'bonanza' && type !== 'olympus' ? (
                    <Input
                        inputService={WheelRegistrationFormService.email}
                        name='email'
                        type='email'
                        size={InputSize.xxsmall}
                    />
                ) : (
                    <Input
                        className={type}
                        inputService={WheelRegistrationFormService.email}
                        name='email'
                        type='email'
                        size={InputSize.small}
                    />
                )}
            </>
        );
    }
}

export default EmailWheel;
