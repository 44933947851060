import Input, { InputSize } from 'component/Input/Input';
import MaskedInput from 'component/Input/MaskedInput';
import RxComponent from 'component/RxComponent/RxComponent';
import SelectEasy from 'component/SelectEasy/SelectEasy';
import { translate as t } from 'service/Language';
import { bankListHavaleHera, defaultBank } from 'service/model/BankTRY';
import { formatCurrencySign } from 'utils/format';
import { createRef } from 'react';
import { HavaleHeraErrorTip, HavaleHeraInputTip } from '../../../../Deposit/HavaleHera/service/AmountForm';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import WithdrawalTip from '../../../Instapay/Form/Card/WithdrawalTip/WithdrawalTip';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import CardFormService from '../../service/CardForm';
import HavaleHeraWithdrawService, { HavaleHeraWithdrawState } from '../../service/HavaleHeraWithdraw';
import WithdrawalButton from '../../WithdrawalButton/WithdrawalButton';
import Back from './image/back.png';
import _paymentMethod from '../../../../PaymentMethod/methods';
import WithdrawService from '../../../service/Withdrawal';
import Attention from '../../../../../../../assets/svg/attention.svg';

import './Card.scss';


export type BankTRY = {
    id: number;
    name: string;
    img?: string;
};

type CardProps = {
    paymentMethod: PaymentMethod;
};
type CardState = {
    value: string;
    tip: HavaleHeraInputTip;
    errorLetters: HavaleHeraErrorTip;
    currentBank: BankTRY;
    error: string,
};

class Card extends RxComponent<CardProps, CardState> {
    state = {
        value: '',
        tip: HavaleHeraInputTip.NONE,
        errorLetters: HavaleHeraErrorTip.NONE,
        currentBank: defaultBank,
        error: '',
    };

    handler = {
        onTip: this.onTip.bind(this),
        onErrorLetters: this.errorLetters.bind(this),
        onSelect: this.onSelect.bind(this),
        getValue: this.getValue.bind(this),
        setBank: this.setBank.bind(this),
        onError: this.onError.bind(this),
        clickOutside: this.clickOutside.bind(this),
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    }

    close() {
        this.setState({errorLetters: HavaleHeraErrorTip.NONE});
    }

    clickOutside(event: PointerEvent) {
        if (this.ref.self.current && !this.ref.self.current.contains(event.target as Node)) {
            this.close();
        }
    }

    onTip(tip: HavaleHeraInputTip) {
        this.setState({ tip });
    };

    errorLetters(errorLetters: HavaleHeraErrorTip) {
        this.setState({ errorLetters });
    }

    onError(error: string) {
        this.setState({ error });
    }

    onSelect(bankOption: BankTRY) {
        CardFormService.onSelectBank(bankOption);
    }

    getValue(value: string) {
        this.setState({ value });
    }

    setBank(bank: BankTRY) {
        this.setState({ currentBank: bank });
    }

    componentDidMount() {
        const { paymentMethod } = this.props;

        CardFormService.setPaymentMethod(paymentMethod);
        this.subscribe(CardFormService.amount.value, { next: this.handler.getValue });
        this.subscribe(CardFormService.bank, { next: this.handler.setBank });
        this.subscribe(CardFormService.tip, { next: this.handler.onTip });
        this.subscribe(CardFormService.errorLetters, { next: this.handler.onErrorLetters });
        this.subscribe(CardFormService.error, { next: this.handler.onError });
        document.addEventListener('pointerdown', this.handler.clickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    render() {
        const { paymentMethod } = this.props;
        const { value, tip, errorLetters, currentBank, error } = this.state;

        const status = HavaleHeraWithdrawService.state.getValue();

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        const fee = WithdrawService.fee.getValue();

        const isInit = status === HavaleHeraWithdrawState.CARD;
        if (fee && isInit) {
            HavaleHeraWithdrawService.state.next(HavaleHeraWithdrawState.HAS_FEE)
        };
        const amountFee = formatCurrencySign(paymentMethod.limit.withdraw.min * 1.25, 0, paymentMethod.currency);
        const allFeeText = `${amountFee} ${t('popup.payment.min.fee')}`;

        return (
            <div className="hera-withdrawal">
                <div className="hera-withdrawal__header">
                    <img src={paymentMethod.icon} alt={paymentMethod.slug} />
                    <h2 className="hera-withdrawal__title">{t(paymentMethod.title)}</h2>
                </div>
                <form className="hera-withdrawal-form" onSubmit={(e) => CardFormService.onSubmit(e)}>
                    <div className="hera-withdrawal-form-block">
                        <Input
                            className="hera-withdrawal-form-block__input"
                            inputService={CardFormService.amount}
                            name="amount"
                            type="number"
                            hint={hint}
                            size={InputSize.medium}
                        />
                        <span className="hera-withdrawal-form-block__currency">{paymentMethod.currency.sign}</span>
                        {!!error ? (
                            <div className="hera-withdrawal-form-block__error">
                                <img src={Attention} alt="attention"/>
                                <span
                                    className="hera-withdrawal-form-block__error_text">
                                {allFeeText}
                            </span>
                            </div>
                        ) : null}
                    </div>

                    <div className="hera-withdrawal-form-block">
                        <Input
                            inputService={CardFormService.name}
                            name="name"
                            size={InputSize.medium}
                        />
                        {tip === HavaleHeraInputTip.NAME ?(
                            <span className="hera-withdrawal-form-block__help">
                                {t('popup.payment.name.hint')}
                            </span>
                        ): null}
                        {errorLetters === HavaleHeraErrorTip.NAME ?
                            <div className="hera-withdrawal-form-block-letters" ref={this.ref.self}>
                                <img className="hera-withdrawal-form-block-letters__img" src={Back} alt="back"/>
                                <span
                                    className="hera-withdrawal-form-block-letters__text">{t('popup.payment.turkey.error.letters')}</span>
                            </div> : null}
                    </div>
                    <div className="hera-withdrawal-form-block">
                        <Input
                            className="hera-withdrawal-form-block__input"
                            inputService={CardFormService.surname}
                            name="surname"
                            size={InputSize.medium}
                        />
                        {tip === HavaleHeraInputTip.SURNAME && (
                            <span className="hera-withdrawal-form-block__help">
                                {t('popup.payment.name.hint')}
                            </span>
                        )}
                        {errorLetters === HavaleHeraErrorTip.SURNAME ?
                            <div className="hera-withdrawal-form-block-letters" ref={this.ref.self}>
                                <img className="hera-withdrawal-form-block-letters__img" src={Back} alt="back"/>
                                <span
                                    className="hera-withdrawal-form-block-letters__text">{t('popup.payment.turkey.error.letters')}</span>
                            </div> : null}
                    </div>
                    <div className="hera-withdrawal-form-block">
                        <SelectEasy
                            onSelect={this.onSelect}
                            list={bankListHavaleHera}
                            current={currentBank}
                            classList="hera-withdrawal__list"
                        />
                        {tip === HavaleHeraInputTip.BANK && (
                            <span className="hera-withdrawal-form-block__help">
                                {t('profile.balance.withdraw.input.help.bank')}
                            </span>
                        )}
                    </div>
                    <div className="hera-withdrawal-form-block">
                        <MaskedInput
                            className="hera-withdrawal-form-block__input"
                            placeholder="TRXXXXXXXXXXXXXXXXXXXXXXXX"
                            mask="TR000000000000000000000000"
                            inputService={CardFormService.iban}
                            name="iban"
                            size={InputSize.medium}
                        />
                        {tip === HavaleHeraInputTip.IBAN && (
                            <span className="hera-withdrawal-form-block__help">
                                {t('profile.balance.withdraw.input.iban.help')}
                            </span>
                        )}
                        <WithdrawalTip type="iban" up />
                    </div>
                    {(status === HavaleHeraWithdrawState.HAS_FEE || status === HavaleHeraWithdrawState.AWAIT) && fee ? <FeeNotice amount={value} fee={fee} />: null}
                    {status === HavaleHeraWithdrawState.HAS_FEE || status === HavaleHeraWithdrawState.AWAIT ? (
                        <>
                            <WithdrawalButton
                                buttonTitle="profile.balance.withdraw.fee.agree"
                                variation={true}
                                paymentMethod={paymentMethod}
                                amount={value}
                            />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel" />
                        </>
                    ) : (
                        <WithdrawalButton
                            buttonTitle="profile.balance.withdraw.button"
                            paymentMethod={_paymentMethod.HavaleHera}
                        />
                    )}
                </form>
            </div>
        );
    }
}

export default Card;
