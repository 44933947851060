import classNames from 'classnames';
import { BankTRY } from 'page/Profile/Balance/Withdrawal/Instapay/Form/Card/Card';
import React, { useEffect, useRef, useState } from 'react';
import { translate as t } from 'service/Language';
import SvgImage from '../../svg';

import './SelectEasy.scss';

export enum SelectSize {
    average,
    small,
}

type SelectProps = {
    className?: string;
    list: BankTRY[];
    onSelect: (option: BankTRY) => void;
    current: any | null;
    placeholder?: string;
    disabled?: boolean;
    size?: SelectSize;
    classList?: string;
};

function SelectEasy({
    list,
    current,
    onSelect,
    className,
    placeholder = '',
    size = SelectSize.average,
    disabled = false,
    classList,
}: SelectProps) {
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef<HTMLDivElement>(null);

    const sizeMap = new Map<SelectSize, string>([[SelectSize.small, 'component-select____small']]);

    const sizeClass = sizeMap.get(size) || 'component-select____unknown';

    const _className = classNames(className, 'component-select', {
        'component-select____open': isOpen,
        'component-select____empty': current === null,
        'component-select____disabled': disabled,
        [sizeClass]: true,
    });

    const classNameList = classNames( classList, 'component-select__list', {
    });

    useEffect(() => {
        const listener = (event: PointerEvent | MouseEvent | TouchEvent): void => {
            if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        document.addEventListener('pointerdown', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
            document.removeEventListener('pointerdown', listener);
        };
    }, [inputRef]);

    return (
        <div className={_className} ref={inputRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="component-select__toggle"
                disabled={disabled}
            >
                <div className="component-select__value">{current ? t(current.name) : placeholder}</div>
                <SvgImage name="SelectArrow" />
            </button>

            <div className={classNameList}>
                {list.map((option: BankTRY) => {
                    const containerClassName = classNames({
                        'component-select__option': true,
                        'component-select__option__active': option === current,
                    });

                    return (
                        <button
                            onClick={() => {
                                onSelect(option);
                                setIsOpen(false);
                            }}
                            key={option.id}
                            type="button"
                            className={containerClassName}
                        >
                            <div>{option.name}</div>
                            {option.img ? <img src={option.img} alt="logoBank" /> : null}
                        </button>
                    );
                })}
            </div>
        </div>
    );
}

export default SelectEasy;
