import { useRef } from 'react';
import useAspectRatio from 'utils/useAspectRatio';

import GameProvider from '../../../../service/model/GameProvider';

import './Provider.scss';

type ProviderProps = {
    provider: GameProvider;
};

function Provider(props: ProviderProps) {
    const { provider } = props;

    const providerRef = useRef<HTMLDivElement>(null);
    useAspectRatio({ ratio: 3, element: providerRef });

    return (
        <div className="provider" ref={providerRef}>
            <img className="provider__image" src={provider.image} alt={provider.name} />
        </div>
    );
}

export default Provider;
