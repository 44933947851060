import RouteConfig, { QUERY_PARAMS_VALUE } from 'appConstants/RouteConfig';
import CategoryHeader from 'component/CategoryHeader';
import GamesService from 'service/Games';
import useObservable from 'utils/useObservable';
import useQuery from 'utils/useQuery';

import GameList from '../GameList/GameList';

import './CustomProvider.scss';

function CustomProvider() {
    const games = useObservable(GamesService.games, GamesService.games.getValue());

    const query = useQuery();
    const providersQuery = query.get('providers') || '';
    const providerList = QUERY_PARAMS_VALUE[providersQuery]
        .split(',')
        .map((name) => GamesService.findProviderByName(name));

    const filteredGames = games.filter((game) => providerList.includes(game.provider));
    const title = providerList.map((provider) => (provider ? provider.name : '')).join(', ');

    return (
        <div>
            <CategoryHeader title={title} backUrl={RouteConfig.casino.root} />
            <GameList games={filteredGames} />
        </div>
    );
}

export default CustomProvider;
