/* eslint-disable max-len */

import { Icon } from 'types';

function CasinoOther({ color, width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0)">
                <path
                    d="M23.0001 15.9998C23.5524 15.9998 24.0001 15.5521 24.0001 14.9998C24.0001 14.4475 23.5524 13.9998 23.0001 13.9998C22.4478 13.9998 22.0001 14.4475 22.0001 14.9998C22.0001 15.5521 22.4478 15.9998 23.0001 15.9998Z"
                    fill={color ?? 'url(#paint0_linear)'}
                />
                <path
                    d="M17.9999 6.99976C18.5522 6.99976 18.9999 6.55205 18.9999 5.99976C18.9999 5.44748 18.5522 4.99976 17.9999 4.99976C17.4476 4.99976 16.9999 5.44748 16.9999 5.99976C16.9999 6.55205 17.4476 6.99976 17.9999 6.99976Z"
                    fill={color ?? 'url(#paint1_linear)'}
                />
                <path
                    d="M1 15.9998C1.55228 15.9998 2 15.5521 2 14.9998C2 14.4475 1.55228 13.9998 1 13.9998C0.447715 13.9998 0 14.4475 0 14.9998C0 15.5521 0.447715 15.9998 1 15.9998Z"
                    fill={color ?? 'url(#paint2_linear)'}
                />
                <path
                    d="M5.49985 20.9999C5.49985 21.1325 5.55253 21.2597 5.64629 21.3535C5.74006 21.4473 5.86724 21.4999 5.99985 21.4999H15.9998C16.1324 21.4999 16.2596 21.4473 16.3534 21.3535C16.4472 21.2597 16.4998 21.1325 16.4998 20.9999V18.9999H5.49985V20.9999Z"
                    fill={color ?? 'url(#paint3_linear)'}
                />
                <path
                    d="M22.9227 13.9018C23.0007 13.844 23.0602 13.7648 23.094 13.6738C23.1277 13.5828 23.1343 13.4839 23.1128 13.3893C22.7297 11.6983 21.168 8.49991 18.0002 8.49991C15.6822 8.49991 14.4397 9.59616 13.8958 10.2682C12.7553 9.24028 12.4307 8.04791 13.0104 6.94141C13.6545 5.71191 15.2053 5.05391 16.467 5.47428C16.5609 5.50558 16.6619 5.50834 16.7573 5.48223C16.8526 5.45611 16.9382 5.40229 17.003 5.32757C17.0678 5.25285 17.109 5.16059 17.1213 5.06246C17.1337 4.96433 17.1167 4.86474 17.0724 4.77628C16.0029 2.63741 13.6414 1.12041 10.7765 2.55266C7.79366 4.04416 7.35916 7.47203 7.38453 9.50166C6.27416 8.47291 4.60253 7.63978 2.77653 8.55266C0.526031 9.67803 0.500155 13.2249 0.500155 13.3749C0.500155 13.4902 0.540004 13.602 0.612956 13.6913C0.685908 13.7806 0.787479 13.8419 0.900467 13.8649C1.01345 13.8879 1.13092 13.8711 1.23296 13.8174C1.335 13.7637 1.41535 13.6764 1.46041 13.5703C1.46453 13.5607 1.88203 12.6038 2.63666 12.3274C2.98666 12.1993 3.35928 12.2384 3.77653 12.4472C5.18766 13.1529 5.49928 16.6949 5.50016 17.9999H16.5002C16.5015 16.9722 16.814 14.152 18.2238 13.4472C19.9563 12.5807 22.3535 13.9208 22.3772 13.934C22.4614 13.9822 22.5578 14.0047 22.6547 13.999C22.7516 13.9933 22.8447 13.9595 22.9227 13.9018Z"
                    fill={color ?? 'url(#paint4_linear)'}
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="0.545457"
                    y1="6.274"
                    x2="20.229"
                    y2="12.0532"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="0.545457"
                    y1="6.274"
                    x2="20.229"
                    y2="12.0532"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="0.545457"
                    y1="6.274"
                    x2="20.229"
                    y2="12.0532"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="0.545457"
                    y1="6.274"
                    x2="20.229"
                    y2="12.0532"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear"
                    x1="0.545457"
                    y1="6.274"
                    x2="20.229"
                    y2="12.0532"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default CasinoOther;
