/* eslint-disable max-len */

import { Icon } from 'types';

function ActiveSearch({ color = '#3F4250', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
                <rect x="6" y="6" width="6" height="6" rx="1" fill="#3959D9" />
                <rect x="6" y="16" width="6" height="6" rx="1" fill="#9FA0A8" />
                <rect x="16" y="6" width="6" height="6" rx="1" fill="#9FA0A8" />
                <rect x="16" y="16" width="6" height="6" rx="1" fill="#9FA0A8" />
            </g>
            <defs>
                <filter
                    id="filter0_d"
                    x="-3"
                    y="-1"
                    width="34"
                    height="34"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}

export default ActiveSearch;
