import { SvgImageProps } from '../types';
import icons from './icons';

function SvgImage(props: SvgImageProps) {
    const { name, ...rest } = props;

    const Icon = icons[name];

    return <Icon {...rest} />;
}

export default SvgImage;
