import { BehaviorSubject } from 'rxjs';
import PaymentService from 'service/Payment';
import StatusFormPopupService, { StatusForm } from '../../../Deposit/DepositPopup/service/StatusForm';
import IMPSFormService from './IMPSForm';

enum ConstantposWithdrawState {
    INIT,
    COMPLETE,
    AWAIT,
    HAS_FEE,
    ACCEPT,
    REJECT,
    BONUS_REJECT,
}

class ConstantposWithdraw {
    state = new BehaviorSubject<ConstantposWithdrawState>(ConstantposWithdrawState.INIT);

    handler = {
        onWithdraw: this.onWithdraw.bind(this),
        onError: this.onError.bind(this),
    };

    constructor() {
        PaymentService.constantpos.withdrawResult.subscribe(this.handler.onWithdraw);
        PaymentService.constantpos.error.subscribe(this.handler.onError);
    }

    onWithdraw() {
        this.state.next(ConstantposWithdrawState.ACCEPT);
        StatusFormPopupService.status.next(StatusForm.SUCCESS);
    }

    onError() {
        this.state.next(ConstantposWithdrawState.REJECT);
        StatusFormPopupService.status.next(StatusForm.FAILED);
    }

    reset() {
        this.state.next(ConstantposWithdrawState.INIT);
        StatusFormPopupService.status.next(StatusForm.INIT);
        IMPSFormService.reset();
    }
}

const ConstantposWithdrawService = new ConstantposWithdraw();

export { ConstantposWithdrawState };
export default ConstantposWithdrawService;
