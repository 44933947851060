import { translate as t } from 'service/Language';

import Input from '../../../../../../../component/Input/Input';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { formatCurrencySign } from '../../../../../../../utils/format';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import Attention from '../../../../../../../assets/svg/attention.svg';

import PaykassmaWithdrawService, {PaykassmaWithdrawState} from '../../../Paykassma/service/PaykassmaWithdraw';
import IMPSFormService, {ApayTipIMPS} from '../service/IMPSForm';
import {AmountError} from '../../../../Deposit/Directa24/Pix/service/AmountForm';
import WithdrawalButtonApayIMPS from './WithdrawalButtonApayIMPS/WithdrawalButtonApayIMPS';
import WithdrawService from '../../../service/Withdrawal';
import AttentionWithdrawalTime from '../../../../../../../component/AttentionWithdrawalTime/AttentionWithdrawalTime';

import './IMPS.scss';



type IMPSProps = {
    paymentMethod: PaymentMethod;
    status: PaykassmaWithdrawState;
};
type IMPSState = {
    value: string;
    tip: ApayTipIMPS,
    amountLimit: AmountError,
    errorAmount: string | null,
    errorCode: string | null,
};

class IMPS extends RxComponent<IMPSProps, IMPSState> {
    state = {
        value: '',
        tip: ApayTipIMPS.NOERROR,
        amountLimit: AmountError.NONE,
        errorAmount: null,
        errorCode: null,
    };

    handler = {
        getValue: this.getValue.bind(this),
        onTip: this.onTip.bind(this),
        onAmountLimit: this.onAmountLimit.bind(this),
        onErrorAmount: this.onErrorAmount.bind(this),
        onErrorCode: this.onErrorCode.bind(this),
    };

    onTip(tip: ApayTipIMPS) {
        this.setState({tip});
    }

    onErrorAmount(errorAmount: string | null) {
        this.setState({errorAmount});
    }

    onAmountLimit(amountLimit: AmountError) {
        this.setState({amountLimit});
    }

    onErrorCode(errorCode: string | null) {
        this.setState({errorCode});
    }

    getValue(value: string) {
        this.setState({value});
    }

    componentDidMount() {
        const {paymentMethod} = this.props;
        IMPSFormService.setPaymentMethod(paymentMethod);

        this.subscribe(IMPSFormService.tip, {next: this.handler.onTip});
        this.subscribe(IMPSFormService.amount.value, {next: this.handler.getValue});
        this.subscribe(IMPSFormService.amount.error, {next: this.handler.onErrorAmount});
        this.subscribe(IMPSFormService.amountLimit, {next: this.handler.onAmountLimit});
        this.subscribe(IMPSFormService.bankCode.error, {next: this.handler.onErrorCode});
    }

    render() {
        const {paymentMethod, status} = this.props;
        const { value, tip, amountLimit, errorAmount, errorCode } = this.state;

        const min = formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency);
        const max = formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency);
        const hint = `${t('popup.payment.amount.min')} ${min} — ${t('popup.payment.amount.max')} ${max}`;

        const fee = WithdrawService.fee.getValue();

        const isInit = status === PaykassmaWithdrawState.INIT;
        if (fee && isInit) {
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.HAS_FEE)
        }
        const minFee = Math.ceil(paymentMethod.limit.withdraw.min * 1.25)
        const amountFee = formatCurrencySign(minFee, 0, paymentMethod.currency);
        const allFeeText = `${amountFee} ${t('popup.payment.min.fee')}`;

        return (
            <div className="imps-withdrawal">
                <div className="imps-withdrawal-header">
                    <img src={paymentMethod.icon} alt={paymentMethod.slug}/>
                    <h2 className="imps-withdrawal-header__title">{t(paymentMethod.title)}</h2>
                </div>
                <form className="imps-withdrawal-form" onSubmit={(e) => IMPSFormService.onSubmit(e)}>
                    <div className="imps-withdrawal-form-block">
                        <Input
                            className="imps-withdrawal-form-block__input"
                            inputService={IMPSFormService.amount}
                            name="amount"
                            type="number"
                        />
                        <span className="imps-withdrawal-form-block__currency">{paymentMethod.currency.sign}</span>
                        {!!errorAmount ? (
                            <div className="imps-withdrawal-form-block__error">
                                <img src={Attention} alt="attention"/>
                                <span className="imps-withdrawal-form-block__error_text">
                                    {amountLimit && amountLimit === AmountError.AMOUNT_MIN ? t('popup.payment.amount.min') + ` ${min}` : null}
                                    {fee && amountLimit === AmountError.AMOUNT_FEE ? allFeeText : null}
                                    {amountLimit && amountLimit === AmountError.AMOUNT_MAX ? t('popup.payment.amount.max') + ` ${max}` : null}
                                    {amountLimit && amountLimit === AmountError.AMOUNT_WITHDRAWAL ? t('popup.payment.amount.withdrawal.max') : null}
                                    </span>
                            </div>
                        ) : null}
                        {tip === ApayTipIMPS.AMOUNT && !errorAmount ? (
                            <span className="imps-withdrawal-form-block__tip">
                                {hint}
                            </span>
                        ) : null}
                    </div>
                    <div className="imps-withdrawal-form-block">
                        <MaskedInput
                            className="imps-withdrawal-form-block__input"
                            mask="00000000000000000000"
                            inputService={IMPSFormService.accountNumber}
                            name="accountNumber"
                        />
                    </div>
                    <div className="imps-withdrawal-form-block">
                        <Input
                            className="imps-withdrawal-form-block__input"
                            inputService={IMPSFormService.accountName}
                            name="name"
                        />
                        {tip === ApayTipIMPS.NAME && (
                            <span className="imps-withdrawal-form-block__tip">
                                {t('popup.balance.deposit.input.name')}
                            </span>
                        )}
                    </div>
                    <div className="imps-withdrawal-form-block">
                        <Input
                            className="imps-withdrawal-form-block__input"
                            inputService={IMPSFormService.bankCode}
                            name="bankCode"
                            placeholder="AAAA0XXXXXX"
                            isUpperCase
                        />
                        {tip === ApayTipIMPS.CODE && !errorCode && (
                            <span className="imps-withdrawal-form-block__tip">
                                {t('popup.payment.imps.ifsc.hint')}
                            </span>
                        )}
                        {!!errorCode ? (
                            <div className="pix-withdrawal-form-block__error">
                                <img src={Attention} alt="attention"/>
                                <span className="pix-withdrawal-form-block__error_text">
                                    {t('popup.payment.imps.ifsc.error')}
                                    </span>
                            </div>
                        ) : null}
                    </div>

                    {((status === PaykassmaWithdrawState.HAS_FEE || status === PaykassmaWithdrawState.AWAIT) && fee) ?
                        <FeeNotice amount={value} fee={fee}/> : null}
                    <AttentionWithdrawalTime />

                    {(status === PaykassmaWithdrawState.HAS_FEE || status === PaykassmaWithdrawState.AWAIT) ? (
                        <>
                            <WithdrawalButtonApayIMPS
                                buttonTitle="profile.balance.withdraw.fee.agree"
                                variation={true}
                                paymentMethod={paymentMethod}
                                amount={value}
                            />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel"/>
                        </>
                    ) : (
                        <WithdrawalButtonApayIMPS buttonTitle="profile.balance.withdraw.button" />
                    )}
                </form>
            </div>
        );
    }
}

export default IMPS;
