import { Operation } from '@ay_tsarbet/newbet-core/dist/types';
import { useEffect } from 'react';
import { translate as t } from 'service/Language';
import OperationsService from 'service/Operations';

import NotificationItem from '../NotificationItem/NotificationItem';

import './NotificationDesktop.scss';

type NotificationDesktopProps = {
    isOpen: boolean;
    NotificationList: Operation[];
};

function NotificationDesktop({ isOpen, NotificationList }: NotificationDesktopProps) {
    useEffect(() => {
        OperationsService.requestHistory();
    }, []);

    const sortedNotificationList = NotificationList.sort((a, b) => b.creationDate - a.creationDate);
    if (!isOpen) return null;

    return (
        <>
            <div className="notification-desktop-triangle" />
            <div className="notification-desktop-wrapper">
                <div className="notification-desktop">
                    <div className="notification-desktop__content">
                        <div className="notification-desktop__title">{t('notification.page.notification')}</div>
                        <hr className="notification-desktop__hr" />
                        <div className="notification-desktop__items" />
                        {sortedNotificationList.length ? (
                            sortedNotificationList.map((item) => {
                                return (
                                    <NotificationItem
                                        key={item.id}
                                        type={item.direction}
                                        amount={item.amount}
                                        datetime={item.creationDate}
                                        isRead={item.notice}
                                        currency={item.currency}
                                    />
                                );
                            })
                        ) : (
                            <div className="notification-desktop__items-not">{t('notification.item.not.exist')}</div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotificationDesktop;
