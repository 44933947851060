import { useEffect } from 'react';
import { translate as t } from 'service/Language';

import GoogleService from '../../../../../../service/Google';
import OperationsService from '../../../../../../service/Operations';
import useObservable from '../../../../../../utils/useObservable';
import { PaymentMethod } from '../../../PaymentMethod/types';
import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import FailedPage from '..//ResultPage/FailedPage/FailedPage';
import PaykassmaDepositService, { DepositState, InvoiceState } from '../service/PaykassmaDeposit';
import Amount from './Amount/Amount';

import './Form.scss';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(PaykassmaDepositService.state, PaykassmaDepositService.state.getValue());
    const transactionState = useObservable(
        PaykassmaDepositService.transaction,
        PaykassmaDepositService.transaction.getValue()
    );

    useEffect(()=>{
        PaykassmaDepositService.reset()
    },[]);

    useEffect(() => {
        const gaSub = OperationsService.invoice.subscribe({
            next: (data) => {
                const clientId = GoogleService.getClientId();
                const invoiceId = data.id.toString();
                OperationsService.sendGAClientRelation(clientId, invoiceId);
            },
        });
        return () => {
            gaSub.unsubscribe();
        };
    }, []);

    return (
        <div className="deposit-form">
            {state !== DepositState.SUCCESS &&
                transactionState !== InvoiceState.SUCCESS &&
                state !== DepositState.FAILED &&
                transactionState !== InvoiceState.FAILED && (
                    <div className="deposit-form__header">
                        <img className="deposit-form__img" src={paymentMethod.icon} alt={paymentMethod.title} />
                        <h2 className="deposit-form__title">{t(paymentMethod.title)}</h2>
                    </div>
                )}
            {state !== DepositState.FAILED && state !== DepositState.SUCCESS && (
                <Amount paymentMethod={paymentMethod} lock={state !== DepositState.AMOUNT} />
            )}

            {state === DepositState.SUCCESS || transactionState === InvoiceState.SUCCESS ? <SuccessPage /> : null}
            {state === DepositState.FAILED || transactionState === InvoiceState.FAILED ? <FailedPage /> : null}
        </div>
    );
}

export default Form;
