import useObservable from 'utils/useObservable';

import InstapayWithdrawService, { InstapayWithdrawState } from '../service/InstapayWithdraw';

import FailedPage from '../FailedPage/FailedPage';
import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import BonusFailedPage from '../../../../../../component/BonusFailedPage/BonusFailedPage';

import './ResultForm.scss';


function ResultForm() {
    const state = useObservable(InstapayWithdrawService.state, InstapayWithdrawService.state.getValue());

    switch (state) {
        case InstapayWithdrawState.ACCEPT: {
            return <SuccessPage />;
        }
        case InstapayWithdrawState.REJECT: {
            return <FailedPage />;
        }
        case InstapayWithdrawState.BONUS_REJECT: {
            return <BonusFailedPage />;
        }
        default: {
            return null;
        }
    }
}

export default ResultForm;
