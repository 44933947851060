import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';

import AuthService from '../../../../../../service/Auth';
import GoogleService from '../../../../../../service/Google';
import { translate as t } from '../../../../../../service/Language';
import Validator from '../../../../../../service/Validator/Validator';
import { PaymentMethod } from '../../../PaymentMethod/types';
import DepositService from './CorefyDeposit';
import {OnlyLetterService} from '../../../../../../service/Validator/OnlyLetter';
import {CheckAzerLettersService} from '../../../../../../service/Validator/CheckAzerLetters';
import {ChannelType, Currency as CurrencyIso} from '@ay_tsarbet/newbet-core/dist/types';

export enum CorefyInputTip {
    NONE,
    NAME,
    SURNAME,
}

export enum CorefyLettersTip {
    NONE,
    NAME,
    SURNAME,
}

class AmountForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(1, 1000000);

    amount = new InputService({
        title: 'profile.balance.deposit.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.deposit.input.amount.invalid',
    });

    name = new InputService({
        title: 'popup.payment.pix.name',
        validator: Validator.OnlyLetter,
        validatorText: 'popup.payment.pix.name',
    });

    surname = new InputService({
        title: 'popup.payment.pix.surname',
        validator: Validator.OnlyLetter,
        validatorText: 'popup.payment.pix.surname',
    });

    tip = new BehaviorSubject<CorefyInputTip>(CorefyInputTip.NONE);

    errorLetters = new BehaviorSubject<CorefyLettersTip>(CorefyLettersTip.NONE);

    valid = new BehaviorSubject<boolean>(false);

    handler = {
        onAmount: this.onAmount.bind(this),
        onName: this.onName.bind(this),
        onNameActive: this.onNameActive.bind(this),
        onSurname: this.onSurname.bind(this),
        onSurnameActive: this.onSurnameActive.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.name.value.subscribe(this.handler.onName);
        this.name.active.subscribe(this.handler.onNameActive);
        this.surname.value.subscribe(this.handler.onSurname);
        this.surname.active.subscribe(this.handler.onSurnameActive);
    }

    setLimit(min: number, max: number) {
        if (this.limitValidator) {
            this.limitValidator.setLimit(min, max);
        }
    }

    setValue(value: number) {
        this.amount.onFocus();
        this.amount.value.next(value.toString());
    }

    setMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        this.setLimit(paymentMethod.limit.deposit.min, paymentMethod.limit.deposit.max);
    }

    onAmount(value: string) {
        this.checkValid();
        if (!this.limitValidator.validate(value) && this.amount.getValue()) {
            this.amount.error.next(t('profile.balance.withdraw.input.error'));
            this.valid.next(false);
        } else {
            this.amount.error.next(null);
        }
    }

    onName(value: string) {
        if (this.name.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.name.value.next(name);
            this.valid.next(false);
            if (CheckAzerLettersService.validate(value)) {
                this.errorLetters.next(CorefyLettersTip.NAME)
            } else {
                this.errorLetters.next(CorefyLettersTip.NONE)
            }

        } else {
            this.name.error.next(null);
            this.errorLetters.next(CorefyLettersTip.NONE)
        }
        this.checkValid();
    }

    onNameActive(active: boolean) {
        if (active) {
            this.tip.next(CorefyInputTip.NAME);
        } else {
            this.tip.next(CorefyInputTip.NONE);
        }
    }

    onSurname(value: string) {
        if (this.surname.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.surname.value.next(name);
            this.valid.next(false);
            if (CheckAzerLettersService.validate(value)) {
                this.errorLetters.next(CorefyLettersTip.SURNAME)
            } else {
                this.errorLetters.next(CorefyLettersTip.NONE)
            }
        } else {
            this.name.error.next(null);
            this.errorLetters.next(CorefyLettersTip.NONE)
        }
        this.checkValid();
    }

    onSurnameActive(active: boolean) {
        if (active) {
            this.tip.next(CorefyInputTip.SURNAME);
        } else {
            this.tip.next(CorefyInputTip.NONE);
        }
    }

    checkValid() {
        if(this.paymentMethod) {
            const isAmount = this.amount.isValid();
            if(this.paymentMethod.currency.ISO === CurrencyIso.AZN) {
                const isName = this.name.isValid();
                const isSurname = this.surname.isValid();
                this.valid.next(isAmount && isName && isSurname);
            } else {
                this.valid.next(isAmount);
            }
        }
    }

    reset() {
        this.amount.value.next('');
        this.name.value.next('');
        this.surname.value.next('');

        this.amount.error.next(null);
        this.name.error.next(null);
        this.surname.error.next(null);

        this.valid.next(false);
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const valid = this.amount.isValid();

        if (valid && this.paymentMethod) {
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            const amount = parseFloat(this.amount.getValue());
            const name = this.name.getValue();
            const surname = this.surname.getValue();

            GoogleService.sentDepositAmount(
                userId,
                this.paymentMethod.title,
                amount.toString(),
                this.paymentMethod.currency.name
            );

            DepositService.request(this.paymentMethod, name, surname, amount);
        }
    }
}

const AmountFormService = new AmountForm();

export default AmountFormService;
