import Button from './Button';
import List from './List';

import './Sort.scss';

function Sort() {
    return (
        <div className="game-sort">
            <Button />
            <List className="game-sort__list" />
        </div>
    );
}

export default Sort;
