/* eslint-disable max-len */

import { Icon } from 'types';

function OpenEye({ color = '#3F4250', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.50457 8.89576C8.29193 7.31502 10.7992 5.88333 14.0006 5.88333C17.202 5.88333 19.7084 7.31504 21.4967 8.89667C23.2799 10.4734 24.3952 12.2371 24.8437 13.0247C24.9891 13.2772 25.0656 13.5633 25.0656 13.8546C25.0656 14.1459 24.9891 14.4321 24.8437 14.6845C24.3952 15.4721 23.2799 17.2358 21.4967 18.8135C19.7093 20.3942 17.202 21.8259 14.0006 21.8259C10.7992 21.8259 8.29285 20.3942 6.50463 18.8136C4.72137 17.2377 3.60604 15.4731 3.15752 14.6855C3.01213 14.433 2.93561 14.1469 2.93561 13.8556C2.93561 13.5642 3.01214 13.2781 3.15752 13.0256C3.60607 12.238 4.72144 10.4743 6.50457 8.89576ZM14.0006 7.65474C11.3624 7.65474 9.25452 8.827 7.67851 10.2238C6.13128 11.5913 5.13877 13.135 4.72379 13.8551C5.13875 14.5751 6.13106 16.1178 7.67835 17.4863C9.25542 18.8814 11.3625 20.0545 14.0006 20.0545C16.6388 20.0545 18.7467 18.8814 20.3228 17.4863C21.8704 16.1176 22.8629 14.5745 23.2777 13.8547C22.8628 13.1355 21.8704 11.5916 20.3229 10.223C18.7458 8.82789 16.6388 7.65474 14.0006 7.65474ZM11.5965 11.4505C12.2341 10.8129 13.0989 10.4547 14.0006 10.4547C14.9023 10.4547 15.7671 10.8129 16.4047 11.4505C17.0423 12.0882 17.4006 12.953 17.4006 13.8547C17.4006 14.3012 17.3126 14.7433 17.1417 15.1558C16.9709 15.5683 16.7204 15.9431 16.4047 16.2588C16.089 16.5745 15.7142 16.825 15.3017 16.9958C14.8892 17.1667 14.4471 17.2546 14.0006 17.2546C13.5541 17.2546 13.112 17.1667 12.6995 16.9958C12.287 16.825 11.9122 16.5745 11.5965 16.2588C11.2808 15.9431 11.0303 15.5683 10.8595 15.1558C10.6886 14.7433 10.6007 14.3012 10.6007 13.8547C10.6007 12.953 10.9589 12.0882 11.5965 11.4505Z"
                fill={color}
            />
        </svg>
    );
}

export default OpenEye;
