import RouteConfig from 'appConstants/RouteConfig';
import { HistoryService } from 'service/HistoryService';
import { translate as t } from 'service/Language';
import PopupService from '../../service/Popup';
import Sent from './images/sent.webp';

import back from '../../page/Profile/Balance/Deposit/DepositPopup/image/back.svg';
import Close from '../Close/Close';
import UserDataService from '../../service/UserData';
import ChangeEmail from '../../page/Profile/Account/PersonalData/ChangeEmail/ChangeEmail';
import classNames from 'classnames';
import useObservable from '../../utils/useObservable';

import './SuccessSentEmailPopup.scss';


type SuccessSentEmailPopupProps = {
    play?: boolean;
}

function SuccessSentEmailPopup({play}: SuccessSentEmailPopupProps) {

    function onClickChange() {
        PopupService.show(<ChangeEmail/>);
    }

    function onClickPlay() {
        PopupService.close();
        HistoryService.history.push(RouteConfig.casino.root);
    }

    const user = useObservable(UserDataService.data, UserDataService.data.getValue());

    const PlayClass = classNames('SuccessSentEmailPopup-container__btn', {
        'SuccessSentEmailPopup-container__btn_play': play
    })
    return (
        <div className="SuccessSentEmailPopup">
            <div className="SuccessSentEmailPopup-header">
                <button className="SuccessSentEmailPopup-header__back" onClick={(e) => HistoryService.history.goBack()}>
                    <img className="SuccessSentEmailPopup-header__img" src={back} alt="back"/>
                </button>
                <div className="SuccessSentEmailPopup-header__title">
                    {t('check.email.thank')}
                </div>
                <Close size="s"/>
            </div>
            <div className="SuccessSentEmailPopup-container">
                <>
                    <img className="SuccessSentEmailPopup-container__img" src={Sent} alt="sent"/>
                    <div className="SuccessSentEmailPopup-container-desc">
                        <span
                            className="SuccessSentEmailPopup-container-desc-email__title">{t('check.email.sent')}</span>&nbsp;
                        <span
                            className="SuccessSentEmailPopup-container-desc-email__desc">{user ? user.email: ''}
                    </span>
                        <br/>
                        <div className="SuccessSentEmailPopup-container-desc__check">{t('check.email.check')}</div>
                    </div>
                </>
                <>
                    {!play ? <button className="SuccessSentEmailPopup-container__change" onClick={onClickChange}>
                        {t('check.email.change')}
                    </button> : null}
                    <button className={PlayClass} onClick={onClickPlay}>
                        {t('profile.game.button')}
                    </button>
                </>
            </div>
        </div>
    );
}

export default SuccessSentEmailPopup;
