import ABB from '../../page/Profile/Balance/Withdrawal/Azarpay/images/ABB.png';
import AccessBank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/AccessBank.png';
import AFBBANK from '../../page/Profile/Balance/Withdrawal/Azarpay/images/AFBBANK.png';
import ASBBank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/ASBBank.png';
import AzerTurkBank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/AzerTurkBank.png';
import BankAvrasiya from '../../page/Profile/Balance/Withdrawal/Azarpay/images/BankAvrasiyaAZE.png';
import BankBTB from '../../page/Profile/Balance/Withdrawal/Azarpay/images/BankBTB.png';
import BankofBaku from '../../page/Profile/Balance/Withdrawal/Azarpay/images/BankofBaku.png';
import BankRespublika from '../../page/Profile/Balance/Withdrawal/Azarpay/images/BankRespublika.png';
import ExpressBank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/ExpressBank.png';
import GunaiBank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/GunaiBank.png';
import KapitalBank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/KapitalBank.png';
import Leonbank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/Leonbank.png';
import Muganbank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/Muganbank.png';
import Naxcivanbank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/Naxcivanbank.png';
import PASABank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/PASABank.png';
import PremiumBank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/PremiumBank.png';
import Rabitabank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/Rabitabank.png';
import Turanbank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/Turanbank.png';
import Unibank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/Unibank.png';
import XalqBank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/XalqBank.png';
import YapiKrediBank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/YapiKrediBankAze.png';
import YeloBank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/YeloBank.png';
import ZiraatBank from '../../page/Profile/Balance/Withdrawal/Azarpay/images/ZiraatBankAze.png';
import { BankType } from './BankUpay';

export type BankAZN = {
    id: number;
    name: string;
    img?: string;
};

class BankCardsAZN {
    public id = 0;

    public name = '';

    constructor(data: BankType) {
        Object.assign(this, data);
    }
}

const BANK_LIST_AZN: BankType[] = [
    {
        id: 1,
        name: 'KapitalBank',
        img: KapitalBank,
    },
    {
        id: 2,
        name: 'Unibank',
        img: Unibank,
    },
    {
        id: 3,
        name: 'Azer Turk Bank',
        img: AzerTurkBank,
    },
    {
        id: 4,
        name: 'PASA Bank',
        img: PASABank,
    },
    {
        id: 5,
        name: 'Express Bank',
        img: ExpressBank,
    },
    {
        id: 6,
        name: 'Bank Respublika',
        img: BankRespublika,
    },
    {
        id: 7,
        name: 'Bank of Baku',
        img: BankofBaku,
    },
    {
        id: 8,
        name: 'YeloBank',
        img: YeloBank,
    },
    {
        id: 9,
        name: 'Gunai Bank',
        img: GunaiBank,
    },
    {
        id: 10,
        name: 'Turanbank',
        img: Turanbank,
    },
    {
        id: 11,
        name: 'Rabitabank',
        img: Rabitabank,
    },
    {
        id: 12,
        name: 'AccessBank',
        img: AccessBank,
    },
    {
        id: 13,
        name: 'Xalq Bank',
        img: XalqBank,
    },
    {
        id: 14,
        name: 'Muganbank',
        img: Muganbank,
    },
    {
        id: 15,
        name: 'ABB',
        img: ABB,
    },
    {
        id: 16,
        name: 'AFB BANK',
        img: AFBBANK,
    },
    {
        id: 17,
        name: 'ASB Bank',
        img: ASBBank,
    },
    {
        id: 18,
        name: 'Ziraat Bank Aze',
        img: ZiraatBank,
    },
    {
        id: 19,
        name: 'Yapi Kredi Bank Aze',
        img: YapiKrediBank,
    },
    {
        id: 20,
        name: 'Bank Avrasiya',
        img: BankAvrasiya,
    },
    {
        id: 21,
        name: 'Bank BTB',
        img: BankBTB,
    },
    {
        id: 22,
        name: 'Premium Bank',
        img: PremiumBank,
    },
    {
        id: 23,
        name: 'Naxcivanbank',
        img: Naxcivanbank,
    },
    {
        id: 24,
        name: 'Leobank',
        img: Leonbank,
    },
];

const bankListClass: BankCardsAZN[] = BANK_LIST_AZN.map((data) => new BankCardsAZN(data));

export const defaultBank: BankAZN = {
    id: 1,
    name: 'KapitalBank',
    img: KapitalBank,
};

const bankMap = new Map<number, BankAZN>(bankListClass.map((bank) => [bank.id, bank]));

export { BANK_LIST_AZN };
export default BankCardsAZN;
