/* eslint-disable max-len */

import { Icon } from 'types';

function CasinoPoker({ color, width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0)">
                <path
                    d="M4.5 20.6563C4.51887 21.4021 5.13482 21.9996 5.89358 21.9996H18.1055C18.8762 21.9996 19.4996 21.3832 19.4996 20.6211V3.37812C19.4996 2.63288 18.9035 2.02695 18.1565 2.00049C18.1568 2.0122 18.1569 2.02396 18.1569 2.03574V19.2787C18.1569 20.0408 17.5336 20.6572 16.7629 20.6572H4.55096C4.5339 20.6572 4.51691 20.6569 4.5 20.6563Z"
                    fill={color ?? 'url(#paint0_linear)'}
                />
                <path
                    d="M6.5 22.6568C6.51887 23.4026 7.13482 24.0001 7.89358 24.0001H20.1055C20.8762 24.0001 21.4996 23.3837 21.4996 22.6215V5.37861C21.4996 4.63337 20.9035 4.02745 20.1565 4.00098C20.1568 4.0127 20.1569 4.02445 20.1569 4.03623V21.2792C20.1569 22.0413 19.5336 22.6577 18.7629 22.6577H6.55096C6.5339 22.6577 6.51691 22.6574 6.5 22.6568Z"
                    fill={color ?? 'url(#paint1_linear)'}
                />
                <path
                    d="M5.61719 3.98438H4.45654L5.04736 2.19824L5.61719 3.98438Z"
                    fill={color ?? 'url(#paint2_linear)'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.106 0H3.89403C3.12335 0 2.5 0.616419 2.5 1.37854V18.6215C2.5 19.3836 3.12335 20 3.89403 20H16.106C16.8767 20 17.5 19.3836 17.5 18.6215V1.37854C17.5 0.616419 16.8767 0 16.106 0ZM4.17773 5.22412L4.38525 4.4668H5.69727L5.91016 5.21777C5.91357 5.23145 5.91943 5.25439 5.9502 5.271C5.97119 5.28271 6.00439 5.29102 6.05615 5.29102C6.15283 5.29102 6.2583 5.25244 6.32617 5.20166C6.36816 5.17041 6.396 5.13428 6.39795 5.1001C6.39795 5.09473 6.39795 5.0835 6.39258 5.07227L5.39453 1.81982C5.36084 1.70215 5.19287 1.65186 5.04102 1.65186C4.89551 1.65186 4.72705 1.70215 4.69385 1.81982L3.69531 5.07227C3.69531 5.07764 3.69434 5.08301 3.69287 5.08838C3.69141 5.09424 3.68994 5.1001 3.68994 5.10596C3.69531 5.20654 3.88623 5.29639 4.03174 5.29639C4.08545 5.29639 4.11865 5.2876 4.13965 5.27588C4.15625 5.2666 4.16504 5.25488 4.17041 5.24463C4.17422 5.237 4.17628 5.22945 4.17773 5.22412ZM10.626 13.6567C11.6743 14.0884 12.8799 13.8472 13.6816 13.0454C14.7749 11.9521 14.7749 10.1689 13.6704 9.07568L10.1719 5.57666C10.1099 5.50977 10.0259 5.47607 9.93604 5.47607C9.85205 5.47607 9.76221 5.51514 9.70068 5.57666L6.20752 9.10352C5.41113 9.8999 5.17041 11.0942 5.59082 12.1426C6.17383 13.5835 7.81104 14.2788 9.25195 13.6958L8.68018 15.0078C8.64111 15.0864 8.64111 15.1816 8.6748 15.2656C8.70312 15.3364 8.75439 15.3926 8.81689 15.4287C8.87354 15.4609 8.93945 15.4775 9.00635 15.4736C9.0415 15.4717 9.07715 15.4644 9.11182 15.4507C9.65039 15.2378 10.2446 15.2378 10.7827 15.4507C10.7983 15.457 10.8145 15.4619 10.8306 15.4653C10.8535 15.4707 10.8765 15.4731 10.8999 15.4731C10.9473 15.4736 10.9951 15.4639 11.041 15.4453C11.1089 15.416 11.1621 15.3652 11.1968 15.3042C11.248 15.2148 11.2583 15.1025 11.2148 15.0024L10.626 13.6567Z"
                    fill={color ?? 'url(#paint3_linear)'}
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="2.93181"
                    y1="5.26831"
                    x2="19.2741"
                    y2="8.35584"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="2.93181"
                    y1="5.26831"
                    x2="19.2741"
                    y2="8.35584"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="2.93181"
                    y1="5.26831"
                    x2="19.2741"
                    y2="8.35584"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="2.93181"
                    y1="5.26831"
                    x2="19.2741"
                    y2="8.35584"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default CasinoPoker;
