import { HistoryService } from 'service/HistoryService';
import CloseIcon from './image/close_1.svg';

import './Close.scss';
import classNames from 'classnames';

type CloseProps = {
    size?: string;
};

function Close({ size }: CloseProps) {
    const onClose = () => {
        HistoryService.clearHash();
    };

    const classNameClose = classNames('button-close', {
        'button-close__small': size === 's',
    });

    return (
        <button onClick={onClose} className="button-close">
           <img className={classNameClose} src={CloseIcon} alt="close" />
        </button>
    );
}

export default Close;
