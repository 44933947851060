import Validator from '../interface/Validator';

class PhonePeTransaction implements Validator {
    validate(value: string): boolean {
        return this.numberCheck(value) && this.lengthCheck(value);
    }

    numberCheck(value: string): boolean {
        return new RegExp('^[0-9]+$').test(value);
    }

    lengthCheck(value: string): boolean {
        return value.length === 12;
    }
}

const PhonePeTransactionService = new PhonePeTransaction();

export { PhonePeTransactionService };
export default PhonePeTransaction;
