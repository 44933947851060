import { CashixWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendCashixWithdraw';
import { CashixDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendCashixP2CDepositRequest';

interface CashixHandler {
    depositCashixP2C(request: CashixDepositRequest): void;
    withdraw(withdraw: CashixWithdrawRequest): void;
}

class CashixProvider {
    handler: CashixHandler;

    constructor(handler: CashixHandler) {
        this.handler = handler;
    }

    depositCashixP2C(request: CashixDepositRequest): void {
        this.handler.depositCashixP2C(request);
    }

    withdraw(withdraw: CashixWithdrawRequest) {
        this.handler.withdraw(withdraw);
    }
}

export type { CashixHandler };
export default CashixProvider;
