import { SystemTypes } from 'enums/payment';

import { PaymentMethod } from '../PaymentMethod/types';
import AnindaHavaleForm from './AnindaHavale/Form/Form';
import ApayForm from './Apay/Form';
import AzarpayForm from './Azarpay/Form/Form';
import CorefyForm from './Corefy/Form/Form';
import EpulForm from './Epul/Form/Form';
import HavaleHeraForm from './HavaleHera/Form/Form';
import InstapayForm from './Instapay/Form/Form';
import PaycosForm from './Paycos/Form/Form';
import PayfixForm from './Payfix/Form/Form';
import PaykassmaForm from './Paykassma/Form/Form';
import Directa24Form from './Directa24/Form/Form';
import PayesForm from './Payes/Form/Form';
import ConstantposForm from './Constantpos/Form';
import CoinspaidForm from './Coinspaid/Form';
import WellxPayForm from './WellxPay/Form/Form';
import CashixForm from './Cashix/Form/Form';
import ValerForm from "../Deposit/Azarpay/Form/Form";

type WithdrawProps = {
    method: PaymentMethod;
};

const Withdraw = (props: WithdrawProps) => {
    switch (props.method.system) {
        case SystemTypes.PAYHERA: {
            return <HavaleHeraForm paymentMethod={props.method} />;
        }
        case SystemTypes.ANINDA: {
            return <AnindaHavaleForm paymentMethod={props.method} />;
        }
        case SystemTypes.PAYKASSMA: {
            return <PaykassmaForm paymentMethod={props.method} />;
        }
        case SystemTypes.PAYCOS: {
            return <PaycosForm paymentMethod={props.method} />;
        }
        case SystemTypes.EPUL: {
            return <EpulForm paymentMethod={props.method} />;
        }
        case SystemTypes.INSTAPAYS: {
            return <InstapayForm paymentMethod={props.method} />;
        }
        case SystemTypes.PAYFIX: {
            return <PayfixForm paymentMethod={props.method} />;
        }
        case SystemTypes.COREFY: {
            return <CorefyForm paymentMethod={props.method} />;
        }
        case SystemTypes.AZARPAY: {
            return <AzarpayForm paymentMethod={props.method} />;
        }
        case SystemTypes.APAY: {
            return <ApayForm paymentMethod={props.method} />;
        }
        case SystemTypes.DIRECT24: {
            return <Directa24Form paymentMethod={props.method} />;
        }
        case SystemTypes.PAYES: {
            return <PayesForm paymentMethod={props.method} />;
        }
        case SystemTypes.CONSTANTPOS: {
            return <ConstantposForm paymentMethod={props.method} />;
        }
        case SystemTypes.COINSPAID: {
            return <CoinspaidForm paymentMethod={props.method} />;
        }
        case SystemTypes.WELLXPAY: {
            return <WellxPayForm paymentMethod={props.method} />;
        }
        case SystemTypes.CASHIX: {
            return <CashixForm paymentMethod={props.method} />;
        }
        case SystemTypes.VALLERPAY: {
            return <AzarpayForm paymentMethod={props.method} />;
        }
        default:
            return null;
    }
};

export default Withdraw;
