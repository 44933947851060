import Currency, { CurrencySignPosition } from '../service/model/Currency';

function formatCurrency(value: number, precision = 2) {
    const option = {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
    };

    const currentValue = value < 0 ? 0 : value;

    return new Intl.NumberFormat('ru-RU', option).format(currentValue);
}

function formatCurrencySign(value: number = 0, precision = 2, currency: Currency | null): string {
    if (!currency) return formatCurrency(value, precision);
    if (currency.signPosition === CurrencySignPosition.AFTER) {
        return `${formatCurrency(value, precision)} ${currency.sign}`;
    }
    return `${currency.sign} ${formatCurrency(value, precision)}`;
}

function formatBangladeshPhoneForBack(phone: string) {
    return phone.split(' ')[1];
}
function formatMpesaPhoneForBack(phone: string) {
    return phone.replace('+', '').replace(' ', '');
}

function formatCPFNumberForBack(cpf: string) {
    return cpf.replaceAll('.', '').replace('-', '');
}

function formatEmanatPhone(phone: string) {
    return phone.replaceAll(' ', '').replaceAll('+994', '')
}

function formatMyanmarForBack(phone: string) {
    return `0${phone.split(' ')[1]}`;
}

export {
    formatBangladeshPhoneForBack,
    formatCurrency,
    formatCurrencySign,
    formatMpesaPhoneForBack,
    formatCPFNumberForBack,
    formatEmanatPhone,
    formatMyanmarForBack,
};
