import Validator from '../interface/Validator';

class PhoneMyanmar implements Validator {
    validate(value: string): boolean {
        return value.length === 14;
    }
}
const PhoneMyanmarService = new PhoneMyanmar();
export { PhoneMyanmarService };

export default PhoneMyanmar;
