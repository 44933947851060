import Validator from '../interface/Validator';

class VPA implements Validator {
    validate(value: string): boolean {
        return new RegExp('^[\\w.-]+@[\\w.-]+$').test(value);
    }
}
const VPAService = new VPA();

export { VPAService };
export default VPA;
