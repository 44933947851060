import useObservable from 'utils/useObservable';

import { PaymentMethod } from '../../../PaymentMethod/types';
import Balance from '../../Balance/Balance';
import ResultForm from '../ResultForm/ResultForm';
import CorefyWithdrawService, { CorefyWithdrawState } from '../service/CorefyWithdraw';
import Card from './Card/Card';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(CorefyWithdrawService.state, CorefyWithdrawService.state.getValue());

    if (paymentMethod) {
        if (
            state === CorefyWithdrawState.CARD ||
            state === CorefyWithdrawState.AWAIT ||
            state === CorefyWithdrawState.HAS_FEE
        ) {
            return (
                <>
                    <Balance />
                    <Card paymentMethod={paymentMethod} />
                </>
            );
        } else {
            return <ResultForm />;
        }
    }

    return null;
}

export default Form;
