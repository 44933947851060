/* eslint-disable max-len */

import { Icon } from 'types';

function PlayGameIcon({ color = '#9FA0A8', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
            <rect x="0.75" y="4.75" width="26.5" height="18.5" rx="1.25" stroke={color} strokeWidth="1.5" />
            <path
                d="M10.2666 18.241V9.75899C10.2666 8.98326 11.1115 8.50292 11.7781 8.89969L18.903 13.1407C19.5543 13.5284 19.5543 14.4716 18.903 14.8593L11.7781 19.1003C11.1115 19.4971 10.2666 19.0167 10.2666 18.241Z"
                stroke={color}
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default PlayGameIcon;
