import {Subject} from 'rxjs';
import WSBet from '../WSBet';
import StatusFormPopupService, {StatusForm} from '../../page/Profile/Balance/Deposit/DepositPopup/service/StatusForm';
import {
    WellxpayBkashP2CDepositRequest
} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendWellxpayBkashP2CDepositRequest';
import {
    WellxpayDepositResponse, WellxPayDepositTwoCode, WellxPayDepositTwoResponse,
    WellxPayInvoiceCode,
    WellxPayInvoiceResponse,
    WellxpayWithdrawCode,
    WellxpayWithdrawResponse
} from '@ay_tsarbet/newbet-core/dist/types';
import { WellxpayWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendWellxpayWithdraw';
import WellxPayInterface from '../interface/payment/WellxPay';
import WellxPayDepositService from '../../page/Profile/Balance/Deposit/Wellxpay/service/WellxpayDeposit';

import { WellxPayInvoiceRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendWellxPayInvoiceRequest';
import WellxPayInvoice from '../model/payment/WellxPayInvoice';
import { WellxPayDepositStepTwo } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendWellxPayDepositStepTwo';

enum WellxPayError {
    UNKNOWN,
    WITHDRAW,
}
export enum InvoiceStatusCheck {
    SUCCESS,
    FAIL,
}

class WellxPay implements WellxPayInterface {
    invoice = new Subject<WellxPayInvoice>();
    error = new Subject<WellxPayError>();
    invoiceCheckCode = new Subject<InvoiceStatusCheck>();
    withdrawResult = new Subject<void>();

    depositBKashP2C(request: WellxpayBkashP2CDepositRequest) {
        console.log('request', request);
        WSBet.payment.wellxPay.depositBKashP2C(request);
    }

    withdraw(withdraw: WellxpayWithdrawRequest) {
        console.log('withdraw', withdraw);
        WSBet.payment.wellxPay.withdraw(withdraw);
    }

    onDepositResponse(response: WellxpayDepositResponse) {
        if (response.errorCode >= 0) {
            WellxPayDepositService.onReceive(response.message);
            console.log('data.message', response.message)
        } else {
            WellxPayDepositService.onError();
        }
    }

    onWithdrawResponse(response: WellxpayWithdrawResponse) {
        console.log('onWithdrawResponse', response);
        if (response.code >= WellxpayWithdrawCode.success) {
            this.withdrawResult.next();
            StatusFormPopupService.status.next(StatusForm.SUCCESS)
        } else {
            this.error.next(WellxPayError.WITHDRAW);
            StatusFormPopupService.status.next(StatusForm.FAILED);
        }
    }
    createInvoice(invoiceRequest: WellxPayInvoiceRequest) {
        console.log('createInvoice', invoiceRequest);
        WSBet.payment.wellxPay.createInvoice(invoiceRequest);
    }
    onInvoiceResponse(response: WellxPayInvoiceResponse) {
        if (response.code === WellxPayInvoiceCode.success) {
            this.invoice.next(new WellxPayInvoice(response.id));
            WellxPayDepositService.setWalletType(response.walletType);
        } else {
            this.error.next(WellxPayError.UNKNOWN);
        }
    }

    depositStepTwo(payment: WellxPayDepositStepTwo) {
        console.log('depositStepTwo', payment);
        WSBet.payment.wellxPay.depositStepTwo(payment);
    }

    onDepositTwoResponse(response: WellxPayDepositTwoResponse) {
        console.log('onWellxPayDepositTwoResponse', response);
        this.invoiceCheckCode.next(
            response.code === WellxPayDepositTwoCode.success ? InvoiceStatusCheck.SUCCESS : InvoiceStatusCheck.FAIL
        );
    }
}

export { WellxPayError };
export default WellxPay;
