import Input, { InputSize } from 'component/Input/Input';

import AuthService, { AuthError } from '../../../service/Auth';
import SvgImage from '../../../svg';
import RxComponent from '../../RxComponent/RxComponent';
import AuthFormService from '../service/AuthForm';
import PasswordInputService from '../service/PasswordInput';

import './Password.scss';

type PasswordProps = {};
type PasswordState = {
    visible: boolean;
};

class Password extends RxComponent<PasswordProps, PasswordState> {
    state = {
        visible: false,
    };

    componentDidMount() {
        this.subscribe(AuthService.error, {
            next: (error) => {
                if (error === AuthError.PASSWORD) {
                    PasswordInputService.error.next('login.password.error');
                }
            },
        });
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        AuthFormService.changed.next(true);
        PasswordInputService.onChange(event, false);
    }

    render() {
        const { visible } = this.state;

        return (
            <div className="login-password">
                <Input
                    inputService={PasswordInputService}
                    type={visible ? 'text' : 'password'}
                    name="password"
                    size={InputSize.small}
                    onChange={this.handleChange}
                />
                <button
                    className="login-password__toggle"
                    type="button"
                    onClick={() => this.setState({ visible: !visible })}
                >
                    <SvgImage name={visible ? 'LockEye' : 'LockEye'} />
                </button>
            </div>
        );
    }
}

export default Password;
