import Input, { InputSize } from '../../../../../../../../component/Input/Input';
import MaskedInput from '../../../../../../../../component/Input/MaskedInput';
import SelectEasy from '../../../../../../../../component/SelectEasy/SelectEasy';
import { translate as t } from '../../../../../../../../service/Language';
import { bankList } from '../../../../../../../../service/model/BankTRY';
import useObservable from '../../../../../../../../utils/useObservable';
import { InstapayErrorTip, InstapayInputTip } from '../../../../../Deposit/Instapay/service/AmountForm';
import { PaymentMethod } from '../../../../../PaymentMethod/types';
import CardFormService from '../../../service/CardForm';
import { BankTRY } from '../Card';
import WithdrawalTip from '../WithdrawalTip/WithdrawalTip';
import Back from '../image/back.png';
import Attention from '../../../../../../../../assets/svg/attention.svg';

import './CepBank.scss';


type CepBankProps = {
    paymentMethod: PaymentMethod;
    hint: string;
    error: string;
    fee: string;
};

function CepBank({ paymentMethod, hint, fee, error }: CepBankProps) {
    const currentBank = useObservable(CardFormService.bank, CardFormService.bank.getValue());
    const tip = useObservable(CardFormService.tip, CardFormService.tip.getValue());
    const errorLetters = useObservable(CardFormService.errorLetters, CardFormService.errorLetters.getValue());

    const onSelect = (bankOption: BankTRY) => {
        CardFormService.onSelectBank(bankOption);
    };

    return (
        <>
            <div className="card-withdrawal-form-block">
                <MaskedInput
                    className="card-withdrawal-form-block__input"
                    inputService={CardFormService.accountCepbank}
                    name="accountNumber"
                    mask="00000000000000000"
                    size={InputSize.medium}
                />
                {tip === InstapayInputTip.ACCOUNT_NUMBER_CEP && (
                    <span className="card-withdrawal-form-block__help">
                        {t('profile.balance.withdraw.input.help.account')}
                    </span>
                )}
            </div>
            <div className="card-withdrawal-form-block">
                <Input
                    className="card-withdrawal-form-block__input"
                    inputService={CardFormService.name}
                    name="name"
                    size={InputSize.medium}
                />
                {tip === InstapayInputTip.NAME && (
                    <span className="card-withdrawal-form-block__help">
                        {t('popup.payment.name.hint')}
                    </span>
                )}
                {errorLetters === InstapayErrorTip.NAME ?
                    <div className="card-withdrawal-form-block-letters" >
                        <img className="card-withdrawal-form-block-letters__img" src={Back} alt="back"/>
                        <span
                            className="card-withdrawal-form-block-letters__text">{t('popup.payment.turkey.error.letters')}</span>
                    </div> : null}
            </div>
            <div className="card-withdrawal-form-block">
                <Input
                    className="card-withdrawal-form-block__input"
                    inputService={CardFormService.surname}
                    name="surname"
                    size={InputSize.medium}
                />
                {tip === InstapayInputTip.SURNAME && (
                    <span className="card-withdrawal-form-block__help">
                        {t('popup.payment.name.hint')}
                    </span>
                )}
                {errorLetters === InstapayErrorTip.SURNAME ?
                    <div className="card-withdrawal-form-block-letters" >
                        <img className="card-withdrawal-form-block-letters__img" src={Back} alt="back"/>
                        <span
                            className="card-withdrawal-form-block-letters__text">{t('popup.payment.turkey.error.letters')}</span>
                    </div> : null}
            </div>
            <div className="card-withdrawal-form-block">
                <SelectEasy
                    onSelect={onSelect}
                    list={bankList}
                    current={currentBank}
                    classList="card-withdrawal__list"
                />
                {tip === InstapayInputTip.BANK && (
                    <span className="card-withdrawal-form-block__help">
                        {t('profile.balance.withdraw.input.help.bank')}
                    </span>
                )}
            </div>
            <div className="card-withdrawal-form-block">
                <MaskedInput
                    className="card-withdrawal-form-block__input"
                    inputService={CardFormService.amount}
                    name="amount"
                    mask="0000000"
                    hint={hint}
                    size={InputSize.medium}
                />
                <span className="card-withdrawal-form-block__currency">{paymentMethod.currency.sign}</span>
                {!!error ? (
                    <div className="card-withdrawal-form-block__error">
                        <img src={Attention} alt="attention"/>
                        <span
                            className="card-withdrawal-form-block__error_text">
                                {fee}
                            </span>
                    </div>
                ) : null}
            </div>
            <div className="card-withdrawal-form-block">
                <Input
                    className="card-withdrawal-form-block__input"
                    inputService={CardFormService.branchCode}
                    name="branchCode"
                    size={InputSize.medium}
                    type="text"
                    isUpperCase={true}
                />
                {tip === InstapayInputTip.BRANCH && (
                    <span className="card-withdrawal-form-block__help">
                        {t('profile.balance.withdraw.input.help.branch')}
                    </span>
                )}
                <WithdrawalTip type="swift" up />
            </div>
            <div className="card-withdrawal-form-block">
                <MaskedInput
                    className="card-withdrawal-form-block__input"
                    inputService={CardFormService.iban}
                    name="iban"
                    size={InputSize.medium}
                    placeholder="TRXXXXXXXXXXXXXXXXXXXXXXXX"
                    mask="TR000000000000000000000000"
                    type="text"
                />
                {tip === InstapayInputTip.IBAN && (
                    <span className="card-withdrawal-form-block__help">
                        {t('profile.balance.withdraw.input.iban.help')}
                    </span>
                )}
                <WithdrawalTip type="iban" up />
            </div>
        </>
    );
}

export default CepBank;
