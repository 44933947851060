import AnindaHavale from './payment/AnindaHavale';
import Azarpay from './payment/Azarpay';
import Corefy from './payment/Corefy';
import Epul from './payment/Epul';
import HavaleHera from './payment/HavaleHera';
import Instapay from './payment/Instapay';
import Paycos from './payment/Paycos';
import Payfix from './payment/Payfix';
import Paykassma from './payment/Paykassma';
import Directa24 from './payment/Directa24';
import Payes from './payment/Payes';
import Constantpos from './payment/Constantpos';
import CoinsPaid from './payment/CoinPaid';
import { BehaviorSubject } from 'rxjs';
import { PaymentMethodsListType } from 'utils/payment';
import Mpay from './payment/Mpay';
import Paycord from './payment/Paycord';
import WellxPay from './payment/WellxPay';
import Cashix from './payment/Cashix';

class Payment {
    havaleHera = new HavaleHera();

    anindaHavale = new AnindaHavale();

    paykassma = new Paykassma();

    paycos = new Paycos();

    epul = new Epul();

    instapay = new Instapay();

    payfix = new Payfix();

    corefy = new Corefy();

    azarpay = new Azarpay();

    directa24 = new Directa24();

    payes = new Payes();

    constantpos = new Constantpos();

    mpay = new Mpay();

    coinspaid = new CoinsPaid();

    paycord = new Paycord();

    wellxpay = new WellxPay();

    cashix = new Cashix();

    paymentList = new BehaviorSubject<PaymentMethodsListType | null>(null);
}

const PaymentService = new Payment();

export default PaymentService;
