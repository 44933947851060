import { FormEvent } from 'react';
import { translate as t } from 'service/Language';

import RecoveryService, { RecoveryError } from '../../service/Recovery';
import Close from '../Close/Close';
import RxComponent from '../RxComponent/RxComponent';
import ConfirmPasswordInputService from './service/ConfirmPassword';
import PasswordInputService from './service/Password';
import ResetFormService from './service/ResetForm';
import Submit from './Submit';
import ToggleInput from './ToggleInput';

import './ResetPassword.scss';

type ResetPasswordProps = {};
type ResetPasswordState = {
    message: string | null;
};

class ResetPassword extends RxComponent<ResetPasswordProps, ResetPasswordState> {
    state = {
        message: null,
    };

    handler = {
        onResetComplete: this.onResetComplete.bind(this),
        onSubmit: this.onSubmit.bind(this),
        onError: this.onError.bind(this),
    };

    onError(error: RecoveryError) {
        if (error === RecoveryError.TOKEN_EXPIRED) {
            this.setState({ message: `${t('recovery.error.link')}` });
        }
        if (error === RecoveryError.RESET_ERROR) {
            PasswordInputService.error.next(t('recovery.error.password'));
        }
    }

    onResetComplete() {
        this.setState({ message: `${t('recovery.password.success')}` });
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        ResetFormService.submit();
    }

    componentDidMount() {
        this.subscribe(RecoveryService.complete, { next: this.handler.onResetComplete });
        this.subscribe(RecoveryService.error, { next: this.handler.onError });

        const pathname = window.location.search.split('sub_id3=');
        const token = pathname[1];

        RecoveryService.sendEmailToken(token);
    }

    render() {
        const { message } = this.state;

        return (
            <form className="reset-password" onSubmit={this.handler.onSubmit}>
                <div className="reset-password__header">
                    <span>{t('recovery.header')}</span>
                    <Close />
                </div>
                {message ? (
                    <div className="reset-password__error">{message}</div>
                ) : (
                    <div className="reset-password__body">
                        <div className="reset-password__input-block">
                            <ToggleInput service={PasswordInputService} />
                            <ToggleInput service={ConfirmPasswordInputService} />
                        </div>
                        <Submit />
                    </div>
                )}
            </form>
        );
    }
}

export default ResetPassword;
