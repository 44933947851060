import { translate as t } from 'service/Language';

import './Gamble.scss';

type ListType = {
    id: number;
    header: string;
    title: string;
    description: string;
};

const textList: ListType[] = [
    { id: 1, header: 'gamble.header', title: '', description: 'gamble.description' },
    {
        id: 2,
        header: 'gamble.interdef.header',
        title: 'gamble.interpretation.title',
        description: 'gamble.interpretation.description',
    },
    {
        id: 3,
        header: '',
        title: 'gamble.definitions.title',
        description: 'gamble.definitions.description',
    },
    {
        id: 4,
        header: 'gamble.responsible.header',
        title: 'gamble.responsible.title',
        description: 'gamble.responsible.description',
    },
    {
        id: 5,
        header: '',
        title: 'gamble.information.title',
        description: 'gamble.information.description',
    },
];
const textListSecond: ListType[] = [
    {
        id: 6,
        header: '',
        title: 'gamble.helpful.title',
        description: 'gamble.helpful.description',
    },
    {
        id: 7,
        header: '',
        title: 'gamble.protection.title',
        description: 'gamble.protection.description',
    },
];

function Gamble() {
    return (
        <div className="gamble">
            {textList.map((item) => (
                <>
                    {item.header && <p className="gamble__header">{t(item.header)}</p>}
                    {item.title && <p className="gamble__title">{t(item.title)}</p>}
                    <p className="gamble__description">{t(item.description)}</p>
                </>
            ))}
            <div>
                <a href="https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/">
                    {t('gamble.link.problem')}
                </a>
            </div>
            <br />
            <p className="gamble__description">{t('gamble.information.description.2')}</p>
            <a href="https://www.begambleaware.org/safer-gambling/"> {t('gamble.link.safer')}</a>
            {textListSecond.map((item) => (
                <>
                    {item.header && <p className="gamble__header">{t(item.header)}</p>}
                    {item.title && <p className="gamble__title">{t(item.title)}</p>}
                    <p className="gamble__description">{t(item.description)}</p>
                </>
            ))}
            <a href="https://famisafe.wondershare.com/internet-filter/best-internet-filters.html">
                {t('gamble.link.internet')}
            </a>
            <p className="gamble__title">{t('gamble.exclusion.title')}</p>
            <p className="gamble__description">{t('gamble.exclusion.description')}</p>
        </div>
    );
}

export default Gamble;
