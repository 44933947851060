import { translate as t } from 'service/Language';

import Close from './Close/Close';

import './Header.scss';

function Header() {
    return (
        <div className="registration-header">
            <div className="registration-header__title">{t('registration.header')}</div>
            <Close />
        </div>
    );
}

export default Header;
