import ProfileHeader from '../ProfileHeader/ProfileHeader';
import Password from './Password/Password';

import './Safety.scss';

function Safety() {
    return (
        <>
            <ProfileHeader />
            <div className="safety">
                <Password />
            </div>
        </>
    );
}

export default Safety;
