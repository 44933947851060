/* eslint-disable max-len */

import { Icon } from 'types';

function TimeIcon12({ color = '#3959D9', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                d="M19.0563 8.81274L20.5826 7.04086C20.7308 6.86892 20.7078 6.61242 20.5313 6.46853L19.3004 5.46247C19.1239 5.31816 18.8605 5.34011 18.7127 5.51247L17.292 7.162C16.1179 6.32585 14.7313 5.76286 13.2246 5.56124V4.09911H15.0394C15.2426 4.09911 15.4079 3.93814 15.4079 3.74018V2.35893C15.4079 2.16097 15.2426 2 15.0394 2H8.96021C8.75653 2 8.59166 2.16097 8.59166 2.35893V3.74018C8.59166 3.93814 8.75653 4.09911 8.96021 4.09911H10.775V5.56124C9.26824 5.76246 7.88169 6.32585 6.70802 7.16159L5.28683 5.51247C5.13866 5.34011 4.87571 5.31776 4.69874 5.46247L3.4683 6.46812C3.29133 6.61202 3.26838 6.86851 3.41655 7.04046L4.94291 8.81193C3.73042 10.3058 3 12.1874 3 14.2349C3 19.068 7.03733 23 12 23C16.9627 23 21 19.068 21 14.2349C20.9996 12.1874 20.2692 10.3062 19.0563 8.81274ZM11.9996 21.5773C7.84288 21.5773 4.46042 18.2835 4.46042 14.2349C4.46042 10.1867 7.84288 6.89249 11.9996 6.89249C16.1567 6.89249 19.5387 10.1867 19.5387 14.2349C19.5387 18.2839 16.1567 21.5773 11.9996 21.5773Z"
                fill={color}
            />
            <path
                d="M10.0957 18H8.94824V13.3906C8.94824 12.8405 8.96126 12.4043 8.9873 12.082C8.91243 12.1602 8.81966 12.2464 8.70898 12.3408C8.60156 12.4352 8.23698 12.7347 7.61523 13.2393L7.03906 12.5117L9.13867 10.8613H10.0957V18ZM17.2783 18H12.4395V17.1309L14.2803 15.2803C14.8239 14.7236 15.1836 14.3298 15.3594 14.0986C15.5384 13.8643 15.6686 13.6445 15.75 13.4395C15.8314 13.2344 15.8721 13.0146 15.8721 12.7803C15.8721 12.458 15.7744 12.2041 15.5791 12.0186C15.387 11.833 15.1201 11.7402 14.7783 11.7402C14.5049 11.7402 14.2396 11.7907 13.9824 11.8916C13.7285 11.9925 13.4339 12.1748 13.0986 12.4385L12.4785 11.6816C12.8757 11.3464 13.2614 11.1087 13.6357 10.9688C14.0101 10.8288 14.4089 10.7588 14.832 10.7588C15.4961 10.7588 16.0283 10.9329 16.4287 11.2812C16.8291 11.6263 17.0293 12.0918 17.0293 12.6777C17.0293 13 16.9707 13.306 16.8535 13.5957C16.7396 13.8854 16.5622 14.1849 16.3213 14.4941C16.0837 14.8001 15.6865 15.2152 15.1299 15.7393L13.8896 16.9404V16.9893H17.2783V18Z"
                fill={color}
            />
        </svg>
    );
}

export default TimeIcon12;
