import RouteConfig from 'appConstants/RouteConfig';
import { HistoryService } from 'service/HistoryService';
import { translate as t } from 'service/Language';

import Failed from './images/Failed.svg';

import './FailedPage.scss';

function FailedPage() {
    function skipUnusedForm() {
        HistoryService.history.push(RouteConfig.casino.root);
    }
    return (
        <div className="transaction-failed">
            <div className="transaction-failed__container">
                <img src={Failed} alt="failed" />
                <span className="transaction-failed__title ">{t('profile.balance.deposit.transaction.failed')}</span>
                <button className="transaction-failed__btn" onClick={skipUnusedForm}>
                    {t('profile.balance.deposit.transaction.failed.button')}
                </button>
            </div>
        </div>
    );
}

export default FailedPage;
