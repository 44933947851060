import { translate as t } from 'service/Language';

import './Aml.scss';

type ListType = {
    id: number;
    title: string;
    subTitle: string;
    description: string;
};

const textList: ListType[] = [
    { id: 1, title: 'aml.cash.header', subTitle: '', description: 'aml.cash.description' },
    { id: 2, title: 'aml.definition.title', subTitle: '', description: 'aml.definition.description' },
    { id: 3, title: 'aml.organization.title', subTitle: '', description: 'aml.organization.description' },
    { id: 4, title: 'aml.implementation.title', subTitle: '', description: 'aml.implementation.description' },
    {
        id: 5,
        title: 'aml.verification.title',
        subTitle: 'aml.verification.step.1',
        description: 'aml.verification.description.1',
    },
    {
        id: 6,
        title: '',
        subTitle: 'aml.verification.step.2',
        description: 'aml.verification.description.2',
    },
    {
        id: 7,
        title: '',
        subTitle: 'aml.verification.step.3',
        description: 'aml.verification.description.3',
    },
    { id: 8, title: 'aml.customer.title', subTitle: '', description: 'aml.customer.description' },
    { id: 9, title: 'aml.proof.title', subTitle: '', description: 'aml.proof.description' },
    { id: 10, title: 'aml.funds.title', subTitle: '', description: 'aml.funds.description' },
    { id: 11, title: 'aml.basic.title', subTitle: '', description: 'aml.basic.description' },
    { id: 12, title: 'aml.risk.title', subTitle: '', description: 'aml.risk.description' },
    { id: 13, title: '', subTitle: 'aml.risk.1', description: 'aml.risk.description.1' },
    { id: 14, title: '', subTitle: 'aml.risk.2', description: 'aml.risk.description.2' },
    { id: 15, title: '', subTitle: 'aml.risk.3', description: 'aml.risk.description.3' },
    { id: 16, title: 'aml.measurements.title', subTitle: '', description: 'aml.measurements.description' },

    { id: 17, title: '', subTitle: 'aml.enterprise.sub.title', description: 'aml.enterprise.description' },
    { id: 18, title: 'aml.transaction.title', subTitle: '', description: 'aml.transaction.description' },
    { id: 19, title: 'aml.reporting.title', subTitle: '', description: 'aml.reporting.description' },
    { id: 20, title: 'aml.procedures.title', subTitle: '', description: 'aml.procedures.description' },
    { id: 21, title: 'aml.record.title', subTitle: '', description: 'aml.record.description' },
    { id: 22, title: 'aml.training.title', subTitle: '', description: 'aml.training.description' },
    { id: 23, title: 'aml.auditing.title', subTitle: '', description: 'aml.auditing.description' },
    { id: 24, title: 'aml.security.title', subTitle: '', description: 'aml.security.description' },
    { id: 25, title: 'aml.withdrawal.title', subTitle: '', description: 'aml.withdrawal.description' },
    { id: 26, title: 'aml.refund.title', subTitle: '', description: 'aml.refund.description' },
    { id: 27, title: 'aml.contact.title', subTitle: '', description: 'aml.contact.description' },
];

function Aml() {
    return (
        <div className="aml">
            <p className="aml__header">{t('aml.header')}</p>
            <span className="aml__description">{t('aml.description')}</span>
            <br />
            {textList.map((item) => (
                <>
                    <p className="aml__title">{t(item.title)}</p>
                    <p className="aml__sub-title">{t(item.subTitle)}</p>
                    <span className="aml__description">{t(item.description)}</span>
                </>
            ))}
        </div>
    );
}

export default Aml;
