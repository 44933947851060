import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import { translate as t } from 'service/Language';
import { formatCurrencySign } from 'utils/format';

import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import PaymentService from '../../../../../../../service/Payment';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import AmountFormService from '../../service/AmountForm';

import SubmitAmount from '../Button/SubmitAmount';
import VideoInstruction from '../VideoInstruction/VideoInstruction';
import DisabledAmount from './DisabledAmount/DisabledAmount';

import WellxPayDepositService, {DepositState} from '../../service/WellxpayDeposit';
import SumSelector from '../../SummSelector/SumSelector';

import './Amount.scss';


type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {};

class Amount extends RxComponent<AmountProps, AmountState> {
      handler = {
        onInvoiceError: this.onInvoiceError.bind(this),
           };

    onInvoiceError() {
        AmountFormService.amount.error.next(t('profile.balance.deposit.invoice.error'));
    }

    componentDidMount() {
        const { paymentMethod } = this.props;
        AmountFormService.reset();
        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setValue(paymentMethod.defaultValue);
        WellxPayDepositService.resetInvoice();
        this.subscribe(PaymentService.wellxpay.invoice, {
            next: (invoice) => WellxPayDepositService.onReceiveInvoice(invoice),
        });
        this.subscribe(PaymentService.wellxpay.error, { next: this.handler.onInvoiceError });
          }

    render() {
        const { paymentMethod, lock } = this.props;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        const isInvoiceWait = WellxPayDepositService.state.getValue() === DepositState.WAIT_INVOICE;
        const isAmountState = WellxPayDepositService.state.getValue() === DepositState.AMOUNT;

        return (
            <div className="deposit-amount">
                {paymentMethod.id !== ChannelType.WELLXPAY_H2H_UPAY ? <VideoInstruction method={paymentMethod}/>: null}
                <form className="deposit-amount__form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    <div className="deposit-amount-block">
                        {isAmountState ? (
                            <MaskedInput
                                mask="00000000000"
                                inputService={AmountFormService.amount}
                                className="deposit-amount__input"
                                name="amount"
                                hint={hint}
                                enabled={!lock}
                            />
                        ) : (
                            <DisabledAmount hint={hint} />
                        )}
                        <span className="deposit-amount-block__currency">{paymentMethod.currency.sign}</span>
                    </div>

                    {(isAmountState || isInvoiceWait) && (
                        <>
                            <div className="deposit-amount__sum">
                                <SumSelector paymentMethod={paymentMethod} disabled={lock} />
                            </div>
                            <SubmitAmount />
                        </>
                    )}
                </form>
            </div>
        );
    }
}

export default Amount;
