import Arrow from '../images/arrow.svg';
import './ButtonPayment.scss';

type ButtonPaymentProps = {
    title: string;
    logo: string;
    onClick: (payApp: string)=> void;
};

function ButtonPayment({ title, logo, onClick }: ButtonPaymentProps) {
    return (
        <button className="payment-button" onClick={()=>onClick(title)} >
            <img src={logo} alt="paytm" />
            <div className="payment-button-arrow">
                <span className="payment-button-arrow__text">{title}</span>
                <img src={Arrow} alt="Arrow"/>
            </div>
        </button>
    )
}

export default ButtonPayment;