import BalanceService from 'service/Balance';
import BonusService from 'service/Bonus';
import { translate as t } from 'service/Language';
import UserDataService from 'service/UserData';
import { formatCurrency, formatCurrencySign } from 'utils/format';
import useObservable from 'utils/useObservable';

import { unknownCurrency } from '../../../service/model/Currency';
import Item from './Item/Item';

import './Stat.scss';

function Stat() {
    const balance = useObservable(BalanceService.balance, BalanceService.balance.getValue());
    const bonus = useObservable(BonusService.balance, BonusService.balance.getValue());
    const userData = useObservable(UserDataService.data, UserDataService.data.getValue());

    const currency = userData ? userData.currency : unknownCurrency;

    return (
        <div className="balance-stat">
            <Item
                value={formatCurrencySign(balance, 2, currency)}
                title={t('profile.balance.header.balance')}
                icon="Coins"
                type="balance"
            />
            <Item
                value={formatCurrency(bonus, 0)}
                title={t('profile.balance.header.bonus')}
                icon="BonusIcon"
                type="bonus"
            />
        </div>
    );
}

export default Stat;
