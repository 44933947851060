import { Direction } from '@ay_tsarbet/newbet-core/dist/types';
import { BehaviorSubject } from 'rxjs';

class TransactionHistory {
    selectedType = new BehaviorSubject<Direction>(Direction.REFILL);

    public toggle(type: Direction) {
        this.selectedType.next(type);
    }
}

const TransactionHistoryService = new TransactionHistory();

export default TransactionHistoryService;
