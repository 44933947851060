import useObservable from 'utils/useObservable';

import { PaymentMethod } from '../../../PaymentMethod/types';
import Balance from '../../Balance/Balance';
import ResultForm from '../ResultForm/ResultForm';
import PayfixWithdrawService, { PayfixWithdrawState } from '../service/PayfixWithdraw';
import Card from './Card/Card';
import { useEffect } from 'react';

type FormProps = {
    paymentMethod: PaymentMethod;
};

export type BankTRYType = {
    id: number;
    name: string;
    value: number;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(PayfixWithdrawService.state, PayfixWithdrawService.state.getValue());

    useEffect(()=> {
        PayfixWithdrawService.reset();
    },[])

    if (paymentMethod) {
        if (state === PayfixWithdrawState.CARD || state === PayfixWithdrawState.AWAIT || state === PayfixWithdrawState.HAS_FEE ) {
            return (
                <>
                    <Balance />
                    <Card paymentMethod={paymentMethod} />
                </>
            );
        } else {
            return <ResultForm />;
        }
    }

    return null;
}

export default Form;
