import { useEffect, useState } from 'react';

import FavoriteGameService from '../../../service/FavoriteGame';
import GamesService from '../../../service/Games';
import { unknownGame } from '../../../service/model/Game';
import useObservable from '../../../utils/useObservable';
import Star from '../../Casino/GameList/GameCard/Star';
import button from '../image/button_icon.svg';

import './FavoriteButton.scss';

function FavoriteButton() {
    const loadingGame = useObservable(GamesService.loadingGameInFrame, GamesService.loadingGameInFrame.getValue());
    const [favorite, setFavorite] = useState(false);

    useEffect(() => {
        return () => {
            GamesService.loadingGameInFrame.next(unknownGame);
        };
    }, []);

    function toggleFavorite() {
        if (favorite) {
            setFavorite(false);
            FavoriteGameService.removeFavoriteGame(loadingGame);
        } else {
            setFavorite(true);
            FavoriteGameService.addFavoriteGame(loadingGame);
        }
    }

    return (
        <button className="favorite-button" onClick={toggleFavorite}>
            <Star favorite={favorite} />
        </button>
    );
}

export default FavoriteButton;
