import { translate as t } from 'service/Language';

import ChangeButtonPopUp from './ChangeButtonPopUp/ChangeButtonPopUp';

import './Password.scss';

function Password() {
    return (
        <div className="password-safety">
            <div className="password-safety__header">{t('profile.settings.password.change.title')}</div>
            <div className="password-safety__wrapper">
                <input value="************" disabled={true} className="password-safety__wrapper____input" />
            </div>
            <div className="password-safety__button">
                <ChangeButtonPopUp type="password" />
            </div>
        </div>
    );
}

export default Password;
