import UIService from '../../../../service/UI';
import RxComponent from '../../../RxComponent/RxComponent';
import Menu from './image/Menu.svg';
import AuthService from '../../../../service/Auth';
import { ClientInfo } from '@ay_tsarbet/newbet-core/dist/types';
import PinAnimated from '../../../PinAnimated/PinAnimated';

import './Button.scss';

type LeftSideMenuProps = {};
type LeftSideMenuState = {};

class Button extends RxComponent<LeftSideMenuProps, LeftSideMenuState> {
    handler = {
        setAuth: this.setAuth.bind(this),
        setConfirmEmail: this.setConfirmEmail.bind(this),
    };

    state = {
        auth: null,
        confirmEmail: false,
    };

    setConfirmEmail(confirmEmail: boolean) {
        this.setState({ confirmEmail });
    }
    setAuth(auth: ClientInfo | null) {
        this.setState({ auth });
    }

    componentDidMount() {
        this.subscribe(AuthService.auth, { next: this.handler.setAuth });
        this.subscribe(AuthService.confirmEmail, { next: this.handler.setConfirmEmail });
    }

    render() {
        const { auth, confirmEmail } = this.state;
        return (
            <button className="slide-menu-button" type="button" onClick={() => UIService.toggleSlideMenu()}>
                <img src={Menu} className="slide-menu-button__edge" alt="menu" />
                {auth && !confirmEmail ?
                    <div className="slide-menu-button_pin">
                        <PinAnimated/>
                    </div>: null}
            </button>
        );
    }
}

export default Button;
