import Validator from '../interface/Validator';

class PhoneEmanat implements Validator {
    validate(value: string): boolean {
        return value.length === 18;
    }
}
const PhoneEmanatService = new PhoneEmanat();
export { PhoneEmanatService };
export default PhoneEmanat;
