import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject, combineLatestWith, map } from 'rxjs';

import AuthService from '../../../../../../service/Auth';
import GoogleService from '../../../../../../service/Google';
import Validator from '../../../../../../service/Validator/Validator';
import { PaymentMethod } from '../../../PaymentMethod/types';
import DepositService, { DepositState } from './EpulDeposit';

class AmountForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(1, 1000000);

    currentAmount = new BehaviorSubject(0);

    showAmount = new BehaviorSubject(true);

    amount = new InputService({
        title: 'profile.balance.deposit.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.deposit.input.amount.invalid',
    });

    valid = this.amount.valid
        .pipe(combineLatestWith(DepositService.state))
        .pipe(map(([valid, state]) => valid && state === DepositState.AMOUNT));

    handler = {
        onChange: this.onChange.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onChange);
    }

    onChange(value: string) {
        if (value) {
            const valid = this.amount.isValid();
            if (valid) {
                const currentAmount = Math.ceil(+value + +value * 0.03);
                this.currentAmount.next(currentAmount);
                this.showAmount.next(true);
                this.amount.error.next(null);
            } else {
                this.showAmount.next(false);
                this.amount.error.next('profile.balance.deposit.input.amount.invalid');
            }
        }
    }

    setLimit(min: number, max: number) {
        if (this.limitValidator) {
            this.limitValidator.setLimit(min, max);
        }
    }

    setValue(value: number) {
        this.amount.onFocus();
        this.amount.value.next(value.toString());
    }

    setMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        this.setLimit(paymentMethod.limit.deposit.min, paymentMethod.limit.deposit.max);
    }

    reset() {
        this.amount.value.next('');
        this.amount.error.next(null);
        this.currentAmount.next(0);
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();
        if (this.valid && this.paymentMethod) {
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            const amount = parseFloat(this.amount.getValue());

            GoogleService.sentDepositAmount(
                userId,
                this.paymentMethod.title,
                amount.toString(),
                this.paymentMethod.currency.name
            );

            DepositService.request(this.paymentMethod, amount);
        }
    }
}

const AmountFormService = new AmountForm();

export default AmountFormService;
