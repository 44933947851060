import { EpulDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendEpulDepositRequest';
import { EpulWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendEpulWithdraw';

import EpulInterface from '../../interface/payment/Epul';

interface EpulHandler {
    deposit(request: EpulDepositRequest): void;
    withdraw(request: EpulWithdrawRequest): void;
}

class EpulProvider implements EpulInterface {
    handler: EpulHandler;

    constructor(handler: EpulHandler) {
        this.handler = handler;
    }

    deposit(request: EpulDepositRequest): void {
        this.handler.deposit(request);
    }

    withdraw(request: EpulWithdrawRequest): void {
        this.handler.withdraw(request);
    }
}

export type { EpulHandler };
export default EpulProvider;
