import { BehaviorSubject } from 'rxjs';

import PaymentService from '../../../../../../service/Payment';
import { PaymentMethod } from '../../../PaymentMethod/types';
import AmountFormService from './AmountForm';

enum DepositState {
    AMOUNT,
    WAIT,
    HAS_FEE,
    FAILED,
    SUCCESS,
    BONUS_REJECT,
}

class AzarpayDeposit {
    state = new BehaviorSubject<DepositState>(DepositState.AMOUNT);

    depositBank(paymentMethod: PaymentMethod, amount: number) {
        this.state.next(DepositState.WAIT);
        PaymentService.azarpay.depositBank({
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
            amount,
        });
    }
    depositVisa(paymentMethod: PaymentMethod ) {
        this.state.next(DepositState.WAIT);
        PaymentService.azarpay.depositVisa({
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
            name: ' ',
            surname: ' ',
            amount: 0,
        });
    }

    reset() {
        this.state.next(DepositState.AMOUNT);
        AmountFormService.reset();
    }

    onError() {
        this.state.next(DepositState.FAILED);
    }

    onReceive(link: string) {
        const state = this.state.getValue();
        if (state === DepositState.WAIT) {
            window.open(link, '_self');
        }
    }

    back() {
        this.reset();
    }
}

const AzarpayDepositService = new AzarpayDeposit();

export { DepositState };
export default AzarpayDepositService;
