import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import RouteConfig, { QUERY_PARAMS } from '../../../../appConstants/RouteConfig';
import GamesService from '../../../../service/Games';
import GoogleService from '../../../../service/Google';
import { translate as t } from '../../../../service/Language';
import image from './image/image';
import newIcon from '../../../../component/Header/NavBar/Images/new_flag_right.svg';
import 'swiper/swiper.scss';

import './TopMenu.scss';

function TopMenu({ className }: { className?: string }) {

    const handleClick = () => {
            GoogleService.bettingOpen();
    };
    return (
        <div className={classnames(className, 'top-menu')}>
            <Swiper slidesPerView={3.5} spaceBetween={8} slidesPerGroupSkip={1} className="top-menu__slider">
                <SwiperSlide className="top-menu__slide">
                    <NavLink to={RouteConfig.casino.gloryGames} className="top-menu__link top-menu__glory" onClick={() => GoogleService.openGloryGames()}>
                        <div className="top-menu__center">
                            <img src={image.Glory} alt="glory games" />
                            <div className="top-menu__center_text">{'glory games'}</div>
                        </div>
                    </NavLink>
                    <img className="top-menu__glory_new" src={newIcon} alt="" />
                </SwiperSlide>
                <SwiperSlide className="top-menu__slide">
                    <NavLink to={RouteConfig.betting.root} className="top-menu__link top-menu__betting" onClick={handleClick}>
                        <div className="top-menu__center">
                            <img src={image.betting} alt={t('casino.top.sports')} />
                            <div className="top-menu__center_text">{t('casino.top.sports')}</div>
                        </div>
                    </NavLink>
                </SwiperSlide>
                <SwiperSlide className="top-menu__slide">
                    <button onClick={() => GamesService.loadAviatorGame()} className="top-menu__link top-menu__aviator">
                        <div className="top-menu__aviator-body">
                            <img src={image.AviatorIcon} alt={t('casino.top.aviator')} />
                            <img
                                className="top-menu__aviator-text"
                                src={image.AviatorText}
                                alt={t('casino.top.aviator')}
                            />
                        </div>
                    </button>
                </SwiperSlide>
                <SwiperSlide className="top-menu__slide">
                    <button onClick={() => GamesService.loadCrazyGame()} className="top-menu__link top-menu__crazy">
                        <div className="top-menu__crazy-body">
                            <img
                                className="top-menu__crazy-img"
                                src={image.crazytime}
                                alt={t('header.navbar.crazytime')}
                            />
                            <span className="top-menu__center_text">{t('header.navbar.crazytime')}</span>
                        </div>
                    </button>
                </SwiperSlide>
                <SwiperSlide className="top-menu__slide">
                    <NavLink to={RouteConfig.casino.live} className="top-menu__link top-menu__live" onClick={() => GoogleService.openLive()}>
                        <div className="top-menu__center">
                            <img src={image.live} alt={t('casino.top.livecasino')} />
                            <div className="top-menu__center_text">{t('casino.top.livecasino')}</div>
                        </div>
                    </NavLink>
                </SwiperSlide>
                <SwiperSlide className="top-menu__slide">
                    <NavLink to={RouteConfig.casino.tvGames} className="top-menu__link top-menu__tv" onClick={() => GoogleService.tvGames()}>
                        <div className="top-menu__center">
                            <img src={image.tv} alt={t('casino.top.tvgames')} />
                            <div className="top-menu__center_text">{t('casino.top.tvgames')}</div>
                        </div>
                    </NavLink>
                </SwiperSlide>
            </Swiper>
        </div>
    );
}

export default TopMenu;
