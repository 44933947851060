import { useEffect, useState } from 'react';
import LanguageService, { translate as t } from 'service/Language';

import SocialLinkService from '../../../service/SocialLink';
import UserDataService from '../../../service/UserData';
import useObservable from '../../../utils/useObservable';
import Instagram from './image/instagram.svg';
import Telegram from './image/telegram.svg';

import './SocialNetwork.scss';

function SocialNetwork() {
    const language = useObservable(LanguageService.current, LanguageService.current.getValue());
    const userData = useObservable(UserDataService.data, UserDataService.data.getValue());
    const [hide, setHide] = useState(false);

    useEffect(() => {
        if (language) {
            if (
                language.title === 'BN' ||
                language.title === 'UZ' ||
                language.title === 'TR' ||
                language.title === 'MY' ||
                language.title === 'AZ' ||
                language.title === 'EN'||
                language.title === 'SI' ||
                language.title === 'PT'
            ) {
                setHide(true);
            } else {
                setHide(false);
            }
        }
    }, [language, hide]);

    return (
        <>
            {hide && (
                <div className="social-network">
                    <div className="social-network__header">{t('footer.socialnetwork')}</div>
                    <div className="social-network__logos">
                        <a
                            href={t('sidebar.social.link.telegram')}
                            onClick={() => {
                                SocialLinkService.onClickSocial('telegram', userData);
                            }}
                        >
                            <img src={Telegram} alt="telegram" />
                        </a>
                        <a
                            href={t('sidebar.social.link.instagram')}
                            onClick={() => {
                                SocialLinkService.onClickSocial('instagram', userData);
                            }}
                        >
                            <img src={Instagram} alt="instagram" />
                        </a>
                    </div>
                </div>
            )}
        </>
    );
}

export default SocialNetwork;
