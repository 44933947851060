import { RefObject, useLayoutEffect } from 'react';

interface UseAspectRatio {
    (props: { ratio: number; element: RefObject<HTMLElement>; disableAspect?: boolean }): void;
}

const useAspectRatio: UseAspectRatio = ({ ratio, element, disableAspect }) => {
    useLayoutEffect(() => {
        const resizeHandler = () => {
            if (!element.current) {
                return;
            }
            element.current.style.height = disableAspect ? '' : `${element.current.offsetWidth / ratio}px`;
        };
        resizeHandler();
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [disableAspect, element, ratio]);
};

export default useAspectRatio;
