import { NavLink } from 'react-router-dom';

import RouteConfig from '../../../../../appConstants/RouteConfig';
import AuthService from '../../../../../service/Auth';
import { translate as t } from '../../../../../service/Language';
import useObservable from '../../../../../utils/useObservable';

import './Button.scss';

function Button() {
    const auth = useObservable(AuthService.auth, AuthService.auth.getValue());
    const rc = RouteConfig;
    const depositRoute = `${rc.profile.root}${rc.profile.balance.root}${rc.profile.balance.deposit}`;
    return (
        <div>
            <NavLink className="bonus__link" to={auth ? depositRoute : { hash: RouteConfig.hash.registration }}>
                {t('info.promo.welcome.button')}
            </NavLink>
        </div>
    );
}

export default Button;
