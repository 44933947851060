import { useIsDesktop } from '../../../utils/useBreakpoints';
import Chatwoot from '../Intercom/Chatwoot';
import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';

import './Controls.scss';

function Controls() {
    const isDesktop = useIsDesktop();
    return (
        <div className='controls'>
            {isDesktop && <Chatwoot />}
            <SignUp />
            <SignIn />
            {!isDesktop && <Chatwoot />}
        </div>
    );
}

export default Controls;
