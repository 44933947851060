import Input, { InputSize } from "component/Input/Input";
import RxComponent from "component/RxComponent/RxComponent";
import { translate as t } from "service/Language";
import { formatCurrencySign } from "utils/format";
import _paymentMethod from "../../../../PaymentMethod/methods";

import { PaymentMethod } from "../../../../PaymentMethod/types";
import FeeNotice from "../../../FeeNotice/FeeNotice";
import WithdrawCloseButton from "../../../WithdrawCloseButton/WithdrawCloseButton";
import MaskedInput from "../../../../../../../component/Input/MaskedInput";
import Attention from "../../../../../../../assets/svg/attention.svg";

import PaykassmaWithdrawService, {
  PaykassmaWithdrawState,
} from "../../service/PaykassmaWithdraw";

import Balance from "../../../Balance/Balance";
import WithdrawService from "../../../service/Withdrawal";
import AttentionWithdrawalTime from "../../../../../../../component/AttentionWithdrawalTime/AttentionWithdrawalTime";

import "./Jazzcash.scss";
import {AmountError, JazzcashInputTip} from './service/JazzcashForm';
import JazzcashFormService from './service/JazzcashForm';
import WithdrawalButtonJazzcash from './WithdrawalButtonJazzcash/WithdrawalButtonJazzcash';

type JazzcashProps = {
  paymentMethod: PaymentMethod;
  status: PaykassmaWithdrawState;
};
type JazzcashState = {
  value: string;
  amountLimit: AmountError;
  tip: JazzcashInputTip;
  errorAccount: string | null;
  completed: boolean;
  errorAmount: string | null;
};

class Jazzcash extends RxComponent<JazzcashProps, JazzcashState> {
  state = {
    value: "",
    tip: JazzcashInputTip.NONE,
    amountLimit: AmountError.NONE,
    errorAccount: null,
    completed: false,
    errorAmount: null,
  };

  handler = {
    onTip: this.onTip.bind(this),
    onErrorAmount: this.onErrorAmount.bind(this),
    getValue: this.getValue.bind(this),
    onErrorAccount: this.onErrorAccount.bind(this),
    onAutoCompleted: this.onAutoCompleted.bind(this),
    onAmountLimit: this.onAmountLimit.bind(this),
  };


  onErrorAmount(errorAmount: string | null) {
    this.setState({ errorAmount });
  }

  onAmountLimit(amountLimit: AmountError) {
    this.setState({ amountLimit });
  }

  onTip(tip: JazzcashInputTip) {
    this.setState({ tip });
  }

  onErrorAccount(errorAccount: string | null) {
    this.setState({ errorAccount });
  }

  onAutoCompleted(completed: boolean) {
    this.setState({ completed });
  }

  getValue(value: string) {
    this.setState({ value });
  }


  componentDidMount() {
    const { paymentMethod } = this.props;

    JazzcashFormService.setPaymentMethod(paymentMethod);
    this.subscribe(JazzcashFormService.amount.value, {
      next: this.handler.getValue,
    });
    this.subscribe(JazzcashFormService.amount.error, {
      next: this.handler.onErrorAmount,
    });
    this.subscribe(JazzcashFormService.tip, { next: this.handler.onTip });
    this.subscribe(JazzcashFormService.accountNumber.error, {
      next: this.handler.onErrorAccount,
    });
    this.subscribe(JazzcashFormService.amountLimit, {
      next: this.handler.onAmountLimit,
    });
  }

  render() {
    const { paymentMethod, status } = this.props;
    const {
      amountLimit,
      value,
      tip,
      errorAccount,
      errorAmount,
    } = this.state;

    const min = formatCurrencySign(
      paymentMethod.limit.withdraw.min,
      0,
      paymentMethod.currency
    );
    const max = formatCurrencySign(
      paymentMethod.limit.withdraw.max,
      0,
      paymentMethod.currency
    );

    const hint = `${t("popup.payment.amount.min")} ${min} — ${t(
      "popup.payment.amount.max"
    )} ${max}`;

    const fee = WithdrawService.fee.getValue();

    const isInit = status === PaykassmaWithdrawState.INIT;
    if (fee && isInit) {
      PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.HAS_FEE);
    }
    const amountFee = formatCurrencySign(
      paymentMethod.limit.withdraw.min * 1.25,
      0,
      paymentMethod.currency
    );
    const allFeeText = `${amountFee} ${t("popup.payment.min.fee")}`;

    return (
      <div className="upay-withdrawal">
        <div className="upay-withdrawal__header">
          <img src={paymentMethod.icon} alt={paymentMethod.slug} />
          <h2 className="upay-withdrawal__header_title">
            {t(paymentMethod.title)}
          </h2>
        </div>
        <Balance />
        <form
          className="upay-withdrawal-form"
          onSubmit={(e) => JazzcashFormService.onSubmit(e)}
        >
          <div className="upay-withdrawal-form-block">
            <Input
              className="upay-withdrawal-form-block__input"
              inputService={JazzcashFormService.amount}
              name="amount"
              type="number"
            />
            <span className="upay-withdrawal-form-block__currency">
              {paymentMethod.currency.sign}
            </span>
            {fee || !!errorAmount ? (
              <div className="upay-withdrawal-form-block__error">
                {amountLimit ? <img src={Attention} alt="attention" /> : null}
                <span className="upay-withdrawal-form-block__error_text">
                  {!fee && amountLimit && amountLimit === AmountError.AMOUNT_MIN
                    ? t("popup.payment.amount.min") + ` ${min}`
                    : null}
                  {fee && amountLimit === AmountError.AMOUNT_FEE
                    ? allFeeText
                    : null}
                  {amountLimit && amountLimit === AmountError.AMOUNT_MAX
                    ? t("popup.payment.amount.max") + ` ${max}`
                    : null}
                  {amountLimit && amountLimit === AmountError.AMOUNT_WITHDRAWAL
                    ? t("popup.payment.amount.withdrawal.max")
                    : null}
                </span>
              </div>
            ) : null}
            {tip === JazzcashInputTip.AMOUNT && !amountLimit ? (
              <span className="upay-withdrawal-form-block__tip">{hint}</span>
            ) : null}
          </div>
          <div className="upay-withdrawal-form-block">
            <MaskedInput
              className="upay-withdrawal-form-block__input"
              mask="00000000000000000000"
              placeholder="00000000000000000000"
              inputService={JazzcashFormService.accountNumber}
              name="account"
            />

            {tip === JazzcashInputTip.ACCOUNT && !errorAccount && (
              <span className="upay-withdrawal-form-block__tip">
                {t("popup.payment.upay.account.hint.validation")}
              </span>
            )}

            {!!errorAccount && (
              <div className="upay-withdrawal-form-block__error">
                <img src={Attention} alt="attention" />
                <span className="upay-withdrawal-form-block__error_text">
                  {t("popup.payment.upay.account.hint.validation")}
                </span>
              </div>
            )}
          </div>
          {(status === PaykassmaWithdrawState.HAS_FEE ||
            status === PaykassmaWithdrawState.AWAIT) &&
          fee ? (
            <FeeNotice amount={value} fee={fee} />
          ) : null}
          <AttentionWithdrawalTime />
          {status === PaykassmaWithdrawState.HAS_FEE ? (
            <>
              <WithdrawalButtonJazzcash
                buttonTitle="profile.balance.withdraw.fee.agree"
                variation={true}
                paymentMethod={paymentMethod}
                amount={value}
                state={status}
              />
              <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel" />
            </>
          ) : (
            <WithdrawalButtonJazzcash
              buttonTitle="profile.balance.withdraw.button"
              paymentMethod={_paymentMethod.Jazzcash}
              state={status}
            />
          )}
        </form>
      </div>
    );
  }
}

export default Jazzcash;
