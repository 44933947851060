import { HavaleHeraDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendHavaleHeraDepositRequest';
import { HavaleHeraWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendHavaleHeraWithdrawal';
import {
    HavaleHeraDepositCode,
    HavaleHeraDepositResponse,
    HavaleHeraWithdrawResponse,
} from '@ay_tsarbet/newbet-core/dist/types';
import { BehaviorSubject, Subject } from 'rxjs';

import HavaleHeraDepositService from '../../page/Profile/Balance/Deposit/HavaleHera/service/HavaleHeraDeposit';
import HavaleHeraInterface from '../interface/payment/HavaleHera';
import WSBet from '../WSBet';
import StatusFormPopupService, { StatusForm } from '../../page/Profile/Balance/Deposit/DepositPopup/service/StatusForm';

enum HavaleHeraError {
    UNKNOWN,
    DEPOSIT,
    WITHDRAW,
}

class HavaleHera implements HavaleHeraInterface {
    error = new Subject<HavaleHeraError>();

    redirect = new BehaviorSubject<string | null>(null);

    withdrawResult = new Subject<void>();

    deposit(request: HavaleHeraDepositRequest) {
        console.log('HAVALE HERA | DEPOSIT REQUEST', request);
        WSBet.payment.havaleHera.deposit(request);
    }

    withdraw(withdraw: HavaleHeraWithdrawalRequest) {
        console.log('HAVALE HERA  | WITHDRAW REQUEST', withdraw);
        WSBet.payment.havaleHera.withdraw(withdraw);
    }

    onWithdrawReceive(data: HavaleHeraWithdrawResponse) {
        console.log('HAVALE HERA  | WITHDRAW RESPONSE', data);
        if (data.errorCode >= HavaleHeraDepositCode.success) {
            this.withdrawResult.next();
        } else {
            this.error.next(HavaleHeraError.WITHDRAW);
        }
    }

    onDepositReceive(data: HavaleHeraDepositResponse) {
        console.log('HAVALE HERA | DEPOSIT RESPONSE', data);
        if (!data.code) {
            HavaleHeraDepositService.onReceive(data.message);
            StatusFormPopupService.status.next(StatusForm.SUCCESS);
        } else {
            HavaleHeraDepositService.onError();
            StatusFormPopupService.status.next(StatusForm.FAILED);
        }
    }
}

export { HavaleHeraError };
export default HavaleHera;
