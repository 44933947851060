/* eslint-disable max-len */

import { Icon } from 'types';

function CasinoBetting({ color, width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.9202 15.4468L22.3115 8.61035C21.8782 6.76868 20.1807 4.875 17.5956 4.875C15.0105 4.875 14.2924 6.25924 12.0648 6.27133C9.78816 6.28369 8.29675 4.875 6.40473 4.875C4.5127 4.875 2.28064 6.09393 1.68866 8.61035L0.0798977 15.4468C-0.356809 17.3026 1.05182 19.0829 2.9607 19.0829C3.77185 19.0829 4.53485 18.6508 4.95178 17.9555L6.19104 15.8904C6.4029 15.537 6.79035 15.3177 7.20233 15.3177H16.7976C17.2096 15.3177 17.5972 15.5372 17.8091 15.8906L19.0482 17.9553C19.4653 18.6508 20.2283 19.0829 21.0403 19.0829C22.9463 19.0829 24.3573 17.3044 23.9202 15.4468ZM8.98798 10.7994H8.18488V11.6025C8.18488 11.9907 7.87012 12.3054 7.48175 12.3054C7.09357 12.3054 6.77881 11.9907 6.77881 11.6025V10.7994H5.97571C5.58753 10.7994 5.27277 10.4846 5.27277 10.0964C5.27277 9.70807 5.58753 9.39331 5.97571 9.39331H6.77881V8.59021C6.77881 8.20203 7.09357 7.88727 7.48175 7.88727C7.87012 7.88727 8.18488 8.20203 8.18488 8.59021V9.39331H8.98798C9.37616 9.39331 9.69092 9.70807 9.69092 10.0964C9.69092 10.4846 9.37616 10.7994 8.98798 10.7994ZM15.0121 10.7994C14.6241 10.7994 14.3092 10.4844 14.3092 10.0964C14.3092 9.70825 14.6243 9.39331 15.0121 9.39331C15.4003 9.39331 15.7153 9.70825 15.7153 10.0964C15.7153 10.4844 15.4003 10.7994 15.0121 10.7994ZM16.5184 12.3054C16.1304 12.3054 15.8152 11.9905 15.8152 11.6025C15.8152 11.2145 16.1304 10.8994 16.5184 10.8994C16.9064 10.8994 17.2213 11.2145 17.2213 11.6025C17.2213 11.9905 16.9064 12.3054 16.5184 12.3054ZM16.5184 9.29333C16.1304 9.29333 15.8152 8.97839 15.8152 8.59021C15.8152 8.20221 16.1304 7.88727 16.5184 7.88727C16.9064 7.88727 17.2213 8.20221 17.2213 8.59021C17.2213 8.97839 16.9064 9.29333 16.5184 9.29333ZM18.0244 10.7994C17.6364 10.7994 17.3213 10.4844 17.3213 10.0964C17.3213 9.70825 17.6364 9.39331 18.0244 9.39331C18.4124 9.39331 18.7274 9.70825 18.7274 10.0964C18.7274 10.4844 18.4124 10.7994 18.0244 10.7994Z"
                    fill={color ?? 'url(#paint0_linear)'}
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="0.545458"
                    y1="7.99381"
                    x2="18.9369"
                    y2="15.408"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default CasinoBetting;
