import { translate as t } from 'service/Language';
import PopupService from '../../service/Popup';
import {HistoryService} from '../../service/HistoryService';
import RouteConfig from '../../appConstants/RouteConfig';
import FailedBonus from './image/failedBonus.svg';

import './BonusFailedPage.scss';


function BonusFailedPage() {
    function onClickBonus() {
        PopupService.close();
        HistoryService.history.push(RouteConfig.profile.root + RouteConfig.profile.bonus);
    }
    function onClickPlay() {
        PopupService.close();
        HistoryService.history.push(RouteConfig.casino.root);
    }
    return (
        <div className="bonus-result">
            <div className="bonus-result-container">
                <img className="bonus-result-container__img" src={FailedBonus} alt="failed" />
                <p className="bonus-result-container__title ">{t('popup.payment.withdrawal.block.title')}</p>
                <span className="bonus-result-container__text">{t('popup.payment.withdrawal.block.desc')}</span>
                <button className="bonus-result-container__btn" onClick={onClickBonus}>
                    {t('popup.payment.withdrawal.block.button')}
                </button>
                <button className="bonus-result-container__variated" onClick={onClickPlay}>
                    {t('profile.balance.deposit.transaction.success.button')}
                </button>
            </div>
        </div>
    );
}

export default BonusFailedPage;
