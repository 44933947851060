import { Route, Switch } from 'react-router-dom';

import RouteConfig from '../../../appConstants/RouteConfig';
import WheelAviator from '../Wheel/Aviator/WheelAviator';
import WheelCriket from '../Wheel/Criket/WheelCriket';
import WheelCard from '../Wheel/Cards/WheelCard';
import WheelGirl from '../Wheel/Girls/WheelGirl';
import WheelCrazyTime from '../Wheel/CrazyTime/WheelCrazyTime';
import WheelTeenager from '../Wheel/Teenagers/WheelTeenager';
import WheelSlots from './Slots/WheelSlots';
import WheelRoulette from './LigtningRoulette/WheelRoulette';
import WheelBonanza from './Bonanza/WheelBonanza';
import WheelOlympus from './Olympus/WheelOlympus';

function Wheel() {
    const rc = RouteConfig;
    const root = rc.landing.root + rc.landing.wheels.root;

    const route = {
        card: root + rc.landing.wheels.wheelCard,
        crazytime: root + rc.landing.wheels.wheelCrazyTime,
        girl: root + rc.landing.wheels.wheelGirl,
        teen: root + rc.landing.wheels.wheelTeenager,
        aviator: root + rc.landing.wheels.wheelAviator,
        criket: root + rc.landing.wheels.wheelCriket,
        slots: root + rc.landing.wheels.wheelSlots,
        ligtningRoulette: root + rc.landing.wheels.wheelLigtningRoulette,
        bonanza: root + rc.landing.wheels.wheelBonanza,
        olympus: root + rc.landing.wheels.wheelOlympus,
    };

    return (
        <Switch>
            <Route path={route.card} component={WheelCard} />
            <Route path={route.girl} component={WheelGirl} />
            <Route path={route.teen} component={WheelTeenager} />
            <Route path={route.aviator} component={WheelAviator} />
            <Route path={route.criket} component={WheelCriket} />
            <Route path={route.slots} component={WheelSlots} />
            <Route path={route.crazytime} component={WheelCrazyTime} />
            <Route path={route.ligtningRoulette} component={WheelRoulette} />
            <Route path={route.bonanza} component={WheelBonanza} />
            <Route path={route.olympus} component={WheelOlympus} />
        </Switch>
    );
}

export default Wheel;
