import { ChannelType, Operation, SlotegratorCampaign } from '@ay_tsarbet/newbet-core/dist/types';

import { OPERATIONS } from '../../appConstants/operations';

class Bonus {
    id = 0;

    amount = 0.0;

    title = '';

    channelType: ChannelType | null = null;

    percentOfDeposit = 0.0;

    campaign: SlotegratorCampaign | null | undefined = null;

    deposit: number | null = null;

    rolled: number | null = null;

    fullRolled: number | null = null;

    startDate: number | null = null;

    finishDate: number | null = null;

    constructor(bonus: Bonus) {
        Object.assign(this, bonus);
    }

    static fromObject(data: Operation): Bonus {
        let title = '';
        let percentOfDeposit: number | null = null;

        if (data.channelType === OPERATIONS.SLOTEGRATOR_DEPOSIT) {
            title = 'bonus.type.deposit';
        }
        if (data.channelType === OPERATIONS.SLOTEGRATOR_FREESPIN) {
            title = 'bonus.type.freespin';
        }
        // if (data.channelType === OPERATIONS.SLOTEGRATOR_NEWYEAR) {
        //     title = 'bonus.type.newyear';
        // }
        if (data.bonus?.deposit && data.amount && data.bonus?.deposit <= data.amount) {
            percentOfDeposit = (data.amount * 100) / data.bonus?.deposit;
        }

        return new Bonus({
            id: data.id,
            amount: data.amount,
            title,
            channelType: data.channelType,
            // @ts-ignore
            campaign: data.bonus ? data.bonus?.campaign : data.campaign,
            percentOfDeposit: percentOfDeposit || 0.0,
            deposit: data.bonus?.deposit || null,
            rolled: data.bonus?.rolled || null,
            fullRolled: data.bonus?.fullRolled || null,
            startDate: data.bonus && data.bonus.startDate ? data.bonus.startDate : null,
            finishDate: data.bonus && data.bonus.finishDate ? data.bonus.finishDate : null,
        });
    }
}

export default Bonus;
