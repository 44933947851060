import { translate as t } from 'service/Language';

import { useEffect, useState } from 'react';
import GoogleService from 'service/Google';
import PopupHelper from 'service/PopupHelper';
import classNames from 'classnames';

import './WheelRoulette.scss';


type WheelWheelRouletteProps = {};

const WheelRoulette = (props: WheelWheelRouletteProps) => {
    const {} = props;

    const [angle, setAngle] = useState(0);
    const [idle, setIdle] = useState(true);
    const [attempts, setAttempts] = useState(2);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (!idle) {
            if (attempts === 2) {
                setAngle(785);
            } else {
                setAngle(1825);
            }
        }
    }, [attempts, idle]);

    const spin = {
        transform: `rotate(${angle}deg)`,
    };

    const handleClick = () => {
        setIdle(false);
        setTimeout(() => {
            setAttempts(prevState => {
                if (prevState === 2) {
                    GoogleService.landingSpin('');
                    setIdle(true);
                    PopupHelper.showWheelWin('roulette', handleClick);
                    return 1;
                } else {
                    GoogleService.landingSpinAgain('');
                    PopupHelper.showWheelRegistration('roulette');
                    return 0;
                }
            });
        }, 4000);
    };

    const scoreboardClass = classNames('WheelCrazyTime__scoreboard', {
        WheelCrazyTime__scoreboard_idle: idle,
    });

    return (
        <div className='WheelRoulette'>
            <div className='WheelRoulette__elements'>
                <div className='WheelRoulette__elements__patternleft' />
                <div className='WheelRoulette__elements__patternright' />
                <div className='WheelRoulette__elements__shadow' />
                <div className='WheelRoulette__elements__logo' />
                <div className='WheelRoulette__elements__title'>{t('wheels.title.roll')}</div>
                <div className='WheelRoulette__elements__center' />
                <div className='WheelRoulette__attempt'>
                    <div className='WheelRoulette__attempt_block'>
                        <div className='WheelRoulette__attempt_block_title'>
                            {t('wheel.attempts')}
                        </div>
                        <div className='WheelRoulette__attempt_block_number'>{attempts}</div>
                    </div>
                </div>

            </div>
            <div className={scoreboardClass}>
                <div className='WheelRoulette__disk_wrapper'>
                    <div className='WheelRoulette__disk' style={spin}>
                        <div className='WheelRoulette__disk_name' />
                    </div>
                </div>
            </div>
            <div className='WheelRoulette__frame'>
                <div className='WheelRoulette__frame_lights first' />
                <div className='WheelRoulette__frame_lights second' />
            </div>
            <div className='WheelRoulette__button_wrapper'>
                <button
                    className='WheelRoulette__button'
                    disabled={!idle || attempts === 0}
                    onClick={handleClick}
                >
                    <span className='WheelRoulette__button_text'>{t('wheel.aviator.button')}</span>
                </button>
            </div>
        </div>
    );
};

export default WheelRoulette;
