import {CoinsPaidRateRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sentCoinsPaidRateRequest';
import {CoinsPaidWithdrawRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendCoinsPaidWithdraw';
import {CoinsPaidDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sentCoinsPaidDepositRequest';
import CoinspaidInterface from '../../interface/payment/Coinspaid';

interface CoinspaidHandler {
    requestRateMin(request: CoinsPaidRateRequest): void;

    deposit(request: CoinsPaidRateRequest): void;

    withdraw(request: CoinsPaidWithdrawRequest): void;
}

class CoinspaidProvider implements CoinspaidInterface {
    handler: CoinspaidHandler;

    constructor(handler: CoinspaidHandler) {
        this.handler = handler;
    }

    requestRateMin(request: CoinsPaidRateRequest): void {
        this.handler.requestRateMin(request);
    }
    deposit(request: CoinsPaidDepositRequest): void {
        this.handler.deposit(request);
    }

    withdraw(request: CoinsPaidWithdrawRequest): void {
        this.handler.withdraw(request);
    }
}

export type { CoinspaidHandler };
export default CoinspaidProvider;
