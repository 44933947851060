import { BehaviorSubject } from 'rxjs';

class AuthForm {
    valid = new BehaviorSubject<boolean>(false);

    changed = new BehaviorSubject<boolean>(false);
}

const AuthFormService = new AuthForm();

export default AuthFormService;
