import Input from 'component/Input/service/Input';

import Validator from '../../../service/Validator/Validator';

const EmailInputService = new Input({
    title: 'recovery.email',
    validator: Validator.email,
});

export default EmailInputService;
