import RouteConfig from 'appConstants/RouteConfig';
import ProgressBar from 'page/Profile/Bonus/ProgressBar/ProgressBar';
import { useHistory } from 'react-router-dom';
import BonusService from 'service/Bonus';
import { translate as t } from 'service/Language';
import { formatCurrency } from 'utils/format';
import useObservable from 'utils/useObservable';

import bonus from '../../page/Profile/Bonus/images/BonusIcon.svg';
import helpIcon from './images/help.svg';
import timerIcon from './images/timer.svg';
import Timer from './Timer/Timer';

import './BonusWidget.scss';

function BonusWidget() {
    const history = useHistory();
    const bonuses = useObservable(BonusService.list, BonusService.list.getValue());
    const firstDepositBonus = bonuses.find(bonus => bonus.channelType === 50)

    const handleGoToBonus = () => {
        history.push(`${RouteConfig.profile.root}${RouteConfig.profile.bonus}`);
    };

    const widthProgress =
        firstDepositBonus && !!firstDepositBonus.fullRolled && (firstDepositBonus.rolled === 0 || !!firstDepositBonus.rolled)
            ? (firstDepositBonus.rolled * 100) / firstDepositBonus.fullRolled
            : 0;

    if (widthProgress >= 100) return null;

    return (
        <div className="bonus-widget" onClick={handleGoToBonus}>
            <div className="bonus-widget__header">
                <div className="bonus-widget__header-title-container">
                    <span className="bonus-widget__header-title">{t('bonus.widget.title')}</span>
                    <img src={helpIcon} alt="helpIcon" />
                </div>
                <div className="bonus-widget__header-timer-container ">
                    <img src={timerIcon} alt="timerIcon" />
                    {firstDepositBonus && firstDepositBonus.finishDate ? <Timer finish={new Date(firstDepositBonus.finishDate * 1000)} />: null}
                </div>
            </div>
            <div className="bonus-widget__container">
                <div className="bonus-widget__bonus-title ">
                    <span className="bonus-widget__bonus-gift">{firstDepositBonus && formatCurrency(firstDepositBonus.amount, 0)}</span>
                    <img src={bonus} alt="Бонус" className="bonus-widget_bonus-icon" />
                </div>

                <div className="bonus-widget__progress">
                    <span className="bonus-widget__bonus-start">0</span>
                    {firstDepositBonus && firstDepositBonus.fullRolled !== null && (
                        <span className="bonus-widget__bonus-goal">
                            {formatCurrency(firstDepositBonus.fullRolled, 0)}
                        </span>
                    )}
                </div>

                <ProgressBar width={widthProgress} />
            </div>
        </div>
    );
}

export default BonusWidget;
