import { useIsDesktop } from 'utils/useBreakpoints';
import Age from './image/age.svg';
import BeGambleAware from './image/beGambleAware.svg';
import Curacao from './image/curacao.svg';
import RGC from './image/rgc.svg';

import './LogoPartners.scss';

function LogoPartners() {
    const isDesktop = useIsDesktop();
    return (
        <div className='logo-partners'>
            <div className='logo-partners__wrapper'>
                 <a
                    href='https://licensing.gaming-curacao.com/validator/?lh=c59b82fd8a349cb55b9658ccbef529f0'
                    target='_blank'
                    rel={isDesktop ? '' : 'noreferrer'}
                >
                    <img alt='Curacao' src={Curacao} />
                </a>
                <a
                    href='https://www.responsiblegambling.org/'
                    target='_blank'
                    rel={isDesktop ? '' : 'noreferrer'}
                >
                    <img alt='rgc' src={RGC} />
                </a>
                <a
                    className='logo-partners__link'
                    href='https://www.begambleaware.org/'
                    target='_blank'
                    rel={isDesktop ? '' : 'noreferrer'}
                >
                    <img alt='beGambleAware' src={BeGambleAware} />
                </a>
                <img className='logo-partners__link' alt='age' src={Age} />
            </div>
        </div>
    );
}

export default LogoPartners;
