import { Link } from 'react-router-dom';
import { translate as t } from 'service/Language';
import SvgImage from 'svg';
import GoogleService from '../../../../../service/Google';
import GameCategory from '../../../../../service/model/GameCategory';

import './Category.scss';

type CategoryProps = {
    category: GameCategory;
};

function Category(props: CategoryProps) {
    const { category } = props;

    return (
        <Link to={`/${category.route}`} className="category-nav-item" onClick={()=>        GoogleService.openGameCategory(category.name)}>
            <div className="category-nav-item__icon">
                <SvgImage name={category.image} width={24} height={24} />
            </div>
            <div className="category-nav-item__title">{t(category.name)}</div>
        </Link>
    );
}

export default Category;
