/* eslint-disable max-len */

import { Icon } from 'types';

function BonusIcon({ color = '#FC4665', width = 12, height = 12 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 12 12" fill="none">
            <circle cx="6" cy="6" r="6" fill={color} />
            <path
                d="M4 3H6.09871C7.05504 3 7.74846 3.12175 8.17897 3.36525C8.61255 3.60602 8.82934 3.99042 8.82934 4.51847C8.82934 4.87688 8.73401 5.171 8.54336 5.40082C8.35578 5.63064 8.10517 5.76881 7.79151 5.81532V5.85636C8.21894 5.94118 8.52645 6.09986 8.71402 6.33242C8.90467 6.56498 9 6.87414 9 7.25992C9 7.80711 8.77706 8.23393 8.33118 8.54036C7.88838 8.84679 7.28567 9 6.52306 9H4V3ZM5.42989 5.3762H6.26015C6.6476 5.3762 6.92743 5.32285 7.09963 5.21614C7.27491 5.10944 7.36255 4.93297 7.36255 4.68673C7.36255 4.45691 7.26722 4.29275 7.07657 4.19425C6.88899 4.09302 6.59071 4.04241 6.18173 4.04241H5.42989V5.3762ZM5.42989 6.38577V7.94938H6.36162C6.75523 7.94938 7.04582 7.88235 7.23339 7.74829C7.42097 7.61423 7.51476 7.40903 7.51476 7.13269C7.51476 6.63475 7.11501 6.38577 6.3155 6.38577H5.42989Z"
                fill="white"
            />
        </svg>
    );
}

export default BonusIcon;
