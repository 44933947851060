import useObservable from 'utils/useObservable';

import AnindaHavaleWithdrawService, { AnindaHavaleWithdrawState } from '../service/AnindaHavaleWithdraw';
import FailedPage from '../FailedPage/FailedPage';
import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import BonusFailedPage from '../../../../../../component/BonusFailedPage/BonusFailedPage';

import './ResultForm.scss';


function ResultForm() {
    const state = useObservable(AnindaHavaleWithdrawService.state, AnindaHavaleWithdrawService.state.getValue());

    switch (state) {
        case AnindaHavaleWithdrawState.ACCEPT: {
            return <SuccessPage />;
        }
        case AnindaHavaleWithdrawState.REJECT: {
            return <FailedPage />;
        }
        case AnindaHavaleWithdrawState.BONUS_REJECT: {
            return <BonusFailedPage />;
        }
        default: {
            return null;
        }
    }
}

export default ResultForm;
