import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { translate as t } from 'service/Language';
import { BankType } from '../../service/model/BankUpay';
import SvgImage from '../../svg';
import SearchImage from './image/search.svg';
import Erase from './image/erase.svg';

import './SelectWithSearch.scss';

export enum SelectSize {
    average,
    small,
}

type SelectProps = {
    className?: string;
    list: BankType[];
    onSelect: (option: BankType) => void;
    current: any | null;
    placeholder?: string;
    disabled?: boolean;
    type?: 'upay' | 'azn';
};

function SelectWithSearch( props: SelectProps ) {
    const { className, list, onSelect, current, placeholder, disabled, type } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState<string>('');
    const inputRef = useRef<HTMLDivElement>(null);
    const [bankList, setBankList] = useState<BankType[]>(list)

    const _className = classNames(className, 'select-search', {
        'select-search____open': isOpen,
        'select-search____empty': current === null,
        'select-search____disabled': disabled,
    });

    const classBackground = classNames( 'select-search-back', {
        'select-search-back__azn': type === 'azn',
    });
    const classSearch = classNames( 'select-search-back-search', {
        'select-search-back-search__azn': type === 'azn',
    });

    useEffect(() => {
        const listener = (event: PointerEvent | MouseEvent | TouchEvent): void => {
            if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        document.addEventListener('pointerdown', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
            document.removeEventListener('pointerdown', listener);
        };
    }, [inputRef]);

    function isAllowed(bank: BankType) {
        return list.length ? list.includes(bank) : true;
    }

    function onChange(text: string) {
        setSearchText(text);
        const current = list.filter((bank) => {
            const nameMatch = bank.name.toLowerCase().includes(text.toLowerCase());
            const allow = isAllowed(bank);
            const assert = [nameMatch, allow];
            return !assert.includes(false);
        })
        setBankList(current)
    }

    function reset() {
        setSearchText('')
        setBankList(list);
    }

    return (
        <div className={_className} ref={inputRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="select-search__toggle"
                disabled={disabled}
            >
                <div className="select-search__value">{current ? t(current.name) : placeholder && t(placeholder)}</div>
                <SvgImage name="SelectArrow"/>
            </button>

            <div className={classBackground}>
                <div className={classSearch}>
                    <input
                        className="select-search-back-search__input"
                        placeholder={t('placeholder.search')}
                        value={searchText}
                        onChange={(e) => {
                            onChange(e.target.value);
                        }}
                    />
                    {searchText ? <button className="select-search-back-search__erase" onClick={reset} type={'button'}>
                        <img src={Erase} alt="erase"/>
                    </button> : null}
                    <div className="select-search-back-search__block">
                        <img src={SearchImage} alt="search"/>
                    </div>
                </div>
                <div className="select-search-back-list">{bankList.map((option: BankType) => {
                    const containerClassName = classNames({
                        'select-search__option': true,
                        'select-search__option_active': option === current,
                    });

                    return (
                        <button
                            onClick={() => {
                                onSelect(option);
                                setIsOpen(false);
                            }}
                            key={option.id}
                            type="button"
                            className={containerClassName}
                        >
                            <div>{option.name}</div>
                            {option.img ? <img src={option.img} alt="logoBank"/> : null}
                        </button>
                    );
                })}
                </div>
            </div>
        </div>
    );
}

export default SelectWithSearch;
