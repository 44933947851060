import { FormEvent } from 'react';

import AuthService from '../../../service/Auth';
import EmailInputService from './EmailInput';
import PasswordInputService from './PasswordInput';

function Submit(event: FormEvent) {
    event.preventDefault();

    const login = EmailInputService.getValue();
    const password = PasswordInputService.getValue();

    AuthService.login(login, password);
}

export default Submit;
