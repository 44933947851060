import { useIsDesktop } from '../../../../../../utils/useBreakpoints';
import Banking from './logo/Banking.svg';
import Bkash from './logo/Bkash.svg';
import Cepbank from './logo/Cepbank.svg';
import Havale_Express from './logo/Havale_Express.svg';
import Humo from './logo/Humo.svg';
import Mastercard from './logo/Mastercard.svg';
import Nagad from './logo/Nagad.svg';
import Papara from './logo/Papara.svg';
import PayFix from './logo/PayFix.svg';
import Rocket from './logo/Rocket.svg';
import Ucard from './logo/Ucard.svg';
import Uzcard from './logo/Uzcard.svg';
import VIPHavale from './logo/VIPHavale.svg';
import Visa from './logo/Visa.svg';

import './PartnersLogo.scss';

function PartnersLogo() {
    const isDesktop = useIsDesktop();

    const logoListDesktop: string[] = [
        Mastercard,
        Visa,
        Bkash,
        Nagad,
        Rocket,
        Banking,
        VIPHavale,
        Papara,
        Ucard,
        Havale_Express,
        Cepbank,
        PayFix,
        Humo,
        Uzcard,
    ];
    const logoListMobile: string[] = [Mastercard, Visa, Bkash, Nagad, Rocket];

    const list = isDesktop ? logoListDesktop : logoListMobile;
    return (
        <div className="partners-logo">
            {list.map((logo) => (
                <img src={logo} alt="logo" />
            ))}
        </div>
    );
}

export default PartnersLogo;
