import { PaykassmaWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaWithdraw';
import InputService from 'component/Input/service/Input';
import paymentMethod from 'page/Profile/Balance/PaymentMethod/methods';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import AuthService from 'service/Auth';
import GoogleService from 'service/Google';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';

import { translate as t } from '../../../../../../../service/Language';
import { AccountEasypaisaService } from '../../../../../../../service/Validator/AccountEasypaisa';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import PaykassmaWithdrawService, { PaykassmaWithdrawState } from '../../../Paykassma/service/PaykassmaWithdraw';
import WithdrawService from '../../../service/Withdrawal';
import BonusService from '../../../../../../../service/Bonus';
import Directa24WithdrawService, {Directa24WithdrawState} from '../../../Directa24/service/Directa24Withdraw';
import StatusFormPopupService, {StatusForm} from '../../../../Deposit/DepositPopup/service/StatusForm';

enum EasypaisaFormError {
    NOERROR,
    ACCOUNT,
    NAME,
}

class EasypaisaForm {
    paymentMethod: PaymentMethod = paymentMethod.ApayEasypaisa;

    limitValidator = new Validator.limit(0, 1);

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.withdraw.input.amount',
    });

    accountNumber = new InputService({
        title: 'popup.payment.easypaisa.wallet',
        validator: Validator.accountEasypaisa,
        validatorText: 'popup.payment.easypaisa.account.error',
    });

    accountName = new InputService({
        title: 'popup.payment.easypaisa.name',
        validator: Validator.nonEmpty,
        validatorText: 'popup.payment.easypaisa.name',
    });

    valid = new BehaviorSubject<boolean>(false);

    tip = new BehaviorSubject<EasypaisaFormError>(EasypaisaFormError.NOERROR);

    error = new BehaviorSubject<string>('');

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        onAccountNumber: this.onAccountNumber.bind(this),
        onAccountActive: this.onAccountActive.bind(this),
        onAccountName: this.onAccountName.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
        this.accountNumber.value.subscribe(this.handler.onAccountNumber);
        this.accountNumber.active.subscribe(this.handler.onAccountActive);
        this.accountName.value.subscribe(this.handler.onAccountName);
        this.accountName.active.subscribe(this.handler.onAmountActive);
    }

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        const limit = this.paymentMethod.limit.withdraw;
        const isFee = WithdrawService.fee.getValue();
        const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
        this.limitValidator.setLimit(isFee ? isMin : limit.min, limit.max);
    }

    onAmount(value: string) {
        const isValue = this.amount.value.getValue();
        if (isValue) {
            if(!value.indexOf('0', 0)) {
                const current = value.length;
                const done = value.slice(0, current - 1);
                this.amount.value.next(done);
            }
            const isFee = WithdrawService.fee.getValue();
            const isMin = this.paymentMethod.limit.withdraw.min * 1.25;
            if(isFee && +isValue < isMin) {
                this.amount.error.next('profile.balance.withdraw.input.amount');
                this.error.next( 'popup.payment.min.fee')
            } else {
                this.amount.error.next(null);
                this.error.next('');
                this.checkValid();
            }
        } else {
            this.amount.error.next(null);
            this.checkValid();
        }
    }

    onAccountNumber(value: string) {
        if (this.accountNumber.getValue() && !AccountEasypaisaService.validate(value)) {
            this.accountNumber.error.next(t('popup.payment.easypaisa.account.error'));
            this.valid.next(false);
        } else {
            this.checkValid();
            this.accountNumber.error.next(null);
        }
    }

    onAccountName(value: string) {
        this.checkValid();
    }

    onAccountActive(active: boolean) {
        if (active) {
            this.tip.next(EasypaisaFormError.ACCOUNT);
        } else {
            this.tip.next(EasypaisaFormError.NOERROR);
        }
    }

    onAmountActive() {
        this.tip.next(EasypaisaFormError.NOERROR);
    }

    checkValid() {
        const isAmount = this.amount.isValid();
        const isAccountName = this.accountName.isValid();
        const isAccountNumber = this.accountNumber.isValid();
        if (isAmount && isAccountNumber && isAccountName) {
            this.valid.next(true);
        } else this.valid.next(false);
    }

    send() {
        if (this.valid.getValue() && this.paymentMethod) {
            const currencyId = this.paymentMethod.currency.ISO;
            const channelType = this.paymentMethod.id;
            const accountNumber = this.accountNumber.getValue();
            const accountName = this.accountName.getValue();
            const amount = parseInt(this.amount.getValue());
            const request: PaykassmaWithdrawRequest = {
                currencyId,
                channelType,
                accountNumber,
                accountName,
                bankCode: '',
                amount,
            };
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            GoogleService.sentWithdraw(
                userId,
                this.paymentMethod.title,
                amount.toString(),
                this.paymentMethod.currency.name,
                request.accountNumber
            );
            console.log('send', request);
            PaymentService.paykassma.withdraw(request);
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.AWAIT);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const status = PaykassmaWithdrawService.state.getValue();
        const fee = WithdrawService.fee.getValue();
        const isBonuses = BonusService.list.getValue().length;
        console.log('SUBMIT fee', fee);

        if (isBonuses) {
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.BONUS_REJECT);
            StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
        } else {
            if (!!fee) {
                if (status === PaykassmaWithdrawState.HAS_FEE) {
                    this.send();
                }
            } else {
                this.send();
            }
        }
    }

    reset() {
        this.amount.value.next('');
        this.accountNumber.value.next('');
        this.accountName.value.next('');

        this.amount.error.next(null);
        this.accountNumber.error.next(null);
        this.accountName.error.next(null);

        this.accountNumber.active.next(false);
        this.accountName.active.next(false);
        this.error.next('');
        this.tip.next(EasypaisaFormError.NOERROR);
    }
}

const EasypaisaFormService = new EasypaisaForm();
export { EasypaisaFormError };
export default EasypaisaFormService;
