import RouteConfig from "appConstants/RouteConfig";
import {
    Autoplay,
    Navigation,
    Pagination,
    Swiper as SwiperClass,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AdsService from "service/AdsService";
import { HistoryService } from "service/HistoryService";
import AuthService from "service/Auth";
import LanguageService, { translate as t } from "service/Language";
import { useIsDesktop } from "utils/useBreakpoints";
import useObservable from "utils/useObservable";
import GamesService from "service/Games";
import GameHelper from "service/GameHelper";
import { SlotegratorGameMode } from "@ay_tsarbet/newbet-core/dist/connection/actions/actionEnums";
import GoogleService from "service/Google";
import Games from "enums/games";
import BalanceService from "service/Balance";
import UserDataService from "service/UserData";

import "./MainBanner.scss";

enum Auth {
    NONE,
    TRUE,
    FALSE,
}

type BannerType = {
    id: number;
    name: string;
    img: string;
    url: string;
};

function MainBanner() {
    const isDesktop = useIsDesktop();
    const welcomeBonus = RouteConfig.info.root + RouteConfig.info.promo.root;
    const isSuccessTransaction = useObservable(
        AdsService.firstDepositBanner,
        AdsService.firstDepositBanner.getValue()
    );
    const isConfirm = useObservable(
        AuthService.confirmEmail,
        AuthService.confirmEmail.getValue()
    );
    const balance = useObservable(
        BalanceService.balance,
        BalanceService.balance.getValue()
    );
    const balanceRef = useRef<number>(balance);
    const aviatorGame = GamesService.findGame(Games.AVIATOR);
    const thunderCoinGame = GamesService.findGame(Games.THUNDER_COIN);
    const thunderCoinGameMobile = GamesService.findGame(
        Games.THUNDER_COIN_MOBILE
    );
    const isAZ = LanguageService.current.getValue().title === "AZ";
    const lang = useObservable(
        LanguageService.current,
        LanguageService.current.getValue()
    );
    const userData = useObservable(
        UserDataService.data,
        UserDataService.data.getValue()
    );

    const [bannerList, setBannerList] = useState<BannerType[]>([]);
    const [isAuth, setIsAuth] = useState<Auth>(Auth.NONE);
    const bannerListRef = useRef<BannerType[]>([]);
    const currentSlideIndex = useRef<number>(0);

    useEffect(() => {
        balanceRef.current = balance;
    }, [balance]);

    const onClickHandler = (name: string, url: string, position: number) => {
        GoogleService.clickBanner(
            name.toLowerCase(),
            url,
            userData ? userData?.id.toString() : "",
            position
        );
        if (isAuth) {
            if (name === "FirstDeposit") {
                HistoryService.history.push(welcomeBonus);
            }
            if (name === "ConfirmEmail") {
                HistoryService.history.push({
                    hash: RouteConfig.hash.checkEmail,
                });
            }
            if (name === "Crypto") {
                HistoryService.setHash(RouteConfig.hash.deposit);
            }
            if (name === "GloryGames") {
                HistoryService.history.push("glory");
            }
            if (name === "NonStopDrop") {
                HistoryService.history.push("tournament/94");
            }
            if (name === "Hunt") {
                HistoryService.history.push("tournament/107");
            }
            if (name === "Aviator") {
                if (aviatorGame.isReal() && balanceRef.current > 0) {
                    GameHelper.loadGame(
                        aviatorGame,
                        SlotegratorGameMode.REAL,
                        null
                    );
                } else {
                    HistoryService.setHash(RouteConfig.hash.deposit);
                }
            }
            if (name === "ThunderCoin") {
                if (
                    isDesktop
                        ? thunderCoinGame.isReal()
                        : thunderCoinGameMobile.isReal() &&
                          balanceRef.current > 0
                ) {
                    GameHelper.loadGame(
                        isDesktop ? thunderCoinGame : thunderCoinGameMobile,
                        SlotegratorGameMode.REAL,
                        null
                    );
                } else {
                    HistoryService.setHash(RouteConfig.hash.deposit);
                }
            }
        } else {
            HistoryService.history.push({
                hash: RouteConfig.hash.registration,
            });
        }
    };

    const onSlideChangeHandler = useCallback(
        (swiper: SwiperClass) => {
            const newIndex = swiper.realIndex;
            if (newIndex !== currentSlideIndex.current) {
                currentSlideIndex.current = newIndex;
                const currentSlide = bannerListRef.current[newIndex];

                if (isAuth === Auth.TRUE && userData) {
                    const userId = userData.id ? userData.id.toString() : "";
                    const email = userData ? userData.email : "";
                    GoogleService.viewBanner(
                        currentSlide.name.toLowerCase(),
                        email,
                        userId,
                        newIndex + 1
                    );
                } else if (isAuth === Auth.FALSE) {
                    GoogleService.viewBanner(
                        currentSlide.name.toLowerCase(),
                        "",
                        "",
                        newIndex + 1
                    );
                }
            }
        },
        [isAuth, userData]
    );

    useEffect(() => {
        setIsAuth(AuthService.checkAuthOnce() ? Auth.TRUE : Auth.FALSE);
    }, []);

    useEffect(() => {
        const list: BannerType[] = [
            {
                id: 1,
                name: "FirstDeposit",
                img: isDesktop
                    ? "banner.freespins.desktop"
                    : "banner.freespins.mobile",
                url: welcomeBonus,
            },
            {
                id: 12,
                name: 'Hunt',
                img: isDesktop ? 'banner.hunt.desktop' : 'banner.hunt.mobile',
                url: 'tournament/107',
            },
            {
                id: 3,
                name: 'NonStopDrop',
                img: isDesktop ? 'banner.nonstopdrop.desktop' : 'banner.nonstopdrop.mobile',
                url: 'tournament/94',
            },
            {
                id: 9,
                name: "GloryGames",
                img: isDesktop
                    ? "banner.glorygames.desktop"
                    : "banner.glorygames.mobile",
                url: aviatorGame.provider.route,
            },
            {
                id: 2,
                name: "Crypto",
                img: isDesktop
                    ? "banner.crypto.desktop"
                    : "banner.crypto.mobile",
                url: RouteConfig.hash.deposit,
            },
            {
                id: 4,
                name: "Aviator",
                img: isDesktop
                    ? "banner.newaviator.desktop"
                    : "banner.newaviator.mobile",
                url: aviatorGame.provider.route,
            },
            {
                id: 5,
                name: "ConfirmEmail",
                img: isDesktop
                    ? "banner.confirm.desktop"
                    : "banner.confirm.mobile",
                url: RouteConfig.hash.checkEmail,
            },
        ];
        setBannerList(list);
        bannerListRef.current = list;
    }, [isDesktop, aviatorGame.provider.route, welcomeBonus]);

    useEffect(() => {
        if (isSuccessTransaction) {
            const updatedList = bannerListRef.current.filter(
                (item) => item.name !== "FirstDeposit"
            );
            setBannerList(updatedList);
            bannerListRef.current = updatedList;
        }
    }, [isSuccessTransaction]);

    useEffect(() => {
        if (isConfirm) {
            const updatedList = bannerListRef.current.filter(
                (item) => item.name !== "ConfirmEmail"
            );
            setBannerList(updatedList);
            bannerListRef.current = updatedList;
        }
    }, [isConfirm]);

    useEffect(() => {
        if (isAuth === Auth.TRUE && userData) {
            const userId = userData.id ? userData.id.toString() : "";
            const email = userData ? userData.email : "";
            GoogleService.viewBanner(
                bannerList[0].name.toLowerCase(),
                email,
                userId,
                1
            );
        } else if (isAuth === Auth.FALSE) {
            GoogleService.viewBanner(
                bannerList[0].name.toLowerCase(),
                "",
                "",
                1
            );
        }
    }, [isAuth, userData]);

    useEffect(() => {
        if (isAuth === Auth.TRUE && userData) {
            GoogleService.viewMainPage(userData.id.toString());
        } else if (isAuth === Auth.FALSE) {
            GoogleService.viewMainPage("");
        }
    }, [isAuth, userData]);

    useEffect(() => {
        setBannerList((prevList) => {
            let updatedList = [...prevList];
            if (isAZ) {
                updatedList = prevList.filter(
                    (item) => item.name !== "Aviator"
                );
                const newBanner: BannerType = {
                    id: 7,
                    name: "ThunderCoin",
                    img: isDesktop
                        ? "https://diswdgcu9cfva.cloudfront.net/oldFront/banners/thundercoin/thundercoin_desktop_az.webp"
                        : "https://diswdgcu9cfva.cloudfront.net/oldFront/banners/thundercoin/thundercoin_mobile_az.webp",
                    url: "",
                };
                updatedList.splice(1, 0, newBanner);
            } else {
                updatedList = prevList.filter(
                    (item) => item.name !== "ThunderCoin"
                );
                const aviatorBanner: BannerType = {
                    id: 6,
                    name: "Aviator",
                    img: isDesktop
                        ? "banner.newaviator.desktop"
                        : "banner.newaviator.mobile",
                    url: aviatorGame.provider.route,
                };
                if (!updatedList.find((item) => item.name === "Aviator")) {
                    updatedList.push(aviatorBanner);
                }
            }
            bannerListRef.current = updatedList;
            return updatedList;
        });
    }, [isAZ, isDesktop, lang, aviatorGame.provider.route]);

    const swiperComponent = useMemo(
        () =>
            bannerList.length > 0 && (
                <Swiper
                    initialSlide={0}
                    modules={[Autoplay, Pagination, Navigation]}
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                    loop={true}
                    spaceBetween={15}
                    centeredSlides={!isDesktop}
                    slidesPerView={!isDesktop ? 1.1 : 1}
                    className='CarouselBanners__carousel'
                    onSlideChange={onSlideChangeHandler}
                >
                    {bannerList.map((slide, index) => (
                        <SwiperSlide
                            key={slide.id}
                            className='CarouselBanners__slide'
                        >
                            <div className='CarouselBanners__image'>
                                <img
                                    className='CarouselBanners__image_item'
                                    src={t(slide.img)}
                                    alt={slide.name}
                                    onClick={() =>
                                        onClickHandler(
                                            slide.name,
                                            slide.url,
                                            index + 1
                                        )
                                    }
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ),
        [bannerList, isDesktop, onSlideChangeHandler]
    );

    return <div className='CarouselBanners'>{swiperComponent}</div>;
}

export default MainBanner;
