import BonusService from 'service/Bonus';
import useObservable from 'utils/useObservable';
import { OPERATIONS } from 'appConstants/operations';
import { compareAsc } from 'date-fns';

import { translate as t } from 'service/Language';
import WelcomeBonusItem from './WelcomeBonusItem/WelcomeBonusItem';
import AdsService from 'service/AdsService';
import UserDataService from 'service/UserData';
import BonusModel from '../../../service/model/Bonus';
import BonusItem from './BonusItem/BonusItem';
import GamesService from 'service/Games';

import './Bonus.scss';


function ProfileBonus() {
    const currentOperations = useObservable(BonusService.list, BonusService.list.getValue());
    const games = useObservable(GamesService.games, GamesService.games.getValue());
    const isUserReg = useObservable(
        UserDataService.isUserRegTime,
        UserDataService.isUserRegTime.getValue(),
    );
    const isSuccessTransaction = useObservable(
        AdsService.firstDepositBanner,
        AdsService.firstDepositBanner.getValue(),
    );

    return (
        <div className='ProfileBonus'>
            <div className='ProfileBonus__active'>
                <div className='ProfileBonus__title'>{t('bonus.title.active')}</div>
                <div className='ProfileBonus__list'>
                    {currentOperations
                        .filter((bonus: BonusModel) => {
                            const now = new Date();
                            const from = bonus.campaign
                                ? new Date(bonus.campaign?.validFrom * 1000)
                                : 0;
                            if (
                                bonus.channelType === OPERATIONS.SLOTEGRATOR_DEPOSIT ||
                                compareAsc(now, from) === 1
                            ) {
                                return true;
                            }
                        })
                        .sort((a: BonusModel, b: BonusModel) => {
                            return a.channelType === OPERATIONS.SLOTEGRATOR_DEPOSIT ? -1 : 0;
                        })
                        .map((item: BonusModel) => {
                            return <BonusItem key={item.id} bonus={item} games={games} isActive />;
                        })}
                </div>
            </div>
            <div className='ProfileBonus__next'>
                <div className='ProfileBonus__title'>{t('bonus.title.available')}</div>
                <div className='ProfileBonus__list'>
                    {!isSuccessTransaction && isUserReg && <WelcomeBonusItem />}
                    {currentOperations
                        .filter((bonus: BonusModel) => {
                            const now = new Date();
                            const from = bonus.campaign
                                ? new Date(bonus.campaign?.validFrom * 1000)
                                : 0;
                            if (compareAsc(now, from) === -1) {
                                return true;
                            }
                        })
                        .sort((a: BonusModel, b: BonusModel) => {
                            return a.campaign && b.campaign
                                ? compareAsc(a.campaign?.validUntil, b.campaign?.validUntil)
                                : 0;
                        })
                        .map((item: BonusModel) => {
                            return <BonusItem key={item.id} bonus={item} games={games} />;
                        })}
                </div>
            </div>
        </div>
    );
}

export default ProfileBonus;
