import { TournamentList } from '../../service/model/Tournament';
import Card from './Card/Card';
import EmptyCard from './Card/EmptyCard';

import './Running.scss';
import 'swiper/swiper.scss';

type RunningProps = {
    tournamentList: TournamentList;
};

function Running(props: RunningProps) {
    const { tournamentList } = props;

    return (
        <div className="tournament-list-running">
            {tournamentList.map((tournament, index) => (
                <Card key={tournament.id} index={index} tournament={tournament} />
            ))}
            {new Array(tournamentList.length > 2 ? 10 : 0).fill(null).map((v, i) => (
                <EmptyCard key={i} />
            ))}
        </div>
    );
}

export default Running;
