import classNames from 'classnames';
import { Component } from 'react';
import { translate as t } from 'service/Language';

import GoogleService from '../../../../service/Google';
import PopupHelper from '../../../../service/PopupHelper';
import WheelTeen from './WheelTeen/WheelTeen';

import './WheelTeenager.scss';

class WheelTeenager extends Component {
    state = {
        angle: 0,
        idle: true,
        attempts: 2,
        disabled: false,
    };

    handler = {
        spin: this.spin.bind(this),
    };

    componentDidMount() {
        GoogleService.landingOpen('');
    }

    spin() {
        this.setState({ disabled: true, idle: false });
        this.setState({ angle: this.state.attempts === 2 ? 765 : 1440 });

        setTimeout(() => {
            if (this.state.attempts === 2) {
                GoogleService.landingSpin('');
                this.setState({ attempts: 1, idle: true });
                PopupHelper.showWheelWin('teen', this.handler.spin);
            } else {
                GoogleService.landingSpinAgain('');
                this.setState({ attempts: 0 });
                PopupHelper.showWheelRegistration('teen');
            }
            this.setState({ disabled: false });
        }, 4000);
    }

    render() {
        const { attempts, disabled, angle, idle } = this.state;

        const _className = classNames({
            'wheel-teenager__scoreboard': true,
            'wheel-teenager__scoreboard__idle': !idle,
        });

        const lightsFirstClassName = classNames({
            'wheel-teenager__lights': true,
            'wheel-teenager__lights_first': true,
            'wheel-teenager__lights__fast': !idle,
        });
        const lightsSecondClassName = classNames({
            'wheel-teenager__lights': true,
            'wheel-teenager__lights_second': true,
            'wheel-teenager__lights__fast': !idle,
        });

        return (
            <div className="wheel-teenager">
                <div className="wheel-teenager__logo" />
                <div className="wheel-teenager__attempts">
                    <div className="wheel-teenager__attempts_wrapper">
                        <div className="wheel-teenager__title">{t('wheel.attempts')}</div>
                        <div className="wheel-teenager__number">{attempts}</div>
                    </div>
                </div>
                <div className={_className}>
                    <WheelTeen angle={angle} />
                </div>
                <div className="wheel-teenager__frame">
                    <div className={lightsFirstClassName} />
                    <div className={lightsSecondClassName} />
                </div>
                <div className="wheel-teenager__team">
                    <div className="wheel-teenager__boy-left" />
                    <div className="wheel-teenager__boy-right" />
                </div>
                <button
                    className="wheel-teenager__button"
                    onClick={this.handler.spin}
                    disabled={disabled || attempts === 0}
                >
                    <span className="wheel-teenager__button__title">{t('wheel.spin')}</span>
                </button>
            </div>
        );
    }
}

export default WheelTeenager;
