import { useEffect } from 'react';
import useObservable from 'utils/useObservable';
import { PaymentMethod } from '../../../PaymentMethod/types';
import Balance from '../../Balance/Balance';
import ResultForm from '../ResultForm/ResultForm';
import WellxPayWithdrawService, {WellxPayWithdrawState} from '../service/WellxPayWithdraw';
import Phone from './Phone/Phone';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const {paymentMethod} = props;
    const state = useObservable(WellxPayWithdrawService.state, WellxPayWithdrawService.state.getValue());
    console.log('WellxPay')
    useEffect(() => {
        WellxPayWithdrawService.reset();
    }, []);

    if (
        state === WellxPayWithdrawState.INIT ||
        state === WellxPayWithdrawState.AWAIT ||
        state === WellxPayWithdrawState.HAS_FEE
    ) {

            return (
                <>
                    <Balance/>
                    <Phone paymentMethod={paymentMethod}/>
                </>
            );
    } else {
        return <ResultForm/>;
    }
}

export default Form;
