import { BehaviorSubject } from 'rxjs';
import PaymentService from 'service/Payment';

import PhoneFormService from './PhoneForm';
import UpayFormService from '../Form/Upay/service/UpayForm';
import IMPSFormService from './IMPSForm';
import ApayIMPSFormService from '../../Apay/Form/service/IMPSForm';
import BankalfallahFormService from '../../Apay/Form/service/BankalfallahForm';
import PaykassmaEasypaisaFormService from './EasypaisaForm';
import EasypaisaFormService from '../../Apay/Form/service/EasypaisaForm';
import ApayBDTFormService from '../../Apay/Form/service/ApayBDTForm';

enum PaykassmaWithdrawState {
    INIT,
    HAS_FEE,
    AWAIT,
    ACCEPT,
    REJECT,
    BONUS_REJECT,
}

class PaykassmaWithdraw {
    state = new BehaviorSubject<PaykassmaWithdrawState>(PaykassmaWithdrawState.INIT);

    handler = {
        onWithdraw: this.onWithdraw.bind(this),
        onError: this.onError.bind(this),
    };

    constructor() {
        PaymentService.paykassma.withdrawResult.subscribe(this.handler.onWithdraw);
        PaymentService.paykassma.error.subscribe(this.handler.onError);
    }

    onWithdraw() {
        this.state.next(PaykassmaWithdrawState.ACCEPT);
    }

    onError() {
        this.state.next(PaykassmaWithdrawState.REJECT);
    }

    reset() {
        this.state.next(PaykassmaWithdrawState.INIT);
        PhoneFormService.reset();
        UpayFormService.reset();
        IMPSFormService.reset();
        BankalfallahFormService.reset();
        PaykassmaEasypaisaFormService.reset();
        EasypaisaFormService.reset();
        ApayBDTFormService.reset();
        ApayIMPSFormService.reset();

    }
}

const PaykassmaWithdrawService = new PaykassmaWithdraw();

export { PaykassmaWithdrawState };
export default PaykassmaWithdrawService;
