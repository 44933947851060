import classNames from 'classnames';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { translate as t } from 'service/Language';

import Notification from '../../../../../../../component/Notification/Notification';
import PaykassmaInvoice from '../../../../../../../service/model/payment/PaykassmaInvoice';
import { useIsDesktop } from '../../../../../../../utils/useBreakpoints';
import useObservable from '../../../../../../../utils/useObservable';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import { ReactComponent as CopyImg } from '../../images/copy.svg';
import PaykassmaDepositService from '../../service/PaykassmaDeposit';
import Transaction from '../Transaction/Transaction';
import Wallet from './Wallet/Wallet';

import './Account.scss';

type AccountProps = {
    paymentMethod: PaymentMethod;
    invoice: PaykassmaInvoice;
};

function Account({ paymentMethod, invoice }: AccountProps) {
    const state = useObservable(PaykassmaDepositService.state, PaykassmaDepositService.state.getValue());
    const walletType = useObservable(PaykassmaDepositService.walletType, PaykassmaDepositService.walletType.getValue());
    const isDesktop = useIsDesktop();

    const className = classNames({
        'deposit-account__copy': true,
        // 'deposit-account__copy__copied': isCopied,
    });

    const copyInvoice = () => {
        Notification({ message: t('profile.balance.deposit.copied') });
    };

    const bdtMethodList: number[] = [ChannelType.bKash, ChannelType.Nagad];
    const isBDT = bdtMethodList.includes(paymentMethod.id);

    function hint(): string {
        if(paymentMethod.id === ChannelType.Easypaisa_2) {
            return t('profile.balance.deposit.description.easypaisa')
        }
        if(paymentMethod.id === ChannelType.PayTM) {
            return t('popup.hint.paytm')
        }
        if(paymentMethod.id === ChannelType.PhonePe) {
            return t('popup.hint.phonepe')
        } else {
           return t('profile.balance.deposit.description.invoice')
        }
    }

    return (
        <div className="deposit-account">
            <div className="deposit-account__invoice-block">
                <div className="deposit-account__invoice">
                    <span className="deposit-account__invoice-hint">{t('profile.balance.deposit.input.account')}</span>
                    <span className="deposit-account__invoice-value">{invoice.id}</span>
                </div>
                <CopyToClipboard text={invoice.id} onCopy={copyInvoice}>
                    <div className={className}>
                        <CopyImg />
                    </div>
                </CopyToClipboard>
            </div>
            {paymentMethod.id === ChannelType.bKash ? (
                <Wallet type={walletType} />
            ) : (
                <div className="deposit-account__hint">
                    {hint()}
                </div>
            )}
            {isBDT && !isDesktop ? (
                <div className="deposit-account__goto">
                    <a href={paymentMethod.getLink()} target="_blank" rel="noreferrer">
                        <span>{t('profile.balance.deposit.goto.app')}</span>
                        <span className="deposit-account__goto-name">{t(paymentMethod.title)}</span>
                        <img src={paymentMethod.icon} width={14} height={14} alt={paymentMethod.title} />
                    </a>
                </div>
            ) : null}
            <Transaction paymentMethod={paymentMethod} state={state} />
        </div>
    );
}

export default Account;
