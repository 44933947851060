import Validator from '../interface/Validator';

class WalletZoft implements Validator {
    validate(value: string): boolean {
        return value.length === 11;
    }
}
const WalletZoftService = new WalletZoft();

export { WalletZoftService };

export default WalletZoft;
