export enum ListType {
    DEPOSIT,
    WITHDRAW,
}
export enum SystemTypes {
    MANUAL = 1,
    BONUS,
    PAYKASSMA = 4,
    PAYCOS = 7,
    EPUL,
    COREFY = 11,
    INSTAPAYS,
    PAYZOFT,
    PAYFIX,
    ANINDA,
    PAYHERA = 17,
    AZARPAY,
    CONSTANTPOS = 20,
    DIRECT24 = 22,
    PAYES = 24,
    COINSPAID = 26,
    APAY,
    MPAY,
    PAYCORD,
    WELLXPAY,
    VALLERPAY,
    CASHIX = 34,
}
