import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import { formatCurrencySign } from 'utils/format';
import Input from '../../../../../../../component/Input/Input';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { translate as t } from '../../../../../../../service/Language';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import VideoInstruction from '../../../Paykassma/Other/VideoInstruction/VideoInstruction';
import AmountFormService, { InstapayErrorTip, InstapayInputTip } from '../../service/AmountForm';
import Submit from '../Button/Submit';
import SumSelector from '../SummSelector/SumSelector';
import Back from './image/back.png';
import { createRef } from 'react';
import Attention from '../../../Apay/Attention/Attention';

import './Amount.scss';

type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {};

class Amount extends RxComponent<AmountProps, AmountState> {
    state = {
        errorType: InstapayInputTip.NONE,
        errorLetters: InstapayErrorTip.NONE,
    };

    handler = {
        onErrorType: this.onErrorType.bind(this),
        onErrorLetters: this.errorLetters.bind(this),
        clickOutside: this.clickOutside.bind(this),
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    componentDidMount() {
        const { paymentMethod } = this.props;

        AmountFormService.setMethod(paymentMethod);
        paymentMethod.defaultValue && AmountFormService.setAmount(paymentMethod.defaultValue);
        this.subscribe(AmountFormService.errorType, { next: this.handler.onErrorType });
        this.subscribe(AmountFormService.errorLetters, {next: this.handler.onErrorLetters});
        document.addEventListener('pointerdown', this.handler.clickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    close() {
        this.setState({errorLetters: InstapayErrorTip.NONE});
    }

    clickOutside(event: PointerEvent) {
        if (this.ref.self.current && !this.ref.self.current.contains(event.target as Node)) {
            this.close();
        }
    }

    onErrorType(errorType: InstapayInputTip) {
        this.setState({errorType});
    };

    errorLetters(errorLetters: InstapayErrorTip) {
        this.setState({errorLetters});
    }

    render() {
        const { paymentMethod, lock } = this.props;
        const { errorType, errorLetters } = this.state;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        return (
            <div className="deposit-amount-instapay">
                {paymentMethod.id === ChannelType.TRY_CEP_BANK && <VideoInstruction method={paymentMethod} />}
                <form className="deposit-amount-instapay-form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    <div className="deposit-amount-payfix-form-block">
                        <Input
                            className="deposit-amount-instapay-form-block__input"
                            inputService={AmountFormService.name}
                            name="name"
                            enabled={!lock}
                        />

                        {errorType === InstapayInputTip.NAME ? (
                            <span className="deposit-amount-instapay-form__help">
                                {t('popup.payment.name.hint')}
                            </span>
                        ): null}
                        {errorType === InstapayInputTip.PAPARA_NAME && (
                            <span className="card-withdrawal-form-block__help">
                        {t('profile.balance.withdraw.input.help.papara.name')}
                    </span>
                        )}
                        {errorLetters === InstapayErrorTip.NAME ?
                            <div className="deposit-amount-instapay-form-letters" ref={this.ref.self}>
                                <img className="deposit-amount-instapay-form-letters__img" src={Back} alt="back"/>
                                <span
                                    className="deposit-amount-instapay-form-letters__text">{t('popup.payment.turkey.error.letters')}</span>
                        </div> : null}
                    </div>
                    <div className="deposit-amount-instapay-form-block">
                        <Input
                            className="deposit-amount-instapay-form-block__input"
                            inputService={AmountFormService.surname}
                            name="surname"
                            enabled={!lock}
                        />

                        {errorType === InstapayInputTip.SURNAME ? (
                            <span className="deposit-amount-instapay-form__help">
                                {t('popup.payment.name.hint')}
                            </span>
                        ): null}
                        {errorType === InstapayInputTip.PAPARA_SURNAME && (
                            <span className="card-withdrawal-form-block__help">
                        {t('profile.balance.withdraw.input.help.papara.surname')}
                    </span>
                        )}
                        {errorLetters === InstapayErrorTip.SURNAME ?
                            <div className="deposit-amount-instapay-form-letters" ref={this.ref.self}>
                                <img className="deposit-amount-instapay-form-letters__img" src={Back} alt="back"/>
                                <span
                                    className="deposit-amount-instapay-form-letters__text">{t('popup.payment.turkey.error.letters')}</span>
                            </div> : null}
                    </div>
                    {paymentMethod.id === ChannelType.TRY_CREDIT_CARD && (
                        <MaskedInput
                            className="deposit-amount-instapay__input"
                            inputService={AmountFormService.birth}
                            mask="00-00-0000"
                            placeholder="GG-AA-YYYY"
                            name="birth"
                            enabled={!lock}
                        />
                    )}
                    {paymentMethod.id === ChannelType.TRY_CREDIT_CARD ||
                    paymentMethod.id === ChannelType.TRY_CEP_BANK ? (
                        <div className="deposit-amount-instapay-form-block">
                            <Input
                                className="deposit-amount-instapay-form-block__input"
                                inputService={AmountFormService.email}
                                name="surname"
                                enabled={!lock}
                            />
                            {errorType === InstapayInputTip.EMAIL && (
                                <span className="deposit-amount-instapay-form__help">
                                    {t('profile.balance.deposit.input.help.email')}
                                </span>
                            )}
                        </div>
                    ) : null}
                    {paymentMethod.id === ChannelType.TRY_PAPARA || paymentMethod.id === ChannelType.TRY_MFT ? null : (
                        <div className="deposit-amount-instapay-form-block">
                            <MaskedInput
                                className="deposit-amount-instapay-form-block__input"
                                inputService={AmountFormService.amount}
                                name="amount"
                                mask="0000000"
                                hint={hint}
                                enabled={!lock}
                            />
                            <span className="deposit-amount-instapay-form-block__input_currency">
                                {paymentMethod.currency.sign}
                            </span>
                        </div>
                    )}
                </form>
                {paymentMethod.id === ChannelType.TRY_PAPARA || paymentMethod.id === ChannelType.TRY_MFT ? null : (
                    <div className="deposit-amount-instapay__sum">
                        <SumSelector paymentMethod={paymentMethod} disabled={lock} />
                    </div>
                )}
                <div className="deposit-amount-instapay__submit">
                    <Submit />
                </div>
            </div>
        );
    }
}

export default Amount;
