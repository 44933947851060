import classNames from 'classnames';
import { translate as t } from 'service/Language';

import Select from '../../../../../component/Select/Select';
import { OptionInterface } from '../../../../../component/Select/Select.type';
import Country, { countryList } from '../../../../../service/model/Country';
import PersonalDataFormService from '../service/PersonalDataForm';

import './CountryList.scss';

type CountryOption = OptionInterface<Country>;

function countryOptionAdapter(country: Country): CountryOption {
    return {
        getValue() {
            return country;
        },
        getId(): string {
            return country.name;
        },
        getName(): string {
            return country.name;
        },
    };
}

const options = countryList.map(countryOptionAdapter);
const optionsMap = new Map<Country, CountryOption>(options.map((option) => [option.getValue(), option]));

type CountryListProps = {
    current: Country;
    disabled?: boolean;
    error: string;
};

function CountryList({ current, disabled, error = '' }: CountryListProps) {
    const className = classNames('country-list', { 'country-list____error': !!error });
    const currentCountry = optionsMap.get(current) || options[0];

    const onSelect = (option: CountryOption) => {
        PersonalDataFormService.setCountry(option.getValue());
    };

    return (
        <div className={className}>
            <span className="country-list__title">{error ? t(error) : t('profile.account.country')}</span>
            <Select
                className="country-list__select"
                onSelect={onSelect}
                current={currentCountry}
                list={options}
                placeholder={t('profile.account.country')}
                disabled={disabled}
            />
        </div>
    );
}

export default CountryList;
