import { OptionInterface } from '../../../../../component/Select/Select.type';
import Currency from '../../../../../service/model/Currency';

class CurrencyOption implements OptionInterface<Currency> {
    private readonly currency: Currency;

    constructor(currency: Currency) {
        this.currency = currency;
    }

    getId() {
        return this.currency.name;
    }

    getName(): string {
        return this.currency.name;
    }

    getValue(): Currency {
        return this.currency;
    }
}

export default CurrencyOption;
