import useObservable from 'utils/useObservable';

import { PaymentMethod } from '../../../PaymentMethod/types';
import ResultForm from '../ResultForm/ResultForm';
import PaycosWithdrawService, { PaycosWithdrawState } from '../service/PaycosWithdraw';
import Card from './Card/Card';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(PaycosWithdrawService.state, PaycosWithdrawService.state.getValue());

    if (paymentMethod) {
        if (state === PaycosWithdrawState.CARD || state === PaycosWithdrawState.HAS_FEE) {
            return <Card paymentMethod={paymentMethod} />;
        } else {
            return <ResultForm />;
        }
    }

    return null;
}

export default Form;
