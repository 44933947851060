import classNames from 'classnames';
import { PointerEvent, ReactElement } from 'react';

import { HistoryService } from '../../service/HistoryService';
import PopupService from '../../service/Popup';
import UIService from '../../service/UI';
import RxComponent from '../RxComponent/RxComponent';

import './Popup.scss';
import Portal from 'component/Portal';

type PopupProps = {};
type PopupState = {
    component: ReactElement | null;
};

class Popup extends RxComponent<PopupProps, PopupState> {
    handler = {
        setComponent: this.setComponent.bind(this),
        setRef: this.setRef.bind(this),
        onClick: this.onClick.bind(this),
    };

    ref: {
        container: HTMLDivElement | null;
    } = {
        container: null,
    };

    constructor(props: PopupProps) {
        super(props);

        this.state = {
            component: null,
        };
    }

    setRef(ref: HTMLDivElement) {
        this.ref.container = ref;
    }

    onClick(event: PointerEvent<HTMLDivElement>) {
        const container = this.ref.container;

        if (container && !container.contains(event.target as Node)) {
            PopupService.close();
            HistoryService.clearHash();
        }
    }

    setComponent(component: ReactElement | null) {
        this.setState((prevState, props) => {
            return prevState.component === component ? null : { component };
        });
    }

    componentDidMount() {
        this.subscribe(PopupService.component, { next: this.handler.setComponent });
    }

    render() {
        const { component } = this.state;

        const className = classNames({
            popup: true,
            popup____visible: component !== null,
        });

        return (
            // @ts-ignore
            <Portal>
                <div className={className} onClick={this.handler.onClick}>
                    <div className='popup__anti-over-scroll' ref={this.handler.setRef}>
                        {component}
                    </div>
                </div>
            </Portal>
        );
    }
}

export default Popup;
