import {
    PaykassmaBkashP2CDepositRequest
} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaBkashP2CDepositRequest';
import { PaykassmaDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaDepositRequest';
import {
    PaykassmaEasypaisaDepositRequest
} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaEasypaisaDepositRequest';
import { PaykassmaInvoiceRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaInvoiceRequest';
import {
    PaykassmaNagadP2CDepositRequest
} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaNagadP2CDepositRequest';
import { PaykassmaPayment } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaPaymentCheck';
import { PaykassmaWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaWithdraw';

import PaykassmaInterface from '../../interface/payment/Paykassma';
import {ApayBDTDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendApayBDTDepositRequest';

interface PaykassmaHandler {
    byCard(request: PaykassmaDepositRequest): void;
    depositNagadP2C(request: PaykassmaNagadP2CDepositRequest): void;
    depositBKashP2C(request: PaykassmaBkashP2CDepositRequest): void;
    depositEasypaisa(request: PaykassmaEasypaisaDepositRequest): void;
    depositApayBDTRequest(request: ApayBDTDepositRequest): void;
    createInvoice(invoice: PaykassmaInvoiceRequest): void;
    checkPayment(payment: PaykassmaPayment): void;
    withdraw(withdraw: PaykassmaWithdrawRequest): void;
}

class PaykassmaProvider implements PaykassmaInterface {
    handler: PaykassmaHandler;

    constructor(handler: PaykassmaHandler) {
        this.handler = handler;
    }

    byCard(request: PaykassmaDepositRequest): void {
        this.handler.byCard(request);
    }

    depositNagadP2C(request: PaykassmaNagadP2CDepositRequest): void {
        this.handler.depositNagadP2C(request);
    }

    depositBKashP2C(request: PaykassmaBkashP2CDepositRequest): void {
        this.handler.depositBKashP2C(request);
    }

    depositEasypaisa(request: PaykassmaEasypaisaDepositRequest): void {
        this.handler.depositEasypaisa(request);
    }
    depositApayBDTRequest(request: ApayBDTDepositRequest): void {
        this.handler.depositApayBDTRequest(request);
    }

    createInvoice(invoiceRequest: PaykassmaInvoiceRequest) {
        this.handler.createInvoice(invoiceRequest);
    }

    checkPayment(payment: PaykassmaPayment) {
        this.handler.checkPayment(payment);
    }

    withdraw(withdraw: PaykassmaWithdrawRequest) {
        this.handler.withdraw(withdraw);
    }
}

export type { PaykassmaHandler };
export default PaykassmaProvider;
