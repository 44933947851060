import { VFC } from 'react';

import './loadingSpinner.scss';

const LoadingSpinner: VFC = () => (
    <div className="loading-spinner-ripple">
        <div />
        <div />
    </div>
);

export default LoadingSpinner;
