import { AnindaHavaleWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendAnindaHavaleWithdrawal';

class PayfixWithdraw {
    currencyId: number;

    channelType: number;

    amount: number;

    name: string;

    surname: string;

    iban: string;

    banksId: number;

    constructor(data: AnindaHavaleWithdrawalRequest) {
        this.currencyId = data.currencyId;
        this.channelType = data.channelType;
        this.amount = data.amount;
        this.name = data.name;
        this.surname = data.surname;
        this.iban = data.iban;
        this.banksId = data.banksId;
    }
}

export default PayfixWithdraw;
