import { BehaviorSubject } from 'rxjs';

import PaymentService from '../../../../../../service/Payment';
import { PaymentMethod } from '../../../PaymentMethod/types';
import AmountFormService from './AmountForm';
import StatusFormPopupService, { StatusForm } from '../../DepositPopup/service/StatusForm';


enum DepositState {
    AMOUNT,
    WAIT,
    FAILED,
    SUCCESS,
}

class HavaleHeraDeposit {
    state = new BehaviorSubject<DepositState>(DepositState.AMOUNT);

    request(paymentMethod: PaymentMethod, name: string, surname: string, amount: number) {
        this.state.next(DepositState.WAIT);
        const userName = name + surname;
        PaymentService.havaleHera.deposit({
            name,
            surname,
            userName,
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
            amount,
        });
    }

    reset() {
        this.state.next(DepositState.AMOUNT);
        AmountFormService.reset();
    }

    onError() {
        this.state.next(DepositState.FAILED);
    }

    onReceive(link: string) {
        const state = this.state.getValue();
        if (state === DepositState.WAIT) {
            window.open(link, '_self');
        }
    }

    back() {
        this.reset();
    }
}

const HavaleHeraDepositService = new HavaleHeraDeposit();

export { DepositState };
export default HavaleHeraDepositService;
