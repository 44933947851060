import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import RouteConfig from '../../appConstants/RouteConfig';
import DepositPopup from '../../page/Profile/Balance/Deposit/DepositPopup/DepositPopup';
import FailedDepositPopup from '../../page/Profile/Balance/Deposit/ResultPage/FailedPage/FailedPage';
import SuccessDepositPopup from '../../page/Profile/Balance/Deposit/ResultPage/SuccessPage/SuccessPage';
import WithdrawalPopup from '../../page/Profile/Balance/Withdrawal/WithdrawalPopup/WithdrawalPopup';
import AuthService from '../../service/Auth';
import GoogleService from '../../service/Google';
import Popup from '../../service/Popup';
import PopupHelper from '../../service/PopupHelper';
import useObservable from '../../utils/useObservable';
import Recovery from '../Recovery/Recovery';
import ResetPassword from '../ResetPassword/ResetPassword';
import CheckEmailPopup from '../CheckEmailPopup/CheckEmailPopup';
import SuccessSentEmailPopup from '../SuccessSentEmailPopup/SuccessSentEmailPopup';

function HashRoute() {
    const location = useLocation();
    const auth = useObservable(AuthService.auth, AuthService.auth.getValue());

    useEffect(() => {
        const hash = location.hash.substring(1);
        console.log('hash', hash, auth);
        switch (true) {
            case hash === RouteConfig.hash.auth:
                if (!auth) {
                    console.log('LOGIN');
                    PopupHelper.showLogin();
                    GoogleService.login();
                }
                break;
            case hash === RouteConfig.hash.registration:
                if (!auth) {
                    console.log('REGISTRATION');
                    PopupHelper.showRegistration();
                    GoogleService.registration();
                }
                break;
            case hash === RouteConfig.hash.recovery:
                Popup.show(<Recovery />);
                break;
            case hash.startsWith(RouteConfig.hash.reset):
                Popup.show(<ResetPassword />);
                break;
            case hash === RouteConfig.hash.lost:
                PopupHelper.showLostConnection();
                break;
            case hash === RouteConfig.hash.deposit:
                Popup.show(<DepositPopup />);
                break;
            case hash === RouteConfig.hash.withdrawal:
                Popup.show(<WithdrawalPopup />);
                break;
            case hash === RouteConfig.hash.successDeposit:
                Popup.show(<SuccessDepositPopup />);
                break;
            case hash === RouteConfig.hash.failedDeposit:
                Popup.show(<FailedDepositPopup />);
                break;
            case hash === RouteConfig.hash.checkEmail:
                Popup.show(<CheckEmailPopup />);
                break;
            case hash === RouteConfig.hash.successSentEmail:
                Popup.show(<SuccessSentEmailPopup />);
                break;
            default:
                Popup.close();
        }
    }, [location, auth]);

    return null;
}

export default HashRoute;
