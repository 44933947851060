/* eslint-disable max-len */

import { Icon } from 'types';

function CasinoBlackjack({ color, width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0)">
                <path
                    d="M3.86824 6.17376L4.31062 7.82464C4.3824 8.09319 4.39753 8.30071 4.35554 8.44768C4.34675 8.47893 4.33552 8.50725 4.32185 8.53313C4.27693 8.61565 4.20515 8.66985 4.10749 8.69622C3.97712 8.73089 3.85993 8.70354 3.75642 8.61419C3.65681 8.52386 3.56794 8.33392 3.49079 8.04436L3.04841 6.39349C2.97077 6.10442 2.95271 5.89544 2.99372 5.76751C3.03865 5.63811 3.12605 5.55608 3.25691 5.52093C3.52839 5.44817 3.73249 5.66595 3.86824 6.17376Z"
                    fill={color ?? 'url(#paint0_linear)'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.08426 2.74487L1.03669 4.63323C0.292064 4.83294 -0.150319 5.58929 0.0469464 6.32561L4.50984 22.9809C4.70661 23.7172 5.46833 24.1513 6.21296 23.9516L12.4444 22.2819L4.83861 20.2439C4.09418 20.0445 3.65162 19.2877 3.84887 18.5516L8.08426 2.74487ZM4.70759 7.63323L4.30769 6.14104C4.18757 5.69329 4.03279 5.40374 3.84284 5.27337C3.65534 5.13811 3.42732 5.10686 3.15925 5.17864C3.04304 5.20989 2.94294 5.25433 2.85896 5.31243C2.75154 5.38714 2.66999 5.48382 2.61482 5.60247C2.51911 5.8095 2.53132 6.13714 2.65144 6.58489L3.05134 8.07708C3.17146 8.52532 3.32527 8.81682 3.51228 8.95159C3.70271 9.08245 3.92976 9.11224 4.19441 9.04143C4.46247 8.96966 4.6446 8.83001 4.74031 8.62298C4.76472 8.56975 4.7823 8.50921 4.79353 8.44134C4.80281 8.38372 4.8072 8.32073 4.80671 8.25237C4.80622 8.18499 4.80085 8.11272 4.7906 8.03509C4.77449 7.91399 4.74665 7.7802 4.70759 7.63323ZM2.70124 9.14934L1.76081 5.6386L1.41365 5.73138L1.00495 6.18987C0.986888 6.21038 0.980052 6.23919 0.987376 6.26556C0.999095 6.3095 1.04353 6.33538 1.08747 6.32366L1.46345 6.22259L2.2779 9.26263C2.30671 9.36956 2.41609 9.43255 2.52302 9.40423L2.56013 9.39446C2.66658 9.36565 2.73005 9.25579 2.70124 9.14934Z"
                    fill={color ?? 'url(#paint1_linear)'}
                />
                <path
                    d="M14.3903 2.09781C14.0818 1.57846 13.4511 1.30659 12.8326 1.47259L12.4448 1.5765L14.3903 2.09781Z"
                    fill={color ?? 'url(#paint2_linear)'}
                />
                <path
                    d="M11.7045 4.6922L10.5834 4.3918L11.6164 2.81945L11.7045 4.6922Z"
                    fill={color ?? 'url(#paint3_linear)'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.8671 3.55828L11.0713 0.397602C10.3269 0.198134 9.56524 0.632215 9.36799 1.36837L4.9052 18.0238C4.70795 18.7599 5.15051 19.5167 5.89493 19.7161L17.6908 22.8768C18.4352 23.0763 19.1968 22.6422 19.3941 21.906L23.8569 5.25065C24.0541 4.5145 23.6116 3.75775 22.8671 3.55828ZM9.99325 5.51714L10.3897 4.83933L11.657 5.17891L11.6683 5.9594C11.668 5.97349 11.6678 5.99717 11.6932 6.02117C11.7104 6.03792 11.7404 6.05453 11.7903 6.06793C11.8837 6.09295 11.9956 6.08299 12.0743 6.0515C12.1229 6.03219 12.1592 6.00449 12.1699 5.97198C12.1713 5.96679 12.1742 5.95594 12.1719 5.94371L12.0497 2.54378C12.0476 2.42139 11.8984 2.32934 11.7517 2.29003C11.6111 2.25237 11.4354 2.25735 11.3729 2.36243L9.56657 5.2456C9.56518 5.25079 9.56285 5.25573 9.56004 5.26053C9.55711 5.26582 9.55418 5.2711 9.55266 5.27676C9.53182 5.3753 9.69298 5.5115 9.83353 5.54916C9.88541 5.56306 9.91975 5.56317 9.94307 5.55728C9.9615 5.55262 9.97303 5.54357 9.98087 5.53506C9.98653 5.52868 9.99047 5.52192 9.99325 5.51714ZM14.0393 15.3314C14.9402 16.0196 16.1671 16.0986 17.149 15.5317C18.488 14.7587 18.9495 13.0362 18.1656 11.6944L15.6919 7.40907C15.6493 7.3284 15.5769 7.27412 15.4901 7.25087C15.409 7.22913 15.3121 7.24361 15.2368 7.28711L10.9498 9.7897C9.97444 10.3528 9.4328 11.4442 9.56756 12.5656C9.75776 14.1083 11.1592 15.2037 12.7019 15.0135L11.8101 16.1328C11.752 16.1986 11.7273 16.2906 11.7382 16.3804C11.7472 16.4561 11.7822 16.5236 11.8332 16.5747C11.8796 16.6205 11.9389 16.6536 12.0046 16.6671C12.039 16.6744 12.0754 16.6765 12.1124 16.6723C12.6877 16.606 13.2617 16.7598 13.7263 17.1047C13.7398 17.1149 13.7541 17.1238 13.7688 17.1313C13.7895 17.1424 13.8111 17.1507 13.8337 17.1568C13.8793 17.1695 13.9281 17.1724 13.9772 17.1664C14.0504 17.1557 14.1149 17.1204 14.1642 17.0704C14.2369 16.9974 14.2758 16.8915 14.2598 16.7836L14.0393 15.3314Z"
                    fill={color ?? 'url(#paint4_linear)'}
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="0.543268"
                    y1="5.54082"
                    x2="20.667"
                    y2="10.3947"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="0.543268"
                    y1="5.54082"
                    x2="20.667"
                    y2="10.3947"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="0.543268"
                    y1="5.54082"
                    x2="20.667"
                    y2="10.3947"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="0.543268"
                    y1="5.54082"
                    x2="20.667"
                    y2="10.3947"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear"
                    x1="0.543268"
                    y1="5.54082"
                    x2="20.667"
                    y2="10.3947"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default CasinoBlackjack;
