import RouteConfig from 'appConstants/RouteConfig';
import classNames from 'classnames';
import { format, secondsToHours } from 'date-fns';
import { useEffect, useState } from 'react';
import { translate as t } from 'service/Language';
import BonusModel from 'service/model/Bonus';
import Bonus from 'service/model/Bonus';
import { formatCurrencySign } from 'utils/format';
import ImageBonus from '../images/image_bonus.png';
import ProgressBar from '../ProgressBar/ProgressBar';
import { GameList } from 'service/model/Game';
import { OPERATIONS } from 'appConstants/operations';
import Timer from 'component/BonusWidget/Timer/Timer';
import { SlotegratorGameMode } from '@ay_tsarbet/newbet-core/dist/connection/actions/actionEnums';
import GameHelper from 'service/GameHelper';
import useObservable from 'utils/useObservable';
import UserDataService from 'service/UserData';
import { useHistory } from 'react-router-dom';
import ScrollService from '../../../../component/ScrollTopButton/service/Scroll';
import PopupHelper from '../../../../service/PopupHelper';

import './BonusItem.scss';

function BonusItem(props: { bonus: BonusModel; games: GameList; isActive?: boolean }) {
    const {
        amount,
        rolled,
        fullRolled,
        startDate,
        finishDate,
        channelType,
        title,
        percentOfDeposit,
        campaign,
    } = props.bonus;

    const [isOpen, setOpenCollapse] = useState(false);
    const userData = useObservable(UserDataService.data, UserDataService.data.getValue());
    const [currentWidth, setCurrentWidth] = useState(0);
    const history = useHistory();

    useEffect(() => {
        const widthProgress =
            !!fullRolled && (rolled === 0 || !!rolled) ? (rolled * 100) / fullRolled : 0;
        setCurrentWidth(widthProgress);
    }, [fullRolled, rolled]);

    if (currentWidth >= 100) return null;

    const game = props.games.find(game => game.id === campaign?.gameId);

    const handlePlay = () => {
        if (game) {
            GameHelper.loadGame(game, SlotegratorGameMode.REAL, false);
        } else {
            history.push(RouteConfig.casino.root);
            ScrollService.scrollToSmoothly(0, 500);
        }
    };

    const handleCancel = (bonus: Bonus) => {
        PopupHelper.showPopupCancel(bonus);
    };

    const duration =
        finishDate && startDate
            ? secondsToHours(finishDate - startDate)
            : campaign
            ? secondsToHours(campaign?.validUntil - campaign.validFrom)
            : 0;

    const ending = finishDate
        ? new Date(finishDate * 1000)
        : campaign
            ? new Date(campaign?.validUntil * 1000)
            : null;

    let header = 'bonus.welcome.card.header';
    if (game?.id === 2458) {
        header = 'bonus.freespin.first';
    }
    if (game?.id === 4221) {
        header = 'bonus.freespin.second';
    }
    if (game?.id === 1919) {
        header = 'bonus.freespin.third';
    }
    if (game?.id === 2600) {
        header = 'bonus.freespin.fourth';
    }
    if (game?.id === 1973) {
        header = 'bonus.freespin.fifth';
    }

    const bonusClass = classNames('BonusItem', {
        flip: isOpen,
        inactive: !props.isActive,
    });

    const bonusButtonClass = classNames('BonusItem__controls', {
        'BonusItem__controls_inactive': !props.isActive
    });

    function getRemainderRolled() {
        if(fullRolled) {
            if(rolled) {
                return fullRolled - rolled
            } else {
                return fullRolled
            }
        } else return 0
    }

    return (
        <div className={bonusClass}>
            {!isOpen
                ? <div className="BonusItem__front">
                    <div className="BonusItem__header">
                        <div className="BonusItem__header_title">{t(header)}</div>
                        <div className="BonusItem__header_more">
                            {props.isActive && (
                                <button className="tip"
                                        onClick={() => setOpenCollapse(true)} />
                            )}
                        </div>
                    </div>
                    <div className="BonusItem__body">
                        <div className="BonusItem__info">
                            <div className="BonusItem__info_game">
                                <img
                                    src={
                                        channelType === OPERATIONS.SLOTEGRATOR_FREESPIN
                                            ? game?.image
                                            : ImageBonus
                                    }
                                    alt={game?.name}
                                />
                            </div>
                            <div className="BonusItem__info_data">
                                <div className="BonusItem__title">
                                    {percentOfDeposit ? `${percentOfDeposit}% ${t(title)}` : t(title)}
                                </div>
                                <div className="BonusItem__game">{game ? game.name : ''}</div>
                                <div className="BonusItem__list">
                                    <div className="BonusItem__list_item">
                                        <div className="BonusItem__list_item_name">
                                            {channelType === OPERATIONS.SLOTEGRATOR_FREESPIN
                                                ? t('bonus.block.attribute.spins')
                                                : t('bonus.block.attribute.reward')}
                                        </div>
                                        <div className="BonusItem__list_item_value">
                                            {channelType === OPERATIONS.SLOTEGRATOR_FREESPIN
                                                ? campaign?.quantity
                                                : formatCurrencySign(
                                                    amount,
                                                    0,
                                                    userData ? userData.currency : null,
                                                )}
                                        </div>
                                    </div>
                                    <div className="BonusItem__list_item">
                                        <div className="BonusItem__list_item_name">
                                            {t('bonus.block.attribute.duration')}
                                        </div>
                                        <div className="BonusItem__list_item_value">{duration}h</div>
                                    </div>
                                    {!props.isActive && <div className="BonusItem__list_item">
                                        <div className="BonusItem__list_item_name">
                                            {t('bonus.block.attribute.upcoming')}
                                        </div>
                                        <div className="BonusItem__list_item_value">
                                            {campaign ? format(campaign?.validFrom * 1000, 'dd.MM.yyyy') : ''}
                                        </div>
                                    </div>}
                                    {props.isActive && (
                                        <div className="BonusItem__list_item">
                                            <div className="BonusItem__list_item_name">
                                                {t('bonus.block.attribute.ending')}
                                            </div>
                                            <div className="BonusItem__list_item_value">
                                                <Timer
                                                    finish={ending}
                                                    className="BonusItem__list_item_value"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {props.isActive && (
                            <div className="BonusItem__progress">
                                <div className="BonusItem__progress_title">
                                    {t('bonus.block.progress')}
                                </div>
                                <div className="BonusItem__progress_bar">
                                    <ProgressBar width={currentWidth}/>
                                </div>
                            </div>
                        )}
                        <div className={bonusButtonClass}>
                            <button className="BonusItem__controls_secondary" onClick={()=> handleCancel(props.bonus)}>
                                {t('bonus.block.discard')}
                            </button>
                            {props.isActive ? <button className="BonusItem__controls_primary" onClick={handlePlay}>
                                {t('bonus.block.play')}
                            </button>: null}
                        </div>
                    </div>
                </div>

                : <div className="BonusItem__back">
                    <div className="BonusItem__back_title">
                        {t('bonus.info.header')}{' '}
                        <button className="close" onClick={() => setOpenCollapse(false)} />
                    </div>
                    <div className="BonusItem__back_active">
                        <div className="BonusItem__back_desc_bold">{t('bonus.info.valid')}</div>
                        <div className="BonusItem__back_desc_text text">
                            {finishDate
                                ? format(new Date(finishDate * 1000), 'HH:mm d.M ')
                                : campaign
                                    ? format(new Date(campaign?.validUntil * 1000), 'HH:mm d.M')
                                    : ''}
                        </div>
                    </div>
                    <div className="BonusItem__back_desc">
                    <span className="BonusItem__back_desc_text">
                        {t('bonus.info.desc.1')} {t('bonus.info.desc.2')}&nbsp;
                    </span>
                        <span className="BonusItem__back_desc_bold">
                        {t('bonus.info.desc.3.dep')}{' '}
                            {getRemainderRolled()}
                            &nbsp;
                    </span>
                        <span className="BonusItem__back_desc_text">
                        {t('bonus.info.desc.4')} {game?.name} {t('bonus.info.desc.5')}&nbsp;
                            {t('bonus.info.desc.6')}&nbsp;
                    </span>
                        <span className="BonusItem__back_desc_bold">{t('bonus.info.desc.7')}</span>
                    </div>
                </div>
            }
        </div>
    );
}

export default BonusItem;
