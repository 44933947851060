import { useEffect } from 'react';
import GoogleService from 'service/Google';
import { translate as t } from 'service/Language';
import OperationsService from 'service/Operations';
import useObservable from 'utils/useObservable';

import { PaymentMethod } from '../../../PaymentMethod/types';

import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import HavaleHeraDepositService, { DepositState } from '../service/HavaleHeraDeposit';
import Amount from './Amount/Amount';
import FailedPage from '../FailedPage/FailedPage';

import './Form.scss';


type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(HavaleHeraDepositService.state, HavaleHeraDepositService.state.getValue());

    useEffect(() => {
        const gaSub = OperationsService.invoice.subscribe({
            next: (data) => {
                const clientId = GoogleService.getClientId();
                const invoiceId = data.id.toString();
                OperationsService.sendGAClientRelation(clientId, invoiceId);
            },
        });
        return () => {
            gaSub.unsubscribe();
            HavaleHeraDepositService.reset();
        };
    }, []);

    return (
        <div className="deposit-form">
            {state !== DepositState.SUCCESS && state !== DepositState.FAILED  ? <div className="deposit-form-header">
                <img className="deposit-form-header__img" src={paymentMethod.icon} alt={paymentMethod.title}/>
                <h2 className="deposit-form-header__title">{t(paymentMethod.title)}</h2>
            </div> : null}
            {(state === DepositState.AMOUNT || state === DepositState.WAIT) && (
                <Amount paymentMethod={paymentMethod} lock={state !== DepositState.AMOUNT} />
            )}

            {state === DepositState.SUCCESS ? <SuccessPage /> : null}
            {state === DepositState.FAILED ? <FailedPage /> : null}
        </div>
    );
}

export default Form;
