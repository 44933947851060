import { useLayoutEffect, useState } from 'react';

import './mediaMarker.scss';

export enum Breakpoint {
    XS = 0,
    S = 1,
    M = 2,
    L = 3,
}

const BreakpointToMarkerMap = {
    xs: Breakpoint.XS,
    s: Breakpoint.S,
    m: Breakpoint.M,
    l: Breakpoint.L,
};

const DEFAULT_BREAKPOINT = Breakpoint.M;

const initMediaMarker = () => {
    let marker = document.querySelector('.media-marker');
    if (!marker) {
        marker = document.createElement('div');
        marker.className = 'media-marker';
        document.body.appendChild(marker);
    }
    return marker;
};

const mediaMarker = initMediaMarker();

const getCurrentBreakpoint = (): Breakpoint => {
    const style = window.getComputedStyle(mediaMarker);
    const value = style.getPropertyValue('font-family').replace(/['"]/g, '') as keyof typeof BreakpointToMarkerMap;

    if (!value) {
        return DEFAULT_BREAKPOINT;
    }

    return BreakpointToMarkerMap[value];
};

const useBreakpoint = () => {
    const [breakpoint, setBreakpoint] = useState(getCurrentBreakpoint());

    useLayoutEffect(() => {
        const resizeHandler = () => setBreakpoint(getCurrentBreakpoint());
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    return breakpoint;
};

export { useBreakpoint };
export const useIsXS = () => useBreakpoint() === Breakpoint.XS;
export const useIsDesktop = () => useBreakpoint() > Breakpoint.S;
