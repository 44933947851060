import AcceptCheckbox from "./AcceptCheckbox/AcceptCheckbox";
import AgeCheckbox from "./AgeCheckbox/AgeCheckbox";
import CurrencyList from "./CurrencyList/CurrencyList";
import Email from "./Email/Email";
import HaveAccountSection from "./HaveAccountSection/HaveAccountSection";
import Header from "./Header/Header";
import Password from "./Password/Password";
import RegistrationFormService from "./service/RegistrationForm";
import SignUpButton from "./SignUpButton/SignUpButton";

import "./Form.scss";

interface FormProps {
  isVisibleHeader?: boolean;
}

function Form({ isVisibleHeader = true }: FormProps) {
  return (
    <form
      className="registration-form"
      onSubmit={(e) => RegistrationFormService.submit(e)}
    >
      {isVisibleHeader && <Header />}
      <Email />
      <Password />
      <CurrencyList />
      <SignUpButton />
      <HaveAccountSection />
      <AcceptCheckbox />
      <AgeCheckbox />
    </form>
  );
}

export default Form;
