import { BehaviorSubject, map, Observable } from 'rxjs';
import Validator from '../../../service/interface/Validator';


type InputConfig = {
    title: string;
    validator?: Validator;
    validatorText?: string;
};

class Input {
    value = new BehaviorSubject<string>('');

    touched = new BehaviorSubject<boolean>(false);

    active = new BehaviorSubject<boolean>(false);

    error = new BehaviorSubject<string | null>(null);

    autoCompleted = new BehaviorSubject<boolean>(false);

    handler = {
        validate: this.validate.bind(this),
    };

    valid = this.value.pipe(map(this.handler.validate));

    title: Observable<string>;

    defaultTitle = '';

    validator: Validator | null = null;

    validatorText = '';

    constructor(config: InputConfig) {
        this.defaultTitle = config.title;
        this.title = this.error.pipe(map((error) => error ?? this.defaultTitle));
        if (config.validator) {
            this.validator = config.validator;
        }
        if (config.validatorText) {
            this.validatorText = config.validatorText;
        }
    }

    getValue(): string {
        return this.value.getValue();
    }

    onChange(event: any, isUpperCase: boolean) {
        const active = this.active.value;
        if (event.target) {
            const text = isUpperCase ? event.target.value.toUpperCase() : event.target.value;
            this.value.next(text);
        } else {
            const text = isUpperCase ? event.toUpperCase() : event;
            this.value.next(text);
        }

        if (!active) {
            this.active.next(true);
        }

        if (this.autoCompleted.getValue()) {
            this.autoCompleted.next(false)
        }
    }

    validate(text: string): boolean {
        return this.validator ? this.validator.validate(text) : true;
    }

    isValid(): boolean {
        return this.validate(this.value.getValue());
    }

    onBlur() {
        const text = this.value.value;
        this.touched.next(true);

        if (text && this.validator ) {
            const valid = this.validate(text);
            this.error.next(valid ? null : this.validatorText);
        }
        if (!text) {
            this.active.next(false);
        }
        if (!this.error.getValue()) {
            this.error.next(null);
        }
    }

    onFocus() {
        this.active.next(true);
    }
}

export default Input;
