import classNames from 'classnames';
import SvgImage from 'svg';

import { SvgNames } from '../../../../types';

import './Item.scss';

type TopInfoItemProps = {
    value: string | number;
    title: string;
    type: string;
    icon: SvgNames;
};

function Item(props: TopInfoItemProps) {
    const { icon, value, title, type } = props;

    const itemClass = classNames('top-info-item', { [type]: type });

    return (
        <div className={itemClass}>
            <div className="top-info-item__icon-frame">
                <SvgImage height={18} width={18} name={icon} />
            </div>
            <div className="top-info-item__container">
                <div className="top-info-item__container-title">{title}</div>
                <div className="top-info-item__container-value">{value}</div>
            </div>
        </div>
    );
}

export default Item;
