import Input, { InputSize } from 'component/Input/Input';

import RegistrationService, { RegistrationError } from '../../../../service/Registration';
import RxComponent from '../../../RxComponent/RxComponent';
import ErrorAdapter from '../service/ErrorAdapter';
import RegistrationFormService from '../service/RegistrationForm';

import './Email.scss';

class Email extends RxComponent<any, any> {
    handler = {
        onError: this.onError.bind(this),
    };

    onError(error: RegistrationError) {
        RegistrationFormService.email.error.next(ErrorAdapter.toString(error));
    }

    componentDidMount() {
        this.subscribe(RegistrationService.error, { next: this.handler.onError });
    }

    render() {
        return (
            <Input
                className="registration-email-input"
                inputService={RegistrationFormService.email}
                name="email"
                type="email"
                size={InputSize.small}
            />
        );
    }
}

export default Email;
