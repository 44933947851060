import { useEffect } from 'react';
import AuthService from 'service/Auth';
import { translate as t } from 'service/Language';

import { GameHistoryService } from '../../../service/GameHistory';
import GoogleService from '../../../service/Google';
import useObservable from '../../../utils/useObservable';
import GameCard from '../../Casino/GameList/GameCard/GameCard';
import EmptyList from './EmptyList/EmptyList';

import './GameHistory.scss';
import UserDataService from 'service/UserData';

function GameHistory() {
    const isAuth = useObservable(AuthService.auth, AuthService.auth.getValue());
    const userData = useObservable(UserDataService.data, UserDataService.data.getValue());
    const gameHistoryList = useObservable(
        GameHistoryService.gameHistoryList,
        GameHistoryService.gameHistoryList.getValue(),
    );

    useEffect(() => {
        if (isAuth) {
            GameHistoryService.getGameHistory();
            GoogleService.gameHistory(userData?.id);
        }
    }, [isAuth, userData]);

    return (
        <div className='game-history'>
            <div className='game-history__header'>{t('profile.navigation.games')}</div>

            {gameHistoryList.length ? (
                <div className='game-history__list'>
                    {gameHistoryList.map((game, index) => (
                        <GameCard game={game!} hover tournament={false} />
                    ))}
                </div>
            ) : (
                <EmptyList />
            )}
        </div>
    );
}

export default GameHistory;
