import classNames from 'classnames';
import CategoryHeader from 'component/CategoryHeader';
import { useEffect, useRef, useState } from 'react';
import Collapsible from 'react-collapsible';
import { translate as t } from 'service/Language';
import { Swiper, SwiperSlide } from 'swiper/react';

import { gameCategoryOrder } from '../../../../service/model/GameCategory';
import Category from './Category/Category';

import './CategoryNavigation.scss';

function CategoryNavigation() {
    const [expanded, setExpanded] = useState(false);

    const expandedList = useRef<HTMLDivElement>(null);

    function clickOutsideExpanded(event: PointerEvent) {
        if (expandedList.current && !expandedList.current.contains(event.target as Node) && !expanded) {
            setExpanded(false);
        }
    }

    function clickExpanded() {
        setExpanded(!expanded);
    }

    useEffect(() => {
        document.addEventListener('pointerdown', clickOutsideExpanded, false);
        return () => {
            document.removeEventListener('pointerdown', clickOutsideExpanded, false);
        };
        // eslint-disable-next-line
    }, []);

    const arrowClass = classNames({
        'category-navigation__arrow': true,
        'category-navigation__arrow__up': expanded,
    });

    const classCategoryNavigation = classNames({
        'category-navigation': true,
        'category-navigation____expanded': expanded,
    });

    return (
        <div ref={expandedList} className={classCategoryNavigation}>
            <CategoryHeader
                title={t('casino.providers.category')}
                linkProps={{
                    title: t(expanded ? 'casino.providers.collapse' : 'casino.providers.all'),
                    onClick: () => setExpanded(true),
                    className: arrowClass,
                }}
            />
            <div className="category-navigation__list">
                <Swiper
                    className="category-navigation__slider"
                    // slidesPerView={1}
                    slidesPerGroupSkip={1}
                    spaceBetween={8}
                    breakpoints={{
                        0: {
                            width: 0,
                            slidesPerView: 1,
                        },
                        200: {
                            width: 200,
                            slidesPerView: 1,
                        },
                        300: {
                            width: 300,
                            slidesPerView: 2,
                        },
                        400: {
                            width: 400,
                            slidesPerView: 2,
                        },
                        500: {
                            width: 500,
                            slidesPerView: 3,
                        },
                        600: {
                            width: 600,
                            slidesPerView: 4,
                        },
                        700: {
                            width: 700,
                            slidesPerView: 5,
                        },
                        800: {
                            width: 800,
                            slidesPerView: 6,
                        },
                    }}
                >
                    {gameCategoryOrder.map((category) => (
                        <SwiperSlide className="category-navigation__slide" key={category.id} >
                            <Category category={category} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div ref={expandedList} className="category-navigation__collapsible">
                <Collapsible open={expanded} transitionTime={200} trigger="">
                    <div className="category-navigation__collapsed">
                        <CategoryHeader
                            title={t('casino.providers.category')}
                            linkProps={{
                                title: t('casino.providers.collapse'),
                                onClick: clickExpanded,
                                className: arrowClass,
                            }}
                        />
                        <div className="category-navigation__collapsed_list">
                            {gameCategoryOrder.map((category) => (
                                <Category category={category} key={category.id} />
                            ))}
                        </div>
                    </div>
                </Collapsible>
            </div>
        </div>
    );
}

export default CategoryNavigation;
