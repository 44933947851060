/* eslint-disable max-len */

import { Icon } from 'types';

function CasinoLottery({ color, width = 24, height = 26 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 26" fill="none">
            <g clipPath="url(#clip0)">
                <path
                    d="M14.9774 2.48768C15.0482 2.21902 15.3248 2.05855 15.5895 2.14296C18.1974 2.97457 20.4537 4.68929 21.9777 7.00962C23.5951 9.47223 24.2774 12.4491 23.8974 15.3855C23.5174 18.3219 22.101 21.0176 19.9122 22.9705C17.7234 24.9233 14.9113 26 12 26C9.08874 26 6.27671 24.9233 4.08788 22.9705C1.89905 21.0176 0.482694 18.3219 0.102692 15.3855C-0.277311 12.4491 0.40496 9.47224 2.02239 7.00962C3.54637 4.68929 5.80263 2.97458 8.41056 2.14296C8.67525 2.05856 8.95188 2.21903 9.02264 2.48768L9.2818 3.47163C9.3517 3.73704 9.19407 4.00796 8.93322 4.09332C6.78693 4.79566 4.93114 6.2165 3.6732 8.13178C2.32338 10.1869 1.75399 12.6713 2.07112 15.1218C2.38825 17.5724 3.57027 19.8222 5.39696 21.4519C7.22364 23.0816 9.57041 23.9802 12 23.9802C14.4297 23.9802 16.7764 23.0816 18.6031 21.4519C20.4298 19.8221 21.6118 17.5724 21.9289 15.1218C22.2461 12.6712 21.6767 10.1869 20.3269 8.13177C19.0689 6.2165 17.2131 4.79566 15.0668 4.09331C14.806 4.00795 14.6483 3.73704 14.7183 3.47163L14.9774 2.48768Z"
                    fill={color ?? 'url(#paint0_linear)'}
                />
                <path
                    d="M12.805 22.8021C12.805 22.9195 12.9057 23.0119 13.0223 22.9988C14.7596 22.8036 16.3482 22.1169 17.6458 21.0809C17.7375 21.0077 17.7434 20.8713 17.6604 20.7883L16.5198 19.6477C16.4479 19.5758 16.3336 19.5696 16.2524 19.631C15.3167 20.3387 14.1991 20.8185 12.9822 20.988C12.8814 21.002 12.805 21.0873 12.805 21.189V22.8021Z"
                    fill={color ?? 'url(#paint1_linear)'}
                />
                <path
                    d="M20.8025 14.8033C20.9198 14.8033 21.0122 14.904 20.9991 15.0206C20.8046 16.7545 20.1205 18.3403 19.0883 19.6366C19.0151 19.7285 18.8785 19.7345 18.7955 19.6514L17.6547 18.5106C17.5829 18.4388 17.5766 18.3246 17.6377 18.2435C18.3421 17.3092 18.8196 16.1943 18.9884 14.9806C19.0024 14.8798 19.0876 14.8033 19.1893 14.8033H20.8025Z"
                    fill={color ?? 'url(#paint2_linear)'}
                />
                <path
                    d="M17.6467 9.47859C17.5748 9.55056 17.5686 9.66491 17.63 9.74607C18.3384 10.6822 18.8188 11.8006 18.9882 13.0184C19.0023 13.1192 19.0875 13.1957 19.1892 13.1957H20.8024C20.9197 13.1957 21.0121 13.095 20.999 12.9783C20.8038 11.2401 20.1166 9.65065 19.0799 8.35264C19.0067 8.26094 18.8702 8.2551 18.7873 8.33808L17.6467 9.47859Z"
                    fill={color ?? 'url(#paint3_linear)'}
                />
                <path
                    d="M6.34578 18.5112C6.41765 18.4393 6.42393 18.3252 6.36274 18.244C5.65816 17.3096 5.18051 16.1945 5.01171 14.9806C4.9977 14.8798 4.91248 14.8033 4.81074 14.8033H3.19762C3.08025 14.8033 2.98792 14.904 3.001 15.0206C3.19553 16.7548 3.87978 18.3408 4.91223 19.6372C4.98541 19.7291 5.12198 19.735 5.20503 19.652L6.34578 18.5112Z"
                    fill={color ?? 'url(#paint4_linear)'}
                />
                <path
                    d="M7.74621 19.6299C7.66505 19.5684 7.5507 19.5746 7.47873 19.6466L6.33822 20.7871C6.25524 20.8701 6.26108 21.0065 6.35278 21.0798C7.65051 22.1163 9.23952 22.8034 10.9773 22.9987C11.094 23.0118 11.1947 22.9195 11.1947 22.8021V21.1889C11.1947 21.0872 11.1183 21.002 11.0175 20.988C9.80008 20.8183 8.68209 20.3381 7.74621 19.6299Z"
                    fill={color ?? 'url(#paint5_linear)'}
                />
                <path
                    d="M16.5114 8.34564C16.4395 8.41751 16.3253 8.42379 16.2442 8.36259C15.3102 7.6583 14.1955 7.18075 12.9822 7.01177C12.8814 6.99774 12.805 6.91253 12.805 6.8108V5.19764C12.805 5.08026 12.9057 4.98792 13.0223 5.00103C14.7559 5.19579 16.3413 5.87994 17.6373 6.91209C17.7292 6.98527 17.7352 7.12183 17.6521 7.20489L16.5114 8.34564Z"
                    fill={color ?? 'url(#paint6_linear)'}
                />
                <path
                    d="M10.9773 5.00107C11.094 4.98796 11.1947 5.0803 11.1947 5.19769V6.81086C11.1947 6.91258 11.1183 6.99779 11.0175 7.01183C9.80452 7.18082 8.69025 7.65819 7.75646 8.36218C7.6753 8.42336 7.56115 8.41708 7.48928 8.34521L6.34852 7.20445C6.26546 7.12139 6.27141 6.98481 6.3633 6.91164C7.6591 5.87982 9.24422 5.19587 10.9773 5.00107Z"
                    fill={color ?? 'url(#paint7_linear)'}
                />
                <path
                    d="M4.91898 8.35413C4.99221 8.26241 5.12865 8.25655 5.21164 8.33954L6.35218 9.48009C6.42414 9.55204 6.43034 9.66636 6.36896 9.74752C5.66112 10.6834 5.18121 11.8012 5.01182 13.0184C4.9978 13.1192 4.91258 13.1957 4.81085 13.1957H3.1977C3.08033 13.1957 2.98799 13.095 3.00109 12.9783C3.19616 11.2407 3.88292 9.65188 4.91898 8.35413Z"
                    fill={color ?? 'url(#paint8_linear)'}
                />
                <path
                    d="M10.618 0C9.87465 0 9.39116 0.782312 9.72361 1.44721L11.1056 4.21115C11.4741 4.94819 12.5259 4.94819 12.8944 4.21115L14.2764 1.44721C14.6088 0.782312 14.1253 0 13.382 0H10.618Z"
                    fill={color ?? 'url(#paint9_linear)'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.0001 20.0002C15.3138 20.0002 18.0001 17.3139 18.0001 14.0002C18.0001 10.6865 15.3138 8.00021 12.0001 8.00021C8.68635 8.00021 6.00006 10.6865 6.00006 14.0002C6.00006 17.3139 8.68635 20.0002 12.0001 20.0002ZM11.1954 11.8049C11.1954 11.3606 11.5557 11.0002 12.0001 11.0002C12.4444 11.0002 12.8047 11.3606 12.8047 11.8049V12.1955C12.8047 12.7478 13.2525 13.1955 13.8047 13.1955H14.1954C14.6397 13.1955 15.0001 13.5559 15.0001 14.0002C15.0001 14.4445 14.6397 14.8049 14.1954 14.8049H13.8047C13.2525 14.8049 12.8047 15.2526 12.8047 15.8049V16.1955C12.8047 16.6399 12.4444 17.0002 12.0001 17.0002C11.5557 17.0002 11.1954 16.6399 11.1954 16.1955V15.8049C11.1954 15.2526 10.7477 14.8049 10.1954 14.8049H9.80474C9.36041 14.8049 9.00006 14.4445 9.00006 14.0002C9.00006 13.5559 9.36041 13.1955 9.80474 13.1955H10.1954C10.7477 13.1955 11.1954 12.7478 11.1954 12.1955V11.8049Z"
                    fill={color ?? 'url(#paint10_linear)'}
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="0.545456"
                    y1="5.70732"
                    x2="20.9362"
                    y2="10.1993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="0.545456"
                    y1="5.70732"
                    x2="20.9362"
                    y2="10.1993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="0.545456"
                    y1="5.70732"
                    x2="20.9362"
                    y2="10.1993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="0.545456"
                    y1="5.70732"
                    x2="20.9362"
                    y2="10.1993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear"
                    x1="0.545456"
                    y1="5.70732"
                    x2="20.9362"
                    y2="10.1993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear"
                    x1="0.545456"
                    y1="5.70732"
                    x2="20.9362"
                    y2="10.1993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear"
                    x1="0.545456"
                    y1="5.70732"
                    x2="20.9362"
                    y2="10.1993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear"
                    x1="0.545456"
                    y1="5.70732"
                    x2="20.9362"
                    y2="10.1993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear"
                    x1="0.545456"
                    y1="5.70732"
                    x2="20.9362"
                    y2="10.1993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear"
                    x1="0.545456"
                    y1="5.70732"
                    x2="20.9362"
                    y2="10.1993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear"
                    x1="0.545456"
                    y1="5.70732"
                    x2="20.9362"
                    y2="10.1993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="24" height="26" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default CasinoLottery;
