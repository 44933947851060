import {translate as t} from 'service/Language';
import Failed from './image/failed.svg';
import CoinspaidWithdrawService, {CryptoWithdrawState} from '../../../service/CoinspaidWithdrawal';
import CryptoFormService from '../../../service/CryptoForm';

import './FailedPage.scss';


function FailedPage() {
    function onClickHandler() {
        CoinspaidWithdrawService.state.next(CryptoWithdrawState.INIT)
        CryptoFormService.amount.autoCompleted.next(true);
        CryptoFormService.wallet.autoCompleted.next(true);
        CryptoFormService.tag.autoCompleted.next(true);
    }
    return (
        <div className="result-pix">
            <div className="result-pix-container">
                <img className="result-pix-container__img" src={Failed} alt="failed" />
                <p className="result-pix-container__title ">{t('popup.payment.error.title')}</p>
                <span className="result-pix-container__text">{t('popup.payment.error.text')}</span>
                <button className="result-pix-container__btn" onClick={onClickHandler}>
                    {t('popup.payment.error.back')}
                </button>
            </div>
        </div>
    );
}

export default FailedPage;
