import useObservable from 'utils/useObservable';

import PaycosWithdrawService, { PaycosWithdrawState } from '../service/PaycosWithdraw';
import FailedPage from './ResultPage/FailedPage';
import ProgressPage from './ResultPage/ProgressPage';
import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import BonusFailedPage from '../../../../../../component/BonusFailedPage/BonusFailedPage';

import './ResultForm.scss';

function ResultForm() {
    const state = useObservable(PaycosWithdrawService.state, PaycosWithdrawService.state.getValue());

    switch (state) {
        case PaycosWithdrawState.AWAIT: {
            return <ProgressPage />;
        }
        case PaycosWithdrawState.ACCEPT: {
            return <SuccessPage />;
        }
        case PaycosWithdrawState.REJECT: {
            return <FailedPage />;
        }
        case PaycosWithdrawState.BONUS_REJECT: {
            return <BonusFailedPage />;
        }
        default: {
            return null;
        }
    }
}

export default ResultForm;
