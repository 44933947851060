import { Step10Data } from '@ay_tsarbet/newbet-core/dist/types';
import { parse } from 'date-fns';
import { FormEvent } from 'react';
import { BehaviorSubject, Subject } from 'rxjs';

import InputService from '../../../../../component/Input/service/Input';
import GoogleService from '../../../../../service/Google';
import { translate as t } from '../../../../../service/Language';
import Country, { unknownCountry } from '../../../../../service/model/Country';
import UserData from '../../../../../service/model/UserData';
import UserDataService, { UserDataResponse } from '../../../../../service/UserData';
import { CheckPersonalNameService } from '../../../../../service/Validator/PersonalName';
import Validator from '../../../../../service/Validator/Validator';
import EmailInputService from '../ChangeEmail/NewEmail/service/NewEmail';

enum PersonalDataError {
    NOERROR,
    SYMBOL,
}

const fakeUserData = new UserData(0, '', 0, '', '', unknownCountry, '');

class PersonalDataForm {
    id = new InputService({
        title: '',
    });

    name = new InputService({
        title: '',
        validator: Validator.personalName,
    });

    currency = new InputService({
        title: '',
    });

    birthDate = new InputService({
        title: '',
        validator: Validator.CheckBirthDateService,
    });

    canSave = new Subject<boolean>();

    userData = new BehaviorSubject<UserData>(fakeUserData);

    country = new BehaviorSubject<Country>(unknownCountry);

    status = UserDataService.response;

    handler = {
        onNameChange: this.onNameChange.bind(this),
        onBirthdate: this.setBirthdate.bind(this),
        onEmailChange: this.setEmail.bind(this),
        onUserData: this.onUserData.bind(this),
    };

    constructor() {
        this.name.value.subscribe(this.handler.onNameChange);
        this.birthDate.value.subscribe(this.handler.onBirthdate);
        EmailInputService.value.subscribe(this.handler.onEmailChange);
        UserDataService.data.subscribe(this.handler.onUserData);
    }

    init() {
        const data = UserDataService.data.getValue();
        if (data) {
            this.userData.next(data.copy());
        }
        this.validate();
    }

    onUserData(userData: UserData | null) {
        if (userData) {
            this.userData.next(userData);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();
        this.canSave.next(false);
        GoogleService.saveUserData(this.userData.getValue());
        UserDataService.save(this.userData.getValue());
    }

    countryError(error: UserDataResponse | null): string {
        if (error === UserDataResponse.COUNTRY_NAME_MISMATCH) {
            return t('profile.account.country.error.name');
        }
        return '';
    }

    phoneError(error: UserDataResponse | null): string {
        if (error === UserDataResponse.INVALID_PHONE_FORMAT) {
            return t('profile.account.phone.error.format');
        }
        if (error === UserDataResponse.PHONE_ALREADY_IN_USE) {
            return t('profile.account.phone.error.used');
        }
        return '';
    }

    birthdayError(error: UserDataResponse | null): string {
        if (error === UserDataResponse.INVALID_AGE) {
            return t('profile.account.birthday.error');
        }
        return '';
    }

    onNameChange(name: string) {
        if (name) {
            this.name.error.next(null);
            if (!CheckPersonalNameService.validate(name)) {
                this.name.error.next(this.name.validatorText);
            }
        } else {
            this.name.error.next(null);
        }
        this.userData.getValue().firstName = name;
        this.validate();
    }

    setEmail(email: string) {
        this.userData.getValue().email = email;
    }

    setCountry(country: Country) {
        this.userData.getValue().country = country;
        this.country.next(country);
    }

    setBirthdate(birthdate: string) {
        if (birthdate.length === 10) {
            this.birthDate.error.next(null);
            const separator = birthdate.includes('.');
            this.userData.getValue().birthDate = parse(birthdate, separator ? 'dd.MM.yyyy' : 'dd/MM/yyyy', new Date());
        } else {
            this.userData.getValue().birthDate = null;
            this.birthDate.error.next(this.birthDate.validatorText);
        }
        this.validate();
    }

    validate() {
        const name = this.name.getValue() && this.name.error.getValue() === null;
        const birthDate = this.birthDate.getValue() && this.birthDate.error.getValue() === null;
        const valid = !!name && !!birthDate;
        this.canSave.next(valid);
    }

    onReceive(data: Step10Data) {
        console.log('PersonalDataFormService', data);
    }

    reset() {
        this.canSave.next(false);
    }
}

const PersonalDataFormService = new PersonalDataForm();
export { PersonalDataError };
export default PersonalDataFormService;
