import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import { translate as t } from '../../../service/Language';
import LeftArrow from '../Account/image/LeftArrow.svg';
import { profileRouteList, titleMap } from '../Profile';
import RouteConfig from '../../../appConstants/RouteConfig';

import './ProfileHeader.scss';

function ProfileHeader() {
    const currentRoute = useLocation();
    const title = titleMap.get(currentRoute.pathname) || '';

    const classProfileTitle = classNames({
        'profile-header__title': true,
        'profile-header__title____small': currentRoute.pathname !== profileRouteList.account,
    });

    const route = currentRoute.pathname === profileRouteList.account ? RouteConfig.casino.root : profileRouteList.account

    return (
        <div className="profile-header">
            <NavLink to={route} className="profile-header__backurl">
                <img src={LeftArrow} alt="left-arrow" />
            </NavLink>
            <h2 className={classProfileTitle}>{t(title)}</h2>
        </div>
    );
}

export default ProfileHeader;
