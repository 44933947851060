import { BehaviorSubject } from 'rxjs';

enum StatusForm {
    INIT,
    FAILED,
    SUCCESS,
    BONUS_REJECT,
    TIME_REJECT,
}

class StatusFormPopup {
    status = new BehaviorSubject<StatusForm>(StatusForm.INIT);
}

const StatusFormPopupService = new StatusFormPopup();

export { StatusFormPopup, StatusForm };
export default StatusFormPopupService;
