// https://stackoverflow.com/questions/4770025/how-to-disable-scrolling-temporarily

import { BehaviorSubject } from 'rxjs';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade';
import AuthService from './Auth';
import GoogleService from './Google';

class UI {
    slideMenu = new BehaviorSubject(false);

    lockScroll() {
        const element: HTMLDivElement = document.querySelector('#root') as HTMLDivElement;
        disableBodyScroll(element, {
            allowTouchMove: () => true,
        });
    }

    unlockScroll() {
        const element: HTMLDivElement = document.querySelector('#root') as HTMLDivElement;
        enableBodyScroll(element);
    }

    toggleSlideMenu() {
        const menu = this.slideMenu.getValue();
        if (menu) {
            this.closeSlideMenu();
        } else {
            this.openSlideMenu();
        }
    }

    closeSlideMenu() {
        this.slideMenu.next(false);
        this.unlockScroll();
    }

    openSlideMenu() {
        this.slideMenu.next(true);
        this.lockScroll();
        const userId = AuthService.auth.getValue()?.userId.toString();
        if (userId) {
            GoogleService.openSlideMenu(userId);
        } else {
            GoogleService.openSlideMenu('');
        }
    }
}

const UIService = new UI();

export default UIService;
