import { Subject } from 'rxjs';

import WSBet from '../WSBet';

import PaycordInterface from '../interface/payment/Paycord';
import {
    PaycordDepositResponse,
} from '@ay_tsarbet/newbet-core/dist/types';
import {PaycordDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaycordDepositRequest';
import PaycordDepositService from '../../page/Profile/Balance/Deposit/Paycord/service/PaycordDeposit';

enum PaycordError {
    UNKNOWN,
    DEPOSIT,
    WITHDRAW,
}

class Paycord implements PaycordInterface {
    error = new Subject<PaycordError>();

    deposit(request: PaycordDepositRequest) {
        console.log('deposit', request);
        WSBet.payment.paycord.deposit(request);
    }


    onDepositReceive(data: PaycordDepositResponse) {
        console.log('Paycord | DEPOSIT RESPONSE', data);
     if (data.code >= 0) {
         PaycordDepositService.onReceive(data.message);
         console.log('data.message', data.message)
        } else {
         PaycordDepositService.onError();
        }
    }
}

export { PaycordError };
export default Paycord;
