import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';

import EmailInputService from '../NewEmail/service/NewEmail';
import OldPasswordService from '../OldPasswordInput/service/OldPassword';
import ChangeEmailService, { EmailChangeResult } from './ChangeEmail';
import GoogleService from '../../../../../../service/Google';
import UserDataService from '../../../../../../service/UserData';

class EmailForm {
    public active = new BehaviorSubject<boolean>(false);

    state = {
        oldPassword: '',
        newEmail: '',
    };

    handler = {
        onNewEmail: this.onNewEmail.bind(this),
        oldPassword: this.onOldPasswordChange.bind(this),
        onSubmit: this.onSubmit.bind(this),
    };

    onOldPasswordChange(value: string) {
        this.state.oldPassword = value;
        this.checkValid();
    }

    onNewEmail(value: string) {
        this.state.newEmail = value;
        this.checkValid();
    }

    checkValid() {
        if (EmailInputService.isValid()) {
            this.active.next(true);
        } else {
            this.active.next(false);
        }
    }

    reset() {
        ChangeEmailService.status.next(EmailChangeResult.INIT);
        this.active.next(false);
        OldPasswordService.value.next('');
    }

    onSubmit(event: FormEvent, oldEmail?: string) {
        event.preventDefault();
        if (this.active.getValue()) {
            const email = EmailInputService.value.getValue();
            // const password = OldPasswordService.value.getValue();
            if(oldEmail) {
                const User = UserDataService.data.getValue();
                const location = window.location.pathname;
                const userId = User ? User.id.toString() : '';
                GoogleService.newYearChangeEmailRequest(userId, oldEmail ? oldEmail : '', email,  location);
            }
            ChangeEmailService.change(email);
        }
    }
}

const EmailFormService = new EmailForm();

export default EmailFormService;
