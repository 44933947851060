import { translate as t } from 'service/Language';

import { useEffect, useState } from 'react';
import GoogleService from 'service/Google';
import PopupHelper from 'service/PopupHelper';
import classNames from 'classnames';

import './WheelBonanza.scss';

const WheelBonanza = () => {
    const [angle, setAngle] = useState(0);
    const [idle, setIdle] = useState(true);
    const [attempts, setAttempts] = useState(2);

    useEffect(() => {
        if (!idle) {
            if (attempts === 2) {
                setAngle(715);
            } else {
                setAngle(1825);
            }
        }
    }, [attempts, idle]);

    const spin = {
        transform: `rotate(${angle}deg)`,
    };

    const handleClick = () => {
        setIdle(false);
        setTimeout(() => {
            setAttempts(prevState => {
                if (prevState === 2) {
                    GoogleService.landingSpin('');
                    setIdle(true);
                    PopupHelper.showWheelWin('bonanza', handleClick);
                    return 1;
                } else {
                    GoogleService.landingSpinAgain('');
                    PopupHelper.showWheelRegistration('bonanza');
                    return 0;
                }
            });
        }, 4000);
    };

    const scoreboardClass = classNames('WheelBonanza__scoreboard', {
        WheelBonanza__scoreboard_idle: idle,
    });

    return (
        <div className='WheelBonanza'>
            <div className='WheelBonanza__elements'>
                <div className='WheelBonanza__elements__logo' />
                <div className='WheelBonanza__elements__title'>{t('wheels.title.roll')}</div>
                <div className='WheelBonanza__elements__center' />
                <div className='WheelBonanza__attempt'>
                    <div className='WheelBonanza__attempt_block'>
                        <div className='WheelBonanza__attempt_block_title'>
                            {t('wheel.attempts')}
                        </div>
                        <div className='WheelBonanza__attempt_block_number'>{attempts}</div>
                    </div>
                </div>

            </div>
            <div className={scoreboardClass}>
                <div className='WheelBonanza__disk_wrapper'>
                    <div className='WheelBonanza__disk' style={spin}>
                        <div className='WheelBonanza__disk_name' />
                    </div>
                </div>
            </div>
            <div className='WheelBonanza__frame'>
                <div className='WheelBonanza__frame_lights first' />
                <div className='WheelBonanza__frame_lights second' />
            </div>
            <div className='WheelBonanza__button_wrapper'>
                <button
                    className='WheelBonanza__button'
                    disabled={!idle || attempts === 0}
                    onClick={handleClick}
                >
                    <span className='WheelBonanza__button_text'>{t('wheel.aviator.button')}</span>
                </button>
            </div>
        </div>
    );
};

export default WheelBonanza;
