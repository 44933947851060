/* eslint-disable max-len */

import { Icon } from 'types';

function Offside({ color = '#3959D9', width = 12, height = 12 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 12 12" fill="none">
            <path
                d="M11.3999 0H2.5999C2.48945 0 2.3999 0.08955 2.3999 0.2V6.8H1.3999C0.847902 6.80065 0.400552 7.248 0.399902 7.8V11.8C0.399902 11.9104 0.489452 12 0.599902 12H3.3999C3.51035 12 3.5999 11.9104 3.5999 11.8V11.4H4.5999C4.82083 11.4 4.9999 11.2209 4.9999 11V10.6C4.9995 10.5296 4.9803 10.4605 4.9443 10.4C4.9803 10.3395 4.9995 10.2704 4.9999 10.2V9.8C4.9995 9.72958 4.9803 9.66053 4.9443 9.6C4.9803 9.53948 4.9995 9.47043 4.9999 9.4V9C4.9995 8.92957 4.9803 8.86053 4.9443 8.8C4.9803 8.73948 4.9995 8.67043 4.9999 8.6V8.2C4.9999 7.97907 4.82083 7.8 4.5999 7.8H4.5631C4.5868 7.73595 4.59923 7.6683 4.5999 7.6V7.4C4.5999 7.06862 4.33128 6.8 3.9999 6.8H3.5999V5.6H11.3999C11.5104 5.6 11.5999 5.51045 11.5999 5.4V0.2C11.5999 0.08955 11.5104 0 11.3999 0ZM2.7999 0.4H3.1999V6.8H2.7999V0.4ZM3.1999 11.6H2.7999V11.4H3.1999V11.6ZM4.5999 11H2.5999V10.6H4.5999V11ZM4.5999 10.2H2.5999V9.8H4.5999V10.2ZM4.5999 9.4H2.5999V9H4.5999V9.4ZM4.5999 8.2V8.6H2.5999V8.2H4.5999ZM3.9999 7.2C4.11035 7.2 4.1999 7.28955 4.1999 7.4V7.6C4.1999 7.71045 4.11035 7.8 3.9999 7.8H1.7999V8.2H2.1999V8.6C2.2003 8.67043 2.2195 8.73948 2.2555 8.8C2.2195 8.86053 2.2003 8.92957 2.1999 9V9.4C2.2003 9.47043 2.2195 9.53948 2.2555 9.6C2.2195 9.66053 2.2003 9.72958 2.1999 9.8V10.2C2.2003 10.2704 2.2195 10.3395 2.2555 10.4C2.2195 10.4605 2.2003 10.5296 2.1999 10.6V11C2.20033 11.1423 2.27655 11.2735 2.3999 11.3444V11.6H0.799902V7.8C0.799902 7.46863 1.06853 7.2 1.3999 7.2H3.9999ZM7.1999 5.2H3.5999V3H7.1999V5.2ZM7.1999 2.6H3.5999V0.4H7.1999V2.6ZM11.1999 5.2H7.5999V3H11.1999V5.2ZM11.1999 2.6H7.5999V0.4H11.1999V2.6Z"
                fill={color}
            />
        </svg>
    );
}

export default Offside;
