import { Direction, Operation, Status } from '@ay_tsarbet/newbet-core/dist/types';
import { toDate } from 'date-fns';
import paymentMethod from 'page/Profile/Balance/PaymentMethod/methods';
import { PaymentMethod } from 'page/Profile/Balance/PaymentMethod/types';

import Currency, { currencyList, unknownCurrency } from './Currency';
import cryptoPaymentMethod from '../../page/Profile/Balance/PaymentMethod/cryptoMethods';

class History {
    id: number;

    amount: number;

    type: Direction;

    status: Status;

    paymentMethod: PaymentMethod | null;

    idTransaction: string;

    accountNumber: string;

    trxID: string;

    created: Date;

    confirmed: Date;

    currency: Currency | null = unknownCurrency;

    constructor({ id, amount, type, status, paymentMethod, idTransaction, accountNumber, trxID, currency, created, confirmed }: History) {
        this.id = id; // ID транзакции glory
        this.amount = amount; // Сумма бонуса
        this.type = type; // Пополнение / Вывод
        this.status = status; // Статус
        this.paymentMethod = paymentMethod; // Платежный метод
        this.idTransaction = idTransaction; // ID транзакции
        this.accountNumber = accountNumber;
        this.trxID = trxID;
        this.currency = currency; // Валюта
        this.created = created; // Дата создания транзакции
        this.confirmed = confirmed; // Дата подтверждение транзакции
    }

    static fromObject(data: Operation): History {
        const paymentMethodData = Object.values({...paymentMethod,...cryptoPaymentMethod}).find((method) => method.id === data.channelType) || null;
        const currency = currencyList.find((cur) => cur.ISO === data.currency) || null;

        return new History({
            id: data.id,
            amount: data.amount,
            type: data.direction,
            status: data.status,
            paymentMethod: paymentMethodData,
            idTransaction: data.providerId,
            accountNumber: data.accountNumber,
            trxID: data.trxID,
            currency,
            created: toDate(data.creationDate * 1000),
            confirmed: toDate(data.endDate * 1000),
        });
    }
}

export default History;
