import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';

import AuthService from '../../../../../../service/Auth';
import GoogleService from '../../../../../../service/Google';
import { OnlyLetterService } from '../../../../../../service/Validator/OnlyLetter';
import Validator from '../../../../../../service/Validator/Validator';
import { PaymentMethod } from '../../../PaymentMethod/types';
import DepositService from './PayfixDeposit';
import { CheckTurkeyLettersService } from '../../../../../../service/Validator/CheckTurkeyLetters';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';

export enum PayfixInputTip {
    NONE,
    NAME,
    SURNAME,
    ACCOUNT_NUMBER_PAYFIX,
    NON_EMPTY,
}
export enum PayfixErrorTip {
    NONE,
    NAME,
    SURNAME,
}

class AmountForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(0, 1);

    handler = {
        onAmount: this.onAmount.bind(this),
        onName: this.onName.bind(this),
        onNameActive: this.onNameActive.bind(this),
        onSurname: this.onSurname.bind(this),
        onSurnameActive: this.onSurnameActive.bind(this),
        valid: this.checkValid.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.name.value.subscribe(this.handler.onName);
        this.name.active.subscribe(this.handler.onNameActive);
        this.surname.value.subscribe(this.handler.onSurname);
        this.surname.active.subscribe(this.handler.onSurnameActive);
    }

    amount = new InputService({
        title: 'profile.balance.deposit.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.deposit.input.amount'
    });

    name = new InputService({
        title: 'popup.payment.pix.name',
        validator: Validator.OnlyLetter,
        validatorText: 'popup.payment.pix.name',
    });

    surname = new InputService({
        title: 'popup.payment.pix.surname',
        validator: Validator.OnlyLetter,
        validatorText: 'popup.payment.pix.surname',
    });

    valid = new BehaviorSubject<boolean>(false);

    errorType = new BehaviorSubject<PayfixInputTip>(PayfixInputTip.NONE);

    errorLetters = new BehaviorSubject<PayfixErrorTip>(PayfixErrorTip.NONE);

    setLimit(min: number, max: number) {
        if (this.limitValidator) {
            this.limitValidator.setLimit(min, max);
        }
    }

    setAmount(value: number) {
        this.amount.onFocus();
        this.amount.value.next(value.toString());
    }

    onAmount(value: string) {
        this.checkValid();
        if(!!value && !this.amount.isValid()){
            this.amount.error.next('profile.balance.deposit.input.amount')
        } else {
            this.amount.error.next(null);
        }
    }

    onName(value: string) {
        this.checkValid();
        if (this.name.getValue() &&
            !OnlyLetterService.validate(value)
        ) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.name.value.next(name);
            if(CheckTurkeyLettersService.validate(value)) {
                this.errorLetters.next(PayfixErrorTip.NAME)
            } else{
                this.errorLetters.next(PayfixErrorTip.NONE)
            }

        } else {
            this.name.error.next(null);
            this.errorLetters.next(PayfixErrorTip.NONE)
        }
    }

    onSurname(value: string) {
        this.checkValid();
        if (this.surname.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const surname = value.slice(0, current - 1);
            this.surname.value.next(surname);
            if(CheckTurkeyLettersService.validate(value)) {
                this.errorLetters.next(PayfixErrorTip.SURNAME)
            } else{
                this.errorLetters.next(PayfixErrorTip.NONE)
            }
        } else {
            this.surname.error.next(null);
            this.errorLetters.next(PayfixErrorTip.NONE)
        }
    }

    onNameActive(active: boolean) {
        active ? this.errorType.next(PayfixInputTip.NAME)
            : this.errorType.next(PayfixInputTip.NONE)
    }

    onSurnameActive(active: boolean) {
        active ? this.errorType.next(PayfixInputTip.SURNAME)
            : this.errorType.next(PayfixInputTip.NONE)
    }

    setMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        this.setLimit(paymentMethod.limit.deposit.min, paymentMethod.limit.deposit.max);
    }

    checkValid() {
        const isValid = this.name.isValid() && this.surname.isValid() && this.amount.isValid();
        this.valid.next(isValid);
    }

    reset() {
        this.amount.value.next('');
        this.name.value.next('');
        this.surname.value.next('');

        this.amount.error.next(null);
        this.name.error.next(null);
        this.surname.error.next(null);

        this.amount.active.next(false);
        this.name.active.next(false);
        this.surname.active.next(false);

        this.valid.next(false);
        this.errorType.next(PayfixInputTip.NONE);
        this.errorLetters.next(PayfixErrorTip.NONE);
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const assert = [this.amount.isValid(), this.name.isValid(), this.surname.isValid()];
        const valid = !assert.includes(false);

        if (valid && this.paymentMethod) {
            const revertBirth = (str: string): string => {
                const arr = str.split('-');

                const day = arr.splice(0, 1)[0];

                arr.splice(2, 0, day);

                const year = arr.splice(1, 1)[0];

                arr.splice(0, 0, year);
                return arr.join('-');
            };

            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            const amount = parseFloat(this.amount.getValue());
            const name = this.name.getValue();
            const surname = this.surname.getValue();

            GoogleService.sentDepositAmount(
                userId,
                this.paymentMethod.title,
                amount.toString(),
                this.paymentMethod.currency.name
            );
            DepositService.request(this.paymentMethod, name, surname, amount);
        } else {
            const formFieldList = [this.name, this.surname];
            formFieldList.filter((field) => !field.isValid()).forEach((item) => item.error.next(item.validatorText));
        }
    }
}

const AmountFormService = new AmountForm();

export default AmountFormService;
