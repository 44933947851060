/* eslint-disable max-len */

import { Icon } from 'types';

function BorderPlayButton({ color = '#FDB43C', width = 14, height = 20 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 14 20" fill="none">
            <path
                d="M1 18V2C1 1.58798 1.47038 1.35279 1.8 1.6L12.4667 9.6C12.7333 9.8 12.7333 10.2 12.4667 10.4L1.8 18.4C1.47038 18.6472 1 18.412 1 18Z"
                stroke={color}
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default BorderPlayButton;
