import Validator from '../interface/Validator';

class LettersNumbers implements Validator {
    validate(value: string): boolean {
        return new RegExp('^[A-Za-z0-9]+$').test(value);
    }
}
const LettersNumbersService = new LettersNumbers();

export { LettersNumbersService };
export default LettersNumbers;
