import { PaymentMethod } from 'page/Profile/Balance/PaymentMethod/types';

class CorefyWithdraw {
    amount: number;

    currencyId: number;

    channelType: number;

    surname: string;

    name: string;

    card: string;

    constructor(method: PaymentMethod, surname: string, name: string, card: string, amount: number) {
        this.currencyId = method.currency.ISO;
        this.channelType = method.id;
        this.surname = surname;
        this.name = name;
        this.card = card;
        this.amount = amount;
    }
}

export default CorefyWithdraw;
