/* eslint-disable max-len */

import { Icon } from 'types';

function BigPesapallo({ color = '#3959D9', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.6566 9.27787C22.9211 6.24358 21.0222 3.62553 18.3749 1.99562H18.3441C14.7338 -0.427796 10.0931 -0.660898 6.26103 1.3887C2.42899 3.4383 0.0243662 7.43967 0.000184065 11.807C-0.0239981 16.1743 2.33616 20.2024 6.14527 22.2948C8.80131 23.9172 11.9904 24.4071 15.0064 23.656C18.026 22.9112 20.6328 20.9998 22.2629 18.3354C23.91 15.6244 24.4118 12.3632 23.6566 9.27787ZM4.38523 4.87406C4.53286 6.10609 4.42434 7.35545 4.06651 8.54329C3.72568 9.46691 3.17463 10.2979 2.45722 10.9702L2.42587 11.0017C2.17702 11.2541 1.9079 11.4855 1.62123 11.6936C1.6658 10.0772 2.08246 8.49311 2.83864 7.06512C3.25993 6.27381 3.77961 5.53934 4.38523 4.8793V4.87406ZM4.66748 8.77393C4.05056 10.2826 2.98506 11.5639 1.6161 12.4432V12.4484C1.70786 15.414 3.07629 18.1941 5.36762 20.07C7.76065 20.8772 11.0419 19.8813 13.623 17.4019L13.064 16.9406C12.2541 16.2749 11.4913 15.6459 10.7859 14.9697C9.58481 13.5538 9.07531 11.6744 9.39606 9.84326C9.71232 7.99612 10.6759 6.32308 12.113 5.12566C14.2448 3.32249 16.502 3.35918 18.5032 3.77328C18.1702 3.50741 17.8212 3.26233 17.4582 3.03944C13.4603 0.614605 8.34524 1.13845 4.91827 4.32367C5.19198 5.7883 5.1059 7.29776 4.66748 8.72152V8.77393ZM7.17018 21.2336C9.52663 21.2336 12.1861 20.0961 14.3545 17.9785C15.4562 18.8804 16.4425 19.9157 17.2909 21.0606C14.1795 22.8878 10.3436 22.9554 7.17018 21.2389V21.2336ZM17.8186 20.7042C19.0805 19.8615 20.1491 18.759 20.9536 17.47C23.3599 13.4418 22.8323 8.30449 19.6578 4.85309C19.4279 4.74301 19.198 4.63818 18.9995 4.55431C17.3954 4.14545 15.7078 3.93578 14.0462 4.66963C13.5014 4.91319 12.9946 5.23451 12.5414 5.62363C11.2292 6.72228 10.3449 8.24973 10.0439 9.93761C9.75556 11.5694 10.2034 13.2454 11.2665 14.5137C11.8819 15.116 12.6056 15.7089 13.3616 16.3283C13.4016 16.3611 13.4417 16.394 13.4819 16.4269C15.114 17.6489 16.573 19.0879 17.8186 20.7042Z"
                fill={color}
            />
        </svg>
    );
}

export default BigPesapallo;
