import classNames from 'classnames';
import { PaymentMethod } from 'page/Profile/Balance/PaymentMethod/types';
import { unknownCurrency } from 'service/model/Currency';
import UserDataService from 'service/UserData';
import { formatCurrencySign } from 'utils/format';
import useObservable from 'utils/useObservable';
import AmountFormService from '../../service/AmountForm';

import './SumSelector.scss';

type SumSelectorProps = {
    paymentMethod: PaymentMethod;
    disabled: boolean;
};

const SumSelector = (props: SumSelectorProps) => {
    const amount = useObservable(AmountFormService.amount.value, AmountFormService.amount.value.getValue());
    const userData = useObservable(UserDataService.data, UserDataService.data.getValue());
    const currency = userData ? userData.currency : unknownCurrency;

    return (
        <div className="sum-selector-instapay">
            {props.paymentMethod.amountPreset.map((preset, index) => {
                const itemClass = classNames('sum-selector-epul__item', `item-${index}`, {
                    active: Number(amount) === Number(preset),                });
                return (
                    <button
                        className={itemClass}
                        onClick={() => AmountFormService.setValue(preset)}
                        disabled={props.disabled}
                        key={index}
                        type='button'
                    >
                        {formatCurrencySign(Number(preset), 0, currency)}
                    </button>
                );
            })}
        </div>
    );
};

export default SumSelector;
