import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import { PaymentMethod } from '../../../PaymentMethod/types';
import Validator from '../../../../../../service/Validator/Validator';
import AuthService from '../../../../../../service/Auth';
import GoogleService from '../../../../../../service/Google';
import WellxPayDepositService from './WellxpayDeposit';
import {ChannelType} from '@ay_tsarbet/newbet-core/dist/types';


export enum WellxPayInputTip {
    NONE,
    AMOUNT,
}

export enum AmountError {
    NONE,
    AMOUNT_MIN,
    AMOUNT_MAX,
    AMOUNT_WITHDRAWAL,
    AMOUNT_FEE,
}

class AmountForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(1, 1000000);

    amount = new InputService({
        title: 'profile.balance.deposit.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.deposit.input.amount',
    });

    tip = new BehaviorSubject<WellxPayInputTip>(WellxPayInputTip.NONE);

    amountLimit = new BehaviorSubject<AmountError>(AmountError.NONE);

    valid = new BehaviorSubject<boolean>(false);

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
    }

    setLimit(min: number, max: number) {
        if (this.limitValidator) {
            this.limitValidator.setLimit(min, max);
        }
    }

    onAmount(value: string) {
        if (this.paymentMethod && this.amount.getValue() && !this.limitValidator.validate(value)) {
            this.valid.next(false);
            if(+value < this.paymentMethod.limit.deposit.min) {
                this.amountLimit.next(AmountError.AMOUNT_MIN);
            } else {
                this.amountLimit.next(AmountError.NONE);
            }
            if(+value > this.paymentMethod.limit.deposit.max) {
                this.amountLimit.next(AmountError.AMOUNT_MAX);
            }
        } else {
            this.amount.error.next(null);
        }
        this.checkValid();
    }

    onAmountActive(active: boolean) {
        if (active) {
            this.tip.next(WellxPayInputTip.AMOUNT);
        } else {
            this.tip.next(WellxPayInputTip.NONE);
        }
    }

    checkValid() {
        if (this.paymentMethod) {
            const isAmount = this.amount.isValid();
            this.valid.next(isAmount);
        }
    }

    setValue(value: number) {
        this.amount.value.getValue();
        this.amount.onFocus();
        this.amount.value.next(value.toString());
    }

    setMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        this.setLimit(paymentMethod.limit.deposit.min, paymentMethod.limit.deposit.max);
    }

    reset() {
        this.amount.value.next('');
        this.amount.error.next(null);
        this.amount.active.next(false);
        this.amount.autoCompleted.next(false);
        this.amountLimit.next(AmountError.NONE);
        this.tip.next(WellxPayInputTip.NONE);
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const valid = this.amount.isValid();

        if (valid && this.paymentMethod) {
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            const amount = parseFloat(this.amount.getValue());
            GoogleService.sentDepositAmount(
                userId,
                this.paymentMethod.title,
                amount.toString(),
                this.paymentMethod.currency.name
            );

            if (
                this.paymentMethod.id === ChannelType.WELLXPAY_H2H_BKASH ||
                this.paymentMethod.id === ChannelType.WELLXPAY_H2H_NAGAD ||
                this.paymentMethod.id === ChannelType.WELLXPAY_H2H_ROCKET ||
                this.paymentMethod.id === ChannelType.WELLXPAY_H2H_UPAY
            ) {
                WellxPayDepositService.requestInvoice(this.paymentMethod, amount);
                WellxPayDepositService.showInvoiceWait();
            } else {
                WellxPayDepositService.request(
                    this.paymentMethod,
                    amount,
                );
            }
        }
    }
}

const AmountFormService = new AmountForm();

export default AmountFormService;
