import { formatCurrencySign } from 'utils/format';
import { createRef } from 'react';
import Input, { InputSize } from '../../../../../../../component/Input/Input';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { translate as t } from '../../../../../../../service/Language';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import SumSelector from '../../SummSelector/SumSelector';
import Submit from '../Button/Submit';
import AmountFormService, { AmountError, PixErrorTip, PixInputTip } from '../service/AmountForm';
import DisabledAmount from './DisabledAmount/DisabledAmount';
import Attention from '../../../../../../../assets/svg/attention.svg'
import Back from './image/back.png';
import Directa24DepositService, { DepositState } from '../service/Directa24Deposit';

import './Amount.scss';


type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {
    amount: number,
    amountLimit: AmountError,
    errorTip: PixInputTip,
    errorAmount: string | null,
    errorCPF: string | null,
    errorName: string | null,
    errorSurname: string | null,
    errorEmail: string | null,
    errorLetters: PixErrorTip,
};

class Amount extends RxComponent<AmountProps, AmountState> {
    state = {
        amount: 0,
        amountLimit: AmountError.NONE,
        errorTip: PixInputTip.NONE,
        errorLetters: PixErrorTip.NONE,
        errorAmount: null,
        errorCPF: null,
        errorName: null,
        errorSurname: null,
        errorEmail: null,
    };

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountLimit: this.onAmountLimit.bind(this),
        onErrorTip: this.onErrorTip.bind(this),
        onErrorLetters: this.ErrorLetters.bind(this),
        onErrorAmount: this.onErrorAmount.bind(this),
        onErrorCPF: this.onErrorCPF.bind(this),
        onNameError: this.onNameError.bind(this),
        onSurnameError: this.onSurnameError.bind(this),
        onEmail: this.onEmail.bind(this),
        clickOutside: this.clickOutside.bind(this),
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    close() {
        this.setState({errorLetters: PixErrorTip.NONE});
    }

    clickOutside(event: PointerEvent) {
        if (this.ref.self.current && !this.ref.self.current.contains(event.target as Node)) {
            this.close();
        }
    }

    onAmount(amount: any) {
        this.setState({amount});
    }
    onAmountLimit(amountLimit: AmountError) {
        this.setState({amountLimit});
    }
    onErrorTip(errorTip: PixInputTip) {
        this.setState({errorTip});
    }

    ErrorLetters(errorLetters: PixErrorTip) {
        this.setState({ errorLetters });
    }

    onErrorAmount(errorAmount: string | null) {
        this.setState({errorAmount});
    }

    onErrorCPF(errorCPF: string | null) {
        this.setState({errorCPF});
    }

    onNameError(errorName: string | null) {
        this.setState({errorName});
    }

    onSurnameError(errorSurname: string | null) {
        this.setState({errorSurname});
    }

    onEmail(errorEmail: string | null) {
        this.setState({errorEmail});
    }

    componentDidMount() {
        const {paymentMethod} = this.props;

        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setValue(paymentMethod.defaultValue);
        this.subscribe(AmountFormService.errorTip, {next: this.handler.onErrorTip});
        this.subscribe(AmountFormService.errorLetters, {next: this.handler.onErrorLetters});
        this.subscribe(AmountFormService.amount.error, {next: this.handler.onErrorAmount});
        this.subscribe(AmountFormService.numberCPF.error, {next: this.handler.onErrorCPF});
        this.subscribe(AmountFormService.name.error, {next: this.handler.onNameError});
        this.subscribe(AmountFormService.surname.error, {next: this.handler.onSurnameError});
        this.subscribe(AmountFormService.email.error, {next: this.handler.onEmail});
        this.subscribe(AmountFormService.amountLimit, {next: this.handler.onAmountLimit});
        document.addEventListener('pointerdown', this.handler.clickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    render() {
        const {paymentMethod, lock} = this.props;
        const { amountLimit, errorTip, errorLetters, errorAmount, errorCPF, errorName, errorSurname, errorEmail} = this.state;

        const min =  formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency);
        const max =  formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency);

        const hint = `${t('popup.payment.amount.min')} ${min} — ${t('popup.payment.amount.max')} ${max}`;

        const amount = Directa24DepositService.state.getValue() === DepositState.AMOUNT || Directa24DepositService.state.getValue() === DepositState.WAIT;

        return (
            <div className="deposit-amount-pix">
                <form className="deposit-amount-pix-form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    {amount ? (
                        <>
                            <div className="deposit-amount-pix-form__container">
                                <MaskedInput
                                    mask="00000000000"
                                    inputService={AmountFormService.amount}
                                    className="deposit-amount-pix-form__container_input"
                                    name="amount"
                                    enabled={!lock}
                                />
                                <span className="deposit-amount-pix-form__container_currency">
                                {paymentMethod.currency.sign}
                            </span>
                                {!!errorAmount && (
                                    <div className="deposit-amount-pix-form__container__error">
                                        <img src={Attention} alt="attention"/>
                                        <span className="deposit-amount-pix-form__container__error_text">
                        {amountLimit === AmountError.AMOUNT_MIN && t('popup.payment.amount.min') + ` ${min}`}
                        {amountLimit === AmountError.AMOUNT_MAX && t('popup.payment.amount.max') + ` ${max}` }
                                    </span>
                                    </div>
                                )}
                                {errorTip === PixInputTip.AMOUNT && !errorAmount ? (
                                    <span className="deposit-amount-pix-form__container_tip">
                                {hint}
                            </span>
                                ) : null}
                            </div>
                            {<SumSelector paymentMethod={paymentMethod} disabled={lock}/>}
                        </>
                    ) : (
                        <DisabledAmount hint={hint}/>
                    )}
                    <div className="deposit-amount-pix-form__container">
                        <MaskedInput
                            mask="000.000.000-00"
                            placeholder="000.000.000-00"
                            inputService={AmountFormService.numberCPF}
                            name="wallet"
                            size={InputSize.medium}
                            className="deposit-amount-pix-form__container_input"
                        />
                        {errorTip === PixInputTip.CPF && !errorCPF && (
                            <span className="deposit-amount-pix-form__container_tip">
                                {t('popup.payment.easypaisa.account.error')}
                            </span>
                        )}
                        {!!errorCPF && (
                            <div className="deposit-amount-pix-form__container__error">
                                <img src={Attention} alt="attention"/>
                                <span className="deposit-amount-pix-form__container__error_text">
                        {t('popup.payment.pix.cpf.error')}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="deposit-amount-pix-form__container">
                        <Input
                            className="deposit-amount-pix__input"
                            inputService={AmountFormService.name}
                            name="name"
                            enabled={!lock}
                        />
                        {errorTip === PixInputTip.NAME && !errorName && (
                            <span className="deposit-amount-pix-form__container_tip">
                                {t('popup.payment.name.hint')}
                            </span>
                        )}
                        {!!errorName && (
                            <div className="deposit-amount-pix-form__container__error">
                                <img src={Attention} alt="attention"/>
                                <span className="deposit-amount-pix-form__container__error_text">
                        {t('popup.payment.name.hint')}
                                </span>
                            </div>
                        )}
                        {errorLetters === PixErrorTip.NAME ?
                            <div className="deposit-amount-pix-form__container-letters" ref={this.ref.self}>
                                <img className="deposit-amount-pix-form__container-letters__img" src={Back} alt="back"/>
                                <span
                                    className="deposit-amount-pix-form__container-letters__text">{t('popup.payment.brazil.error.letters')}</span>
                            </div>
                            : null}
                    </div>
                    <div className="deposit-amount-pix-form__container">
                        <Input
                            className="deposit-amount-pix-form__container_input"
                            inputService={AmountFormService.surname}
                            name="name"
                            enabled={!lock}
                        />
                        {errorTip === PixInputTip.SURNAME && !errorSurname && (
                            <span className="deposit-amount-pix-form__container_tip">
                                {t('popup.payment.name.hint')}
                            </span>
                        )}
                        {!!errorSurname && (
                            <div className="deposit-amount-pix-form__container__error">
                                <img src={Attention} alt="attention"/>
                                <span className="deposit-amount-pix-form__container__error_text">
                        {t('popup.payment.name.hint')}
                                </span>
                            </div>
                        )}
                        {errorLetters === PixErrorTip.SURNAME ?
                            <div className="deposit-amount-pix-form__container-letters" ref={this.ref.self}>
                                <img className="deposit-amount-pix-form__container-letters__img" src={Back} alt="back"/>
                                <span
                                    className="deposit-amount-pix-form__container-letters__text">{t('popup.payment.brazil.error.letters')}</span>
                            </div>
                            : null}
                    </div>
                    <div className="deposit-amount-pix-form__container" >
                        <Input
                            className="deposit-amount-pix-form__container_input"
                            inputService={AmountFormService.email}
                            name="surname"
                            enabled={!lock}
                        />
                        {!!errorEmail && (
                            <div className="deposit-amount-pix-form__container__error">
                                <img src={Attention} alt="attention"/>
                                <span className="deposit-amount-pix-form__container__error_text">
                        {t('recovery.error.email')}
                                </span>
                            </div>
                        )}
                    </div>
                    <Submit/>
                </form>
            </div>
        );
    }
}

export default Amount;
