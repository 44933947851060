import { BehaviorSubject } from 'rxjs';

import PaymentService from '../../../../../../service/Payment';
import { PaymentMethod } from '../../../PaymentMethod/types';

enum DepositState {
    AMOUNT,
    WAIT,
    LINK,
    FAILED,
    SUCCESS,
}

class CorefyDeposit {
    amount = 0;

    link = new BehaviorSubject('');

    state = new BehaviorSubject<DepositState>(DepositState.AMOUNT);

    request(paymentMethod: PaymentMethod, name: string, surname: string, amount: number) {
        this.state.next(DepositState.WAIT);
        PaymentService.corefy.deposit({
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
            name,
            surname,
            amount,
        });
    }

    setAmount(amount: number) {
        this.amount = amount;
    }

    getAmount(): number {
        return this.amount;
    }

    reset() {
        this.amount = 0;
        this.link.next('');
        this.state.next(DepositState.AMOUNT);
    }

    onError() {
        this.state.next(DepositState.FAILED);
    }

    onReceive(link: string) {
        const state = this.state.getValue();
        if (state === DepositState.WAIT) {
            this.link.next(link);
            this.state.next(DepositState.LINK);
        }
    }

    back() {
        this.reset();
    }
}

const CorefyDepositService = new CorefyDeposit();

export { DepositState };
export default CorefyDepositService;
