/* eslint-disable max-len */

import { Icon } from 'types';

function PlayArrow({ color = '#9FA0A8', width = 7, height = 10 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <g opacity="0.5">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.61537 9.62203L6.3846 6.38316C6.769 6.05285 7 5.54533 7 5C7 4.45467 6.769 3.94715 6.3846 3.61684L2.61537 0.377968C2.13182 -0.0375359 1.47628 -0.115679 0.924006 0.169432C0.368675 0.45612 0 1.06973 0 1.76113V8.23887C0 8.93027 0.368678 9.54388 0.924008 9.83057C1.47628 10.1157 2.13182 10.0375 2.61537 9.62203ZM5.73781 4.52579L1.96858 1.28692C1.79887 1.14109 1.56635 1.11265 1.37047 1.21378C1.17458 1.31491 1.05 1.52769 1.05 1.76113V8.23887C1.05 8.47231 1.17458 8.68509 1.37047 8.78622C1.56635 8.88735 1.79887 8.85891 1.96858 8.71308L5.73781 5.47421C5.87193 5.35897 5.95 5.18449 5.95 5C5.95 4.81552 5.87193 4.64103 5.73781 4.52579Z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

export default PlayArrow;
