import { translate as t } from 'service/Language';
import UIService from 'service/UI';

import PopupService from '../../../../../service/Popup';

import './WheelWinOlympus.scss';

type WheelWinProps = {
    spin: () => void;
};

function WheelWinOlympus({ spin }: WheelWinProps) {
    const spinAgain = () => {
        UIService.unlockScroll();
        PopupService.close();
        spin();
    };
    return (
        <div className='WheelWinOlympus'>
            <div className='WheelWinOlympus__header'>{t('wheel.popup.again.header')}</div>
            <div className='WheelWinOlympus__description'>{t('wheel.bonus.multiply.glory')}</div>
            <div className='WheelWinOlympus__title'>{t('wheel.popup.registration.bonus')}</div>
            <div className='WheelWinOlympus__again'>{t('wheel.popup.again.win.desc.crazy')}</div>
            <div className='WheelWinOlympus__plane' />
            <button className='WheelWinOlympus__button' onClick={spinAgain}>
                {t('wheel.aviator.win.button.again')}
            </button>
        </div>
    );
}

export default WheelWinOlympus;
