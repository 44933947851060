import { translate as t } from 'service/Language';
import UserDataService from 'service/UserData';
import { formatCurrencySign } from 'utils/format';
import useObservable from 'utils/useObservable';
import BalanceService from '../../../../../service/Balance';

import './Balance.scss';

const Balance = () => {
    const userData = useObservable(UserDataService.data, UserDataService.data.getValue());
    const balance = useObservable(BalanceService.balance, BalanceService.balance.getValue());

    return (
        <div className="payment-balance">
            <div className="payment-balance__title">{t('profile.balance.header.available')}</div>
            <div className="payment-balance__balance">
                {userData ? formatCurrencySign(+balance, 2, userData.currency) : ''}
            </div>
        </div>
    );
};

export default Balance;
