import { BehaviorSubject } from 'rxjs';

class Ban {
    state = new BehaviorSubject(false);

    setBan(banned: boolean) {
        this.state.next(banned);
    }

    getBan() {
        return this.state.getValue();
    }
}

const BanService = new Ban();

export default BanService;
