import { PayFixDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPayFixDepositRequest';
import { PayfixWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPayFixWithdrawal';

import PayfixInterface from '../../interface/payment/Payfix';

interface PayfixHandler {
    deposit(request: PayFixDepositRequest): void;
    withdraw(request: PayfixWithdrawalRequest): void;
}

class PayfixProvider implements PayfixInterface {
    handler: PayfixHandler;

    constructor(handler: PayfixHandler) {
        this.handler = handler;
    }

    deposit(request: PayFixDepositRequest): void {
        this.handler.deposit(request);
    }

    withdraw(request: PayfixWithdrawalRequest): void {
        this.handler.withdraw(request);
    }
}

export type { PayfixHandler };
export default PayfixProvider;
