import Input, { InputSize } from '../../../../../../../../component/Input/Input';
import MaskedInput from '../../../../../../../../component/Input/MaskedInput';
import { translate as t } from '../../../../../../../../service/Language';
import useObservable from '../../../../../../../../utils/useObservable';
import { InstapayErrorTip, InstapayInputTip } from '../../../../../Deposit/Instapay/service/AmountForm';
import { PaymentMethod } from '../../../../../PaymentMethod/types';
import CardFormService from '../../../service/CardForm';
import Back from '../image/back.png';

import './Mefete.scss';
import Attention from '../../../../../../../../assets/svg/attention.svg';


type MefeteProps = {
    paymentMethod: PaymentMethod;
    hint: string;
    error: string;
    fee: string;
};

function Mefete({ paymentMethod, hint, fee, error }: MefeteProps) {
    const tip = useObservable(CardFormService.tip, CardFormService.tip.getValue());
    const errorLetters = useObservable(CardFormService.errorLetters, CardFormService.errorLetters.getValue());

    return (
        <>
            <div className="card-withdrawal-form-block">
                <Input
                    className="card-withdrawal-form-block__input"
                    inputService={CardFormService.name}
                    name="name"
                    size={InputSize.medium}
                />
                {tip === InstapayInputTip.NAME && (
                    <span className="card-withdrawal-form-block__help">
                        {t('popup.payment.name.hint')}
                    </span>
                )}
                {errorLetters === InstapayErrorTip.NAME ?
                    <div className="card-withdrawal-form-block-letters" >
                        <img className="card-withdrawal-form-block-letters__img" src={Back} alt="back"/>
                        <span
                            className="card-withdrawal-form-block-letters__text">{t('popup.payment.turkey.error.letters')}</span>
                    </div> : null}
            </div>
            <div className="card-withdrawal-form-block">
                <Input
                    className="card-withdrawal-form-block__input"
                    inputService={CardFormService.surname}
                    name="surname"
                    size={InputSize.medium}
                />
                {tip === InstapayInputTip.SURNAME && (
                    <span className="card-withdrawal-form-block__help">
                        {t('popup.payment.name.hint')}
                    </span>
                )}
                {errorLetters === InstapayErrorTip.SURNAME ?
                    <div className="card-withdrawal-form-block-letters" >
                        <img className="card-withdrawal-form-block-letters__img" src={Back} alt="back"/>
                        <span
                            className="card-withdrawal-form-block-letters__text">{t('popup.payment.turkey.error.letters')}</span>
                    </div> : null}
            </div>
            <div className="card-withdrawal-form-block">
                <MaskedInput
                    className="card-withdrawal-form-block__input"
                    inputService={CardFormService.accountMefete}
                    name="accountNumber"
                    size={InputSize.medium}
                    mask="0000000000"
                />
                {tip === InstapayInputTip.ACCOUNT_NUMBER_MEFETE && (
                    <span className="card-withdrawal-form-block__help">
                        {t('profile.balance.withdraw.input.help.account.mefete')}
                    </span>
                )}
            </div>
            <div className="card-withdrawal-form-block">
                <MaskedInput
                    className="card-withdrawal-form-block__input"
                    inputService={CardFormService.amount}
                    name="amount"
                    mask="0000000"
                    hint={hint}
                    size={InputSize.medium}
                />
                <span className="card-withdrawal-form-block__currency">{paymentMethod.currency.sign}</span>
                {!!error ? (
                    <div className="card-withdrawal-form-block__error">
                        <img src={Attention} alt="attention"/>
                        <span
                            className="card-withdrawal-form-block__error_text">
                                {fee}
                            </span>
                    </div>
                ) : null}
            </div>

            {tip === InstapayInputTip.NON_EMPTY && (
                <span className="card-withdrawal-form-block__help">
                    {t('profile.balance.deposit.input.validation.empty')}
                </span>
            )}
        </>
    );
}

export default Mefete;
