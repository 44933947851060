import useObservable from 'utils/useObservable';

import AzarpayWithdrawService, { AzarpayWithdrawState } from '../service/AzarpayWithdraw';
import FailedPage from './ResultPage/FailedPage/FailedPage';
import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import BonusFailedPage from '../../../../../../component/BonusFailedPage/BonusFailedPage';

import './ResultForm.scss';

function ResultForm() {
    const state = useObservable(AzarpayWithdrawService.state, AzarpayWithdrawService.state.getValue());

    switch (state) {
        case AzarpayWithdrawState.ACCEPT: {
            return <SuccessPage />;
        }
        case AzarpayWithdrawState.REJECT: {
            return <FailedPage />;
        }
        case AzarpayWithdrawState.BONUS_REJECT: {
            return <BonusFailedPage />;
        }
        default: {
            return null;
        }
    }
}

export default ResultForm;
