import colors from 'appConstants/colors';
import RouteConfig from 'appConstants/RouteConfig';
import classNames from 'classnames';
import { Link, useRouteMatch } from 'react-router-dom';
import { translate as t } from 'service/Language';
import SvgImage from 'svg';
import GoogleService from '../../../../../../../service/Google';
import GameCategory from '../../../../../../../service/model/GameCategory';

import './SideLink.scss';

type SideLinkProps = {
    category: GameCategory;
};

function SideLink(props: SideLinkProps) {
    const { category } = props;
    const routeMatch = useRouteMatch<{ category: string }>(RouteConfig.casino.category);

    const active = category.route === (routeMatch?.params.category || '');

    const containerClassName = classNames({
        'side-link': true,
        'side-link____active': active,
    });

    const classNameTitle = classNames({
        'side-link__title': true,
        'side-link__title__active': active,
    });

    return (
        <Link to={`/${category.route}`} className={containerClassName} onClick={()=>        GoogleService.openGameCategory(category.name)}
        >
            <div className="side-link__icon">
                <SvgImage color={active ? colors.white : undefined} name={category.image} />
            </div>
            <span className={classNameTitle}>{t(category.name)}</span>
        </Link>
    );
}

export default SideLink;
