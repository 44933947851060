// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FormEvent } from 'react';
import { BehaviorSubject, combineLatestWith, map } from 'rxjs';

import Input from '../../../../../component/Input/service/Input';
import GeoDataService from '../../../../../service/GeoData';
import GoogleService from '../../../../../service/Google';
import Currency from '../../../../../service/model/Currency';
import RegistrationService from '../../../../../service/Registration';
import UserTrackService from '../../../../../service/UserTrack';
import Validator from '../../../../../service/Validator/Validator';

class WheelRegistration {
    email = new Input({
        title: 'registration.email',
        validator: Validator.email,
    });

    password = new Input({
        title: 'registration.password',
        validator: Validator.password,
        validatorText: 'registration.password.error',
    });

    termsAgreement = new BehaviorSubject<boolean>(false);

    promotionAgreement = new BehaviorSubject<boolean>(false);

    visible = new BehaviorSubject<boolean>(false);

    currency = new BehaviorSubject<Currency | null>(null);

    correct = this.email.valid
        .pipe(combineLatestWith(this.password.valid, this.currency))
        /* eslint-disable @typescript-eslint/no-unsafe-return */
        .pipe(map(([email, password, currency]) => email && password && currency));

    constructor() {
        GeoDataService.data.subscribe(geoData => {
            if (geoData && geoData.currencyList.length > 0) {
                this.selectCurrency(geoData.currencyList[0]);
            }
        });
    }

    selectCurrency(currency: Currency) {
        this.currency.next(currency);
    }

    togglePassword() {
        this.visible.next(!this.visible.getValue());
    }

    togglePromotions() {
        this.promotionAgreement.next(!this.promotionAgreement.value);
    }

    toggleTermsAndCondition() {
        this.termsAgreement.next(!this.termsAgreement.value);
    }

    submit(event: FormEvent) {
        event.preventDefault();

        const currency = this.currency.getValue();
        // const validationsArray = [
        //     this.email.isValid(),
        //     this.password.isValid(),
        // ];

        // if () {

        // }
        const valid = ![
            this.email.isValid(),
            this.password.isValid(),
            // this.promotionAgreement.getValue(),
            // this.termsAgreement.getValue(),
        ].includes(false);
        if (valid && currency) {
            const email = this.email.getValue();
            const password = this.password.getValue();
            GoogleService.landingRegistration();
            RegistrationService.byEmailAndPassword(email, password, currency, true);
        }
    }
}

const WheelRegistrationFormService = new WheelRegistration();

export default WheelRegistrationFormService;
