import Validator from '../interface/Validator';

class CheckTurkeyLetters implements Validator {
    validate(value: string): boolean {
        return new RegExp('[öüçğşı]+$').test(value);
    }
}
const CheckTurkeyLettersService = new CheckTurkeyLetters();

export { CheckTurkeyLettersService };
export default CheckTurkeyLetters;
