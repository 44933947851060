/* eslint-disable max-len */

import { Icon } from 'types';

function ActiveSearch({ color = '#3F4250', width = 20, height = 14 }: Icon) {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.6429 0.5H2.5C2.30261 0.5 2.14286 0.659929 2.14286 0.857143V2.48418H0.357143C0.15975 2.48418 0 2.64411 0 2.84132V2.84289V6.01586V9.8255V13C0 13.1972 0.15975 13.3571 0.357143 13.3571H3.53168H3.53864H14.3255H17.5C17.6974 13.3571 17.8571 13.1972 17.8571 13V11.3714H19.6429C19.8403 11.3714 20 11.2115 20 11.0142V0.857143C20 0.659929 19.8403 0.5 19.6429 0.5ZM13.9865 3.20004C14.1549 4.86211 15.4807 6.18718 17.1429 6.35489V9.48643C15.4802 9.65418 14.1542 10.9801 13.9864 12.6429H3.86811C3.70004 10.9804 2.37696 9.654 0.714286 9.48632V6.35489C2.37643 6.18718 3.70218 4.86211 3.87064 3.20004H13.9865ZM17.1429 3.20004V5.63621C15.8749 5.47496 14.8674 4.46775 14.7056 3.20004H17.1429ZM0.714286 5.63618V3.2H2.5H3.15154C2.98971 4.46775 1.98221 5.47496 0.714286 5.63618ZM0.714286 12.6429V10.2051C1.98279 10.3664 2.99071 11.3744 3.15186 12.6429H0.714286ZM17.1429 12.6429H14.7053C14.8664 11.3744 15.8744 10.3664 17.1429 10.2051V11.0142V12.6429ZM19.2857 10.6571H17.8571V9.82546V6.01582V2.84286V2.84129C17.8571 2.64407 17.6974 2.48414 17.5 2.48414H14.3255C14.3227 2.48414 14.3204 2.48564 14.3177 2.48571H3.53946C3.53671 2.48564 3.53446 2.48414 3.53168 2.48414H2.85714V1.21429H19.2857V10.6571Z"
                fill={color}
            />
            <path
                d="M8.92854 8.27702C9.5239 8.27702 9.5239 8.52934 9.5239 8.71352C9.5239 8.95016 9.25115 9.15002 8.92854 9.15002H8.21425C8.01686 9.15002 7.85711 9.30995 7.85711 9.50716C7.85711 9.70438 8.01686 9.86431 8.21425 9.86431H8.5714V10.4595C8.5714 10.6567 8.73115 10.8166 8.92854 10.8166C9.12593 10.8166 9.28568 10.6567 9.28568 10.4595V9.81534C9.83365 9.67774 10.2382 9.23859 10.2382 8.71352C10.2382 8.36752 10.1105 7.56274 8.92854 7.56274C8.33318 7.56274 8.33318 7.26784 8.33318 7.12624C8.33318 6.88959 8.60593 6.68974 8.92854 6.68974H9.64283C9.84022 6.68974 9.99997 6.52981 9.99997 6.33259C9.99997 6.13538 9.84022 5.97545 9.64283 5.97545H9.28568V5.38009C9.28568 5.18288 9.12593 5.02295 8.92854 5.02295C8.73115 5.02295 8.5714 5.18288 8.5714 5.38009V6.02441C8.02343 6.16202 7.6189 6.60116 7.6189 7.12624C7.6189 7.68256 7.96279 8.27702 8.92854 8.27702Z"
                fill={color}
            />
            <path
                d="M8.9285 11.7705C11.0508 11.7705 12.7775 10.0437 12.7775 7.92127C12.7775 5.79884 11.0508 4.07202 8.9285 4.07202C6.80622 4.07202 5.07947 5.79881 5.07947 7.92124C5.07947 10.0437 6.80622 11.7705 8.9285 11.7705ZM8.9285 4.78631C10.657 4.78631 12.0633 6.19256 12.0633 7.92124C12.0633 9.64991 10.657 11.0562 8.9285 11.0562C7.2 11.0562 5.79375 9.64995 5.79375 7.92127C5.79375 6.19259 7.2 4.78631 8.9285 4.78631Z"
                fill={color}
            />
        </svg>
    );
}

export default ActiveSearch;
