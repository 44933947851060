import { Fragment, useEffect, useRef, useState } from 'react';
import GameHelper from 'service/GameHelper';
import { useIsDesktop, useIsXS } from 'utils/useBreakpoints';

import GamesService from '../../../service/Games';
import GameSortService from '../../../service/GameSort';
import { GameList as GameListType } from '../../../service/model/Game';
import useObservable from '../../../utils/useObservable';
import GameCard from './GameCard/GameCard';

import './GameList.scss';

const ITEMS_ON_PAGE_XS = 12;
const ITEMS_ON_PAGE_OTHER = 24;

type GameListProps = {
    games: GameListType;
};

const LAZY_LOADING_DISTANCE = 100;

function GameList(props: GameListProps) {
    const { games } = props;

    const sort = useObservable(GameSortService.method, GameSortService.method.getValue());
    const topGameList = useObservable(GamesService.topGameList, GamesService.topGameList.getValue());

    const isXS = useIsXS();
    const isDesktop = useIsDesktop();

    const gamesPerPage = isXS ? ITEMS_ON_PAGE_XS : ITEMS_ON_PAGE_OTHER;

    const totalPages = Math.ceil(games.length / gamesPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [gamesToShow, setGamesToShow] = useState<GameListType>([]);

    useEffect(() => {
        setCurrentPage(1);
    }, [games]);

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (currentPage >= totalPages) {
            return;
        }
        const scrollHandler = () => {
            if (!containerRef.current) {
                return;
            }
            const { bottom } = containerRef.current.getBoundingClientRect();
            if (bottom - window.innerHeight < LAZY_LOADING_DISTANCE) {
                setCurrentPage(currentPage + 1);
            }
        };
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [currentPage, totalPages]);

    useEffect(() => {
        const filteredGames = games.filter((game) => GameHelper.matchPlatform(game, isDesktop));
        const sortedGames = filteredGames.sort(sort.sort);
        setGamesToShow(sortedGames.slice(0, gamesPerPage * currentPage));
    }, [currentPage, games, gamesPerPage, isDesktop, sort]);

    // const listGame = topGameList.length ? topGameList : gamesToShow;

    return (
        <div className="game-list" ref={containerRef}>
            {gamesToShow.map((game, index) => (
                <Fragment key={game.id}>
                    {GamesService.isAllowed(game.provider) ? <GameCard game={game} tournament={false} /> : null}
                </Fragment>
            ))}
        </div>
    );
}

export default GameList;
