/* eslint-disable max-len */

import { Icon } from 'types';

function Expand({ color = '#9FA0A8', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.75 14C4.33579 14 4 14.3358 4 14.75V22.5V23C4 23.5523 4.44772 24 5 24H5.5H13.25C13.6642 24 14 23.6642 14 23.25C14 22.8358 13.6642 22.5 13.25 22.5H5.5V14.75C5.5 14.3358 5.16421 14 4.75 14Z"
                fill={color}
            />
            <path
                d="M23.25 14C23.6642 14 24 13.6642 24 13.25V5C24 4.44771 23.5523 4 23 4H14.75C14.3358 4 14 4.33579 14 4.75C14 5.16421 14.3358 5.5 14.75 5.5H22.5V13.25C22.5 13.6642 22.8358 14 23.25 14Z"
                fill={color}
            />
        </svg>
    );
}

export default Expand;
