import UserDataService from '../UserData';
import Country, { unknownCountry } from './Country';
import { unknownCurrency } from './Currency';

class UserData {
    id: number;

    email: string;

    phone: number | null;

    /** Имя */
    firstName: string;

    /** Фамилия */
    secondName: string;

    country = unknownCountry;

    birthDate: Date | null = null;

    currency = unknownCurrency;

    registrationDate: number;

    constructor(
        id = 0,
        email = '',
        phone: number | null,
        firstName = '',
        secondName = '',
        country: Country,
        registrationDate: number,
    ) {
        this.id = id;
        this.email = email;
        this.phone = phone;
        this.firstName = firstName;
        this.secondName = secondName;
        this.country = country;
        this.registrationDate = registrationDate;
    }

    copy(): UserData {
        const data = new UserData(
            this.id,
            this.email,
            this.phone,
            this.firstName,
            this.secondName,
            this.country,
            this.registrationDate,
        );

        data.currency = this.currency;
        data.birthDate = this.birthDate ? new Date(this.birthDate) : null;
        return data;
    }

    isValid() {
        const userData = UserDataService.checkUserData();

        return userData;
    }
}

export default UserData;
