/* eslint-disable max-len */

import { Icon } from 'types';

function ChangeIcon({ color = '#3F4250', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.7205 3.1712L20.8116 6.89101C21.0627 7.11928 21.0627 7.48937 20.8116 7.71763L16.7205 11.4374C16.4695 11.6657 16.0624 11.6657 15.8114 11.4374C15.5603 11.2092 15.5603 10.8391 15.8114 10.6108L18.8051 7.88883L3.64285 7.88883C3.28782 7.88883 3 7.62714 3 7.30432C3 6.9815 3.28782 6.71981 3.64285 6.71981L18.8051 6.71981L15.8114 3.99782C15.5603 3.76956 15.5603 3.39947 15.8114 3.1712C16.0624 2.94293 16.4695 2.94293 16.7205 3.1712Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.27949 20.0461L3.1884 16.3263C2.93735 16.098 2.93735 15.7279 3.1884 15.4997L7.27949 11.7798C7.53054 11.5516 7.93757 11.5516 8.18862 11.7798C8.43967 12.0081 8.43967 12.3782 8.18862 12.6065L5.19495 15.3285L20.3571 15.3285C20.7122 15.3285 21 15.5901 21 15.913C21 16.2358 20.7122 16.4975 20.3571 16.4975H5.19495L8.18862 19.2195C8.43967 19.4477 8.43967 19.8178 8.18862 20.0461C7.93757 20.2744 7.53054 20.2744 7.27949 20.0461Z"
                fill={color}
            />
        </svg>
    );
}

export default ChangeIcon;
