import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';

import AuthService from '../../../../../../service/Auth';
import GoogleService from '../../../../../../service/Google';
import { OnlyLetterService } from '../../../../../../service/Validator/OnlyLetter';
import Validator from '../../../../../../service/Validator/Validator';
import { PaymentMethod } from '../../../PaymentMethod/types';
import DepositService from './InstapayDeposit';
import { CheckTurkeyLettersService } from '../../../../../../service/Validator/CheckTurkeyLetters';
import {ChannelType} from '@ay_tsarbet/newbet-core/dist/types';

export enum InstapayInputTip {
    NONE,
    NAME,
    SURNAME,
    EMAIL,
    PAPARA_NUMBER,
    PAPARA_NAME,
    PAPARA_SURNAME,
    ACCOUNT_NUMBER_CEP,
    ACCOUNT_NUMBER_HAVALE_EXP,
    ACCOUNT_NUMBER_MEFETE,
    PHONE,
    PHONE_CODE,
    BANK,
    BRANCH,
    IBAN,
    TC_NUMBER,
    DUE_DATE,
    NON_EMPTY,
}

export enum InstapayErrorTip {
    NONE,
    NAME,
    SURNAME,
}

class AmountForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(1, 1000000);

    handler = {
        onAmount: this.onAmount.bind(this),
        onName: this.onName.bind(this),
        onNameActive: this.onNameActive.bind(this),
        onSurname: this.onSurname.bind(this),
        onSurnameActive: this.onSurnameActive.bind(this),
        onEmailActive: this.onEmailActive.bind(this),
        valid: this.checkValid.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.name.value.subscribe(this.handler.onName);
        this.name.active.subscribe(this.handler.onNameActive);
        this.surname.value.subscribe(this.handler.onSurname);
        this.surname.active.subscribe(this.handler.onSurnameActive);
        this.email.value.subscribe(this.handler.valid);
        this.email.active.subscribe(this.handler.onEmailActive);
        this.birth.value.subscribe(this.handler.valid);
    }

    amount = new InputService({
        title: 'profile.balance.deposit.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.deposit.input.amount',
    });

    name = new InputService({
        title: 'popup.payment.pix.name',
        validator: Validator.OnlyLetter,
        validatorText: 'popup.payment.pix.name',
    });

    surname = new InputService({
        title: 'popup.payment.pix.surname',
        validator: Validator.OnlyLetter,
        validatorText: 'popup.payment.pix.surname',
    });

    email = new InputService({
        title: 'profile.balance.deposit.input.email',
        validator: Validator.email,
        validatorText: 'profile.balance.deposit.input.email',
    });

    birth = new InputService({
        title: 'profile.balance.deposit.input.birthday',
        validator: Validator.birth,
        validatorText: 'profile.balance.deposit.input.birthday',
    });

    valid = new BehaviorSubject<boolean>(false);

    errorType = new BehaviorSubject<InstapayInputTip>(InstapayInputTip.NONE);

    errorLetters = new BehaviorSubject<InstapayErrorTip>(InstapayErrorTip.NONE);

    setLimit(min: number, max: number) {
        if (this.limitValidator) {
            this.limitValidator.setLimit(min, max);
        }
    }

    setAmount(value: number) {
        this.amount.onFocus();
        this.amount.value.next(value.toString());
    }

    onAmount(value: string) {
        this.checkValid();
        if (!!value && !this.amount.isValid()) {
            this.amount.error.next('profile.balance.deposit.input.amount')
        } else {
            this.amount.error.next(null);
        }
    }

    onName(value: string) {
        if (this.name.getValue() &&
            !OnlyLetterService.validate(value)
        ) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.name.value.next(name);
            if (CheckTurkeyLettersService.validate(value)) {
                this.errorLetters.next(InstapayErrorTip.NAME)
            } else {
                this.errorLetters.next(InstapayErrorTip.NONE)
            }

        } else {
            this.name.error.next(null);
            this.errorLetters.next(InstapayErrorTip.NONE)
            this.checkValid();
        }
    }

    onSurname(value: string) {
        if (this.surname.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const surname = value.slice(0, current - 1);
            this.surname.value.next(surname);
            if (CheckTurkeyLettersService.validate(value)) {
                this.errorLetters.next(InstapayErrorTip.SURNAME)
            } else {
                this.errorLetters.next(InstapayErrorTip.NONE)
            }
        } else {
            this.surname.error.next(null);
            this.errorLetters.next(InstapayErrorTip.NONE)
            this.checkValid();
        }
    }

    onEmailActive(active: boolean) {
        if (active) {
            this.errorType.next(InstapayInputTip.EMAIL);
        } else {
            this.errorType.next(InstapayInputTip.NONE);
        }
    }

    setMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        this.setLimit(paymentMethod.limit.deposit.min, paymentMethod.limit.deposit.max);
    }

    getAmount(paymentMethod: number, amount: number) {
        if (paymentMethod === 194 || paymentMethod === 195 || paymentMethod === 196) {
            return amount;
        } else return 0;
    }

    onNameActive(active: boolean) {
       if(active) {
           if(this.paymentMethod?.id === ChannelType.TRY_PAPARA) {
               this.errorType.next(InstapayInputTip.PAPARA_NAME);
           } else {
               this.errorType.next(InstapayInputTip.NAME);
           }
       } else {
           this.errorType.next(InstapayInputTip.NONE);
       }
    }

    onSurnameActive(active: boolean) {
        if(active) {
            if(this.paymentMethod?.id === ChannelType.TRY_PAPARA) {
                this.errorType.next(InstapayInputTip.PAPARA_SURNAME);
            } else {
                this.errorType.next(InstapayInputTip.SURNAME);
            }
        } else {
            this.errorType.next(InstapayInputTip.NONE);
        }
    }

    checkValid() {
        if (this.paymentMethod?.id === ChannelType.TRY_CREDIT_CARD) {
            this.validationBankCards();
        }
        if (this.paymentMethod?.id === ChannelType.TRY_BANK) {
            this.validationHavaleVIP();
        }
        if (this.paymentMethod?.id === ChannelType.TRY_PAPARA) {
            this.validationPapara();
        }
        if (this.paymentMethod?.id === ChannelType.TRY_MFT) {
            this.validationMefete();
        }
        if (this.paymentMethod?.id === ChannelType.TRY_FAST_BANK) {
            this.validationExpressHavale();
        }
        if (this.paymentMethod?.id === ChannelType.TRY_CEP_BANK) {
            this.validationCepBank();
        }
    }

    validationBankCards() {
        const valid =
            this.name.isValid() &&
            this.surname.isValid() &&
            this.birth.isValid() &&
            this.email.isValid() &&
            this.amount.isValid();
        if (valid) {
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    validationHavaleVIP() {
        const valid = this.name.isValid() && this.surname.isValid() && this.amount.isValid();
        if (valid) {
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    validationPapara() {
        const valid = this.name.isValid() && this.surname.isValid();
        this.valid.next(valid);
    }

    validationMefete() {
        const valid = this.name.isValid() && this.surname.isValid();
        this.valid.next(valid);
    }

    validationExpressHavale() {
        const valid = this.name.isValid() && this.surname.isValid() && this.amount.isValid();
        if (valid) {
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    validationCepBank() {
        const valid = this.name.isValid() && this.surname.isValid() && this.email.isValid() && this.amount.isValid();
        if (valid) {
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }


    reset() {
        this.amount.value.next('');
        this.name.value.next('');
        this.surname.value.next('');
        this.birth.value.next('');
        this.email.value.next('');

        this.amount.error.next(null);
        this.name.error.next(null);
        this.surname.error.next(null);
        this.birth.error.next(null);
        this.email.error.next(null);

        this.amount.active.next(false);
        this.name.active.next(false);
        this.surname.active.next(false);
        this.birth.active.next(false);
        this.email.active.next(false);

        this.valid.next(false);
        this.errorType.next(InstapayInputTip.NONE);
        this.errorLetters.next(InstapayErrorTip.NONE);
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const assert = [this.name.isValid(), this.surname.isValid()];
        const valid = !assert.includes(false);

        if (valid && this.paymentMethod) {
            const revertBirth = (str: string): string => {
                const arr = str.split('-');

                const day = arr.splice(0, 1)[0];

                arr.splice(2, 0, day);

                const year = arr.splice(1, 1)[0];

                arr.splice(0, 0, year);
                return arr.join('-');
            };

            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            const amount = parseFloat(this.amount.getValue());
            const name = this.name.getValue();
            const surname = this.surname.getValue();
            const tcNumber = '';
            const birth = this.birth.getValue() ? revertBirth(this.birth.getValue()) : '';
            const email = this.email.getValue();
            // отправляем сумму только для 194, 195, 196 channelType для других отправляем 0
            const dispatchAmount = this.getAmount(this.paymentMethod.id, amount);

            GoogleService.sentDepositAmount(
                userId,
                this.paymentMethod.title,
                amount.toString(),
                this.paymentMethod.currency.name
            );
            DepositService.request(this.paymentMethod, name, surname, tcNumber, birth, email, dispatchAmount);
        } else {
            const formFieldList = [this.name, this.surname];
            formFieldList.filter((field) => !field.isValid()).forEach((item) => item.error.next(item.validatorText));
        }
    }
}

const AmountFormService = new AmountForm();

export default AmountFormService;
