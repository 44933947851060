import { AnindaHavaleDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendAnindaHavaleDepositRequest';
import { AnindaHavaleWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendAnindaHavaleWithdrawal';
import {
    AnindaHavaleDepositCode,
    AnindaHavaleDepositResponse,
    AnindaHavaleWithdrawResponse,
} from '@ay_tsarbet/newbet-core/dist/types';
import AnindaHavaleDepositService from 'page/Profile/Balance/Deposit/AnindaHavale/service/AnindaHavaleDeposit';
import { BehaviorSubject, Subject } from 'rxjs';

import AnindaHavaleInterface from '../interface/payment/AnindaHavale';
import WSBet from '../WSBet';
import StatusFormPopupService, { StatusForm } from '../../page/Profile/Balance/Deposit/DepositPopup/service/StatusForm';

enum AnindaHavaleError {
    UNKNOWN,
    DEPOSIT,
    WITHDRAW,
}

class AnindaHavale implements AnindaHavaleInterface {
    error = new Subject<AnindaHavaleError>();

    redirect = new BehaviorSubject<string | null>(null);

    withdrawResult = new Subject<void>();

    deposit(request: AnindaHavaleDepositRequest) {
        console.log('ANINDA HAVALE | DEPOSIT REQUEST', request);
        WSBet.payment.anindaHavale.deposit(request);
    }

    withdraw(withdraw: AnindaHavaleWithdrawalRequest) {
        console.log('ANINDA HAVALE  | WITHDRAW REQUEST', withdraw);
        WSBet.payment.anindaHavale.withdraw(withdraw);
    }

    onWithdrawReceive(data: AnindaHavaleWithdrawResponse) {
        console.log('ANINDA HAVALE  | WITHDRAW RESPONSE', data);
        if (data.errorCode >= AnindaHavaleDepositCode.success) {
            this.withdrawResult.next();
            StatusFormPopupService.status.next(StatusForm.SUCCESS);
        } else {
            this.error.next(AnindaHavaleError.WITHDRAW);
            StatusFormPopupService.status.next(StatusForm.FAILED);
        }
    }

    onDepositReceive(data: AnindaHavaleDepositResponse) {
        console.log('ANINDA HAVALE | DEPOSIT RESPONSE', data);
        if (!data.code) {
            AnindaHavaleDepositService.onReceive(data.message);
            StatusFormPopupService.status.next(StatusForm.SUCCESS);
        } else {
            AnindaHavaleDepositService.onError();
            StatusFormPopupService.status.next(StatusForm.FAILED);
        }
    }
}

export { AnindaHavaleError };
export default AnindaHavale;
