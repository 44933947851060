import classNames from 'classnames';
import { PaymentMethod } from 'page/Profile/Balance/PaymentMethod/types';
import useObservable from 'utils/useObservable';

import { formatCurrencySign } from '../../../../../../utils/format';
import AmountFormService from '../Pix/service/AmountForm';

import './SumSelector.scss';

type SumSelectorProps = {
    paymentMethod: PaymentMethod;
    disabled: boolean;
};

const SumSelector = (props: SumSelectorProps) => {
    const amount = useObservable(AmountFormService.amount.value, AmountFormService.amount.value.getValue());

    function onClickHandler(value: number) {
        AmountFormService.setValue(value);
        AmountFormService.amount.autoCompleted.next(false);
    }

    return (
        <div className="sum-selector">
            {props.paymentMethod.amountPreset.map((preset, index) => {
                const itemClass = classNames('sum-selector__item', {
                    active: Number(amount) === Number(preset),
                });
                return (
                    <button
                        type="button"
                        className={itemClass}
                        onClick={() => onClickHandler(preset)}
                        disabled={props.disabled}
                        key={index}
                    >
                        {formatCurrencySign(Number(preset), 0, props.paymentMethod.currency)}
                    </button>
                );
            })}
        </div>
    );
};

export default SumSelector;
