import { FormEvent } from 'react';
import { translate as t } from 'service/Language';

import RecoveryService, { RecoveryError } from '../../service/Recovery';
import Close from '../Close/Close';
import Input, { InputSize } from '../Input/Input';
import RxComponent from '../RxComponent/RxComponent';
import Sent from './Sent';
import EmailInputService from './service/EmailInput';
import RecoveryFormService from './service/RecoveryForm';

import './Recovery.scss';

type RecoveryProps = {};
type RecoveryState = {
    sent: boolean;
};

class Recovery extends RxComponent<RecoveryProps, RecoveryState> {
    handler = {
        onSubmit: this.onSubmit.bind(this),
        onError: this.onError.bind(this),
        onSent: this.onSent.bind(this),
    };

    state = {
        sent: false,
    };

    onSubmit(event: FormEvent) {
        event.preventDefault();

        RecoveryFormService.submit();
    }

    onSent() {
        this.setState({ sent: true });
    }

    onError(error: RecoveryError) {
        EmailInputService.error.next(t('recovery.error.email'));
    }

    componentDidMount() {
        this.subscribe(RecoveryService.complete, { next: this.handler.onSent });
        this.subscribe(RecoveryService.error, { next: this.handler.onError });
    }

    render() {
        const { sent } = this.state;

        return (
            <form onSubmit={this.handler.onSubmit} className="recovery-form">
                <div className="recovery-form__header">
                    <span className="recovery-form__header-text">{t('recovery.header')}</span>
                    <Close />
                </div>

                {sent ? (
                    <Sent />
                ) : (
                    <div className="recovery-form__body">
                        <Input
                            name="email"
                            inputService={EmailInputService}
                            className="recovery-form__input"
                            size={InputSize.small}
                        />
                        <button className="recovery-form__submit" type="submit">
                            {t('recovery.button.continue')}
                        </button>
                    </div>
                )}
            </form>
        );
    }
}

export default Recovery;
