import { BehaviorSubject } from 'rxjs';

import PaymentService from '../../../../../../service/Payment';
import { PaymentMethod } from '../../../PaymentMethod/types';

enum DepositState {
    AMOUNT,
    WAIT,
    FAILED,
    SUCCESS,
}

class EpulDeposit {
    amount = 0;

    state = new BehaviorSubject<DepositState>(DepositState.AMOUNT);

    request(paymentMethod: PaymentMethod, amount: number) {
        this.state.next(DepositState.WAIT);
        PaymentService.epul.deposit({
            amount,
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
        });
    }

    setAmount(amount: number) {
        this.amount = amount;
    }

    getAmount(): number {
        return this.amount;
    }

    reset() {
        this.amount = 0;
        this.state.next(DepositState.AMOUNT);
    }

    onReceive(link: string) {
        const state = this.state.getValue();
        if (state === DepositState.WAIT) {
            window.open(link, '_self');
        }
    }

    back() {
        this.reset();
    }
}

const EpulDepositService = new EpulDeposit();

export { DepositState };
export default EpulDepositService;
