/* eslint-disable max-len */

import { Icon } from 'types';

function TimeIcon6({ color = '#3959D9', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                d="M19.0563 8.81274L20.5826 7.04086C20.7308 6.86892 20.7078 6.61242 20.5313 6.46853L19.3004 5.46247C19.1239 5.31816 18.8605 5.34011 18.7127 5.51247L17.292 7.162C16.1179 6.32585 14.7313 5.76286 13.2246 5.56124V4.09911H15.0394C15.2426 4.09911 15.4079 3.93814 15.4079 3.74018V2.35893C15.4079 2.16097 15.2426 2 15.0394 2H8.96021C8.75653 2 8.59166 2.16097 8.59166 2.35893V3.74018C8.59166 3.93814 8.75653 4.09911 8.96021 4.09911H10.775V5.56124C9.26824 5.76246 7.88169 6.32585 6.70802 7.16159L5.28683 5.51247C5.13866 5.34011 4.87571 5.31776 4.69874 5.46247L3.4683 6.46812C3.29133 6.61202 3.26838 6.86851 3.41655 7.04046L4.94291 8.81193C3.73042 10.3058 3 12.1874 3 14.2349C3 19.068 7.03733 23 12 23C16.9627 23 21 19.068 21 14.2349C20.9996 12.1874 20.2692 10.3062 19.0563 8.81274ZM11.9996 21.5773C7.84288 21.5773 4.46042 18.2835 4.46042 14.2349C4.46042 10.1867 7.84288 6.89249 11.9996 6.89249C16.1567 6.89249 19.5387 10.1867 19.5387 14.2349C19.5387 18.2839 16.1567 21.5773 11.9996 21.5773Z"
                fill={color}
            />
            <path
                d="M9.60254 14.958C9.60254 12.165 10.7402 10.7686 13.0156 10.7686C13.3737 10.7686 13.6764 10.7962 13.9238 10.8516V11.8086C13.6764 11.737 13.39 11.7012 13.0645 11.7012C12.2995 11.7012 11.7249 11.9062 11.3408 12.3164C10.9567 12.7266 10.7484 13.3841 10.7158 14.2891H10.7744C10.9274 14.0254 11.1423 13.8219 11.4189 13.6787C11.6956 13.5322 12.0212 13.459 12.3955 13.459C13.0433 13.459 13.5479 13.6576 13.9092 14.0547C14.2705 14.4518 14.4512 14.9906 14.4512 15.6709C14.4512 16.4196 14.2412 17.012 13.8213 17.4482C13.4046 17.8812 12.835 18.0977 12.1123 18.0977C11.6012 18.0977 11.1569 17.9756 10.7793 17.7314C10.4017 17.484 10.1104 17.126 9.90527 16.6572C9.70345 16.1852 9.60254 15.6188 9.60254 14.958ZM12.0928 17.1504C12.4867 17.1504 12.7894 17.0234 13.001 16.7695C13.2158 16.5156 13.3232 16.1527 13.3232 15.6807C13.3232 15.2705 13.2223 14.9482 13.0205 14.7139C12.8219 14.4795 12.5225 14.3623 12.1221 14.3623C11.8747 14.3623 11.6468 14.416 11.4385 14.5234C11.2301 14.6276 11.0658 14.7725 10.9453 14.958C10.8249 15.1403 10.7646 15.3275 10.7646 15.5195C10.7646 15.9785 10.8883 16.3659 11.1357 16.6816C11.3864 16.9941 11.7054 17.1504 12.0928 17.1504Z"
                fill={color}
            />
        </svg>
    );
}

export default TimeIcon6;
