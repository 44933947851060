/* eslint-disable max-len */

import { Icon } from 'types';

function LeftFlatArrow({ color = '#3F4250', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7072 23.7071C18.3166 24.0976 17.6835 24.0976 17.293 23.7071L8.79295 15.2071C8.40243 14.8166 8.40243 14.1834 8.79295 13.7929L17.293 5.29289C17.6835 4.90237 18.3166 4.90237 18.7072 5.29289C19.0977 5.68342 19.0977 6.31658 18.7072 6.70711L10.9143 14.5L18.7072 22.2929C19.0977 22.6834 19.0977 23.3166 18.7072 23.7071Z"
                fill={color}
            />
        </svg>
    );
}

export default LeftFlatArrow;
