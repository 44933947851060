import NoticeProviderInterface from '../interface/NoticeProviderInterface';

class NoticeProvider {
    handler: NoticeProviderInterface;

    constructor(handler: NoticeProviderInterface) {
        this.handler = handler;
    }

    updateNotice(noticeId: string) {
        this.handler.sendUpdateNotice(noticeId);
    }
}

export default NoticeProvider;
