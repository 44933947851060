import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';

import { BankAZN, defaultBank } from '../../../../../../service/model/BankAZN';
import AzarpayWithdraw from '../../../../../../service/model/payment/AzarpayWithdraw';
import { OnlyLetterService } from '../../../../../../service/Validator/OnlyLetter';
import { PaymentMethod } from '../../../PaymentMethod/types';
import WithdrawService from '../../service/Withdrawal';
import AzarpayWithdrawService, { AzarpayWithdrawState } from './AzarpayWithdraw';
import { DueDateMonthYearService } from '../../../../../../service/Validator/DueDateMonthYear';
import BonusService from '../../../../../../service/Bonus';
import StatusFormPopupService, { StatusForm } from '../../../Deposit/DepositPopup/service/StatusForm';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import { translate as t } from '../../../../../../service/Language';
import BalanceService from '../../../../../../service/Balance';

export enum AzarpayInputTip {
    NONE,
    AMOUNT,
    NAME,
    SURNAME,
    CARD,
    DUE_DATE,
    NON_EMPTY,
}

export enum AzarpayError {
    NONE,
    AMOUNT_FEE,
    CARD,
    FULL_DUE,
    EXPIRED,
}

export enum AmountError {
    NONE,
    AMOUNT_MIN,
    AMOUNT_MAX,
    AMOUNT_WITHDRAWAL,
    AMOUNT_FEE,
}

class CardForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(0, 1);

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.withdraw.input.amount',
    });

    card = new InputService({
        title: 'profile.balance.withdraw.input.card',
        validator: Validator.maskCardNumber,
        validatorText: 'profile.balance.withdraw.input.card',
    });

    name = new InputService({
        title: 'profile.balance.deposit.input.name',
        validator: Validator.OnlyLetter,
    });

    surname = new InputService({
        title: 'profile.balance.deposit.input.surname',
        validator: Validator.OnlyLetter,
    });

    bank = new BehaviorSubject<BankAZN>(defaultBank);

    dueDate = new InputService({
        title: 'profile.balance.withdraw.input.duedate',
        validator: Validator.dueDateMonthYear,
        validatorText: 'profile.balance.withdraw.input.duedate',
    });

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        onName: this.onName.bind(this),
        onNameActive: this.onNameActive.bind(this),
        onSurname: this.onSurname.bind(this),
        onSurnameActive: this.onSurnameActive.bind(this),
        onCard: this.onCard.bind(this),
        onCardActive: this.onCardActive.bind(this),
        onDueDate: this.onDueDate.bind(this),
        onDueDateActive: this.onDueDateActive.bind(this),
        valid: this.checkValid.bind(this),
        onBalance: this.onBalance.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
        this.name.value.subscribe(this.handler.onName);
        this.name.active.subscribe(this.handler.onNameActive);
        this.surname.value.subscribe(this.handler.onSurname);
        this.surname.active.subscribe(this.handler.onSurnameActive);
        this.card.value.subscribe(this.handler.onCard);
        this.card.active.subscribe(this.handler.onCardActive);
        this.dueDate.value.subscribe(this.handler.onDueDate);
        this.dueDate.active.subscribe(this.handler.onDueDateActive);
        BalanceService.balance.subscribe(this.handler.onBalance);
    }

    valid = new BehaviorSubject<boolean>(false);

    tip = new BehaviorSubject<AzarpayInputTip>(AzarpayInputTip.NONE);

    error = new BehaviorSubject<AzarpayError>(AzarpayError.NONE);

    errorCard = new BehaviorSubject(false);

    balance = new BehaviorSubject(0);

    amountLimit = new BehaviorSubject<AmountError>(AmountError.NONE);

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        const limit = this.paymentMethod.limit.withdraw;
        const isFee = WithdrawService.fee.getValue();
        const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
        this.limitValidator.setLimit(isFee ? isMin : limit.min, limit.max);
    }

    onBalance(balance: number) {
        this.balance.next(balance)
    }

    onAmount(value: string) {
        this.checkValid();
        const isValue = this.amount.value.getValue();
        if (!value.indexOf('0', 0)) {
            const current = value.length;
            const done = value.slice(0, current - 1);
            this.amount.value.next(done);
        }
        if (this.paymentMethod && isValue) {
            if (this.paymentMethod.id === ChannelType.VALERPAY_WITHDRAWAL) {
                const isFee = WithdrawService.fee.getValue();
                const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
                const isMax = Math.ceil(this.paymentMethod.limit.withdraw.max * 1.25);
                const min = Math.ceil(this.paymentMethod.limit.withdraw.min);
                const max = Math.ceil(this.paymentMethod.limit.withdraw.max);
                    if (isFee) {
                        if (this.balance.getValue() < +isValue) {
                            this.amount.error.next('profile.balance.withdraw.input.amount');
                            this.amountLimit.next(AmountError.AMOUNT_WITHDRAWAL)
                            this.valid.next(false)
                        } else {
                            if (+isValue < +isMin) {
                                this.amount.error.next('profile.balance.withdraw.input.amount');
                                this.amountLimit.next(AmountError.AMOUNT_MIN)
                                this.error.next(AzarpayError.AMOUNT_FEE)
                                this.valid.next(false)
                            } else if (+isValue > isMax) {
                                this.amount.error.next('profile.balance.withdraw.input.amount');
                                this.amountLimit.next(AmountError.AMOUNT_MAX)
                                this.valid.next(false)
                            } else {
                                this.amount.error.next(null);
                                this.amountLimit.next(AmountError.NONE)
                                this.error.next(AzarpayError.NONE);
                                this.valid.next(true)
                            }
                        }

                    } else {
                        if (isValue) {
                            if (this.balance.getValue() < +isValue) {
                                this.amount.error.next('profile.balance.withdraw.input.amount');
                                this.amountLimit.next(AmountError.AMOUNT_WITHDRAWAL)
                                this.valid.next(false)
                            } else {
                                if (+isValue < +min) {
                                    this.amount.error.next('profile.balance.withdraw.input.amount');
                                    this.amountLimit.next(AmountError.AMOUNT_MIN)
                                    this.error.next(AzarpayError.AMOUNT_FEE)
                                    this.valid.next(false)
                                } else if (+isValue > max) {
                                    this.amount.error.next('profile.balance.withdraw.input.amount');
                                    this.amountLimit.next(AmountError.AMOUNT_MAX)
                                    this.valid.next(false)
                                } else {
                                    this.amount.error.next(null);
                                    this.amountLimit.next(AmountError.NONE)
                                    this.error.next(AzarpayError.NONE);
                                    this.valid.next(true)
                                }
                            }
                        }
                    }

            } else {
                if (!this.limitValidator.validate(value) && this.amount.getValue()) {
                    this.amount.error.next(t('profile.balance.deposit.input.amount'));
                    this.valid.next(false);
                } else {
                    this.checkValid();
                    this.amount.error.next(null);
                }
            }
        } else {
            this.amount.error.next(null);
            this.amountLimit.next(AmountError.NONE)
            this.error.next(AzarpayError.NONE);
        }
    }

    onAmountActive(active: boolean) {
        if (active) {
            this.tip.next(AzarpayInputTip.AMOUNT);
        } else {
            this.tip.next(AzarpayInputTip.NONE);
        }
    }


    onName(value: string) {
        this.checkValid();
        if (!OnlyLetterService.validate(value) && this.name.getValue()) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.name.value.next(name);
        } else {
            this.name.error.next(null);
        }
    }

    onSurname(value: string) {
        this.checkValid();
        if (!OnlyLetterService.validate(value) && this.surname.getValue()) {
            const current = value.length;
            const surname = value.slice(0, current - 1);
            this.surname.value.next(surname);
        } else {
            this.surname.error.next(null);
        }
    }

    onCard(value: string) {
        this.checkValid();
        if (value && value.length !== 19) {
            this.errorCard.next(true);
            this.valid.next(false);
        } else {
            this.errorCard.next(false);
        }
    }

    onSelectBank(bank: BankAZN) {
        this.bank.next(bank);
    }

    onDueDate(value: string) {
        const length = value.length;
        const dueDate = this.dueDate.getValue();
        if (length) {
            this.checkValid();
            const month = value.slice(0, 2);

            if (+month[0] === 0 && +month[1] === 0) {
                const current = value.slice(0, length - 1);
                this.dueDate.value.next(current);
                this.dueDate.error.next('profile.balance.withdraw.input.duedate');
            }

            if (+month[0] === 1 && +month[1] > 2) {
                const current = value.slice(0, length - 1);
                this.dueDate.value.next(current);
                this.dueDate.error.next('profile.balance.withdraw.input.duedate');
            }

            if (+month[0] > 1) {
                const current = value.slice(0, length - 1);
                this.dueDate.value.next(current);
                this.dueDate.error.next('profile.balance.withdraw.input.duedate');
            }

            if (dueDate && dueDate.length < 5) {
                this.error.next(AzarpayError.FULL_DUE)
            }
            if (dueDate.length === 5 && !DueDateMonthYearService.checkDifference(value)) {
                this.error.next(AzarpayError.EXPIRED)
            }
            if (dueDate.length === 5 && DueDateMonthYearService.checkDifference(value)) {
                this.error.next(AzarpayError.NONE);
            }
        } else {
            this.dueDate.error.next(null);
        }
    }

    checkValid() {
        if (this.amount.getValue() && this.paymentMethod) {
            if (this.paymentMethod.id === ChannelType.VALERPAY_WITHDRAWAL) {
                const valid = this.amount.isValid();
                this.valid.next(valid);
            } else {
                const valid = this.amount.isValid() && this.name.isValid() && this.surname.isValid() && this.card.isValid() && this.dueDate.isValid();
                this.valid.next(valid);
            }

        }
    }

    onNameActive(active: boolean) {
        if (active) {
            this.tip.next(AzarpayInputTip.NAME);
        } else {
            this.tip.next(AzarpayInputTip.NONE);
        }
    }

    onSurnameActive(active: boolean) {
        if (active) {
            this.tip.next(AzarpayInputTip.SURNAME);
        } else {
            this.tip.next(AzarpayInputTip.NONE);
        }
    }

    onCardActive(active: boolean) {
        if (active) {
            this.tip.next(AzarpayInputTip.CARD);
        } else {
            this.tip.next(AzarpayInputTip.NONE);
        }
    }

    onDueDateActive(active: boolean) {
        if (active) {
            this.tip.next(AzarpayInputTip.DUE_DATE);
        } else {
            this.tip.next(AzarpayInputTip.NONE);
        }
    }

    send() {
        const valid = this.valid.getValue();

        if (valid && this.paymentMethod) {
            const amount = parseInt(this.amount.getValue());
            const card = this.card.getValue().split(' ').join('');
            const request = new AzarpayWithdraw(
                this.paymentMethod,
                this.surname.getValue(),
                this.name.getValue(),
                card,
                this.bank.getValue().id.toString(),
                this.dueDate.getValue(),
                amount
            );
            PaymentService.azarpay.withdraw(request);
            AzarpayWithdrawService.state.next(AzarpayWithdrawState.AWAIT);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const status = AzarpayWithdrawService.state.getValue();
        const fee = WithdrawService.fee.getValue();
        console.log('SUBMIT fee', fee);
        const isBonuses = BonusService.list.getValue().length;
        if (isBonuses) {
            AzarpayWithdrawService.state.next(AzarpayWithdrawState.BONUS_REJECT);
            StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
        } else {
            if (!!fee) {
                if (status === AzarpayWithdrawState.HAS_FEE) {
                    this.send();
                }
            } else {
                this.send();
            }
        }
    }

    reset() {
        this.amount.value.next('');
        this.name.value.next('');
        this.surname.value.next('');
        this.card.value.next('');
        this.dueDate.value.next('');

        this.amount.error.next(null);
        this.name.error.next(null);
        this.surname.error.next(null);
        this.card.error.next(null);
        this.dueDate.error.next(null);

        this.amount.active.next(false);
        this.name.active.next(false);
        this.surname.active.next(false);
        this.card.active.next(false);
        this.dueDate.active.next(false);

        this.valid.next(false);
        this.tip.next(AzarpayInputTip.NONE);
        this.error.next(AzarpayError.NONE);
        this.bank.next(defaultBank);
    }
}

const CardFormService = new CardForm();

export default CardFormService;
