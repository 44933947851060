import classNames from 'classnames';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';

import CardFormService from '../service/CardForm';
import { PaymentMethod } from '../../../PaymentMethod/types';
import InstapayWithdrawService, { InstapayWithdrawState } from '../service/InstapayWithdraw';
import Spinner from '../../../Deposit/Paykassma/Other/Button/image/Spinner.svg';

import './WithdrawalButton.scss';


type WithdrawalButtonType = {
    buttonTitle: string;
    variation?: boolean;
    amount?: string;
    minLimit?: number;
};

function WithdrawalButton(props: WithdrawalButtonType) {
    const { buttonTitle, variation, amount, minLimit } = props;
    const valid = useObservable(CardFormService.valid, CardFormService.valid.getValue());
    const state = useObservable(InstapayWithdrawService.state, InstapayWithdrawService.state.getValue());

    const sumToWithdraw = amount && parseInt(amount) - parseInt(amount) * 0.2;
    const disabledFee =  sumToWithdraw! >= minLimit!;

    const className = classNames('withdrawal-button-instapay', {
        'withdrawal-button-instapay__active': valid || (!variation && disabledFee),
        'withdrawal-button-instapay__variated': variation && !disabledFee,
        });

    return (
        <button type="submit" className={className} disabled={!valid || (variation && !disabledFee) }>
            {state === InstapayWithdrawState.AWAIT ? (
                <img className="withdrawal-button-instapay__wait" src={Spinner} alt="" />
            ) : (
                t(buttonTitle)
            )}
        </button>
    );
}

export default WithdrawalButton;
