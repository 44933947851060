import { AnindaHavaleDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendAnindaHavaleDepositRequest';
import { AnindaHavaleWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendAnindaHavaleWithdrawal';

import AnindaHavaleInterface from '../../interface/payment/AnindaHavale';

interface AnindaHavaleHandler {
    deposit(request: AnindaHavaleDepositRequest): void;
    withdraw(request: AnindaHavaleWithdrawalRequest): void;
}

class AnindaHavaleProvider implements AnindaHavaleInterface {
    handler: AnindaHavaleHandler;

    constructor(handler: AnindaHavaleHandler) {
        this.handler = handler;
    }

    deposit(request: AnindaHavaleDepositRequest): void {
        this.handler.deposit(request);
    }

    withdraw(request: AnindaHavaleWithdrawalRequest): void {
        this.handler.withdraw(request);
    }
}

export type { AnindaHavaleHandler };
export default AnindaHavaleProvider;
