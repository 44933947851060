import GeoDataService from 'service/GeoData';
import { translate as t } from 'service/Language';
import { country } from 'service/model/Country';
import useObservable from 'utils/useObservable';

import Email from './image/email.svg';
import Phone from './image/phone.svg';

import './Contacts.scss';

function Contacts() {
    const geoData = useObservable(GeoDataService.data, GeoDataService.data.getValue());
    const countryId = geoData?.country.id;
    const isShowPhone =
        countryId === country.Austria.id ||
        countryId === country.Belgium.id ||
        countryId === country.Bulgaria.id ||
        countryId === country.GreatBritain.id ||
        countryId === country.Hungary.id ||
        countryId === country.Germany.id ||
        countryId === country.Greece.id ||
        countryId === country.Denmark.id ||
        countryId === country.Ireland.id ||
        countryId === country.Spain.id ||
        countryId === country.Italy.id ||
        countryId === country.Cyprus.id ||
        countryId === country.Latvia.id ||
        countryId === country.Lithuania.id ||
        countryId === country.Luxembourg.id ||
        countryId === country.Malta.id ||
        countryId === country.Netherlands.id ||
        countryId === country.Poland.id ||
        countryId === country.Portugal.id ||
        countryId === country.Romania.id ||
        countryId === country.Slovakia.id ||
        countryId === country.Slovenia.id ||
        countryId === country.Finland.id ||
        countryId === country.France.id ||
        countryId === country.Croatia.id ||
        countryId === country.Czech.id ||
        countryId === country.Sweden.id ||
        countryId === country.Estonia.id ||
        countryId === country.Canada.id ||
        countryId === country.Singapore.id ||
        countryId === country.Japan.id;

    const mail = () => {
        return `mailto:${t('footer.email')}`;
    };
    return (
        <div className="contacts">
            <div className="contacts-wrapper">
                <a className="contacts__email-box" href={mail()}>
                    <img src={Email} alt="email" />
                    <span className="contacts__email">{t('footer.email')}</span>
                </a>
                {isShowPhone && (
                    <div className="contacts__phone-box">
                        <img src={Phone} alt="phone" />
                        <span className="contacts__phone">+357-25-262733</span>
                    </div>
                )}
            </div>
            {isShowPhone && <p className="contacts__support">{t('footer.phone.support')}</p>}
        </div>
    );
}

export default Contacts;
