import { AzarpayDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendAzarpayDepositRequest';
import { AzarpayWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendAzarpayWithdrawal';

import AzarpayInterface from '../../interface/payment/Azarpay';
import {VisaDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendVisaDepositRequest';

interface AzarpayHandler {
    depositBank(request: AzarpayDepositRequest): void;
    depositVisa(request: VisaDepositRequest): void;
    withdraw(request: AzarpayWithdrawalRequest): void;
}

class AzarpayProvider implements AzarpayInterface {
    handler: AzarpayHandler;

    constructor(handler: AzarpayHandler) {
        this.handler = handler;
    }

    depositBank(request: AzarpayDepositRequest): void {
        this.handler.depositBank(request);
    }
    depositVisa(request: VisaDepositRequest): void {
        this.handler.depositVisa(request);
    }

    withdraw(request: AzarpayWithdrawalRequest): void {
        this.handler.withdraw(request);
    }
}

export type { AzarpayHandler };
export default AzarpayProvider;
