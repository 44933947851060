import { useEffect } from 'react';
import { translate as t } from 'service/Language';

import GoogleService from '../../../../../../service/Google';
import OperationsService from '../../../../../../service/Operations';
import useObservable from '../../../../../../utils/useObservable';
import { PaymentMethod } from '../../../PaymentMethod/types';
import FailedPage from '../ResultPage/FailedPage/FailedPage';
import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import PaykassmaDepositService, { DepositState } from '../service/PaykassmaDeposit';
import Amount from './Amount/Amount';

import './Form.scss';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const {paymentMethod} = props;
    const state = useObservable(PaykassmaDepositService.state, PaykassmaDepositService.state.getValue());

    useEffect(() => {
        PaykassmaDepositService.reset()
    }, []);

    useEffect(() => {
        const gaSub = OperationsService.invoice.subscribe({
            next: (data) => {
                const clientId = GoogleService.getClientId();
                const invoiceId = data.id.toString();
                OperationsService.sendGAClientRelation(clientId, invoiceId);
            },
        });
        return () => {
            gaSub.unsubscribe();
            PaykassmaDepositService.reset();
        };
    }, []);

    return (
        <div className="deposit-form">
            {state !== DepositState.SUCCESS && state !== DepositState.FAILED && (
                <div className="deposit-form__header">
                    <img className="deposit-form__img" src={paymentMethod.icon} alt={paymentMethod.title}/>
                    <h2 className="deposit-form__title">{t(paymentMethod.title)}</h2>
                </div>
            )}
            {state === DepositState.AMOUNT && (
                <Amount paymentMethod={paymentMethod} lock={state !== DepositState.AMOUNT}/>
            )}
            {state === DepositState.SUCCESS ? <SuccessPage/> : null}
            {state === DepositState.FAILED ? <FailedPage/> : null}
        </div>
    );
}

export default Form;
