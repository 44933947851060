import { BehaviorSubject } from 'rxjs';


import { CoinspaidDepositRateResponse } from '@ay_tsarbet/newbet-core/dist/types';
import StatusFormPopupService, {StatusForm} from '../../../Deposit/DepositPopup/service/StatusForm';
import CryptoFormService from './CryptoForm';
import PaymentService from '../../../../../../service/Payment';


enum CryptoWithdrawState {
    INIT,
    AWAIT,
    DATA,
    HAS_FEE,
    ACCEPT,
    REJECT,
    BONUS_REJECT,
}

class CoinspaidWithdrawal {
    amount = 0;

    rate = new BehaviorSubject<CoinspaidDepositRateResponse | null>(null);

    link = new BehaviorSubject('');

    state = new BehaviorSubject<CryptoWithdrawState>(CryptoWithdrawState.INIT);

    handler = {
        onWithdraw: this.onWithdraw.bind(this),
        onError: this.onError.bind(this),
    };
    constructor() {
        PaymentService.coinspaid.withdrawResult.subscribe(this.handler.onWithdraw);
        PaymentService.coinspaid.error.subscribe(this.handler.onError);
    }

    setAmount(amount: number) {
        this.amount = amount;
    }

    getAmount(): number {
        return this.amount;
    }

    onWithdraw() {
        this.state.next(CryptoWithdrawState.ACCEPT);
        StatusFormPopupService.status.next(StatusForm.SUCCESS);
    }

    onError() {
        this.state.next(CryptoWithdrawState.REJECT);
        StatusFormPopupService.status.next(StatusForm.FAILED);
    }

    reset() {
        this.state.next(CryptoWithdrawState.INIT);
        StatusFormPopupService.status.next(StatusForm.INIT);
        CryptoFormService.reset();
    }

    onRateReceive(data: CoinspaidDepositRateResponse) {
        console.log('onRateReceive', data)
        this.rate.next(data)
    }

    back() {
        this.reset();
    }
}

const CoinspaidWithdrawService = new CoinspaidWithdrawal();

export { CryptoWithdrawState };
export default CoinspaidWithdrawService;
