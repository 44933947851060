/* eslint-disable max-len */

import { Icon } from 'types';

function VkIcon({ color = '#2787F5', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.2933 0H9.70667C1.86667 0 0 1.86667 0 9.70667V18.2933C0 26.1333 1.86667 28 9.70667 28H18.2933C26.1333 28 28 26.1333 28 18.2933V9.70667C28 1.86667 26.1333 0 18.2933 0ZM22.3125 8.89583C22.93 8.89583 23.0597 9.21372 22.93 9.64632C22.6704 10.8359 20.1829 14.34 20.1829 14.34C19.9666 14.6861 19.8801 14.8591 20.1829 15.2484C20.2896 15.3978 20.5171 15.6207 20.7775 15.8757C21.0452 16.1379 21.3476 16.4342 21.5889 16.7193C22.4659 17.7059 23.1268 18.5382 23.3109 19.1119C23.4792 19.6875 23.1875 19.9792 22.6042 19.9792H20.5625C20.0157 19.9792 19.7403 19.6728 19.1453 19.0109C18.8931 18.7304 18.5834 18.3859 18.1713 17.9738C16.9601 16.8058 16.4409 16.6544 16.1381 16.6544C15.7271 16.6544 15.6042 16.7708 15.6042 17.3542V19.1851C15.6042 19.6875 15.4437 19.9792 14.1459 19.9792C11.9829 19.9792 9.60584 18.666 7.91872 16.2434C5.38803 12.6961 4.69586 10.014 4.69586 9.47324C4.69586 9.17044 4.81253 8.89583 5.39587 8.89583H7.43753C7.95933 8.89583 8.1556 9.12421 8.35129 9.68957C9.34984 12.5983 11.0335 15.1403 11.7256 15.1403C11.9852 15.1403 12.1042 15.0208 12.1042 14.3617V11.3551C12.0573 10.4992 11.7584 10.1291 11.5369 9.85498C11.4002 9.68569 11.293 9.553 11.293 9.36512C11.293 9.13677 11.4881 8.89583 11.8125 8.89583H15.0209C15.4535 8.89583 15.6042 9.1272 15.6042 9.64633V13.6911C15.6042 14.1237 15.792 14.2751 15.9218 14.2751C16.1814 14.2751 16.3977 14.1237 16.8735 13.6478C18.3444 12.004 19.3826 9.47324 19.3826 9.47324C19.5124 9.17044 19.7517 8.89583 20.2709 8.89583H22.3125Z"
                fill={color}
            />
        </svg>
    );
}

export default VkIcon;
