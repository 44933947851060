/* eslint-disable max-len */

import { Icon } from 'types';

function SelectArrow({ color = '#3959D9', width = 6, height = 11 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                d="M0.518411 7C0.0738785 7 -0.163702 7.54103 0.129025 7.88673L2.61061 10.8174C2.81675 11.0609 3.18325 11.0609 3.38939 10.8174L5.87098 7.88673C6.1637 7.54103 5.92612 7 5.48159 7H0.518411Z"
                fill={color}
            />
            <path
                d="M5.48159 4C5.92612 4 6.1637 3.45897 5.87098 3.11327L3.38939 0.182583C3.18325 -0.0608614 2.81675 -0.060861 2.61061 0.182584L0.129024 3.11327C-0.163702 3.45897 0.0738792 4 0.518411 4H5.48159Z"
                fill={color}
            />
        </svg>
    );
}

export default SelectArrow;
