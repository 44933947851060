/* eslint-disable max-len */

import { Icon } from 'types';

function PhotoIcon({ color = '#3F4250', width = 20, height = 20 }: Icon) {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 7.35C10 7.1567 10.1567 7 10.35 7H17.15C17.3433 7 17.5 7.1567 17.5 7.35C17.5 7.5433 17.3433 7.7 17.15 7.7H10.35C10.1567 7.7 10 7.5433 10 7.35Z"
                fill={color}
            />
            <path
                d="M10.35 9.5C10.1567 9.5 10 9.6567 10 9.85C10 10.0433 10.1567 10.2 10.35 10.2H17.15C17.3433 10.2 17.5 10.0433 17.5 9.85C17.5 9.6567 17.3433 9.5 17.15 9.5H10.35Z"
                fill={color}
            />
            <path
                d="M10 12.35C10 12.1567 10.1567 12 10.35 12H17.15C17.3433 12 17.5 12.1567 17.5 12.35C17.5 12.5433 17.3433 12.7 17.15 12.7H10.35C10.1567 12.7 10 12.5433 10 12.35Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.32815 6.73584C6.20813 6.73584 6.92815 7.45721 6.92815 8.34781C6.92815 9.23441 6.20813 9.95979 5.32815 9.95979C4.44414 9.95979 3.72815 9.23441 3.72815 8.34781C3.72815 7.45721 4.44414 6.73584 5.32815 6.73584ZM6.21386 8.34781C6.21386 7.82659 5.79629 7.43584 5.32815 7.43584C4.85713 7.43584 4.44244 7.82543 4.44244 8.34781C4.44244 8.86734 4.85827 9.25979 5.32815 9.25979C5.79515 9.25979 6.21386 8.86618 6.21386 8.34781Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.73086 10.0643C2.52219 10.381 2.40015 10.7601 2.40015 11.1688V12.41C2.40015 12.8533 2.75613 13.216 3.20015 13.216H7.45215C7.89614 13.216 8.25215 12.8533 8.25215 12.41V11.1688C8.25215 10.7613 8.13079 10.3833 7.92324 10.0673C7.78764 9.86079 7.61526 9.68072 7.41589 9.53613C7.30848 9.72693 7.17616 9.90171 7.0234 10.0559C6.58921 10.4941 5.9899 10.7659 5.3281 10.7659C4.66422 10.7659 4.06319 10.4924 3.62866 10.0518C3.47703 9.89807 3.34567 9.72395 3.23899 9.53399C3.0394 9.67824 2.86676 9.85802 2.73086 10.0643ZM7.50577 12.492C7.52415 12.4734 7.53786 12.4455 7.53786 12.41V11.1688C7.53786 10.982 7.4993 10.8052 7.43004 10.6452C6.87907 11.1516 6.14294 11.4659 5.3281 11.4659C4.51189 11.4659 3.77459 11.1506 3.22327 10.6426C3.15337 10.8033 3.11443 10.981 3.11443 11.1688V12.41C3.11443 12.4455 3.12815 12.4734 3.14652 12.492C3.16419 12.5099 3.18224 12.516 3.20015 12.516H7.45215C7.47003 12.516 7.48809 12.5099 7.50577 12.492Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 3C0.895431 3 0 3.89543 0 5V15C0 16.1046 0.895431 17 2 17H18C19.1046 17 20 16.1046 20 15V5C20 3.89543 19.1046 3 18 3H2ZM18 3.7H2C1.28203 3.7 0.7 4.28203 0.7 5V15C0.7 15.718 1.28203 16.3 2 16.3H18C18.718 16.3 19.3 15.718 19.3 15V5C19.3 4.28203 18.718 3.7 18 3.7Z"
                fill={color}
            />
        </svg>
    );
}

export default PhotoIcon;
