import { useEffect } from 'react';

import RouteConfig from '../../appConstants/RouteConfig';
import CategoryHeader from '../../component/CategoryHeader';
import { Providers } from '../../enums/providers';
import AuthService from '../../service/Auth';
import GamesService from '../../service/Games';
import GoogleService from '../../service/Google';
import GameProvider from '../../service/model/GameProvider';
import useObservable from '../../utils/useObservable';
import GameList from '../Casino/GameList/GameList';
import CountryBlocked from '../Casino/Provider/CountryBlocked/CountryBlocked';
import CurrencyBlocked from '../Casino/Provider/CurrencyBlocked/CurrencyBlocked';

function TvGames() {
    const tvGamesProviderList = [Providers.Betgames, Providers.HollywoodTV];

    const games = useObservable(GamesService.games, GamesService.games.getValue());
    const providers = useObservable(GamesService.allowedProviders, GamesService.providers.getValue());
    const routeMap = new Map<string, GameProvider>(providers.map((provider) => [provider.route, provider]));
    const isAuth = useObservable(AuthService.auth, AuthService.auth.getValue());

    const providerList: GameProvider[] = [];
    const blockedProvider = isAuth ? <CurrencyBlocked /> : <CountryBlocked />;

    tvGamesProviderList.forEach((route) => {
        const provider = routeMap.get(route);
        if (provider) {
            providerList.push(provider);
        }
    });

    const filteredGames = games.filter((game) => game.provider && providerList.includes(game.provider));

    return filteredGames.length ? (
        <div>
            <CategoryHeader title="casino.top.tvgames" backUrl={RouteConfig.casino.root} />
            <GameList games={filteredGames} />
        </div>
    ) : (
        blockedProvider
    );
}

export default TvGames;
