import {PaymentMethod} from '../../PaymentMethod/types';
import ConstantposWithdrawService, {ConstantposWithdrawState} from './service/ConstantposWithdraw';
import IMPS from './IMPS/IMPS';
import useObservable from '../../../../../utils/useObservable';
import { useEffect } from 'react';
import Balance from '../Balance/Balance';
import ResultForm from '../Constantpos/ResultForm/ResultForm';


type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const {paymentMethod} = props;
    const state = useObservable(ConstantposWithdrawService.state, ConstantposWithdrawService.state.getValue());

    useEffect(() => {
        ConstantposWithdrawService.reset();
    }, []);

    if (paymentMethod) {
        if (
            state === ConstantposWithdrawState.INIT ||
            state === ConstantposWithdrawState.AWAIT ||
            state === ConstantposWithdrawState.HAS_FEE
        ) {
            return (
                <>
                    <Balance />
                    <IMPS paymentMethod={paymentMethod} status={state} />
                </>
            );
        } else {
            return <ResultForm/>;
        }
    }

    return null;
}

export default Form;
