import { configureWebSockets } from '@ay_tsarbet/newbet-core';
import RegistrationResponseStatus from '@ay_tsarbet/newbet-core/dist/models/registrationResponseStatus';
import {
    AuthResult,
    CashixDepositResponse,
    CoinspaidDepositRateResponse,
    CoinspaidDepositResponse,
    CoinspaidWithdrawResponse,
    EpulDepositResponse,
    EpulWithdrawResponse,
    InstapayDepositResponse,
    InstapayWithdrawResponse,
    MpayDepositResponse,
    Operation,
    PartialDeserializeDataHandlers,
    PaycordDepositResponse,
    PaycosWithdrawResponse,
    PaykassmaDepositResponse,
    ProviderListTopGameList,
    SlotegratorGroup,
    Status,
    WellxpayDepositResponse,
    WellxPayDepositTwoResponse,
} from '@ay_tsarbet/newbet-core/dist/types';
import { READY_STATES } from 'enums/ws';
import WithdrawService from 'page/Profile/Balance/Withdrawal/service/Withdrawal';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { BehaviorSubject } from 'rxjs';

import RouteConfig from '../appConstants/RouteConfig';
import ChangeEmailService from '../page/Profile/Account/PersonalData/ChangeEmail/service/ChangeEmail';
import PersonalDataFormService from '../page/Profile/Account/PersonalData/service/PersonalDataForm';
import RegistrationProvider from '../service/provider/Registration';
import AuthService from './Auth';
import BalanceService from './Balance';
import BanService from './Ban';
import BonusService from './Bonus';
import ChangePasswordService from './ChangePassword';
import FavoriteGameService from './FavoriteGame';
import { GameHistoryService } from './GameHistory';
import GamesService from './Games';
import GeoDataService from './GeoData';
import { HistoryService } from './HistoryService';
import { LC, log } from './Log';
import GameProvider from './model/GameProvider';
import NotificationService from './Notifications';
import OperationsService from './Operations';
import PaymentService from './Payment';
import AuthProvider from './provider/Auth';
import FavoriteGameProvider from './provider/FavoriteGameProvider';
import GameHistory from './provider/GameHistory';
import GeoDataProvider from './provider/GeoData';
import NoticeProvider from './provider/Notice';
import OperationsProvider from './provider/Operations';
import PaymentProvider from './provider/Payment';
import RecoveryProvider from './provider/Recovery';
import UserDataProvider from './provider/UserData';
import RecoveryService from './Recovery';
import RegistrationService from './Registration';
import UserDataService, { UserDataResponseMap } from './UserData';
import { setHostname } from 'utils/setHostname';
import PromoProvider from './provider/PromoProvider';

class WSBet {
    public state = new BehaviorSubject(0);

    public registration: RegistrationProvider;

    public auth: AuthProvider;

    public recovery: RecoveryProvider;

    public userData: UserDataProvider;

    public payment: PaymentProvider;

    public geoData: GeoDataProvider;

    public slots: GameHistory;

    public notice: NoticeProvider;

    public operations: OperationsProvider;

    public websocketInstance: ReconnectingWebSocket;

    public reconnections = 0;

    public storage: FavoriteGameProvider;

    constructor(handlerMap: PartialDeserializeDataHandlers) {
        console.log('SOCKET URL', setHostname());
        const { actions, instance } = configureWebSockets({
            language: 'ru',
            wss: {
                // url: 'wss://wss.gllrwinwjhf.com/',
                url: setHostname(),
                onError: error => {
                    console.log('WebSocket ERROR: ', error);
                    this.state.next(READY_STATES.ERROR);
                },
                onOpen: () => {
                    console.log('CONNECTED...', instance.readyState);
                    this.state.next(instance.readyState);
                    this.clearReconnects();
                },
                onClose: () => {
                    console.log('CLOSE', this.reconnections);
                    this.state.next(READY_STATES.CLOSED);
                    if (this.reconnections < 5) {
                        setTimeout(() => {
                            instance.reconnect();
                            this.incrementReconnects();
                        }, 100);
                    }
                },
            },
            onDeserializeData: handlerMap,
        });

        this.websocketInstance = instance;

        this.registration = new RegistrationProvider(actions.registration);
        this.auth = new AuthProvider(actions.login);
        this.recovery = new RecoveryProvider(actions.registration);
        this.userData = new UserDataProvider(actions.registration);
        this.payment = new PaymentProvider(actions.payment);
        this.geoData = new GeoDataProvider(actions.registration);
        this.operations = new OperationsProvider(actions.operations);
        this.slots = new GameHistory(actions.slots);
        this.notice = new NoticeProvider(actions.notice);

        this.storage = new FavoriteGameProvider(actions.storage);

        BonusService.setActions(actions.operations);
        GamesService.setActions(actions.slots);
    }

    incrementReconnects() {
        this.reconnections += 1;
    }

    reconnectsCount() {
        return this.reconnections;
    }

    clearReconnects() {
        this.reconnections = 0;
    }
}

const recoveryResponseStatus = [
    RegistrationResponseStatus.newPasswordRecoverySuccess,
    RegistrationResponseStatus.resetTokenSentByEmail,
    RegistrationResponseStatus.resetTokenSentByPhone,
    RegistrationResponseStatus.resetTokenAccepted,
    RegistrationResponseStatus.recoveryByPhoneError,
    RegistrationResponseStatus.recoveryByEmailError,
    RegistrationResponseStatus.recoveryCodeExpired,
    RegistrationResponseStatus.recoveryCodeError,
    RegistrationResponseStatus.newPasswordRecoverySuccess,
    RegistrationResponseStatus.newPasswordRecoveryError,
    RegistrationResponseStatus.recoverySaveNewPasswordError,
];

const changeEmailStatus = [
    RegistrationResponseStatus.userDataUpdateSuccess,
    RegistrationResponseStatus.emailAlreadyInUse,
    RegistrationResponseStatus.invalidUpdatePassword,
];

const changePasswordStatus = [RegistrationResponseStatus.passwordChangeSuccess];

const WSBetService = new WSBet({
    step255: step1Data => {
        console.log('STEP 255', step1Data);
        log(LC.step1, '%c%s %O', 'color:#ddff00', 'WSBetService.step1', step1Data);
        const games = GamesService.games.getValue();
        if (!games.length) {
            GamesService.updateData(step1Data.slotegrator);
        }
    },
    step3: authResult => {
        console.log('STEP 3', authResult);
        log(LC.WSBet, 'WSBetService.step3', authResult);
        if (authResult === AuthResult.INVALID) {
            AuthService.onReceive(null);
        }
        if (authResult === AuthResult.BLOCKED) {
            BanService.setBan(true);
        }
        if (typeof authResult === 'object') {
            AuthService.onReceive(authResult);
            UserDataService.onReceive(authResult);
            UserDataService.checkRegistrationTime(authResult.registrationDate * 1000);
            FavoriteGameService.onReceive(authResult.favorite);
        }
        if (authResult === undefined) {
            AuthService.onReceive(null);
        }
        if (authResult.toString() === '-4') {
            window.open('http://glorymczo.com', '_self')
        }
        AuthService.passed.next(true);
    },
    step10: data => {
        console.log('STEP 10', data);
        log(LC.WSBet, 'WSBetService.step10', data);
        PersonalDataFormService.onReceive(data);
        if (recoveryResponseStatus.includes(data.status)) {
            RecoveryService.onReceive(data);
        } else {
            RegistrationService.onReceive(data);
        }
        if (changePasswordStatus.includes(data.status)) {
            ChangePasswordService.onReceive(data);
        }
        if (changeEmailStatus.includes(data.status)) {
            ChangeEmailService.onReceive(data);
        }

        const userDataResponse = UserDataResponseMap.get(data.status);
        if (userDataResponse || userDataResponse === 0) {
            UserDataService.onResponse(userDataResponse);
        }
    },
    step13: data => {
        console.log('STEP 13 ', data);
        log(LC.WSBet, 'WSBetService.step13', data);
        BonusService.onReceive(data.currentOperationsData);
        if (data && data.needRolled) {
            WithdrawService.setFee(data.needRolled);
        }
    },
    step20: (data: Operation[]) => {
        console.log('STEP 20 ', data);
        log(LC.WSBet, 'WSBetService.step20', data);
        const operations = data.filter(operation => operation.providerId !== 'bonus');
        NotificationService.filterNotification(data);
        OperationsService.onReceive(operations);
    },
    step21: (data, needRolled) => {
        console.log('STEP 21', data, needRolled);
        log(LC.WSBet, 'WSBetService.step21', data);

        BonusService.accumulationBonus(data[0]);
        WithdrawService.setFee(needRolled);
        const bonusOperation = data.find(operation => operation.providerId === 'bonus');
        if (bonusOperation && bonusOperation.status === Status.CANCELED) {
            BonusService.onRemoveOperation(bonusOperation.id);
        }
    },

    step24: data => {
        console.log('STEP 24', data);
        log(LC.step24, 'WSBetService.step24', data);
        GameHistoryService.onReceiveGameList(data);
    },

    step25: data => {
        console.log('STEP 25', data);
        log(LC.step25, 'WSBetService.step25', data);
        BalanceService.setBalance(data[1].balance);
        GameHistoryService.onReceiveGame(data[0][0]);
        WithdrawService.setFee(data[2]);
    },
    //on invoice create
    step40: invoiceResponse => {
        console.log('STEP 40', invoiceResponse);
        log(LC.WSBet, 'WSBetService.step40', invoiceResponse);
        PaymentService.paykassma.onInvoiceResponse(invoiceResponse);
        console.log('step40', invoiceResponse);
    },
    //on invoice update
    step41: invoiceData => {
        console.log('STEP 41', invoiceData);
        PaymentService.paykassma.onReceiveCheckInvoice(invoiceData);
        OperationsService.onInvoiceData(invoiceData);
    },
    //on paykassma deposit response
    step42: (data: PaykassmaDepositResponse) => {
        console.log('STEP 42', data);
        PaymentService.paykassma.onDepositResponse(data);
    },

    step45: withdrawResponse => {
        console.log('STEP 45', withdrawResponse);
        log(LC.WSBet, 'WSBetService.step45', withdrawResponse);
        PaymentService.paykassma.onWithdraw(withdrawResponse);
    },
    step46: depositResponse => {
        console.log('STEP 46', depositResponse);
        log(LC.WSBet, 'WSBetService.step46', depositResponse);
        PaymentService.paycos.onReceiveDeposit(depositResponse);
    },
    step47: (data: PaycosWithdrawResponse) => {
        console.log('STEP 47', data);
        log(LC.WSBet, 'WSBetService.step47', data);
        PaymentService.paycos.onWithdraw(data);
    },
    //on invoice WellxPay
    step48: invoiceData => {
        console.log('STEP 48', invoiceData);
        PaymentService.wellxpay.onInvoiceResponse(invoiceData);

    },
    //on WellxPay deposit response
    step49: (data: WellxPayDepositTwoResponse) => {
        console.log('STEP 49', data);
        PaymentService.wellxpay.onDepositTwoResponse(data);
        OperationsService.onInvoiceDataWellx(data);
    },
    step50: (data: CoinspaidDepositRateResponse) => {
        console.log('STEP 50', data);
        log(LC.WSBet, 'WSBetService.step50', data);
        PaymentService.coinspaid.onRateReceive(data);
    },
    step51: (data: CoinspaidWithdrawResponse) => {
        console.log('STEP 51', data);
        log(LC.WSBet, 'WSBetService.step51', data);
        PaymentService.coinspaid.onWithdrawReceive(data);
    },
    step52: (data: CoinspaidDepositResponse) => {
        console.log('STEP 52', data);
        log(LC.WSBet, 'WSBetService.step52', data);
        PaymentService.coinspaid.onDepositReceive(data);
    },
    step54: (data: MpayDepositResponse) => {
        console.log('STEP 54', data);
        log(LC.WSBet, 'WSBetService.step54', data);
        PaymentService.mpay.onDepositReceive(data);
    },
    step56: (data: PaycordDepositResponse) => {
        console.log('STEP 56', data);
        log(LC.WSBet, 'WSBetService.step56', data);
        PaymentService.paycord.onDepositReceive(data);
    },
    step58: (data: WellxpayDepositResponse) => {
        console.log('STEP 58', data);
        PaymentService.wellxpay.onDepositResponse(data);
    },
    step59: withdrawResponse => {
        console.log('STEP 59', withdrawResponse);
        log(LC.WSBet, 'WSBetService.step59', withdrawResponse);
        PaymentService.wellxpay.onWithdrawResponse(withdrawResponse);
    },
    step61: (data: EpulDepositResponse) => {
        console.log('STEP 61', data);
        log(LC.WSBet, 'WSBetService.step61', data);
        PaymentService.epul.onReceiveDeposit(data);
    },
    step62: (data: EpulWithdrawResponse) => {
        console.log('STEP 62', data);
        log(LC.WSBet, 'WSBetService.step61', data);
        PaymentService.epul.onWithdraw(data);
    },
    step69: (data: InstapayDepositResponse) => {
        console.log('STEP 69', data);
        log(LC.WSBet, 'WSBetService.step69', data);
        PaymentService.instapay.onReceiveDeposit(data);
    },
    step70: (data: InstapayWithdrawResponse) => {
        console.log('STEP 70', data);
        log(LC.WSBet, 'WSBetService.step70', data);
        PaymentService.instapay.onWithdraw(data);
    },
    step71: invoiceResponse => {
        console.log('STEP 71', invoiceResponse);
        log(LC.WSBet, 'WSBetService.step71', invoiceResponse);
    },
    step72: invoiceData => {
        console.log('STEP 72', invoiceData);
        log(LC.WSBet, 'WSBetService.step72', invoiceData);
    },
    step73: withdrawResponse => {
        console.log('STEP 73', withdrawResponse);
        log(LC.WSBet, 'WSBetService.step45', withdrawResponse);
    },
    step76: response => {
        console.log('STEP 76', response);
        log(LC.WSBet, 'WSBetService.step76', response);
        PaymentService.payfix.onDepositReceive(response);
    },
    step77: response => {
        console.log('STEP 77', response);
        log(LC.WSBet, 'WSBetService.step45', response);
        PaymentService.payfix.onWithdrawReceive(response);
    },
    step78: response => {
        console.log('STEP 78', response);
        log(LC.WSBet, 'WSBetService.step78', response);
        PaymentService.anindaHavale.onDepositReceive(response);
    },
    step79: response => {
        console.log('STEP 79', response);
        log(LC.WSBet, 'WSBetService.step79', response);
        PaymentService.anindaHavale.onWithdrawReceive(response);
    },
    step82: response => {
        console.log('STEP 82', response);
        log(LC.WSBet, 'WSBetService.step82', response);
        PaymentService.havaleHera.onDepositReceive(response);
    },
    step83: response => {
        console.log('STEP 83', response);
        log(LC.WSBet, 'WSBetService.step83', response);
        PaymentService.havaleHera.onWithdrawReceive(response);
    },
    step84: response => {
        console.log('STEP 84', response);
        log(LC.WSBet, 'WSBetService.step84', response);
        PaymentService.corefy.onDepositReceive(response);
    },
    step85: response => {
        console.log('STEP 85', response);
        log(LC.WSBet, 'WSBetService.step85', response);
        PaymentService.corefy.onWithdrawReceive(response);
    },
    step86: response => {
        console.log('STEP 86', response);
        log(LC.WSBet, 'WSBetService.step86', response);
        PaymentService.azarpay.onDepositReceive(response);
    },
    step87: response => {
        console.log('STEP 87', response);
        log(LC.WSBet, 'WSBetService.step87', response);
        PaymentService.azarpay.onWithdrawReceive(response);
    },
    step92: response => {
        console.log('STEP 92', response);
        log(LC.WSBet, 'WSBetService.step92', response);
        PaymentService.directa24.onDepositReceive(response);
    },
    step93: response => {
        console.log('STEP 93', response);
        log(LC.WSBet, 'WSBetService.step93', response);
        PaymentService.directa24.onWithdrawReceive(response);
    },
    step96: response => {
        console.log('STEP 96', response);
        log(LC.WSBet, 'WSBetService.step96', response);
        PaymentService.payes.onDepositReceive(response);
    },
    step97: response => {
        console.log('STEP 97', response);
        log(LC.WSBet, 'WSBetService.step97', response);
        PaymentService.payes.onWithdrawReceive(response);
    },
    step98: response => {
        console.log('STEP 98', response);
        log(LC.WSBet, 'WSBetService.step98', response);
        PaymentService.constantpos.onDepositReceive(response);
    },
    step99: response => {
        console.log('STEP 99', response);
        log(LC.WSBet, 'WSBetService.step99', response);
        PaymentService.constantpos.onWithdrawReceive(response);
    },
    step102: (data: CashixDepositResponse) => {
        console.log('STEP 102', data);
        PaymentService.cashix.onDepositResponse(data);
    },
    step103: withdrawResponse => {
        console.log('STEP 103', withdrawResponse);
        log(LC.WSBet, 'WSBetService.step103', withdrawResponse);
        PaymentService.cashix.onWithdrawResponse(withdrawResponse);
    },
    step200: data => {
        console.log('STEP 200', data);
        log(LC.WSBet, 'WSBetService.step200', data);
        GamesService.resetLoadingGame();
        if (data.status !== 0) {
            /**
             * Нужно как-то нормально обрабатывать ошибку получения игры
             * Сейчас чтобы иметь обратную связь при тестировании выводим alert
             * */
            alert(data.url);
            return;
        }

        if (window.innerWidth >= 1020) {
            console.log('if', window.innerWidth, typeof window.innerWidth);
            window.open(data.url);
        } else {
            console.log('else', window.innerWidth, typeof window.innerWidth);
            GamesService.setGameLoaded();
            console.log('data.url', data.url)
            GamesService.setGameUrl(data.url);
            setTimeout(() => {
                HistoryService.redirect(RouteConfig.casino.gameplay);
            },100)
        }
    },
    step201: (data: ProviderListTopGameList) => {
        console.log('STEP 201', data);
        log(LC.WSBet, 'WSBetService.step201', data);
        const providerList: GameProvider[] = [];
        data.providerList.forEach(providerRecord => {
            const provider = GamesService.findProvider(providerRecord.id);
            if (provider) {
                providerList.push(provider);
            }
        });
        GeoDataService.checkGeoRedirect(data.geo);
        GamesService.updateAllowFilter(providerList);
        GamesService.setTopGamesByGeo(data);
    },
    step202: (data: SlotegratorGroup[]) => {
        console.log('STEP 202', data);
        log(LC.WSBet, 'WSBetService.step202', data);
        const providerList: GameProvider[] = [];
        if (!!data.length) {
            data.forEach(providerRecord => {
                const provider = GamesService.findProvider(providerRecord.id);
                if (provider) {
                    providerList.push(provider);
                }
            });
            GamesService.updateAllowFilter(providerList);
        }
    },
    step203: data => {
        console.log('STEP 203', data);
        log(LC.WSBet, 'WSBetService.step203', data);
        GeoDataService.onReceive(data);
    },
    onBalanceUpdate: balance => {
        console.log('onBalanceUpdate', balance);
        log(LC.WSBet, 'WSBetService.onBalanceUpdate', balance);
        BalanceService.setBalance(balance);
    },
    onBonusBalanceUpdate: balance => {
        log(LC.WSBet, 'WSBetService.onBonusBalanceUpdate', balance);
        BonusService.setBalance(balance);
    },
});

export default WSBetService;
