/* eslint-disable max-len */

import { Icon } from 'types';

function Money({ color = '#3F4250', width = 20, height = 20 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7409_5318)">
                <path
                    d="M19.6429 3.5H2.5C2.30261 3.5 2.14286 3.65993 2.14286 3.85714V5.48418H0.357143C0.15975 5.48418 0 5.64411 0 5.84132V5.84289V9.01586V12.8255V16C0 16.1972 0.15975 16.3571 0.357143 16.3571H3.53168H3.53864H14.3255H17.5C17.6974 16.3571 17.8571 16.1972 17.8571 16V14.3714H19.6429C19.8403 14.3714 20 14.2115 20 14.0142V3.85714C20 3.65993 19.8403 3.5 19.6429 3.5ZM13.9865 6.20004C14.1549 7.86211 15.4807 9.18718 17.1429 9.35489V12.4864C15.4802 12.6542 14.1542 13.9801 13.9864 15.6429H3.86811C3.70004 13.9804 2.37696 12.654 0.714286 12.4863V9.35489C2.37643 9.18718 3.70218 7.86211 3.87064 6.20004H13.9865ZM17.1429 6.20004V8.63621C15.8749 8.47496 14.8674 7.46775 14.7056 6.20004H17.1429ZM0.714286 8.63618V6.2H2.5H3.15154C2.98971 7.46775 1.98221 8.47496 0.714286 8.63618ZM0.714286 15.6429V13.2051C1.98279 13.3664 2.99071 14.3744 3.15186 15.6429H0.714286ZM17.1429 15.6429H14.7053C14.8664 14.3744 15.8744 13.3664 17.1429 13.2051V14.0142V15.6429ZM19.2857 13.6571H17.8571V12.8255V9.01582V5.84286V5.84129C17.8571 5.64407 17.6974 5.48414 17.5 5.48414H14.3255C14.3227 5.48414 14.3204 5.48564 14.3177 5.48571H3.53946C3.53671 5.48564 3.53446 5.48414 3.53168 5.48414H2.85714V4.21429H19.2857V13.6571Z"
                    fill={color}
                />
                <path
                    d="M8.92854 11.2771C9.5239 11.2771 9.5239 11.5295 9.5239 11.7136C9.5239 11.9503 9.25115 12.1501 8.92854 12.1501H8.21425C8.01686 12.1501 7.85711 12.3101 7.85711 12.5073C7.85711 12.7045 8.01686 12.8644 8.21425 12.8644H8.5714V13.4596C8.5714 13.6568 8.73115 13.8168 8.92854 13.8168C9.12593 13.8168 9.28568 13.6568 9.28568 13.4596V12.8155C9.83365 12.6779 10.2382 12.2387 10.2382 11.7136C10.2382 11.3676 10.1105 10.5629 8.92854 10.5629C8.33318 10.5629 8.33318 10.268 8.33318 10.1264C8.33318 9.88971 8.60593 9.68986 8.92854 9.68986H9.64283C9.84022 9.68986 9.99997 9.52993 9.99997 9.33271C9.99997 9.1355 9.84022 8.97557 9.64283 8.97557H9.28568V8.38021C9.28568 8.183 9.12593 8.02307 8.92854 8.02307C8.73115 8.02307 8.5714 8.183 8.5714 8.38021V9.02454C8.02343 9.16214 7.6189 9.60129 7.6189 10.1264C7.6189 10.6827 7.96279 11.2771 8.92854 11.2771Z"
                    fill={color}
                />
                <path
                    d="M8.92863 14.7704C11.0509 14.7704 12.7777 13.0436 12.7777 10.9212C12.7777 8.79878 11.0509 7.07196 8.92863 7.07196C6.80634 7.07196 5.07959 8.79875 5.07959 10.9212C5.07959 13.0436 6.80634 14.7704 8.92863 14.7704ZM8.92863 7.78625C10.6571 7.78625 12.0634 9.1925 12.0634 10.9212C12.0634 12.6499 10.6571 14.0561 8.92863 14.0561C7.20013 14.0561 5.79388 12.6499 5.79388 10.9212C5.79388 9.19253 7.20013 7.78625 8.92863 7.78625Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_7409_5318">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Money;
