import SvgImage from '../../../../svg';
import { Breakpoint, useBreakpoint } from '../../../../utils/useBreakpoints';

const starIconSizes = {
    [Breakpoint.XS]: 21,
    [Breakpoint.S]: 21,
    [Breakpoint.M]: 28,
    [Breakpoint.L]: 28,
};

type StarProps = {
    favorite: boolean;
};

function Star(props: StarProps) {
    const { favorite } = props;
    const breakpoint = useBreakpoint();

    return (
        <SvgImage
            name={favorite ? 'StarActive' : 'Star'}
            width={starIconSizes[breakpoint]}
            height={starIconSizes[breakpoint]}
        />
    );
}

export default Star;
