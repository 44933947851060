import Input, { InputSize } from 'component/Input/Input';
import RxComponent from 'component/RxComponent/RxComponent';
import { translate as t } from 'service/Language';
import { formatCurrencySign } from 'utils/format';

import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import CardFormService, { EpulInputTip } from '../../service/CardForm';
import EpulWithdrawService, { EpulWithdrawState } from '../../service/EpulWithdraw';
import WithdrawalButton from '../../WithdrawalButton/WithdrawalButton';

import './Card.scss';

type CardProps = {
    paymentMethod: PaymentMethod;
};
type CardState = {
    value: string;
    error: EpulInputTip;
};

class Card extends RxComponent<CardProps, CardState> {
    state = {
        value: '',
        error: EpulInputTip.NONE,
    };

    handler = {
        onError: this.onError.bind(this),
        getValue: this.getValue.bind(this),
    };

    onError(error: EpulInputTip) {
        this.setState({ error });
    }

    getValue(value: string) {
        this.setState({ value });
    }

    componentDidMount() {
        const { paymentMethod } = this.props;

        CardFormService.setPaymentMethod(paymentMethod);
        this.subscribe(CardFormService.error, { next: this.handler.onError });
        this.subscribe(CardFormService.amount.value, { next: this.handler.getValue });
    }

    render() {
        const { paymentMethod } = this.props;
        const { value } = this.state;

        const status = EpulWithdrawService.state.getValue();

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        return (
            <div className="card-withdrawal">
                <div className="card-withdrawal__header">
                    <img src={paymentMethod.icon} alt={paymentMethod.slug} />
                    <h2 className="card-withdrawal__title">{t(paymentMethod.title)}</h2>
                </div>
                <form className="card-withdrawal__form" onSubmit={(e) => CardFormService.onSubmit(e)}>
                    <MaskedInput
                        className="card-withdrawal__account"
                        mask="0000 0000 0000 0000"
                        placeholder="ХХХХ ХХХХ ХХХХ ХХХХ"
                        inputService={CardFormService.card}
                        name="card"
                        size={InputSize.medium}
                    />
                    <div className="card-withdrawal__amount-block">
                        <Input
                            className="card-withdrawal__amount"
                            inputService={CardFormService.amount}
                            name="amount"
                            type="number"
                            hint={hint}
                            size={InputSize.medium}
                        />
                        <span className="card-withdrawal__currency">{paymentMethod.currency.sign}</span>
                    </div>
                    {status === EpulWithdrawState.HAS_FEE && <FeeNotice amount={value} />}
                    {status === EpulWithdrawState.HAS_FEE ? (
                        <>
                            <WithdrawalButton buttonTitle="profile.balance.withdraw.fee.agree" variation={true} />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel" />
                        </>
                    ) : (
                        <WithdrawalButton buttonTitle="profile.balance.withdraw.button" />
                    )}
                </form>
            </div>
        );
    }
}

export default Card;
