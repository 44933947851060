import { CorefyDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendCorefyDepositRequest';
import { CorefyWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendCorefyWithdrawal';
import { CorefyDepositCode, CorefyDepositResponse, CorefyWithdrawResponse } from '@ay_tsarbet/newbet-core/dist/types';
import { Subject } from 'rxjs';

import CorefyDepositService from '../../page/Profile/Balance/Deposit/Corefy/service/CorefyDeposit';
import CorefyInterface from '../interface/payment/Corefy';
import WSBet from '../WSBet';

enum CorefyError {
    UNKNOWN,
    DEPOSIT,
    WITHDRAW,
}

class Corefy implements CorefyInterface {
    error = new Subject<CorefyError>();

    withdrawResult = new Subject<void>();

    deposit(request: CorefyDepositRequest) {
        console.log('deposit', request);
        WSBet.payment.corefy.deposit(request);
    }

    withdraw(withdraw: CorefyWithdrawalRequest) {
        console.log('withdraw', withdraw);
        WSBet.payment.corefy.withdraw(withdraw);
    }

    onWithdrawReceive(response: CorefyWithdrawResponse) {
        console.log('Сorefy  | WITHDRAW RESPONSE', response);
        if (response.errorCode >= CorefyDepositCode.success) {
            this.withdrawResult.next();
        } else {
            this.error.next(CorefyError.WITHDRAW);
        }
    }

    onDepositReceive(data: CorefyDepositResponse) {
        console.log('COREFY | DEPOSIT RESPONSE', data);
        if (!data.code) {
            CorefyDepositService.onReceive(data.message);
        } else {
            CorefyDepositService.onError();
        }
    }
}

export { CorefyError };
export default Corefy;
