import { NavLink } from 'react-router-dom';
import GamesService from 'service/Games';
import { translate as t } from 'service/Language';
import UserDataService from 'service/UserData';
import useObservable from 'utils/useObservable';

import RouteConfig from '../../../appConstants/RouteConfig';
import GoogleService from '../../../service/Google';
import aviator from './Images/aviatorText.svg';
import hot from './Images/hot.svg';
import newIcon from './Images/new_flag_right.svg';

import './NavBar.scss';

type NavbarListType = {
    id: number;
    title: string;
    path: string;
};

function NavBar() {
    const user = useObservable(UserDataService.data, UserDataService.data.getValue());
    const navbarList: Array<NavbarListType> = [
        { id: 0, title: t('header.navbar.casino'), path: '/' },
        { id: 1, title: 'Glory Games', path: RouteConfig.casino.gloryGames },
        { id: 2, title: t('casino.top.sports'), path: RouteConfig.betting.root },
        { id: 3, title: t('header.navbar.casino.live'), path: RouteConfig.casino.live },
        // { id: 3, title: t('header.navbar.jackpot'), path: RouteConfig.jackpot },
        { id: 5, title: t('header.navbar.tournament'), path: RouteConfig.casino.tournament },
    ];

    const handleClick = (id: number) => {
        if (id === 1) {
        }
        if (id === 2) {
            GoogleService.betting();
        }
        if (id === 5) {
            GoogleService.tournament(user?.id);
        }
    };

    return (
        <div className="navbar">
            {navbarList.map((item) => (
                <NavLink
                    key={item.id}
                    to={item.path}
                    className="navbar__link"
                    exact
                    activeClassName="navbar__link__active"
                    onClick={() => handleClick(item.id)}
                >
                    {item.title.toUpperCase()}
                    {item.id === 1 && <img className="navbar__new" src={newIcon} alt=""/>}
                </NavLink>
            ))}
            <button className="navbar__link navbar__aviator" onClick={() => GamesService.loadAviatorGame()}>
                <img src={aviator} alt="Aviator" />
            </button>
        </div>
    );
}

export default NavBar;
