import GeoDataProviderInterface from '../interface/GeoDataProviderInterface';

class GeoDataProvider {
    handler: GeoDataProviderInterface;

    constructor(handler: GeoDataProviderInterface) {
        this.handler = handler;
    }

    request() {
        this.handler.sendCurrencyByGeo();
    }
}

export default GeoDataProvider;
