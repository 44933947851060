import { NavLink } from 'react-router-dom';

import { translate as t } from '../../../service/Language';
import { list } from '../Info';

import './SwitchList.scss';

function SwitchList() {
    return (
        <>
            {list.map((item) => (
                <NavLink className="about-company-switch" to={item.path}>
                    {t(item.title)}
                </NavLink>
            ))}
        </>
    );
}

export default SwitchList;
