import { translate as t } from 'service/Language';
import { unknownCurrency } from 'service/model/Currency';
import UserDataService from 'service/UserData';
import { formatCurrencySign } from 'utils/format';
import useObservable from 'utils/useObservable';
import Attention from './image/attantion.svg';

import './FeeNotice.scss';

type FeeNoticeProps = {
    amount: string;
    fee: number;
};

const FeeNotice = (props: FeeNoticeProps) => {
    const { amount, fee } = props;

    const userData = useObservable(UserDataService.data, UserDataService.data.getValue());
    const currency = userData ? userData.currency : unknownCurrency;
    const sumToWithdraw =  amount ? Math.floor(parseInt(amount) - parseInt(amount) * 0.2): 0;

    return (
        <div className="fee-notice">
            <img className="fee-notice__icon" src={Attention} alt="attention" />

            <div className="fee-notice-message">
                <div className="fee-notice-message__text">
                    {t('popup.payment.attention.fee')} <strong>{formatCurrencySign(fee, 0, currency)}</strong>
                </div>
                <div className="fee-notice-message__text">
                    <strong>
                        {t('profile.balance.withdraw.fee.sum')} {formatCurrencySign(sumToWithdraw, 0, currency)}
                    </strong>
                </div>
            </div>
        </div>
    );
};

export default FeeNotice;
