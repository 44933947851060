import { PaykassmaWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaWithdraw';
import { translate as t } from '../../../../../../../service/Language';
import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import AuthService from 'service/Auth';
import GoogleService from 'service/Google';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import _paymentMethod from '../../../../PaymentMethod/methods';
import WithdrawService from '../../../service/Withdrawal';
import { CheckPersonalNameService } from '../../../../../../../service/Validator/PersonalName';
import { OnlyLetterService } from '../../../../../../../service/Validator/OnlyLetter';
import { BankCodeService } from '../../../../../../../service/Validator/BankCode';
import BonusService from '../../../../../../../service/Bonus';
import PaykassmaWithdrawService, { PaykassmaWithdrawState } from '../../../Paykassma/service/PaykassmaWithdraw';
import StatusFormPopupService, { StatusForm } from '../../../../Deposit/DepositPopup/service/StatusForm';
import { AmountError } from '../../../../Deposit/Directa24/Pix/service/AmountForm';
import BalanceService from '../../../../../../../service/Balance';


export enum ApayTipIMPS {
    NOERROR,
    AMOUNT,
    NAME,
    CODE,
}

class IMPSForm {
    paymentMethod: PaymentMethod = _paymentMethod.APAY_IMPS;

    limitValidator = new Validator.limit(0, 1);

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.withdraw.input.amount',
    });

    accountNumber = new InputService({
        title: 'popup.payment.imps.bill',
        validator: Validator.nonEmpty,
        validatorText: 'popup.payment.easypaisa.wallet.error',
    });

    accountName = new InputService({
        title: 'popup.payment.imps.name',
        validator: Validator.personalName,
        validatorText: 'popup.payment.imps.name',
    });

    bankCode = new InputService({
        title: 'popup.payment.imps.code',
        validator: Validator.backCode,
        validatorText: 'popup.payment.imps.code',
    });

    amountLimit = new BehaviorSubject<AmountError>(AmountError.NONE);

    valid = new BehaviorSubject<boolean>(false);

    tip = new BehaviorSubject<ApayTipIMPS>(ApayTipIMPS.NOERROR);

    balance = new BehaviorSubject(0);

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        onAccountNumber: this.onAccountNumber.bind(this),
        onName: this.onName.bind(this),
        onBankCode: this.onBankCode.bind(this),
        onBankCodeActive: this.onBankCodeActive.bind(this),
        onNameActive: this.onNameActive.bind(this),
        onActive: this.onActive.bind(this),
        onBalance: this.onBalance.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
        this.accountNumber.value.subscribe(this.handler.onAccountNumber);
        this.accountNumber.active.subscribe(this.handler.onActive);
        this.accountName.value.subscribe(this.handler.onName);
        this.accountName.active.subscribe(this.handler.onNameActive);
        this.bankCode.value.subscribe(this.handler.onBankCode);
        this.bankCode.active.subscribe(this.handler.onBankCodeActive);
        BalanceService.balance.subscribe(this.handler.onBalance);
    }

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        const limit = this.paymentMethod.limit.withdraw;
        const isFee = WithdrawService.fee.getValue();
        const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
        this.limitValidator.setLimit(isFee ? isMin : limit.min, limit.max);
    }

    onBalance(balance: number) {
        this.balance.next(balance)
    }

    onAmountActive(isActive: boolean) {
        const tip = isActive ? ApayTipIMPS.AMOUNT : ApayTipIMPS.NOERROR
        this.tip.next(tip)
    }

    onActive() {
        this.tip.next(ApayTipIMPS.NOERROR);
    }

    onAmount(value: string) {
        const isValue = this.amount.value.getValue();
        if (this.paymentMethod && isValue) {
            const fee = WithdrawService.fee.getValue();
            if(fee && +value < this.paymentMethod.limit.withdraw.min * 1.25) {
                this.amountLimit.next(AmountError.AMOUNT_FEE);
                this.amount.error.next('profile.balance.withdraw.input.amount');
            }
            else {
                this.amountLimit.next(AmountError.NONE);
                this.amount.error.next(null);
            }
            if(!fee && +value < this.paymentMethod.limit.withdraw.min) {
                this.amountLimit.next(AmountError.AMOUNT_MIN);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }
            if(+value > this.paymentMethod.limit.withdraw.max) {
                this.amountLimit.next(AmountError.AMOUNT_MAX);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }
            if( this.balance.getValue() < +value) {
                this.amountLimit.next(AmountError.AMOUNT_WITHDRAWAL);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }

        } else {
            this.amount.error.next(null);
        }
        this.checkValid();
    }

    onAccountNumber(value: string) {
        this.checkValid();
    }

    onName(value: string) {
        this.checkValid();
        if (this.accountName.getValue() && !CheckPersonalNameService.validate(value)) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.accountName.value.next(name);
        }
    }
    onNameActive(active: boolean) {
        if (active) {
            this.tip.next(ApayTipIMPS.NAME);
        } else {
            this.tip.next(ApayTipIMPS.NOERROR);
        }
    }
    onBankCode(value: string) {
        if (this.bankCode.getValue()) {
            if (value.length < 5 && !OnlyLetterService.validate(value)) {
                const current = value.length;
                const name = value.slice(0, current - 1);
                this.bankCode.value.next(name);
                this.bankCode.error.next(t('popup.payment.imps.code.error'));
                this.valid.next(false);
            }
            if (value.length === 5 && !BankCodeService.numberCheck(value)) {
                const current = value.length;
                const name = value.slice(0, current - 1);
                this.bankCode.value.next(name);
                this.bankCode.error.next(t('popup.payment.imps.code.error'));
            }
            if (value.length > 5 && !BankCodeService.symbolCheck(value)) {
                const current = value.length;
                const name = value.slice(0, current - 1);
                this.bankCode.value.next(name);
                this.bankCode.error.next(t('popup.payment.imps.code.error'));
            }
            if (value.length > 11) {
                const current = value.length;
                const name = value.slice(0, current - 1);
                this.bankCode.value.next(name);
            } else {
                this.checkValid();
                this.bankCode.error.next(null);
            }
        } else {
            this.checkValid();
            this.bankCode.error.next(null);
        }
    }

    onBankCodeActive(isActive: boolean) {
        const tip = isActive ? ApayTipIMPS.CODE : ApayTipIMPS.NOERROR
        this.tip.next(tip)
    }

    checkValid() {
        const isAmount = this.amount.isValid();
        const isAccountName = this.accountName.getValue() && this.accountName.isValid();
        const isAccountNumber = this.accountNumber.isValid();
        const isBank = this.bankCode.isValid();
        if (isAmount && isAccountNumber && isAccountName && isBank) {
            this.valid.next(true);
        } else this.valid.next(false);
    }

    send() {
        if (this.valid.getValue() && this.paymentMethod) {
            const currencyId = this.paymentMethod.currency.ISO;
            const channelType = this.paymentMethod.id;
            const accountNumber = this.accountNumber.getValue();
            const accountName = this.accountName.getValue().trim();
            const bankCode = this.bankCode.getValue();
            const amount = parseInt(this.amount.getValue());
            const request: PaykassmaWithdrawRequest = {
                currencyId,
                channelType,
                accountNumber,
                accountName,
                bankCode,
                amount,
            };
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            GoogleService.sentWithdraw(
                userId,
                this.paymentMethod.title,
                amount.toString(),
                this.paymentMethod.currency.name,
                request.accountNumber
            );
            console.log('send', request);
            PaymentService.paykassma.withdraw(request);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();
        const isBonuses = BonusService.list.getValue().length;

        if (isBonuses) {
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.BONUS_REJECT);
            StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
        } else {
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.AWAIT);
            this.send();
        }
    }

    reset() {
        this.amount.value.next('');
        this.accountNumber.value.next('');
        this.accountName.value.next('');
        this.bankCode.value.next('');

        this.amount.error.next(null);
        this.accountNumber.error.next(null);
        this.accountName.error.next(null);
        this.bankCode.error.next(null);

        this.amount.active.next(false);
        this.accountNumber.active.next(false);
        this.accountName.active.next(false);
        this.bankCode.active.next(false);

        this.tip.next(ApayTipIMPS.NOERROR);
        this.amountLimit.next(AmountError.NONE);
        this.valid.next(false);
    }
}

const IMPSFormService = new IMPSForm();
export default IMPSFormService;
