/* eslint-disable max-len */

import { Icon } from 'types';

function TimeIcon2({ color = '#3959D9', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                d="M19.0563 8.81274L20.5826 7.04086C20.7308 6.86892 20.7078 6.61242 20.5313 6.46853L19.3004 5.46247C19.1239 5.31816 18.8605 5.34011 18.7127 5.51247L17.292 7.162C16.1179 6.32585 14.7313 5.76286 13.2246 5.56124V4.09911H15.0394C15.2426 4.09911 15.4079 3.93814 15.4079 3.74018V2.35893C15.4079 2.16097 15.2426 2 15.0394 2H8.96021C8.75653 2 8.59166 2.16097 8.59166 2.35893V3.74018C8.59166 3.93814 8.75653 4.09911 8.96021 4.09911H10.775V5.56124C9.26824 5.76246 7.88169 6.32585 6.70802 7.16159L5.28683 5.51247C5.13866 5.34011 4.87571 5.31776 4.69874 5.46247L3.4683 6.46812C3.29133 6.61202 3.26838 6.86851 3.41655 7.04046L4.94291 8.81193C3.73042 10.3058 3 12.1874 3 14.2349C3 19.068 7.03733 23 12 23C16.9627 23 21 19.068 21 14.2349C20.9996 12.1874 20.2692 10.3062 19.0563 8.81274ZM11.9996 21.5773C7.84288 21.5773 4.46042 18.2835 4.46042 14.2349C4.46042 10.1867 7.84288 6.89249 11.9996 6.89249C16.1567 6.89249 19.5387 10.1867 19.5387 14.2349C19.5387 18.2839 16.1567 21.5773 11.9996 21.5773Z"
                fill={color}
            />
            <path
                d="M14.4219 18H9.58301V17.1309L11.4238 15.2803C11.9674 14.7236 12.3271 14.3298 12.5029 14.0986C12.682 13.8643 12.8122 13.6445 12.8936 13.4395C12.9749 13.2344 13.0156 13.0146 13.0156 12.7803C13.0156 12.458 12.918 12.2041 12.7227 12.0186C12.5306 11.833 12.2637 11.7402 11.9219 11.7402C11.6484 11.7402 11.3831 11.7907 11.126 11.8916C10.8721 11.9925 10.5775 12.1748 10.2422 12.4385L9.62207 11.6816C10.0192 11.3464 10.4049 11.1087 10.7793 10.9688C11.1536 10.8288 11.5524 10.7588 11.9756 10.7588C12.6396 10.7588 13.1719 10.9329 13.5723 11.2812C13.9727 11.6263 14.1729 12.0918 14.1729 12.6777C14.1729 13 14.1143 13.306 13.9971 13.5957C13.8831 13.8854 13.7057 14.1849 13.4648 14.4941C13.2272 14.8001 12.8301 15.2152 12.2734 15.7393L11.0332 16.9404V16.9893H14.4219V18Z"
                fill={color}
            />
        </svg>
    );
}

export default TimeIcon2;
