import classNames from 'classnames';
import { Component, createRef } from 'react';
import Collapsible from 'react-collapsible';

import { translate as t } from '../../../service/Language';
import CasinoLinkItem from '../CasinoLinkList/CasinoLinkItem/CasinoLinkItem';
import Arrow from './image/arrow.svg';

import '../../../scss/footerDropDown.scss';

class CasinoLinkMenu extends Component {
    state = {
        active: false,
    };

    handler = {
        toggle: this.toggle.bind(this),
        clickOutside: this.clickOutside.bind(this),
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    close() {
        this.setState({ active: false });
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    open() {
        this.setState({ active: true });
        document.addEventListener('pointerdown', this.handler.clickOutside);
    }

    toggle() {
        const { active } = this.state;

        if (active) {
            this.close();
        } else {
            this.open();
        }
    }

    clickOutside(event: PointerEvent) {
        if (this.ref.self.current && !this.ref.self.current.contains(event.target as Node)) {
            this.close();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    render() {
        const { active } = this.state;

        const classCurrent = classNames({
            'casino-link__current': true,
            'casino-link__current____active': active,
        });

        const arrowClass = classNames({
            'casino-link__arrow': true,
            'casino-link__arrow____up': active,
        });

        return (
            <div className="casino-link" ref={this.ref.self}>
                <button type="button" className={classCurrent} onClick={this.handler.toggle}>
                    {t('footer.casino')}
                    <img className={arrowClass} src={Arrow} alt="arrow" />
                </button>

                <div className="casino-link__list">
                    <Collapsible open={active} transitionTime={200} trigger="">
                        <div className="casino-link__collapsible">
                            <CasinoLinkItem className="casino-link__value" />
                        </div>
                    </Collapsible>
                </div>
            </div>
        );
    }
}

export default CasinoLinkMenu;
