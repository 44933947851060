import { FocusEvent, FocusEventHandler, useState, VFC } from 'react';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';

import Close from './image/close.svg';
import SearchIcon from './image/search.svg';
import SearchActiveIcon from './image/search-active.svg';

import './CountrySearch.scss';
import CountrySupportService from 'service/CountrySupport';

interface SearchProps {
    onFocus?: FocusEventHandler;
    onBlur?: FocusEventHandler;
}

const CountrySearch: VFC<SearchProps> = ({ onFocus, onBlur }) => {
    const [active, setActive] = useState(false);
    const searchText = useObservable(
        CountrySupportService.searchText,
        CountrySupportService.searchText.getValue(),
    );

    const _onFocus = (event: FocusEvent) => {
        if (onFocus) {
            setActive(true);
            onFocus(event);
        }
    };

    const reset = () => {
        CountrySupportService.setSearchText('');
    };

    return (
        <div className='search'>
            <input
                className='search__input'
                placeholder={t('placeholder.search')}
                onFocus={_onFocus}
                value={searchText}
                onChange={e => {
                    CountrySupportService.setSearchText(e.target.value);
                }}
            />
            <span className='search__icon'>
                <img src={active ? SearchActiveIcon : SearchIcon} alt='search' />
            </span>
            {searchText && (
                <button className='search__clear' onClick={() => reset()}>
                    <img className='search__img' src={Close} alt='close' />
                    <span className='search__cancel'>{t('search.button.cancel')}</span>
                </button>
            )}
        </div>
    );
};

export default CountrySearch;
