import { NewsLettersStatus } from '@ay_tsarbet/newbet-core/dist/connection/actions/actionEnums';
import { PasswordData } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendChangePassword';
import { RegistrationFullData } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendRegistrationFull';

import UserDataInterface from '../interface/UserData';
import UserData from '../model/UserData';

interface UserDataHandler {
    full: (data: RegistrationFullData) => void;
    sendChangePassword: (data: PasswordData) => void;
}

class UserDataProvider implements UserDataInterface {
    handler: UserDataHandler;

    constructor(handler: UserDataHandler) {
        this.handler = handler;
    }

    update(userData: UserData) {
        this.fullUpdate(userData);

        return true;
    }

    fullUpdate(userData: UserData) {
        const data: RegistrationFullData = {
            type: 'change',
            email: userData.email,
            phone: userData.phone ? `+${userData.phone}` : '',
            login: '',
            password: '',
            currencyCode: userData.currency.ISO,
            otp: '',
            domain: '',
            btag: '',

            person: {
                firstName: userData.firstName,
                patronymic: '',
                surname: userData.secondName,
                country: userData.country,
                city: {
                    name: '',
                    id: 0,
                },
                region: {
                    name: '',
                    id: 0,
                },
                postalCode: '',
                address: '',
                newsletters: NewsLettersStatus.EMAIL_AND_SMS_DISAGREE,
                timeZone: 0,
                promoCode: '',
                pinCode: '',
                birth: userData.birthDate ?? new Date(0),
            },
        };

        this.handler.full(data);

        return true;
    }

    sendChangePassword(data: PasswordData) {
        this.handler.sendChangePassword(data);
    }
}

export default UserDataProvider;
