import { useEffect } from 'react';
import { translate as t } from 'service/Language';
import { HistoryService } from '../../../../../../../service/HistoryService';
import PopupService from '../../../../../../../service/Popup';
import DepositService from '../../../service/Deposit';
import PaykassmaDepositService from '../../../Paykassma/service/PaykassmaDeposit';

import Mobile from './images/mobile.svg';

import './AttentionPage.scss';


function AttentionPage() {
    useEffect(() => {
        return () => {
            DepositService.back();
            PaykassmaDepositService.reset();
            PopupService.close();
            HistoryService.clearHash();
        };
    }, []);

    return (
        <div className="apay-attention">
            <div className="apay-attention-container">
                <img className="apay-attention-container__img" src={Mobile} alt="Success"/>
                <div className="apay-attention-container__1">
                    {t('popup.deposit.attention.1')}
                </div>
                <b className="apay-attention-container__2">
                    {t('popup.deposit.attention.2')}
                </b>
                <div className="apay-attention-container__block">
                    <span >
                    {t('popup.deposit.attention.3')}
                </span>&nbsp;
                    <em>
                    {t('popup.deposit.attention.4')}
                </em>&nbsp;
                    <span >
                    {t('popup.deposit.attention.5')}
                </span>
                </div>

            </div>
        </div>
    );
}

export default AttentionPage;
