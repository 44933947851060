import { useEffect } from 'react';
import AuthService from 'service/Auth';
import OperationsService from 'service/Operations';
import useObservable from 'utils/useObservable';

import BalanceService from '../../../../service/Balance';
import GoogleService from '../../../../service/Google';
import { translate as t } from '../../../../service/Language';
import UserDataService from '../../../../service/UserData';
import Stat from '../../Stat/Stat';
import HistoryItem from './HistoryItem/HistoryItem';
import ListHeader from './ListHeader/ListHeader';
import ListItem from './ListItem/ListItem';
import ListTab from './ListTab/ListTab';
import TransactionHistoryService from './service/TransactionHistoryService';
import Tab from './Tab/Tab';

import './History.scss';

function History() {
    const isAuth = useObservable(AuthService.auth, AuthService.auth.getValue());
    const historyTransactions = useObservable(
        OperationsService.list,
        OperationsService.list.getValue(),
    );
    const selectedType = useObservable(
        TransactionHistoryService.selectedType,
        TransactionHistoryService.selectedType.getValue(),
    );
    const currentUserData = useObservable(UserDataService.data, UserDataService.data.getValue());
    const currentBalance = useObservable(BalanceService.balance, BalanceService.balance.getValue());

    useEffect(() => {
        if (isAuth) {
            OperationsService.list.getValue();
            if (currentUserData) {
                GoogleService.openBalance(currentUserData.id.toString(), currentBalance.toString());
            }
        }
    }, [currentBalance, currentUserData, isAuth]);

    return (
        <div className='history'>
            <div className='history__header'>{t('profile.balance.history.title')}</div>
            <div className='history__content'>
                <div className='history__list'>
                    <ListTab type={selectedType} />
                    <ListHeader />
                    {historyTransactions
                        .sort((a, b) => b.created.getTime() - a.created.getTime())
                        .filter(item => item.type === selectedType)
                        .map(item => (
                            <ListItem key={item.id} history={item} />
                        ))}
                </div>
                <div className='history__list mobile'>
                    <Tab type={selectedType} />
                    {historyTransactions
                        .filter(item => item.type === selectedType)
                        .map(item => (
                            <HistoryItem key={item.id} history={item} />
                        ))}
                </div>
                <div className='history__stat'>
                    <Stat />
                </div>
            </div>
        </div>
    );
}

export default History;
