import Validator from '../interface/Validator';

class PhoneHavaleVIP implements Validator {
    validate(value: string): boolean {
        return value.length === 10;
    }
}
const PhoneHavaleVIPService = new PhoneHavaleVIP();
export { PhoneHavaleVIPService };
export default PhoneHavaleVIP;
