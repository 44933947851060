import { translate as t } from 'service/Language';

import Input from '../../../../../../../component/Input/Input';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { formatCurrencySign } from '../../../../../../../utils/format';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import PaykassmaWithdrawService, { PaykassmaWithdrawState } from '../../../Paykassma/service/PaykassmaWithdraw';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import WithdrawalButtonApayBDT from './WithdrawalButtonApayBDT/WithdrawalButtonApayBDT';
import WithdrawService from '../../../service/Withdrawal';
import Attention from '../../../../../../../assets/svg/attention.svg';
import ApayBDTFormService, { ApayBDTFormError } from '../service/ApayBDTForm';
import { AmountError } from '../../../../Deposit/Directa24/Pix/service/AmountForm';

import './ApayBDT.scss';

type ApayUpayProps = {
    paymentMethod: PaymentMethod;
    status: PaykassmaWithdrawState;
};
type ApayUpayState = {
    value: string;
    tip: ApayBDTFormError;
    errorAccount: string | null;
    errorAmount: string | null,
    amountLimit: AmountError,
};

class ApayBDT extends RxComponent<ApayUpayProps, ApayUpayState> {
    state = {
        value: '',
        tip: ApayBDTFormError.NOERROR,
        errorAccount: null,
        errorAmount: null,
        amountLimit: AmountError.NONE,
    };

    handler = {
        getValue: this.getValue.bind(this),
        onTip: this.onTip.bind(this),
        onErrorAccount: this.onErrorAccount.bind(this),
        onErrorAmount: this.onErrorAmount.bind(this),
        onAmountLimit: this.onAmountLimit.bind(this),
    };

    onErrorAmount(errorAmount: string | null) {
        this.setState({errorAmount});
    }

    onErrorAccount(errorAccount: string | null) {
        this.setState({errorAccount});
    }

    onAmountLimit(amountLimit: AmountError) {
        this.setState({amountLimit});
    }

    onTip(tip: ApayBDTFormError) {
        this.setState({tip});
    }

    getValue(value: string) {
        this.setState({value});
    }

    componentDidMount() {
        const {paymentMethod} = this.props;

        ApayBDTFormService.setPaymentMethod(paymentMethod);
        this.subscribe(ApayBDTFormService.amount.error, {next: this.handler.onErrorAmount});
        this.subscribe(ApayBDTFormService.accountNumber.error, {next: this.handler.onErrorAccount});
        this.subscribe(ApayBDTFormService.tip, {next: this.handler.onTip});
        this.subscribe(ApayBDTFormService.amount.value, {next: this.handler.getValue});
        this.subscribe(ApayBDTFormService.amountLimit, {next: this.handler.onAmountLimit});
    }

    render() {
        const {paymentMethod, status} = this.props;

        const {value, tip, errorAmount, errorAccount, amountLimit} = this.state;
        const min = formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency);
        const max = formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency);

        const hint = `${t('popup.payment.amount.min')} ${min} — ${t('popup.payment.amount.max')} ${max}`;

        const fee = WithdrawService.fee.getValue();

        const isInit = status === PaykassmaWithdrawState.INIT;
        if (fee && isInit) {
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.HAS_FEE)
        };
        const amountFee = formatCurrencySign(paymentMethod.limit.withdraw.min * 1.25, 0, paymentMethod.currency);
        const allFeeText = `${amountFee} ${t('popup.payment.min.fee')}`;

        return (
            <div className="apayBDT-withdrawal">
                <div className="apayBDT-withdrawal-header">
                    <img
                        className="apayBDT-withdrawal-header__img"
                        src={paymentMethod.icon}
                        alt={paymentMethod.slug}
                    />
                    <h2 className="apayBDT-withdrawal-header__title">{t(paymentMethod.title)}</h2>
                </div>
                <form className="apayBDT-withdrawal-form" onSubmit={(e) => ApayBDTFormService.onSubmit(e)}>
                    <div className="apayBDT-withdrawal-form-block">
                        <Input
                            className="apayBDT-withdrawal-form-block__amount"
                            inputService={ApayBDTFormService.amount}
                            name="amount"
                            type="number"
                        />
                        <span className="apayBDT-withdrawal-form-block__currency">{paymentMethod.currency.sign}</span>
                        {!!errorAmount ? (
                            <div className="apayBDT-withdrawal-form-block__error">
                                <img src={Attention} alt="attention"/>
                                <span className="apayBDT-withdrawal-form-block__error_text">
                                    {amountLimit && amountLimit === AmountError.AMOUNT_MIN ? t('popup.payment.amount.min') + ` ${min}` : null}
                                    {fee && amountLimit === AmountError.AMOUNT_FEE ? allFeeText : null}
                                    {amountLimit && amountLimit === AmountError.AMOUNT_MAX ? t('popup.payment.amount.max') + ` ${max}` : null}
                                    {amountLimit && amountLimit === AmountError.AMOUNT_WITHDRAWAL ? t('popup.payment.amount.withdrawal.max') : null}
                                    </span>
                            </div>
                        ) : null}
                        {tip === ApayBDTFormError.AMOUNT && !errorAmount ? (
                            <span className="apayBDT-withdrawal-form-block__tip">
                                {hint}
                            </span>
                        ) : null}
                    </div>

                    <div className="apayBDT-withdrawal-form-block">
                        <MaskedInput
                            className="apayBDT-withdrawal-form-block__wallet"
                            mask="00000000000"
                            inputService={ApayBDTFormService.accountNumber}
                            name="accountNumber"
                        />
                        {tip === ApayBDTFormError.ACCOUNT && !errorAccount && (
                            <span className="apayBDT-withdrawal-form-block__tip">
                                {t('popup.payment.easypaisa.account.error')}
                            </span>
                        )}
                        {!!errorAccount && (
                            <div className="apayBDT-withdrawal-form-block__error">
                                <img src={Attention} alt="attention"/>
                                <span className="apayBDT-withdrawal-form-block__error_text">
                        {t('popup.payment.error.11')}
                                </span>
                            </div>
                        )}
                    </div>
                    {(status === PaykassmaWithdrawState.HAS_FEE || status === PaykassmaWithdrawState.AWAIT) && fee ?
                        <FeeNotice amount={value} fee={fee}/> : null}
                    {status === PaykassmaWithdrawState.HAS_FEE || status === PaykassmaWithdrawState.AWAIT ? (
                        <>
                            <WithdrawalButtonApayBDT
                                buttonTitle="profile.balance.withdraw.fee.agree"
                                variation={true}
                                paymentMethod={paymentMethod}
                                amount={value}
                            />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel"/>
                        </>
                    ) : (
                        <WithdrawalButtonApayBDT buttonTitle="profile.balance.withdraw.button"/>
                    )}
                </form>
            </div>
        );
    }
}

export default ApayBDT;
