import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';

import AuthService from '../../../../../../service/Auth';
import GoogleService from '../../../../../../service/Google';
import { OnlyLetterService } from '../../../../../../service/Validator/OnlyLetter';
import Validator from '../../../../../../service/Validator/Validator';
import { PaymentMethod } from '../../../PaymentMethod/types';
import DepositService from './AnindaHavaleDeposit';
import { CheckTurkeyLettersService } from '../../../../../../service/Validator/CheckTurkeyLetters';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';

export enum AnindaHavaleInputTip {
    NONE,
    NAME,
    SURNAME,
    BANK,
    IBAN,
    NON_EMPTY,
}

export enum AnindaHavaleErrorTip {
    NONE,
    NAME,
    SURNAME,
}

class AmountForm {
    paymentMethod: PaymentMethod | null = null;

    handler = {
        onAmount: this.checkValid.bind(this),
        onName: this.onName.bind(this),
        onNameActive: this.onNameActive.bind(this),
        onSurname: this.onSurname.bind(this),
        onSurnameActive: this.onSurnameActive.bind(this),
        valid: this.checkValid.bind(this),
    };

    constructor() {
        this.name.value.subscribe(this.handler.onName);
        this.name.active.subscribe(this.handler.onNameActive);
        this.surname.value.subscribe(this.handler.onSurname);
        this.surname.active.subscribe(this.handler.onSurnameActive);
    }

    amount = new InputService({
        title: 'profile.balance.deposit.input.amount',
    });

    name = new InputService({
        title: 'popup.payment.pix.name',
        validator: Validator.OnlyLetter,
        validatorText: 'popup.payment.pix.name',
    });

    surname = new InputService({
        title: 'popup.payment.pix.surname',
        validator: Validator.OnlyLetter,
        validatorText: 'popup.payment.pix.surname',
    });

    valid = new BehaviorSubject<boolean>(false);

    errorType = new BehaviorSubject<AnindaHavaleInputTip>(AnindaHavaleInputTip.NONE);

    errorLetters = new BehaviorSubject<AnindaHavaleErrorTip>(AnindaHavaleErrorTip.NONE)

    setMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
    }

    setAmount(value: string) {
        this.amount.onFocus();
        this.amount.value.next(value);
    }

    onName(value: string) {
        if (this.name.getValue() &&
            !OnlyLetterService.validate(value)
        ) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.name.value.next(name);
            if(CheckTurkeyLettersService.validate(value)) {
                this.errorLetters.next(AnindaHavaleErrorTip.NAME)
            } else{
                this.errorLetters.next(AnindaHavaleErrorTip.NONE)
            }

        } else {
            this.name.error.next(null);
            this.errorLetters.next(AnindaHavaleErrorTip.NONE)
            this.checkValid();
        }
    }

    onSurname(value: string) {
        if (this.surname.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const surname = value.slice(0, current - 1);
            this.surname.value.next(surname);
            if(CheckTurkeyLettersService.validate(value)) {
                this.errorLetters.next(AnindaHavaleErrorTip.SURNAME)
            } else{
                this.errorLetters.next(AnindaHavaleErrorTip.NONE)
            }
        } else {
            this.surname.error.next(null);
            this.errorLetters.next(AnindaHavaleErrorTip.NONE)
            this.checkValid();
        }
    }

    onNameActive(active: boolean) {
        active ? this.errorType.next(AnindaHavaleInputTip.NAME)
            : this.errorType.next(AnindaHavaleInputTip.NONE)
    }

    onSurnameActive(active: boolean) {
        active ? this.errorType.next(AnindaHavaleInputTip.SURNAME)
            : this.errorType.next(AnindaHavaleInputTip.NONE)
    }

    toggleTermsAndCondition() {
        this.validationAnindaHavale()
    }

    checkValid() {
        if (this.paymentMethod) {
            if (this.paymentMethod.id === ChannelType.BANK_TRANSFER_TRY) {
                this.validationAnindaHavale();
            }
        } else {
            this.valid.next(false);
        }
    }

    validationAnindaHavale() {
        const valid = this.name.isValid() && this.surname.isValid();
        if (valid) {
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    reset() {
        this.amount.value.next('');
        this.name.value.next('');
        this.surname.value.next('');

        this.amount.error.next(null);
        this.name.error.next(null);
        this.surname.error.next(null);

        this.amount.active.next(false);
        this.name.active.next(false);
        this.surname.active.next(false);

        this.valid.next(false);
        this.errorType.next(AnindaHavaleInputTip.NONE);
        this.errorLetters.next(AnindaHavaleErrorTip.NONE);
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const assert = [this.amount.isValid(), this.name.isValid(), this.surname.isValid()];
        const valid = !assert.includes(false);

        if (valid && this.paymentMethod) {
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';

            const name = this.name.getValue();
            const surname = this.surname.getValue();

            GoogleService.sentDepositAmount(
                userId,
                this.paymentMethod.title,
                '',
                this.paymentMethod.currency.name
            );
            DepositService.request(this.paymentMethod, name, surname, 0);

        } else {
            const formFieldList = [this.name, this.surname];
            formFieldList.filter((field) => !field.isValid()).forEach((item) => item.error.next(item.validatorText));
        }
    }
}

const AmountFormService = new AmountForm();

export default AmountFormService;
