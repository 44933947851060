import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import { translate as t } from 'service/Language';

import EmailInputService from '../../../../../../../component/LogIn/service/EmailInput';
import AuthService from '../../../../../../../service/Auth';
import GoogleService from '../../../../../../../service/Google';
import { AccountEasypaisaService } from '../../../../../../../service/Validator/AccountEasypaisa';
import Validator from '../../../../../../../service/Validator/Validator';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import PaykassmaDepositService from '../../../Paykassma/service/PaykassmaDeposit';

export enum EasypaisaInputTip {
    NONE,
    EMAIL,
    ACCOUNT,
}

class AmountForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(1, 1000000);

    amount = new InputService({
        title: 'profile.balance.deposit.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.deposit.input.amount.invalid',
    });

    email = new InputService({
        title: 'popup.payment.easypaisa.email',
        validator: Validator.email,
        validatorText: 'recovery.error.email',
    });

    account = new InputService({
        title: 'popup.payment.easypaisa.wallet',
        validator: Validator.accountEasypaisa,
        validatorText: 'popup.payment.easypaisa.account.error',
    });

    errorTip = new BehaviorSubject<EasypaisaInputTip>(EasypaisaInputTip.NONE);

    valid = new BehaviorSubject<boolean>(false);

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        onAccount: this.onAccount.bind(this),
        onAccountActive: this.onAccountActive.bind(this),
        onEmail: this.onEmail.bind(this),
        onEmailActive: this.onEmailActive.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.email.value.subscribe(this.handler.onEmail);
        this.email.active.subscribe(this.handler.onEmailActive);
        this.account.value.subscribe(this.handler.onAccount);
        this.account.active.subscribe(this.handler.onAccountActive);
    }

    setLimit(min: number, max: number) {
        if (this.limitValidator) {
            this.limitValidator.setLimit(min, max);
        }
    }

    onAmount(value: string) {
        if (!this.limitValidator.validate(value) && this.amount.getValue()) {
            this.amount.error.next(t('profile.balance.withdraw.input.error'));
            this.valid.next(false);
        } else {
            this.checkValid();
            this.amount.error.next(null);
        }
    }

    onAccount(value: string) {
        if (this.account.getValue() && !AccountEasypaisaService.validate(value)) {
            this.account.error.next(t('popup.payment.easypaisa.account.error'));
            this.valid.next(false);
        } else {
            this.checkValid();
            this.account.error.next(null);
        }
    }

    onEmail(value: string) {
        if (value && !EmailInputService.validate(value)) {
            this.email.error.next(t('recovery.error.email'));
            this.valid.next(false);
        } else {
            this.checkValid();
            this.email.error.next(null);
        }
    }

    onAmountActive(active: boolean) {
        this.errorTip.next(EasypaisaInputTip.NONE);
    }

    onAccountActive(active: boolean) {
        if (active) {
            this.errorTip.next(EasypaisaInputTip.ACCOUNT);
        } else {
            this.errorTip.next(EasypaisaInputTip.NONE);
        }
    }

    onEmailActive(active: boolean) {
        if (active) {
            this.errorTip.next(EasypaisaInputTip.EMAIL);
        } else {
            this.errorTip.next(EasypaisaInputTip.NONE);
        }
    }

    checkValid() {
        if (this.paymentMethod) {
            const isAmount = this.amount.isValid();
            const isEmail = this.email.isValid();
            const isWallet = this.account.isValid();

            if (isAmount && isEmail && isWallet) {
                this.valid.next(true);
            } else this.valid.next(false);
        }
    }

    setValue(value: number) {
        this.amount.onFocus();
        this.amount.value.next(value.toString());
    }

    setMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        this.setLimit(paymentMethod.limit.deposit.min, paymentMethod.limit.deposit.max);
    }

    reset() {
        this.amount.value.next('');
        this.email.value.next('');
        this.account.value.next('');
        this.amount.error.next(null);
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const valid = this.amount.isValid();

        if (valid && this.paymentMethod) {
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            const amount = parseFloat(this.amount.getValue());

            GoogleService.sentDepositAmount(
                userId,
                this.paymentMethod.title,
                amount.toString(),
                this.paymentMethod.currency.name
            );

            PaykassmaDepositService.setAmount(amount);

            PaykassmaDepositService.depositPaykassmaEasypaisaRequest(
                this.paymentMethod,
                this.account.getValue(),
                this.email.getValue(),
                amount
            );
            PaykassmaDepositService.showWait();
        }
    }
}

const AmountFormService = new AmountForm();

export default AmountFormService;
