import { useEffect } from 'react';
import { translate as t } from 'service/Language';
import PaymentService from 'service/Payment';

import GoogleService from '../../../../../../service/Google';
import OperationsService from '../../../../../../service/Operations';
import { InvoiceStatusCheck } from '../../../../../../service/payment/Paykassma';
import useObservable from '../../../../../../utils/useObservable';
import { PaymentMethod } from '../../../PaymentMethod/types';
import FailedTransactionPage from '../ResultPage/FailedPage/FailedTransactionPage';
import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import PaykassmaDepositService, { DepositState, InvoiceState } from '../service/PaykassmaDeposit';
import Account from './Account/Account';
import Amount from './Amount/Amount';
import Transaction from './Transaction/Transaction';

import './Form.scss';


type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(PaykassmaDepositService.state, PaykassmaDepositService.state.getValue());
    const transactionState = useObservable(
        PaykassmaDepositService.transaction,
        PaykassmaDepositService.transaction.getValue()
    );

    useEffect(()=>{
        PaykassmaDepositService.reset()
    },[]);

    useEffect(() => {
        const gaSub = OperationsService.invoice.subscribe({
            next: (data) => {
                const clientId = GoogleService.getClientId();
                const invoiceId = data.id.toString();
                OperationsService.sendGAClientRelation(clientId, invoiceId);
            },
        });
        const checkToStateSubscription = PaymentService.paykassma.invoiceCheckCode.subscribe({
            next: (check: InvoiceStatusCheck) => {
                if (PaykassmaDepositService.state.getValue()) {
                    PaykassmaDepositService.state.next(
                        check === InvoiceStatusCheck.SUCCESS ? DepositState.SUCCESS : DepositState.FAILED
                    );
                }
                if (PaykassmaDepositService.transaction.getValue()) {
                    PaykassmaDepositService.transaction.next(
                        check === InvoiceStatusCheck.SUCCESS ? InvoiceState.SUCCESS : InvoiceState.FAILED
                    );
                }
            },
        });

        return () => {
            if (checkToStateSubscription) {
                checkToStateSubscription.unsubscribe();
            }
            gaSub.unsubscribe();
        };
    }, []);

    return (
        <div className="deposit-form">
            {state !== DepositState.SUCCESS &&
                transactionState !== InvoiceState.SUCCESS &&
                state !== DepositState.FAILED &&
                transactionState !== InvoiceState.FAILED && (
                    <div className="deposit-form__header">
                        <img className="deposit-form__img" src={paymentMethod.icon} alt={paymentMethod.title} />
                        <h2 className="deposit-form__title">{t(paymentMethod.title)}</h2>
                    </div>
                )}
            {!!transactionState ||
                (state !== DepositState.FAILED && state !== DepositState.SUCCESS && (
                    <Amount paymentMethod={paymentMethod} lock={state !== DepositState.AMOUNT} />
                ))}

            {state === DepositState.INVOICE || state === DepositState.WAIT ? (
                <Account paymentMethod={paymentMethod} invoice={PaykassmaDepositService.invoice} />
            ) : null}

            {transactionState === InvoiceState.TRANSACTION || transactionState === InvoiceState.WAIT ? (
                <Transaction paymentMethod={paymentMethod} state={state} transactionState={transactionState} showHint />
            ) : null}

            {state === DepositState.SUCCESS || transactionState === InvoiceState.SUCCESS ? <SuccessPage /> : null}
            {state === DepositState.FAILED || transactionState === InvoiceState.FAILED ? (
                <FailedTransactionPage />
            ) : null}
        </div>
    );
}

export default Form;
