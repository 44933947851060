import { useEffect } from 'react';

import Logo from '../../../assets/svg/LogoGlory.svg';
import LoadingService from '../../../service/Loading';
import useObservable from '../../../utils/useObservable';

import './Preloader.scss';

function Preloader() {
    const progress = useObservable(LoadingService.progress, LoadingService.progress.getValue());
    const ready = useObservable(LoadingService.fullReady, false);

    useEffect(() => {
        LoadingService.start();
    }, []);

    return ready ? null : (
        <div className="preloader-container">
            <div className="preloader-container__loader">
                <img className="preloader-container__logo" src={Logo} alt="" />
                <div className="preloader-container__bar">
                    <div style={{ width: `${progress * 100}%` }} className="preloader-container__progress" />
                </div>
            </div>
        </div>
    );
}

export default Preloader;
