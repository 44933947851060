import { translate as t } from 'service/Language';

import Input from '../../../../../../../component/Input/Input';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { formatCurrencySign } from '../../../../../../../utils/format';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import PaykassmaWithdrawService, { PaykassmaWithdrawState } from '../../service/PaykassmaWithdraw';
import PhoneFormService, { PhoneCardError } from '../../service/PhoneForm';
import WithdrawService from '../../../service/Withdrawal';
import WithdrawalButton from '../../WithdrawalButton/WithdrawalButton';
import WithdrawalButtonMpesa from '../../WithdrawalButtonMpesa/WithdrawalButtonMpesa';
import AuthService from '../../../../../../../service/Auth';
import Attention from '../../../../../../../assets/svg/attention.svg';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';

import './Phone.scss';

type PhoneProps = {
    paymentMethod: PaymentMethod;
    status: PaykassmaWithdrawState;
};
type PhoneState = {
    value: string;
    tip: PhoneCardError;
    error: string;
};

class Phone extends RxComponent<PhoneProps, PhoneState> {
    state = {
        value: '',
        tip: PhoneCardError.NOERROR,
        error: '',
    };

    handler = {
        onError: this.onError.bind(this),
        getValue: this.getValue.bind(this),
        onTip: this.onTip.bind(this),
    };

    onError(error: string) {
        this.setState({ error });
    }

    onTip(tip: PhoneCardError) {
        this.setState({ tip });
    }

    getValue(value: string) {
        this.setState({ value });
    }

    componentDidMount() {
        const { paymentMethod } = this.props;
        PhoneFormService.setPaymentMethod(paymentMethod);

        this.subscribe(PhoneFormService.error, { next: this.handler.onError });
        this.subscribe(PhoneFormService.tip, { next: this.handler.onTip });
        this.subscribe(PhoneFormService.amount.value, { next: this.handler.getValue });
    }

    render() {
        const { paymentMethod, status } = this.props;
        const { value, tip, error } = this.state;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        const mask = { placeholder: '+88 01000000000', mask: '+88 \\00000000000' };
        if (paymentMethod.id === ChannelType.Wavepay || paymentMethod.id === ChannelType.Kpay) {
            mask.placeholder = '+95 0000000000';
            mask.mask = '+95 0000000000';
        }
        if (paymentMethod.id === ChannelType.PayTM || paymentMethod.id === ChannelType.PhonePe) {
            mask.placeholder = '+91 0000000000';
            mask.mask = '+91 0000000000';
        }
        if (paymentMethod.id === ChannelType.MPESA) {
            mask.placeholder = '+254 000000000';
            mask.mask = '+254 000000000';
        }
        if (paymentMethod.id === ChannelType.Rocket) {
            mask.placeholder = '+88 010000000000';
            mask.mask = '+88 \\000000000000';
        }
        const inputService = () => {
            if(paymentMethod.id === ChannelType.MPESA || paymentMethod.id === ChannelType.PayTM) {
                return PhoneFormService.phoneMpesa
            }
            if(paymentMethod.id === ChannelType.Rocket) {
                return PhoneFormService.phoneRocket
            }
            if(paymentMethod.id === ChannelType.Wavepay || paymentMethod.id === ChannelType.Kpay) {
                return PhoneFormService.phoneMyanmar
            }
             else {
                return PhoneFormService.phone
            }
        }

        const fee = WithdrawService.fee.getValue();
        const isInit = status === PaykassmaWithdrawState.INIT;
        if (fee && isInit) {
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.HAS_FEE);
        }
        const amountFee = formatCurrencySign(paymentMethod.limit.withdraw.min * 1.25, 0, paymentMethod.currency)
        const allFeeText = `${amountFee} ${t('popup.payment.min.fee')}`;

        const isBDT = AuthService.auth.getValue()!.currencyCode === 50;

        return (
            <div className="phone-withdrawal">
                <div className="phone-withdrawal__header">
                    <img className="phone-withdrawal__img" src={paymentMethod.icon} alt={paymentMethod.slug} />
                    <h2 className="phone-withdrawal__title">{t(paymentMethod.title)}</h2>
                </div>
                <form className="phone-withdrawal-form" onSubmit={(e) => PhoneFormService.onSubmit(e)}>
                    <div className="phone-withdrawal-form-block">
                        <Input
                            className="phone-withdrawal-form-block__input"
                            inputService={PhoneFormService.amount}
                            name="amount"
                            type="number"
                            hint={hint}
                        />
                        <span className="phone-withdrawal-form-block__currency">{paymentMethod.currency.sign}</span>

                        {!!error ? (
                            <div className="phone-withdrawal-form-block__error">
                                <img src={Attention} alt="attention"/>
                                <span
                                    className="phone-withdrawal-form-block__error_text">
                                {allFeeText}
                            </span>
                            </div>
                        ) : null}

                        {tip === PhoneCardError.AMOUNT_PAYTM && !error ? (
                            <div className="phone-withdrawal-form-block__help">
                                <div>{t('popup.payment.amount.withdrawal.round')}</div>
                            </div>
                        ): null}
                        {tip === PhoneCardError.AMOUNT_PAYTM_FEE && !error ? (
                            <div className="phone-withdrawal-form-block__help">
                                <div>{t('popup.payment.amount.withdrawal.round.fee')}</div>
                            </div>
                        ): null}
                    </div>
                    <div className="phone-withdrawal-form-block">
                        <MaskedInput
                        className="phone-withdrawal-form-block__input"
                        inputService={inputService()}
                        name="phone-number"
                        mask={mask.mask}
                        type="tel"
                        placeholder={mask.placeholder}
                    />
                        {tip === PhoneCardError.PHONE_ZERO && (
                            <div className="phone-withdrawal__account_error">
                                <div>{t('profile.balance.deposit.amount.hint')}</div>
                            </div>
                        )}
                        {tip === PhoneCardError.PHONE_MPESA && paymentMethod.id === ChannelType.MPESA && (
                            <div className="phone-withdrawal-form-block__help">
                                <div>{t('popup.payment.mpesa.phone')}</div>
                            </div>
                        )}
                    </div>
                    {((status === PaykassmaWithdrawState.HAS_FEE || status === PaykassmaWithdrawState.AWAIT) && fee ) ?
                        <FeeNotice amount={value} fee={fee} /> : null}
                    {/*<AttentionWithdrawalTime />*/}
                    {(status === PaykassmaWithdrawState.HAS_FEE || status === PaykassmaWithdrawState.AWAIT ) ? (
                        <>
                            {(paymentMethod.id === ChannelType.MPESA || paymentMethod.id === ChannelType.PayTM) ? (
                                <WithdrawalButtonMpesa
                                    buttonTitle="profile.balance.withdraw.fee.agree"
                                    variation={true}
                                    paymentMethod={paymentMethod}
                                    amount={value}
                                />
                            ) : (
                                <WithdrawalButton
                                    buttonTitle="profile.balance.withdraw.fee.agree"
                                    variation={true}
                                    paymentMethod={paymentMethod}
                                    amount={value}
                                    state={status}
                                />
                            )}
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel" />
                        </>
                    ) : (paymentMethod.id === ChannelType.MPESA || paymentMethod.id === ChannelType.PayTM) ? (
                        <WithdrawalButtonMpesa buttonTitle="profile.balance.withdraw.button" />
                    ) : (
                        <WithdrawalButton buttonTitle="profile.balance.withdraw.button" state={status} />
                    )}
                </form>
            </div>
        );
    }
}

export default Phone;
