import Validator from '../interface/Validator';

class PhoneMpesa implements Validator {
    validate(value: string): boolean {
        return value.length === 14;
    }
}
const PhoneMpesaService = new PhoneMpesa();
export { PhoneMpesaService };
export default PhoneMpesa;
