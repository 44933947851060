import Validator from '../interface/Validator';

class Length implements Validator {
    validate(value: string, number: number): boolean {
        return value.length === 9;
    }
}
const LengthService = new Length();

export { LengthService };
export default Length;
