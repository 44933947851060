import classNames from 'classnames';
import { PaymentMethod } from 'page/Profile/Balance/PaymentMethod/types';
import { formatCurrencySign } from 'utils/format';
import useObservable from 'utils/useObservable';

import AmountFormService from '../../service/AmountForm';

import './SumSelector.scss';

type SumSelectorProps = {
    paymentMethod: PaymentMethod;
    disabled: boolean;
};

const SumSelector = (props: SumSelectorProps) => {
    const amount = useObservable(AmountFormService.amount.value, AmountFormService.amount.value.getValue());

    return (
        <div className="sum-selector-corefy">
            {props.paymentMethod.amountPreset.map((preset, index) => {
                const itemClass = classNames('sum-selector-corefy__item', `item-${index}`, {
                    active: Number(amount) === Number(preset),
                });
                return (
                    <button
                        type="button"
                        className={itemClass}
                        onClick={() => AmountFormService.setValue(preset)}
                        disabled={props.disabled}
                        key={index}
                    >
                        {formatCurrencySign(Number(preset), 0, props.paymentMethod.currency)}
                    </button>
                );
            })}
        </div>
    );
};

export default SumSelector;
