/* eslint-disable max-len */

import { Icon } from 'types';

function WhatsUpIcon({ width = 100, height = 100 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="15" fill="#F3F3F6" />
            <path
                opacity="0.5"
                d="M52.4149 26.855C46.1678 27.1591 40.3467 29.7102 35.9007 34.1161C31.1582 38.8155 28.5478 45.0623 28.5504 51.7055C28.5561 65.4144 39.8254 76.5685 53.6693 76.5685H53.6797C57.7517 76.5671 61.7548 75.5877 65.3362 73.7263C65.5604 73.6098 65.8195 73.5776 66.064 73.6411L77.3226 76.5643C78.0678 76.7578 78.7429 76.0718 78.5377 75.3298L75.5483 64.5218C75.4764 64.2621 75.5133 63.9849 75.6451 63.7498C77.7074 60.0695 78.7907 55.9269 78.789 51.6858C78.7834 37.9773 67.515 26.8243 53.6697 26.8243L52.4149 26.855Z"
                fill="url(#paint0_linear_1:215)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M47.3737 22.0307C53.6207 22.3348 59.4418 24.8859 63.8879 29.2918C68.6304 33.9913 71.2407 40.2381 71.2382 46.8813C71.2325 60.5901 59.9631 71.7442 46.1193 71.7442H46.1089C42.0369 71.7429 38.0337 70.7635 34.4524 68.902C34.2282 68.7855 33.9691 68.7533 33.7245 68.8168L22.466 71.74C21.7208 71.9335 21.0456 71.2476 21.2509 70.5055L24.2403 59.6976C24.3122 59.4379 24.2753 59.1606 24.1435 58.9255C22.0812 55.2452 20.9979 51.1026 20.9996 46.8615C21.0052 33.153 32.2736 22 46.1189 22L47.3737 22.0307ZM57.9139 52.2347C58.3517 52.444 58.6473 52.5854 58.7736 52.7941C58.9305 53.0532 58.9305 54.297 58.4075 55.7484C57.8845 57.1994 55.377 58.524 54.1711 58.7023C53.0896 58.8623 51.7215 58.929 50.2179 58.4562C49.3064 58.1698 48.1373 57.7877 46.6398 57.1475C40.756 54.6326 36.7797 48.9881 36.028 47.9211C35.9753 47.8463 35.9385 47.794 35.918 47.7668L35.9125 47.7596C35.5788 47.3188 33.3551 44.381 33.3551 41.3402C33.3551 38.4783 34.7755 36.9783 35.4292 36.288C35.4739 36.2408 35.515 36.1974 35.5518 36.1576C36.1271 35.5355 36.8071 35.3801 37.2256 35.3801C37.6439 35.3801 38.0627 35.3841 38.4284 35.402C38.4735 35.4043 38.5204 35.404 38.5689 35.4037C38.9347 35.4016 39.3907 35.399 39.8407 36.4685C40.0136 36.8798 40.2666 37.4894 40.5334 38.1326C41.0736 39.4343 41.6707 40.8732 41.7758 41.0814C41.9326 41.3924 42.0373 41.7551 41.8281 42.1697C41.7966 42.2321 41.7675 42.2909 41.7397 42.3471C41.5826 42.6645 41.4671 42.898 41.2005 43.2061C41.0959 43.327 40.9878 43.4573 40.8797 43.5876C40.6634 43.8483 40.4472 44.1089 40.2589 44.2946C39.9447 44.6043 39.6177 44.9406 39.9837 45.5624C40.3499 46.1844 41.6095 48.2187 43.4754 49.8661C45.4813 51.637 47.2248 52.3856 48.1082 52.7649C48.2806 52.8389 48.4203 52.8989 48.5227 52.9496C49.1503 53.2606 49.5164 53.2087 49.8824 52.7941C50.2487 52.3794 51.4515 50.98 51.8699 50.3582C52.2882 49.7364 52.7067 49.84 53.282 50.0471C53.8573 50.2546 56.9431 51.7575 57.5707 52.0684C57.6932 52.1291 57.8077 52.1839 57.9139 52.2347Z"
                fill="url(#paint1_linear_1:215)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M58.7735 52.7941C58.6166 52.5348 58.1983 52.3794 57.5706 52.0684C56.943 51.7575 53.8572 50.2546 53.2819 50.0471C52.7066 49.84 52.2881 49.7364 51.8698 50.3582C51.4514 50.98 50.2486 52.3794 49.8823 52.7941C49.5163 53.2087 49.1502 53.2606 48.5226 52.9496C47.895 52.6387 45.8726 51.9826 43.4753 49.8661C41.6094 48.2187 40.3498 46.1844 39.9836 45.5624C39.6176 44.9406 39.9446 44.6043 40.2588 44.2946C40.5412 44.0161 40.8865 43.5689 41.2004 43.2061C41.5142 42.8435 41.6187 42.5842 41.828 42.1697C42.0372 41.755 41.9325 41.3924 41.7757 41.0814C41.6187 40.7703 40.3636 37.7124 39.8406 36.4685C39.331 35.2572 38.8135 35.4212 38.4283 35.402C38.0626 35.3841 37.6438 35.3801 37.2255 35.3801C36.807 35.3801 36.127 35.5355 35.5517 36.1576C34.9764 36.7794 33.355 38.2826 33.355 41.3402C33.355 44.3983 35.604 47.3521 35.9179 47.7668C36.2317 48.1816 40.3436 54.4563 46.6397 57.1475C48.1372 57.7877 49.3063 58.1698 50.2178 58.4561C51.7214 58.929 53.0895 58.8623 54.171 58.7023C55.3769 58.524 57.8844 57.1994 58.4074 55.7484C58.9304 54.297 58.9304 53.0532 58.7735 52.7941Z"
                fill="#F3F3F6"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1:215"
                    x1="29.697"
                    y1="37.8384"
                    x2="72.1925"
                    y2="48.0354"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1:215"
                    x1="22.1414"
                    y1="32.9258"
                    x2="64.4416"
                    y2="43.1153"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default WhatsUpIcon;
