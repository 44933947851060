import {AzarpayDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendAzarpayDepositRequest';
import {AzarpayWithdrawalRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendAzarpayWithdrawal';
import {AzarpayDepositResponse, AzarpayWithdrawResponse, CorefyDepositCode} from '@ay_tsarbet/newbet-core/dist/types';
import {Subject} from 'rxjs';

import AzarpayDepositService from '../../page/Profile/Balance/Deposit/Azarpay/service/AzarpayDeposit';
import AzarpayInterface from '../interface/payment/Azarpay';
import WSBet from '../WSBet';
import {VisaDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendVisaDepositRequest';
import AzarpayWithdrawService, {
    AzarpayWithdrawState
} from '../../page/Profile/Balance/Withdrawal/Azarpay/service/AzarpayWithdraw';

enum AzarpayError {
    UNKNOWN,
    DEPOSIT,
    WITHDRAW,
}

class Azarpay implements AzarpayInterface {
    error = new Subject<AzarpayError>();

    withdrawResult = new Subject<void>();

    depositBank(request: AzarpayDepositRequest) {
        console.log('azarpay.deposit', request);
        WSBet.payment.azarpay.depositBank(request);
    }

    depositVisa(request: VisaDepositRequest) {
        console.log('azarpay.deposit', request);
        WSBet.payment.azarpay.depositVisa(request);
    }

    withdraw(withdraw: AzarpayWithdrawalRequest) {
        console.log('withdraw', withdraw);
        WSBet.payment.azarpay.withdraw(withdraw);
    }

    onWithdrawReceive(response: AzarpayWithdrawResponse) {
        console.log('AZARPAY  | WITHDRAW RESPONSE', response);
        if (response.errorCode >= CorefyDepositCode.success) {
            const isValerpay = response.message.includes('valerpay')
            if(isValerpay) {
                window.open(response.message, '_self')
            } else {
                this.withdrawResult.next();
                AzarpayWithdrawService.state.next(AzarpayWithdrawState.ACCEPT)
            }
        } else {
            this.error.next(AzarpayError.WITHDRAW);
            AzarpayWithdrawService.state.next(AzarpayWithdrawState.REJECT)
        }
    }

    onDepositReceive(data: AzarpayDepositResponse) {
        console.log('AZARPAY | DEPOSIT RESPONSE', data);
        if (!data.code) {
            AzarpayDepositService.onReceive(data.message);
        } else {
            AzarpayDepositService.onError();
        }
    }
}

export { AzarpayError };
export default Azarpay;
