import classNames from 'classnames';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';

import Spinner from '../../../../../Deposit/Paykassma/Other/Button/image/Spinner.svg';
import { PaymentMethod } from '../../../../../PaymentMethod/types';
import PaykassmaWithdrawService, { PaykassmaWithdrawState } from '../../../../Paykassma/service/PaykassmaWithdraw';
import IMPSFormService from '../../service/IMPSForm';

import './WithdrawalButtonApayIMPS.scss';

type WithdrawalButtonType = {
    buttonTitle: string;
    variation?: boolean;
    paymentMethod?: PaymentMethod;
    amount?: string;
};

function WithdrawalButtonApayIMPS(props: WithdrawalButtonType) {
    const { buttonTitle, variation, paymentMethod, amount } = props;
    const valid = useObservable<boolean>(IMPSFormService.valid, false);
    const state = useObservable(PaykassmaWithdrawService.state, PaykassmaWithdrawService.state.getValue());

    const min = paymentMethod && paymentMethod.limit.withdraw.min;
    const sumToWithdraw = amount && parseInt(amount) - parseInt(amount) * 0.2;
    const disabledFee = sumToWithdraw && min && sumToWithdraw >= min;

    const className = classNames('withdrawal-button-easypaisa', {
        'withdrawal-button-easypaisa__active': valid || (!variation && disabledFee),
        'withdrawal-button-easypaisa__variated': variation && !disabledFee,
    });
    return (
        <button type="submit" className={className} disabled={!valid}>
            {state === PaykassmaWithdrawState.AWAIT ? (
                <img className="withdrawal-button-easypaisa__wait" src={Spinner} alt="" />
            ) : (
                t(buttonTitle)
            )}
        </button>
    );
}

export default WithdrawalButtonApayIMPS;
