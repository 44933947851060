import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { translate as t } from 'service/Language';
import Spinner from 'svg/spinner.svg';

import useObservable from '../../../../../../../utils/useObservable';
import AmountFormService from '../../../Paykassma/service/AmountForm';
import PaykassmaDepositService, { DepositState } from '../../../Paykassma/service/PaykassmaDeposit';

import './Submit.scss';

type SubmitProps = {
    className?: string;
};

function Submit(props: SubmitProps) {
    const { className } = props;
    const [valid, setValid] = useState(false);
    const status = useObservable(PaykassmaDepositService.state, PaykassmaDepositService.state.getValue());
    useEffect(() => {
        const validate = AmountFormService.amount.valid.subscribe({
            next: (isValid) => {
                setValid(isValid);
            },
        });
        return () => {
            validate.unsubscribe();
        };
    }, []);

    const _className = classNames({
        [className || '']: !!className,
        'deposit-submit': true,
        'deposit-submit__active': valid,
    });

    return (
        <button type="submit" className={_className} disabled={!valid || status === DepositState.WAIT}>
            {status === DepositState.WAIT ? (
                <img className="deposit-submit__wait" src={Spinner} alt="" />
            ) : (
                t('profile.balance.deposit.button.next')
            )}
        </button>
    );
}

export default Submit;
