import { PaymentMethod } from '../../../PaymentMethod/types';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import Pix from '../../Directa24/Pix/Form';

import './Form.scss';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;

    if (paymentMethod.id === ChannelType.Direct24_PIX || paymentMethod.id === ChannelType.Direct24_Boleto) {
        return <Pix paymentMethod={paymentMethod} />;
    } else return null;
}

export default Form;
