import { translate as t } from 'service/Language';

import InformationItem from './InformationItem/InformationItem';

import './Information.scss';

function Information() {
    return (
        <div className="information-list">
            <p className="information-list__title">{t('footer.information')}</p>
            <div className="information-list__link">
                <InformationItem className="information-list__value" />
            </div>
        </div>
    );
}

export default Information;
