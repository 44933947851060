/* eslint-disable max-len */

import { Icon } from 'types';

function Check({ color = '#3959D9', width = 14, height = 14 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 14 14" fill={color}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7183 0.683166C13.4456 1.07986 13.7135 1.99101 13.3168 2.71829L7.95682 12.545C7.15761 14.0102 5.17449 14.2958 3.99431 13.1156L0.93934 10.0607C0.353553 9.47488 0.353553 8.52514 0.93934 7.93935C1.52513 7.35356 2.47487 7.35356 3.06066 7.93935L5.64312 10.5218L10.6832 1.28173C11.0799 0.554458 11.991 0.286472 12.7183 0.683166Z"
                fill={color}
            />
        </svg>
    );
}

export default Check;
