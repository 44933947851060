/* eslint-disable max-len */

import { Icon } from 'types';

function ScoreCard({ color = '#3959D9', width = 19, height = 14 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 19 14" fill="none">
            <path
                d="M7.60889 6.7876C7.60889 7.90381 7.42871 8.73291 7.06836 9.2749C6.71094 9.81689 6.16309 10.0879 5.4248 10.0879C4.70996 10.0879 4.16797 9.80811 3.79883 9.24854C3.42969 8.68896 3.24512 7.86865 3.24512 6.7876C3.24512 5.65381 3.42383 4.81885 3.78125 4.28271C4.1416 3.74365 4.68945 3.47412 5.4248 3.47412C6.14258 3.47412 6.68604 3.75537 7.05518 4.31787C7.42432 4.88037 7.60889 5.70361 7.60889 6.7876ZM4.29102 6.7876C4.29102 7.66357 4.38037 8.28906 4.55908 8.66406C4.74072 9.03906 5.0293 9.22656 5.4248 9.22656C5.82031 9.22656 6.10889 9.03613 6.29053 8.65527C6.4751 8.27441 6.56738 7.65186 6.56738 6.7876C6.56738 5.92627 6.4751 5.30371 6.29053 4.91992C6.10889 4.5332 5.82031 4.33984 5.4248 4.33984C5.0293 4.33984 4.74072 4.52881 4.55908 4.90674C4.38037 5.28467 4.29102 5.91162 4.29102 6.7876Z"
                fill={color}
            />
            <path
                d="M14.2861 10H13.2534V5.85156C13.2534 5.35645 13.2651 4.96387 13.2886 4.67383C13.2212 4.74414 13.1377 4.82178 13.0381 4.90674C12.9414 4.9917 12.6133 5.26123 12.0537 5.71533L11.5352 5.06055L13.4248 3.5752H14.2861V10Z"
                fill={color}
            />
            <rect x="0.5" y="0.5" width="18" height="13" rx="4.5" stroke={color} />
            <rect x="9" y="1" width="1" height="12" fill={color} />
        </svg>
    );
}

export default ScoreCard;
