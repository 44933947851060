/* eslint-disable max-len */

import { Icon } from 'types';

function CasinoSlots({ color, width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                d="M6.5 7.03125C7.40323 7.03125 8.46207 8.14481 8.24839 9.0224C8.12945 9.51091 8.03909 10.0106 7.9788 10.5172C7.92083 11.0042 7.54531 11.3952 7.07001 11.5159C4.3798 12.199 2.375 14.6648 2.375 17.625C2.375 21.1144 5.1506 24 8.5625 24C9.16615 24 9.75522 23.9001 10.3184 23.7191C10.9487 23.5165 11.0583 22.7232 10.6787 22.1807C9.78067 20.8972 9.25 19.3097 9.25 17.625C9.25 15.942 9.77966 14.3843 10.6762 13.114C11.0579 12.573 10.9485 11.7809 10.3176 11.5799C10.2756 11.5665 10.2333 11.5536 10.191 11.5411C9.68709 11.3924 9.2988 10.9398 9.37534 10.4201C9.92633 6.67846 12.3967 3.54776 15.7266 2.16683C16.5406 1.82928 17.1961 2.77364 16.8691 3.59188C16.3808 4.81372 16.125 6.13327 16.125 7.49341V10.4764C16.125 10.9845 15.7406 11.4031 15.2497 11.534C12.595 12.242 10.625 14.6905 10.625 17.625C10.625 21.1144 13.4006 24 16.8125 24C20.2244 24 23 21.1144 23 17.625C23 14.6905 21.03 12.242 18.3753 11.534C17.8844 11.4031 17.5 10.9845 17.5 10.4764V7.49341C17.5 5.11615 18.405 2.88116 20.0486 1.20026L20.0619 1.18667C20.4942 0.744487 20.1809 0 19.5625 0C16.6898 0 14.0566 1.06587 12.0194 2.83069C11.6009 3.19325 10.9707 3.19942 10.5297 2.86467C9.66486 2.20831 8.25352 1.40625 6.5 1.40625C3.59961 1.40625 1.61499 3.61652 1.51903 3.71063C1.23418 3.9895 1.23418 4.44801 1.51903 4.72687C1.61499 4.82098 3.59961 7.03125 6.5 7.03125ZM16.8125 14.8125C16.8125 15.2008 16.4883 15.504 16.1234 15.6367C15.5302 15.8524 15.0612 16.3383 14.8586 16.949C14.7391 17.3094 14.4422 17.625 14.0625 17.625C13.6828 17.625 13.3681 17.3143 13.4402 16.9415C13.7074 15.5604 14.7682 14.4675 16.1143 14.1823C16.4942 14.1018 16.8125 14.4242 16.8125 14.8125ZM8.5625 14.8125C8.5625 15.2008 8.23834 15.504 7.87339 15.6367C7.28021 15.8524 6.81116 16.3383 6.60862 16.949C6.48909 17.3094 6.1922 17.625 5.8125 17.625C5.4328 17.625 5.1181 17.3143 5.19022 16.9415C5.45741 15.5604 6.51817 14.4675 7.86435 14.1823C8.24424 14.1018 8.5625 14.4242 8.5625 14.8125Z"
                fill={color ?? 'url(#paint0_linear)'}
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="1.5"
                    y1="5.26829"
                    x2="20.2034"
                    y2="9.35995"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default CasinoSlots;
