import { TournamentRecordList } from '../model/Tournament';
import JSON from './JSON';

class Tournament extends JSON {
    getList(): Promise<TournamentRecordList> {
        return this.get<TournamentRecordList>('/tournament', {}).then((response) => response.data);
    }
}

export default Tournament;
