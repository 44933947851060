import { Providers } from 'enums/providers';

const RouteConfig = {
    hash: {
        deposit: 'deposit',
        withdrawal: 'withdrawal',
        auth: 'signin',
        registration: 'signup',
        recovery: 'recovery',
        reset: 'reset',
        lost: 'lost',
        successDeposit: 'success-deposit',
        failedDeposit: 'failed-deposit',
        checkEmail: 'check-email',
        successSentEmail: 'success-sent-email',
    },

    landing: {
        root: '/land',
        APK: '/mobile-app',
        wheels: {
            root: '/wheels',
            wheelCard: '/cards',
            wheelCrazyTime: '/crazytime',
            wheelGirl: '/girls',
            wheelTeenager: '/teenagers',
            wheelAviator: '/aviator',
            wheelCriket: '/criket',
            wheelSlots: '/slots',
            wheelLigtningRoulette: '/roulette',
            wheelBonanza: '/bonanza',
            wheelOlympus: '/olympus',
        },
        sports: {
            cricket: '/cricket',
        },
    },

    profile: {
        root: '/profile',
        balance: {
            root: '/balance',
            deposit: '/deposit',
            withdrawal: '/withdrawal',
            history: '/history',
        },
        notification: {
            root: '/notification',
            info: '/info',
            adds: '/adds',
            news: '/news',
        },
        account: {
            root: '/account',
            personal: '/personal',
            safety: '/safety',
        },
        docs: '/docs',
        history: '/history',
        bonus: '/bonus',
    },
    info: {
        root: '/info',
        faq: '/faq',
        promo: {
            root: '/promo',
        },
        support: '/support',
        about: '/about',
        rules: '/rules',
        kycaml: '/kycaml',
        privacy: '/privacy',
        responsiblegame: '/responsiblegame',
        cookies: '/cookies',
    },
    notFound: '/404',
    jackpot: '/jackpot',

    casino: {
        root: '/',
        category: '/:category',
        providers: '/providers',
        provider: '/providers/:provider',
        games: '/games',
        live: '/live-casino',
        betting: '/v-sport',
        filter: '/:category/:provider',
        tournament: '/tournament',
        tournamentId: '/tournament/:id',
        gameplay: '/game/play',
        favorite: '/favorite',
        tvGames: '/tv-games',
        gloryGames: '/glory',
    },

    betting: {
        root: '/betting',
    },

    external: {
        bkash: '//bka.sh/next',
        nagad: {
            ios: '//apps.apple.com/ru/app/nagad/id1471844924',
            android: '//play.google.com/store/apps/details?id=com.konasl.nagad&hl=en&gl=US',
        },
    },
};

export const QUERY_PARAMS = {
    TV_GAMES: 'TVGames',
    LIVE_CASINO: 'Evolution2',
};

export const QUERY_PARAMS_VALUE = {
    [QUERY_PARAMS.TV_GAMES]: `${Providers.Betgames},${Providers.HollywoodTV}`,
    [QUERY_PARAMS.LIVE_CASINO]: `${Providers.Evolution2}`,
};

export default RouteConfig;
