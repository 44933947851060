import RegistrationResponseStatus from '@ay_tsarbet/newbet-core/dist/models/registrationResponseStatus';
import { Step10Data } from '@ay_tsarbet/newbet-core/dist/types';
import { Subject } from 'rxjs';

import RecoveryInterface from './interface/Recovery';
import WSBet from './WSBet';

enum RecoveryState {
    NONE,
    AWAIT,
}

enum RecoveryError {
    EMAIL_NOTFOUND,
    TOKEN_EXPIRED,
    RESET_ERROR,
}

class Recovery implements RecoveryInterface {
    public error = new Subject<RecoveryError>();

    private state: RecoveryState = RecoveryState.NONE;

    complete = new Subject<void>();

    byPhone(phone: string) {
        if (this.state === RecoveryState.NONE) {
            WSBet.recovery.byPhone(phone);
            this.delay();
        }
    }

    byEmail(email: string) {
        if (this.state === RecoveryState.NONE) {
            WSBet.recovery.byEmail(email);
            this.delay();
        }
    }

    sendPhoneToken(code: string) {
        WSBet.recovery.sendPhoneToken(code);
    }

    sendEmailToken(token: string) {
        WSBet.recovery.sendEmailToken(token);
    }

    setPassword(password: string) {
        WSBet.recovery.setPassword(password);
    }

    onReceive(data: Step10Data) {
        switch (data.status) {
            case RegistrationResponseStatus.newPasswordRecoverySuccess:
                this.complete.next();
                break;
            case RegistrationResponseStatus.resetTokenSentByEmail:
            case RegistrationResponseStatus.resetTokenSentByPhone:
                this.complete.next();
                break;
            case RegistrationResponseStatus.resetTokenAccepted:
                // not an error, nor complete
                break;
            case RegistrationResponseStatus.recoveryByEmailError:
                this.error.next(RecoveryError.EMAIL_NOTFOUND);
                break;
            case RegistrationResponseStatus.recoveryCodeExpired:
            case RegistrationResponseStatus.recoveryCodeError:
                this.error.next(RecoveryError.TOKEN_EXPIRED);
                break;
            case RegistrationResponseStatus.newPasswordRecoveryError:
            case RegistrationResponseStatus.recoverySaveNewPasswordError:
                this.error.next(RecoveryError.RESET_ERROR);
                break;
            default:
        }
    }

    delay() {
        this.state = RecoveryState.AWAIT;
        setTimeout(() => {
            this.state = RecoveryState.NONE;
        }, 5000);
    }
}

const RecoveryService = new Recovery();

export { RecoveryError };
export default RecoveryService;
