import InputService from "component/Input/service/Input";
import { FormEvent } from "react";
import { BehaviorSubject } from "rxjs";
import AuthService from "../../../../../../../service/Auth";
import GoogleService from "../../../../../../../service/Google";
import Validator from "../../../../../../../service/Validator/Validator";
import { PaymentMethod } from "../../../../PaymentMethod/types";
import PaykassmaDepositService from "../../service/PaykassmaDeposit";

export enum JazzcashInputTip {
  NONE,
  AMOUNT,
  CPF,
  NAME,
  SURNAME,
  EMAIL,
}

export enum AmountError {
  NONE,
  AMOUNT_MIN,
  AMOUNT_MAX,
  AMOUNT_WITHDRAWAL,
}

class AmountForm {
  paymentMethod: PaymentMethod | null = null;

  limitValidator = new Validator.limit(1, 1000000);

  amount = new InputService({
    title: "profile.balance.deposit.input.amount",
    validator: this.limitValidator,
    validatorText: "profile.balance.deposit.input.amount",
  });

  errorTip = new BehaviorSubject<JazzcashInputTip>(JazzcashInputTip.NONE);

  amountLimit = new BehaviorSubject<AmountError>(AmountError.NONE);

  valid = new BehaviorSubject<boolean>(false);

  handler = {
    onAmount: this.onAmount.bind(this),
    onAmountActive: this.onAmountActive.bind(this),
  };

  constructor() {
    this.amount.value.subscribe(this.handler.onAmount);
    this.amount.active.subscribe(this.handler.onAmountActive);
  }

  setLimit(min: number, max: number) {
    if (this.limitValidator) {
      this.limitValidator.setLimit(min, max);
    }
  }

  onAmount(value: string) {
    if (
      this.paymentMethod &&
      this.amount.getValue() &&
      !this.limitValidator.validate(value)
    ) {
      this.valid.next(false);
      if (+value < this.paymentMethod.limit.deposit.min) {
        this.amountLimit.next(AmountError.AMOUNT_MIN);
      } else {
        this.amountLimit.next(AmountError.NONE);
      }
      if (+value > this.paymentMethod.limit.deposit.max) {
        this.amountLimit.next(AmountError.AMOUNT_MAX);
      }
    } else {
      this.amount.error.next(null);
    }
    this.checkValid();
  }

  onAmountActive(active: boolean) {
    if (active) {
      this.errorTip.next(JazzcashInputTip.AMOUNT);
    } else {
      this.errorTip.next(JazzcashInputTip.NONE);
    }
  }

  checkValid() {
    if (this.paymentMethod) {
      const isAmount = this.amount.isValid();

      if (isAmount) {
        this.valid.next(true);
      } else this.valid.next(false);
    }
  }

  setValue(value: number) {
    this.amount.onFocus();
    this.amount.value.next(value.toString());
  }

  setMethod(paymentMethod: PaymentMethod) {
    this.paymentMethod = paymentMethod;
    this.setLimit(
      paymentMethod.limit.deposit.min,
      paymentMethod.limit.deposit.max
    );
  }

  reset() {
    this.amount.value.next("");
    this.amount.error.next(null);
    this.amount.active.next(false);
    this.amount.autoCompleted.next(false);
    this.amountLimit.next(AmountError.NONE);
    this.errorTip.next(JazzcashInputTip.NONE);
  }

  onSubmit(event: FormEvent) {
    event.preventDefault();

    const valid = this.amount.isValid();

    if (valid && this.paymentMethod) {
      const clientInfo = AuthService.auth.getValue();
      const userId = clientInfo ? clientInfo.userId.toString() : "";
      const amount = parseFloat(this.amount.getValue());
      GoogleService.sentDepositAmount(
        userId,
        this.paymentMethod.title,
        amount.toString(),
        this.paymentMethod.currency.name
      );
      PaykassmaDepositService.depositNagadP2CRequest(
        this.paymentMethod,
        amount
      );

      PaykassmaDepositService.showWait();
    }
  }
}

const AmountFormService = new AmountForm();

export default AmountFormService;
