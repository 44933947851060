import classNames from 'classnames';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { translate as t } from 'service/Language';

import Notification from '../../../../../../../component/Notification/Notification';

import { useIsDesktop } from '../../../../../../../utils/useBreakpoints';
import useObservable from '../../../../../../../utils/useObservable';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import { ReactComponent as CopyImg } from '../../images/copy.svg';

import Transaction from '../Transaction/Transaction';
import Wallet from './Wallet/Wallet';

import WellxPayInvoice from '../../../../../../../service/model/payment/WellxPayInvoice';
import WellxPayDepositService from '../../service/WellxpayDeposit';

import './Account.scss';


type AccountProps = {
    paymentMethod: PaymentMethod;
    invoice: WellxPayInvoice;
};

function Account({ paymentMethod, invoice }: AccountProps) {
    const state = useObservable(WellxPayDepositService.state, WellxPayDepositService.state.getValue());
    const walletType = useObservable(WellxPayDepositService.walletType, WellxPayDepositService.walletType.getValue());
    const isDesktop = useIsDesktop();

    const className = classNames({
        'deposit-account__copy': true,
        // 'deposit-account__copy__copied': isCopied,
    });

    const copyInvoice = () => {
        Notification({ message: t('profile.balance.deposit.copied') });
    };

    function hint(): string {
           return t('profile.balance.deposit.description.invoice')
    }
    const isLink = paymentMethod.id === ChannelType.WELLXPAY_H2H_NAGAD || paymentMethod.id === ChannelType.WELLXPAY_H2H_BKASH;
    return (
        <div className="deposit-account">
            <div className="deposit-account__invoice-block">
                <div className="deposit-account__invoice">
                    <span className="deposit-account__invoice-hint">{t('profile.balance.deposit.input.account')}</span>
                    <span className="deposit-account__invoice-value">{invoice.id}</span>
                </div>
                <CopyToClipboard text={invoice.id} onCopy={copyInvoice}>
                    <div className={className}>
                        <CopyImg />
                    </div>
                </CopyToClipboard>
            </div>
            {paymentMethod.id === ChannelType.WELLXPAY_H2H_NAGAD || paymentMethod.id === ChannelType.WELLXPAY_H2H_BKASH ? (
                <Wallet type={walletType} />
            ) : (
                <div className="deposit-account__hint">
                    {hint()}
                </div>
            )}
            {!isDesktop && isLink ? (
                <div className="deposit-account__goto">
                    <a href={paymentMethod.getLink()} target="_blank" rel="noreferrer">
                        <span>{t('profile.balance.deposit.goto.app')}</span>
                        <span className="deposit-account__goto-name">{t(paymentMethod.title)}</span>
                        <img src={paymentMethod.icon} width={14} height={14} alt={paymentMethod.title} />
                    </a>
                </div>
            ) : null}
            <Transaction paymentMethod={paymentMethod} state={state} />
        </div>
    );
}

export default Account;
