import Validator from '../interface/Validator';

class BankCode implements Validator {
    validate(value: string): boolean {
        return (
            this.firstLetterCheck(value) &&
            this.symbolCheck(value) &&
            this.numberCheck(value) &&
            this.lengthCheck(value)
        );
    }

    firstLetterCheck(value: string): boolean {
        return new RegExp('^[A-Z]{4}').test(value);
    }

    symbolCheck(value: string): boolean {
        return new RegExp('^[A-Za-z0-9]+$').test(value);
    }

    numberCheck(value: string): boolean {
        return new RegExp('^[0]+$').test(value[4]);
    }

    lengthCheck(value: string): boolean {
        return value.length === 11;
    }
}
const BankCodeService = new BankCode();

export { BankCodeService };
export default BankCode;
