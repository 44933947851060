import { translate as t } from 'service/Language';

import './License.scss';

function License() {
    return (
        <div className='license-container'>
            {/* <iframe
                scrolling='none'
                className='license-container__iframe'
                src={t('footer.curacao.iframe')}
            /> */}
            <p className='license-container__desktop'>
                <noindex>{t('footer.content')}</noindex>
            </p>
            <div className='license-container__mobile'>
                <p className='license-container__title'>
                    <noindex>{t('footer.content.first')}</noindex>
                </p>
                <p className='license-container__title'>{t('footer.content.second')}</p>
            </div>
        </div>
    );
}

export default License;
