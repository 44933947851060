/* eslint-disable max-len */

import { Icon } from 'types';

function Cart({ color = '#9FA0A8', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.9355 7.37523L22.3576 16.2385C22.2801 16.6082 21.975 16.8286 21.612 16.8286H10.0339C9.86418 16.8286 9.74815 16.8903 9.67315 16.974C9.59576 17.0604 9.55395 17.1796 9.55395 17.3036C9.55395 17.4275 9.59576 17.5468 9.67315 17.6332C9.74815 17.7169 9.86418 17.7786 10.0339 17.7786H21.612C21.8623 17.7786 22.0612 17.8731 22.1961 18.0238C22.3287 18.1718 22.3919 18.365 22.3919 18.5536C22.3919 18.7421 22.3287 18.9354 22.1961 19.0833C22.0612 19.234 21.8623 19.3286 21.612 19.3286H10.0339C7.80769 19.3286 7.21476 16.4206 9.08157 15.464C8.82616 14.3212 7.08686 6.5277 6.85142 5.40001H3.67487C3.41305 5.40001 3.2039 5.30798 3.06048 5.15856C2.91887 5.01102 2.84998 4.81649 2.84998 4.62501C2.84998 4.43353 2.91887 4.23899 3.06048 4.09145C3.2039 3.94203 3.41305 3.85001 3.67487 3.85001H7.45426C7.8019 3.85001 8.18288 4.05802 8.2612 4.44656L8.65687 6.40953H24.1916C24.7023 6.40953 25.0856 6.85417 24.9355 7.37523ZM23.0933 7.95953L21.0187 15.2786H10.6343L9.02071 7.95953H23.0933ZM11.5336 19.6238C12.8152 19.6238 13.8433 20.6423 13.8433 21.9167C13.8433 23.1343 12.8124 24.15 11.5336 24.15C10.3121 24.15 9.28395 23.1316 9.28395 21.9167C9.28395 20.645 10.3093 19.6238 11.5336 19.6238ZM12.4035 21.9167C12.4035 21.4649 11.9918 21.0548 11.5336 21.0548C11.3425 21.0548 11.1395 21.1509 10.9815 21.3141C10.8243 21.4767 10.7238 21.6936 10.7238 21.9167C10.7238 22.3625 11.1293 22.7191 11.5336 22.7191C12.0039 22.7191 12.4035 22.357 12.4035 21.9167ZM18.6125 19.6238C19.894 19.6238 20.9221 20.6423 20.9221 21.9167C20.9221 23.1343 19.8913 24.15 18.6125 24.15C17.3337 24.15 16.3028 23.1343 16.3028 21.9167C16.3028 20.6423 17.3309 19.6238 18.6125 19.6238ZM19.4223 21.9167C19.4223 21.4529 19.0591 21.0548 18.6125 21.0548C18.1659 21.0548 17.8026 21.4529 17.8026 21.9167C17.8026 22.3684 18.1543 22.7191 18.6125 22.7191C19.0707 22.7191 19.4223 22.3684 19.4223 21.9167Z"
                fill={color}
            />
        </svg>
    );
}

export default Cart;
