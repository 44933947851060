import { useEffect } from 'react';
import { translate as t } from 'service/Language';

import Close from '../../../../../component/Close/Close';
import useObservable from '../../../../../utils/useObservable';
import ChangeButton from '../ChangeButton/ChangeButton';
import NewEmailInput from './NewEmail/NewEmail';

import ChangeEmailService, {EmailChangeResult} from './service/ChangeEmail';
import EmailFormService from './service/EmailFormService';

import SuccessSentEmailPopup from '../../../../../component/SuccessSentEmailPopup/SuccessSentEmailPopup';
import Email from '../../../../../component/CheckEmailPopup/images/email.webp';

import './ChangeEmail.scss';

type ChangeEmailProps = {
    oldEmail?: string;
}

function ChangeEmail({oldEmail}: ChangeEmailProps) {
    const status = useObservable(ChangeEmailService.status, ChangeEmailService.status.value);

    useEffect(() => {
        EmailFormService.reset();
    }, []);
    return (
        status !== EmailChangeResult.SUCCESS ?
            <form className="change-email" onSubmit={(e) => EmailFormService.handler.onSubmit(e, oldEmail)}>
                <div className="change-email-header">
                    <div className="change-email-header__title">
                        {t('check.email.header')}
                    </div>
                    <Close size="s"/>
                </div>

                <div className="change-email-container">
                    <>
                        <img className="change-email-container__img" src={Email} alt="email"/>
                        <div className="change-email-container-email">
                            <span className="change-email-container-email__title">{t('check.email.your')}</span>
                        </div>
                        <NewEmailInput/>
                        <span className="change-email-container__desc">
                            {t('check.email.provide')}
                        </span>
                    </>
                    <ChangeButton/>
                </div>
            </form>
            : <SuccessSentEmailPopup play />
    )
}

export default ChangeEmail;
