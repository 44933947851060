import classNames from 'classnames';
import { Component } from 'react';
import { translate as t } from 'service/Language';

import GoogleService from '../../../../service/Google';
import PopupHelper from '../../../../service/PopupHelper';
import Around from './images/Around.svg';
import WheelBlack from './WheelBlack/WheelBlack';

import './WheelGirl.scss';

class WheelGirl extends Component {
    state = {
        angle: 0,
        idle: true,
        attempts: 2,
        disabled: false,
    };

    handler = {
        spin: this.spin.bind(this),
    };

    componentDidMount() {
        GoogleService.landingOpen('');
    }

    spin() {
        this.setState({ disabled: true, idle: false });
        this.setState({ angle: this.state.attempts === 2 ? 765 : 1440 });

        setTimeout(() => {
            if (this.state.attempts === 2) {
                GoogleService.landingSpin('');
                this.setState({ attempts: 1, idle: true });
                PopupHelper.showWheelWin('girl', this.handler.spin);
            } else {
                GoogleService.landingSpinAgain('');
                this.setState({ attempts: 0 });
                PopupHelper.showWheelRegistration('girl');
            }
            this.setState({ disabled: false });
        }, 4000);
    }

    render() {
        const { attempts, disabled, angle, idle } = this.state;

        const _className = classNames({
            'wheel-girl__scoreboard': true,
            'wheel-girl__scoreboard__idle': !idle,
        });

        const lightsFirstClassName = classNames({
            'wheel-girl__lights': true,
            'wheel-girl__lights_first': true,
            'wheel-girl__lights__fast': !idle,
        });
        const lightsSecondClassName = classNames({
            'wheel-girl__lights': true,
            'wheel-girl__lights_second': true,
            'wheel-girl__lights__fast': !idle,
        });

        return (
            <div className="wheel-girl">
                <div className="wheel-girl__logo" />
                <div className="wheel-girl__attempts">
                    <div className="wheel-girl__attempts_wrapper">
                        <div className="wheel-girl__title">{t('wheel.attempts')}</div>
                        <div className="wheel-girl__number">{attempts}</div>
                    </div>
                </div>
                <div className={_className}>
                    <WheelBlack angle={angle} />
                </div>
                <div className="wheel-girl__frame">
                    <div className={lightsFirstClassName} />
                    <div className={lightsSecondClassName} />
                </div>
                <div className="wheel-girl__harem">
                    <div className="wheel-girl__girl-left" />
                    <div className="wheel-girl__girl-right" />
                </div>
                <button
                    className="wheel-girl__button"
                    onClick={this.handler.spin}
                    disabled={disabled || attempts === 0}
                >
                    <div className="wheel-girl__button-center">
                        <img className="wheel-girl__button-image" src={Around} alt="around" />
                        <span className="wheel-girl__button-text">{t('wheel.spin')}</span>
                    </div>
                </button>
            </div>
        );
    }
}

export default WheelGirl;
