import Validator from '../interface/Validator';

class EasypaisaTransaction implements Validator {
    validate(value: string): boolean {
        return this.symbolCheck(value) && this.lengthCheck(value);
    }

    numberCheck(value: string): boolean {
        return new RegExp('^[Z0-9]+$').test(value);
    }

    symbolCheck(value: string): boolean {
        return new RegExp('^[A-Za-z0-9]+$').test(value);
    }

    lengthCheck(value: string): boolean {
        return value.length === 11;
    }
}

const EasypaisaTransactionService = new EasypaisaTransaction();

export { EasypaisaTransactionService };
export default EasypaisaTransaction;
