import UserDataStatus from '@ay_tsarbet/newbet-core/dist/models/registrationResponseStatus';
import { ClientInfo } from '@ay_tsarbet/newbet-core/dist/types';
import { format } from 'date-fns';
import { BehaviorSubject, Subject } from 'rxjs';

import { getCountry, unknownCountry } from './model/Country';
import { getCurrency } from './model/Currency';
import UserDataModel from './model/UserData';
import WSBet from './WSBet';

enum UserDataResponse {
    INVALID_PHONE_FORMAT,
    INVALID_EMAIL_FORMAT,
    INVALID_AGE,
    EMAIL_ALREADY_IN_USE,
    PHONE_ALREADY_IN_USE,
    COUNTRY_NAME_MISMATCH,
    SUCCESS,
    PASSWORD_CHANGED_SUCCESS,
}

enum ChangePasswordError {
    EMAIL_NOTFOUND,
    TOKEN_EXPIRED,
    RESET_ERROR,
}

const responseList: Array<[UserDataStatus, UserDataResponse]> = [
    [UserDataStatus.invalidPhoneFormat, UserDataResponse.INVALID_PHONE_FORMAT],
    [UserDataStatus.invalidEmailFormat, UserDataResponse.INVALID_EMAIL_FORMAT],
    [UserDataStatus.invalidAge, UserDataResponse.INVALID_AGE],
    [UserDataStatus.emailAlreadyInUse, UserDataResponse.EMAIL_ALREADY_IN_USE],
    [UserDataStatus.phoneAlreadyInUse, UserDataResponse.PHONE_ALREADY_IN_USE],
    [UserDataStatus.countryNameMismatch, UserDataResponse.COUNTRY_NAME_MISMATCH],
    [UserDataStatus.userDataUpdateSuccess, UserDataResponse.SUCCESS],
    [UserDataStatus.passwordChangeSuccess, UserDataResponse.PASSWORD_CHANGED_SUCCESS],
];

const UserDataResponseMap = new Map<UserDataStatus, UserDataResponse>(responseList);

class UserData {
    data = new BehaviorSubject<UserDataModel | null>(null);

    pendingData: UserDataModel | null = null;

    response = new Subject<UserDataResponse>();

    isUserRegTime = new BehaviorSubject(false);

    public error = new Subject<ChangePasswordError>();

    onReceive(clientInfo: ClientInfo) {
        let country = unknownCountry;

        if (clientInfo.country) {
            country = getCountry(clientInfo.country.id);
        }

        let phone = null;
        if (clientInfo.phone) {
            phone = parseInt(clientInfo.phone, 10);
        }

        const data = new UserDataModel(
            clientInfo.userId,
            clientInfo.email,
            phone,
            clientInfo.firstName,
            clientInfo.surname,
            country,
            clientInfo.registrationDate,
        );
        data.currency = getCurrency(clientInfo.currencyCode);

        if (clientInfo.birthday) {
            const epochStart =
                clientInfo.birthday.getTime() / 1000 === new Date().getTimezoneOffset() * 60;
            if (!epochStart) {
                data.birthDate = clientInfo.birthday;
            }
        }

        this.data.next(data);
    }

    onResponse(response: UserDataResponse) {
        if (response === UserDataResponse.SUCCESS) {
            this.data.next(this.pendingData);
        }
        if (response === UserDataResponse.PASSWORD_CHANGED_SUCCESS) {
            this.data.next(this.pendingData);
        }
        this.pendingData = null;
        this.response.next(response);
    }

    save(userData: UserDataModel) {
        if (this.pendingData === null && userData.isValid()) {
            this.pendingData = userData.copy();
            WSBet.userData.update(this.pendingData);
        }
    }

    changeEmail(email: string) {
        const currentData = this.data.getValue();

        if (currentData) {
            currentData.email = email;
        }
    }

    checkRegistrationTime(registrationTime: number) {
        const registration = new Date(registrationTime).getTime();
        const now = new Date().getTime();
        // переводим в минуты, смотрим менне 60 мин назад регистрация
        const diff = (now - registration) / 60000;
        if (diff < 60) {
            this.isUserRegTime.next(true);
        } else {
            this.isUserRegTime.next(false);
        }
    }

    checkUserData() {
        const userData = this.data.getValue();
        if (userData) {
            return !!(
                userData.id &&
                userData.firstName &&
                userData.email &&
                userData.birthDate &&
                userData.country &&
                userData.currency
            );
        }
    }
}

const UserDataService = new UserData();

export { UserDataResponse, UserDataResponseMap };
export default UserDataService;
