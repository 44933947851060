import AviatorIcon from './aviatorIcon.svg';
import AviatorText from './aviatorText.svg';
import BonusMenu from './bonus_icon.svg';
import Cross from './cross_icon.svg';
import GameHistory from './game-history_icon.svg';
import Home from './home_icon.svg';
import Instagram from './instagram_icon.svg';
import Jackpot from './jackpot_icon.svg';
import Logout from './logout_icon.svg';
import App from './mobile_icon.svg';
import New from './new_star.svg';
import Notification from './notification_icon.svg';
import Present from './present_icon.svg';
import Profile from './profile_icon.svg';
import Sport from './sport.svg';
import Telegram from './telegram_icon.svg';
import Tournament from './tournament_icon.svg';
import Transaction from './transaction_icon.svg';
import PresentRed from './present.svg';

const image = {
    BonusMenu,
    Cross,
    GameHistory,
    Home,
    Instagram,
    Jackpot,
    Logout,
    App,
    Present,
    Profile,
    Telegram,
    Transaction,
    Tournament,
    Notification,
    AviatorIcon,
    AviatorText,
    Sport,
    New,
    PresentRed,
};

export default image;
