/* eslint-disable max-len */

import { Icon } from 'types';

function Trash({ color = '#FFFFFF', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
            <path
                d="M9 10.8125C9.26522 10.8125 9.51957 10.8969 9.70711 11.0471C9.89464 11.1974 10 11.4012 10 11.6136V21.2273C10 21.4397 9.89464 21.6435 9.70711 21.7938C9.51957 21.944 9.26522 22.0284 9 22.0284C8.73478 22.0284 8.48043 21.944 8.29289 21.7938C8.10536 21.6435 8 21.4397 8 21.2273V11.6136C8 11.4012 8.10536 11.1974 8.29289 11.0471C8.48043 10.8969 8.73478 10.8125 9 10.8125ZM14 10.8125C14.2652 10.8125 14.5196 10.8969 14.7071 11.0471C14.8946 11.1974 15 11.4012 15 11.6136V21.2273C15 21.4397 14.8946 21.6435 14.7071 21.7938C14.5196 21.944 14.2652 22.0284 14 22.0284C13.7348 22.0284 13.4804 21.944 13.2929 21.7938C13.1054 21.6435 13 21.4397 13 21.2273V11.6136C13 11.4012 13.1054 11.1974 13.2929 11.0471C13.4804 10.8969 13.7348 10.8125 14 10.8125ZM20 11.6136C20 11.4012 19.8946 11.1974 19.7071 11.0471C19.5196 10.8969 19.2652 10.8125 19 10.8125C18.7348 10.8125 18.4804 10.8969 18.2929 11.0471C18.1054 11.1974 18 11.4012 18 11.6136V21.2273C18 21.4397 18.1054 21.6435 18.2929 21.7938C18.4804 21.944 18.7348 22.0284 19 22.0284C19.2652 22.0284 19.5196 21.944 19.7071 21.7938C19.8946 21.6435 20 21.4397 20 21.2273V11.6136Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27 6.80682C27 7.23177 26.7893 7.63931 26.4142 7.9398C26.0391 8.24028 25.5304 8.40909 25 8.40909H24V22.8295C24 23.6794 23.5786 24.4945 22.8284 25.0955C22.0783 25.6965 21.0609 26.0341 20 26.0341H8C6.93913 26.0341 5.92172 25.6965 5.17157 25.0955C4.42143 24.4945 4 23.6794 4 22.8295V8.40909H3C2.46957 8.40909 1.96086 8.24028 1.58579 7.9398C1.21071 7.63931 1 7.23177 1 6.80682V5.20455C1 4.7796 1.21071 4.37205 1.58579 4.07157C1.96086 3.77108 2.46957 3.60227 3 3.60227H10C10 3.17732 10.2107 2.76978 10.5858 2.46929C10.9609 2.16881 11.4696 2 12 2H16C16.5304 2 17.0391 2.16881 17.4142 2.46929C17.7893 2.76978 18 3.17732 18 3.60227H25C25.5304 3.60227 26.0391 3.77108 26.4142 4.07157C26.7893 4.37205 27 4.7796 27 5.20455V6.80682ZM6.236 8.40909L6 8.50362V22.8295C6 23.2545 6.21071 23.662 6.58579 23.9625C6.96086 24.263 7.46957 24.4318 8 24.4318H20C20.5304 24.4318 21.0391 24.263 21.4142 23.9625C21.7893 23.662 22 23.2545 22 22.8295V8.50362L21.764 8.40909H6.236ZM3 6.80682V5.20455H25V6.80682H3Z"
                fill={color}
            />
        </svg>
    );
}

export default Trash;
