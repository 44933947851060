import Input from '../../../../../../../component/Input/Input';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { translate as t } from '../../../../../../../service/Language';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import AmountFormService, { AnindaHavaleErrorTip, AnindaHavaleInputTip } from '../../service/AmountForm';
import Submit from '../Button/Submit';
import { createRef } from 'react';
import { InstapayErrorTip } from '../../../Instapay/service/AmountForm';
import Back from '../../../Instapay/Form/Amount/image/back.png';

import './Amount.scss';
import Attention from '../../../Apay/Attention/Attention';


type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {};

class Amount extends RxComponent<AmountProps, AmountState> {
    state = {
        errorType: AnindaHavaleInputTip.NONE,
        errorLetters: AnindaHavaleErrorTip.NONE,
    };

    handler = {
        onErrorType: this.onErrorType.bind(this),
        onErrorLetters: this.errorLetters.bind(this),
        clickOutside: this.clickOutside.bind(this),
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    componentDidMount() {
        const {paymentMethod} = this.props;

        AmountFormService.setMethod(paymentMethod);
        this.subscribe(AmountFormService.errorType, { next: this.handler.onErrorType });
        this.subscribe(AmountFormService.errorLetters, { next: this.handler.onErrorLetters });
        document.addEventListener('pointerdown', this.handler.clickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    close() {
        this.setState({errorLetters: InstapayErrorTip.NONE});
    }

    clickOutside(event: PointerEvent) {
        if (this.ref.self.current && !this.ref.self.current.contains(event.target as Node)) {
            this.close();
        }
    }

    onErrorType(errorType: AnindaHavaleInputTip) {
        this.setState({errorType});
    };

    errorLetters(errorLetters: AnindaHavaleErrorTip) {
        this.setState({errorLetters});
    }

    render() {
        const { lock } = this.props;
        const { errorType, errorLetters } = this.state;

        return (
            <div className="deposit-amount-aninda">
                <form className="deposit-amount-aninda-form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    <div className="deposit-amount-aninda-form-block">
                        <Input
                            className="deposit-amount-aninda-form-block__input"
                            inputService={AmountFormService.name}
                            name="name"
                            enabled={!lock}
                        />

                        {errorType === AnindaHavaleInputTip.NAME && (
                            <span className="deposit-amount-aninda-form__help">
                                {t('popup.payment.name.hint')}
                            </span>
                        )}
                        {errorLetters === AnindaHavaleErrorTip.NAME ?
                            <div className="deposit-amount-aninda-form-letters" ref={this.ref.self}>
                                <img className="deposit-amount-aninda-form-letters__img" src={Back} alt="back"/>
                                <span
                                    className="deposit-amount-aninda-form-letters__text">{t('popup.payment.turkey.error.letters')}</span>
                            </div> : null}
                    </div>
                    <div className="deposit-amount-aninda-form-block">
                        <Input
                            className="deposit-amount-aninda-form-block__input"
                            inputService={AmountFormService.surname}
                            name="surname"
                            enabled={!lock}
                        />

                        {errorType === AnindaHavaleInputTip.SURNAME && (
                            <span className="deposit-amount-aninda-form__help">
                                {t('profile.balance.deposit.input.help.surname')}
                            </span>
                        )}
                        {errorLetters === AnindaHavaleErrorTip.SURNAME ?
                            <div className="deposit-amount-aninda-form-letters" ref={this.ref.self}>
                                <img className="deposit-amount-aninda-form-letters__img" src={Back} alt="back"/>
                                <span
                                    className="deposit-amount-aninda-form-letters__text">{t('popup.payment.turkey.error.letters')}</span>
                            </div> : null}
                    </div>
                </form>
                <div className="deposit-amount-aninda-check">
                    <Attention title="popup.payment.attention" type="attention" />
                </div>
                <div className="deposit-amount-aninda__submit">
                    <Submit />
                </div>
            </div>
        );
    }
}

export default Amount;
