import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
// import { IntercomProvider } from 'react-use-intercom';

import Casino from './app/Casino/Casino';

ReactDOM.render(
    <StrictMode>
        {/* <IntercomProvider appId={process.env.INTERCOM_APP_ID || 'c34b4d32'}> */}
        <Casino />
        {/* </IntercomProvider> */}
    </StrictMode>,
    document.getElementById('root'),
);
