/* eslint-disable max-len */

import { Icon } from 'types';

function InfoIcon({ color = '#3F4250', width = 20, height = 20 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99972 1C8.97405 1 8.14258 1.83147 8.14258 2.85714C8.14258 3.88281 8.97405 4.71429 9.99972 4.71429C11.0254 4.71429 11.8569 3.88281 11.8569 2.85714C11.8569 1.83147 11.0254 1 9.99972 1ZM8.99972 2.85714C8.99972 2.30486 9.44744 1.85714 9.99972 1.85714C10.552 1.85714 10.9997 2.30486 10.9997 2.85714C10.9997 3.40943 10.552 3.85714 9.99972 3.85714C9.44744 3.85714 8.99972 3.40943 8.99972 2.85714Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99972 6C8.97405 6 8.14258 6.83147 8.14258 7.85714V16.4286C8.14258 17.4542 8.97405 18.2857 9.99972 18.2857C11.0254 18.2857 11.8569 17.4542 11.8569 16.4286V7.85714C11.8569 6.83147 11.0254 6 9.99972 6ZM8.99972 7.85714C8.99972 7.30486 9.44744 6.85714 9.99972 6.85714C10.552 6.85714 10.9997 7.30486 10.9997 7.85714V16.4286C10.9997 16.9809 10.552 17.4286 9.99972 17.4286C9.44744 17.4286 8.99972 16.9809 8.99972 16.4286V7.85714Z"
                fill={color}
            />
        </svg>
    );
}

export default InfoIcon;
