import { HistoryService } from 'service/HistoryService';
import SvgImage from 'svg';

import './Close.scss';

function Close() {
    const onClose = () => {
        HistoryService.clearHash();
    };

    return (
        <button onClick={onClose} className="log-in-close">
            <SvgImage name="HeaderBigCross" />
        </button>
    );
}
export default Close;
