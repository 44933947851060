import { formatCurrencySign } from 'utils/format';

import Input, { InputSize } from '../../../../../../../component/Input/Input';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { translate as t } from '../../../../../../../service/Language';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import PaykassmaDepositService, { DepositState } from '../../../Paykassma/service/PaykassmaDeposit';
import Attention from '../../Attention/Attention';
import SumSelector from '../../SummSelector/SumSelector';
import Submit from '../Button/Submit';
import AmountFormService, { EasypaisaInputTip } from '../service/AmountForm';
import DisabledAmount from './DisabledAmount/DisabledAmount';

import './Amount.scss';

type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {
    errorTip: EasypaisaInputTip;
};

class Amount extends RxComponent<AmountProps, AmountState> {
    state = {
        errorTip: EasypaisaInputTip.NONE,
    };

    handler = {
        onErrorTip: this.onErrorTip.bind(this),
    };

    onErrorTip(errorTip: EasypaisaInputTip) {
        this.setState({ errorTip });
    }

    componentDidMount() {
        const { paymentMethod } = this.props;

        AmountFormService.reset();
        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setValue(paymentMethod.defaultValue);
        this.subscribe(AmountFormService.errorTip, { next: this.handler.onErrorTip });
    }

    render() {
        const { paymentMethod, lock } = this.props;
        const { errorTip } = this.state;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        const amount = PaykassmaDepositService.state.getValue() === DepositState.AMOUNT;

        return (
            <div className="deposit-amount-easypaisa">
                <form className="deposit-amount-easypaisa-form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    {amount ? (
                        <>
                            <div className="deposit-amount-easypaisa-form__container">
                                <MaskedInput
                                    mask="00000000000"
                                    inputService={AmountFormService.amount}
                                    className="deposit-amount-easypaisa-form__container_input"
                                    name="amount"
                                    hint={hint}
                                    enabled={!lock}
                                />
                                <span className="deposit-amount-easypaisa-form__container_currency">
                                {paymentMethod.currency.sign}
                            </span>
                            </div>
                            <div className="deposit-amount-easypaisa__sum">
                                <SumSelector paymentMethod={paymentMethod} disabled={lock}/>
                            </div>
                        </>
                    ) : (
                        <DisabledAmount hint={hint} />
                    )}
                    <div>
                        <Input
                            className="deposit-amount-easypaisa-form__container_input"
                            inputService={AmountFormService.email}
                            name="surname"
                            enabled={!lock}
                        />
                        {errorTip === EasypaisaInputTip.EMAIL && (
                            <span className="deposit-amount-easypaisa-form__container_tip">
                                {t('popup.payment.easypaisa.email.hint')}
                            </span>
                        )}
                    </div>
                    <div className="deposit-amount-easypaisa__input_wrapper">
                        <MaskedInput
                            mask="00000000000"
                            inputService={AmountFormService.account}
                            name="wallet"
                            size={InputSize.medium}
                        />
                        {errorTip === EasypaisaInputTip.ACCOUNT && (
                            <span className="deposit-amount-easypaisa-form__container_tip">
                                {t('popup.payment.easypaisa.wallet.hint')}
                            </span>
                        )}
                    </div>
                    <Attention title="popup.payment.easypaisa.attention" type="attention" />
                    <Submit />
                </form>
            </div>
        );
    }
}

export default Amount;
