import Input, { InputSize } from "component/Input/Input";
import RxComponent from "component/RxComponent/RxComponent";
import { translate as t } from "service/Language";
import { formatCurrencySign } from "utils/format";
import _paymentMethod from "../../../../PaymentMethod/methods";

import { PaymentMethod } from "../../../../PaymentMethod/types";
import FeeNotice from "../../../FeeNotice/FeeNotice";
import WithdrawCloseButton from "../../../WithdrawCloseButton/WithdrawCloseButton";
import MaskedInput from "../../../../../../../component/Input/MaskedInput";
import Attention from "../../../../../../../assets/svg/attention.svg";

import PaykassmaWithdrawService, {
  PaykassmaWithdrawState,
} from "../../service/PaykassmaWithdraw";
import UpayFormService, { AmountError, UpayInputTip } from "./service/UpayForm";
import WithdrawalButtonUpay from "./WithdrawalButtonUpay/WithdrawalButtonUpay";
import Balance from "../../../Balance/Balance";
import {
  BANK_LIST_UPAY,
  BankType,
} from "../../../../../../../service/model/BankUpay";
import SelectWithSearch from "../../../../../../../component/SelectWithSearch/SelectWithSearch";
import WithdrawService from "../../../service/Withdrawal";
import AuthService from '../../../../../../../service/Auth';

import "./Upay.scss";

type UpayProps = {
  paymentMethod: PaymentMethod;
  status: PaykassmaWithdrawState;
};
type UpayState = {
  value: string;
  amountLimit: AmountError;
  tip: UpayInputTip;
  errorAccount: string | null;
  errorName: string | null;
  completed: boolean;
  errorAmount: string | null;
  currentBank: BankType | null;
};

class Upay extends RxComponent<UpayProps, UpayState> {
  state = {
    value: "",
    tip: UpayInputTip.NONE,
    amountLimit: AmountError.NONE,
    errorAccount: null,
    errorName: null,
    completed: false,
    errorAmount: null,
    currentBank: null,
  };

  handler = {
    onTip: this.onTip.bind(this),
    onErrorAmount: this.onErrorAmount.bind(this),
    getValue: this.getValue.bind(this),
    onErrorAccount: this.onErrorAccount.bind(this),
    onErrorName: this.onErrorName.bind(this),
    onAutoCompleted: this.onAutoCompleted.bind(this),
    onAmountLimit: this.onAmountLimit.bind(this),
    setBank: this.setBank.bind(this),
  };

  setBank(currentBank: BankType) {
    this.setState({ currentBank });
  }

  onErrorAmount(errorAmount: string | null) {
    this.setState({ errorAmount });
  }

  onAmountLimit(amountLimit: AmountError) {
    this.setState({ amountLimit });
  }

  onTip(tip: UpayInputTip) {
    this.setState({ tip });
  }

  onErrorAccount(errorAccount: string | null) {
    this.setState({ errorAccount });
  }

  onErrorName(errorName: string | null) {
    this.setState({ errorName });
  }

  onAutoCompleted(completed: boolean) {
    this.setState({ completed });
  }

  getValue(value: string) {
    this.setState({ value });
  }

  onSelect(bankOption: BankType) {
    UpayFormService.onSelectBank(bankOption);
  }

  componentDidMount() {
    const { paymentMethod } = this.props;

    UpayFormService.setPaymentMethod(paymentMethod);
    this.subscribe(UpayFormService.amount.value, {
      next: this.handler.getValue,
    });
    this.subscribe(UpayFormService.amount.error, {
      next: this.handler.onErrorAmount,
    });
    this.subscribe(UpayFormService.tip, { next: this.handler.onTip });
    this.subscribe(UpayFormService.accountNumber.error, {
      next: this.handler.onErrorAccount,
    });
    this.subscribe(UpayFormService.name.error, {
      next: this.handler.onErrorName,
    });
    this.subscribe(UpayFormService.amountLimit, {
      next: this.handler.onAmountLimit,
    });
    // @ts-ignore
    this.subscribe(UpayFormService.bank, { next: this.handler.setBank });
  }

  render() {
    const { paymentMethod, status } = this.props;
    const {
      amountLimit,
      value,
      tip,
      errorAccount,
      errorName,
      errorAmount,
      currentBank,
    } = this.state;

    const min = formatCurrencySign(
      paymentMethod.limit.withdraw.min,
      0,
      paymentMethod.currency
    );
    const max = formatCurrencySign(
      paymentMethod.limit.withdraw.max,
      0,
      paymentMethod.currency
    );

    const hint = `${t("popup.payment.amount.min")} ${min} — ${t(
      "popup.payment.amount.max"
    )} ${max}`;

    const fee = WithdrawService.fee.getValue();

    const isInit = status === PaykassmaWithdrawState.INIT;
    if (fee && isInit) {
      PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.HAS_FEE);
    }
    const amountFee = formatCurrencySign(
      paymentMethod.limit.withdraw.min * 1.25,
      0,
      paymentMethod.currency
    );
    const allFeeText = `${amountFee} ${t("popup.payment.min.fee")}`;

    const isBDT = AuthService.auth.getValue()!.currencyCode === 50 ;

    return (
      <div className="upay-withdrawal">
        <div className="upay-withdrawal__header">
          <img src={paymentMethod.icon} alt={paymentMethod.slug} />
          <h2 className="upay-withdrawal__header_title">
            {t(paymentMethod.title)}
          </h2>
        </div>
        <Balance />
        <form
          className="upay-withdrawal-form"
          onSubmit={(e) => UpayFormService.onSubmit(e)}
        >
          <div className="upay-withdrawal-form-block">
            <Input
              className="upay-withdrawal-form-block__input"
              inputService={UpayFormService.amount}
              name="amount"
              type="number"
            />
            <span className="upay-withdrawal-form-block__currency">
              {paymentMethod.currency.sign}
            </span>
            {fee || !!errorAmount ? (
              <div className="upay-withdrawal-form-block__error">
                {amountLimit ? <img src={Attention} alt="attention" /> : null}
                <span className="upay-withdrawal-form-block__error_text">
                  {!fee && amountLimit && amountLimit === AmountError.AMOUNT_MIN
                    ? t("popup.payment.amount.min") + ` ${min}`
                    : null}
                  {fee && amountLimit === AmountError.AMOUNT_FEE
                    ? allFeeText
                    : null}
                  {amountLimit && amountLimit === AmountError.AMOUNT_MAX
                    ? t("popup.payment.amount.max") + ` ${max}`
                    : null}
                  {amountLimit && amountLimit === AmountError.AMOUNT_WITHDRAWAL
                    ? t("popup.payment.amount.withdrawal.max")
                    : null}
                </span>
              </div>
            ) : null}
            {tip === UpayInputTip.AMOUNT && !amountLimit ? (
              <span className="upay-withdrawal-form-block__tip">{hint}</span>
            ) : null}
          </div>
          <div className="upay-withdrawal-form-block">
            <Input
              className="upay-withdrawal-form-block__input"
              inputService={UpayFormService.name}
              name="name"
              size={InputSize.medium}
            />
            {tip === UpayInputTip.NAME && !errorName ? (
              <span className="upay-withdrawal-form-block__tip">
                {t("popup.balance.deposit.input.name")}
              </span>
            ) : null}
            {!!errorName && (
              <div className="upay-withdrawal-form-block__error">
                <img src={Attention} alt="attention" />
                <span className="upay-withdrawal-form-block__error_text">
                  {t("popup.balance.deposit.input.name")}
                </span>
              </div>
            )}
          </div>
          <div className="upay-withdrawal-form-block">
            <MaskedInput
              className="upay-withdrawal-form-block__input"
              mask="00000000000000000000"
              placeholder="00000000000000000000"
              inputService={UpayFormService.accountNumber}
              name="account"
            />

            {tip === UpayInputTip.ACCOUNT && !errorAccount && (
              <span className="upay-withdrawal-form-block__tip">
                {t("popup.payment.upay.account.hint.validation")}
              </span>
            )}

            {!!errorAccount && (
              <div className="upay-withdrawal-form-block__error">
                <img src={Attention} alt="attention" />
                <span className="upay-withdrawal-form-block__error_text">
                  {t("popup.payment.upay.account.hint.validation")}
                </span>
              </div>
            )}
          </div>
          <div className="upay-withdrawal-form-block">
            <SelectWithSearch
              onSelect={this.onSelect}
              list={BANK_LIST_UPAY}
              current={currentBank}
              placeholder="popup.payment.upay.bank"
            />
          </div>
          {(status === PaykassmaWithdrawState.HAS_FEE ||
            status === PaykassmaWithdrawState.AWAIT) &&
          fee ? (
            <FeeNotice amount={value} fee={fee} />
          ) : null}
          {/*<AttentionWithdrawalTime />*/}
          {status === PaykassmaWithdrawState.HAS_FEE ? (
            <>
              <WithdrawalButtonUpay
                buttonTitle="profile.balance.withdraw.fee.agree"
                variation={true}
                paymentMethod={paymentMethod}
                amount={value}
                state={status}
              />
              <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel" />
            </>
          ) : (
            <WithdrawalButtonUpay
              buttonTitle="profile.balance.withdraw.button"
              paymentMethod={_paymentMethod.Upay}
              state={status}
            />
          )}
        </form>
      </div>
    );
  }
}

export default Upay;
