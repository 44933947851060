import Validator from '../interface/Validator';

class WalletEasypaisa implements Validator {
    validate(value: string): boolean {
        return value.length > 7;
    }
}
const WalletEasypaisaService = new WalletEasypaisa();

export { WalletEasypaisaService };

export default WalletEasypaisa;
