import Input, { InputSize } from 'component/Input/Input';
import RxComponent from 'component/RxComponent/RxComponent';
import { translate as t } from 'service/Language';
import { formatCurrencySign } from 'utils/format';
import PixFormService, { Directa24InputTip } from '../../service/PixForm';
import Directa24WithdrawService, { Directa24WithdrawState } from '../../service/Directa24Withdraw';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import WithdrawalButton from '../../WithdrawalButton/WithdrawalButton';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import Attention from '../../../../../../../assets/svg/attention.svg';
import Back from './image/back.png';
import { AmountError, PixErrorTip } from '../../../../Deposit/Directa24/Pix/service/AmountForm';
import { createRef } from 'react';
import WithdrawService from '../../../service/Withdrawal';

import './Pix.scss';


type PixProps = {
    paymentMethod: PaymentMethod;
};
type PixState = {
    value: string;
    amountLimit: AmountError,
    errorLetters: PixErrorTip,
    tip: Directa24InputTip;
    errorCPF: string | null,
    errorName: string | null,
    completed: boolean,
    errorAmount: string | null,
};

class Pix extends RxComponent<PixProps, PixState> {
    state = {
        value: '',
        tip: Directa24InputTip.NONE,
        amountLimit: AmountError.NONE,
        errorLetters: PixErrorTip.NONE,
        errorCPF: null,
        errorName: null,
        completed: false,
        errorAmount: null,
    };

    handler = {
        onTip: this.onTip.bind(this),
        onErrorAmount: this.onErrorAmount.bind(this),
        onErrorLetters: this.ErrorLetters.bind(this),
        getValue: this.getValue.bind(this),
        onErrorCPF: this.onErrorCPF.bind(this),
        onErrorName: this.onErrorName.bind(this),
        onAutoCompleted: this.onAutoCompleted.bind(this),
        onAmountLimit: this.onAmountLimit.bind(this),
        clickOutside: this.clickOutside.bind(this),
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    close() {
        this.setState({errorLetters: PixErrorTip.NONE});
    }

    clickOutside(event: PointerEvent) {
        if (this.ref.self.current && !this.ref.self.current.contains(event.target as Node)) {
            this.close();
        }
    }
    componentWillUnmount() {
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }
    onErrorAmount(errorAmount: string | null) {
        this.setState({errorAmount});
    }

    onAmountLimit(amountLimit: AmountError) {
        this.setState({amountLimit});
    }

    onTip(tip: Directa24InputTip) {
        this.setState({ tip });
    }

    ErrorLetters(errorLetters: PixErrorTip) {
        this.setState({errorLetters});
    }

    onErrorCPF(errorCPF: string | null) {
        this.setState({errorCPF});
    }

    onErrorName(errorName: string | null) {
        this.setState({errorName});
    }

    onAutoCompleted(completed: boolean) {
        this.setState({completed});
    }

    getValue(value: string) {
        this.setState({value});
    }

    componentDidMount() {
        const {paymentMethod} = this.props;

        PixFormService.setPaymentMethod(paymentMethod);
        this.subscribe(PixFormService.amount.value, {next: this.handler.getValue});
        this.subscribe(PixFormService.amount.error, {next: this.handler.onErrorAmount});
        this.subscribe(PixFormService.tip, {next: this.handler.onTip});
        this.subscribe(PixFormService.errorLetters, {next: this.handler.onErrorLetters});
        this.subscribe(PixFormService.numberCPF.error, {next: this.handler.onErrorCPF});
        this.subscribe(PixFormService.name.error, {next: this.handler.onErrorName});
        this.subscribe(PixFormService.amountLimit, {next: this.handler.onAmountLimit});
        document.addEventListener('pointerdown', this.handler.clickOutside);
    }

    render() {
        const { paymentMethod } = this.props;
        const { amountLimit, errorLetters, value, tip, errorCPF, errorName, errorAmount } = this.state;

        const status = Directa24WithdrawService.state.getValue();

        const min = formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency);
        const max = formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency);

        const hint = `${t('popup.payment.amount.min')} ${min} — ${t('popup.payment.amount.max')} ${max}`;

        const fee = WithdrawService.fee.getValue();

        const isInit = status === Directa24WithdrawState.INIT;
        if (fee && isInit) {
            Directa24WithdrawService.state.next(Directa24WithdrawState.HAS_FEE)
        }
        const minFee = Math.ceil(paymentMethod.limit.withdraw.min * 1.25)
        const amountFee = formatCurrencySign(minFee, 0, paymentMethod.currency);
        const allFeeText = `${amountFee} ${t('popup.payment.min.fee')}`;

        return (
            <div className="pix-withdrawal">
                <div className="pix-withdrawal__header">
                    <img src={paymentMethod.icon} alt={paymentMethod.slug}/>
                    <h2 className="pix-withdrawal__header_title">{t(paymentMethod.title)}</h2>
                </div>
                <form className="pix-withdrawal-form" onSubmit={(e) => PixFormService.onSubmit(e)}>
                    <div className="pix-withdrawal-form-block">
                        <Input
                            className="pix-withdrawal-form-block__input"
                            inputService={PixFormService.amount}
                            name="amount"
                            type="number"
                        />
                        <span className="pix-withdrawal-form-block__currency">{paymentMethod.currency.sign}</span>
                        {!!errorAmount ? (
                            <div className="pix-withdrawal-form-block__error">
                                <img src={Attention} alt="attention"/>
                                <span className="pix-withdrawal-form-block__error_text">
                                    {amountLimit && amountLimit === AmountError.AMOUNT_MIN ? t('popup.payment.amount.min') + ` ${min}` : null}
                                    {fee && amountLimit === AmountError.AMOUNT_FEE ? allFeeText : null}
                                    {amountLimit && amountLimit === AmountError.AMOUNT_MAX ? t('popup.payment.amount.max') + ` ${max}` : null}
                                    {amountLimit && amountLimit === AmountError.AMOUNT_WITHDRAWAL ? t('popup.payment.amount.withdrawal.max') : null}
                                    </span>
                            </div>
                        ) : null}
                        {tip === Directa24InputTip.AMOUNT && !errorAmount ? (
                            <span className="pix-withdrawal-form-block__tip">
                                {hint}
                            </span>
                        ) : null}
                    </div>
                    <div className="pix-withdrawal-form-block">
                        <MaskedInput
                            className="pix-withdrawal-form-block__input"
                            mask="000.000.000-00"
                            placeholder="000.000.000-00"
                            inputService={PixFormService.numberCPF}
                            name="wallet"
                            size={InputSize.medium}
                        />
                        {tip === Directa24InputTip.CPF && !errorCPF && (
                            <span className="pix-withdrawal-form-block__tip">
                                {t('popup.payment.easypaisa.account.error')}
                            </span>
                        )}
                        {!!errorCPF && (
                            <div className="pix-withdrawal-form-block__error">
                                <img src={Attention} alt="attention"/>
                                <span className="pix-withdrawal-form-block__error_text">
                        {t('popup.payment.pix.cpf.error')}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="pix-withdrawal-form-block">
                        <Input
                            className="pix-withdrawal-form-block__input"
                            inputService={PixFormService.name}
                            name="name"
                            size={InputSize.medium}
                        />
                        {tip === Directa24InputTip.NAME && !errorName ? (
                            <span className="pix-withdrawal-form-block__tip">
                                {t('popup.balance.deposit.input.name')}
                            </span>
                        ) : null}
                        {!!errorName && (
                            <div className="pix-withdrawal-form-block__error">
                                <img src={Attention} alt="attention"/>
                                <span className="pix-withdrawal-form-block__error_text">
                        {t('popup.balance.deposit.input.name')}
                                </span>
                            </div>
                        )}
                        {
                            errorLetters === PixErrorTip.NAME ?
                                <div className="pix-withdrawal-form-block-letters" ref={this.ref.self}>
                                    <img className="pix-withdrawal-form-block-letters__img" src={Back} alt="back"/>
                                    <span
                                        className="pix-withdrawal-form-block-letters__text">{t('popup.payment.brazil.error.letters')}</span>
                                </div>
                                : null
                        }
                    </div>
                    {((status === Directa24WithdrawState.HAS_FEE || status === Directa24WithdrawState.AWAIT) && fee ) ?
                        <FeeNotice amount={value} fee={fee} /> : null}
                    {status === Directa24WithdrawState.HAS_FEE ? (
                        <>
                            <WithdrawalButton
                                buttonTitle="profile.balance.withdraw.fee.agree"
                                variation={true}
                                paymentMethod={paymentMethod}
                                amount={value}
                            />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel"/>
                        </>
                    ) : (
                        <WithdrawalButton
                            buttonTitle="profile.balance.withdraw.button"
                            paymentMethod={paymentMethod}
                        />
                    )}
                </form>
            </div>
        );
    }
}

export default Pix;
