import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import question from '../../../../../Deposit/Paykassma/Other/Transaction/image/tip.svg';

import './WithdrawalTip.scss';

type WithdrawalTipProps = {
    type: 'swift' | 'account' | 'iban';
    up?: boolean;
};

function WithdrawalTip({ type, up }: WithdrawalTipProps) {
    const [expanded, setExpanded] = useState(false);
    const expandedList = useRef<HTMLButtonElement>(null);

    function clickOutside(event: PointerEvent) {
        if (expandedList.current && !expandedList.current.contains(event.target as Node) && !expanded) {
            setExpanded(false);
        }
    }

    function clickExpanded() {
        setExpanded(!expanded);
    }

    useEffect(() => {
        document.addEventListener('pointerdown', clickOutside, false);

        return () => {
            document.removeEventListener('pointerdown', clickOutside, false);
        };
    }, []);

    const buttonClassName = classnames({
        'withdrawal-tip': true,
        'withdrawal-tip__active': expanded,
    });
    const tipsClassName = classnames({
        'withdrawal-tip-img': true,
        'withdrawal-tip-img__swift': type === 'swift',
        'withdrawal-tip-img__swift_up': type === 'swift' && up,
        'withdrawal-tip-img__account': type === 'account',
        'withdrawal-tip-img__account_up': type === 'account' && up,
        'withdrawal-tip-img__iban': type === 'iban',
        'withdrawal-tip-img__iban_up': type === 'iban' && up,
    });

    return (
        <div>
            <button ref={expandedList} type="button" className={buttonClassName} onClick={clickExpanded}>
                <img src={question} alt="" />
            </button>
            {expanded && <div className={tipsClassName} />}
        </div>
    );
}

export default WithdrawalTip;
