import classNames from 'classnames';
import IMask from 'imask';
import { useEffect } from 'react';

import Input from '../../../../../component/Input/Input';
import MaskedInput from '../../../../../component/Input/MaskedInput';
import InputService from '../../../../../component/Input/service/Input';
import PersonalDataFormService from '../service/PersonalDataForm';

import './PersonalDetailsField.scss';

type PersonalDetailsFieldProps = {
    title: string;
    disabled?: boolean;
    placeholder?: string;
    error?: string | null;
    type?: string;
    mask?: string;
    birthDate?: Date | null;
    inputService: InputService;
};

function PersonalDetailsField(props: PersonalDetailsFieldProps) {
    const { title, disabled, error = '', type, mask, inputService } = props;
    const className = classNames('personal-details-field', { 'personal-details-field____error': !!error });

    useEffect(() => {
        PersonalDataFormService.birthDate.error.next(null);
    }, []);

    return (
        <div className={className}>
            <span className="personal-details-field__title">{error ? error : title}</span>
            <div className="personal-details-field__box">
                {mask ? (
                    <MaskedInput
                        className="personal-details-field"
                        inputService={PersonalDataFormService.birthDate}
                        mask={mask}
                        blocks={{
                            d: {
                                mask: IMask.MaskedRange,
                                from: 1,
                                to: 31,
                                maxLength: 2,
                            },
                            m: {
                                mask: IMask.MaskedRange,
                                from: 1,
                                to: 12,
                                maxLength: 2,
                            },
                            y: {
                                mask: IMask.MaskedRange,
                                from: 1900,
                                to: 2022,
                            },
                        }}
                    />
                ) : (
                    <Input type={type} inputService={inputService} enabled={!disabled} />
                )}
            </div>
        </div>
    );
}

export default PersonalDetailsField;
