import Validator from '../interface/Validator';

class FourthNomber implements Validator {
    validate(value: string): boolean {
        return new RegExp('^[0]+$').test(value[5]);
    }
}
const FirstNotZeroNumberService = new FourthNomber();

export { FirstNotZeroNumberService };
export default FourthNomber;
