import { BehaviorSubject } from 'rxjs';

type Winner = {
    name: string;
    amount: number;
};

type FakeJackpotType = {
    amount: number;
    remainingTime: number;
    winnerList: Winner[];
};

interface FakeJackpotActions {
    getFakeJackpot: () => void;
}

const winnerList: Winner[] = [];

const emptyJackpot = {
    amount: 0,
    dueDate: new Date(),
    winnerList,
};

const EmptyFakeJackpotActions: FakeJackpotActions = {
    getFakeJackpot: () => {},
};

class FakeJackpot {
    jackpot = new BehaviorSubject(emptyJackpot);

    actions: FakeJackpotActions = EmptyFakeJackpotActions;

    setActions(actions: FakeJackpotActions) {
        this.actions = actions;
    }

    onReceive(fakeJackpot: FakeJackpotType) {
        const dueDate = new Date(new Date().getTime() + fakeJackpot.remainingTime * 1000);
        const jackpot = {
            amount: fakeJackpot.amount,
            dueDate,
            winnerList: fakeJackpot.winnerList,
        };
        this.jackpot.next(jackpot);
    }
}

const FakeJackpotService = new FakeJackpot();

export default FakeJackpotService;
