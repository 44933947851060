import { PaykassmaBkashP2CDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaBkashP2CDepositRequest';
import { PaykassmaDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaDepositRequest';
import { PaykassmaEasypaisaDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaEasypaisaDepositRequest';
import { PaykassmaInvoiceRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaInvoiceRequest';
import { PaykassmaNagadP2CDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaNagadP2CDepositRequest';
import { PaykassmaPayment } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaPaymentCheck';
import { PaykassmaWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaWithdraw';
import {
    PaykassmaCheckCode,
    PaykassmaCheckResponse,
    PaykassmaDepositResponse,
    PaykassmaInvoiceCode,
    PaykassmaInvoiceResponse,
    PaykassmaWithdrawCode,
    PaykassmaWithdrawResponse,
} from '@ay_tsarbet/newbet-core/dist/types';
import PaykassmaDepositService from 'page/Profile/Balance/Deposit/Paykassma/service/PaykassmaDeposit';
import { Subject } from 'rxjs';

import PaykassmaInterface from '../interface/payment/Paykassma';
import PaykassmaInvoice from '../model/payment/PaykassmaInvoice';
import WSBet from '../WSBet';
import StatusFormPopupService, { StatusForm } from '../../page/Profile/Balance/Deposit/DepositPopup/service/StatusForm';
import {ApayBDTDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendApayBDTDepositRequest';

enum PaykassmaError {
    UNKNOWN,
    WITHDRAW,
}
enum InvoiceStatusCheck {
    SUCCESS,
    FAIL,
}

class Paykassma implements PaykassmaInterface {
    invoice = new Subject<PaykassmaInvoice>();

    error = new Subject<PaykassmaError>();

    withdrawResult = new Subject<void>();

    invoiceCheckCode = new Subject<InvoiceStatusCheck>();

    byCard(request: PaykassmaDepositRequest) {
        WSBet.payment.paykassma.byCard(request);
    }

    depositNagadP2C(request: PaykassmaNagadP2CDepositRequest) {
        console.log('request', request);
        WSBet.payment.paykassma.depositNagadP2C(request);
    }

    depositBKashP2C(request: PaykassmaBkashP2CDepositRequest) {
        console.log('request', request);
        WSBet.payment.paykassma.depositBKashP2C(request);
    }

    depositEasypaisa(request: PaykassmaEasypaisaDepositRequest) {
        console.log('request', request);
        WSBet.payment.paykassma.depositEasypaisa(request);
    }
    depositApayBDTRequest(request: ApayBDTDepositRequest) {
        console.log('request', request);
        WSBet.payment.paykassma.depositApayBDTRequest(request);
    }

    createInvoice(invoiceRequest: PaykassmaInvoiceRequest) {
        console.log('createInvoice', invoiceRequest);
        WSBet.payment.paykassma.createInvoice(invoiceRequest);
    }

    checkPayment(payment: PaykassmaPayment) {
        console.log('checkPayment', payment);
        WSBet.payment.paykassma.checkPayment(payment);
    }

    withdraw(withdraw: PaykassmaWithdrawRequest) {
        console.log('withdraw', withdraw);
        WSBet.payment.paykassma.withdraw(withdraw);
    }

    onDepositResponse(response: PaykassmaDepositResponse) {
        PaykassmaDepositService.onDepositResponse(response);
    }

    onWithdraw(response: PaykassmaWithdrawResponse) {
        console.log('onWithdraw', response);
        if (response.code >= PaykassmaWithdrawCode.success) {
            this.withdrawResult.next();
            StatusFormPopupService.status.next(StatusForm.SUCCESS)
        } else {
            this.error.next(PaykassmaError.WITHDRAW);
            StatusFormPopupService.status.next(StatusForm.FAILED);
        }
    }

    onInvoiceResponse(response: PaykassmaInvoiceResponse) {
        if (response.code === PaykassmaInvoiceCode.success) {
            this.invoice.next(new PaykassmaInvoice(response.id));
            PaykassmaDepositService.setWalletType(response.walletType);
        } else {
            this.error.next(PaykassmaError.UNKNOWN);
        }
    }

    onReceiveCheckInvoice(invoiceResponse: PaykassmaCheckResponse) {
        console.log('invoiceResponse', invoiceResponse);
        this.invoiceCheckCode.next(
            invoiceResponse.code === PaykassmaCheckCode.success ? InvoiceStatusCheck.SUCCESS : InvoiceStatusCheck.FAIL
        );
    }
}

export { InvoiceStatusCheck, PaykassmaError };
export default Paykassma;
