import Landing from "app/Landing/Landing";
import BettingLayout from "layout/BettingLayout/BettingLayout";
import { Route, Switch } from "react-router-dom";

import RouteConfig from "../appConstants/RouteConfig";
import Slider from "../component/Header/Menu/Slider/Slider";
import Casino from "../page/Casino/CasinoLayout/CasinoLayout";
import ErrorPage from "../page/ErrorPage/ErrorPage";
import FakeJackpot from "../page/FakeJackpot/FakeJackpot";
import GamePage from "../page/GamePage/GamePage";
import InfoPage from "../page/Info/Info";
import LandingAPK from "../page/LandingAPK/LandingAPK";
import LandingCricket from "page/LandingCricket/LandingCricket";
import ProfilePage from "../page/Profile/Profile";
import TournamentLayout from "../page/Tournament/TournamentLayout";
import { useIsDesktop } from "../utils/useBreakpoints";

import "./Viewport.scss";


function Viewport() {
  const isDesktop = useIsDesktop();

  const rc = RouteConfig;
  const rootLand = rc.landing.root + rc.landing.wheels.root;

  return (
    <>
      {!isDesktop && <Slider />}
      <Switch>
        <Route path={rc.landing.sports.cricket} component={LandingCricket} />

        <Route path={rootLand} component={Landing} />
        <Route path="/">
          <div className="app-viewport">
            <Switch>
              <Route path={rc.betting.root} component={BettingLayout} />
              <Route path={rc.profile.root} component={ProfilePage} />
              <Route path={rc.info.root} component={InfoPage} />
              <Route path={rc.jackpot} component={FakeJackpot} />
              <Route path={rc.landing.APK} component={LandingAPK} />

              <Route path={rc.casino.gameplay} exact component={GamePage} />
              <Route path={rc.casino.tournament} component={TournamentLayout} />

              <Route path={rc.casino.providers} component={Casino} />
              <Route path={rc.casino.category} component={Casino} />

              <Route path={rc.casino.root} component={Casino} />
              <Route path={rc.casino.root} component={Casino} />

              <Route path="*" component={ErrorPage} />
            </Switch>
          </div>
        </Route>

        {/* <Redirect from="" to={RouteConfig.notFound} /> */}
      </Switch>
    </>
  );
}
export default Viewport;
