import { HistoryService } from 'service/HistoryService';
import RxComponent from '../../component/RxComponent/RxComponent';
import GoogleService from '../../service/Google';
import { translate as t } from '../../service/Language';
import { TournamentList } from '../../service/model/Tournament';
import TournamentService from '../../service/Tournament';
import LeftArrow from '../Profile/Account/image/LeftArrow.svg';
import Finished from './Finished';
import Running from './Running';

import './Tournament.scss';
import AuthService from '../../service/Auth';

type TournamentProps = {};
type TournamentState = {
    list: {
        running: TournamentList;
        finished: TournamentList;
    };
};

class Tournament extends RxComponent<TournamentProps, TournamentState> {
    state = {
        list: {
            running: [],
            finished: [],
        },
    };

    handler = {
        setList: this.setList.bind(this),
    };

    setList(list: TournamentList) {
        const running: TournamentList = [];
        const finished: TournamentList = [];

        list.forEach((tournament) => {
            if (tournament.isFinished()) {
                finished.push(tournament);
            } else {
                running.push(tournament);
            }
        });

        this.setState({
            list: {
                running,
                finished,
            },
        });
    }

    componentDidMount() {
        this.subscribe(TournamentService.list, { next: this.handler.setList });
        const auth = AuthService.auth.getValue();
        const userId = auth ? auth.userId.toString() : '';
        GoogleService.onOpenTournaments(userId);
    }

    render() {
        const { list } = this.state;

        return (
            <div className="tournament-page">
                <div className="tournament-page__header">
                    <img src={LeftArrow} alt="left-arrow" onClick={() => HistoryService.history.goBack()} />

                    <div className="tournament-page__title">{t('tournament.page.title')}</div>
                </div>
                {list.running.length ? <Running tournamentList={list.running} /> : null}
                <div className="tournament-page__title_finished">
                    {t('tournament.page.finished')}
                </div>
                {list.finished.length ? <Finished tournamentList={list.finished} /> : null}
            </div>
        );
    }
}

export default Tournament;
