import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import UserDataService from 'service/UserData';

import ChangePasswordService from '../../../../../service/ChangePassword';
import Validator from '../../../../../service/Validator/Validator';
import NewPasswordService from '../NewPasswordInput/service/NewPassword';
import OldPasswordService from '../OldPasswordInput/service/OldPassword';
import RepeatPasswordService from '../RepeatePasswordInput/service/RepeatePasswordInput';

class PasswordForm {
    public active = new BehaviorSubject<boolean>(false);

    handler = {
        onOldPasswordChange: this.checkValid.bind(this),
        onNewPasswordChange: this.checkValid.bind(this),
        onRepeatPasswordChange: this.checkValid.bind(this),
        onSubmit: this.onSubmit.bind(this),
    };

    checkValid() {
        const isEqual = Validator.equal(NewPasswordService.getValue(), RepeatPasswordService.getValue());
        const isValid = NewPasswordService.isValid() && RepeatPasswordService.isValid();
        if (isValid && isEqual) {
            NewPasswordService.error.next('');
            RepeatPasswordService.error.next('');
            this.active.next(true);
        } else if (!isEqual) {
            if (NewPasswordService.getValue().length) {
                NewPasswordService.error.next('profile.settings.password.change.error');
                this.active.next(false);
            }
            if (RepeatPasswordService.getValue().length) {
                RepeatPasswordService.error.next('profile.settings.password.change.error');
                this.active.next(false);
            }
        } else {
            this.active.next(false);
        }
    }

    reset() {
        OldPasswordService.value.next('');
        NewPasswordService.value.next('');
        RepeatPasswordService.value.next('');
        ChangePasswordService.status.next(false);
        this.active.next(false);
    }

    onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (this.active.getValue()) {
            const data = {
                oldPassword: OldPasswordService.value.getValue(),
                newPassword: NewPasswordService.value.getValue(),
            };
            const newUserData = UserDataService.data.getValue()?.copy();
            if (newUserData) {
                UserDataService.pendingData = newUserData;
            }
            ChangePasswordService.change(data);
        }
    }
}

const PasswordFormService = new PasswordForm();

export default PasswordFormService;
