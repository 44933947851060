import { BehaviorSubject } from 'rxjs';

class Balance {
    balance = new BehaviorSubject(0);

    setBalance(balance: number) {
        this.balance.next(balance);
    }
}

const BalanceService = new Balance();

export default BalanceService;
