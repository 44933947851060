import classNames from 'classnames';
import { MouseEventHandler, VFC } from 'react';

import ImageLoader from '../ImageLoader/ImageLoader';

const highlightText = (text: string, searchText: string) => {
    const parts = text.split(new RegExp(`(${searchText})`, 'gi'));
    return (
        <span>
            {parts.map((part, i) => (
                <span
                    key={i}
                    className={part.toLowerCase() === searchText.toLowerCase() ? 'search-result-item-highlight' : ''}
                >
                    {part}
                </span>
            ))}
        </span>
    );
};

interface SearchResultItemProps {
    name: string;
    imageSrc?: string;
    description: string;
    searchText: string;
    centerLogo?: boolean;
    onClick?: MouseEventHandler;
}

const SearchResultItem: VFC<SearchResultItemProps> = ({
    name,
    imageSrc,
    searchText,
    description,
    centerLogo,
    onClick,
}) => (
    <div className="search-result-item" onClick={onClick}>
        <div
            className={classNames('search-result-item-image', {
                'search-result-item-image_center-logo': centerLogo,
            })}
        >
            <ImageLoader src={imageSrc} />
        </div>
        <div className="search-result-item-info">
            <div className="search-result-item-title">{highlightText(name, searchText)}</div>
            <div className="search-result-item-description">{description}</div>
        </div>
    </div>
);

export default SearchResultItem;
