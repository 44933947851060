import { Operation } from '@ay_tsarbet/newbet-core/dist/types';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { translate as t } from '../../../../service/Language';
import NotificationService from '../../../../service/Notifications';
import OperationsService from '../../../../service/Operations';
import useObservable from '../../../../utils/useObservable';
import arrow from '../image/arrow_left_icon.svg';
import NotificationItem from '../NotificationItem/NotificationItem';

import './NotificationList.scss';

export type notifyItem = {
    type: number;
    amount: number;
    datetime: number;
    isRead: boolean;
    currency: number;
};

function NotificationList(props: any) {
    const history = useHistory();
    const infoList = useObservable(NotificationService.info, NotificationService.info.getValue());
    const addsList = useObservable(NotificationService.adds, NotificationService.adds.getValue());
    const newsList = useObservable(NotificationService.news, NotificationService.news.getValue());

    useEffect(() => {
        OperationsService.requestHistory();
    }, []);

    useEffect(() => {
        // TODO: сделать общий массив всех трех потоков уведомлений и проверять по ним
        const listIds = infoList.filter((elem) => elem.id);
        listIds.forEach((operation) => NotificationService.updateNotice(operation.id.toString()));
    }, [infoList]);

    return (
        <>
            <div onClick={() => history.goBack()} className="notification-list__header">
                <img src={arrow} alt="arrow" />
                <div className="notification-list__title">{t('notification.page.notification')}</div>
            </div>
            <div className="notification-list__list">
                {infoList
                    .sort((a, b) => b.creationDate - a.creationDate)
                    .map((item: Operation) => (
                        <NotificationItem
                            key={item.id}
                            type={item.direction}
                            amount={item.amount}
                            datetime={item.creationDate}
                            isRead={item.notice}
                            currency={item.currency}
                        />
                    ))}
            </div>
        </>
    );
}

export default NotificationList;
