import useObservable from 'utils/useObservable';

import PayfixWithdrawService, { PayfixWithdrawState } from '../service/PayfixWithdraw';

import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import FailedPage from '../FailedPage/FailedPage';
import BonusFailedPage from '../../../../../../component/BonusFailedPage/BonusFailedPage';
import TransactionTimeFailedPage from '../../../../../../component/TransactionTimeFailedPage/TransactionTimeFailedPage';

import './ResultForm.scss';

function ResultForm() {
    const state = useObservable(PayfixWithdrawService.state, PayfixWithdrawService.state.getValue());

    switch (state) {
        case PayfixWithdrawState.ACCEPT: {
            return <SuccessPage />;
        }
        case PayfixWithdrawState.REJECT: {
            return <FailedPage />;
        }
        case PayfixWithdrawState.BONUS_REJECT: {
            return <BonusFailedPage />;
        }
        case PayfixWithdrawState.TIME_REJECT: {
            return <TransactionTimeFailedPage />;
        }
        default: {
            return null;
        }
    }
}

export default ResultForm;
