import { BehaviorSubject } from 'rxjs';

import AmountFormService from './AmountForm';
import {PaymentMethod} from '../../../PaymentMethod/types';
import PaymentService from '../../../../../../service/Payment';
import StatusFormPopupService, {StatusForm} from '../../DepositPopup/service/StatusForm';

enum DepositState {
    AMOUNT,
    WAIT,
    LINK,
    QR,
    FAILED,
    SUCCESS,
}
enum PaymentType {
    NONE,
    PAYTM,
    PHONE_PEE,
    GOOGLE_PAY,
}

class ConstantposDeposit {
    amount = 0;

    link = new BehaviorSubject('');

    state = new BehaviorSubject<DepositState>(DepositState.AMOUNT);

    paymentType = new BehaviorSubject<PaymentType>(PaymentType.NONE);

    request(paymentMethod: PaymentMethod, vpa: string,  amount: number) {
        this.state.next(DepositState.WAIT);
        PaymentService.constantpos.deposit({
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
            acountNumber: vpa,
            amount,
        });
    }

    setAmount(amount: number) {
        this.amount = amount;
    }

    getAmount(): number {
        return this.amount;
    }

    reset() {
        this.amount = 0;
        this.link.next('');
        this.state.next(DepositState.AMOUNT);
        AmountFormService.reset();
    }

    onError() {
        this.state.next(DepositState.FAILED);
        StatusFormPopupService.status.next(StatusForm.FAILED);
    }

    onReceive(link: string) {
        const state = this.state.getValue();
        if (state === DepositState.WAIT) {
            StatusFormPopupService.status.next(StatusForm.SUCCESS);
            this.link.next(link);
            this.state.next(DepositState.LINK)
        } else {
            this.state.next(DepositState.FAILED);
            StatusFormPopupService.status.next(StatusForm.FAILED);
        }
    }

    back() {
        this.reset();
    }
}

const ConstantposDepositService = new ConstantposDeposit();

export { DepositState, PaymentType };
export default ConstantposDepositService;
