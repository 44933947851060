import RouteConfig from 'appConstants/RouteConfig';
import CategoryHeader from 'component/CategoryHeader';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import GamesService from 'service/Games';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';

import GoogleService from '../../../service/Google';
import GameProvider from '../../../service/model/GameProvider';
import { useIsXS } from '../../../utils/useBreakpoints';
import Provider from './Provider/Provider';

import './TopProviderList.scss';

const PROVIDERS_ON_SCREEN = 8;

function TopProviderList() {
    const providerList: GameProvider[] = useObservable(
        GamesService.allowedProviders,
        GamesService.cache.allowedProvider
    );
    const providersToDisplay = providerList.slice(0, PROVIDERS_ON_SCREEN);

    const isXS = useIsXS();

    return (
        <div className="provider-list">
            <CategoryHeader
                title={t('casino.providers.category.top')}
                linkProps={{
                    title: t(isXS ? 'casino.providers.all' : 'casino.providers.category.all'),
                    route: RouteConfig.casino.providers,
                    className: 'provider-list__arrow',
                }}
            />
            <div className="provider-list__content">
                {providersToDisplay.map((provider) => (
                    <NavLink to={`${RouteConfig.casino.providers}/${provider.route}`} key={provider.id}>
                        <Provider provider={provider} />
                    </NavLink>
                ))}
            </div>
        </div>
    );
}

export default TopProviderList;
