import Footer from 'component/Footer/Footer';
import Header from 'component/Header/Header';
import BettingPage from 'page/Profile/Betting/Betting';

import './BettingLayout.scss';

type BettingLayoutProps = {};

const BettingLayout = (props: BettingLayoutProps) => {
    const {} = props;

    return (
        <div className="BettingLayout">
            <Header />
            <BettingPage />
        </div>
    );
};

export default BettingLayout;
