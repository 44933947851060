import { SlotegratorProvider } from '@ay_tsarbet/newbet-core/dist/types';

import { PROVIDER_IMAGE_HOST, PROVIDER_IMAGE_MINI_HOST } from '../../appConstants/image';
import Game, { GameList } from './Game';

class GameProvider {
    gameList: GameList = [];

    route = '';

    hasDesktop = false;

    hasMobile = false;

    constructor(
        public id: number,
        public name: string,
        public isDemo: boolean,
        public image: string,
        public logoImage: string
    ) {}

    addGame(game: Game) {
        if (!this.gameList.includes(game)) {
            this.gameList.push(game);
        }

        game.setProvider(this);
    }

    setExistDesktop(value: boolean) {
        this.hasDesktop = value;
    }

    setExistMobile(value: boolean) {
        this.hasMobile = value;
    }

    static fromRecord(record: SlotegratorProvider): GameProvider {
        const baseName = record.name.toLowerCase().replaceAll
            ? record.name.toLowerCase().replaceAll(' ', '-')
            : record.name.toLowerCase();
        const image = `${PROVIDER_IMAGE_HOST}${baseName}.svg`;
        const logoImage = `${PROVIDER_IMAGE_MINI_HOST}${baseName}.svg`;

        const provider = new GameProvider(record.id, record.name, record.isDemo, image, logoImage);
        provider.route = baseName;

        return provider;
    }
}

const unknownProvider = new GameProvider(-1000, '', false, '', '');
export { unknownProvider };

export default GameProvider;
