import Validator from '../interface/Validator';

class Phone implements Validator {
    validate(value: string): boolean {
        const pattern = new RegExp('^\\d{7,11}$', 'gi');

        return pattern.test(value);
    }
}

export default Phone;
