import { PaymentMethod } from '../../../PaymentMethod/types';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import Upi from '../UPI/Form'
import './Form.scss';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;

    if (paymentMethod.id === ChannelType.CONSTANTPOS_UPI) {
        return <Upi paymentMethod={paymentMethod} />;
    } else return null;
}

export default Form;
