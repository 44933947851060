import classNames from 'classnames';
import { Component } from 'react';
import { translate as t } from 'service/Language';

import AuthService from '../../../../service/Auth';
import GoogleService from '../../../../service/Google';
import MainBanner from '../../../Casino/Banners/MainBanner/MainBanner';
import Button from './Button/Button';
import Arrow from './images/arrow.svg';
import Gift from './images/gift.svg';
import Spin from './images/spin.svg';
import Time from './images/time.svg';
import WelcomeDescription from './WelcomeDescription/WelcomeDescription';
import WelcomeItem from './WelcomeItem/WelcomeItem';

import './WelcomeBonus.scss';

export type PromoType = {
    img: string;
    title: string;
    description: string;
};

class WelcomeBonus extends Component {
    state = {
        collapsed: false,
    };

    handler = {
        toggle: this.toggle.bind(this),
    };

    promoList: PromoType[] = [
        { img: Gift, title: '100%', description: 'info.promo.welcome.description.bonus' },
        { img: Time, title: '125%', description: 'info.promo.welcome.description.timebonus' },
        { img: Spin, title: '250 FS', description: 'info.promo.welcome.description.freespin' },
    ];

    componentDidMount() {
        const userId = AuthService.auth.getValue()?.userId.toString();
        if (userId) {
            GoogleService.openBonusPage(userId);
        }
    }

    close() {
        this.setState({ collapsed: false });
    }

    open() {
        this.setState({ collapsed: true });
    }

    toggle() {
        const { collapsed } = this.state;
        if (collapsed) {
            this.close();
        } else {
            this.open();
        }
    }

    render() {
        const { collapsed } = this.state;

        const arrowClass = classNames({
            'welcome-bonus__terms-arrow': true,
            'welcome-bonus__terms-arrow__up': collapsed,
        });

        return (
            <div className="welcome-bonus">
                <img className="welcome-bonus__banner" src={t('banner.freespins.desktop')} alt="img" />
                <div className="welcome-bonus__title">{t('info.promo.welcome.title')}</div>
                <div className="welcome-bonus__promo-list">
                    {this.promoList.map((promo) => (
                        <WelcomeItem promo={promo} />
                    ))}
                </div>
                <div className="welcome-bonus__description">{t('info.promo.welcome.description')}</div>

                <div className="welcome-bonus__button">
                    <Button />
                </div>

                <div className="welcome-bonus__terms">
                    <button className="welcome-bonus__terms-button" type="button" onClick={this.handler.toggle}>
                        <span>{t('info.promo.welcome.terms')}</span>
                        <img className={arrowClass} src={Arrow} alt="arrow" />
                    </button>
                </div>
                {collapsed ? (
                    <div className="welcome-bonus__collapse">
                        <WelcomeDescription />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default WelcomeBonus;
