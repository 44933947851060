import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';
import useObservable from 'utils/useObservable';
import ChatwootService from 'service/Chatwoot';

const ChatwootLoader = () => {
    const [container] = useState(() => document.createElement('div'));
    const isLoading = useObservable(
        ChatwootService.isLoading,
        ChatwootService.isLoading.getValue(),
    );
    const [close, setClose] = useState(false);
    const loaderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.body.appendChild(container);
        document.addEventListener('pointerdown', handleOutsideClick);
        return () => {
            document.removeEventListener('pointerdown', handleOutsideClick);
            document.body.removeChild(container);
        };
    }, []);

    const handleOutsideClick = (event: PointerEvent) => {
        if (loaderRef.current && !loaderRef.current.contains(event.target as Node)) {
            setClose(true);
        }
    };

    if (!isLoading || close) return null;

    return ReactDOM.createPortal(
        <div className='ChatwootLoader'>
            <div className='ChatwootLoader__loader' ref={loaderRef}></div>
        </div>,
        container,
    );
};

export default ChatwootLoader;
