import {
    ChannelType,
    Currency as CurrencyIso,
    Direction,
    Status,
} from '@ay_tsarbet/newbet-core/dist/types';
import classnames from 'classnames';
import { ListType } from 'enums/payment';
import History from 'service/model/History';
import OperationsService from 'service/Operations';

import RxComponent from '../../../../component/RxComponent/RxComponent';
import Currency, { unknownCurrency } from '../../../../service/model/Currency';
import UserData from '../../../../service/model/UserData';
import UserDataService from '../../../../service/UserData';
import paymentMethod from '../PaymentMethod/methods';
import PaymentMethod from '../PaymentMethod/PaymentMethod';
import { PaymentMethod as PaymentMethodType } from '../PaymentMethod/types';
import PaymentService from 'service/Payment';
import { PaymentMethodsListType } from 'utils/payment';

import './PaymentList.scss';

type PaymentListProps = {
    type: ListType;
    className?: string;
};

type PaymentListState = {
    isExistDepositBDT: boolean | null;
    isExistDepositTRY: boolean | null;
    userCurrency: Currency;
    bkashP2C: boolean;
    isFourthDepUPI: boolean;
    isThreeDepCardsTurkey: boolean;
    isThreeDepCardsUPIConst: boolean;
    isTwoWithdrawINR: boolean;
    isThreeDepositAZN: boolean;
    depositMethods: PaymentMethodType[];
    withdrawsMethods: PaymentMethodType[];
    isTwoDepBDT: boolean;
};

class PaymentList extends RxComponent<PaymentListProps, PaymentListState> {
    handler = {
        setHistory: this.setHistory.bind(this),
        setUserData: this.setUserData.bind(this),
        getList: this.getList.bind(this),
    };

    state = {
        userCurrency: unknownCurrency,
        isExistDepositBDT: null,
        isExistDepositTRY: null,
        bkashP2C: false,
        isFourthDepUPI: false,
        isThreeDepCardsTurkey: false,
        isThreeDepCardsUPIConst: false,
        isTwoWithdrawINR: false,
        isThreeDepositAZN: false,
        depositMethods: [],
        withdrawsMethods: [],
        isTwoDepBDT: false,
    };

    getList(data: PaymentMethodsListType) {
        this.setState({ depositMethods: data.deposits });
        this.setState({ withdrawsMethods: data.withdraws });
    }

    setHistory(data: History[]) {
        if (data) {
            const a = data.filter(
                history =>
                    history.type === Direction.REFILL &&
                    history.status === Status.SUCCESS &&
                    history.currency &&
                    history.currency.ISO === CurrencyIso.TRY,
            );
            const isExistDepositBDT =
                data.filter(
                    history =>
                        history.type === Direction.REFILL &&
                        history.status === Status.SUCCESS &&
                        history.currency &&
                        history.currency.ISO === CurrencyIso.BDT,
                ).length > 2;
            const bkashP2C =
                data.filter(
                    history =>
                        history.type === Direction.REFILL &&
                        history.status === Status.SUCCESS &&
                        history.currency &&
                        history.currency.ISO === CurrencyIso.BDT,
                ).length > 2;
            const isFourthDepUPI =
                data.filter(
                    history =>
                        history.type === Direction.REFILL &&
                        history.status === Status.SUCCESS &&
                        history.currency &&
                        history.currency.ISO === CurrencyIso.INR,
                ).length > 3;
            const isThreeDepCardsTurkey =
                data.filter(
                    history =>
                        history.type === Direction.REFILL &&
                        history.status === Status.SUCCESS &&
                        history.currency &&
                        history.currency.ISO === CurrencyIso.TRY,
                ).length > 2;
            const isThreeDepCardsUPIConst =
                data.filter(
                    history =>
                        history.type === Direction.REFILL &&
                        history.status === Status.SUCCESS &&
                        history.currency &&
                        history.currency.ISO === CurrencyIso.INR,
                ).length > 2;
            const isTwoWithdrawINR =
                data.filter(
                    history =>
                        history.type === Direction.WITHDRAW &&
                        history.status === Status.SUCCESS &&
                        history.currency &&
                        history.currency.ISO === CurrencyIso.INR,
                ).length > 1;
            const isExistDepositTRY =
                data.filter(
                    history =>
                        history.type === Direction.REFILL &&
                        history.status === Status.SUCCESS &&
                        history.currency &&
                        history.currency.ISO === CurrencyIso.TRY,
                ).length > 0;
            const isThreeDepositAZN =
                data.filter(
                    history =>
                        history.type === Direction.REFILL &&
                        history.status === Status.SUCCESS &&
                        history.currency &&
                        history.currency.ISO === CurrencyIso.AZN,
                ).length > 2;
            const isTwoDepBDT =
                data.filter(
                    history =>
                        history.type === Direction.REFILL &&
                        history.status === Status.SUCCESS &&
                        history.currency &&
                        history.currency.ISO === CurrencyIso.BDT,
                ).length > 1;
            this.setState({ isExistDepositBDT });
            this.setState({ bkashP2C });
            this.setState({ isFourthDepUPI });
            this.setState({ isThreeDepCardsTurkey });
            this.setState({ isThreeDepCardsUPIConst });
            this.setState({ isTwoWithdrawINR });
            this.setState({ isExistDepositTRY });
            this.setState({ isThreeDepositAZN });
            this.setState({ isTwoDepBDT });
        }
    }

    setUserData(userData: UserData | null) {
        if (userData) {
            this.setState({ userCurrency: userData.currency });
        }
    }

    componentDidMount() {
        this.subscribe(UserDataService.data, { next: this.handler.setUserData });
        this.subscribe(OperationsService.allList, {
            next: this.handler.setHistory,
        });
        // @ts-ignore
        this.subscribe(PaymentService.paymentList, { next: this.handler.getList });
    }

    render() {
        const { type, className } = this.props;
        const {
            userCurrency,
            isExistDepositBDT,
            bkashP2C,
            isFourthDepUPI,
            isThreeDepCardsTurkey,
            isThreeDepCardsUPIConst,
            isTwoWithdrawINR,
            isExistDepositTRY,
            isThreeDepositAZN,
            depositMethods,
            withdrawsMethods,
            isTwoDepBDT,
        } = this.state;

        const list: PaymentMethodType[] =
            type === ListType.DEPOSIT ? depositMethods : withdrawsMethods;

        const classNames = classnames({
            [className || '']: !!className,
            'payment-list': true,
        });

        return (
            <div className={classNames}>
                {list
                    .filter(method => method.currency.ISO === userCurrency.ISO)
                    .filter(method => {
                        if (type === ListType.DEPOSIT) {
                            // if (!isExistDepositBDT) {
                            // return method !== paymentMethod.NagadP2C;
                            // } else {
                            return method !== paymentMethod.Nagad;
                            // }
                        }
                        return method;
                    })
                    .filter(method => {
                        if (type === ListType.DEPOSIT) {
                            // if (!bkashP2C) {
                            //     return method !== paymentMethod.BKashP2C;
                            // } else {
                            return method !== paymentMethod.BKash;
                            // }
                        }
                        return method;
                    })
                    // .filter((method) => {
                    //   if (type === ListType.DEPOSIT) {
                    //     if (!isFourthDepUPI) {
                    //       return method !== paymentMethod.UPI;
                    //     }
                    //   }
                    //   return method;
                    // })
                    .filter(method => {
                        if (type === ListType.DEPOSIT) {
                            if (!isThreeDepCardsTurkey) {
                                return method.id !== ChannelType.TRY_CREDIT_CARD;
                            }
                        }
                        return method;
                    })
                    // .filter((method) => {
                    //   if (type === ListType.DEPOSIT) {
                    //     if (!isExistDepositTRY) {
                    //       return method.id !== ChannelType.MPAY_TRY;
                    //     }
                    //   }
                    //   return method;
                    // })
                    // .filter((method) => {
                    //   if (type === ListType.DEPOSIT) {
                    //     if (!isThreeDepCardsUPIConst) {
                    //       return method !== paymentMethod.CONSTANTPOS_UPI;
                    //     }
                    //   }
                    //   return method;
                    // })
                    // .filter((method) => {
                    //   if (method === paymentMethod.CONSTANTPOS_UPI) {
                    //     if (!isTwoWithdrawINR) {
                    //       return method !== paymentMethod.CONSTANTPOS_UPI;
                    //     }
                    //   }
                    //   return method;
                    // })
                    // .filter(method => {
                    //     if (type === ListType.DEPOSIT) {
                    //         if (!isThreeDepositAZN) {
                    //             return method.id !== ChannelType.KZT_COREFY;
                    //         }
                    //     }
                    //     return method;
                    // })
                    // .filter(method => {
                    //     if (type === ListType.DEPOSIT) {
                    //         if (!isTwoDepBDT) {
                    //             return method.id !== ChannelType.СASHIX_P2C_NAGAD && method.id !== ChannelType.СASHIX_P2C_BKASH
                    //         }
                    //     }
                    //     return method;
                    // })
                    .map(method => {
                        // console.log("---------- METHOD ----------", method);
                        return <PaymentMethod paymentMethod={method} key={method.id} type={type} />;
                    })}
            </div>
        );
    }
}

export default PaymentList;
