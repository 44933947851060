import classNames from 'classnames';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';

import AuthForm from '../service/AuthForm';

import './SignInButton.scss';

function SignInButton() {
    const valid = useObservable(AuthForm.valid, false);

    const className = classNames({
        'sign-in-button': true,
        'sign-in-button____active': valid,
    });

    return (
        <button type="submit" className={className}>
            {t('login.button')}
        </button>
    );
}

export default SignInButton;
