import { Subject } from 'rxjs';

import WSBet from '../WSBet';

import PayesInterface from '../interface/payment/Payes';

import {
    Direct24DepositCode,
    PayesDepositCode,
    PayesDepositResponse,
    PayesWithdrawResponse
} from '@ay_tsarbet/newbet-core/dist/types';
import PayesDepositService from '../../page/Profile/Balance/Deposit/Payes/service/PayesDeposit';
import { PayesDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPayesDepositRequest';
import { PayesWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPayesWithdrawal';

enum PayesError {
    UNKNOWN,
    DEPOSIT,
    WITHDRAW,
}

class Payes implements PayesInterface {
    error = new Subject<PayesError>();

    withdrawResult = new Subject<void>();

    deposit(request: PayesDepositRequest) {
        console.log('deposit', request);
        WSBet.payment.payes.deposit(request);
    }

    withdraw(withdraw: PayesWithdrawalRequest) {
        console.log('withdraw', withdraw);
        WSBet.payment.payes.withdraw(withdraw);
    }

    onDepositReceive(data: PayesDepositResponse) {
        console.log('Payes | DEPOSIT RESPONSE', data);
     if (data.code >= 0) {
         PayesDepositService.onReceive(data.message);
         console.log('data.message', data.message)
        } else {
         PayesDepositService.onError();
        }
    }
    onWithdrawReceive(response: PayesWithdrawResponse) {
        console.log('Payes  | WITHDRAW RESPONSE', response);
        if (response.errorCode >= PayesDepositCode.success) {
            this.withdrawResult.next();
        } else {
            this.error.next(PayesError.WITHDRAW);
        }
    }
}

export { PayesError };
export default Payes;
