/* eslint-disable @typescript-eslint/unbound-method */

// Preload images
import { BehaviorSubject, combineLatestWith, from, fromEvent } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import Disconnect from '../component/LostConnection/images/Illustration.svg';
import Logo from '../page/ErrorPage/images/Glory.svg';
import CountryPermissionService, { GeoPermission } from './CountryPermission';

const preloadSrcList: string[] = [
    Disconnect,
    Logo,
];

class Loading {
    public loadingImages: string[];

    public imagesPreloaded: boolean;

    public loadingListLength: number;

    public progressCounter: number;

    progress = new BehaviorSubject<number>(0);

    imageReady = new BehaviorSubject(false);

    fullReady = this.imageReady.pipe(combineLatestWith(CountryPermissionService.result)).pipe(map(this.onReadyUpdate));

    constructor() {
        this.loadingImages = preloadSrcList;
        this.imagesPreloaded = false;
        this.loadingListLength = preloadSrcList.length - 1;
        this.progressCounter = 0;
    }

    onReadyUpdate([imageReady, permission]: [boolean, GeoPermission]) {
        return imageReady && permission !== GeoPermission.AWAIT;
    }

    getProgressPercent(value: number) {
        if (value === this.loadingListLength) {
            this.imageReady.next(true);
        }

        return value / this.loadingListLength;
    }

    start() {
        const a = from(this.loadingImages).pipe(
            mergeMap((path) => {
                const img = new Image();
                img.src = path;
                img.onerror = () => this.progress.next(this.getProgressPercent(this.progressCounter++));
                return fromEvent(img, 'load').pipe(map((e) => e.target));
            })
        );
        a.subscribe((e) => this.progress.next(this.getProgressPercent(this.progressCounter++)));
    }
}

const LoadingService = new Loading();

export default LoadingService;
