import axios, { AxiosRequestConfig } from 'axios';

class JSON {
    constructor(private readonly url: string) {}

    get<T>(uri: string, config: AxiosRequestConfig) {
        return axios.get<T>(this.url + uri, config);
    }
}

export default JSON;
