import classNames from 'classnames';
import { Component, createRef } from 'react';
import { translate as t } from 'service/Language';

import SvgImage from '../../svg';
import { OptionInterface } from './Select.type';

import './Select.scss';

export enum SelectSize {
    average,
    small,
    xsmall,
}

type SelectProps<T> = {
    className?: string;
    list: Array<OptionInterface<T>>;
    onSelect: (option: OptionInterface<T>) => void;
    current: OptionInterface<T> | null;
    placeholder?: string;
    disabled?: boolean;
    size?: SelectSize;
};

type SelectState = {
    open: boolean;
};

class Select<S> extends Component<SelectProps<S>, SelectState> {
    state = {
        open: false,
    };

    handler = {
        open: this.open.bind(this),
        clickOutside: this.clickOutside.bind(this),
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    clickOutside(event: PointerEvent) {
        if (this.ref.self.current && !this.ref.self.current.contains(event.target as Node)) {
            this.close();
        }
    }

    open() {
        this.setState({ open: true });
        document.addEventListener('pointerdown', this.handler.clickOutside);
    }

    close() {
        this.setState({ open: false });
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    render() {
        const {
            list,
            current,
            onSelect,
            className,
            placeholder = '',
            size = SelectSize.average,
            disabled = false,
        } = this.props;
        const { open } = this.state;
        const sizeMap = new Map<SelectSize, string>([
            [SelectSize.small, 'component-select____small'],
            [SelectSize.xsmall, 'component-select____xsmall'],
        ]);

        const sizeClass = sizeMap.get(size) || 'component-select____unknown';

        const _className = classNames(className, 'component-select', {
            'component-select____open': open,
            'component-select____empty': current === null,
            'component-select____disabled': disabled,
            [sizeClass]: true,
        });

        return (
            <div className={_className} ref={this.ref.self}>
                <button
                    onClick={this.handler.open}
                    type="button"
                    className="component-select__toggle"
                    disabled={disabled}
                >
                    <div className="component-select__value">{current ? t(current.getName()) : placeholder}</div>
                    <SvgImage name="SelectArrow" />
                </button>

                <div className="component-select__list">
                    {list.map((option) => {
                        const containerClassName = classNames({
                            'component-select__option': true,
                            'component-select__option__active': option.getName() === current?.getName(),
                        });

                        return (
                            <button
                                onClick={() => {
                                    this.close();
                                    onSelect(option);
                                }}
                                key={option.getId()}
                                type="button"
                                className={containerClassName}
                            >
                                {t(option.getName())}
                            </button>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default Select;
