import { formatCurrencySign } from 'utils/format';

import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { translate as t } from '../../../../../../../service/Language';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import AmountFormService from '../../service/AmountForm';
import Submit from '../Button/Submit';
import SumSelector from '../SummSelector/SumSelector';
import Attention from '../../../Apay/Attention/Attention';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';

import './Amount.scss';



type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {};

class Amount extends RxComponent<AmountProps, AmountState> {

    componentDidMount() {
        const { paymentMethod } = this.props;

        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setValue(paymentMethod.defaultValue);
    }

    render() {
        const { paymentMethod, lock } = this.props;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        return (
            <div className="deposit-amount-azarpay">
                {paymentMethod.id === ChannelType.AZN_AZARPAY ? <div className="deposit-amount-azarpay__help-hint">{t('profile.balance.deposit.help.hint')}</div>: null}
                <form className="deposit-amount-azarpay__form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    <div className="deposit-amount-azarpay__input_wrapper">
                        <MaskedInput
                            className="deposit-amount-azarpay__input"
                            inputService={AmountFormService.amount}
                            name="amount"
                            mask="0000000"
                            hint={hint}
                            enabled={!lock}
                        />
                        <span className="deposit-amount-azarpay__input_currency">{paymentMethod.currency.sign}</span>
                    </div>
                </form>
                <div className="deposit-amount-azarpay__sum">
                    <SumSelector paymentMethod={paymentMethod} disabled={lock} />
                </div>
                <div className="deposit-amount-azarpay__check">
                    <Attention title="popup.payment.attention" type="attention" />
                </div>
                <div className="deposit-amount-azarpay__submit">
                    <Submit />
                </div>
            </div>
        );
    }
}

export default Amount;
