/* eslint-disable max-len */

import { Icon } from 'types';

function SupportIcon({ color = '#3F4250', width = 20, height = 20 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.99944 7.1437C3.99944 5.30937 4.74418 3.95775 5.85052 3.0563C6.96729 2.14633 8.47408 1.67941 9.99944 1.67941C11.5248 1.67941 13.0316 2.14633 14.1484 3.0563C15.2547 3.95776 15.9994 5.30937 15.9994 7.1437V13.4294C15.9994 13.745 16.2553 14.0008 16.5709 14.0008H18.5709C19.2021 14.0008 19.7137 13.4892 19.7137 12.858V7.85799C19.7137 7.2268 19.2021 6.71513 18.5709 6.71513H16.8443C16.7348 4.83077 15.9037 3.38092 14.6898 2.39181C13.3959 1.33749 11.6884 0.822266 9.99944 0.822266C8.31052 0.822266 6.60302 1.33749 5.30908 2.39181C4.09518 3.38092 3.26406 4.83077 3.15461 6.71513H1.42801C0.796831 6.71513 0.285156 7.2268 0.285156 7.85799V12.858C0.285156 13.4892 0.796831 14.0008 1.42801 14.0008H3.1423V16.7866C3.1423 17.4177 3.65397 17.9294 4.28516 17.9294H7.85834C7.89556 18.6852 8.52013 19.2866 9.28516 19.2866H10.7137C11.5027 19.2866 12.1423 18.647 12.1423 17.858V17.1437C12.1423 16.3547 11.5027 15.7151 10.7137 15.7151H9.28516C8.52013 15.7151 7.89555 16.3165 7.85834 17.0723H4.28516C4.12736 17.0723 3.99944 16.9444 3.99944 16.7866V7.1437ZM16.8566 13.1437V7.57227H18.5709C18.7287 7.57227 18.8566 7.70019 18.8566 7.85799V12.858C18.8566 13.0158 18.7287 13.1437 18.5709 13.1437H16.8566ZM1.42801 7.57227H3.1423V13.1437H1.42801C1.27022 13.1437 1.1423 13.0158 1.1423 12.858V7.85799C1.1423 7.70019 1.27022 7.57227 1.42801 7.57227ZM8.71373 17.1437C8.71373 16.8281 8.96957 16.5723 9.28516 16.5723H10.7137C11.0293 16.5723 11.2852 16.8281 11.2852 17.1437V17.858C11.2852 18.1736 11.0293 18.4294 10.7137 18.4294H9.28516C8.96957 18.4294 8.71373 18.1736 8.71373 17.858V17.1437Z"
                fill={color}
            />
        </svg>
    );
}

export default SupportIcon;
