/* eslint-disable max-len */

import { Icon } from 'types';

function SmallArrowDown({ color = '#FC4665', width = 8, height = 6 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                d="M0.561704 0C0.113827 0 -0.153315 0.476982 0.0951225 0.833081L3.53342 5.76139C3.75538 6.07954 4.24462 6.07954 4.46658 5.76139L7.90488 0.833081C8.15332 0.476981 7.88617 0 7.4383 0H0.561704Z"
                fill={color}
            />
        </svg>
    );
}

export default SmallArrowDown;
