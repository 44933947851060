import Validator from '../interface/Validator';

class TurkeyIban implements Validator {
    validate(value: string): boolean {
        return value.length === 26;
    }
}
const TurkeyIbanService = new TurkeyIban();
export { TurkeyIbanService };
export default TurkeyIban;
