import Input, { InputSize } from 'component/Input/Input';
import RxComponent from 'component/RxComponent/RxComponent';
import { translate as t } from 'service/Language';
import { formatCurrencySign } from 'utils/format';

import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import SelectEasy from '../../../../../../../component/SelectEasy/SelectEasy';
import { BankAZN, BANK_LIST_AZN, defaultBank } from '../../../../../../../service/model/BankAZN';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import AzarpayWithdrawService, { AzarpayWithdrawState } from '../../service/AzarpayWithdraw';
import CardFormService, { AzarpayError, AzarpayInputTip } from '../../service/CardForm';
import WithdrawalButton from '../../WithdrawalButton/WithdrawalButton';
import _paymentMethod from '../../../../PaymentMethod/methods';
import WithdrawService from '../../../service/Withdrawal';

import './Card.scss';
import Attention from '../../../../../../../assets/svg/attention.svg';


type CardProps = {
    paymentMethod: PaymentMethod;
};
type CardState = {
    value: string;
    tip: AzarpayInputTip;
    currentBank: BankAZN;
    error: AzarpayError;
    errorCard: boolean;
    errorValue: string | null;
    errorDueValue: string | null;
};

class Card extends RxComponent<CardProps, CardState> {
    state = {
        value: '',
        tip: AzarpayInputTip.NONE,
        currentBank: defaultBank,
        error: AzarpayError.NONE,
        errorCard: false,
        errorValue: null,
        errorDueValue: null,
    };

    handler = {
        onTip: this.onTip.bind(this),
        onSelect: this.onSelect.bind(this),
        setBank: this.setBank.bind(this),
        getValue: this.getValue.bind(this),
        onError: this.onError.bind(this),
        onErrorCard: this.onErrorCard.bind(this),
        onErrorValue: this.onErrorValue.bind(this),
        onDueValueError: this.onDueValueError.bind(this),
    };

    onTip(tip: AzarpayInputTip) {
        this.setState({tip});
    }

    onError(error: AzarpayError) {
        this.setState({error});
    }

    onErrorCard(errorCard: boolean) {
        this.setState({errorCard});
    }

    onErrorValue(errorValue: string | null) {
        this.setState({errorValue});
    }

    onDueValueError(errorDueValue: string | null) {
        this.setState({errorDueValue});
    }

    onSelect(bankOption: BankAZN) {
        CardFormService.onSelectBank(bankOption);
    }

    setBank(bank: BankAZN) {
        this.setState({currentBank: bank});
    }

    getValue(value: string) {
        this.setState({value});
    }

    componentDidMount() {
        const {paymentMethod} = this.props;

        CardFormService.setPaymentMethod(paymentMethod);
        this.subscribe(CardFormService.tip, {next: this.handler.onTip});
        this.subscribe(CardFormService.error, {next: this.handler.onError});
        this.subscribe(CardFormService.amount.value, {next: this.handler.getValue});
        this.subscribe(CardFormService.bank, {next: this.handler.setBank});
        this.subscribe(CardFormService.errorCard, {next: this.handler.onErrorCard});
        this.subscribe(CardFormService.card.error, {next: this.handler.onErrorValue});
        this.subscribe(CardFormService.dueDate.error, {next: this.handler.onDueValueError});
    }

    render() {
        const {paymentMethod} = this.props;
        const {value, tip, currentBank, error, errorCard, errorValue, errorDueValue} = this.state;

        const status = AzarpayWithdrawService.state.getValue();

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        const fee = WithdrawService.fee.getValue();

        const isInit = status === AzarpayWithdrawState.CARD;
        if (fee && isInit) {
            AzarpayWithdrawService.state.next(AzarpayWithdrawState.HAS_FEE)
        }

        const minFee = Math.ceil(paymentMethod.limit.withdraw.min * 1.25)
        const amountFee = formatCurrencySign(minFee, 0, paymentMethod.currency);
        const allFeeText = `${amountFee} ${t('popup.payment.min.fee')}`;

        return (
            <div className="azar-withdrawal">
                <div className="azar-withdrawal__header">
                    <img src={paymentMethod.icon} alt={paymentMethod.slug}/>
                    <h2 className="azar-withdrawal__header_title">{t(paymentMethod.title)}</h2>
                </div>
                <div className="azar-withdrawal__hint">{t('popup.balance.withdraw.hint')}</div>
                <form className="azar-withdrawal-form" onSubmit={(e) => CardFormService.onSubmit(e)}>
                    <div className="azar-withdrawal-form-block">
                        <Input
                            className="azar-withdrawal-form-block__input"
                            inputService={CardFormService.amount}
                            name="amount"
                            hint={hint}
                            type="number"
                        />
                        <span className="azar-withdrawal-form-block__currency">{paymentMethod.currency.sign}</span>
                        {error === AzarpayError.AMOUNT_FEE ?
                            <div className="azar-withdrawal-form-block__error">
                                <img src={Attention} alt="attention"/>
                                <span
                                    className="azar-withdrawal-form-block__error_text">
                                {allFeeText}
                            </span>
                            </div>
                            : null}
                    </div>
                    <div className="azar-withdrawal-form-block">
                        <Input
                            className="azar-withdrawal-form-block__input"
                            inputService={CardFormService.name}
                            name="name"
                            size={InputSize.medium}
                        />
                        {tip === AzarpayInputTip.NAME && (
                            <span className="azar-withdrawal-form-block__tip">
                                {t('popup.payment.name.hint')}
                            </span>
                        )}
                    </div>
                    <div className="azar-withdrawal-form-block">
                        <Input
                            className="azar-withdrawal-form-block__input"
                            inputService={CardFormService.surname}
                            name="surname"
                            size={InputSize.medium}
                        />
                        {tip === AzarpayInputTip.SURNAME && (
                            <span className="azar-withdrawal-form-block__tip">
                                {t('popup.payment.name.hint')}
                            </span>
                        )}
                    </div>
                    <div className="azar-withdrawal-form-block">
                        <SelectEasy onSelect={this.onSelect} list={BANK_LIST_AZN} current={currentBank}
                                    classList="azar-withdrawal-list"/>
                    </div>
                    <div className="azar-withdrawal-form-block">
                        <MaskedInput
                            mask="0000 0000 0000 0000"
                            placeholder="ХХХХ ХХХХ ХХХХ ХХХХ"
                            inputService={CardFormService.card}
                            name="card"
                            size={InputSize.medium}
                        />
                        {tip === AzarpayInputTip.CARD && !errorValue && (
                            <span className="azar-withdrawal-form-block__tip">
                                {t('popup.payment.numbers.only')}
                            </span>
                        )}
                        {errorValue && errorCard ? (
                            <span className="azar-withdrawal-form-block__error">
                                {t('popup.payment.error.card')}
                            </span>
                        ) : null}
                    </div>
                    <div className="azar-withdrawal-form-block">
                        <MaskedInput
                            mask="00/00"
                            placeholder="XX/XX"
                            inputService={CardFormService.dueDate}
                            name="card"
                            size={InputSize.medium}
                        />
                        {tip === AzarpayInputTip.DUE_DATE && !error && (
                            <span className="azar-withdrawal-form-block__tip">
                                {t('popup.payment.numbers.only')}
                            </span>
                        )}
                        <span className="azar-withdrawal-form-block__error">
                               {errorDueValue && error === AzarpayError.FULL_DUE && t('popup.payment.duedate.card.error')}
                            {errorDueValue && error === AzarpayError.EXPIRED && t('popup.payment.duedate.error')}
                            </span>
                    </div>
                    {(status === AzarpayWithdrawState.HAS_FEE || status === AzarpayWithdrawState.AWAIT) && fee ?
                        <FeeNotice amount={value} fee={fee}/>
                        : null}
                    {status === AzarpayWithdrawState.HAS_FEE || status === AzarpayWithdrawState.AWAIT ? (
                        <>
                            <WithdrawalButton
                                buttonTitle="profile.balance.withdraw.fee.agree"
                                variation={true}
                                paymentMethod={paymentMethod}
                                amount={value}
                            />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel"/>
                        </>
                    ) : (
                        <WithdrawalButton
                            buttonTitle="profile.balance.withdraw.button"
                            paymentMethod={_paymentMethod.Azarpay}
                        />
                    )}
                </form>
            </div>
        );
    }
}

export default Card;
