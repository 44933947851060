import {Subject} from 'rxjs';
import WSBet from '../WSBet';
import StatusFormPopupService, {StatusForm} from '../../page/Profile/Balance/Deposit/DepositPopup/service/StatusForm';
import {
    CashixDepositResponse,
    CashixWithdrawCode,
    CashixWithdrawResponse
} from '@ay_tsarbet/newbet-core/dist/types';
import CashixInterface from '../interface/payment/Cashix';
import { CashixDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendCashixP2CDepositRequest';
import { CashixWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendCashixWithdraw';
import CashixDepositService from '../../page/Profile/Balance/Deposit/Cashix/service/CashixDeposit';

enum CashixError {
    UNKNOWN,
    WITHDRAW,
}

class Cashix implements CashixInterface {
    error = new Subject<CashixError>();
    withdrawResult = new Subject<void>();

    depositP2C(request: CashixDepositRequest) {
        console.log('request', request);
        WSBet.payment.cashix.depositCashixP2C(request);
    }

    withdraw(withdraw: CashixWithdrawRequest) {
        console.log('withdraw', withdraw);
        WSBet.payment.cashix.withdraw(withdraw);
    }

    onDepositResponse(response: CashixDepositResponse) {
        if (response.errorCode >= 0) {
            CashixDepositService.onReceive(response.message);
            console.log('data.message', response.message)
        } else {
            CashixDepositService.onError();
        }
    }

    onWithdrawResponse(response: CashixWithdrawResponse) {
        console.log('onWithdrawResponse', response);
        if (response.code >= CashixWithdrawCode.success) {
            this.withdrawResult.next();
            StatusFormPopupService.status.next(StatusForm.SUCCESS)
        } else {
            this.error.next(CashixError.WITHDRAW);
            StatusFormPopupService.status.next(StatusForm.FAILED);
        }
    }
}

export { CashixError };
export default Cashix;
