import { BehaviorSubject } from 'rxjs';

import Game from './model/Game';

type SortCallback = (a: Game, b: Game) => number;

interface GameSortInterface {
    name: string;
    sort: SortCallback;
}

class GameSortMethod implements GameSortInterface {
    name = '';

    sort = (a: Game, b: Game) => Math.random() - 0.5;

    constructor(name: string, sortFn: SortCallback) {
        this.name = name;
        this.sort = sortFn;
    }
}

const sortMethod = {
    abc: new GameSortMethod('sort.games.alphabet', (a: Game, b: Game) => a.name.localeCompare(b.name)),
    zyx: new GameSortMethod('sort.games.alphabet-reverse', (a: Game, b: Game) => b.name.localeCompare(a.name)),
    new: new GameSortMethod('sort.games.new', (a: Game, b: Game) => (a.id > b.id ? -1 : 1)),
    pop: new GameSortMethod('sort.games.popular', (a: Game, b: Game) => 1),
};

const sortList = [sortMethod.abc, sortMethod.zyx, sortMethod.new, sortMethod.pop];

class GameSort {
    public method = new BehaviorSubject<GameSortInterface>(sortMethod.pop);

    setMethod(method: GameSortInterface) {
        this.method.next(method);
    }
}

const GameSortService = new GameSort();

export default GameSortService;
export type { GameSortInterface };
export { sortList };
