import {ClientInfo, Operation} from '@ay_tsarbet/newbet-core/dist/types';
import NotificationDesktop from 'page/Profile/Notification/NotificationDesktop/NotificationDesktop';
import React from 'react';
import { NavLink } from 'react-router-dom';
import GoogleService from 'service/Google';

import RouteConfig from '../../../appConstants/RouteConfig';
import BalanceService from '../../../service/Balance';
import BonusService from '../../../service/Bonus';
import Currency, { unknownCurrency } from '../../../service/model/Currency';
import UserData from '../../../service/model/UserData';
import NotificationService from '../../../service/Notifications';
import UserDataService from '../../../service/UserData';
import SvgImage from '../../../svg';
import { formatCurrency, formatCurrencySign } from '../../../utils/format';
import RxComponent from '../../RxComponent/RxComponent';
import ScrollService from '../../ScrollTopButton/service/Scroll';
import Chatwoot from '../Intercom/Chatwoot';
import NoticeImage from './image/notice.svg';
import AuthService from '../../../service/Auth';
import PinAnimated from '../../PinAnimated/PinAnimated';

import './UserInfo.scss';

type UserInfoProps = {
    isInfoNotification: number;
};

type UserInfoState = {
    balance: number;
    bonusBalance: number;
    currency: Currency;
    id: number | null;
    isNotification: Operation[];
    isOpenDesktopNotice: boolean;
    auth: ClientInfo | null,
    confirmEmail: boolean,
};

class UserInfo extends RxComponent<UserInfoProps, UserInfoState> {
    state = {
        balance: 0.0,
        bonusBalance: 0.0,
        currency: unknownCurrency,
        id: null,
        isNotification: [],
        isOpenDesktopNotice: false,
        auth: null,
        confirmEmail: false,
    };

    handler = {
        setBalance: this.setBalance.bind(this),
        setBonusBalance: this.setBonusBalance.bind(this),
        setUserData: this.setUserData.bind(this),
        setIsNotification: this.setIsNotification.bind(this),
        open: this.open.bind(this),
        clickOutside: this.clickOutside.bind(this),
        toggle: this.toggle.bind(this),
        setAuth: this.setAuth.bind(this),
        setConfirmEmail: this.setConfirmEmail.bind(this),
    };

    ref = {
        self: React.createRef<HTMLDivElement>(),
    };

    componentDidMount() {
        this.subscribe(BalanceService.balance, { next: this.handler.setBalance });
        this.subscribe(BonusService.balance, { next: this.handler.setBonusBalance });
        this.subscribe(UserDataService.data, { next: this.handler.setUserData });
        this.subscribe(NotificationService.info, { next: this.handler.setIsNotification });
        this.subscribe(AuthService.auth, { next: this.handler.setAuth });
        this.subscribe(AuthService.confirmEmail, { next: this.handler.setConfirmEmail });
    }

    setConfirmEmail(confirmEmail: boolean) {
        this.setState({ confirmEmail });
    }
    setAuth(auth: ClientInfo | null) {
        this.setState({ auth });
    }

    setBalance(balance: number) {
        this.setState({ balance });
    }

    setIsNotification(isNotification: Operation[]) {
        this.setState({ isNotification });
    }

    setBonusBalance(bonusBalance: number) {
        this.setState({ bonusBalance });
    }

    isReadNotification() {
        const notificationList: Operation[] = this.state.isNotification;
        const listIds = notificationList.filter(elem => elem.id);
        listIds.forEach(operation => NotificationService.updateNotice(operation.id.toString()));
    }

    setUserData(userData: UserData | null) {
        if (userData) {
            this.setState({
                currency: userData ? userData.currency : unknownCurrency,
                id: userData.id,
            });
        }
    }

    toggle() {
        const { isOpenDesktopNotice } = this.state;

        GoogleService.notification(this.state.id);

        if (isOpenDesktopNotice) {
            this.close();
        } else {
            this.open();
        }
    }

    close() {
        this.setState({ isOpenDesktopNotice: false });
        this.isReadNotification();

        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    open() {
        this.setState({ isOpenDesktopNotice: true });
        document.addEventListener('pointerdown', this.handler.clickOutside);
    }

    clickOutside(event: PointerEvent) {
        event.preventDefault();
        if (this.ref.self.current && !this.ref.self.current.contains(event.target as Node)) {
            this.close();
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    profileClick(id: number | null) {
        ScrollService.scrollToSmoothly(0, 500);
        GoogleService.goProfile(id);
    }

    render() {
        const { balance, bonusBalance, currency, isNotification, id, auth, confirmEmail } = this.state;
        const { isInfoNotification } = this.props;

        const routeBalanceHistory =
            RouteConfig.profile.root +
            RouteConfig.profile.balance.root +
            RouteConfig.profile.balance.history;

        return (
            <div className='header-info'>
                <div className='header-info__balance'>
                    <div className='header-info__currency'>
                        <p className='header-info__currency-count'>
                            {formatCurrencySign(balance, 2, currency)}
                        </p>
                    </div>
                    <div className='header-info__bonus'>
                        <p className='header-info__bonus-text'>{formatCurrency(bonusBalance, 2)}</p>
                        <SvgImage name='BonusIcon' width={7} height={7} />
                    </div>
                </div>
                <NavLink
                    to={{ hash: RouteConfig.hash.deposit, search: window.location.search }}
                    className='header-info__deposit'
                />
                <Chatwoot />
                <div ref={this.ref.self}>
                    <button onClick={this.handler.toggle} className='header-info__notice'>
                        <img src={NoticeImage} alt='img' />
                        {isInfoNotification ? <div className='header-info__notice-active' /> : null}
                    </button>
                    <NotificationDesktop
                        NotificationList={isNotification}
                        isOpen={this.state.isOpenDesktopNotice}
                    />
                </div>
                <NavLink className="header-info__profile" to={routeBalanceHistory}
                         onClick={() => this.profileClick(id)}>
                    {auth && !confirmEmail ?
                        <div className="header-info__profile_pin">
                            <PinAnimated/>
                        </div>: null}
                </NavLink>
            </div>
        );
    }
}

export default UserInfo;
