import Input, { InputSize } from 'component/Input/Input';
import RxComponent from 'component/RxComponent/RxComponent';
import { translate as t } from 'service/Language';
import { formatCurrencySign } from 'utils/format';
import { BankAZN, BANK_LIST_AZN, defaultBank } from '../../../../../../../service/model/BankAZN';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import AzarpayWithdrawService, { AzarpayWithdrawState } from '../../service/AzarpayWithdraw';
import CardFormService, {AmountError, AzarpayError, AzarpayInputTip} from '../../service/CardForm';
import WithdrawalButton from '../../WithdrawalButton/WithdrawalButton';
import _paymentMethod from '../../../../PaymentMethod/methods';
import WithdrawService from '../../../service/Withdrawal';
import Attention from '../../../../../../../assets/svg/attention.svg';

import './Valerpay.scss';

type CardProps = {
    paymentMethod: PaymentMethod;
};
type CardState = {
    value: string;
    tip: AzarpayInputTip;
    currentBank: BankAZN;
    error: AzarpayError;
    errorCard: boolean;
    errorValue: string | null;
    errorDueValue: string | null;
    errorAmount: string | null,
    amountLimit: AmountError,
};

class Valerpay extends RxComponent<CardProps, CardState> {
    state = {
        value: '',
        tip: AzarpayInputTip.NONE,
        currentBank: defaultBank,
        error: AzarpayError.NONE,
        errorCard: false,
        errorValue: null,
        errorDueValue: null,
        errorAmount: null,
        amountLimit: AmountError.NONE,
    };

    handler = {
        onTip: this.onTip.bind(this),
        onSelect: this.onSelect.bind(this),
        setBank: this.setBank.bind(this),
        getValue: this.getValue.bind(this),
        onError: this.onError.bind(this),
        onErrorCard: this.onErrorCard.bind(this),
        onErrorValue: this.onErrorValue.bind(this),
        onDueValueError: this.onDueValueError.bind(this),
        onErrorAmount: this.onErrorAmount.bind(this),
        onAmountLimit: this.onAmountLimit.bind(this),
    };

    onTip(tip: AzarpayInputTip) {
        this.setState({tip});
    }

    onError(error: AzarpayError) {
        this.setState({error});
    }

    onErrorCard(errorCard: boolean) {
        this.setState({errorCard});
    }

    onErrorValue(errorValue: string | null) {
        this.setState({errorValue});
    }

    onDueValueError(errorDueValue: string | null) {
        this.setState({errorDueValue});
    }

    onSelect(bankOption: BankAZN) {
        CardFormService.onSelectBank(bankOption);
    }

    setBank(bank: BankAZN) {
        this.setState({currentBank: bank});
    }

    getValue(value: string) {
        this.setState({value});
    }

    onAmountLimit(amountLimit: AmountError) {
        this.setState({amountLimit});
    }

    onErrorAmount(errorAmount: string | null) {
        if(CardFormService.valid.getValue()) {
            this.setState({errorAmount: null});
        } else {
            this.setState({errorAmount});
        }
    }

    componentDidMount() {
        const {paymentMethod} = this.props;

        CardFormService.setPaymentMethod(paymentMethod);
        this.subscribe(CardFormService.tip, {next: this.handler.onTip});
        this.subscribe(CardFormService.error, {next: this.handler.onError});
        this.subscribe(CardFormService.amount.value, {next: this.handler.getValue});
        this.subscribe(CardFormService.amount.error, {next: this.handler.onErrorAmount});
        this.subscribe(CardFormService.amountLimit, {next: this.handler.onAmountLimit});
        this.subscribe(CardFormService.bank, {next: this.handler.setBank});
        this.subscribe(CardFormService.errorCard, {next: this.handler.onErrorCard});
        this.subscribe(CardFormService.card.error, {next: this.handler.onErrorValue});
        this.subscribe(CardFormService.dueDate.error, {next: this.handler.onDueValueError});
    }

    render() {
        const { paymentMethod } = this.props;
        const { value, tip, error, errorAmount, amountLimit } = this.state;

        const status = AzarpayWithdrawService.state.getValue();

        const min = formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency);
        const max = formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency);
        const hint = `${t('popup.payment.amount.min')} ${min} — ${t('popup.payment.amount.max')} ${max}`;

        const fee = WithdrawService.fee.getValue();

        const isInit = status === AzarpayWithdrawState.CARD;
        if (fee && isInit) {
            AzarpayWithdrawService.state.next(AzarpayWithdrawState.HAS_FEE)
        }

        const minFee = Math.ceil(paymentMethod.limit.withdraw.min * 1.25)
        const amountFee = formatCurrencySign(minFee, 0, paymentMethod.currency);
        const allFeeText = `${amountFee} ${t('popup.payment.min.fee')}`;

        return (
            <div className="valerpay-withdrawal">
                <div className="valerpay-withdrawal__header">
                    <img src={paymentMethod.icon} alt={paymentMethod.slug}/>
                    <h2 className="valerpay-withdrawal__header_title">{t(paymentMethod.title)}</h2>
                </div>
                <form className="valerpay-withdrawal-form" onSubmit={(e) => CardFormService.onSubmit(e)}>
                    <div className="valerpay-withdrawal-form-block">
                        <Input
                            className="valerpay-withdrawal-form-block__input"
                            inputService={CardFormService.amount}
                            name="amount"
                            type="number"
                        />
                        <span className="valerpay-withdrawal-form-block__currency">{paymentMethod.currency.sign}</span>
                        { error || errorAmount || amountLimit ? (
                            <div className="valerpay-withdrawal-form-block__error">
                                {error || errorAmount || amountLimit ? <img src={Attention} alt="attention"/>: null}
                                <span className="valerpay-withdrawal-form-block__error_text">
                                    {amountLimit && amountLimit === AmountError.AMOUNT_WITHDRAWAL ? t('popup.payment.amount.withdrawal.max') : null}
                                    {amountLimit && amountLimit === AmountError.AMOUNT_MIN ? t('popup.payment.amount.min') + ` ${fee ? amountFee : min}` : null}
                                    {fee && amountLimit === AmountError.AMOUNT_FEE ? allFeeText : null}
                                    {amountLimit && amountLimit === AmountError.AMOUNT_MAX ? t('popup.payment.amount.max') + ` ${max}` : null}
                                    </span>
                            </div>
                        ) : null}
                        {tip === AzarpayInputTip.AMOUNT && !amountLimit && !errorAmount && !error? (
                            <span className="valerpay-withdrawal-form-block__tip">
                                {hint}
                            </span>
                        ) : null}
                    </div>
                    {(status === AzarpayWithdrawState.HAS_FEE || status === AzarpayWithdrawState.AWAIT) && fee ?
                        <FeeNotice amount={value} fee={fee}/>
                        : null}
                    {status === AzarpayWithdrawState.HAS_FEE || status === AzarpayWithdrawState.AWAIT ? (
                        <>
                            <WithdrawalButton
                                buttonTitle="profile.balance.withdraw.fee.agree"
                                variation={true}
                                paymentMethod={paymentMethod}
                                amount={value}
                            />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel"/>
                        </>
                    ) : (
                        <WithdrawalButton
                            buttonTitle="profile.balance.withdraw.button"
                            paymentMethod={_paymentMethod.Azarpay}
                        />
                    )}
                </form>
            </div>
        );
    }
}

export default Valerpay;
