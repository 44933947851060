import { PaymentMethod } from "../../../PaymentMethod/types";
import P2C from "../P2C/Form";
import { useEffect } from 'react';
import CashixDepositService from '../service/CashixDeposit';

import "./Form.scss";


type FormProps = {
  paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
  const { paymentMethod } = props;
  useEffect(()=>{
    return ()=> {
      CashixDepositService.reset()
    }
  },[]);

    return <P2C paymentMethod={paymentMethod} />
}

export default Form;
