import { translate as t } from 'service/Language';

import './CurrencyBlocked.scss';

function CurrencyBlocked() {
    return (
        <div className="provider-blocked-currency">
            <div className="provider-blocked-currency__globe" />
            <div className="provider-blocked-currency__text">{t('casino.provider.blocked.currency')}</div>
        </div>
    );
}

export default CurrencyBlocked;
