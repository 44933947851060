import useObservable from 'utils/useObservable';

import CorefyWithdrawService, { CorefyWithdrawState } from '../service/CorefyWithdraw';

import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import FailedPage from './ResultPage/FailedPage/FailedPage';
import BonusFailedPage from '../../../../../../component/BonusFailedPage/BonusFailedPage';

import './ResultForm.scss';

function ResultForm() {
    const state = useObservable(CorefyWithdrawService.state, CorefyWithdrawService.state.getValue());

    switch (state) {
        case CorefyWithdrawState.ACCEPT: {
            return <SuccessPage />;
        }
        case CorefyWithdrawState.REJECT: {
            return <FailedPage />;
        }
        case CorefyWithdrawState.BONUS_REJECT: {
            return <BonusFailedPage />;
        }
        default: {
            return null;
        }
    }
}

export default ResultForm;
