import { useEffect } from 'react';
import useObservable from 'utils/useObservable';
import { PaymentMethod } from '../../../PaymentMethod/types';
import Balance from '../../Balance/Balance';
import ResultForm from '../ResultForm/ResultForm';
import PayesWithdrawService, { PayesWithdrawState } from '../service/PayesWithdraw';
import EManat from './eManat/eManat';
import M10 from './M10/M10';
import BankCards from './BankCards/BankCards';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const {paymentMethod} = props;
    const state = useObservable(PayesWithdrawService.state, PayesWithdrawService.state.getValue());

    useEffect(() => {
        PayesWithdrawService.reset();
    }, []);

    if (
        state === PayesWithdrawState.INIT ||
        state === PayesWithdrawState.AWAIT ||
        state === PayesWithdrawState.HAS_FEE
    ) {
        if (paymentMethod.id === ChannelType.PAYES_EMANAT) {
            return (
                <>
                    <Balance/>
                    <EManat paymentMethod={paymentMethod}/>
                </>
            );
        }
        if (paymentMethod.id === ChannelType.PAYES_M10) {
            return (
                <>
                    <Balance/>
                    <M10 paymentMethod={paymentMethod}/>
                </>
            );
        }
        if (paymentMethod.id === ChannelType.PAYES_BANK_CARDS) {
            return (
                <>
                    <Balance/>
                    <BankCards paymentMethod={paymentMethod}/>
                </>
            );
        }
    } else {
        return <ResultForm/>;
    }
}

export default Form;
