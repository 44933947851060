import { useEffect } from 'react';
import { translate as t } from 'service/Language';

import GoogleService from '../../../../../../service/Google';
import OperationsService from '../../../../../../service/Operations';
import useObservable from '../../../../../../utils/useObservable';
import { PaymentMethod } from '../../../PaymentMethod/types';
import FailedPage from '../../Paykassma/ResultPage/FailedPage/FailedPage';
import PaykassmaDepositService, { DepositState } from '../../Paykassma/service/PaykassmaDeposit';
import Amount from './Amount/Amount';
import AttentionPage from './SuccessPage/AttentionPage';

import './Form.scss';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(PaykassmaDepositService.state, PaykassmaDepositService.state.getValue());

    useEffect(() => {
        const gaSub = OperationsService.invoice.subscribe({
            next: (data) => {
                const clientId = GoogleService.getClientId();
                const invoiceId = data.id.toString();
                OperationsService.sendGAClientRelation(clientId, invoiceId);
            },
        });
        return () => {
            gaSub.unsubscribe();
            PaykassmaDepositService.reset();
        };
    }, []);
    return (
        <div className="deposit-form">
            {state !== DepositState.FAILED && state !== DepositState.SUCCESS ? (
                <div className="deposit-form__header">
                    <img className="deposit-form__img" src={paymentMethod.icon} alt={paymentMethod.title} />
                    <h2 className="deposit-form__title">{t(paymentMethod.title)}</h2>
                </div>
            ) : null}
            {(state === DepositState.AMOUNT || state === DepositState.WAIT) && (
                <Amount paymentMethod={paymentMethod} lock={state !== DepositState.AMOUNT} />
            )}
            {state === DepositState.SUCCESS ? <AttentionPage /> : null}
            {state === DepositState.FAILED ? <FailedPage /> : null}
        </div>
    );
}

export default Form;
