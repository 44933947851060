import Input, { InputSize } from 'component/Input/Input';
import RxComponent from 'component/RxComponent/RxComponent';
import { translate as t } from 'service/Language';
import { formatCurrencySign } from 'utils/format';

import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import _paymentMethod from '../../../../PaymentMethod/methods';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import CorefyTip from '../../CorefyTip/CorefyTip';
import CardFormService, { CorefyInputTip } from '../../service/CardForm';
import CorefyWithdrawService, { CorefyWithdrawState } from '../../service/CorefyWithdraw';
import WithdrawalButton from '../../WithdrawalButton/WithdrawalButton';
import AttentionIcon from '../../../../Deposit/Apay/Attention/image/attention.svg';
import Attention from '../../../../../../../assets/svg/attention.svg';
import WithdrawService from '../../../service/Withdrawal';

import './Card.scss';
import {ChannelType} from '@ay_tsarbet/newbet-core/dist/types';


type CardProps = {
    paymentMethod: PaymentMethod;
};
type CardState = {
    value: string;
    tip: CorefyInputTip;
    error: string,
};

class Card extends RxComponent<CardProps, CardState> {
    state = {
        value: '',
        tip: CorefyInputTip.NONE,
        error: '',
    };

    handler = {
        getValue: this.getValue.bind(this),
        onTip: this.onTip.bind(this),
        onError: this.onError.bind(this),
    };

    onTip(tip: CorefyInputTip) {
        this.setState({ tip });
    }

    onError(error: string) {
        this.setState({ error });
    }

    getValue(value: string) {
        this.setState({ value });
    }

    componentDidMount() {
        const { paymentMethod } = this.props;

        CardFormService.setPaymentMethod(paymentMethod);
        this.subscribe(CardFormService.tip, { next: this.handler.onTip });
        this.subscribe(CardFormService.amount.value, { next: this.handler.getValue });
        this.subscribe(CardFormService.error, { next: this.handler.onError });
    }

    render() {
        const { paymentMethod } = this.props;
        const { value, error, tip } = this.state;

        const status = CorefyWithdrawService.state.getValue();

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        const fee = WithdrawService.fee.getValue();

        const isInit = status === CorefyWithdrawState.CARD;
        if (fee && isInit) {
            CorefyWithdrawService.state.next(CorefyWithdrawState.HAS_FEE)
        };

        const minFee = Math.round(paymentMethod.limit.withdraw.min * 1.25)
        const amountFee = formatCurrencySign(minFee, 0, paymentMethod.currency);
        const allFeeText = `${amountFee} ${t('popup.payment.min.fee')}`;

        return (
            <div className="card-withdrawal">
                <div className="card-withdrawal__header">
                    <img src={paymentMethod.icon} alt={paymentMethod.slug} />
                    <h2 className="card-withdrawal__title">{t(paymentMethod.title)}</h2>
                </div>
                <form className="card-withdrawal__form" onSubmit={(e) => CardFormService.onSubmit(e)}>
                    <div className="card-withdrawal__amount-block">
                        <Input
                            className="card-withdrawal__amount"
                            inputService={CardFormService.amount}
                            name="amount"
                            hint={hint}
                            type="number"
                        />
                        <span className="card-withdrawal__currency">{paymentMethod.currency.sign}</span>
                        {!!error ? (
                            <div className="card-withdrawal__amount-block__error">
                                <img src={Attention} alt="attention"/>
                                <span
                                    className="card-withdrawal__amount-block__error_text">
                                {allFeeText}
                            </span>
                            </div>
                        ) : null}
                    </div>
                    <div className="card-withdrawal__account">
                        <MaskedInput
                            mask="0000 0000 0000 0000"
                            placeholder="ХХХХ ХХХХ ХХХХ ХХХХ"
                            inputService={CardFormService.card}
                            name="card"
                            size={InputSize.medium}
                        />
                    </div>
                    <div className="card-withdrawal__account">
                        <Input
                            className="card-withdrawal__account"
                            inputService={CardFormService.name}
                            name="name"
                            size={InputSize.medium}
                        />
                        {tip === CorefyInputTip.NAME && (
                            <span className="card-withdrawal__account_help">
                                {t('popup.balance.deposit.input.name')}
                            </span>
                        )}
                        <CorefyTip />
                    </div>
                    <div className="card-withdrawal__account">
                        <Input
                            className="card-withdrawal__account"
                            inputService={CardFormService.surname}
                            name="surname"
                            size={InputSize.medium}
                        />
                        {tip === CorefyInputTip.SURNAME && (
                            <span className="card-withdrawal__account_help">
                                {t('popup.balance.deposit.input.name')}
                            </span>
                        )}
                        <CorefyTip up />
                    </div>
                    {paymentMethod.id === ChannelType.KZT_COREFY ? <div className="card-withdrawal-attention">
                        <img src={AttentionIcon} alt="attention"/>
                        <div className="card-withdrawal-attention__text">{t('popup.payment.azarpay.attention')}</div>
                    </div>: null}
                    {(status === CorefyWithdrawState.HAS_FEE || status === CorefyWithdrawState.AWAIT) && fee ? <FeeNotice amount={value} fee={fee} />
                        : null}
                    {status === CorefyWithdrawState.HAS_FEE || status === CorefyWithdrawState.AWAIT ? (
                        <>
                            <WithdrawalButton
                                buttonTitle="profile.balance.withdraw.fee.agree"
                                variation={true}
                                paymentMethod={paymentMethod}
                                amount={value}
                            />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel" />
                        </>
                    ) : (
                        <WithdrawalButton
                            buttonTitle="profile.balance.withdraw.button"
                            paymentMethod={_paymentMethod.Corefy_AZN}
                        />
                    )}
                </form>
            </div>
        );
    }
}

export default Card;
