import classNames from 'classnames';
import React, { createRef } from 'react';
import Collapsible from 'react-collapsible';

import GameSortService, { GameSortInterface, sortList } from '../../../service/GameSort';
import { translate as t } from '../../../service/Language';
import RxComponent from '../../RxComponent/RxComponent';
import SortAndFilterService from '../service/SortAndFilter';

import './List.scss';

type SortProps = {
    className?: string;
};
type SortState = {
    open: boolean;
    sort: GameSortInterface;
};

class List extends RxComponent<SortProps, SortState> {
    state = {
        open: false,
        sort: GameSortService.method.getValue(),
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    handler = {
        setSortType: this.setSortType.bind(this),
        setOpen: this.setOpen.bind(this),
    };

    componentDidMount() {
        this.subscribe(GameSortService.method, { next: this.handler.setSortType });
        this.subscribe(SortAndFilterService.toggle.sort, { next: this.handler.setOpen });
    }

    setSortType(sort: GameSortInterface) {
        this.setState({ sort });
    }

    setOpen(open: boolean) {
        this.setState({ open });
    }

    pickMethod(method: GameSortInterface) {
        SortAndFilterService.toggleSort();
        GameSortService.setMethod(method);
    }

    render() {
        const { className } = this.props;
        const { open, sort } = this.state;

        const _className = classNames({
            [className || '']: !!className,
            'game-sort-list': true,
        });

        return (
            <div ref={this.ref.self} className={_className}>
                <Collapsible open={open} transitionTime={200} trigger="">
                    {sortList.map((method) => {
                        const sortItemClassName = classNames({
                            'game-sort-list__method': true,
                            'game-sort-list__method__active': method === sort,
                        });
                        return (
                            <button className={sortItemClassName} onClick={() => this.pickMethod(method)}>
                                {t(method.name)}
                            </button>
                        );
                    })}
                </Collapsible>
            </div>
        );
    }
}

export default List;
