import BalanceService from "./Balance";
import BonusService from "./Bonus";
import GoogleService from "./Google";
import Language from "./Language";
import UserDataService from "./UserData";
import UserData from "./model/UserData";
import Storage from "./Storage";
import UserTrackService from "./UserTrack";
import AuthService from "./Auth";
import { country, unknownCountry } from "./model/Country";
import CountrySupportService from "./CountrySupport";
import GeoDataService from "./GeoData";
import { BehaviorSubject } from "rxjs";
import dayjs from "dayjs";

type ChatwootParamsType = {
    userData?: UserData | null;
    balance?: number;
};

type ChatwootConfigType = {
    name: string;
    customAttributes: {
        userId: number | null;
        balance: number | null;
        currency: string;
        registred: string;
    };
};

class Chatwoot {
    public isInitialized = false;
    static baseUrl = "https://chatwoot.glorysgaming.com";
    token: string = "";
    hmacToken: string = "";
    language: string = "";
    currencyFromLink: string = "";

    public isLoading = new BehaviorSubject<boolean>(false);
    private country: string = "";

    private scriptTag: HTMLScriptElement | null = null;
    private scriptReference: HTMLScriptElement | null = null;

    constructor() {
        // this.language = Storage.get(Language.STORAGE_KEY);
        // this.currencyFromLink = Storage.get('currencyFromLink');
        // this.setToken();
        // this.addTag();
        // this.updateTokenIframe();
        this.onReady = this.onReady.bind(this);
        window.addEventListener("chatwoot:ready", this.onReady);
    }

    init() {
        this.setToken();
        this.addTag();
        this.updateTokenIframe();
        try {
            this.setIsLoading(true);
            const language = Storage.get(Language.STORAGE_KEY);
            (window as any).chatwootSettings = {
                hideMessageBubble: true,
                position: "right",
                locale: language ? language.toLowerCase() : "en",
                type: "standard",
            };
        } catch (error) {
            (window as any).chatwootSettings = {
                hideMessageBubble: true,
                position: "right",
                locale: "en",
                type: "standard",
            };
        }
    }

    show() {
        console.log("--- SHOW ---");
        if ((window as any).$chatwoot) {
            this.setToken();
            this.updateTokenIframe();
            (window as any).$chatwoot.setLocale(this.language);
            (window as any).$chatwoot.toggle("open");
            ChatwootService.setIsLoading(false);
        }
        GoogleService.openLiveChat();
    }

    static close() {
        // this.context?.shutdown();
        window.removeEventListener("chatwoot:ready", ChatwootService.onReady);
    }

    setToken() {
        const userData = UserDataService.data.getValue();
        const isAuthenticated = AuthService.auth.getValue();
        const geo = GeoDataService.data.getValue();
        if (
            userData &&
            isAuthenticated &&
            !!userData.country.id &&
            userData.country.id
        ) {
            if (
                !!userData.country.id &&
                userData.country.id !== unknownCountry.id &&
                userData.country.id !== 0
            ) {
                if (userData.country.id === country.Bangladesh.id) {
                    console.log("bn");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN_BN;
                    this.hmacToken = process.env.REACT_APP_CHATWOOT_TOKEN_BN;
                } else if (userData.country.id === country.Pakistan.id) {
                    console.log("pk");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN_PK;
                    this.hmacToken =
                        process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_PK;
                } else if (userData.country.id === country.Brazil.id) {
                    console.log("br");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN_BR;
                    this.hmacToken = process.env.REACT_APP_CHATWOOT_TOKEN_BR;
                } else if (userData.country.id === country.Azerbaijan.id) {
                    console.log("az");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN_AZ;
                    this.hmacToken =
                        process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_AZ;
                } else if (userData.country.id === country.India.id) {
                    console.log("in");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN_IN;
                    this.hmacToken =
                        process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_IN;
                } else if (userData.country.id === country.Turkey.id) {
                    console.log("tr");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN_TR;
                    this.hmacToken =
                        process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_TR;
                } else if (userData.country.id === country.MyanmarBurma.id) {
                    console.log("mm");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN_MM;
                    this.hmacToken =
                        process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_MM;
                } else if (userData.country.id === country.France.id) {
                    console.log("fr");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN_FR;
                    this.hmacToken =
                        process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_FR;
                } else {
                    console.log("default");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN;
                    this.hmacToken = process.env.REACT_APP_CHATWOOT_HMAC_TOKEN;
                }
            } else if (
                geo?.country.id !== unknownCountry.id &&
                geo?.country.id !== 0
            ) {
                if (geo?.country.id === country.Bangladesh.id) {
                    console.log("bn");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN_BN;
                    this.hmacToken = process.env.REACT_APP_CHATWOOT_TOKEN_BN;
                } else if (geo?.country.id === country.Pakistan.id) {
                    console.log("pk");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN_PK;
                    this.hmacToken =
                        process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_PK;
                } else if (geo?.country.id === country.Brazil.id) {
                    console.log("br");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN_BR;
                    this.hmacToken = process.env.REACT_APP_CHATWOOT_TOKEN_BR;
                } else if (geo?.country.id === country.Azerbaijan.id) {
                    console.log("az");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN_AZ;
                    this.hmacToken =
                        process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_AZ;
                } else if (geo?.country.id === country.India.id) {
                    console.log("in");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN_IN;
                    this.hmacToken =
                        process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_IN;
                } else if (geo?.country.id === country.Turkey.id) {
                    console.log("tr");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN_TR;
                    this.hmacToken =
                        process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_TR;
                } else if (geo?.country.id === country.MyanmarBurma.id) {
                    console.log("mm");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN_MM;
                    this.hmacToken =
                        process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_MM;
                } else if (geo?.country.id === country.France.id) {
                    console.log("fr");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN_FR;
                    this.hmacToken =
                        process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_FR;
                } else {
                    console.log("default");
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN;
                    this.hmacToken = process.env.REACT_APP_CHATWOOT_HMAC_TOKEN;
                }
            }
            return;
        }
        if (!isAuthenticated) {
            try {
                const countryCode = Storage.get(
                    CountrySupportService.STORAGE_COUNTRY_SUPPORT
                );
                const currencyFromLink = Storage.get(
                    UserTrackService.STORAGE_CURRENCY_LINK_KEY
                );
                if (
                    countryCode &&
                    (!userData ||
                        (userData &&
                            (!userData.country.id ||
                                userData.country.id === 0 ||
                                userData.country.id === unknownCountry.id))) &&
                    geo?.country.id !== unknownCountry.id &&
                    geo?.country.id !== 0 &&
                    !currencyFromLink
                ) {
                    if (countryCode.replaceAll('"', "") === "BD") {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_BN;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_TOKEN_BN;
                    } else if (countryCode.replaceAll('"', "") === "PK") {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_PK;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_PK;
                    } else if (countryCode.replaceAll('"', "") === "BR") {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_BR;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_TOKEN_BR;
                    } else if (countryCode.replaceAll('"', "") === "AZ") {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_AZ;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_AZ;
                    } else if (countryCode.replaceAll('"', "") === "IN") {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_IN;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_IN;
                    } else if (countryCode.replaceAll('"', "") === "TR") {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_TR;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_TR;
                    } else if (countryCode.replaceAll('"', "") === "MM") {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_MM;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_MM;
                    } else if (countryCode.replaceAll('"', "") === "FR") {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_FR;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_FR;
                    } else if (countryCode.replaceAll('"', "") === "NL") {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_NL;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_NL;
                    } else {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN;
                    }
                    return;
                }
                if (
                    (!userData ||
                        (userData &&
                            (!userData.country.id ||
                                userData.country.id === 0 ||
                                userData.country.id === unknownCountry.id))) &&
                    currencyFromLink &&
                    geo?.country.id !== unknownCountry.id &&
                    geo?.country.id !== 0 &&
                    !countryCode
                ) {
                    if (currencyFromLink.replaceAll('"', "") === "BDT") {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_BN;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_TOKEN_BN;
                    } else if (currencyFromLink.replaceAll('"', "") === "PKR") {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_PK;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_PK;
                    } else if (currencyFromLink.replaceAll('"', "") === "BR") {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_BR;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_TOKEN_BR;
                    } else if (currencyFromLink.replaceAll('"', "") === "AZN") {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_AZ;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_AZ;
                    } else if (currencyFromLink.replaceAll('"', "") === "INR") {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_IN;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_IN;
                    } else if (currencyFromLink.replaceAll('"', "") === "TRY") {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_TR;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_TR;
                    } else if (currencyFromLink.replaceAll('"', "") === "MMK") {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_MM;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_MM;
                    } else {
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN;
                    }
                    return;
                }
                if (
                    (!userData ||
                        (userData &&
                            (!userData.country.id ||
                                userData.country.id === 0 ||
                                userData.country.id === unknownCountry.id))) &&
                    !currencyFromLink &&
                    geo?.country.id !== unknownCountry.id &&
                    geo?.country.id !== 0 &&
                    !countryCode
                ) {
                    if (geo?.country.id === country.Bangladesh.id) {
                        console.log("bn");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_BN;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_TOKEN_BN;
                    } else if (geo?.country.id === country.Pakistan.id) {
                        console.log("pk");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_PK;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_PK;
                    } else if (geo?.country.id === country.Brazil.id) {
                        console.log("br");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_BR;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_TOKEN_BR;
                    } else if (geo?.country.id === country.Azerbaijan.id) {
                        console.log("az");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_AZ;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_AZ;
                    } else if (geo?.country.id === country.India.id) {
                        console.log("in");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_IN;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_IN;
                    } else if (geo?.country.id === country.Turkey.id) {
                        console.log("tr");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_TR;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_TR;
                    } else if (geo?.country.id === country.MyanmarBurma.id) {
                        console.log("mm");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_MM;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_MM;
                    } else if (geo?.country.id === country.France.id) {
                        console.log("fr");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_FR;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_FR;
                    } else {
                        console.log("default");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN;
                    }
                    return;
                }
                if (
                    (!userData ||
                        (userData &&
                            (!userData.country.id ||
                                userData.country.id === 0 ||
                                userData.country.id === unknownCountry.id))) &&
                    geo?.country.id === unknownCountry.id &&
                    geo?.country.id !== 0 &&
                    !countryCode
                ) {
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN;
                    this.hmacToken = process.env.REACT_APP_CHATWOOT_HMAC_TOKEN;
                }
            } catch (error) {
                if (
                    (!userData ||
                        (userData &&
                            (!userData.country.id ||
                                userData.country.id === 0 ||
                                userData.country.id === unknownCountry.id))) &&
                    (geo?.country.id !== unknownCountry.id ||
                        geo?.country.id !== 0)
                ) {
                    if (geo?.country.id === country.Bangladesh.id) {
                        console.log("bn");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_BN;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_TOKEN_BN;
                    } else if (geo?.country.id === country.Pakistan.id) {
                        console.log("pk");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_PK;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_PK;
                    } else if (geo?.country.id === country.Brazil.id) {
                        console.log("br");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_BR;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_TOKEN_BR;
                    } else if (geo?.country.id === country.Azerbaijan.id) {
                        console.log("az");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_AZ;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_AZ;
                    } else if (geo?.country.id === country.India.id) {
                        console.log("in");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_IN;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_IN;
                    } else if (geo?.country.id === country.Turkey.id) {
                        console.log("tr");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_TR;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_TR;
                    } else if (geo?.country.id === country.MyanmarBurma.id) {
                        console.log("mm");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_MM;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_MM;
                    } else if (geo?.country.id === country.France.id) {
                        console.log("fr");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN_FR;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN_FR;
                    } else {
                        console.log("default");
                        this.token = process.env.REACT_APP_CHATWOOT_TOKEN;
                        this.hmacToken =
                            process.env.REACT_APP_CHATWOOT_HMAC_TOKEN;
                    }
                    return;
                }
                if (
                    (!userData ||
                        (userData &&
                            (!userData.country.id ||
                                userData.country.id === 0 ||
                                userData.country.id === unknownCountry.id))) &&
                    (geo?.country.id === unknownCountry.id ||
                        geo?.country.id === 0)
                ) {
                    this.token = process.env.REACT_APP_CHATWOOT_TOKEN;
                    this.hmacToken = process.env.REACT_APP_CHATWOOT_HMAC_TOKEN;
                }
            }
        }
    }

    updateTokenIframe() {
        const isAuthenticated = AuthService.auth.getValue();
        if (isAuthenticated) {
            const iframeTag = document.getElementById(
                "chatwoot_live_chat_widget"
            ) as HTMLScriptElement;
            if (iframeTag) {
                const url = new URL(iframeTag.src);
                url.searchParams.set("website_token", this.token);
                iframeTag.src = url.toString();
            }
        }
        if (!isAuthenticated) {
            const iframeTag = document.getElementById(
                "chatwoot_live_chat_widget"
            ) as HTMLScriptElement;
            if (iframeTag) {
                const url = new URL(iframeTag.src);
                url.searchParams.set("website_token", this.token);
                iframeTag.src = url.toString();
            }
        }
    }

    addTag() {
        console.log("--- ADD TAG ---");
        this.scriptTag = document.createElement("script") as HTMLScriptElement; // g
        this.scriptReference = document.getElementsByTagName("script")[0]; // s
        if (this.scriptTag && this.scriptReference) {
            this.scriptTag.src = Chatwoot.baseUrl + "/packs/js/sdk.js";
            this.scriptReference.parentNode?.insertBefore(
                this.scriptTag,
                this.scriptReference
            );
            this.scriptTag.async = !0;

            const userData = UserDataService.data.getValue();
            const balance = BalanceService.balance.getValue();
            const bonus = BonusService.balance.getValue();

            const lang = this.language;
            const currencyFromLink = this.currencyFromLink;

            this.scriptTag.onload = () => {
                (window as any).chatwootSDK.run({
                    websiteToken: this.token,
                    baseUrl: Chatwoot.baseUrl,
                });
                // console.log('USER DATA Before', userData);
                if ((window as any).$chatwoot) {
                    if (userData && !!userData.id) {
                        // console.log('USER DATA After', userData);
                        const user = {
                            name: userData.email,
                            email: userData.email,
                            identifier_hash: this.hmacToken,
                        };
                        const customAttributes = {
                            id: userData.id,
                            name: userData.email,
                            email: userData.email,
                            identifier_hash: this.hmacToken,
                            registered: dayjs(userData.registrationDate).format(
                                "DD.MM.YYYY HH:mm"
                            ),
                            currency: userData.currency,
                            country: userData.country,
                            language: lang,
                            bonuses: bonus,
                            balance: balance,
                            rawData: {
                                ...userData,
                                registrationDate: dayjs(
                                    userData.registrationDate
                                ).format("DD.MM.YYYY HH:mm"),
                            },
                        };
                        (window as any).$chatwoot.setUser(userData.id, user);
                        (window as any).$chatwoot.setCustomAttributes(
                            customAttributes
                        );
                    } else {
                        const customAttributes = {
                            identifier_hash: this.hmacToken,
                            currency: currencyFromLink || "",
                            language: lang,
                        };
                        (window as any).$chatwoot.setCustomAttributes(
                            customAttributes
                        );
                    }

                    if ((window as any).$chatwoot) {
                        (window as any).addEventListener(
                            "chatwoot:close",
                            Chatwoot.close
                        );
                    }
                }
            };
            this.scriptTag.onerror = function (error) {
                console.log("Chatwood onerror", error);
            };
        }
    }

    setInitialized(value: boolean) {
        this.isInitialized = value;
    }

    setIsLoading(loading: boolean) {
        console.log("setIsLoading", loading);
        this.isLoading.next(loading);
    }

    onReady() {
        console.log("--- ON READY ---");
        const userData = UserDataService.data.getValue();
        const balance = BalanceService.balance.getValue();
        const bonus = BonusService.balance.getValue();
        if (userData && !!userData.id) {
            const user = {
                name: userData.email,
                email: userData.email,
                identifier_hash: this.hmacToken,
            };
            const customAttributes = {
                id: userData.id,
                name: userData.email,
                email: userData.email,
                registered: dayjs(userData.registrationDate).format(
                    "DD.MM.YYYY HH:mm"
                ),
                currency: userData.currency,
                country: userData.country,
                language: this.language,
                identifier_hash: this.hmacToken,
                bonuses: bonus,
                balance: balance,
                rawData: {
                    ...userData,
                    registrationDate: dayjs(userData.registrationDate).format(
                        "DD.MM.YYYY HH:mm"
                    ),
                },
            };
            if (this.hmacToken) {
                user.identifier_hash = this.hmacToken;
                customAttributes.identifier_hash = this.hmacToken;
            }
            (window as any).$chatwoot.setUser(userData.id, user);
            (window as any).$chatwoot.setCustomAttributes(customAttributes);
        } else {
            const currencyFromLink = this.currencyFromLink;
            const customAttributes = {
                identifier_hash: this.hmacToken,
                currency: currencyFromLink || "",
                language: this.language,
            };
            (window as any).$chatwoot.setCustomAttributes(customAttributes);
        }

        console.log("this.isInitialized", ChatwootService.isInitialized);
        if (!ChatwootService.isInitialized) {
            ChatwootService.setInitialized(true);
            ChatwootService.show();
        }
    }
}

const ChatwootService = new Chatwoot();

export default ChatwootService;
