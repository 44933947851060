import { BehaviorSubject } from 'rxjs';

import { CountryMap, unknownCountry } from './model/Country';
import Currency, { currencyMap } from './model/Currency';
import GeoDataModel from './model/GeoData';
import WSBet from './WSBet';

type GeoDataRecordType = {
    id_country: number;
    currencyList: number[];
};

class GeoData {
    data = new BehaviorSubject<GeoDataModel | null>(null);

    requestGeoData() {
        WSBet.geoData.request();
    }

    onReceive(geoDataRecord: GeoDataRecordType) {
        const country = CountryMap.get(geoDataRecord.id_country) || unknownCountry;
        const currencyList: Currency[] = [];

        geoDataRecord.currencyList.forEach((id) => {
            const currency = currencyMap.get(id);
            if (currency) {
                currencyList.push(currency);
            }
        });
        const geoData = new GeoDataModel(currencyList, country);
        this.data.next(geoData);
    }
    checkGeoRedirect(name: string) {
        const countryList = [ 'KZ', 'UZ', 'KE', 'LK', 'IN', 'BD', 'TR']
        if(countryList.includes(name)) {
            window.open('http://glorymczo.com', '_self')
        }
    }
}

const GeoDataService = new GeoData();

export default GeoDataService;
