import './WheelItem.scss';

type WheelProps = {
    angle: number;
};

function WheelItem({ angle }: WheelProps) {
    const spin = {
        transform: `rotate(${angle}deg)`,
    };

    return (
        <div className="wheel-item-aviator" style={spin}>
            <div className="wheel-item-aviator__field" />
        </div>
    );
}

export default WheelItem;
