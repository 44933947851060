/* eslint-disable max-len */

import { Icon } from 'types';

function SmallArrowUp({ color = '#2EBA4D', width = 8, height = 6 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                d="M7.4383 6C7.88617 6 8.15332 5.52302 7.90488 5.16692L4.46658 0.238612C4.24462 -0.0795377 3.75538 -0.0795374 3.53342 0.238613L0.0951224 5.16692C-0.153315 5.52302 0.113827 6 0.561704 6H7.4383Z"
                fill={color}
            />
        </svg>
    );
}

export default SmallArrowUp;
