import { NavLink } from 'react-router-dom';
import NotificationService from 'service/Notifications';
import { useIsDesktop } from 'utils/useBreakpoints';
import useObservable from 'utils/useObservable';

import RouteConfig from '../../appConstants/RouteConfig';
import Logo from '../../assets/svg/LogoGlory.svg';
import GamesService from '../../service/Games';
import LanguageSelect from '../common/LanguageSelect/LanguageSelect';
import ScrollService from '../ScrollTopButton/service/Scroll';
import Auth from './Auth';
import Button from './Menu/Button/Button';
import NavBar from './NavBar/NavBar';

import './Header.scss';

function Header() {
    const isDesktop = useIsDesktop();
    const isInfoNotification = useObservable(NotificationService.info, NotificationService.info.getValue()).filter(
        (item) => item.notice === 0
    ).length;

    const onClick = () => {
        ScrollService.scrollToSmoothly(0, 500);
        GamesService.setSearchText('');
    };

    return (
        <div className="header">
            <div className="header__block-logo">
                <div className="header__logo-container">
                    <NavLink to={RouteConfig.casino.root} onClick={onClick}>
                        <img src={Logo} className="header__logo" alt="logo" />
                    </NavLink>
                </div>
            </div>

            <div className="header__main">
                <div className="header__navbar">
                    <NavBar />
                </div>
                <div className="header__auth">
                    <Auth isInfoNotification={isInfoNotification} />
                    {isDesktop && <LanguageSelect className="header__language" />}
                    {!isDesktop && <Button />}
                </div>
            </div>
        </div>
    );
}

export default Header;
