import { NavLink, useLocation } from 'react-router-dom';
import { translate as t } from 'service/Language';

import RouteConfig from '../../../appConstants/RouteConfig';

import './SignUp.scss';

function SignUp() {
    const location = useLocation();
    return (
        <NavLink
            to={{ hash: RouteConfig.hash.registration, search: location.search }}
            className="header-sign-up-button"
        >
            {t('header.sign.up')}
        </NavLink>
    );
}

export default SignUp;
