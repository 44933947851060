import { BehaviorSubject } from 'rxjs';
import PaymentService from 'service/Payment';
import StatusFormPopupService, { StatusForm } from '../../../Deposit/DepositPopup/service/StatusForm';
import PayesFormService from './PayesForm';

enum PayesWithdrawState {
    INIT,
    COMPLETE,
    AWAIT,
    HAS_FEE,
    ACCEPT,
    REJECT,
    BONUS_REJECT,
}

class PayesWithdraw {
    state = new BehaviorSubject<PayesWithdrawState>(PayesWithdrawState.INIT);

    handler = {
        onWithdraw: this.onWithdraw.bind(this),
        onError: this.onError.bind(this),
    };

    constructor() {
        PaymentService.payes.withdrawResult.subscribe(this.handler.onWithdraw);
        PaymentService.payes.error.subscribe(this.handler.onError);
    }

    onWithdraw() {
        this.state.next(PayesWithdrawState.ACCEPT);
        StatusFormPopupService.status.next(StatusForm.SUCCESS);
    }

    onError() {
        this.state.next(PayesWithdrawState.REJECT);
        StatusFormPopupService.status.next(StatusForm.FAILED);
    }

    reset() {
        this.state.next(PayesWithdrawState.INIT);
        StatusFormPopupService.status.next(StatusForm.INIT);
        PayesFormService.reset();
    }
}

const PayesWithdrawService = new PayesWithdraw();

export { PayesWithdrawState };
export default PayesWithdrawService;
