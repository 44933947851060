import { useEffect } from 'react';
import { translate as t } from 'service/Language';

import Input from '../../../../../component/Input/Input';
import PasswordFormService from '../service/PasswordFormService';
import RepeatPasswordService from './service/RepeatePasswordInput';

import './RepeatePasswordInput.scss';

function RepeatePasswordInput() {
    useEffect(() => {
        const repeatPassword = RepeatPasswordService.value.subscribe(
            PasswordFormService.handler.onRepeatPasswordChange
        );

        return () => {
            repeatPassword.unsubscribe();
        };
    }, []);

    return (
        <div className="repeat-password-input">
            <span className="repeat-password-input__header">{t('profile.settings.password.change.repeat')}</span>
            <Input inputService={RepeatPasswordService} className="repeat-password-input__field" type="password" />
        </div>
    );
}

export default RepeatePasswordInput;
