import { HavaleHeraWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendHavaleHeraWithdrawal';

class HavaleHeraWithdraw {
    name: string;

    surname: string;

    userName: string;

    note: string;

    banksId: number;

    currencyId: number;

    channelType: number;

    amount: number;

    iban: string;

    constructor(data: HavaleHeraWithdrawalRequest) {
        this.name = data.name;
        this.surname = data.surname;
        this.userName = data.userName;
        this.note = data.note;
        this.banksId = data.banksId;
        this.currencyId = data.currencyId;
        this.channelType = data.channelType;
        this.amount = data.amount;
        this.iban = data.iban;
    }
}

export default HavaleHeraWithdraw;
