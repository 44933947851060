/* eslint-disable max-len */

import { Icon } from 'types';

function ArrowUp({ color = '#3F4250', width = 14, height = 14 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 14 14" fill="none">
            <path
                d="M2.5 9.5L7 4.5L11.5 9.5"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default ArrowUp;
