/* eslint-disable max-len */

import { Icon } from 'types';

function CasinoRoulette({ color, width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0)">
                <path
                    d="M6.74944 22.5976C6.69179 22.6975 6.73022 22.8254 6.83434 22.875C8.12787 23.4905 9.54857 23.882 11.0463 23.9995C11.1612 24.0085 11.2584 23.9169 11.2584 23.8017V22.1956C11.2584 22.0908 11.1774 22.004 11.073 21.9944C9.91889 21.8887 8.82129 21.5877 7.81357 21.1245C7.71843 21.0808 7.60511 21.1156 7.55276 21.2062L6.74944 22.5976Z"
                    fill={color ?? 'url(#paint0_linear)'}
                />
                <path
                    d="M6.19126 20.1859C6.27649 20.2465 6.30292 20.3619 6.25064 20.4524L5.44736 21.8437C5.38963 21.9437 5.25938 21.9743 5.1644 21.9087C3.96094 21.0769 2.91641 20.0315 2.0856 18.8273C2.02007 18.7323 2.05071 18.6021 2.15064 18.5445L3.54184 17.7412C3.63247 17.6889 3.74793 17.7154 3.80848 17.8008C4.46296 18.7233 5.26938 19.5305 6.19126 20.1859Z"
                    fill={color ?? 'url(#paint1_linear)'}
                />
                <path
                    d="M17.7484 3.54688C17.6961 3.63744 17.7226 3.75283 17.8078 3.81341C18.7251 4.46535 19.5281 5.2676 20.181 6.18425C20.2416 6.26937 20.3569 6.29574 20.4474 6.24348L21.8388 5.44015C21.9388 5.38239 21.9694 5.25205 21.9037 5.15708C21.0744 3.95912 20.0333 2.91892 18.8346 2.09061C18.7396 2.02498 18.6094 2.05559 18.5517 2.15557L17.7484 3.54688Z"
                    fill={color ?? 'url(#paint2_linear)'}
                />
                <path
                    d="M17.1646 1.12452C17.2687 1.17406 17.3071 1.30201 17.2495 1.40187L16.4462 2.79326C16.3938 2.88393 16.2805 2.91869 16.1854 2.87498C15.184 2.41495 14.0939 2.11489 12.9478 2.00754C12.8437 1.99778 12.7629 1.91105 12.7629 1.8064V0.200062C12.7629 0.084694 12.8603 -0.006903 12.9753 0.00231793C14.4647 0.121738 15.8776 0.512267 17.1646 1.12452Z"
                    fill={color ?? 'url(#paint3_linear)'}
                />
                <path
                    d="M21.8494 18.5446C21.9493 18.6023 21.9799 18.7325 21.9144 18.8274C21.0836 20.0317 20.039 21.0771 18.8354 21.9089C18.7405 21.9745 18.6102 21.9439 18.5525 21.8439L17.7492 20.4526C17.6969 20.362 17.7234 20.2467 17.8086 20.1861C18.7305 19.5307 19.537 18.7235 20.1915 17.8009C20.2521 17.7156 20.3675 17.6891 20.4582 17.7414L21.8494 18.5446Z"
                    fill={color ?? 'url(#paint4_linear)'}
                />
                <path
                    d="M12.7629 22.1937C12.7629 22.089 12.8437 22.0023 12.9478 21.9925C14.0943 21.8851 15.1847 21.5849 16.1863 21.1247C16.2814 21.081 16.3947 21.1157 16.4471 21.2064L17.2504 22.5978C17.308 22.6976 17.2696 22.8256 17.1655 22.8751C15.8782 23.4876 14.465 23.8783 12.9753 23.9977C12.8603 24.007 12.7629 23.9154 12.7629 23.8V22.1937Z"
                    fill={color ?? 'url(#paint5_linear)'}
                />
                <path
                    d="M11.2584 1.80444C11.2584 1.90925 11.1774 1.99606 11.073 2.00562C9.91924 2.11129 8.82194 2.41223 7.81447 2.87512C7.71933 2.91883 7.60602 2.88407 7.55367 2.7934L6.75035 1.40201C6.69269 1.30215 6.73113 1.17421 6.83526 1.12467C8.12854 0.509381 9.54893 0.117992 11.0463 0.000611958C11.1612 -0.00839335 11.2584 0.0831543 11.2584 0.198384V1.80444Z"
                    fill={color ?? 'url(#paint6_linear)'}
                />
                <path
                    d="M6.19206 3.8136C6.2773 3.75302 6.30374 3.63763 6.25146 3.54707L5.44818 2.15575C5.39046 2.05578 5.26022 2.02517 5.16524 2.0908C3.96661 2.91912 2.92561 3.9593 2.09635 5.15723C2.03061 5.25221 2.06118 5.38255 2.16121 5.4403L3.55264 6.24364C3.64314 6.29589 3.75844 6.26952 3.81906 6.1844C4.47183 5.26778 5.27481 4.46554 6.19206 3.8136Z"
                    fill={color ?? 'url(#paint7_linear)'}
                />
                <path
                    d="M23.8017 12.7414C23.917 12.7414 24.0085 12.8385 23.9995 12.9534C23.8824 14.4478 23.4924 15.8655 22.8793 17.1567C22.8298 17.2609 22.7018 17.2994 22.6019 17.2417L21.2104 16.4384C21.1198 16.386 21.085 16.2728 21.1286 16.1777C21.5895 15.1723 21.8892 14.0776 21.9945 12.9267C22.0041 12.8223 22.0909 12.7414 22.1957 12.7414H23.8017Z"
                    fill={color ?? 'url(#paint8_linear)'}
                />
                <path
                    d="M21.2026 7.54479C21.1119 7.59717 21.0772 7.7106 21.121 7.80576C21.5833 8.80946 21.8848 9.90251 21.9925 11.0519C22.0023 11.1561 22.089 11.2368 22.1937 11.2368H23.8C23.9154 11.2368 24.007 11.1394 23.9977 11.0244C23.878 9.5316 23.4859 8.11567 22.8712 6.8263C22.8216 6.72227 22.6937 6.68391 22.5939 6.74153L21.2026 7.54479Z"
                    fill={color ?? 'url(#paint9_linear)'}
                />
                <path
                    d="M2.78965 16.4382C2.88026 16.3859 2.91504 16.2727 2.87144 16.1776C2.4106 15.1722 2.11097 14.0775 2.00562 12.9267C1.99606 12.8223 1.90925 12.7414 1.80444 12.7414H0.198384C0.0831569 12.7414 -0.00838999 12.8385 0.000611459 12.9534C0.117703 14.4477 0.507681 15.8654 1.12074 17.1565C1.17023 17.2607 1.29824 17.2992 1.39815 17.2416L2.78965 16.4382Z"
                    fill={color ?? 'url(#paint10_linear)'}
                />
                <path
                    d="M1.12887 6.82647C1.17846 6.72244 1.30634 6.68408 1.40615 6.74171L2.79742 7.54496C2.88816 7.59734 2.92289 7.71077 2.87906 7.80593C2.41679 8.80958 2.1153 9.90257 2.00761 11.0519C1.99785 11.1561 1.91111 11.2368 1.80647 11.2368H0.200122C0.0847514 11.2368 -0.00684638 11.1394 0.00237841 11.0244C0.122115 9.53167 0.514184 8.1158 1.12887 6.82647Z"
                    fill={color ?? 'url(#paint11_linear)'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.9999 21.0273C16.9856 21.0273 21.0274 16.9856 21.0274 11.9998C21.0274 7.0141 16.9856 2.97235 11.9999 2.97235C7.01413 2.97235 2.97238 7.0141 2.97238 11.9998C2.97238 16.9856 7.01413 21.0273 11.9999 21.0273ZM12.7519 9.24122C13.0566 9.01249 13.2535 8.64859 13.2535 8.23816C13.2535 7.54562 12.6922 6.98434 11.9996 6.98434C11.3071 6.98434 10.7458 7.54562 10.7458 8.23816C10.7458 8.64859 10.9427 9.01249 11.2474 9.24122V10.3579C11.0686 10.4397 10.9055 10.5499 10.764 10.6826C10.5989 10.8379 10.4627 11.024 10.3653 11.2317H9.24125C9.01252 10.927 8.64862 10.7301 8.23819 10.7301C7.54565 10.7301 6.98437 11.2914 6.98437 11.9839C6.98437 12.6765 7.54565 13.2378 8.23819 13.2378C8.46986 13.2378 8.68682 13.1751 8.87294 13.0659C8.9082 13.0448 8.94249 13.0223 8.97579 12.9983C9.02917 12.9596 9.07913 12.9165 9.12566 12.8695C9.16729 12.8278 9.20598 12.7833 9.24125 12.7362H10.3511C10.5298 13.1359 10.8497 13.4586 11.2474 13.6413V14.758C10.9427 14.9867 10.7458 15.3506 10.7458 15.7611C10.7458 16.4536 11.3071 17.0149 11.9996 17.0149C12.6922 17.0149 13.2535 16.4536 13.2535 15.7611C13.2535 15.3506 13.0566 14.9867 12.7519 14.758V13.6413C13.1496 13.4586 13.4695 13.1359 13.6487 12.7362H14.758C14.9868 13.0409 15.3507 13.2378 15.7611 13.2378C16.4536 13.2378 17.0149 12.6765 17.0149 11.9839C17.0149 11.2914 16.4536 10.7301 15.7611 10.7301C15.695 10.7301 15.6303 10.735 15.5672 10.7448C15.4667 10.761 15.3703 10.7884 15.2792 10.8261C15.0715 10.9128 14.8918 11.0539 14.758 11.2317H13.6345C13.4533 10.8462 13.1393 10.5357 12.7519 10.3579V9.24122Z"
                    fill={color ?? 'url(#paint12_linear)'}
                />
                <path
                    d="M12.7522 11.9998C12.7522 12.4153 12.4154 12.7521 11.9999 12.7521C11.5844 12.7521 11.2476 12.4153 11.2476 11.9998C11.2476 11.5844 11.5844 11.2476 11.9999 11.2476C12.4154 11.2476 12.7522 11.5844 12.7522 11.9998Z"
                    fill={color ?? 'url(#paint13_linear)'}
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="0.545457"
                    y1="5.26831"
                    x2="20.7737"
                    y2="10.0958"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="0.545457"
                    y1="5.26831"
                    x2="20.7737"
                    y2="10.0958"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="0.545457"
                    y1="5.26831"
                    x2="20.7737"
                    y2="10.0958"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="0.545457"
                    y1="5.26831"
                    x2="20.7737"
                    y2="10.0958"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear"
                    x1="0.545457"
                    y1="5.26831"
                    x2="20.7737"
                    y2="10.0958"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear"
                    x1="0.545457"
                    y1="5.26831"
                    x2="20.7737"
                    y2="10.0958"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear"
                    x1="0.545457"
                    y1="5.26831"
                    x2="20.7737"
                    y2="10.0958"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear"
                    x1="0.545457"
                    y1="5.26831"
                    x2="20.7737"
                    y2="10.0958"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear"
                    x1="0.545457"
                    y1="5.26831"
                    x2="20.7737"
                    y2="10.0958"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear"
                    x1="0.545457"
                    y1="5.26831"
                    x2="20.7737"
                    y2="10.0958"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear"
                    x1="0.545457"
                    y1="5.26831"
                    x2="20.7737"
                    y2="10.0958"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear"
                    x1="0.545457"
                    y1="5.26831"
                    x2="20.7737"
                    y2="10.0958"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint12_linear"
                    x1="0.545457"
                    y1="5.26831"
                    x2="20.7737"
                    y2="10.0958"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint13_linear"
                    x1="0.545457"
                    y1="5.26831"
                    x2="20.7737"
                    y2="10.0958"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default CasinoRoulette;
