import Validator from '../interface/Validator';

class MinLimit implements Validator {
    constructor(private min: number) {}

    setLimit(min: number) {
        this.min = min;
    }

    validate(value: string): boolean {
        const number = parseFloat(value);

        return number >= this.min;
    }
}

export default MinLimit;
