import { PaykassmaWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaWithdraw';
import InputService from 'component/Input/service/Input';
import paymentMethod from 'page/Profile/Balance/PaymentMethod/methods';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import AuthService from 'service/Auth';
import GoogleService from 'service/Google';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';

import { translate as t } from '../../../../../../../service/Language';
import { AccountEasypaisaService } from '../../../../../../../service/Validator/AccountEasypaisa';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import PaykassmaWithdrawService, { PaykassmaWithdrawState } from '../../../Paykassma/service/PaykassmaWithdraw';
import WithdrawService from '../../../service/Withdrawal';
import BonusService from '../../../../../../../service/Bonus';

import StatusFormPopupService, {StatusForm} from '../../../../Deposit/DepositPopup/service/StatusForm';
import UserDataService from '../../../../../../../service/UserData';
import {AmountError} from '../../../../Deposit/Directa24/Pix/service/AmountForm';
import BalanceService from '../../../../../../../service/Balance';


enum ApayBDTFormError {
    NOERROR,
    AMOUNT,
    ACCOUNT,
}

class ApayBDTForm {
    paymentMethod: PaymentMethod = paymentMethod.APAY_ROCKET;

    limitValidator = new Validator.limit(1, 1000000);

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.withdraw.input.amount',
    });

    accountNumber = new InputService({
        title: 'profile.balance.withdraw.input.wallet',
        validator: Validator.accountEasypaisa,
        validatorText: 'profile.balance.withdraw.input.wallet',
    });

    valid = new BehaviorSubject<boolean>(false);

    tip = new BehaviorSubject<ApayBDTFormError>(ApayBDTFormError.NOERROR);

    amountLimit = new BehaviorSubject<AmountError>(AmountError.NONE);

    balance = new BehaviorSubject(0);

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        onAccountNumber: this.onAccountNumber.bind(this),
        onAccountActive: this.onAccountActive.bind(this),
        onBalance: this.onBalance.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
        this.accountNumber.value.subscribe(this.handler.onAccountNumber);
        this.accountNumber.active.subscribe(this.handler.onAccountActive);
        BalanceService.balance.subscribe(this.handler.onBalance);
    }

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        const limit = this.paymentMethod.limit.withdraw;
        const isFee = WithdrawService.fee.getValue();
        const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
        this.limitValidator.setLimit(isFee ? isMin : limit.min, limit.max);
    }

    onBalance(balance: number) {
        this.balance.next(balance)
    }

    onAmount(value: string) {
        const isValue = this.amount.value.getValue();
        if (this.paymentMethod && isValue) {
            const fee = WithdrawService.fee.getValue();
            if(fee && +value < this.paymentMethod.limit.withdraw.min * 1.25) {
                this.amountLimit.next(AmountError.AMOUNT_FEE);
                this.amount.error.next('profile.balance.withdraw.input.amount');
            }
            else {
                this.amountLimit.next(AmountError.NONE);
                this.amount.error.next(null);
            }
            if(!fee && +value < this.paymentMethod.limit.withdraw.min) {
                this.amountLimit.next(AmountError.AMOUNT_MIN);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }
            if(+value > this.paymentMethod.limit.withdraw.max) {
                this.amountLimit.next(AmountError.AMOUNT_MAX);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }
            if( this.balance.getValue() < +value) {
                this.amountLimit.next(AmountError.AMOUNT_WITHDRAWAL);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }

        } else {
            this.amount.error.next(null);
        }
        this.checkValid();
    }

    onAmountActive(active: boolean) {
        if (active) {
            this.tip.next(ApayBDTFormError.AMOUNT);
        } else {
            this.tip.next(ApayBDTFormError.NOERROR);
        }
    }

    onAccountNumber(value: string) {
        if (this.accountNumber.getValue() && !AccountEasypaisaService.validate(value)) {
            this.valid.next(false);
        } else {
            this.checkValid();
            this.accountNumber.error.next(null);
        }
    }

    onAccountActive(active: boolean) {
        if (active) {
            this.tip.next(ApayBDTFormError.ACCOUNT);
        } else {
            this.tip.next(ApayBDTFormError.NOERROR);
        }
    }

    checkValid() {
        const isAmount = this.amount.isValid();
        const isAccountNumber = this.accountNumber.isValid();
        if (isAmount && isAccountNumber ) {
            this.valid.next(true);
        } else this.valid.next(false);
    }

    send() {
        if (this.valid.getValue() && this.paymentMethod) {
            const currencyId = this.paymentMethod.currency.ISO;
            const channelType = this.paymentMethod.id;
            const accountNumber =this.accountNumber.getValue()
            const accountName = !!UserDataService.data.getValue() ? UserDataService.data.getValue()!.email : ''
            const amount = parseInt(this.amount.getValue());

            const request: PaykassmaWithdrawRequest = {
                currencyId,
                channelType,
                accountNumber,
                accountName,
                bankCode: '',
                amount,
            };
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            GoogleService.sentWithdraw(
                userId,
                this.paymentMethod.title,
                amount.toString(),
                this.paymentMethod.currency.name,
                request.accountNumber
            );
            console.log('send', request);
            PaymentService.paykassma.withdraw(request);
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.AWAIT);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const status = PaykassmaWithdrawService.state.getValue();
        const fee = WithdrawService.fee.getValue();
        const isBonuses = BonusService.list.getValue().length;
        console.log('SUBMIT fee', fee);

        if (isBonuses) {
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.BONUS_REJECT);
            StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
        } else {
            if (!!fee) {
                if (status === PaykassmaWithdrawState.HAS_FEE) {
                    this.send();
                }
            } else {
                this.send();
            }
        }
    }

    reset() {
        this.amount.value.next('');
        this.accountNumber.value.next('');

        this.amount.error.next(null);
        this.accountNumber.error.next(null);

        this.accountNumber.active.next(false);
        this.accountNumber.active.next(false);
        this.tip.next(ApayBDTFormError.NOERROR);
        this.amountLimit.next(AmountError.NONE);
        this.valid.next(false);
    }
}

const ApayBDTFormService = new ApayBDTForm();
export { ApayBDTFormError };
export default ApayBDTFormService;
