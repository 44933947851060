import { translate as t } from 'service/Language';

import Spinner from './images/Spinner.svg';

import './ResultPage.scss';

function ProgressPage() {
    return (
        <div className="result">
            <div className="result__container">
                <p className="result__title ">{t('profile.balance.withdraw.progress.title')}</p>
                <img className="result__img" src={Spinner} />
            </div>
        </div>
    );
}

export default ProgressPage;
