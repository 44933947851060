import RxComponent from '../../../component/RxComponent/RxComponent';
import Tournament from '../../../service/model/Tournament';
import Face from './Face';

import './Card.scss';

type CardProps = {
    index: number;
    tournament: Tournament;
};
type CardState = {};

class Card extends RxComponent<CardProps, CardState> {
    render() {
        const { tournament, index } = this.props;

        return (
            <div className="tournament-card">
                <div className="tournament-card__inner">
                    <Face index={index} className="tournament-card__face" tournament={tournament} />
                </div>
            </div>
        );
    }
}

export default Card;
