import { PaykassmaPayment } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaPaymentCheck';
import { PaykassmaDepositCode, PaykassmaDepositResponse } from '@ay_tsarbet/newbet-core/dist/types';
import { BehaviorSubject } from 'rxjs';

import PaykassmaInvoice from '../../../../../../service/model/payment/PaykassmaInvoice';
import PaymentService from '../../../../../../service/Payment';
import { PaymentMethod } from '../../../PaymentMethod/types';
import TransactionFromService from './TransactionForm';
import StatusFormPopupService, { StatusForm } from '../../DepositPopup/service/StatusForm';

enum DepositState {
    AMOUNT,
    WAITINVOICE,
    INVOICE,
    WAIT,
    LINK,
    QR,
    FAILED,
    SUCCESS,
}

enum InvoiceState {
    INITIAL,
    TRANSACTION,
    WAIT,
    FAILED,
    SUCCESS,
}

enum WalletType {
    AGENT,
    PERSONAL,
    MERCHANT,
    NONE,
}

enum PaymentType {
    NONE,
    PAYTM,
    PHONE_PEE,
    GOOGLE_PAY,
}


const emptyInvoice = new PaykassmaInvoice('');

class PaykassmaDeposit {
    amount = 0;

    invoice: PaykassmaInvoice = emptyInvoice;

    state = new BehaviorSubject<DepositState>(DepositState.AMOUNT);

    transaction = new BehaviorSubject<InvoiceState>(InvoiceState.INITIAL);

    walletType = new BehaviorSubject<WalletType>(WalletType.NONE);

    paymentType = new BehaviorSubject<PaymentType>(PaymentType.NONE);

    link = new BehaviorSubject('');

    requestInvoice(paymentMethod: PaymentMethod) {
        PaymentService.paykassma.createInvoice({
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
        });
    }

    depositNagadP2CRequest(paymentMethod: PaymentMethod, amount: number) {
        PaymentService.paykassma.depositNagadP2C({
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
            accountNumber: '',
            amount,
        });
    }

    depositBKashP2CRequest(paymentMethod: PaymentMethod, amount: number, accountNumber: string) {
        PaymentService.paykassma.depositBKashP2C({
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
            accountNumber,
            amount,
        });
    }

    depositPaykassmaEasypaisaRequest(
        paymentMethod: PaymentMethod,
        accountNumber: string,
        email: string,
        amount: number
    ) {
        PaymentService.paykassma.depositEasypaisa({
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
            accountNumber,
            email,
            amount,
        });
    }

    depositApayBDTRequest(
        paymentMethod: PaymentMethod,
        phone: string,
        email: string,
        name: string,
        amount: number
    ) {
        PaymentService.paykassma.depositApayBDTRequest({
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
            phone,
            email,
            name,
            amount,
        });
    }

    setAmount(amount: number) {
        this.amount = amount;
    }

    getAmount(): number {
        return this.amount;
    }

    showInvoiceWait() {
        this.state.next(DepositState.WAITINVOICE);
    }

    showWait() {
        this.state.next(DepositState.WAIT);
    }

    showTransaction() {
        TransactionFromService.reset();
        this.state.next(DepositState.AMOUNT);
        this.transaction.next(InvoiceState.TRANSACTION);
    }

    showPreviousForm() {
        this.state.next(DepositState.INVOICE);
    }

    showFailedPage() {
        this.state.next(DepositState.FAILED);
        StatusFormPopupService.status.next(StatusForm.FAILED);
    }

    setWalletType(type: number) {
        if (type === 0) {
            this.walletType.next(WalletType.AGENT);
        } else if (type === 1) {
            this.walletType.next(WalletType.PERSONAL);
        } else if (type === 2) {
            this.walletType.next(WalletType.MERCHANT);
        } else {
            this.walletType.next(WalletType.NONE);
        }
    }

    reset() {
        this.amount = 0;
        this.link.next('');
        this.state.next(DepositState.AMOUNT);
        this.transaction.next(InvoiceState.INITIAL);
        TransactionFromService.reset();

    }

    resetInvoice() {
        this.invoice = emptyInvoice;
    }

    checkTransaction(payment: PaykassmaPayment) {
        PaymentService.paykassma.checkPayment(payment);

        if (this.state.getValue() === DepositState.INVOICE) {
            this.transaction.next(InvoiceState.INITIAL);
            this.state.next(DepositState.WAIT);
        }
        if (this.transaction.getValue() === InvoiceState.TRANSACTION) {
            this.state.next(DepositState.AMOUNT);
            this.transaction.next(InvoiceState.WAIT);
        }
    }

    onReceiveInvoice(invoice: PaykassmaInvoice) {
        this.invoice = invoice;

        const state = this.state.getValue();
        if (state === DepositState.WAITINVOICE) {
            this.state.next(DepositState.INVOICE);
        }
    }

    onDepositResponse(response: PaykassmaDepositResponse) {
        console.log('Paykassma | DEPOSIT RESPONSE', response);
        if (response.errorCode === PaykassmaDepositCode.success) {
            !!response.message && response.message !== 'status:ok'
                ? window.open(response.message, '_self')
                : this.state.next(DepositState.SUCCESS);
            StatusFormPopupService.status.next(StatusForm.SUCCESS);
        } else if (response.errorCode === 7) {
            this.state.next(DepositState.SUCCESS)
            StatusFormPopupService.status.next(StatusForm.SUCCESS);
        } else if (response.errorCode === 8 || response.errorCode === 10) {
            StatusFormPopupService.status.next(StatusForm.SUCCESS);
            this.link.next(response.message);
            this.state.next(DepositState.LINK)
        } else if (response.errorCode === 9) {
            const currentArray = response.message.split('#');
            this.link.next(currentArray[0]);
            this.state.next(DepositState.LINK);
            StatusFormPopupService.status.next(StatusForm.SUCCESS);
        } else {
            this.showFailedPage();
        }
    }

    back() {
        this.reset();
    }
}

const PaykassmaDepositService = new PaykassmaDeposit();

export { DepositState, InvoiceState, WalletType, PaymentType };
export default PaykassmaDepositService;
