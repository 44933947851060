import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import question from './images/tip.svg';

import './CorefyTip.scss';

type CorefyTipProps = {
    up?: boolean;
};

function CorefyTip({ up }: CorefyTipProps) {
    const [expanded, setExpanded] = useState(false);
    const expandedList = useRef<HTMLButtonElement>(null);

    function clickOutside(event: PointerEvent) {
        if (expandedList.current && !expandedList.current.contains(event.target as Node) && !expanded) {
            setExpanded(false);
        }
    }

    function clickExpanded() {
        setExpanded(!expanded);
    }

    useEffect(() => {
        document.addEventListener('pointerdown', clickOutside, false);

        return () => {
            document.removeEventListener('pointerdown', clickOutside, false);
        };
    }, []);

    const buttonClassName = classnames({
        'corefy-tip': true,
        'corefy-tip__active': expanded,
    });
    const tipClassName = classnames({
        'corefy-tip-back': true,
        'corefy-tip-back_up': up,
    });
    const imgClassName = classnames({
        'corefy-tip-back__img': true,
        'corefy-tip-back__img_up': up,
    });

    return (
        <div>
            <button ref={expandedList} type="button" className={buttonClassName} onClick={clickExpanded}>
                <img src={question} alt="" />
            </button>
            {expanded && (
                <div className={tipClassName}>
                    <div className={imgClassName} />
                </div>
            )}
        </div>
    );
}

export default CorefyTip;
