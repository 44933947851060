import { formatCurrencySign } from 'utils/format';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { translate as t } from '../../../../../../../service/Language';
import { PaymentMethod } from '../../../../PaymentMethod/types';

import Submit from '../Button/Submit';
import AmountFormService, { AmountError, UpayInputTip } from '../service/AmountForm';
import DisabledAmount from './DisabledAmount/DisabledAmount';
import Attention from '../../../../../../../assets/svg/attention.svg'
import PaykassmaDepositService, { DepositState } from '../../service/PaykassmaDeposit';
import SumSelector from '../SummSelector/SumSelector';

import './Amount.scss';


type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {
    amount: number,
    amountLimit: AmountError,
    errorTip: UpayInputTip,
    errorAmount: string | null,
};

class Amount extends RxComponent<AmountProps, AmountState> {
    state = {
        amount: 0,
        amountLimit: AmountError.NONE,
        errorTip: UpayInputTip.NONE,
        errorAmount: null,
    };

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountLimit: this.onAmountLimit.bind(this),
        onErrorTip: this.onErrorTip.bind(this),
        onErrorAmount: this.onErrorAmount.bind(this),
    };

    onAmount(amount: any) {
        this.setState({amount});
    }

    onAmountLimit(amountLimit: AmountError) {
        this.setState({amountLimit});
    }

    onErrorTip(errorTip: UpayInputTip) {
        this.setState({errorTip});
    }

    onErrorAmount(errorAmount: string | null) {
        this.setState({errorAmount});
    }

    componentDidMount() {
        const {paymentMethod} = this.props;

        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setValue(paymentMethod.defaultValue);
        this.subscribe(AmountFormService.errorTip, {next: this.handler.onErrorTip});
        this.subscribe(AmountFormService.amount.error, {next: this.handler.onErrorAmount});
        this.subscribe(AmountFormService.amountLimit, {next: this.handler.onAmountLimit});
    }

    render() {
        const {paymentMethod, lock} = this.props;
        const {amountLimit, errorTip, errorAmount} = this.state;

        const min = formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency);
        const max = formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency);

        const hint = `${t('popup.payment.amount.min')} ${min} — ${t('popup.payment.amount.max')} ${max}`;

        const amount = PaykassmaDepositService.state.getValue() === DepositState.AMOUNT || PaykassmaDepositService.state.getValue() === DepositState.WAIT;

        return (
            <div className="deposit-amount-upay">
                <form className="deposit-amount-upay-form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    <div className="deposit-amount-upay-form-data">
                        {amount ? (
                            <>
                                <div className="deposit-amount-upay-form-data-block">
                                    <MaskedInput
                                        mask="00000000000"
                                        inputService={AmountFormService.amount}
                                        className="deposit-amount-upay-form-data-block__input"
                                        name="amount"
                                        enabled={!lock}
                                    />
                                    <span className="deposit-amount-upay-form-data-block__currency">
                                {paymentMethod.currency.sign}
                            </span>
                                    {!!errorAmount && (
                                        <div className="deposit-amount-upay-form-data-block__error">
                                            <img src={Attention} alt="attention"/>
                                            <span className="deposit-amount-upay-form-data-block__error_text">
                        {amountLimit === AmountError.AMOUNT_MIN && t('popup.payment.amount.min') + ` ${min}`}
                                                {amountLimit === AmountError.AMOUNT_MAX && t('popup.payment.amount.max') + ` ${max}`}
                                    </span>
                                        </div>
                                    )}
                                    {errorTip === UpayInputTip.AMOUNT && !errorAmount ? (
                                        <span className="deposit-amount-upay-form-data-block__tip">
                                {hint}
                            </span>
                                    ) : null}
                                </div>
                                <SumSelector paymentMethod={paymentMethod} disabled={lock}/>
                            </>
                        ) : (
                            <DisabledAmount hint={hint}/>
                        )}</div>
                    <div className="deposit-amount-upay-form__submit"><Submit/></div>
                </form>
            </div>
        );
    }
}

export default Amount;
