import Search from 'component/Search/Search';
import { useState } from 'react';

import SideLinkList from './SideLinkList/SideLinkList';

import './SideNavigation.scss';

function SideNavigation() {
    const [searchFocused, setSearchFocused] = useState(false);
    return (
        <div className="casino-side-navigation">
            <Search onFocus={() => setSearchFocused(true)} onBlur={() => setSearchFocused(false)} />
            <SideLinkList active={!searchFocused} />
        </div>
    );
}

export default SideNavigation;
