import { BehaviorSubject } from 'rxjs';

import GeoDataService from './GeoData';
import Country, { country } from './model/Country';
import GeoData from './model/GeoData';

enum GeoPermission {
    AWAIT,
    ALLOW,
    DENY,
}

class CountryPermission {
    list: Country[] = [country.GreatBritain, country.UnitedStatesAmerica, country.France];

    result = new BehaviorSubject<GeoPermission>(GeoPermission.AWAIT);

    handler = {
        onGeoDataUpdate: this.onGeoDataUpdate.bind(this),
    };

    constructor() {
        GeoDataService.data.subscribe(this.handler.onGeoDataUpdate);
    }

    check() {
        GeoDataService.requestGeoData();
    }

    onGeoDataUpdate(geoData: GeoData | null) {
        console.log('geoData', geoData);
        if (geoData) {
            const match = this.list.includes(geoData.country);
            this.result.next(match ? GeoPermission.DENY : GeoPermission.ALLOW);
        }
        if(document.referrer.includes("instagram.com")) {
            console.log('instagram')
            document.body.style.overflowY = "auto";
        }
        const searchParams = new URLSearchParams(window.location.search);
        const utmSource = searchParams.get("utm_source");

        if(utmSource && +utmSource === 65) {
            document.body.style.overflowY = "auto";
            console.log('utmSource', utmSource)
        }
    }
}

const CountryPermissionService = new CountryPermission();
export { GeoPermission };
export default CountryPermissionService;
