import { NavLink, useLocation } from 'react-router-dom';
import { translate as t } from 'service/Language';

import RouteConfig from '../../../appConstants/RouteConfig';

import './SignIn.scss';

function SignIn() {
    const location = useLocation();
    return (
        <div className="header__sign-in">
            <NavLink to={{ hash: RouteConfig.hash.auth, search: location.search }} className="header__sign-in__link">
                {t('header.sign.in')}
            </NavLink>
        </div>
    );
}

export default SignIn;
