import Validator from '../interface/Validator';

class CPF implements Validator {
    validate(value: string): boolean {
        return value.length === 14;
    }
}
const CPFService = new CPF();

export { CPFService };

export default CPF;
