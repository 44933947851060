import useObservable from 'utils/useObservable';

import RouteConfig from '../../appConstants/RouteConfig';
import AuthService from '../../service/Auth';
import Wheel from './Wheel/Wheel';

import '../../scss/global.scss';
import './Landing.scss';

const HardRedirect = (props: { to: string }) => {
    window.document.location = props.to + window.location.search + `#${RouteConfig.hash.deposit}`;

    return null;
};

function Landing() {
    const isAuth = useObservable(AuthService.auth, AuthService.auth.getValue());

    return <>{!!isAuth || AuthService.checkAuthOnce() ? <HardRedirect to={RouteConfig.casino.root} /> : <Wheel />}</>;
}

export default Landing;
