import classNames from 'classnames';
import { translate as t } from 'service/Language';

import useObservable from '../../../../../../../utils/useObservable';
import AmountFormService from '../../service/AmountForm';
import PayfixDepositService, { DepositState } from '../../service/PayfixDeposit';

import './Submit.scss';
import Spinner from '../../../Apay/Bankalfallah/Button/image/Spinner.svg';

type SubmitProps = {
    className?: string;
};

function Submit(props: SubmitProps) {
    const {className} = props;
    const status = useObservable(PayfixDepositService.state, PayfixDepositService.state.getValue());
    const valid = useObservable(AmountFormService.valid, AmountFormService.valid.getValue());

    const _className = classNames({
        [className || '']: !!className,
        'deposit-submit': true,
        'deposit-submit__active': valid,
    });

    return (
        <button
            type="submit"
            className={_className}
            onClick={(e) => AmountFormService.onSubmit(e)}
            disabled={!valid || status === DepositState.WAIT}
        >
            {status === DepositState.WAIT ? (
                <img className="deposit-submit__wait" src={Spinner} alt="spinner"/>
            ) : (
                t('profile.balance.deposit.button.next')
            )}
        </button>
    );
}

export default Submit;
