/* eslint-disable max-len */

import { Icon } from 'types';

function ActiveSearch({ color = '#FDB43C', width = 34, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 34 28" fill="none">
            <path
                d="M30.6084 18.9153C30.8217 18.3465 30.5334 17.7124 29.9646 17.4991C29.3958 17.2858 28.7617 17.5741 28.5484 18.1429L30.6084 18.9153ZM15.1981 26.693L15.3944 25.6107L15.1981 26.693ZM4.59961 14L5.39307 13.2382C5.19091 13.0276 4.91336 12.906 4.62153 12.9002C4.3297 12.8944 4.04752 13.0048 3.83713 13.2071L4.59961 14ZM6.20654 17.2618C6.62729 17.7001 7.32363 17.7142 7.76185 17.2935C8.20007 16.8727 8.21422 16.1764 7.79346 15.7382L6.20654 17.2618ZM1.23752 15.7071C0.799637 16.1282 0.786035 16.8246 1.20714 17.2625C1.62825 17.7004 2.3246 17.714 2.76248 17.2929L1.23752 15.7071ZM28.5484 18.1429C27.5779 20.7313 25.7293 22.8968 23.3253 24.2616L24.4115 26.1748C27.2637 24.5556 29.4569 21.9863 30.6084 18.9153L28.5484 18.1429ZM23.3253 24.2616C20.9213 25.6264 18.1144 26.1039 15.3944 25.6107L15.0019 27.7754C18.2291 28.3605 21.5592 27.7941 24.4115 26.1748L23.3253 24.2616ZM15.3944 25.6107C12.6743 25.1175 10.2138 23.685 8.44197 21.563L6.75326 22.9731C8.85539 25.4906 11.7747 27.1902 15.0019 27.7754L15.3944 25.6107ZM8.44197 21.563C6.67017 19.4411 5.69961 16.7644 5.69961 14H3.49961C3.49961 17.2798 4.65112 20.4555 6.75326 22.9731L8.44197 21.563ZM3.80615 14.7618L6.20654 17.2618L7.79346 15.7382L5.39307 13.2382L3.80615 14.7618ZM3.83713 13.2071L1.23752 15.7071L2.76248 17.2929L5.36209 14.7929L3.83713 13.2071Z"
                fill={color}
            />
            <path
                d="M3.96975 9.98507C3.75646 10.5539 4.04469 11.188 4.61352 11.4013C5.18236 11.6145 5.81641 11.3263 6.0297 10.7575L3.96975 9.98507ZM19.38 2.20735L19.1837 3.28971L19.38 2.20735ZM29.9785 14.9004L29.1851 15.6622C29.3872 15.8728 29.6648 15.9944 29.9566 16.0002C30.2484 16.006 30.5306 15.8956 30.741 15.6932L29.9785 14.9004ZM28.3716 11.6385C27.9508 11.2003 27.2545 11.1862 26.8163 11.6069C26.3781 12.0277 26.3639 12.724 26.7847 13.1622L28.3716 11.6385ZM33.3406 13.1932C33.7785 12.7721 33.7921 12.0758 33.371 11.6379C32.9499 11.2 32.2535 11.1864 31.8156 11.6075L33.3406 13.1932ZM6.0297 10.7575C7.00027 8.16906 8.8488 6.00355 11.2528 4.63876L10.1667 2.72557C7.31445 4.34482 5.12127 6.91406 3.96975 9.98507L6.0297 10.7575ZM11.2528 4.63876C13.6568 3.27396 16.4637 2.79651 19.1837 3.28971L19.5762 1.125C16.3491 0.539854 13.0189 1.10632 10.1667 2.72557L11.2528 4.63876ZM19.1837 3.28971C21.9038 3.7829 24.3644 5.21543 26.1362 7.33738L27.8249 5.92733C25.7227 3.40976 22.8034 1.71015 19.5762 1.125L19.1837 3.28971ZM26.1362 7.33738C27.908 9.45933 28.8785 12.136 28.8785 14.9004H31.0785C31.0785 11.6206 29.927 8.44489 27.8249 5.92733L26.1362 7.33738ZM30.772 14.1385L28.3716 11.6385L26.7847 13.1622L29.1851 15.6622L30.772 14.1385ZM30.741 15.6932L33.3406 13.1932L31.8156 11.6075L29.216 14.1075L30.741 15.6932Z"
                fill={color}
            />
        </svg>
    );
}

export default ActiveSearch;
