import { Link } from 'react-router-dom';
import { translate as t } from 'service/Language';
import SvgImage from 'svg';
import useObservable from 'utils/useObservable';

import colors from '../../../../appConstants/colors';
import RegistrationFormService from '../service/RegistrationForm';

import './AgeCheckbox.scss';

function AgeCheckbox() {
    const acceptCheckboxStatus = useObservable<boolean>(
        RegistrationFormService.ageAgreement,
        RegistrationFormService.ageAgreement.value
    );

    return (
        <div className="registration-age-checkbox">
            <button
                onClick={() => RegistrationFormService.toggleAge()}
                className="registration-age-checkbox__check"
                type="button"
            >
                {acceptCheckboxStatus && <SvgImage width={9} height={9} color={colors.royalBlue} name="Check" />}
            </button>
            <div>{t('registration.age.checkbox.accept')}</div>
        </div>
    );
}

export default AgeCheckbox;
