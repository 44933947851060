/* eslint-disable max-len */

import { Icon } from 'types';

function BigRegbieUnion({ color = '#3959D9', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                d="M14.3691 12.7565V8.94103H13.0779V12.688C13.0779 13.6476 12.5342 13.9903 11.7187 13.9903C10.9032 13.9903 10.3595 13.6476 10.3595 12.688V8.94103H9.06831V12.7565C9.06831 14.5615 10.3822 15.1784 11.7187 15.1784C13.0553 15.1784 14.3691 14.5615 14.3691 12.7565Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.78356 4.8742C11.4468 -0.449237 18.8317 -1.5916 22.2524 2.33814C23.9513 4.28017 24.427 7.13609 23.6115 10.3804C22.8187 13.4877 20.8932 16.5949 18.1975 19.131C14.3464 22.7408 9.70254 24.4315 6.0554 23.906C4.33376 23.6776 2.83866 22.9236 1.72866 21.667C-1.69196 17.7373 0.120292 10.1976 5.78356 4.8742ZM14.573 15.9323C18.8091 10.8145 21.0064 4.64572 19.3754 2.49807C19.1262 2.17821 18.8091 1.97258 18.4013 1.8355C18.356 1.82407 18.3164 1.81265 18.2767 1.80123C18.2371 1.7898 18.1974 1.77838 18.1521 1.76696C18.1182 1.76696 18.0785 1.76125 18.0389 1.75553C17.9992 1.74982 17.9596 1.74411 17.9256 1.74411C17.1554 1.65272 16.1587 1.88119 15.026 2.42953C12.9419 3.45766 10.6087 5.42253 8.50193 7.98144C6.3952 10.5403 4.74152 13.3734 3.8354 15.9552C3.04254 18.2399 2.95192 20.1134 3.56356 21.1872C4.17519 21.6442 4.85478 21.9869 5.62499 22.1697C7.9356 22.0554 11.4468 19.7021 14.573 15.9323ZM15.0034 1.74411C12.3756 2.15536 9.43071 3.66329 6.84825 6.08511C1.95519 10.7003 0.165597 16.9605 2.70274 20.2962C2.52152 19.0853 2.72539 17.5316 3.35968 15.7038C4.28846 13.0307 5.98744 10.1291 8.16214 7.50164C10.3368 4.8742 12.7154 2.86363 14.8674 1.81265L15.0034 1.74411ZM17.1328 17.8972C19.6019 15.5668 21.3462 12.7565 22.0711 9.94631C22.7507 7.27317 22.3883 4.96559 21.0517 3.43481C20.7573 3.11495 20.4401 2.84078 20.0777 2.58946C21.3462 5.37684 19.2168 11.2258 14.9128 16.4121C12.4436 19.4051 9.81581 21.4842 7.61846 22.3753C10.5634 22.3067 14.0972 20.7531 17.1328 17.8972Z"
                fill={color}
            />
        </svg>
    );
}

export default BigRegbieUnion;
