import { BehaviorSubject } from 'rxjs';

import {CryptoMethodType, PaymentMethod} from '../../PaymentMethod/types';
import AnindaHavaleDepositService from '../AnindaHavale/service/AnindaHavaleDeposit';
import AzarpayDepositService from '../Azarpay/service/AzarpayDeposit';
import EpulDepositService from '../Epul/service/EpulDeposit';
import InstapayDepositService from '../Instapay/service/InstapayDeposit';
import PaycosDepositService from '../Paycos/service/PaycosDeposit';
import PaykassmaDepositService from '../Paykassma/service/PaykassmaDeposit';

class Deposit {
    method = new BehaviorSubject<PaymentMethod | null>(null);

    criptoMethod = new BehaviorSubject<CryptoMethodType | null>(null);

    reset() {
        this.method.next(null);
    }

    pickMethod(method: PaymentMethod) {
        this.method.next(method);
    }

    criptoPickMethod(method: CryptoMethodType) {
        console.log('criptoPickMethod', method)
        this.criptoMethod.next(method);
    }

    back() {
        this.reset();
        PaykassmaDepositService.reset();
        PaycosDepositService.reset();
        EpulDepositService.reset();
        InstapayDepositService.reset();
        AzarpayDepositService.reset();
        AnindaHavaleDepositService.reset();
    }
}

const DepositService = new Deposit();

export default DepositService;
