import classNames from 'classnames';

import GameSortService from '../../../service/GameSort';
import { translate as t } from '../../../service/Language';
import useObservable from '../../../utils/useObservable';
import arrow from '../image/arrow.svg';
import SortAndFilterService from '../service/SortAndFilter';

import './Button.scss';

function Button() {
    const open = useObservable(SortAndFilterService.toggle.sort, SortAndFilterService.toggle.sort.getValue());
    const method = useObservable(GameSortService.method, GameSortService.method.getValue());

    const className = classNames({
        'game-sort-toggle': true,
        'game-sort-toggle____open': open,
    });

    return (
        <button className={className} onClick={() => SortAndFilterService.toggleSort()}>
            <div className="game-sort-toggle__title">{t(method.name)}</div>
            <img className="game-sort-toggle__arrow" src={arrow} alt="" />
        </button>
    );
}

export default Button;
