import {PaymentMethod} from '../../PaymentMethod/types';

import useObservable from '../../../../../utils/useObservable';
import { useEffect } from 'react';
import ResultForm from '../Coinspaid/ResultForm/ResultForm';
import CoinspaidWithdrawService, {CryptoWithdrawState} from './service/CoinspaidWithdrawal';
import Crypto from './Crypto/Crypto';
import CoinspaidDepositService from '../../Deposit/Coinspaid/service/CoinspaidDeposit';
import Spinner from '../../../../../svg/spinnerBlue.svg';

import './Form.scss';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(CoinspaidWithdrawService.state, CoinspaidWithdrawService.state.getValue());
    const data = useObservable(CoinspaidDepositService.rate, CoinspaidDepositService.rate.getValue());

    useEffect(() => {
        return ()=> {
            CoinspaidWithdrawService.reset();
        }
    }, []);

    if (paymentMethod) {
        if (
            state === CryptoWithdrawState.INIT ||
            state === CryptoWithdrawState.AWAIT ||
            state === CryptoWithdrawState.HAS_FEE
        ) {
            return (
                 !!data ? <Crypto paymentMethod={paymentMethod} status={state} data={data} />
                    : <div className="withdrawal-form-block"><img className="withdrawal-form-block__spinner" src={Spinner} alt=""/></div>
            );
        } else {
            return <ResultForm/>;
        }
    }

    return null;
}

export default Form;
