import { translate as t } from 'service/Language';

import Input from '../../../../../../../component/Input/Input';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { formatCurrencySign } from '../../../../../../../utils/format';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import IMPSFormService, { IMPSFormError } from '../../service/IMPSForm';
import PaykassmaWithdrawService, { PaykassmaWithdrawState } from '../../service/PaykassmaWithdraw';
import WithdrawService from '../../../service/Withdrawal';
import AttentionWithdrawalTime from '../../../../../../../component/AttentionWithdrawalTime/AttentionWithdrawalTime';
import WithdrawalButtonIMPS from '../../WithdrawalButtonIMPS/WithdrawalButtonIMPS';

import './IMPS.scss';
import Attention from '../../../../../../../assets/svg/attention.svg';


type IMPSProps = {
    paymentMethod: PaymentMethod;
    status: PaykassmaWithdrawState;
};
type IMPSState = {
    value: string;
    errorTip: IMPSFormError;
    error: string;
};

class IMPS extends RxComponent<IMPSProps, IMPSState> {
    state = {
        value: '',
        error: '',
        errorTip: IMPSFormError.NOERROR,
    };

    handler = {
        getValue: this.getValue.bind(this),
        onErrorTip: this.onErrorTip.bind(this),
        onError: this.onError.bind(this),
    };

    onError(error: string) {
        this.setState({error});
    }

    onErrorTip(errorTip: IMPSFormError) {
        this.setState({errorTip});
    }

    getValue(value: string) {
        this.setState({value});
    }

    componentDidMount() {
        const {paymentMethod} = this.props;
        IMPSFormService.setPaymentMethod(paymentMethod);

        this.subscribe(IMPSFormService.error, {next: this.handler.onError});
        this.subscribe(IMPSFormService.errorTip, {next: this.handler.onErrorTip});
        this.subscribe(IMPSFormService.amount.value, {next: this.handler.getValue});
    }

    render() {
        const {paymentMethod, status} = this.props;
        const {value, errorTip, error} = this.state;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        const fee = WithdrawService.fee.getValue();

        const isInit = status === PaykassmaWithdrawState.INIT;
        if (fee && isInit) {
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.HAS_FEE)
        }
        const amountFee = formatCurrencySign(paymentMethod.limit.withdraw.min * 1.25, 0, paymentMethod.currency);

        return (
            <div className="imps-withdrawal">
                <div className="imps-withdrawal-header">
                    <img src={paymentMethod.icon} alt={paymentMethod.slug}/>
                    <h2 className="imps-withdrawal-header__title">{t(paymentMethod.title)}</h2>
                </div>
                <form className="imps-withdrawal-form" onSubmit={(e) => IMPSFormService.onSubmit(e)}>
                    <div className="imps-withdrawal-form-block">
                        <Input
                            className="imps-withdrawal-form-block__input"
                            inputService={IMPSFormService.amount}
                            name="amount"
                            type="number"
                            hint={hint}
                        />
                        <span className="imps-withdrawal-form-block__currency">{paymentMethod.currency.sign}</span>
                        {!!error ? (
                            <div className="imps-withdrawal-form-block__error">
                                <img src={Attention} alt="attention"/>
                                <span className="imps-withdrawal-form-block__error_text">
                                {amountFee} {t(error)}
                            </span>
                            </div>
                        ) : null}
                    </div>
                    <div className="imps-withdrawal-form-block">
                        <MaskedInput
                            className="imps-withdrawal-form-block__input"
                            mask="00000000000000000000"
                            inputService={IMPSFormService.accountNumber}
                            name="accountNumber"
                        />
                    </div>
                    <div className="imps-withdrawal-form-block">
                        <Input
                            className="imps-withdrawal-form-block__input"
                            inputService={IMPSFormService.accountName}
                            name="name"
                        />
                        {errorTip === IMPSFormError.NAME && (
                            <span className="imps-withdrawal-form-block__tip">
                                {t('popup.balance.deposit.input.name')}
                            </span>
                        )}
                    </div>
                    <div className="imps-withdrawal-form-block">
                        <Input
                            className="imps-withdrawal-form-block__input"
                            inputService={IMPSFormService.bankCode}
                            name="bankCode"
                            placeholder="AAAA0XXXXXX"
                            isUpperCase
                        />
                    </div>

                    {((status === PaykassmaWithdrawState.HAS_FEE || status === PaykassmaWithdrawState.AWAIT) && fee) ?
                        <FeeNotice amount={value} fee={fee}/> : null}
                    <AttentionWithdrawalTime />

                    {(status === PaykassmaWithdrawState.HAS_FEE || status === PaykassmaWithdrawState.AWAIT) ? (
                        <>
                            <WithdrawalButtonIMPS
                                buttonTitle="profile.balance.withdraw.fee.agree"
                                variation={true}
                                paymentMethod={paymentMethod}
                                amount={value}
                                state={status}
                            />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel"/>
                        </>
                    ) : (
                        <WithdrawalButtonIMPS buttonTitle="profile.balance.withdraw.button" state={status}/>
                    )}
                </form>
            </div>
        );
    }
}

export default IMPS;
