import BanService from '../../service/Ban';
import { translate as t } from '../../service/Language';
import useObservable from '../../utils/useObservable';
import Header from './Header/Header';
import sadMan from './image/sad_man.svg';

import './Ban.scss';

function Ban() {
    const banned = useObservable(BanService.state, BanService.state.getValue());

    return banned ? (
        <div className="ban-overlay">
            <Header />
            <div className="ban-overlay__center">
                <img src={sadMan} alt="banned" />
                <div className="ban-overlay__text">
                    <div className="ban-overlay__title">{t('page.ban.title')}</div>
                    <div>
                        {t('page.ban.text')} {t('app.global.email.support')}
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}

export default Ban;
