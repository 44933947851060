import { ClientInfo } from '@ay_tsarbet/newbet-core/dist/types';

import AuthService from '../../service/Auth';
import RxComponent from '../RxComponent/RxComponent';
import Controls from './Controls/Controls';
import UserInfo from './UserInfo/UserInfo';

type AuthState = {
    auth: ClientInfo | null;
};

type AuthProps = {
    isInfoNotification: number;
};

class Auth extends RxComponent<AuthProps, AuthState> {
    handler = {
        setAuth: this.setAuth.bind(this),
    };

    state = {
        auth: null,
    };

    setAuth(auth: ClientInfo | null) {
        this.setState({ auth });
    }

    componentDidMount() {
        this.subscribe(AuthService.auth, { next: this.handler.setAuth });
    }

    render() {
        const { auth } = this.state;
        const { isInfoNotification } = this.props;

        return auth ? <UserInfo isInfoNotification={isInfoNotification} /> : <Controls />;
    }
}

export default Auth;
