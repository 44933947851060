import ItemBlock from './ItemBlock/ItemBlock';
import BlockAviatorService from './service/BlockAviator';

import './InfoBlock.scss';

function InfoBlock() {
    return (
        <div className="info-block">
            <div className="info-block_desktop">
                <ItemBlock list={BlockAviatorService.AVIATOR_LIST_LEFT} />
                <ItemBlock list={BlockAviatorService.AVIATOR_LIST_RIGHT} />
            </div>
            <div className="info-block_tablet">
                <ItemBlock list={BlockAviatorService.AVIATOR_LIST_LEFT} />
                <ItemBlock list={BlockAviatorService.AVIATOR_LIST_RIGHT} />
            </div>
            <div className="info-block_mobile">
                <ItemBlock list={BlockAviatorService.AVIATOR_LIST_LEFT} />
            </div>
        </div>
    );
}

export default InfoBlock;
