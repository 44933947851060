import { BehaviorSubject, filter } from 'rxjs';
import Storage from './Storage';
import { CountryType } from './api/Counties';

class CountrySupport {
    STORAGE_COUNTRY_SUPPORT: string = 'countryCode';

    public countryCode = new BehaviorSubject<string | null>(null);
    public searchText = new BehaviorSubject<string>('');
    public countries = new BehaviorSubject<CountryType[]>([]);
    public filteredCountries = new BehaviorSubject<CountryType[]>([]);

    setCountries(countries: CountryType[]) {
        this.countries.next(countries);
        this.filteredCountries.next(countries);
    }

    setCountryCode(code: string) {
        this.countryCode.next(code);

        Storage.set(this.STORAGE_COUNTRY_SUPPORT, code);
    }

    setSearchText(text: string) {
        this.searchText.next(text);
        if (this.searchText.getValue()) {
            this.filteredCountries.next(
                this.countries.value.filter(option =>
                    option.name.toLowerCase().includes(this.searchText.value.toLowerCase()),
                ),
            );
        } else {
            this.filteredCountries.next(this.countries.value);
        }

        // if (text.length < MIN_SEARCH_QUERY_LENGTH) {
        //     this.searchResultGames.next([]);
        //     this.searchResultProviders.next([]);
        // } else {
        //     this.searchResultGames.next(
        //         this.games.value.filter((game) => {
        //             const nameMatch = game.name.toLowerCase().includes(text.toLowerCase());
        //             const allow = this.isAllowed(game.provider);
        //             const assert = [nameMatch, allow];
        //             return !assert.includes(false);
        //         })
        //     );
        //     this.searchResultProviders.next(
        //         this.providers.value.filter((provider) => {
        //             const nameMatch = provider.name.toLowerCase().includes(text.toLowerCase());
        //             const allow = this.isAllowed(provider);
        //             const assert = [nameMatch, allow];

        //             return !assert.includes(false);
        //         })
        //     );
        // }
    }
}

const CountrySupportService = new CountrySupport();

export default CountrySupportService;
