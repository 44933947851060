/* eslint-disable max-len */

import { Icon } from 'types';

function LiveIcon({ width = 9, height = 9 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <circle opacity="0.4" cx="4.5" cy="4.5" r="4.5" fill="#FC4665" />
            <circle cx="4.5" cy="4.5" r="2.5" fill="#FC4665" />
        </svg>
    );
}

export default LiveIcon;
