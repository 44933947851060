import { useEffect } from 'react';
import GoogleService from 'service/Google';
import OperationsService from 'service/Operations';
import useObservable from 'utils/useObservable';
import Visa from 'assets/svg/payments/visa.svg';

import { PaymentMethod } from '../../../PaymentMethod/types';
import FailedPage from '../../ResultPage/FailedPage/FailedPage';
import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import CorefyDepositService, { DepositState } from '../service/CorefyDeposit';
import Amount from './Amount/Amount';
import DepositFrame from './DepostFrame/DepositFrame';
import classNames from 'classnames';
import {Currency as CurrencyType} from '@ay_tsarbet/newbet-core/dist/types';

import './Form.scss';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const status = useObservable(CorefyDepositService.state, CorefyDepositService.state.getValue());
    const link = useObservable(CorefyDepositService.link, CorefyDepositService.link.getValue());

    useEffect(() => {
        const gaSub = OperationsService.invoice.subscribe({
            next: (data) => {
                const clientId = GoogleService.getClientId();
                const invoiceId = data.id.toString();
                OperationsService.sendGAClientRelation(clientId, invoiceId);
            },
        });
        return () => {
            gaSub.unsubscribe();
            CorefyDepositService.reset();
        };
    }, []);

    useEffect(()=> {
        const isAZN = paymentMethod.currency.ISO === CurrencyType.AZN;
        if(link && status === DepositState.LINK && isAZN) {
            window.open(link, '_self')
        }
    }, [link, status])

    const classForm = classNames('deposit-form-corefy', {
        'deposit-form-corefy__frame': status === DepositState.LINK,
    })
    const icon = paymentMethod.currency.ISO === CurrencyType.KZT ? Visa : paymentMethod.icon;
    const title = paymentMethod.currency.ISO === CurrencyType.KZT ? 'Visa' : paymentMethod.title;
    const isAZN = paymentMethod.currency.ISO === CurrencyType.AZN;

    return (
        <div className={classForm} >
            {status !== DepositState.FAILED && status !== DepositState.SUCCESS ?
            <div className="deposit-form-corefy-header">
                <img className="deposit-form-corefy-header__img" src={icon} alt={paymentMethod.title} />
                <h2 className="deposit-form-corefy-header__title">{title}</h2>
            </div>: null}
            {status === DepositState.AMOUNT || status === DepositState.WAIT ? (
                <Amount paymentMethod={paymentMethod} lock={status !== DepositState.AMOUNT} />
            ) : null}
            {link && status === DepositState.LINK && !isAZN && <DepositFrame link={link} />}
            {status === DepositState.SUCCESS ? <SuccessPage /> : null}
            {status === DepositState.FAILED ? <FailedPage /> : null}
        </div>
    );
}

export default Form;
