import { BehaviorSubject } from 'rxjs';

import AmountFormService from './AmountForm';
import { PaymentMethod } from '../../../PaymentMethod/types';
import PaymentService from '../../../../../../service/Payment';
import StatusFormPopupService, { StatusForm } from '../../DepositPopup/service/StatusForm';

enum DepositState {
    AMOUNT,
    WAIT_INVOICE,
    INVOICE,
    WAIT,
    LINK,
    FAILED,
    SUCCESS,
}


class CashixDeposit {
    amount = 0;

    link = new BehaviorSubject('');

    state = new BehaviorSubject<DepositState>(DepositState.AMOUNT);

    request(paymentMethod: PaymentMethod, amount: number) {
        this.state.next(DepositState.WAIT);
        PaymentService.cashix.depositP2C({
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
            amount,
        });
    }

    setAmount(amount: number) {
        this.amount = amount;
    }

    getAmount(): number {
        return this.amount;
    }

    reset() {
        this.amount = 0;
        this.link.next('');
        this.state.next(DepositState.AMOUNT);
        AmountFormService.reset();
    }

    onError() {
        this.state.next(DepositState.FAILED);
        StatusFormPopupService.status.next(StatusForm.FAILED);
    }

    onReceive(link: string) {
        const state = this.state.getValue();
        if (state === DepositState.WAIT) {
            this.link.next(link);
            window.open(link, '_self')
            StatusFormPopupService.status.next(StatusForm.SUCCESS);
        }
    }

    back() {
        this.reset();
    }
}

const CashixDepositService = new CashixDeposit();

export { DepositState };
export default CashixDepositService;
