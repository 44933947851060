import Validator from '../interface/Validator';

class UPITransaction implements Validator {
    validate(value: string): boolean {
        return this.numberCheck(value) && this.lengthCheck(value);
    }

    numberCheck(value: string): boolean {
        return new RegExp('^[0-9]+$').test(value);
    }

    lengthCheck(value: string): boolean {
        return value.length === 12;
    }
}

const UPITransactionService = new UPITransaction();

export { UPITransactionService };
export default UPITransaction;
