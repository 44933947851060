import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';
import { PaymentMethod } from '../../../PaymentMethod/types';

import WithdrawService from '../../service/Withdrawal';
import BalanceService from '../../../../../../service/Balance';
import { formatBangladeshPhoneForBack } from '../../../../../../utils/format';
import WellxPayWithdrawService, {WellxPayWithdrawState} from './WellxPayWithdraw';
import {WellxpayWithdrawRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendWellxpayWithdraw';
import BonusService from '../../../../../../service/Bonus';
import StatusFormPopupService, {StatusForm} from '../../../Deposit/DepositPopup/service/StatusForm';

export enum WellxPayInputTip {
    NONE,
    AMOUNT,
    PHONE,
}

export enum AmountError {
    NONE,
    AMOUNT_MIN,
    AMOUNT_MAX,
    AMOUNT_WITHDRAWAL,
    AMOUNT_FEE,
}

class WellxPayForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(1, 1000000);

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.withdraw.input.amount',
    });

    phone = new InputService({
        title: 'profile.balance.withdraw.input.phone',
        validator: Validator.phonePaykassma,
        validatorText: 'profile.balance.withdraw.input.phone',
    });

    tip = new BehaviorSubject<WellxPayInputTip>(WellxPayInputTip.NONE);

    amountLimit = new BehaviorSubject<AmountError>(AmountError.NONE);

    autoCompleted = new BehaviorSubject<boolean>(false);

    valid = new BehaviorSubject<boolean>(false);

    balance = new BehaviorSubject(0);

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        onPhone: this.onPhone.bind(this),
        onPhoneActive: this.onPhoneActive.bind(this),
        onBalance: this.onBalance.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
        this.phone.value.subscribe(this.handler.onPhone);
        this.phone.active.subscribe(this.handler.onPhoneActive);
        BalanceService.balance.subscribe(this.handler.onBalance);
    }

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        const limit = this.paymentMethod.limit.withdraw;
        const isFee = WithdrawService.fee.getValue();
        const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
        this.limitValidator.setLimit(isFee ? isMin : limit.min, limit.max);
    }

    onAmount(value: string) {
        const isValue = this.amount.value.getValue();
        if (this.paymentMethod && isValue) {
            const fee = WithdrawService.fee.getValue();
            if(fee && +value < this.paymentMethod.limit.withdraw.min * 1.25) {
                this.amountLimit.next(AmountError.AMOUNT_FEE);
                this.amount.error.next('profile.balance.withdraw.input.amount');
            }
            else {
                this.amountLimit.next(AmountError.NONE);
                this.amount.error.next(null);
            }
            if(!fee && +value < this.paymentMethod.limit.withdraw.min) {
                this.amountLimit.next(AmountError.AMOUNT_MIN);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }
            if(+value > this.paymentMethod.limit.withdraw.max) {
                this.amountLimit.next(AmountError.AMOUNT_MAX);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }
            if( this.balance.getValue() < +value) {
                this.amountLimit.next(AmountError.AMOUNT_WITHDRAWAL);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }

        } else {
            this.amount.error.next(null);
        }
        this.checkValid();
    }

    onBalance(balance: number) {
        this.balance.next(balance)
    }

    onAmountActive(active: boolean) {
        if (active) {
            this.tip.next(WellxPayInputTip.AMOUNT);
        } else {
            this.tip.next(WellxPayInputTip.NONE);
        }
    }

    onPhone(value: string) {
        this.checkValid();
    }

    onPhoneActive(active: boolean) {
        if (active) {
            this.tip.next(WellxPayInputTip.PHONE);
        } else {
            this.tip.next(WellxPayInputTip.NONE);
        }
    }

    checkValid() {
        if (this.paymentMethod) {
            const isAmount = this.amount.isValid();
            const isPhone = this.phone.isValid();
            this.valid.next(isAmount && isPhone);
        }
    }

    send() {
        const valid = this.valid.getValue();

        if (valid && this.paymentMethod) {
            const amount = parseInt(this.amount.getValue());
            const phone = formatBangladeshPhoneForBack(this.phone.getValue()).replaceAll(' ', '');
            const request: WellxpayWithdrawRequest = {
                currencyId: this.paymentMethod.currency.ISO,
                channelType: this.paymentMethod.id,
                accountNumber: phone,
                amount,
            }

            PaymentService.wellxpay.withdraw(request);
            WellxPayWithdrawService.state.next(WellxPayWithdrawState.AWAIT);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const status = WellxPayWithdrawService.state.getValue();
        const fee = WithdrawService.fee.getValue();
        const isBonuses = BonusService.list.getValue().length;
        console.log('fee', fee);

        if (isBonuses) {
            WellxPayWithdrawService.state.next(WellxPayWithdrawState.BONUS_REJECT);
            StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
        } else {
            if (!!fee) {
                if (status === WellxPayWithdrawState.HAS_FEE) {
                    this.send();
                }
            } else {
                this.send();
            }
        }
    }

    reset() {
        this.amount.value.next('');
        this.phone.value.next('');

        this.amount.error.next(null);
        this.phone.error.next(null);

        this.amount.active.next(false);
        this.phone.active.next(false);

        this.amount.autoCompleted.next(false);
        this.phone.autoCompleted.next(false);
        this.amountLimit.next(AmountError.NONE);
        this.tip.next(WellxPayInputTip.NONE);
    }
}

const WellxPayFormService = new WellxPayForm();

export default WellxPayFormService;
