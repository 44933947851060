import { translate as t } from 'service/Language';

import Form from '../../Form/Form';
import LoginButton from '../../Form/LoginButton/LoginButton';
import WheelRegistrationFormService from '../../Form/service/registration';
import SignUpButton from '../../Form/SignUpButton/SignUpButton';
import Plane from './images/Plane.png';

import './WheelRegistrationAviator.scss';

function WheelRegistrationAviator() {
    return (
        <div className="aviator-registration">
            <div className="aviator-registration__top">
                <div className="aviator-registration__header">{t('wheel.aviator.reg.header')}</div>
                <div className="aviator-registration__description">{t('wheel.aviator.reg.reward')}</div>
                <div className="aviator-registration__title">{t('wheel.aviator.125.250')}</div>
                <div className="aviator-registration__reg">{t('wheel.aviator.reg.title')}</div>
                <div className="aviator-registration__subreg">{t('wheel.aviator.reg.description')}</div>
            </div>
            <form
                className="aviator-registration__form"
                onSubmit={(event) => WheelRegistrationFormService.submit(event)}
            >
                <Form type="aviator" />
                <SignUpButton type="aviator" title="wheel.aviator.reg.button" />
                <LoginButton type="aviator" />
            </form>
            <img className="aviator-registration__plane" src={Plane} alt="plane" />
        </div>
    );
}

export default WheelRegistrationAviator;
