import { FocusEvent, FocusEventHandler, useState, VFC } from 'react';
import GamesService from 'service/Games';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';

import AuthService from '../../service/Auth';
import GoogleService from '../../service/Google';
import Close from './image/close.svg';
import SearchIcon from './image/search.svg';
import SearchActiveIcon from './image/search-active.svg';

import './Search.scss';

interface SearchProps {
    onFocus?: FocusEventHandler;
    onBlur?: FocusEventHandler;
}

const Search: VFC<SearchProps> = ({ onFocus, onBlur }) => {
    const [active, setActive] = useState(false);
    const searchText = useObservable(GamesService.searchText, GamesService.searchText.getValue());
    const userId = useObservable(AuthService.auth, AuthService.auth.getValue())?.userId.toString();
    const _onBlur = (event: FocusEvent) => {
        GoogleService.search(GamesService.searchText.getValue(), userId ? userId : '');
        if (onBlur) {
            onBlur(event);
            setActive(false);
        }
    };

    const _onFocus = (event: FocusEvent) => {
        if (onFocus) {
            setActive(true);
            onFocus(event);
        }
    };

    const reset = () => {
        GoogleService.search(GamesService.searchText.getValue(), userId ? userId : '');
        GamesService.setSearchText('');
    };

    return (
        <div className="search">
            <input
                className="search__input"
                placeholder={t('placeholder.search')}
                onFocus={_onFocus}
                onBlur={_onBlur}
                value={searchText}
                onChange={(e) => {
                    GamesService.setSearchText(e.target.value);
                }}
            />
            <span className="search__icon">
                <img src={active ? SearchActiveIcon : SearchIcon} alt="search" />
            </span>
            {searchText && (
                <button className="search__clear" onClick={() => reset()}>
                    <img className="search__img" src={Close} alt="close" />
                    <span className="search__cancel">{t('search.button.cancel')}</span>
                </button>
            )}
        </div>
    );
};

export default Search;
