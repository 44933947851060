import { formatCurrencySign } from 'utils/format';
import Input from '../../../../../../../component/Input/Input';
import Back from '../../../Instapay/Form/Amount/image/back.png';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { translate as t } from '../../../../../../../service/Language';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import AmountFormService, {AmountError, MpayErrorTip, MpayInputTip} from '../../service/AmountForm';
import Submit from '../Button/Submit';
import SumSelector from '../SummSelector/SumSelector';
import { createRef } from 'react';
import AttentionIcon from '../../../../../../../assets/svg/attention.svg'
import Attention from '../../../Apay/Attention/Attention';

import './Amount.scss';


type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {};

class Amount extends RxComponent<AmountProps, AmountState> {
    state = {
        tip: MpayInputTip.NONE,
        amountLimit: AmountError.NONE,
        errorAmount: null,
        errorLetters: MpayErrorTip.NONE,
    };

    handler = {
        onTip: this.onTip.bind(this),
        onErrorAmount: this.onErrorAmount.bind(this),
        onAmountLimit: this.onAmountLimit.bind(this),
        onErrorLetters: this.errorLetters.bind(this),

    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    onTip(tip: MpayInputTip) {
        this.setState({ tip });
    }

    onAmountLimit(amountLimit: AmountError) {
        this.setState({amountLimit});
    }

    onErrorAmount(errorAmount: string | null) {
        this.setState({errorAmount});
    }

    errorLetters(errorLetters: MpayErrorTip) {
        this.setState({errorLetters});
    }

    componentDidMount() {
        const {paymentMethod} = this.props;

        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setValue(paymentMethod.defaultValue);
        this.subscribe(AmountFormService.amount.error, {next: this.handler.onErrorAmount});
        this.subscribe(AmountFormService.amountLimit, {next: this.handler.onAmountLimit});
        this.subscribe(AmountFormService.tip, {next: this.handler.onTip});
        this.subscribe(AmountFormService.errorLetters, {next: this.handler.onErrorLetters});
    }

    render() {
        const { paymentMethod, lock } = this.props;
        const { errorAmount, amountLimit, tip, errorLetters } = this.state;

        const min =  formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency);
        const max =  formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency);

        const hint = `${t('popup.payment.amount.min')} ${min} — ${t('popup.payment.amount.max')} ${max}`;

        return (
            <div className="deposit-amount-mpay">
                <form className="deposit-amount-mpay-form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                     <div className="deposit-amount-mpay-form-container">
                        <MaskedInput
                            className="deposit-amount-mpay-form-container__input"
                            inputService={AmountFormService.amount}
                            name="amount"
                            mask="00000000000"
                            enabled={!lock}
                        />
                        <span
                            className="deposit-amount-mpay-form-container__currency">{paymentMethod.currency.sign}</span>
                         {!!errorAmount && (
                             <div className="deposit-amount-mpay-form-container__error">
                                 <img src={AttentionIcon} alt="attention"/>
                                 <span className="deposit-amount-mpay-form-container__error_text">
                        {amountLimit === AmountError.AMOUNT_MIN && t('popup.payment.amount.min') + ` ${min}`}
                                     {amountLimit === AmountError.AMOUNT_MAX && t('popup.payment.amount.max') + ` ${max}` }
                                    </span>
                             </div>
                         )}
                         {tip === MpayInputTip.AMOUNT && !errorAmount ? (
                             <span className="deposit-amount-mpay-form-container__tip">
                                {hint}
                            </span>
                         ) : null}
                    </div>
                    <div className="deposit-amount-mpay__sum">
                        <SumSelector paymentMethod={paymentMethod} disabled={lock}/>
                    </div>
                    <div className="deposit-amount-mpay-form-container">
                        <Input
                            className="deposit-amount-mpay-form-container__input"
                            inputService={AmountFormService.name}
                            name="name"
                            enabled={!lock}
                        />
                        {tip === MpayInputTip.NAME && (
                            <span className="deposit-amount-mpay-form-container__tip">
                                {t('popup.payment.name.hint')}
                            </span>
                        )}
                        {errorLetters === MpayErrorTip.NAME ?
                            <div className="deposit-amount-mpay-form-container-letters" ref={this.ref.self}>
                                <img className="deposit-amount-mpay-form-container-letters__img" src={Back} alt="back"/>
                                <span
                                    className="deposit-amount-mpay-form-container-letters__text">{t('popup.payment.turkey.error.letters')}</span>
                            </div> : null}
                    </div>
                    <div className="deposit-amount-mpay-form-container">
                        <Input
                            className="deposit-amount-mpay-form-container__input"
                            inputService={AmountFormService.surname}
                            name="surname"
                            enabled={!lock}
                        />
                        {tip === MpayInputTip.SURNAME && (
                            <span className="deposit-amount-mpay-form-container__tip">
                                {t('popup.payment.name.hint')}
                            </span>
                        )}
                        {errorLetters === MpayErrorTip.SURNAME ?
                            <div className="deposit-amount-mpay-form-container-letters" ref={this.ref.self}>
                                <img className="deposit-amount-mpay-form-container-letters__img" src={Back} alt="back"/>
                                <span
                                    className="deposit-amount-mpay-form-container-letters__text">{t('popup.payment.turkey.error.letters')}</span>
                            </div> : null}
                    </div>

                <div className="deposit-amount-mpay-check">
                    <Attention title="popup.payment.attention.name" type="info" />
                </div>
                <div className="deposit-amount-mpay-check">
                    <Attention title="popup.payment.attention" type="attention" />
                </div>
                <div className="deposit-amount-mpay__submit">
                    <Submit/>
                </div>
                </form>
            </div>
        );
    }
}

export default Amount;
