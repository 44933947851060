class Country {
    /** Id код страны */
    id: number;

    /** Телефонный код страны */
    phone: number;

    /** Название страны */
    name: string;

    constructor(id = 0, phone = 0, name = '') {
        this.id = id;
        this.phone = phone;
        this.name = name;
    }

    isValid() {
        return this.id !== 0 && this.id !== -1000;
    }
}

const country = {
    Russia: new Country(643, 7, 'country.name.russia'),
    Ukraine: new Country(804, 380, 'country.name.ukraine'),
    Belarus: new Country(112, 375, 'country.name.belarus'),
    Kazakhstan: new Country(398, 77, 'country.name.kazakhstan'),
    Azerbaijan: new Country(31, 994, 'country.name.azerbaijan'),
    Armenia: new Country(51, 374, 'country.name.armenia'),
    Georgia: new Country(268, 995, 'country.name.georgia'),
    Kyrgyzstan: new Country(417, 996, 'country.name.kyrgyzstan'),
    Latvia: new Country(428, 371, 'country.name.latvia'),
    Lithuania: new Country(440, 370, 'country.name.lithuania'),
    Moldova: new Country(498, 373, 'country.name.moldova'),
    Tajikistan: new Country(762, 992, 'country.name.tajikistan'),
    Turkmenistan: new Country(795, 993, 'country.name.turkmenistan'),
    Uzbekistan: new Country(860, 998, 'country.name.uzbekistan'),
    Estonia: new Country(233, 372, 'country.name.estonia'),
    Abkhazia: new Country(895, 7, 'country.name.abkhazia'),
    Australia: new Country(36, 61, 'country.name.australia'),
    Austria: new Country(40, 43, 'country.name.austria'),
    Albania: new Country(8, 355, 'country.name.albania'),
    Algeria: new Country(12, 213, 'country.name.algeria'),
    Angola: new Country(24, 244, 'country.name.angola'),
    Anguilla: new Country(660, 264, 'country.name.anguilla'),
    Andorra: new Country(20, 376, 'country.name.andorra'),
    AntiguaBarbuda: new Country(28, 1268, 'country.name.antigua-barbuda'),
    Antilles: new Country(530, 599, 'country.name.antilles'),
    Argentina: new Country(32, 54, 'country.name.argentina'),
    Arulco: new Country(533, 297, 'country.name.arulco'),
    Afghanistan: new Country(4, 93, 'country.name.afghanistan'),
    Bahamas: new Country(44, 1242, 'country.name.bahamas'),
    Bangladesh: new Country(50, 880, 'country.name.bangladesh'),
    Barbados: new Country(52, 1246, 'country.name.barbados'),
    Bahrain: new Country(48, 973, 'country.name.bahrain'),
    Belize: new Country(84, 501, 'country.name.belize'),
    Belgium: new Country(56, 32, 'country.name.belgium'),
    Benin: new Country(204, 229, 'country.name.benin'),
    Bermuda: new Country(60, 1441, 'country.name.bermuda'),
    Bulgaria: new Country(100, 359, 'country.name.bulgaria'),
    Bolivia: new Country(68, 591, 'country.name.bolivia'),
    BosniaHerzegovina: new Country(70, 387, 'country.name.bosnia-herzegovina'),
    Botswana: new Country(72, 267, 'country.name.botswana'),
    Brazil: new Country(76, 55, 'country.name.brazil'),
    BritishVirginIslands: new Country(92, 1284, 'country.name.british-virgin-islands'),
    Brunei: new Country(96, 673, 'country.name.brunei'),
    BurkinaFaso: new Country(854, 226, 'country.name.burkina-faso'),
    Burundi: new Country(108, 257, 'country.name.burundi'),
    Butane: new Country(64, 975, 'country.name.butane'),
    WallisFutunaIslands: new Country(876, 681, 'country.name.wallis-futuna-islands'),
    Vanuatu: new Country(548, 678, 'country.name.vanuatu'),
    GreatBritain: new Country(826, 44, 'country.name.great-britain'),
    Hungary: new Country(348, 36, 'country.name.hungary'),
    Venezuela: new Country(862, 58, 'country.name.venezuela'),
    EastTimor: new Country(626, 670, 'country.name.east-timor'),
    Vietnam: new Country(704, 84, 'country.name.vietnam'),
    Gabon: new Country(266, 241, 'country.name.gabon'),
    Haiti: new Country(332, 509, 'country.name.haiti'),
    Guyana: new Country(328, 592, 'country.name.guyana'),
    Gambia: new Country(270, 220, 'country.name.gambia'),
    Ghana: new Country(288, 233, 'country.name.ghana'),
    Guadeloupe: new Country(312, 590, 'country.name.guadeloupe'),
    Guatemala: new Country(320, 502, 'country.name.guatemala'),
    Guinea: new Country(324, 224, 'country.name.guinea'),
    GuineaBissau: new Country(624, 245, 'country.name.guinea-bissau'),
    Germany: new Country(276, 49, 'country.name.germany'),
    Guernsey: new Country(831, 44, 'country.name.guernsey'),
    Gibraltar: new Country(292, 350, 'country.name.gibraltar'),
    HongKong: new Country(344, 852, 'country.name.hong-kong'),
    Honduras: new Country(340, 504, 'country.name.honduras'),
    Grenada: new Country(308, 1473, 'country.name.grenada'),
    Greenland: new Country(304, 299, 'country.name.greenland'),
    Greece: new Country(300, 30, 'country.name.greece'),
    Denmark: new Country(208, 45, 'country.name.denmark'),
    JerseyIslands: new Country(832, 44, 'country.name.jersey-islands'),
    Djibouti: new Country(262, 253, 'country.name.djibouti'),
    DominicanRepublic: new Country(214, 1809, 'country.name.dominican-republic'),
    Egypt: new Country(818, 20, 'country.name.egypt'),
    Zambia: new Country(894, 260, 'country.name.zambia'),
    WestSahara: new Country(732, 212, 'country.name.west-sahara'),
    Zimbabwe: new Country(716, 263, 'country.name.zimbabwe'),
    Israel: new Country(376, 972, 'country.name.israel'),
    India: new Country(356, 91, 'country.name.india'),
    Indonesia: new Country(360, 62, 'country.name.indonesia'),
    Jordan: new Country(400, 962, 'country.name.jordan'),
    Iraq: new Country(368, 964, 'country.name.iraq'),
    Iran: new Country(364, 98, 'country.name.iran'),
    Ireland: new Country(372, 353, 'country.name.ireland'),
    Iceland: new Country(352, 354, 'country.name.iceland'),
    Spain: new Country(724, 34, 'country.name.spain'),
    Italy: new Country(380, 39, 'country.name.italy'),
    Yemen: new Country(887, 967, 'country.name.yemen'),
    CapeVerde: new Country(132, 238, 'country.name.cape-verde'),
    Cambodia: new Country(116, 855, 'country.name.cambodia'),
    Cameroon: new Country(120, 237, 'country.name.cameroon'),
    Canada: new Country(124, 1, 'country.name.canada'),
    Qatar: new Country(634, 974, 'country.name.qatar'),
    Kenya: new Country(404, 254, 'country.name.kenya'),
    Cyprus: new Country(196, 357, 'country.name.cyprus'),
    Kiribati: new Country(296, 686, 'country.name.kiribati'),
    China: new Country(156, 86, 'country.name.china'),
    Colombia: new Country(170, 57, 'country.name.colombia'),
    Comoros: new Country(174, 269, 'country.name.comoros'),
    CongoDemocraticRepublic: new Country(180, 243, 'country.name.congo-democratic-republic'),
    RepublicCongo: new Country(178, 242, 'country.name.republic-congo'),
    CostaRica: new Country(188, 506, 'country.name.costa-rica'),
    IvoryCoast: new Country(384, 225, 'country.name.ivory-coast'),
    Cuba: new Country(192, 53, 'country.name.cuba'),
    Kuwait: new Country(414, 965, 'country.name.kuwait'),
    CookIslands: new Country(184, 682, 'country.name.cook-islands'),
    Laos: new Country(418, 856, 'country.name.laos'),
    Lesotho: new Country(426, 266, 'country.name.lesotho'),
    Liberia: new Country(430, 231, 'country.name.liberia'),
    Lebanon: new Country(422, 961, 'country.name.lebanon'),
    Libya: new Country(434, 218, 'country.name.libya'),
    Liechtenstein: new Country(438, 423, 'country.name.liechtenstein'),
    Luxembourg: new Country(442, 352, 'country.name.luxembourg'),
    Mauritius: new Country(480, 230, 'country.name.mauritius'),
    Mauritania: new Country(478, 222, 'country.name.mauritania'),
    Madagascar: new Country(450, 261, 'country.name.madagascar'),
    Macedonia: new Country(807, 389, 'country.name.macedonia'),
    Malawi: new Country(454, 265, 'country.name.malawi'),
    Malaysia: new Country(458, 60, 'country.name.malaysia'),
    Mali: new Country(466, 223, 'country.name.mali'),
    Maldives: new Country(462, 960, 'country.name.maldives'),
    Malta: new Country(470, 356, 'country.name.malta'),
    Martinique: new Country(474, 596, 'country.name.martinique'),
    Mexico: new Country(484, 52, 'country.name.mexico'),
    Mozambique: new Country(508, 259, 'country.name.mozambique'),
    Monaco: new Country(492, 377, 'country.name.monaco'),
    Mongolia: new Country(496, 976, 'country.name.mongolia'),
    Morocco: new Country(504, 212, 'country.name.morocco'),
    MyanmarBurma: new Country(104, 95, 'country.name.myanmar-burma'),
    IsleMan: new Country(833, 44, 'country.name.isle-man'),
    Namibia: new Country(516, 264, 'country.name.namibia'),
    Nauru: new Country(520, 674, 'country.name.nauru'),
    Nepal: new Country(524, 977, 'country.name.nepal'),
    Niger: new Country(562, 227, 'country.name.niger'),
    Nigeria: new Country(566, 234, 'country.name.nigeria'),
    Netherlands: new Country(528, 31, 'country.name.netherlands'),
    Nicaragua: new Country(566, 505, 'country.name.nicaragua'),
    NewZealand: new Country(554, 64, 'country.name.new-zealand'),
    NewCaledonia: new Country(540, 687, 'country.name.new-caledonia'),
    Norway: new Country(578, 47, 'country.name.norway'),
    NorfolkIsland: new Country(574, 672, 'country.name.norfolk-island'),
    UnitedArabEmirates: new Country(784, 971, 'country.name.united-arab-emirates'),
    Oman: new Country(512, 968, 'country.name.oman'),
    Pakistan: new Country(586, 92, 'country.name.pakistan'),
    Panama: new Country(591, 507, 'country.name.panama'),
    PapuaNewGuinea: new Country(598, 675, 'country.name.papua-new-guinea'),
    Paraguay: new Country(600, 595, 'country.name.paraguay'),
    Peru: new Country(604, 51, 'country.name.peru'),
    PitcairnIsland: new Country(612, 64, 'country.name.pitcairn-island'),
    Poland: new Country(616, 48, 'country.name.poland'),
    Portugal: new Country(620, 351, 'country.name.portugal'),
    PuertoRico: new Country(630, 1787, 'country.name.puerto-rico'),
    Reunion: new Country(638, 262, 'country.name.reunion'),
    Rwanda: new Country(646, 250, 'country.name.rwanda'),
    Romania: new Country(642, 40, 'country.name.romania'),
    UnitedStatesAmerica: new Country(840, 1, 'country.name.usa'),
    Salvador: new Country(222, 503, 'country.name.salvador'),
    Samoa: new Country(882, 685, 'country.name.samoa'),
    SanMarino: new Country(674, 378, 'country.name.san-marino'),
    SanTomePrincipe: new Country(678, 239, 'country.name.san-tome-principe'),
    SaudiArabia: new Country(682, 966, 'country.name.saudi-arabia'),
    Swaziland: new Country(748, 268, 'country.name.swaziland'),
    SaintLucia: new Country(662, 1758, 'country.name.saint-lucia'),
    SaintHelena: new Country(654, 290, 'country.name.saint-helena'),
    NorthKorea: new Country(410, 850, 'country.name.north-korea'),
    Seychelles: new Country(690, 248, 'country.name.seychelles'),
    SaintPierreMiquelon: new Country(666, 508, 'country.name.saint-pierre-miquelon'),
    Senegal: new Country(686, 221, 'country.name.senegal'),
    SaintKittsNevis: new Country(659, 1869, 'country.name.saint-kitts-nevis'),
    SaintVincentGrenadines: new Country(670, 1784, 'country.name.saint-vincent-grenadines'),
    Serbia: new Country(688, 381, 'country.name.serbia'),
    Singapore: new Country(702, 65, 'country.name.singapore'),
    Syria: new Country(760, 963, 'country.name.syria'),
    Slovakia: new Country(703, 421, 'country.name.slovakia'),
    Slovenia: new Country(705, 986, 'country.name.slovenia'),
    SolomonIslands: new Country(90, 677, 'country.name.solomon-islands'),
    Somalia: new Country(706, 252, 'country.name.somalia'),
    Sudan: new Country(729, 249, 'country.name.sudan'),
    Suriname: new Country(740, 597, 'country.name.suriname'),
    SierraLeone: new Country(694, 232, 'country.name.sierra-leone'),
    Taiwan: new Country(158, 886, 'country.name.taiwan'),
    Thailand: new Country(764, 66, 'country.name.thailand'),
    Tanzania: new Country(834, 255, 'country.name.tanzania'),
    Togo: new Country(768, 228, 'country.name.togo'),
    TokelauIslands: new Country(772, 690, 'country.name.tokelau-islands'),
    Tonga: new Country(776, 676, 'country.name.tonga'),
    TrinidadTobago: new Country(780, 1868, 'country.name.trinidad-tobago'),
    Tuvalu: new Country(798, 688, 'country.name.tuvalu'),
    Tunisia: new Country(788, 216, 'country.name.tunisia'),
    TurksCaicos: new Country(796, 649, 'country.name.turks-caicos'),
    Turkey: new Country(792, 90, 'country.name.turkey'),
    Uganda: new Country(800, 256, 'country.name.uganda'),
    Uruguay: new Country(858, 598, 'country.name.uruguay'),
    FaroeIslands: new Country(858, 298, 'country.name.faroe-islands'),
    Fiji: new Country(242, 679, 'country.name.fiji'),
    Philippines: new Country(608, 63, 'country.name.philippines'),
    Finland: new Country(246, 358, 'country.name.finland'),
    France: new Country(250, 33, 'country.name.france'),
    FrenchGuinea: new Country(254, 594, 'country.name.french-guinea'),
    FrenchPolynesia: new Country(258, 689, 'country.name.french-polynesia'),
    Croatia: new Country(191, 385, 'country.name.croatia'),
    CentralAfricanRepublic: new Country(140, 236, 'country.name.central-african-republic'),
    Chad: new Country(148, 235, 'country.name.chad'),
    Montenegro: new Country(499, 381, 'country.name.montenegro'),
    Czech: new Country(203, 420, 'country.name.czech'),
    Chile: new Country(152, 56, 'country.name.chile'),
    Switzerland: new Country(756, 41, 'country.name.switzerland'),
    Sweden: new Country(752, 46, 'country.name.sweden'),
    SriLanka: new Country(144, 94, 'country.name.sri-lanka'),
    Ecuador: new Country(218, 593, 'country.name.ecuador'),
    EquatorialGuinea: new Country(226, 240, 'country.name.equatorial-guinea'),
    Eritrea: new Country(232, 291, 'country.name.eritrea'),
    Ethiopia: new Country(231, 251, 'country.name.ethiopia'),
    RepublicSouthAfrica: new Country(710, 27, 'country.name.republic-south-africa'),
    SouthKorea: new Country(410, 82, 'country.name.south-korea'),
    SouthOssetia: new Country(896, 7, 'country.name.south-ossetia'),
    Jamaica: new Country(388, 1876, 'country.name.jamaica'),
    Japan: new Country(392, 81, 'country.name.japan'),
};

const unknownCountry = new Country(-1000, -1000, 'unknown');

const countryList: Country[] = Object.values(country).sort((a, b) => (a.name > b.name ? 1 : -1));

const CountryMap = new Map<number, Country>(countryList.map(country => [country.id, country]));

function getCountry(id: number): Country {
    return CountryMap.get(id) || unknownCountry;
}

export { country, countryList, CountryMap, getCountry, unknownCountry };
export default Country;
