import { useEffect } from 'react';
import GoogleService from 'service/Google';
import { translate as t } from 'service/Language';
import OperationsService from 'service/Operations';
import useObservable from 'utils/useObservable';

import { PaymentMethod } from '../../../PaymentMethod/types';
import FailedPage from '../../ResultPage/FailedPage/FailedPage';
import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import AzarpayDepositService from '../service/AzarpayDeposit';
import { DepositState } from '../service/AzarpayDeposit';
import Amount from './Amount/Amount';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import BonusFailedPage from '../../../../../../component/BonusFailedPage/BonusFailedPage';
import AmountWithdrawal from './AmountWithdrawal/Amount';

import './Form.scss';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(AzarpayDepositService.state, AzarpayDepositService.state.getValue());

    useEffect(() => {
        const gaSub = OperationsService.invoice.subscribe({
            next: (data) => {
                const clientId = GoogleService.getClientId();
                const invoiceId = data.id.toString();
                OperationsService.sendGAClientRelation(clientId, invoiceId);
            },
        });
        return () => {
            gaSub.unsubscribe();
            AzarpayDepositService.reset();
        };
    }, []);

    return (
        <div className="deposit-form">
            <div className="deposit-form__header">
                <img className="deposit-form__img" src={paymentMethod.icon} alt={paymentMethod.title} />
                <h2 className="deposit-form__title">{t(paymentMethod.title)}</h2>
            </div>
            {(state === DepositState.AMOUNT || state === DepositState.WAIT || state === DepositState.HAS_FEE) ? (
                paymentMethod.id === ChannelType.VALERPAY_DEPOSIT ?
                    <Amount paymentMethod={paymentMethod} lock={state !== DepositState.AMOUNT} />
                    : <AmountWithdrawal lock={state !== DepositState.AMOUNT} paymentMethod={paymentMethod} />
            ): null}

            {state === DepositState.SUCCESS ? <SuccessPage /> : null}
            {state === DepositState.FAILED ? <FailedPage /> : null}
            {state === DepositState.BONUS_REJECT ? <BonusFailedPage /> : null}
        </div>
    );
}

export default Form;
