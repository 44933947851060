import { READY_STATES } from 'enums/ws';
import { BehaviorSubject, Subscription } from 'rxjs';

import AdsService from './AdsService';
import AuthService from './Auth';
import CountryPermissionService from './CountryPermission';
import ActivityDetectionService from './FirstActivityDetect';
import GamesService from './Games';
import GoogleService from './Google';
import { getCurrency } from './model/Currency';
import UserTrackService from './UserTrack';
import WSBet from './WSBet';
import WSConnectionService from './WSConnection';

// import ChatwootService from './Chatwoot';

class App {
    scrolled = new BehaviorSubject<boolean>(false);

    subscription: Subscription[] = [];

    lastDepositCount = -1; // Initialize with a value that won't match any real count

    deposit = new BehaviorSubject<number>(0);

    handler = {
        checkScroll: this.checkScroll.bind(this),
    };

    checkScroll() {
        const scrolled = window.screen.height / 2 <= window.scrollY;
        if (this.scrolled.value !== scrolled) {
            this.scrolled.next(scrolled);
        }
    }

    bind() {
        console.log('App.bind');

        ActivityDetectionService.watch();

        WSConnectionService.addEventListener();
        WSConnectionService.checkNetwork();

        CountryPermissionService.check();
        WSBet.state.subscribe(state => {
            if (state === READY_STATES.OPEN) {
                AuthService.autoLogin();
            }
        });

        UserTrackService.renew();
        GoogleService.initTagManager();

        AdsService.init();
        // ChatwootService.init();

        const gameGeoSub = GamesService.games.subscribe(gameList => {
            if (gameList.length) {
                GamesService.requestGeoProvider();
            }
        });
        this.subscription.push(gameGeoSub);

        const gameCurrencySub = AuthService.auth.subscribe(clientInfo => {
            if (clientInfo) {
                const currency = getCurrency(clientInfo.currencyCode);
                GamesService.setCurrency(currency);
            }
        });
        this.subscription.push(gameCurrencySub);

        document.addEventListener('scroll', this.handler.checkScroll);
    }

    unbind() {
        this.subscription.forEach(sub => sub.unsubscribe());
        this.subscription = [];

        document.removeEventListener('scroll', this.handler.checkScroll);

        ActivityDetectionService.unwatch();

        WSConnectionService.removeEventListener();
    }
}

const AppService = new App();

export default AppService;
