/* eslint-disable max-len */

import { Icon } from 'types';

function RightFlatArrow({ color = '#3F4250', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289L19.2071 13.7929C19.5976 14.1834 19.5976 14.8166 19.2071 15.2071L10.7071 23.7071C10.3166 24.0976 9.68342 24.0976 9.29289 23.7071C8.90237 23.3166 8.90237 22.6834 9.29289 22.2929L17.0858 14.5L9.29289 6.70711C8.90237 6.31658 8.90237 5.68342 9.29289 5.29289Z"
                fill={color}
            />
        </svg>
    );
}

export default RightFlatArrow;
