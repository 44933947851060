import { translate as t } from 'service/Language';

import Form from '../../Form/Form';
import LoginButton from '../../Form/LoginButton/LoginButton';
import WheelRegistrationFormService from '../../Form/service/registration';
import SignUpButton from '../../Form/SignUpButton/SignUpButton';

import './WheelRegistrationBonanza.scss';

function WheelRegistrationBonanza() {
    return (
        <div className='bonanza-registration'>
            <div className='bonanza-registration__header'>{t('wheel.popup.again.header')}</div>
            <div className='bonanza-registration__subheader'>{t('wheel.win.125.fs')}</div>
            <div className='bonanza-registration__content'>
                <div className='bonanza-registration__block'>
                    <div className='bonanza-registration__bonus'>
                        {t('wheel.popup.registration.bonus')}
                    </div>
                    <div className='bonanza-registration__freespins'>
                        {t('wheel.popup.again.freespins.win')}
                    </div>
                </div>
                <div className='bonanza-registration__element' />
            </div>
            <div className='bonanza-registration__registration'>
                {t('wheel.popup.registration.now')}
            </div>
            <form
                className='bonanza-registration__form'
                onSubmit={event => WheelRegistrationFormService.submit(event)}
            >
                <Form type='bonanza' />
                <SignUpButton type='bonanza' />
                <LoginButton type='bonanza' />
            </form>
        </div>
    );
}

export default WheelRegistrationBonanza;
