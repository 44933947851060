import { translate as t } from 'service/Language';

import betradar from './betradar.png';
import gambling from './gambling.png';

import './Partners.scss';

function Partners() {
    return (
        <div className="partners">
            <span className="partners__title">{t('info.about.partners.header')}</span>
            <div className="partners__card">
                <div className="partner-card">
                    <div className="partner-card__img">
                        <img alt="game" src={betradar} />
                    </div>
                    <span className="partner-card__description">
                        <span className="partner-card__title"> {t('info.about.partners.betradar')}</span>
                        {t('info.about.partners.betradar.description')}
                    </span>
                    <div className="partner-card__divider" />
                </div>
                <div className="partner-card">
                    <div className="partner-card__img">
                        <img alt="game" src={gambling} />
                    </div>
                    <span className="partner-card__description">
                        <span className="partner-card__title">{t('info.about.partners.gambling')}</span>
                        {t('info.about.partners.gambling.description')}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Partners;
