import { useEffect } from 'react';
import useObservable from 'utils/useObservable';
import { PaymentMethod } from '../../../PaymentMethod/types';
import Balance from '../../Balance/Balance';
import ResultForm from '../ResultForm/ResultForm';
import Phone from './Phone/Phone';
import CashixWithdrawService, {CashixWithdrawState} from '../service/CashixWithdraw';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const {paymentMethod} = props;

    const state = useObservable(CashixWithdrawService.state, CashixWithdrawService.state.getValue());

    useEffect(() => {
        CashixWithdrawService.reset();
    }, []);

    if (
        state === CashixWithdrawState.INIT ||
        state === CashixWithdrawState.AWAIT ||
        state === CashixWithdrawState.HAS_FEE
    ) {

            return (
                <>
                    <Balance/>
                    <Phone paymentMethod={paymentMethod}/>
                </>
            );
    } else {
        return <ResultForm/>;
    }
}

export default Form;
