import './PinAnimated.scss';

function PinAnimated() {
    return (
        <div className="PinAnimated">
            <div className="PinAnimated__img"/>
            <div className="circle"/>
        </div>
    )
}

export default PinAnimated;
