import {useEffect, useState} from 'react';
import { translate as t } from 'service/Language';

import GoogleService from '../../../../../service/Google';
import OperationsService from '../../../../../service/Operations';
import useObservable from '../../../../../utils/useObservable';
import { PaymentMethod } from '../../PaymentMethod/types';
import PaymentHeader from './PaymentHeader/PaymentHeader';
import FailedTransactionPage from '../Paykassma/ResultPage/FailedPage/FailedPage';
import CoinspaidDepositService, { DepositState } from './service/CoinspaidDeposit';
import Spinner from 'svg/spinnerBlue.svg';
import Amount from './Amount/Amount';

import './Form.scss';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(CoinspaidDepositService.state, CoinspaidDepositService.state.getValue());
    const data = useObservable(CoinspaidDepositService.rate, CoinspaidDepositService.rate.getValue());
    let wallet = useObservable(CoinspaidDepositService.wallet, CoinspaidDepositService.wallet.getValue());
    const tag = useObservable(CoinspaidDepositService.tag, CoinspaidDepositService.tag.getValue());

    useEffect(() => {
        const gaSub = OperationsService.invoice.subscribe({
            next: (data) => {
                const clientId = GoogleService.getClientId();
                const invoiceId = data.id.toString();
                OperationsService.sendGAClientRelation(clientId, invoiceId);
            },
        });
        return () => {
            gaSub.unsubscribe();
            CoinspaidDepositService.reset();
        };
    }, []);

    return (
        <div className="deposit-form">
                <PaymentHeader />
                <div className="deposit-form__header">
                    <img className="deposit-form__img" src={paymentMethod.icon} alt={paymentMethod.title}/>
                    <h2 className="deposit-form__title">{t(paymentMethod.title)}</h2>
                </div>
            {(state === DepositState.DATA && !!data && !!wallet)
                ? (
                <Amount paymentMethod={paymentMethod} lock={state !== DepositState.DATA} data={data} wallet={wallet} tag={tag} />
            )
                : <div className="deposit-form-block"><img className="deposit-form-block__spinner" src={Spinner} alt=""/></div>}
            {state === DepositState.FAILED ? <FailedTransactionPage /> : null}
        </div>
    );
}

export default Form;
