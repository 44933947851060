import { PaymentMethod } from '../../../PaymentMethod/types';
import Bankalfallah from '../../Apay/Bankalfallah/Form';
import Easypaisa from '../../Apay/Easypaisa/Form';
import UpiFast from '../../Apay/UpiFast/Form';
import UpiArs from '../../Apay/UpiArs/Form';
import UpiArf from '../../Apay/UpiArf/Form';
import ApayUpay from '../../Apay/ApayUpay/Form';
import ApayBDT from '../../Apay/ApayBDT/Form';
import UpiFastV from '../../Apay/UpiFastV/Form';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';

import './Form.scss';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;

    if (paymentMethod.id === ChannelType.Easypaisa) {
        return <Easypaisa paymentMethod={paymentMethod} />;
    }
    if (paymentMethod.id === ChannelType.Bankalfalah) {
        return <Bankalfallah paymentMethod={paymentMethod} />;
    }
    if (paymentMethod.id === ChannelType.UPI_FAST) {
        return <UpiFast paymentMethod={paymentMethod} />;
    }
    if (paymentMethod.id === ChannelType.UPI_FAST_APAY) {
        return <UpiFastV paymentMethod={paymentMethod} />;
    }
    if (paymentMethod.id === ChannelType.UPI_ARS) {
        return <UpiArs paymentMethod={paymentMethod} />;
    }
    if (paymentMethod.id === ChannelType.UPI_ARF) {
        return <UpiArf paymentMethod={paymentMethod} />;
    }
    if (paymentMethod.id === ChannelType.UPAY_APAY) {
        return <ApayUpay paymentMethod={paymentMethod}/>;
    }
    if (paymentMethod.id === ChannelType.APAY_BDT || paymentMethod.id === ChannelType.BKASH_APAY || paymentMethod.id === ChannelType.NAGAD_APAY) {
        return <ApayBDT paymentMethod={paymentMethod}/>;
    } else return null;
}

export default Form;
