import classNames from 'classnames';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';

import Spinner from '../../../Deposit/Paykassma/Other/Button/image/Spinner.svg';
import { PaymentMethod } from '../../../PaymentMethod/types';
import PixFormService from '../service/PixForm';
import Directa24WithdrawService, { Directa24WithdrawState } from '../service/Directa24Withdraw';

import './WithdrawalButton.scss';

type WithdrawalButtonType = {
    buttonTitle: string;
    variation?: boolean;
    paymentMethod?: PaymentMethod;
    amount?: string;
};

function WithdrawalButton(props: WithdrawalButtonType) {
    const { buttonTitle, variation, amount, paymentMethod } = props;
    const valid = useObservable<boolean>(PixFormService.valid, false);
    const state = useObservable(Directa24WithdrawService.state, Directa24WithdrawService.state.getValue());

    const min = paymentMethod && paymentMethod.limit.withdraw.min;
    const sumToWithdraw = amount && parseInt(amount) - parseInt(amount) * 0.2;
    const disabledFee = sumToWithdraw && min && sumToWithdraw >= min;

    const className = classNames('withdrawal-button-pix', {
        'withdrawal-button-pix__active': valid || (!variation && disabledFee),
        'withdrawal-button-pix__variated': variation && !disabledFee,
    });
    return (
        <button type="submit" className={className} disabled={!valid || (variation && !disabledFee)}>
            {state === Directa24WithdrawState.AWAIT ? (
                <img className="withdrawal-button-pix__wait" src={Spinner} alt="" />
            ) : (
                t(buttonTitle)
            )}
        </button>
    );
}

export default WithdrawalButton;
