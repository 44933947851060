import { SlotegratorGameMode } from '@ay_tsarbet/newbet-core/dist/connection/actions/actionEnums';
import RouteConfig from 'appConstants/RouteConfig';
import { VFC } from 'react';
import { Link } from 'react-router-dom';
import GamesService, { MIN_SEARCH_QUERY_LENGTH } from 'service/Games';
import { translate as t } from 'service/Language';
import { useIsDesktop } from 'utils/useBreakpoints';
import useObservable from 'utils/useObservable';

import GameHelper from '../../service/GameHelper';
import GameProvider from '../../service/model/GameProvider';
import SearchResultItem from './SearchResultItem';
import tournament from '../../service/model/Tournament';

import './SearchResult.scss';

const SearchResult: VFC = () => {
    const games = useObservable(GamesService.searchResultGames, GamesService.searchResultGames.getValue());
    const providers = useObservable(GamesService.searchResultProviders, GamesService.searchResultProviders.getValue());
    const searchText = useObservable(GamesService.searchText, GamesService.searchText.getValue());
    const isDesktop = useIsDesktop();

    if (!games.length && !providers.length) {
        return (
            <div className="search-result-zero-screen">
                {searchText.length < MIN_SEARCH_QUERY_LENGTH ? (
                    t('casino.search.placeholder')
                ) : (
                    <>
                        {t('casino.search.error1')}
                        <br />
                        {t('casino.search.error2')}
                    </>
                )}
            </div>
        );
    }

    function gameCount(provider: GameProvider) {
        return provider.gameList.filter((game) => GameHelper.matchPlatform(game, isDesktop)).length;
    }

    return (
        <div className="search-result-list">
            {providers.map((provider) => (
                <Link to={`${RouteConfig.casino.providers}/${provider.route}`} className="search-result-item-link">
                    <SearchResultItem
                        name={provider.name}
                        searchText={searchText}
                        description={`${gameCount(provider)}${' ' + t('casino.providers.games')}`}
                        imageSrc={provider.image}
                        centerLogo
                        onClick={() => !isDesktop && GamesService.setSearchText('')}
                    />
                </Link>
            ))}
            {!!providers.length && !!games.length && <div className="search-result-delimeter" />}
            {games
                .filter((game) => GameHelper.matchPlatform(game, isDesktop))
                .map((game) => (
                    <SearchResultItem
                        name={game.name}
                        searchText={searchText}
                        description={game.provider.name}
                        imageSrc={game.image}
                        onClick={() => {
                            GameHelper.loadGame(game, SlotegratorGameMode.REAL, tournament);
                        }}
                    />
                ))}
        </div>
    );
};

export default SearchResult;
