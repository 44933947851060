import { translate as t } from 'service/Language';

import './WheelBlack.scss';

type PrizeList = {
    id: number;
    title: string;
    angle: number;
};

type WheelProps = {
    angle: number;
};

function WheelBlack({ angle }: WheelProps) {
    const PrizeList: PrizeList[] = [
        { id: 1, title: t('wheel.bonus.125'), angle: 0 },
        { id: 2, title: t('wheel.again'), angle: 45 },
        { id: 3, title: t('wheel.freespin.250'), angle: 90 },
        { id: 4, title: t('wheel.bonus.75'), angle: 135 },
        { id: 5, title: t('wheel.nothing'), angle: 180 },
        { id: 6, title: t('wheel.freespin.70'), angle: 225 },
        { id: 7, title: t('wheel.again'), angle: 270 },
        { id: 8, title: t('wheel.freespin.250'), angle: 315 },
    ];
    const spin = {
        transform: `rotate(${angle}deg)`,
    };

    return (
        <div className="wheel-black" style={spin}>
            <div className="wheel-black__field" />
            <div className="wheel-black__text">
                {PrizeList.map((item) => (
                    <div style={{ transform: `rotate(${item.angle}deg)` }} className="wheel-black__text__value">
                        {item.title}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default WheelBlack;
