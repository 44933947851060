export const setHostname = () => {
    const host = window.location.host;
    if (host.match(/\b(dev)\b/g) && !host.startsWith('dev')) {
        return `wss://wss.00kk.club/casino`;
    }
    if (host.match(/localhost/g) || host.includes('192.168')) {
        return process.env.REACT_APP_WSS_URL || 'wss://wss.00kk.club/casino';
    }
    if (host.match(/preprod/g)) {
        return 'wss://wss.glory.casino/casino';
    }
    return `wss://wss.${host}/casino`;
};
