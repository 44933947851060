/* eslint-disable max-len */

import { Icon } from 'types';

function FireIcon({ color = '#FFFFFF', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                d="M19.5492 10.9971C19.0942 9.97305 18.4328 9.05388 17.6063 8.29714L16.9242 7.67136C16.9011 7.65069 16.8732 7.63603 16.843 7.62865C16.8129 7.62127 16.7814 7.62141 16.7513 7.62904C16.7212 7.63668 16.6935 7.65158 16.6705 7.67245C16.6475 7.69331 16.63 7.71949 16.6195 7.7487L16.3148 8.62292C16.125 9.17136 15.7758 9.73151 15.2812 10.2823C15.2484 10.3175 15.2109 10.3268 15.1852 10.3292C15.1594 10.3315 15.1195 10.3268 15.0844 10.294C15.0516 10.2659 15.0352 10.2237 15.0375 10.1815C15.1242 8.77058 14.7023 7.17917 13.7789 5.44714C13.0148 4.00808 11.9531 2.88542 10.6266 2.10261L9.65859 1.53308C9.53203 1.45808 9.37031 1.55652 9.37734 1.70417L9.42891 2.82917C9.46406 3.59792 9.375 4.27761 9.16406 4.84245C8.90625 5.53386 8.53594 6.17605 8.0625 6.75261C7.73302 7.1533 7.35959 7.51573 6.94922 7.83308C5.96087 8.59284 5.15722 9.56636 4.59844 10.6807C4.04103 11.8048 3.75068 13.0424 3.75 14.2971C3.75 15.4034 3.96797 16.4745 4.39922 17.4846C4.81562 18.4572 5.41654 19.3399 6.16875 20.0839C6.92813 20.8339 7.80938 21.4245 8.79141 21.8346C9.80859 22.2612 10.8867 22.4768 12 22.4768C13.1133 22.4768 14.1914 22.2612 15.2086 21.837C16.1882 21.4292 17.079 20.8346 17.8312 20.0862C18.5906 19.3362 19.1859 18.4596 19.6008 17.487C20.0314 16.4796 20.2523 15.395 20.25 14.2995C20.25 13.1557 20.0156 12.0448 19.5492 10.9971Z"
                fill={color}
            />
        </svg>
    );
}

export default FireIcon;
