import Form from "./Form/Form";
import { useLocation } from "react-router-dom";
import CricketForm from "./CricketForm/CricketForm";
import "./Registration.scss";

function Registration() {
  const location = useLocation();
  if (location.pathname === "/cricket") {
    return <CricketForm />;
  }
  return (
    <div className="registration">
      <Form />
      <div className="registration__chip registration__chip-small" />
      <div className="registration__chip registration__chip-big" />
    </div>
  );
}

export default Registration;
