import Validator from '../interface/Validator';

class BkashTransaction implements Validator {
    validate(value: string): boolean {
        return this.symbolCheck(value) && this.lengthCheck(value);
    }

    numberCheck(value: string): boolean {
        return new RegExp('^[Z0-9]+$').test(value[0]);
    }

    letterSecondCheck(value: string): boolean {
        return new RegExp('^[A-Za-z]+$').test(value[1]);
    }

    symbolCheck(value: string): boolean {
        return new RegExp('^[A-Za-z0-9]+$').test(value);
    }

    lengthCheck(value: string): boolean {
        return value.length >= 10;
    }
}
const BkashTransactionService = new BkashTransaction();

export { BkashTransactionService };
export default BkashTransaction;
