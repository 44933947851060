import { useEffect } from 'react';

import GoogleService from '../../../../../../service/Google';
import OperationsService from '../../../../../../service/Operations';
import useObservable from '../../../../../../utils/useObservable';
import { PaymentMethod } from '../../../PaymentMethod/types';
import { translate as t } from '../../../../../../service/Language';

import Amount from './Amount/Amount';
import WellxPayDepositService, {DepositState} from '../service/WellxpayDeposit';
import FailedPage from '../../ResultPage/FailedPage/FailedPage';

import './Form.scss';


type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const {paymentMethod} = props;
    const state = useObservable(WellxPayDepositService.state, WellxPayDepositService.state.getValue());

    useEffect(() => {
        const gaSub = OperationsService.invoice.subscribe({
            next: (data) => {
                const clientId = GoogleService.getClientId();
                const invoiceId = data.id.toString();
                OperationsService.sendGAClientRelation(clientId, invoiceId);
            },
        });
        return () => {
            gaSub.unsubscribe();
            WellxPayDepositService.reset();
        };
    }, []);

    console.log('BKashP2C')

    return (
        <div className="deposit-form">
            {(state === DepositState.AMOUNT || state === DepositState.WAIT) && (
                <>
                    <div className="deposit-form__header">
                        <img className="deposit-form__img" src={paymentMethod.icon} alt={paymentMethod.title}/>
                        <h2 className="deposit-form__title">{t(paymentMethod.title)}</h2>
                    </div>
                    <Amount paymentMethod={paymentMethod} lock={state !== DepositState.AMOUNT && state !== DepositState.WAIT} />
                </>
            )}

            {state === DepositState.FAILED ? <FailedPage /> : null}
        </div>
    );
}

export default Form;
