import { NavLink } from 'react-router-dom';
import LanguageService, { translate as t } from 'service/Language';
import SvgImage from 'svg';
import useObservable from 'utils/useObservable';

import colors from '../../../../appConstants/colors';
import FooterService from '../../../Footer/service/Footer';
import RegistrationFormService from '../service/RegistrationForm';

import './AcceptCheckbox.scss';

function AcceptCheckbox() {
    const acceptCheckboxStatus = useObservable<boolean>(
        RegistrationFormService.termsAgreement,
        RegistrationFormService.termsAgreement.value
    );

    const currentLang = useObservable(LanguageService.current, LanguageService.current.getValue());

    return (
        <div className="registration-accept-checkbox">
            <button
                onClick={() => RegistrationFormService.toggleTermsAndCondition()}
                className="registration-accept-checkbox__check"
                type="button"
            >
                {acceptCheckboxStatus && <SvgImage width={9} height={9} color={colors.royalBlue} name="Check" />}
            </button>
            <div>
                {currentLang.title !== 'UZ' && <span>{t('registration.accept.checkbox.accept')} </span>}
                <NavLink to={FooterService.documentList[1].path} className="registration-accept-checkbox__link">
                    {t('registration.accept.checkbox.privacy')}
                </NavLink>

                {currentLang.title === 'UZ' && <span>{t('registration.accept.checkbox.accept')}</span>}
            </div>
        </div>
    );
}

export default AcceptCheckbox;
