import { endOfDay, formatISO, parse } from 'date-fns';

import Validator from '../interface/Validator';

class DueDate implements Validator {
    validate(value: string): boolean {
        return this.checkLength(value) && this.checkDifference(value);
    }

    checkLength(value: string): boolean {
        return value.length === 10;
    }

    checkDifference(value: string): boolean {
        const now = formatISO(endOfDay(new Date()));
        const months = value.slice(5, 7);
        const days = value.slice(8, 10);
        if (months && days) {
            const dueDate = formatISO(parse(value, 'yyyy:MM:dd', new Date()));
            return dueDate >= now;
        } else return false;
    }
}

const DueDateService = new DueDate();

export { DueDateService };
export default DueDate;
