import { translate as t } from 'service/Language';

import Failed from './image/failed.svg';
import InstapayWithdrawService, { InstapayWithdrawState } from '../service/InstapayWithdraw';
import StatusFormPopupService, { StatusForm } from '../../../Deposit/DepositPopup/service/StatusForm';

import './FailedPage.scss';


function FailedPage() {
    function onClickHandler() {
        StatusFormPopupService.status.next(StatusForm.INIT)
        InstapayWithdrawService.state.next(InstapayWithdrawState.CARD)
    }
    return (
        <div className="result-pix">
            <div className="result-pix-container">
                <img className="result-pix-container__img" src={Failed} alt="failed" />
                <p className="result-pix-container__title ">{t('popup.payment.error.title')}</p>
                <span className="result-pix-container__text">{t('popup.payment.error.text')}</span>
                <button className="result-pix-container__btn" onClick={onClickHandler}>
                    {t('popup.payment.error.back')}
                </button>
            </div>
        </div>
    );
}

export default FailedPage;
