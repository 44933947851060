import { translate as t } from 'service/Language';

import TableBonus from './TableBonus/TableBonus';

import './WelcomeDescription.scss';

type DescriptionType = {
    title: string;
    description: string;
};

function WelcomeDescription() {
    const descriptionList: DescriptionType[] = [
        {
            title: t('info.promo.welcome.activation.title'),
            description: t('info.promo.welcome.activation.description'),
        },
        {
            title: t('info.promo.welcome.get.title'),
            description: t('info.promo.welcome.get.description'),
        },
        {
            title: t('info.promo.welcome.winback.title'),
            description: t('info.promo.welcome.winback.description'),
        },
        {
            title: t('info.promo.welcome.importantly.title'),
            description: t('info.promo.welcome.importantly.description'),
        },
    ];

    return (
        <div className="welcome">
            {descriptionList.map((desc) => (
                <div className="welcome__block">
                    <div className="welcome__block__title">{desc.title}</div>
                    <div className="welcome__block__description">{desc.description}</div>
                </div>
            ))}
            <div>
                <div className="welcome__header">{t('info.promo.welcome.table')}</div>
                <TableBonus />
            </div>
        </div>
    );
}

export default WelcomeDescription;
