import RouteConfig from 'appConstants/RouteConfig';
import { first, Subscription } from 'rxjs';

import WheelRegistrationCriket from '../app/Landing/Wheel/Criket/WheelRegistationCriket/WheelRegistationCriket';
import WheelRegistrationAviator from '../app/Landing/Wheel/Aviator/WheelRegistationAviator/WheelRegistrationAviator';
import WheelWinAviator from '../app/Landing/Wheel/Aviator/WheelWinAviator/WheelWinAviator';
import WheelRegistrationCard from '../app/Landing/Wheel/Cards/WheelRegistationCard/WheelRegistrationCard';
import WheelWinCard from '../app/Landing/Wheel/Cards/WheelWinCard/WheelWinCard';
import WheelRegistrationGirl from '../app/Landing/Wheel/Girls/WheelRegistationGirl/WheelRegistrationGirl';
import WheelWinGirl from '../app/Landing/Wheel/Girls/WheelWinGirl/WheelWinGirl';
import WheelRegistrationTeen from '../app/Landing/Wheel/Teenagers/WheelRegistationTeen/WheelRegistrationTeen';
import WheelWinTeen from '../app/Landing/Wheel/Teenagers/WheelWinTeen/WheelWinTeen';
import LogIn from '../component/LogIn/LogIn';
import LostConnection from '../component/LostConnection/LostConnection';
import Registration from '../component/Registration/Registration';
import AuthService from './Auth';
import { HistoryService } from './HistoryService';
import PopupService from './Popup';
import RegistrationService from './Registration';
import UIService from './UI';
import UserTrackService from './UserTrack';
import WSBetService from './WSBet';
import WheelRegistationSlots from 'app/Landing/Wheel/Slots/WheelRegistationSlots/WheelRegistationSlots';
import Bonus from './model/Bonus';
import Popup from './Popup';
import PopupCancel from '../page/Profile/Bonus/PopupCancel/PopupCancel';
import WheelWinCrazyTime from 'app/Landing/Wheel/CrazyTime/WheelWinCrazyTime/WheelWinCrazyTime';
import WheelRegistrationCrazyTime from 'app/Landing/Wheel/CrazyTime/WheelRegistationCrazyTime/WheelRegistrationCrazyTime';
import NewYearSuccess from '../component/NewYearSuccess/NewYearSuccess';
import ModalCountrySelect from 'component/ModalCountrySelect';
import CountrySupportService from './CountrySupport';
import ChatwootService from './Chatwoot';
import WheelWinRoulette from '../app/Landing/Wheel/LigtningRoulette/WheelWinRoulette/WheelWinRoulette';
import WheelRegistrationRoulette
    from '../app/Landing/Wheel/LigtningRoulette/WheelRegistationRoulette/WheelRegistrationRoulette';
import WheelRegistrationBonanza from '../app/Landing/Wheel/Bonanza/WheelRegistationBonanza/WheelRegistrationBonanza';
import WheelWinBonanza from '../app/Landing/Wheel/Bonanza/WheelWinBonanza/WheelWinBonanza';
import WheelRegistrationOlympus from '../app/Landing/Wheel/Olympus/WheelRegistationBonanza/WheelRegistrationOlympus';
import WheelWinOlympus from '../app/Landing/Wheel/Olympus/WheelWinBonanza/WheelWinOlympus';

type WheelType = 'card' | 'girl' | 'teen' | 'aviator' | 'criket' | 'slots' | 'crazytime' | 'roulette' | 'bonanza' | 'olympus';

class PopupHelper {
    public static preventByAuth(callback: () => void): boolean {
        if (AuthService.isAwait()) {
            let authResultSub: Subscription | null = null;

            authResultSub = AuthService.result.subscribe(() => {
                authResultSub?.unsubscribe();
                callback();
            });
            return true;
        } else {
            if (AuthService.auththorized()) {
                return true;
            }
        }
        return false;
    }

    public static showRegistration() {
        console.log('111', 111);
        // if (PopupHelper.preventByAuth(PopupHelper.showRegistration)) {
        //     console.log('222', 222);
        //     return;
        // }

        PopupService.show(<Registration />);
        const regSub = RegistrationService.complete.subscribe(data => {
            PopupService.close();

            if (data && data.returnPassword) {
                AuthService.login(RegistrationService.login, data.returnPassword);
                HistoryService.clearHash();
                HistoryService.setHash(RouteConfig.hash.deposit);
            }
        });

        let popupSub: Subscription | null = null;

        popupSub = PopupService.onChange.subscribe(component => {
            regSub.unsubscribe();
            popupSub?.unsubscribe();
        });
    }

    public static showCountrySelectModal() {
        PopupService.show(<ModalCountrySelect />);

        const countrySub = CountrySupportService.countryCode.subscribe(code => {
            if (code) {
                PopupService.close();
                countrySub.unsubscribe();
                setTimeout(() => {
                    console.log('INIT CHATWOOT');
                    if (!ChatwootService.isInitialized) {
                        ChatwootService.init();
                    } else {
                        ChatwootService.show();
                    }
                }, 0);
            }
        });
    }

    public static showLogin() {
        // if (PopupHelper.preventByAuth(PopupHelper.showLogin)) {
        //     return;
        // }

        PopupService.show(<LogIn />);

        const authSub = AuthService.success.subscribe(() => {
            PopupService.close();
        });

        let popupSub: Subscription | null = null;

        popupSub = PopupService.onChange.subscribe(component => {
            authSub.unsubscribe();
            popupSub?.unsubscribe();
        });
    }

    public static showLostConnection() {
        const reconnects = WSBetService.reconnectsCount();
        console.log('showLostConnection reconnects', reconnects);
        if (reconnects === 5) {
            PopupService.show(<LostConnection />);
        }
    }

    public static closeLostConnection() {
        PopupService.close();
    }

    public static showPopupCancel(bonus: Bonus) {
        Popup.show(<PopupCancel bonus={bonus} />);
    }

    public static showWheelWin(type: WheelType, spin: () => void) {
        if (type === 'card') PopupService.show(<WheelWinCard spin={spin} />);
        if (type === 'girl') PopupService.show(<WheelWinGirl spin={spin} />);
        if (type === 'crazytime') PopupService.show(<WheelWinCrazyTime spin={spin} />);
        if (type === 'teen') PopupService.show(<WheelWinTeen spin={spin} />);
        if (type === 'aviator') PopupService.show(<WheelWinAviator spin={spin} />);
        if (type === 'roulette') PopupService.show(<WheelWinRoulette spin={spin} />);
        if (type === 'bonanza') PopupService.show(<WheelWinBonanza spin={spin} />);
        UIService.lockScroll();
        if (type === 'olympus') PopupService.show(<WheelWinOlympus spin={spin} />);
        UIService.lockScroll();
    }

    public static showWheelRegistration(type: WheelType) {
        if (type === 'card') PopupService.show(<WheelRegistrationCard />);
        if (type === 'girl') PopupService.show(<WheelRegistrationGirl />);
        if (type === 'crazytime') PopupService.show(<WheelRegistrationCrazyTime />);
        if (type === 'teen') PopupService.show(<WheelRegistrationTeen />);
        if (type === 'aviator') PopupService.show(<WheelRegistrationAviator />);
        if (type === 'criket') PopupService.show(<WheelRegistrationCriket />);
        if (type === 'slots') PopupService.show(<WheelRegistationSlots />);
        if (type === 'roulette') PopupService.show(<WheelRegistrationRoulette />);
        if (type === 'bonanza') PopupService.show(<WheelRegistrationBonanza />);
        if (type === 'olympus') PopupService.show(<WheelRegistrationOlympus />);

        UserTrackService.renew();

        UIService.lockScroll();

        const regSub = RegistrationService.complete.subscribe(data => {
            UIService.unlockScroll();
            PopupService.close();
            if (data && data.returnPassword) {
                AuthService.success.pipe(first()).subscribe(() => {
                    console.log('PopupHelper.showWheelRegistration', 'Login success, redirect');
                    HistoryService.clearHash();
                    HistoryService.setHash(RouteConfig.hash.deposit);
                });
                AuthService.login(RegistrationService.login, data.returnPassword);
            }
        });

        let lockSub: Subscription | null = null;
        lockSub = PopupService.onChange.subscribe(component => {
            UIService.unlockScroll();
            lockSub?.unsubscribe();
            regSub.unsubscribe();
        });
    }

    public static showNewYear() {
        PopupService.show(<NewYearSuccess />);
    }

    public static showDeposit() {}
}

export default PopupHelper;
