import { PaymentMethod } from 'page/Profile/Balance/PaymentMethod/types';

class AzarpayWithdraw {
    amount: number;

    currencyId: number;

    channelType: number;

    surname: string;

    name: string;

    bankId: string;

    dueDate: string;

    card: string;

    constructor(method: PaymentMethod, surname: string, name: string, card: string, bankId: string, dueDate: string, amount: number) {
        this.currencyId = method.currency.ISO;
        this.channelType = method.id;
        this.surname = surname;
        this.name = name;
        this.card = card;
        this.bankId = bankId;
        this.dueDate = dueDate;
        this.amount = amount;
    }
}

export default AzarpayWithdraw;
