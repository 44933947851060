/* eslint-disable max-len */

import { Icon } from 'types';

function BigMma({ color = '#3959D9', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                d="M8.47519 12.8248H15.5252L16.5752 12.2875C16.5002 12.2108 16.4252 12.134 16.3502 12.0956L13.9877 10.9059C13.8752 10.8675 13.7627 10.8291 13.6502 10.8291H10.3127C10.2002 10.8291 10.0877 10.8675 9.97519 10.9059L7.61269 12.0956C7.53769 12.134 7.4627 12.2108 7.3877 12.2875L8.47519 12.8248Z"
                fill={color}
            />
            <path
                d="M23.5875 12.0959L23.4375 12.0192V11.29V11.0213V9.5629V7.45203C23.4375 6.87633 23.1375 6.33902 22.6125 6.07036L16.9125 3.15352C16.6875 3.03838 16.4625 3 16.2375 3H7.7625C7.5375 3 7.3125 3.03838 7.0875 3.15352L1.3875 6.07036C0.8625 6.33902 0.5625 6.87633 0.5625 7.45203V9.5629V11.0213V11.29V12.0192L0.4125 12.0959C0.15 12.2111 0 12.4797 0 12.7868V16.9318C0 17.2388 0.15 17.5075 0.4125 17.6226L6.8625 20.9232C6.975 20.9616 7.0875 21 7.2 21H16.8C16.9125 21 17.025 20.9616 17.1375 20.9232L23.5875 17.6226C23.85 17.5075 24 17.2388 24 16.9318V12.7868C24 12.4797 23.85 12.2111 23.5875 12.0959ZM21.9375 7.45203V9.52452V10.8725L16.9125 8.4183V4.76756L21.9375 7.45203ZM2.00206 14.0149V12.4341L7.0875 14.9781V18.4203L2.00206 15.8188V14.0149ZM7.7625 9.13224H16.275L21.4094 11.597L16.275 14.2575H7.7625L2.6007 11.597L7.7625 9.13224ZM16.0986 18.6972H7.91123V15.0717H16.0986V18.6972ZM21.9375 15.8188L16.9125 18.4203V14.9907L21.9375 12.4467V14.0149V15.8188ZM16.0986 4.4968V8.19203H7.91123V4.4968H16.0986ZM2.00206 9.5629V7.45203L7.0875 4.90698V8.50581L2.00206 10.8725V9.5629Z"
                fill={color}
            />
        </svg>
    );
}

export default BigMma;
