import { parse } from 'date-fns';
import { combineLatestWith, map, Observable, Observer } from 'rxjs';

import GloryAPI from './Api';
import GamesService from './Games';
import { getCurrency } from './model/Currency';
import Game, { GameList, unknownGame } from './model/Game';
import TournamentModel, {
    TournamentImage,
    TournamentList,
    TournamentRecord,
    TournamentRecordList,
    TournamentStatus,
    TournamentStructure,
    TournamentType,
} from './model/Tournament';

type GameMap = Map<number, Game>;

const statusMap = new Map<number, TournamentStatus>(
    [TournamentStatus.CREATED, TournamentStatus.ACTIVE, TournamentStatus.RESULT, TournamentStatus.CLOSED].map(
        (status) => [status, status]
    )
);
const typeMap = new Map<number, TournamentType>(
    [TournamentType.LOCAL, TournamentType.PROVIDER].map((type) => [type, type])
);

class Tournament {
    handler = {
        onSubscribe: this.onSubscribe.bind(this),
    };

    request = new Observable<TournamentRecordList>(this.handler.onSubscribe);

    recordList = this.request;

    list = this.recordList.pipe(combineLatestWith(GamesService.games)).pipe(map(Tournament.fromRecordList));

    onSubscribe(observer: Observer<TournamentRecordList>) {
        void GloryAPI.tournament.getList().then((list) => observer.next(list));
    }

    static fromRecord(record: TournamentRecord, gameMap: GameMap): TournamentModel {
        const gameList = record.gameList.map((id) => gameMap.get(id) || unknownGame).filter((game) => game.isReal());

        const data = new TournamentStructure({
            id: record.id,
            currency: getCurrency(record.currencyId),
            name: record.name,
            type: typeMap.get(record.type) || TournamentType.LOCAL,
            status: statusMap.get(record.status) || TournamentStatus.CREATED,
            imageList: record.image.map((imageRecord) => new TournamentImage(imageRecord)),
            startDate: parse(record.startDate.substring(0, 10), 'yyyy-MM-dd', new Date()),
            endDate: parse(record.endDate.substring(0, 10), 'yyyy-MM-dd', new Date()),
            rules: record.rules,
            gameList,
            prizePool: record.prizePool,
            ladder: [],
        });

        return new TournamentModel(data);
    }

    static fromRecordList([recordList, fullGameList]: [TournamentRecordList, GameList]) {
        const gameMap = new Map<number, Game>(fullGameList.map((game) => [game.id, game]));

        return recordList.map((record) => Tournament.fromRecord(record, gameMap));
    }

    static createMap(list: TournamentList) {
        return new Map<number, TournamentModel>(list.map((tournament) => [tournament.id, tournament]));
    }

    find(id: number) {
        return new Observable<TournamentModel | null>((observer) => {
            const relay = {
                next: (list: TournamentList) => {
                    const map = Tournament.createMap(list);
                    observer.next(map.get(id) || null);
                },
                error: () => {},
                complete: () => {},
            };
            return this.list.subscribe(relay);
        });
    }
}

const TournamentService = new Tournament();

export default TournamentService;
