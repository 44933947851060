import { match } from 'react-router';

import RxComponent from '../../component/RxComponent/RxComponent';
import Tournament from '../../service/model/Tournament';
import TournamentService from '../../service/Tournament';
import Info from './Info/Info';

type TournamentMatchParams = {
    id: string;
};

type TournamentIdProps = {
    match: match<TournamentMatchParams>;
};

type TournamentIdState = {
    tournament: Tournament | null;
};

class TournamentId extends RxComponent<TournamentIdProps, TournamentIdState> {
    state = {
        tournament: null,
    };

    handler = {
        setTournament: this.setTournament.bind(this),
    };

    setTournament(tournament: Tournament | null) {
        this.setState({ tournament });
    }

    componentDidMount() {
        const { match } = this.props;
        const id = parseInt(match.params.id);

        this.subscribe(TournamentService.find(id), { next: this.handler.setTournament });
    }

    render() {
        const { tournament } = this.state;

        return tournament ? <Info tournament={tournament} /> : null;
    }
}

export default TournamentId;
