import AccountBankalfallah from './AccountBankalfallah';
import AccountEasypaisa from './AccountEasypaisa';
import AccountNumber from './AccountNumber';
import BankCode from './BankCode';
import BirthDay from './BirthDay';
import BkashTransaction from './BkashTransaction';
import CardNumber from './CardNumber';
import CheckBirthDate from './CheckBirthDate';
import CheckSymbol from './CheckSymbol';
import DueDate from './DueDate';
import EasypaisaTransaction from './EasypaisaTransaction';
import Email from './Email';
import FourthNomber from './FourthNomber';
import IbanBankalfallah from './IbanBankalfallah';
import JazzcashTransaction from './JazzcashTransaction';
import Limit from './Limit';
import MaskCardNumber from './MaskCardNumber';
import MinLimit from './MinLimit';
import NagadTransaction from './NagadTransaction';
import NonEmpty from './NonEmpty';
import OnlyLetter from './OnlyLetter';
import Password from './Password';
import PayTMTransaction from './PayTMTransaction';
import PersonalName from './PersonalName';
import Phone from './Phone';
import PhoneAccount from './PhoneAccount';
import PhoneFull from './PhoneFull';
import PhoneMpesa from './PhoneMpesa';
import PhonePaykassma from './PhonePaykassma';
import PhonePeTransaction from './PhonePeTransaction';
import RocketTransaction from './RocketTransaction';
import WalletEasypaisa from './WalletEasypaisa';
import WalletZoft from './WalletZoft';
import ZoftValue from './ZoftValue';
import PhonePtm from './PhonePtm';
import CheckTurkeyLetters from './CheckTurkeyLetters';
import AccountPayfix from './AccountPayfix';
import CPF from './CPF';
import LettersWithSpace from './LettersWithSpace';
import CheckBrazilLetters from './CheckBrazilLetters';
import AccountPapara from './AccountPapara';
import PhoneHavaleVIP from './PhoneHavaleVIP';
import TurkeyIban from './TurkeyIban';
import AccountMefete from './AccountMefete';
import AccountHavale from './AccountHavale';
import AccountCepbank from './AccountCepbank';
import AccountUpay from './AccountUpay';
import DueDateMonthYear from './DueDateMonthYear';
import PhoneEmanat from './PhoneEmanat';
import MaskCardLunh from './MaskCardLunh';
import ExpMonthFullYear from './ExpMonthFullYear';
import VPA from './VPA';
import Min6Lenght from './Min6Lenght';
import LettersNumbers from './LettersNumbers';
import Length from './Length';
import PhonePaykassmaWith from './PhonePaykassmaWith';
import PhonePaykassmaRocket from './PhonePaykassmaRocket';
import PhoneMyanmar from './PhoneMyanmar';

const Validator = {
    phoneFull: new PhoneFull(),
    phone: new Phone(),
    phonePaykassma: new PhonePaykassma(),
    phonePaykassmaWith: new PhonePaykassmaWith(),
    phonePaykassmaRocket: new PhonePaykassmaRocket(),
    phonePtm: new PhonePtm(),
    phoneMpesa: new PhoneMpesa(),
    phoneEmanat: new PhoneEmanat(),
    email: new Email(),
    nonEmpty: new NonEmpty(),
    password: new Password(),
    cardNumber: new CardNumber(),
    maskCardNumber: new MaskCardNumber(),
    maskCardLunh: new MaskCardLunh(),
    bkashTransaction: new BkashTransaction(),
    nagadTransaction: new NagadTransaction(),
    easypaisaTransaction: new EasypaisaTransaction(),
    jazzcashTransaction: new JazzcashTransaction(),
    CheckSymbolService: new CheckSymbol(),
    personalName: new PersonalName(),
    phoneAccount: new PhoneAccount(),
    CheckBirthDateService: new CheckBirthDate(),
    rocketTransaction: new RocketTransaction(),
    walletZoft: new WalletZoft(),
    walletEasypaisa: new WalletEasypaisa(),
    accountEasypaisa: new AccountEasypaisa(),
    accountBankalfallah: new AccountBankalfallah(),
    accountPayfix: new AccountPayfix(),
    ibanBankalfallah: new IbanBankalfallah(),
    zoftValue: new ZoftValue(),
    lettersWithSpace: new LettersWithSpace(),
    OnlyLetter: new OnlyLetter(),
    checkTurkeyLetters: new CheckTurkeyLetters(),
    checkBrazilLetters: new CheckBrazilLetters(),
    birth: new BirthDay(),
    dueDate: new DueDate(),
    dueDateMonthYear: new DueDateMonthYear(),
    expMonthFullYear: new ExpMonthFullYear(),
    accountNumber: new AccountNumber(),
    fourthNumber: new FourthNomber(),
    backCode: new BankCode(),
    payTMTransaction: new PayTMTransaction(),
    phonePeTransaction: new PhonePeTransaction(),
    cpf: new CPF(),
    vpa: new VPA(),
    accountPapara: new AccountPapara(),
    accountMefete: new AccountMefete(),
    accountHavale: new AccountHavale(),
    accountCepbank: new AccountCepbank(),
    accountUpay: new AccountUpay(),
    phoneHavaleVip: new PhoneHavaleVIP(),
    turkeyIban: new TurkeyIban(),
    bankBillCP: new Min6Lenght(),
    lettersNumbers: new LettersNumbers(),
    length9: new Length(),
    phoneMyanmar: new PhoneMyanmar(),
    limit: Limit,
    minLimit: MinLimit,

    equal: function (a: string, b: string) {
        return a === b;
    },
};

export default Validator;
