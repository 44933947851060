/* eslint-disable max-len */

import { Icon } from 'types';

function Corner({ color = '#3959D9', width = 12, height = 12 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 12 12" fill="none">
            <g clipPath="url(#clip0)">
                <path
                    d="M11.9583 7.48542C11.8946 7.39734 11.7722 7.37629 11.6827 7.43803H11.6831L8.57509 9.68843C7.85378 9.26426 7.03677 9.02976 6.20028 9.00682V4.3974C6.26867 4.39961 6.35108 4.4012 6.44607 4.4012C7.27687 4.4012 9.03288 4.28 10.1305 3.33941C10.1748 3.30142 10.2003 3.24596 10.2003 3.1876V0.199996C10.2003 0.121879 10.1548 0.0509336 10.0838 0.0182851C10.0128 -0.0143399 9.92936 -0.00266805 9.87007 0.0481914C8.76027 0.99999 6.80626 1.02579 6.20026 1.0088V0.199996C6.20026 0.0895353 6.11073 3.82959e-06 6.00027 3.82959e-06C5.88981 3.82959e-06 5.80028 0.0895353 5.80028 0.199996V9.00682C4.96381 9.02974 4.14678 9.26426 3.42547 9.68843L0.317484 7.43803C0.227999 7.37629 0.105608 7.39736 0.0418816 7.48542C-0.0218451 7.5735 -0.00354041 7.69633 0.0830848 7.76203L3.30029 10.0922L3.30429 10.095L5.8831 11.9621C5.89126 11.9671 5.89982 11.9715 5.9087 11.9751C5.91559 11.9791 5.92274 11.9828 5.9301 11.9859C5.97486 12.0048 6.02535 12.0048 6.07009 11.9859C6.07745 11.9828 6.0846 11.9791 6.09149 11.9751C6.10037 11.9715 6.10892 11.9671 6.11708 11.9621L11.9171 7.76205C12.0037 7.69633 12.022 7.5735 11.9583 7.48542ZM6.20028 1.4098C6.81008 1.42859 8.56848 1.40499 9.80029 0.599982V3.09338C8.68028 3.98858 6.79349 4.01338 6.20028 3.9962V1.4098ZM5.8003 11.4082L3.7775 9.94343C4.40171 9.61184 5.09382 9.42825 5.8003 9.40683V11.4082ZM6.20028 11.4082V9.40683C6.90679 9.42825 7.59887 9.61184 8.22309 9.94343L6.20028 11.4082Z"
                    fill={color}
                />
                <path
                    d="M6.80018 2.42523V2.42584H6.80857C6.93517 2.42064 7.07257 2.41225 7.21776 2.39945C7.32806 2.38973 7.40957 2.29244 7.39987 2.18216C7.39014 2.07186 7.29285 1.99035 7.18258 2.00005C7.04397 2.01226 6.91279 2.02004 6.79178 2.02525C6.72032 2.02675 6.6551 2.06626 6.62069 2.12889C6.58626 2.19151 6.58786 2.26776 6.62489 2.32888C6.66189 2.39001 6.72874 2.42673 6.80018 2.42523Z"
                    fill={color}
                />
                <path
                    d="M6.8002 3.42525V3.42586H6.80859C6.9352 3.42065 7.07259 3.41226 7.21779 3.39947C7.28913 3.39319 7.35171 3.34931 7.38192 3.28436C7.41213 3.21942 7.40543 3.14332 7.36432 3.08465C7.32321 3.02601 7.25393 2.99376 7.18258 3.00004C7.04397 3.01225 6.91279 3.02003 6.79179 3.02524C6.72032 3.02674 6.6551 3.06625 6.62069 3.12888C6.58626 3.1915 6.58786 3.26775 6.62489 3.32887C6.66192 3.39002 6.72876 3.42675 6.8002 3.42525Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width={width} height={height} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Corner;
