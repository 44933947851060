/* eslint-disable max-len */

import { Icon } from 'types';

function Union({ color = '#3F4250', width = 14, height = 14 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.24993 0C6.83571 0 6.49992 0.335787 6.49992 0.750001V5.99985H0.75C0.335787 5.99985 0 6.33564 0 6.74985C0 7.16406 0.335786 7.49985 0.75 7.49985H6.49992V13.25C6.49992 13.6642 6.83571 14 7.24993 14C7.66414 14 7.99993 13.6642 7.99993 13.25V7.49985H13.25C13.6642 7.49985 14 7.16406 14 6.74985C14 6.33564 13.6642 5.99985 13.25 5.99985H7.99993V0.750001C7.99993 0.335787 7.66414 0 7.24993 0Z"
                fill={color}
            />
        </svg>
    );
}

export default Union;
