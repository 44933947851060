import { translate as t } from 'service/Language';

import Input from '../../../../../../../component/Input/Input';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { formatCurrencySign } from '../../../../../../../utils/format';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import EasypaisaFormService, { EasypaisaFormError } from '../../service/EasypaisaForm';
import PaykassmaWithdrawService, { PaykassmaWithdrawState } from '../../service/PaykassmaWithdraw';
import WithdrawalButtonEasypaisa from '../../WithdrawalButtonEasypaisa/WithdrawalButtonEasypaisa';
import WithdrawService from '../../../service/Withdrawal';
import AttentionWithdrawalTime from '../../../../../../../component/AttentionWithdrawalTime/AttentionWithdrawalTime';

import './Easypaisa.scss';
import Attention from '../../../../../../../assets/svg/attention.svg';


type EasypaisaProps = {
    paymentMethod: PaymentMethod;
    status: PaykassmaWithdrawState;
};
type EasypaisaState = {
    value: string;
    errorTip: EasypaisaFormError;
    error: string;
};

class Easypaisa extends RxComponent<EasypaisaProps, EasypaisaState> {
    state = {
        value: '',
        error: '',
        errorTip: EasypaisaFormError.NOERROR,
    };

    handler = {
        onError: this.onError.bind(this),
        getValue: this.getValue.bind(this),
        onErrorTip: this.onErrorTip.bind(this),
    };

    onError(error: string) {
        this.setState({error});
    }

    onErrorTip(errorTip: EasypaisaFormError) {
        this.setState({errorTip});
    }

    getValue(value: string) {
        this.setState({value});
    }

    componentDidMount() {
        const {paymentMethod} = this.props;

        EasypaisaFormService.setPaymentMethod(paymentMethod);
        this.subscribe(EasypaisaFormService.error, {next: this.handler.onError});
        this.subscribe(EasypaisaFormService.errorTip, {next: this.handler.onErrorTip});
        this.subscribe(EasypaisaFormService.amount.value, {next: this.handler.getValue});
    }

    render() {
        const {paymentMethod, status} = this.props;

        const {value, errorTip, error} = this.state;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        const fee = WithdrawService.fee.getValue();

        const isInit = status === PaykassmaWithdrawState.INIT;
        if (fee && isInit) {
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.HAS_FEE)
        };
        const amountFee = formatCurrencySign(paymentMethod.limit.withdraw.min * 1.25, 0, paymentMethod.currency);

        return (
            <div className="easy-withdrawal">
                <div className="easy-withdrawal-header">
                    <img src={paymentMethod.icon} alt={paymentMethod.slug}/>
                    <h2 className="easy-withdrawal-header__title">{t(paymentMethod.title)}</h2>
                </div>
                <form className="easy-withdrawal-form" onSubmit={(e) => EasypaisaFormService.onSubmit(e)}>
                    <div className="easy-withdrawal-form-block">
                        <Input
                            className="easy-withdrawal-form-block__input"
                            inputService={EasypaisaFormService.amount}
                            name="amount"
                            type="number"
                            hint={hint}
                        />
                        <span className="easy-withdrawal-form-block__currency">{paymentMethod.currency.sign}</span>
                        {!!error ? (
                            <div className="easy-withdrawal-form-block__error">
                                <img src={Attention} alt="attention"/>
                                <span className="easy-withdrawal-form-block__error_text">
                                {amountFee} {t(error)}
                            </span></div>
                        ) : null}
                    </div>
                    <div className="easy-withdrawal-form-block">
                        <MaskedInput
                            className="easy-withdrawal-form-block__input"
                            mask="00000000000000000000"
                            inputService={EasypaisaFormService.accountNumber}
                            name="accountNumber"
                        />
                    </div>
                    <div className="easy-withdrawal-form-block">
                        <Input
                            className="easy-withdrawal-form-block__input"
                            inputService={EasypaisaFormService.accountName}
                            name="name"
                        />
                        {errorTip === EasypaisaFormError.NAME && (
                            <span className="easy-withdrawal-form-block__tip">
                                {t('popup.balance.deposit.input.name')}
                            </span>
                        )}
                    </div>

                    {((status === PaykassmaWithdrawState.HAS_FEE || status === PaykassmaWithdrawState.AWAIT) && fee) ?
                        <FeeNotice amount={value} fee={fee}/> : null}
                    <AttentionWithdrawalTime/>
                    {(status === PaykassmaWithdrawState.HAS_FEE || status === PaykassmaWithdrawState.AWAIT) ? (
                        <>
                            <WithdrawalButtonEasypaisa
                                buttonTitle="profile.balance.withdraw.fee.agree"
                                variation={true}
                                paymentMethod={paymentMethod}
                                amount={value}
                                state={status}
                            />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel"/>
                        </>
                    ) : (
                        <WithdrawalButtonEasypaisa buttonTitle="profile.balance.withdraw.button" state={status}/>
                    )}
                </form>
            </div>
        );
    }
}

export default Easypaisa;
