import { SlotegratorGameMode } from '@ay_tsarbet/newbet-core/dist/connection/actions/actionEnums';
import classnames from 'classnames';
import { useEffect, useRef } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { translate as t } from 'service/Language';
import React from 'react';

import RouteConfig from '../../../../appConstants/RouteConfig';
import { Games } from '../../../../enums/games';
import { profileRouteList } from '../../../../page/Profile/Profile';
import AuthService from '../../../../service/Auth';
import GameHelper from '../../../../service/GameHelper';
import GamesService from '../../../../service/Games';
import GoogleService from '../../../../service/Google';
import PopupService from '../../../../service/Popup';
import SocialLinkService from '../../../../service/SocialLink';
import UI from '../../../../service/UI';
import UserDataService from '../../../../service/UserData';
import useObservable from '../../../../utils/useObservable';
import LanguageSelect from '../../../common/LanguageSelect/LanguageSelect';
import AuthBlock from './AuthBlock/AuthBlock';
import BalanceBlock from './BalanceBlock/BalanceBlock';
import image from './image/index';
import Pin from './Pin/Pin';
import BalanceService from '../../../../service/Balance';
import {HistoryService} from '../../../../service/HistoryService';

import './List.scss';

type MenuType = {
    img: string;
    title: string;
    path: string;
};

type MenuListProps = {
    className?: string;
};

function List(props: MenuListProps) {
    const { className = '' } = props;
    const sidebarRef = useRef(null);

    const isAuth = useObservable(AuthService.auth, AuthService.auth.getValue());
    const userData = useObservable(UserDataService.data, UserDataService.data.getValue());
    const isOpenSideBar = useObservable(UI.slideMenu, UI.slideMenu.getValue());
    const balance = useObservable(BalanceService.balance, BalanceService.balance.getValue());
    const userId = isAuth ? isAuth.userId.toString() : '';

    const location = useLocation();

    const detectHeight = () => {
        const h = window.innerHeight;
        if (sidebarRef && sidebarRef.current) {
            // eslint-disable-next-line
            // @ts-ignore eslint-disable-next-line
            sidebarRef.current.style.height = `${h}px`;
        }
    };

    useEffect(() => {
        detectHeight();

        const popupSub = PopupService.component.subscribe(component => {
            if (component) {
                UI.closeSlideMenu();
            }
        });

        if (isOpenSideBar || location.hash.includes('#')) {
            UI.closeSlideMenu();
        }
        window.addEventListener('resize', detectHeight);
        return () => {
            window.removeEventListener('resize', detectHeight);
            popupSub.unsubscribe();
        };
        // eslint-disable-next-line
    }, [location]);

    const history = useHistory();
    const routeWelcome = RouteConfig.info.root + RouteConfig.info.promo.root;
    const routeBonus = RouteConfig.profile.root + RouteConfig.profile.bonus;
    const routeBetting = RouteConfig.betting.root;

    const menuList: MenuType[] = [
        { img: image.Home, title: 'sidebar.auth.home', path: RouteConfig.casino.root },
        { img: image.AviatorIcon, title: `${image.AviatorText}`, path: RouteConfig.profile.bonus },
        // { img: image.Jackpot, title: 'sidebar.auth.account.jackpot', path: RouteConfig.jackpot },
        { img: image.Present, title: 'sidebar.auth.account.bonus.program', path: routeWelcome },
        { img: image.Sport, title: 'sidebar.auth.account.sport', path: routeBetting },
        // { img: image.App, title: 'landingAPK.page.mobile', path: RouteConfig.landing.APK },
    ];

    const menuAuthList: MenuType[] = [
        { img: image.Home, title: 'sidebar.auth.home', path: RouteConfig.casino.root },
        { img: image.Profile, title: 'sidebar.auth.account.page', path: profileRouteList.account },
        {
            img: image.Transaction,
            title: 'sidebar.auth.account.history',
            path: profileRouteList.balanceHistory,
        },
        {
            img: image.GameHistory,
            title: 'sidebar.auth.game.history',
            path: profileRouteList.gameHistory,
        },
        { img: image.BonusMenu, title: 'sidebar.auth.account.bonus.page', path: routeBonus },
        { img: image.Sport, title: 'sidebar.auth.account.sport', path: RouteConfig.betting.root },
        { img: image.AviatorIcon, title: `${image.AviatorText}`, path: RouteConfig.profile.bonus },
        {
            img: image.Tournament,
            title: 'sidebar.auth.account.tournaments',
            path: RouteConfig.casino.tournament,
        },
        // { img: image.Jackpot, title: 'sidebar.auth.account.jackpot', path: RouteConfig.jackpot },
        { img: image.Present, title: 'sidebar.auth.account.bonus.program', path: routeWelcome },
        // { img: image.App, title: 'landingAPK.page.mobile', path: RouteConfig.landing.APK },
    ];

    const _className = classnames({
        'menu-list': true,
        [className]: !!className,
    });

    function onClickAviatorGame() {
        const game = GamesService.findGame(Games.AVIATOR);
        if (game.isReal() && balance > 0) {
            GameHelper.loadGame(game, SlotegratorGameMode.REAL, null);
        } else {
            HistoryService.setHash(RouteConfig.hash.deposit);
        }
    }

    function handleLogout() {
        const clientInfo = AuthService.auth.getValue();
        if (clientInfo) {
            GoogleService.logout(clientInfo.userId.toString(), clientInfo.email || '');
        }
        AuthService.logout();
        history.push('/');
    }
    const isConfirm = useObservable(AuthService.confirmEmail, AuthService.confirmEmail.getValue());

    const linkClass =  classnames('menu-list__link', {
        'menu-list__link_pin': !isConfirm,
    })

    return (
        <div className={_className} ref={sidebarRef}>
            <div>
                <div onClick={() => UI.closeSlideMenu()} className='menu-list__cross'>
                    <img src={image.Cross} alt='cross' />
                </div>
                {isAuth ? <BalanceBlock isSide /> : <AuthBlock />}
                {isAuth ? (
                    <>
                    {!isConfirm ? <Pin />: null}
                        <div className={linkClass} >
                            {menuAuthList.map(item => {
                                let titleClass = 'menu-list__title';

                                if (item.img === image.PresentRed) {
                                    const handleClick = () => {
                                        GoogleService.slideMenu(item.title, userId);
                                    };
                                    return (
                                        <React.Fragment key={item.path}>
                                            <div className="menu-list__hr"/>
                                            <div className="menu-list__category-wrapper">
                                                <NavLink
                                                    to={item.path}
                                                    className="menu-list__category"
                                                    onClick={handleClick}
                                                >
                                                    <img src={item.img} alt="icon"/>
                                                    <span className={titleClass}>{t(item.title)}</span>
                                                </NavLink>
                                            </div>
                                        </React.Fragment>
                                    );
                                }
                                if (item.img === image.AviatorIcon) {
                                    return (
                                        <div
                                            onClick={() => onClickAviatorGame()}
                                            className="menu-list__category"
                                            key={item.path}
                                        >
                                            <img
                                                src={item.img}
                                                className="menu-list__aviator"
                                                alt="icon"
                                            />
                                            <img src={item.title} alt="title"/>
                                        </div>
                                    );
                                } else {
                                    const handleClick = () => {
                                        GoogleService.slideMenu(item.title, userId);
                                        if (item.path === RouteConfig.casino.tournament) {
                                            GoogleService.tournament(userData?.id);
                                        }
                                        if (item.path === profileRouteList.account) {
                                            GoogleService.myProfile(userData?.id);
                                        }
                                        if (item.path === profileRouteList.gameHistory) {
                                            GoogleService.gameHistoryProfile(userData?.id);
                                        }
                                        if (item.path === routeBonus) {
                                            GoogleService.bonusProfile(userData?.id);
                                        }
                                        if (item.path === RouteConfig.profile.bonus) {
                                            GoogleService.openBonusPageView(
                                                userData?.id.toString() || '',
                                            );
                                        }
                                        if (item.path === routeBetting) {
                                            GoogleService.bettingOpen();
                                        }
                                    };
                                    return (
                                        <NavLink
                                            key={item.path}
                                            to={item.path}
                                            className="menu-list__category"
                                            onClick={handleClick}
                                        >
                                            <img src={item.img} alt="icon"/>
                                            <span className="menu-list__title">{t(item.title)}</span>
                                        </NavLink>
                                    );
                                }
                            })}
                        </div>
                    </>
                ) : (
                    <div className='menu-list__link'>
                        {menuList.map(item => {
                            const handleClick = () => {
                                GoogleService.slideMenu(item.title, userId);
                                if (item.path === RouteConfig.betting.root) {
                                    GoogleService.bettingOpen();
                                }
                            };
                            let titleClass = 'menu-list__title';
                            if (item.img === image.AviatorIcon) {
                                return (
                                    <div
                                        key={item.path}
                                        onClick={() => onClickAviatorGame()}
                                        className='menu-list__category'
                                    >
                                        <img
                                            src={item.img}
                                            className='menu-list__aviator'
                                            alt='icon'
                                        />
                                        <img src={item.title} alt='title' />
                                    </div>
                                );
                            } else {
                                if (item.title === 'sidebar.auth.account.sport') {
                                    return (
                                        <div key={item.path} className='menu-list__category-wrapper'>
                                            <NavLink
                                                to={item.path}
                                                className='menu-list__category'
                                                onClick={handleClick}
                                            >
                                                <img src={item.img} alt='icon' />
                                                <span className='menu-list__title'>
                                                    {t(item.title)}
                                                </span>
                                            </NavLink>
                                            <div className='menu-list__category-new'></div>
                                        </div>
                                    );
                                }
                                return (
                                    <NavLink
                                        key={item.path}
                                        to={item.path}
                                        className='menu-list__category'
                                        onClick={() => GoogleService.slideMenu(item.title, userId)}
                                    >
                                        <img src={item.img} alt='icon' />
                                        <span className={titleClass}>{t(item.title)}</span>
                                    </NavLink>
                                );
                            }
                        })}
                    </div>
                )}
            </div>
            <div className='menu-list__bottom'>
                {isAuth && (
                    <div className='menu-list__hr-wrapper'>
                        <div className='menu-list__hr' />
                    </div>
                )}
                <div className='menu-list__bottom-menu'>
                    <div>
                        {t('sidebar.social.link.telegram') ? (
                            <div>
                                <a
                                    href={t('sidebar.social.link.telegram')}
                                    className='menu-list__bottom-menu-social'
                                    onClick={() => {
                                        SocialLinkService.onClickSocial('telegram', userData);
                                    }}
                                >
                                    <img
                                        className='menu-list__bottom-menu-icon'
                                        src={image.Telegram}
                                        alt='tlgr'
                                    />
                                </a>
                                <a
                                    href={t('sidebar.social.link.instagram')}
                                    className='menu-list__bottom-menu-social'
                                    onClick={() => {
                                        SocialLinkService.onClickSocial('instagram', userData);
                                    }}
                                >
                                    <img src={image.Instagram} alt='igm' />
                                </a>
                            </div>
                        ) : null}
                    </div>
                    <div className='menu-list__bottom-language'>
                        <LanguageSelect
                            className='footer__language-select'
                            size='small'
                            showArrow
                            isOpenUp={true}
                        />
                    </div>
                </div>
                {isAuth && (
                    <div onClick={() => handleLogout()} className='menu-list__logout-wrapper'>
                        <img src={image.Logout} alt='logout' />
                        <div className='menu-list__logout-text'>
                            {' '}
                            {t('profile.navigation.exit')}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default List;
