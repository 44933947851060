import Validator from '../interface/Validator';

class IbanBankalfallah implements Validator {
    validate(value: string): boolean {
        return value.length === 24;
    }
}
const IbanBankalfallahService = new IbanBankalfallah();

export { IbanBankalfallahService };

export default IbanBankalfallah;
