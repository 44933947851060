import Validator from '../interface/Validator';

class PersonalName implements Validator {
    validate(value: string): boolean {
        return !this.spaceCheck(value) && this.letters(value);
    }

    letters(value: string): boolean {
        return new RegExp('^[A-Za-z- ]*$').test(value);
    }

    spaceCheck(value: string): boolean {
        return new RegExp('\\s').test(value[0]);
    }
}

const CheckPersonalNameService = new PersonalName();

export { CheckPersonalNameService };
export default PersonalName;
