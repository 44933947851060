import { BehaviorSubject } from 'rxjs';

import Storage from './Storage';

enum Themes {
    main,
    dark,
}

class Theme {
    public static STORAGE_KEY = 'theme';

    public theme: BehaviorSubject<Themes>;

    constructor() {
        let theme = Themes.main;
        try {
            theme = Storage.get(Theme.STORAGE_KEY) as Themes;
        } catch (e) {}

        this.theme = new BehaviorSubject(theme);
        this.theme.subscribe((value) => Storage.set(Theme.STORAGE_KEY, value));
    }

    toggle() {
        const theme = this.theme.getValue();
        this.setTheme(theme === Themes.main ? Themes.dark : Themes.main);
    }

    setTheme(theme: Themes) {
        this.theme.next(theme);
    }
}

const ThemeService = new Theme();

export { Themes };
export default ThemeService;
