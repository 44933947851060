import Validator from '../interface/Validator';

class CheckBirthDate implements Validator {
    validate(value: string): boolean {
        return value.length === 10;
    }
}

const CheckBirthDateService = new CheckBirthDate();

export { CheckBirthDateService };
export default CheckBirthDate;
