import { NavLink } from 'react-router-dom';
import { translate as t } from 'service/Language';

import RouteConfig from '../../../appConstants/RouteConfig';

import './Register.scss';

function Register() {
    return (
        <div className="register-hint">
            <span>{t('login.account')}</span>
            &nbsp;
            <NavLink to={{ hash: RouteConfig.hash.registration }} className="register-hint__registration">
                {t('login.registration')}
            </NavLink>
        </div>
    );
}

export default Register;
