import RouteConfig from 'appConstants/RouteConfig';
import classNames from 'classnames';
import Close from 'component/Close/Close';
import {ListType} from 'enums/payment';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import AuthService from 'service/Auth';
import GoogleService from 'service/Google';
import {translate as t} from 'service/Language';
import useObservable from 'utils/useObservable';

import PaymentList from '../../PaymentList/PaymentList';
import TabSwitchPopup from '../../PaymentTabs/PaymentTabs';
import AnindaHavaleWithdrawService from '../AnindaHavale/service/AnindaHavaleWithdraw';
import AzarpayWithdrawService from '../Azarpay/service/AzarpayWithdraw';
import Balance from '../Balance/Balance';
import CorefyWithdrawService from '../Corefy/service/CorefyWithdraw';
import EpulWithdrawService from '../Epul/service/EpulWithdraw';
import HavaleHeraWithdrawService from '../HavaleHera/service/HavaleHeraWithdraw';
import InstapayWithdrawService from '../Instapay/service/InstapayWithdraw';
import PaycosWithdrawService from '../Paycos/service/PaycosWithdraw';
import PaykassmaWithdrawService from '../Paykassma/service/PaykassmaWithdraw';
import WithdrawService from '../service/Withdrawal';
import Withdraw from '../Withdraw';
import back from './image/back.svg';
import Directa24DepositService from '../../Deposit/Directa24/Pix/service/Directa24Deposit';
import StatusFormPopupService, {StatusForm} from '../../Deposit/DepositPopup/service/StatusForm';
import PayfixWithdrawService from '../Payfix/service/PayfixWithdraw';
import CryptoCurrencyList from '../../СryptocurrencyList/CryptoCurrencyList';
import OperationsService, {MethodType} from '../../../../../service/Operations';

import './WithdrawalPopup.scss';


function WithdrawalPopup() {
    const auth = useObservable(AuthService.auth, AuthService.auth.getValue());
    const method = useObservable(WithdrawService.method, WithdrawService.method.getValue());
    const status = useObservable(StatusFormPopupService.status, StatusFormPopupService.status.getValue());
    const isSuccessDep = useObservable(OperationsService.isSuccessDep, OperationsService.isSuccessDep.getValue());
    const location = useLocation();

    useEffect(() => {
        const clientInfo = AuthService.auth.getValue();
        GoogleService.openWithdraw(clientInfo ? clientInfo.userId.toString() : '');
    }, []);

    useEffect(() => {
        OperationsService.requestHistory();
        OperationsService.getSuccessDep();
    }, [])

    useEffect(() => {
        if (location.hash !== `#${RouteConfig.hash.withdrawal}`) {
            WithdrawService.reset();
            EpulWithdrawService.reset();
            PaycosWithdrawService.reset();
            PaykassmaWithdrawService.reset();
            InstapayWithdrawService.reset();
            AnindaHavaleWithdrawService.reset();
            HavaleHeraWithdrawService.reset();
            CorefyWithdrawService.reset();
            AzarpayWithdrawService.reset();
            Directa24DepositService.reset();
            PayfixWithdrawService.reset();
            StatusFormPopupService.status.next(StatusForm.INIT);
            WithdrawService.method.next(null);
        }
        return () => {
            HavaleHeraWithdrawService.reset();
            CorefyWithdrawService.reset();
            AzarpayWithdrawService.reset();
            Directa24DepositService.reset();
            PayfixWithdrawService.reset();
            WithdrawService.method.next(null);
        };
    }, []);

    if (!auth) return null;

    const classNameTitle = classNames('payment-popup__title', {
        small: !method,
        'payment-popup__title_result': status === StatusForm.SUCCESS || status === StatusForm.FAILED,
    });

    return (
        <div className="payment-popup__wrapper">
            <div className="payment-popup">
                <div className="payment-popup__header">
                    {status !== StatusForm.SUCCESS && status !== StatusForm.FAILED && status !== StatusForm.BONUS_REJECT && status !== StatusForm.TIME_REJECT && isSuccessDep !== MethodType.NONE ? (
                        <button className="payment-popup__back" onClick={(e) => WithdrawService.back()}>
                            <img className="payment-popup__img" src={back} alt="back"/>
                        </button>
                    ) : null}
                    <div className={classNameTitle}>
                        {t(method ? 'profile.balance.withdraw.title' : 'profile.balance.popup.title')}
                    </div>
                    <Close size="s"/>
                </div>

                {method ? (
                    <Withdraw method={method}/>
                ) : (
                    <>
                        <div className="payment-popup__tab">
                            <TabSwitchPopup/>
                        </div>
                        <Balance/>
                        {isSuccessDep === MethodType.GENERAL ?
                            <>
                                <div className="payment-popup__hint">{t('profile.balance.withdraw.select')}</div>
                                <PaymentList className="payment-popup__list" type={ListType.WITHDRAW}/>
                            </> : null}
                        {isSuccessDep === MethodType.CRYPTO
                            ? <CryptoCurrencyList className="payment-popup__list" type={ListType.WITHDRAW}/>
                            : null}
                        {isSuccessDep === MethodType.NONE || isSuccessDep === MethodType.BOTH ?
                            <>
                                <div className="payment-popup__hint">{t('profile.balance.withdraw.select')}</div>
                                <PaymentList className="payment-popup__list" type={ListType.WITHDRAW}/>
                                {auth.currencyCode !== 104 ? <CryptoCurrencyList className="payment-popup__list" type={ListType.WITHDRAW}/>: null}
                            </> : null}
                    </>
                )}

            </div>
        </div>
    );
}

export default WithdrawalPopup;
