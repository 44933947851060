import RouteConfig from 'appConstants/RouteConfig';
import { NavLink, useLocation } from 'react-router-dom';
import { translate as t } from 'service/Language';

import './PaymentTabs.scss';

const PaymentTabs = () => {
    const location = useLocation();

    return (
        <div className="PaymentTabs">
            <NavLink
                className="PaymentTabs__btn"
                to={{ hash: RouteConfig.hash.deposit, search: location.search }}
                activeClassName="active"
                isActive={() => location.hash === `#${RouteConfig.hash.deposit}`}
            >
                {t('profile.balance.deposit.title')}
            </NavLink>
            <NavLink
                className="PaymentTabs__btn"
                to={{ hash: RouteConfig.hash.withdrawal, search: location.search }}
                activeClassName="active"
                isActive={() => location.hash === `#${RouteConfig.hash.withdrawal}`}
            >
                {t('profile.balance.withdraw.title')}
            </NavLink>
        </div>
    );
};

export default PaymentTabs;
