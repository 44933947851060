// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import AuthService, { AuthMethod } from 'service/Auth';
import ActivityDetectionService from 'service/FirstActivityDetect';
import Storage from 'service/Storage';
// Utils
// import { getLocalStorageItem, setLocalStorageItem } from 'utils/localStorage';
import useObservable from 'utils/useObservable';

import './AutologinIframe.scss';

const element = document.getElementById('autologin-iframe') as HTMLElement;

const AutologinIframe = () => {
    const iframeRef = useRef(null);
    const [isIframeLoaded, setIsIframeLoaded] = useState(false);

    const isAuth = useObservable(AuthService.auth, AuthService.auth.getValue());
    const isDetectedActivity = useObservable(
        ActivityDetectionService.isDeteсted,
        ActivityDetectionService.isDeteсted.getValue(),
    );
    const AuthLastMethod = AuthService.lastMethod;

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    // eslint-disable-next-line
    // @ts-ignore eslint-disable-next-line
    const getToken = async e => {
        if (e.origin !== 'https://iframe-old.ams3.digitaloceanspaces.com') {
            return;
        }
        console.log('getToken', JSON.parse(e.data));
        if (e.data && !isAuth) {
            try {
                const accessToken = JSON.parse(e.data).accessToken;
                const refreshToken = JSON.parse(e.data).refreshToken;
                if (accessToken && refreshToken) {
                    Storage.set(AuthService.STORAGE_KEY_ACCESS_TOKEN, accessToken);
                    Storage.set(AuthService.STORAGE_KEY_REFRESH_TOKEN, refreshToken);
                    await AuthService.loginByToken(accessToken, refreshToken);
                }
            } catch (error) {
                console.log('error', error);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('message', getToken, false);

        return () => {
            window.removeEventListener('message', getToken);
        };
    }, []);

    // Нужно разбить useEffct на 2 и один сделать зависимым от isDetectedActivity и safari

    useEffect(() => {
        // eslint-disable-next-line
        // @ts-ignore eslint-disable-next-line
        const iframe = iframeRef.current.contentWindow;
        if (iframe && isIframeLoaded && !isSafari) {
            console.log('isAuth', isAuth, AuthLastMethod);
            if (AuthLastMethod === AuthMethod.LOGIN) {
                try {
                    const accessToken = Storage.get(AuthService.STORAGE_KEY_ACCESS_TOKEN);
                    const refreshToken = Storage.get(AuthService.STORAGE_KEY_REFRESH_TOKEN);
                    const message = {
                        key: AuthService.STORAGE_KEY_ACCESS_TOKEN,
                        data: {
                            accessToken,
                            refreshToken,
                        },
                        type: 'set',
                    };
                    iframe.postMessage(
                        JSON.stringify(message),
                        'https://iframe-old.ams3.digitaloceanspaces.com',
                    );
                } catch (error) {}
            }
            if (AuthLastMethod === AuthMethod.LOGOUT) {
                const accessMessage = {
                    key: AuthService.STORAGE_KEY_ACCESS_TOKEN,
                    data: '1',
                    type: 'remove',
                };
                const refreshMessage = {
                    key: AuthService.STORAGE_KEY_REFRESH_TOKEN,
                    data: '1',
                    type: 'remove',
                };
                setTimeout(() => {
                    iframe.postMessage(
                        JSON.stringify(accessMessage),
                        'https://iframe-old.ams3.digitaloceanspaces.com',
                    );
                    iframe.postMessage(
                        JSON.stringify(refreshMessage),
                        'https://iframe-old.ams3.digitaloceanspaces.com',
                    );
                }, 100);
            }
            if (isAuth === null && AuthLastMethod !== AuthMethod.LOGOUT) {
                console.log('GET');
                const message = {
                    key: '1',
                    data: '1',
                    type: 'get',
                };
                setTimeout(() => {
                    iframe.postMessage(JSON.stringify(message), '*');
                }, 100);
            }
        }
    }, [isAuth, isIframeLoaded]);

    useEffect(() => {
        // eslint-disable-next-line
        // @ts-ignore eslint-disable-next-line
        const iframe = iframeRef.current.contentWindow;
        if (iframe && isIframeLoaded && isSafari && isDetectedActivity) {
            console.log('isAuth', isAuth, AuthLastMethod);
            if (AuthLastMethod === AuthMethod.LOGIN) {
                try {
                    const accessToken = Storage.get(AuthService.STORAGE_KEY_ACCESS_TOKEN);
                    const refreshToken = Storage.get(AuthService.STORAGE_KEY_REFRESH_TOKEN);
                    const message = {
                        key: AuthService.STORAGE_KEY_ACCESS_TOKEN,
                        data: {
                            accessToken,
                            refreshToken,
                        },
                        type: 'set',
                    };
                    iframe.postMessage(
                        JSON.stringify(message),
                        'https://iframe-old.ams3.digitaloceanspaces.com',
                    );
                } catch (error) {}
            }
            if (AuthLastMethod === AuthMethod.LOGOUT) {
                const accessMessage = {
                    key: AuthService.STORAGE_KEY_ACCESS_TOKEN,
                    data: '1',
                    type: 'remove',
                };
                const refreshMessage = {
                    key: AuthService.STORAGE_KEY_REFRESH_TOKEN,
                    data: '1',
                    type: 'remove',
                };
                setTimeout(() => {
                    iframe.postMessage(
                        JSON.stringify(accessMessage),
                        'https://iframe-old.ams3.digitaloceanspaces.com',
                    );
                    iframe.postMessage(
                        JSON.stringify(refreshMessage),
                        'https://iframe-old.ams3.digitaloceanspaces.com',
                    );
                }, 100);
            }
            if (isAuth === null && AuthLastMethod !== AuthMethod.LOGOUT) {
                console.log('GET');
                const message = {
                    key: '1',
                    data: '1',
                    type: 'get',
                };
                setTimeout(() => {
                    iframe.postMessage(JSON.stringify(message), '*');
                }, 100);
            }
        }
    }, [isAuth, isIframeLoaded, isDetectedActivity]);

    return ReactDOM.createPortal(
        <iframe
            title='autologin'
            src='https://iframe-old.ams3.digitaloceanspaces.com/index.html'
            style={{ width: '1px', height: '1px', opacity: 0 }}
            ref={iframeRef}
            onLoad={() => setIsIframeLoaded(true)}
        />,
        element,
    );
};

export default AutologinIframe;
