import useObservable from 'utils/useObservable';

import EpulWithdrawService, { EpulWithdrawState } from '../service/EpulWithdraw';
import FailedPage from './ResultPage/FailedPage';
import ProgressPage from './ResultPage/ProgressPage';
import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';

import './ResultForm.scss';

function ResultForm() {
    const state = useObservable(EpulWithdrawService.state, EpulWithdrawService.state.getValue());

    switch (state) {
        case EpulWithdrawState.AWAIT: {
            return <ProgressPage />;
        }
        case EpulWithdrawState.ACCEPT: {
            return <SuccessPage />;
        }
        case EpulWithdrawState.REJECT: {
            return <FailedPage />;
        }
        default: {
            return null;
        }
    }
}

export default ResultForm;
