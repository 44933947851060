import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import RouteConfig from '../../../appConstants/RouteConfig';
import CategoryHeader from '../../../component/CategoryHeader';
import AuthService from '../../../service/Auth';
import GamesService, { GameFilter, Games } from '../../../service/Games';
import GoogleService from '../../../service/Google';
import GameProvider from '../../../service/model/GameProvider';
import useObservable from '../../../utils/useObservable';
import GameList from '../GameList/GameList';
import CountryBlocked from './CountryBlocked/CountryBlocked';
import CurrencyBlocked from './CurrencyBlocked/CurrencyBlocked';

import './Provider.scss';

type ProviderParams = {
    provider: string;
};

function Provider() {
    const games = useObservable(GamesService.games, GamesService.games.getValue());
    const providers = useObservable(GamesService.allowedProviders, GamesService.cache.allowedProvider);
    const routeMap = new Map<string, GameProvider>(providers.map((provider) => [provider.route, provider]));
    const params = useParams<ProviderParams>();
    const isAuth = useObservable(AuthService.auth, AuthService.auth.getValue());

    const provider = routeMap.get(params.provider) || null;

    const blockedProvider = isAuth ? <CurrencyBlocked /> : <CountryBlocked />;

    const filter: GameFilter = {
        provider,
        category: null,
    };
    const filteredGames = Games.applyFilter([games, filter]);

    return provider ? (
        <div>
            <CategoryHeader title={provider.name} backUrl={RouteConfig.casino.providers} />
            <GameList games={filteredGames} />
        </div>
    ) : (
        blockedProvider
    );
}

export default Provider;
