import './DepositFrame.scss';

type DepositFrameProps = {
    link: string;
};

function DepositFrame({ link }: DepositFrameProps) {
    return <iframe src={link} className='deposit-frame' />;
}

export default DepositFrame;
