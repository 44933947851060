import { PaymentMethod } from '../../../PaymentMethod/types';
import AzarpayBank from '../Bank/Form';
import AzarpayVisa from '../Visa/Form';
import Balance from '../../../Withdrawal/Balance/Balance';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';

import './Form.scss';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    if (paymentMethod.id === ChannelType.AZN_AZARPAY || paymentMethod.id === ChannelType.VALERPAY_DEPOSIT || paymentMethod.id === ChannelType.VALERPAY_WITHDRAWAL) {
        return <>
            {paymentMethod.id === ChannelType.VALERPAY_WITHDRAWAL ? <Balance/> : null}
            <AzarpayBank paymentMethod={paymentMethod}/>
        </>;
    }
    if (paymentMethod.id === ChannelType.AZARPAY_VISA) {
        return <AzarpayVisa paymentMethod={paymentMethod}/>;
    } else return null;
}

export default Form;
