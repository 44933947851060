/* eslint-disable max-len */

import { Icon } from 'types';

function TimeIcon24({ color = '#3959D9', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                d="M19.0563 8.81274L20.5826 7.04086C20.7308 6.86892 20.7078 6.61242 20.5313 6.46853L19.3004 5.46247C19.1239 5.31816 18.8605 5.34011 18.7127 5.51247L17.292 7.162C16.1179 6.32585 14.7313 5.76286 13.2246 5.56124V4.09911H15.0394C15.2426 4.09911 15.4079 3.93814 15.4079 3.74018V2.35893C15.4079 2.16097 15.2426 2 15.0394 2H8.96021C8.75653 2 8.59166 2.16097 8.59166 2.35893V3.74018C8.59166 3.93814 8.75653 4.09911 8.96021 4.09911H10.775V5.56124C9.26824 5.76246 7.88169 6.32585 6.70802 7.16159L5.28683 5.51247C5.13866 5.34011 4.87571 5.31776 4.69874 5.46247L3.4683 6.46812C3.29133 6.61202 3.26838 6.86851 3.41655 7.04046L4.94291 8.81193C3.73042 10.3058 3 12.1874 3 14.2349C3 19.068 7.03733 23 12 23C16.9627 23 21 19.068 21 14.2349C20.9996 12.1874 20.2692 10.3062 19.0563 8.81274ZM11.9996 21.5773C7.84288 21.5773 4.46042 18.2835 4.46042 14.2349C4.46042 10.1867 7.84288 6.89249 11.9996 6.89249C16.1567 6.89249 19.5387 10.1867 19.5387 14.2349C19.5387 18.2839 16.1567 21.5773 11.9996 21.5773Z"
                fill={color}
            />
            <path
                d="M11.5654 18H6.72656V17.1309L8.56738 15.2803C9.111 14.7236 9.4707 14.3298 9.64648 14.0986C9.82552 13.8643 9.95573 13.6445 10.0371 13.4395C10.1185 13.2344 10.1592 13.0146 10.1592 12.7803C10.1592 12.458 10.0615 12.2041 9.86621 12.0186C9.67415 11.833 9.40723 11.7402 9.06543 11.7402C8.79199 11.7402 8.52669 11.7907 8.26953 11.8916C8.01562 11.9925 7.72103 12.1748 7.38574 12.4385L6.76562 11.6816C7.16276 11.3464 7.5485 11.1087 7.92285 10.9688C8.2972 10.8288 8.69596 10.7588 9.11914 10.7588C9.7832 10.7588 10.3154 10.9329 10.7158 11.2812C11.1162 11.6263 11.3164 12.0918 11.3164 12.6777C11.3164 13 11.2578 13.306 11.1406 13.5957C11.0267 13.8854 10.8493 14.1849 10.6084 14.4941C10.3708 14.8001 9.97363 15.2152 9.41699 15.7393L8.17676 16.9404V16.9893H11.5654V18ZM17.5322 16.4424H16.5703V18H15.4521V16.4424H12.1904V15.5586L15.4521 10.8418H16.5703V15.4902H17.5322V16.4424ZM15.4521 15.4902V13.6982C15.4521 13.0602 15.4684 12.5378 15.501 12.1309H15.4619C15.3708 12.3457 15.2275 12.6061 15.0322 12.9121L13.2598 15.4902H15.4521Z"
                fill={color}
            />
        </svg>
    );
}

export default TimeIcon24;
