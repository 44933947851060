import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';

import AuthService from '../../../../../../service/Auth';
import GoogleService from '../../../../../../service/Google';
import Validator from '../../../../../../service/Validator/Validator';
import { PaymentMethod } from '../../../PaymentMethod/types';
import MpayDepositService from './MpayDeposit';
import {OnlyLetterService} from '../../../../../../service/Validator/OnlyLetter';
import {CheckTurkeyLettersService} from '../../../../../../service/Validator/CheckTurkeyLetters';
import {HavaleHeraErrorTip, HavaleHeraInputTip} from '../../HavaleHera/service/AmountForm';

export enum MpayInputTip {
    NONE,
    AMOUNT,
    NAME,
    SURNAME,
}

export enum AmountError {
    NONE,
    AMOUNT_MIN,
    AMOUNT_MAX,
    AMOUNT_WITHDRAWAL,
    AMOUNT_FEE,
}

export enum MpayErrorTip {
    NONE,
    NAME,
    SURNAME,
}

class AmountForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(1, 1000000);

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        onName: this.onName.bind(this),
        onNameActive: this.onNameActive.bind(this),
        onSurname: this.onSurname.bind(this),
        onSurnameActive: this.onSurnameActive.bind(this),
        valid: this.checkValid.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
        this.name.value.subscribe(this.handler.onName);
        this.name.active.subscribe(this.handler.onNameActive);
        this.surname.value.subscribe(this.handler.onSurname);
        this.surname.active.subscribe(this.handler.onSurnameActive);
    }

    amount = new InputService({
        title: 'profile.balance.deposit.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.deposit.input.amount'
    });
    name = new InputService({
        title: 'popup.payment.pix.name',
        validator: Validator.OnlyLetter,
        validatorText: 'popup.payment.pix.name',
    });

    surname = new InputService({
        title: 'popup.payment.pix.surname',
        validator: Validator.OnlyLetter,
        validatorText: 'popup.payment.pix.surname',
    });

    tip = new BehaviorSubject<MpayInputTip>(MpayInputTip.NONE);

    errorLetters = new BehaviorSubject<MpayErrorTip>(MpayErrorTip.NONE);

    amountLimit = new BehaviorSubject<AmountError>(AmountError.NONE);

    valid = new BehaviorSubject<boolean>(false);

    setLimit(min: number, max: number) {
        if (this.limitValidator) {
            this.limitValidator.setLimit(min, max);
        }
    }

    onAmount(value: string) {
        if (this.paymentMethod && this.amount.getValue() && !this.limitValidator.validate(value)) {
            this.valid.next(false);
            if(+value < this.paymentMethod.limit.deposit.min) {
                this.amountLimit.next(AmountError.AMOUNT_MIN);
            } else {
                this.amountLimit.next(AmountError.NONE);
            }
            if(+value > this.paymentMethod.limit.deposit.max) {
                this.amountLimit.next(AmountError.AMOUNT_MAX);
            }
        } else {
            this.amount.error.next(null);
        }
        this.checkValid();
    }
    onAmountActive(active: boolean) {
        if (active) {
            this.tip.next(MpayInputTip.AMOUNT);
        } else {
            this.tip.next(MpayInputTip.NONE);
        }
    }


    onName(value: string) {
        if (this.name.getValue() &&
            !OnlyLetterService.validate(value)
        ) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.name.value.next(name);
            if (CheckTurkeyLettersService.validate(value)) {
                this.errorLetters.next(MpayErrorTip.NAME)
            } else {
                this.errorLetters.next(MpayErrorTip.NONE)
            }

        } else {
            this.name.error.next(null);
            this.errorLetters.next(MpayErrorTip.NONE)
            this.checkValid();
        }
    }

    onSurname(value: string) {
        if (this.surname.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const surname = value.slice(0, current - 1);
            this.surname.value.next(surname);
            if (CheckTurkeyLettersService.validate(value)) {
                this.errorLetters.next(MpayErrorTip.SURNAME)
            } else {
                this.errorLetters.next(MpayErrorTip.NONE)
            }
        } else {
            this.surname.error.next(null);
            this.errorLetters.next(MpayErrorTip.NONE)
            this.checkValid();
        }
    }

    onNameActive(active: boolean) {
        active ? this.tip.next(MpayInputTip.NAME)
            : this.tip.next(MpayInputTip.NONE)
    }

    onSurnameActive(active: boolean) {
        active ? this.tip.next(MpayInputTip.SURNAME)
            : this.tip.next(MpayInputTip.NONE)
    }

    setMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        this.setLimit(paymentMethod.limit.deposit.min, paymentMethod.limit.deposit.max);
    }

    setValue(value: number) {
        this.amount.onFocus();
        this.amount.value.next(value.toString());
    }

    checkValid() {
        const isValid = this.amount.isValid() && this.name.isValid() && this.surname.isValid()
        this.valid.next(isValid);
    }

    reset() {
        this.amount.value.next('');
        this.amount.error.next(null);
        this.amount.active.next(false);
        this.name.value.next('');
        this.surname.value.next('');

        this.amount.error.next(null);
        this.name.error.next(null);
        this.surname.error.next(null);

        this.amount.active.next(false);
        this.name.active.next(false);
        this.surname.active.next(false);
        this.valid.next(false);

        this.tip.next(MpayInputTip.NONE);
        this.errorLetters.next(MpayErrorTip.NONE);
        this.amountLimit.next(AmountError.NONE)
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();
        const valid = this.amount.isValid();

        if (valid && this.paymentMethod) {
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            const amount = parseFloat(this.amount.getValue());
            const name = this.name.getValue();
            const surname = this.surname.getValue();

            GoogleService.sentDepositAmount(
                userId,
                this.paymentMethod.title,
                amount.toString(),
                this.paymentMethod.currency.name
            );
            MpayDepositService.request(this.paymentMethod, amount, name, surname);
        } else {

        }
    }
}

const AmountFormService = new AmountForm();

export default AmountFormService;
