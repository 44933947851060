import AviatorIcon from './aviatorIcon.svg';
import AviatorText from './aviatorText.svg';
import betting from './betting.svg';
import crazytime from './crazytime.svg';
import live from './live-casino.svg';
import tv from './tvgames.svg';
import vSport from './vSport.svg';
import Glory from './glory.svg';

export default {
    AviatorIcon,
    AviatorText,
    live,
    betting,
    tv,
    vSport,
    crazytime,
    Glory,
};
