import { Link } from 'react-router-dom';
import { translate as t } from 'service/Language';
import SvgImage from 'svg';
import useObservable from 'utils/useObservable';

import colors from '../../../../../appConstants/colors';
import RegistrationFormService from '../service/registration';

import './PromotionCheckbox.scss';

type PromotionCheckboxType = {
    type?: string;
};

function PromotionCheckbox({ type }: PromotionCheckboxType) {
    const acceptCheckboxStatus = useObservable<boolean>(
        RegistrationFormService.promotionAgreement,
        RegistrationFormService.promotionAgreement.value,
    );
    if (type !== 'criket') return null;

    return (
        <div className='registration-promotion-checkbox'>
            <button
                onClick={() => RegistrationFormService.togglePromotions()}
                className='registration-promotion-checkbox__check'
                type='button'
            >
                {acceptCheckboxStatus && (
                    <SvgImage width={9} height={9} color={colors.royalBlue} name='Check' />
                )}
            </button>
            <div>{t('registration.accept.checkbox.offers')}</div>
        </div>
    );
}

export default PromotionCheckbox;
