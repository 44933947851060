import RouteConfig from 'appConstants/RouteConfig';
import { HistoryService } from 'service/HistoryService';
import { translate as t } from 'service/Language';

import PopupService from '../../../../../../service/Popup';
import DepositService from '../../service/Deposit';
import Clock from './images/Clock.svg';

import './TelegramSuccessPage.scss';

function TelegramSuccessPage() {
    function onClickHandler() {
        DepositService.reset();
        PopupService.close();
        HistoryService.history.push(RouteConfig.casino.root);
    }
    return (
        <div className="payment-popup">
            <div className="transaction-telegram-success">
                <div className="transaction-telegram-success__container">
                    <img src={Clock} alt="Clock" />
                    <span className="transaction-telegram-success__title">
                        {t('profile.balance.deposit.transaction.success.paycos')}
                    </span>
                    <span className="transaction-telegram-success__subtitle">
                        {t('profile.balance.deposit.transaction.success.subtitle.paycos')}
                    </span>
                    <button className="transaction-telegram-success__btn" onClick={onClickHandler}>
                        {t('profile.balance.deposit.transaction.success.paycos.button')}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TelegramSuccessPage;
