import { translate as t } from 'service/Language';

import './Rule.scss';

type ListType = {
    id: number;
    title: string;
    description: string;
};

const textList: ListType[] = [
    { id: 1, title: 'tc.about.title', description: 'tc.about.description' },
    { id: 2, title: 'tc.provisions.title', description: 'tc.provisions.description' },
    { id: 3, title: 'tc.definitions.title', description: 'tc.definitions.description' },
    { id: 4, title: 'tc.account.title', description: 'tc.account.description' },
    { id: 5, title: 'tc.juvenile.title', description: 'tc.juvenile.description' },
    { id: 6, title: 'tc.client.title', description: 'tc.client.description' },
    { id: 7, title: 'tc.registration.title', description: 'tc.registration.description' },
    { id: 8, title: 'tc.winnings.title', description: 'tc.winnings.description' },
    { id: 9, title: 'tc.legal.title', description: 'tc.legal.description' },
    { id: 10, title: 'tc.rules.title', description: 'tc.rules.description' },
    { id: 11, title: 'tc.password.title', description: 'tc.password.description' },
    { id: 12, title: 'tc.promotions.title', description: 'tc.promotions.description' },
    { id: 12, title: 'tc.bonus.deposite.title', description: 'tc.bonus.deposite.description' },
    { id: 13, title: 'tc.bonus.title', description: 'tc.bonus.description' },
    { id: 14, title: 'tc.games.title', description: 'tc.games.description' },
    { id: 15, title: 'tc.bets.title', description: 'tc.bets.description' },
    { id: 16, title: 'tc.type.title', description: 'tc.type.description' },
    { id: 17, title: 'tc.restriction.title', description: 'tc.restriction.description' },
    { id: 18, title: 'tc.funds.title', description: 'tc.funds.description' },
];

function Rule() {
    return (
        <div className='rule'>
            <p className='rule__header'>{t('tc.header')}</p>
            <span className='rule__description'>
                <noindex>{t('tc.description')}</noindex>
            </span>
            <br />
            {textList.map(item => (
                <>
                    <p className='rule__title'>{t(item.title)}</p>
                    <span className='rule__description'>
                        <noindex>{t(item.description)}</noindex>
                    </span>
                </>
            ))}
        </div>
    );
}

export default Rule;
