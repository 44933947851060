import CountrySearch from 'component/CountrySearch/CountrySearch';
import useObservable from 'utils/useObservable';
import CountrySupportService from 'service/CountrySupport';

import './styles.scss';
import { CountryType } from 'service/api/Counties';

type CountrySelectListProps = {
    onSelect?: () => void;
};

const CountrySelectList = (props: CountrySelectListProps) => {
    const { onSelect } = props;
    const countries = useObservable(
        CountrySupportService.filteredCountries,
        CountrySupportService.filteredCountries.getValue(),
    );

    const handleClick = (country: CountryType) => {
        console.log('onSelect', onSelect);
        CountrySupportService.setCountryCode(country.code);
        if (onSelect) {
            onSelect();
        }
    };

    return (
        <div className='CountrySelect'>
            <div className='CountrySelect__search'>
                <CountrySearch />
            </div>
            <div className='CountrySelect__list'>
                {countries &&
                    !!countries.length &&
                    countries.map((country, index) => (
                        <div
                            key={index + country.code}
                            className='CountrySelect__country'
                            onClick={() => handleClick(country)}
                        >
                            <div className='CountrySelect__flag'>
                                <img
                                    src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/7.1.0/flags/1x1/${country.code.toLowerCase()}.svg`}
                                    alt={country.name}
                                />
                            </div>
                            <div className='CountrySelect__name'>{country.name}</div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default CountrySelectList;
