import { translate as t } from 'service/Language';

import './CountryBlocked.scss';

function CountryBlocked() {
    return (
        <div className="provider-blocked-country">
            <div className="provider-blocked-country__globe" />
            <div className="provider-blocked-country__text">{t('casino.provider.blocked.country')}</div>
        </div>
    );
}

export default CountryBlocked;
