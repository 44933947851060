import { BehaviorSubject } from 'rxjs';
import PaymentService from 'service/Payment';
import CardFormService from './CardForm';
import StatusFormPopupService, { StatusForm } from '../../../Deposit/DepositPopup/service/StatusForm';

enum HavaleHeraWithdrawState {
    CARD,
    AWAIT,
    HAS_FEE,
    ACCEPT,
    REJECT,
    BONUS_REJECT,
}

class HavaleHeraWithdraw {
    state = new BehaviorSubject<HavaleHeraWithdrawState>(HavaleHeraWithdrawState.CARD);

    handler = {
        onWithdraw: this.onWithdraw.bind(this),
        onError: this.onError.bind(this),
    };

    constructor() {
        PaymentService.havaleHera.withdrawResult.subscribe(this.handler.onWithdraw);
        PaymentService.havaleHera.error.subscribe(this.handler.onError);
    }

    onWithdraw() {
        this.state.next(HavaleHeraWithdrawState.ACCEPT);
        StatusFormPopupService.status.next(StatusForm.SUCCESS);
    }

    onError() {
        this.state.next(HavaleHeraWithdrawState.REJECT);
        StatusFormPopupService.status.next(StatusForm.FAILED);
    }

    reset() {
        this.state.next(HavaleHeraWithdrawState.CARD);
        StatusFormPopupService.status.next(StatusForm.INIT);
        CardFormService.reset();
    }
}

const HavaleHeraWithdrawService = new HavaleHeraWithdraw();

export { HavaleHeraWithdrawState };
export default HavaleHeraWithdrawService;
