import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { combineLatestWith, map } from 'rxjs';
import PaycosWithdraw from 'service/model/payment/PaycosWithdraw';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';

import { PaymentMethod } from '../../../PaymentMethod/types';
import WithdrawService from '../../service/Withdrawal';
import Failed from '../ResultForm/ResultPage/images/Failed.svg';
import Success from '../ResultForm/ResultPage/images/Success.svg';
import PaycosWithdrawService, { PaycosWithdrawState } from './PaycosWithdraw';
import BonusService from '../../../../../../service/Bonus';
import AzarpayWithdrawService, {AzarpayWithdrawState} from '../../Azarpay/service/AzarpayWithdraw';
import StatusFormPopupService, {StatusForm} from '../../../Deposit/DepositPopup/service/StatusForm';

class CardForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(0, 1);

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.withdraw.input.error',
    });

    card = new InputService({
        title: 'profile.balance.withdraw.input.card',
        validator: Validator.cardNumber,
    });

    success = {
        title: 'profile.balance.withdraw.successed.title',
        img: Success,
    };

    failed = {
        title: 'profile.balance.withdraw.failed.title',
        img: Failed,
    };

    valid = this.amount.valid.pipe(combineLatestWith([this.card.valid])).pipe(map((values) => values.includes(false)));

    error = PaymentService.paykassma.error;

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        const limit = this.paymentMethod.limit.withdraw;
        const isFee = WithdrawService.fee.getValue();
        const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
        this.limitValidator.setLimit(isFee ? isMin : limit.min, limit.max);
    }

    send() {
        const assert = [this.amount.isValid(), this.card.isValid()];

        const valid = !assert.includes(false);

        if (valid && this.paymentMethod) {
            const amount = parseInt(this.amount.getValue());
            const request = new PaycosWithdraw(amount, this.paymentMethod, this.card.getValue());
            PaymentService.paycos.withdraw(request);
            PaycosWithdrawService.state.next(PaycosWithdrawState.AWAIT);
            this.reset();
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const status = PaycosWithdrawService.state.getValue();
        const fee = WithdrawService.fee.getValue();
        const isBonuses = BonusService.list.getValue().length;

        if (isBonuses) {
            PaycosWithdrawService.state.next(PaycosWithdrawState.BONUS_REJECT);
            StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
        } else {
            if (!!fee) {
                if (status === PaycosWithdrawState.HAS_FEE) {
                    this.send();
                }
            } else {
                this.send();
            }
        }
    }

    reset() {
        this.card.value.next('');
        this.amount.value.next('');
        this.amount.error.next(null);
    }
}

const CardFormService = new CardForm();

export default CardFormService;
