import useObservable from 'utils/useObservable';

import { PaymentMethod } from '../../../PaymentMethod/types';
import Balance from '../../Balance/Balance';
import ResultForm from '../ResultForm/ResultForm';
import EpulWithdrawService, { EpulWithdrawState } from '../service/EpulWithdraw';
import Card from './Card/Card';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(EpulWithdrawService.state, EpulWithdrawService.state.getValue());

    if (paymentMethod) {
        if (state === EpulWithdrawState.CARD || state === EpulWithdrawState.HAS_FEE) {
            return (
                <>
                    <Balance />
                    <Card paymentMethod={paymentMethod} />
                </>
            );
        } else {
            return <ResultForm />;
        }
    }

    return null;
}

export default Form;
