import Tournament from './api/Tournament';
import Payments from './api/Payments';
import { HOST } from 'appConstants/image';

const { REACT_APP_API_URL } = process.env;

class API {
    tournament = new Tournament(REACT_APP_API_URL || '//localhost:3000');
    payment = new Payments(HOST);
}

const GloryAPI = new API();

export default GloryAPI;
