import { translate as t } from 'service/Language';

// import WithdrawService from '../../../service/Withdraw';
import Failed from './images/Failed.svg';

import './ResultPage.scss';

function FailedPage() {
    return (
        <div className="result">
            <div className="result__container">
                <p className="result__title__error ">{t('profile.balance.withdraw.failed.title')}</p>
                <img className="result__img" src={Failed} />
                {/* <button className="result__btn" onClick={() => WithdrawService.reset()}>
                    {t('profile.balance.withdraw.failed.back')}
                </button> */}
            </div>
        </div>
    );
}

export default FailedPage;
