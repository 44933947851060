import useObservable from 'utils/useObservable';

import PaykassmaWithdrawService, { PaykassmaWithdrawState } from '../service/PaykassmaWithdraw';
import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import FailedPage from './ResultPage/FailedPage';
import BonusFailedPage from '../../../../../../component/BonusFailedPage/BonusFailedPage';

import './ResultForm.scss';


function ResultForm() {
    const state = useObservable(PaykassmaWithdrawService.state, PaykassmaWithdrawService.state.getValue());

    switch (state) {
        case PaykassmaWithdrawState.ACCEPT: {
            return <SuccessPage />;
        }
        case PaykassmaWithdrawState.REJECT: {
            return <FailedPage />;
        }
        case PaykassmaWithdrawState.BONUS_REJECT: {
            return <BonusFailedPage />;
        }
        default: {
            return null;
        }
    }
}

export default ResultForm;
