/* eslint-disable max-len */

import { Icon } from 'types';

function MiniSwitchArrowLeft({ color = '#3959D9', width = 7, height = 8 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 7 8" fill="none">
            <path
                d="M0.500001 3.13397C-0.166666 3.51887 -0.166667 4.48113 0.5 4.86603L5 7.4641C5.66667 7.849 6.5 7.36788 6.5 6.59808L6.5 1.40192C6.5 0.632124 5.66667 0.150998 5 0.535898L0.500001 3.13397Z"
                fill={color}
            />
        </svg>
    );
}

export default MiniSwitchArrowLeft;
