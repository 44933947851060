import classNames from 'classnames';
import AppService from 'service/App';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';

import arrowTop from './img/arrowTop.svg';
import ScrollService from './service/Scroll';

import './ScrollTopButton.scss';

function ScrollTopButton() {
    const scrolled = useObservable(AppService.scrolled, false);

    const className = classNames({
        'scroll-top-button': true,
        'scroll-top-button____active': scrolled,
    });

    return (
        <button
            className={className}
            type="button"
            title="Go Top"
            onClick={() => ScrollService.scrollToSmoothly(0, 500)}
        >
            <img src={arrowTop} alt="arrow" />
            <span className="scroll-top-button__title">{t('app.scroll.top.button')}</span>
        </button>
    );
}

export default ScrollTopButton;
