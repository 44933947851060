import { SlotegratorGameMode } from '@ay_tsarbet/newbet-core/dist/connection/actions/actionEnums';
import classNames from 'classnames';
import LoadingSpinner from 'component/LoadingSpinner';
import { translate as t } from 'service/Language';
import ImageLoader from '../../../../component/ImageLoader/ImageLoader';
import RxComponent from '../../../../component/RxComponent/RxComponent';
import FavoriteGameService from '../../../../service/FavoriteGame';
import GameHelper from '../../../../service/GameHelper';
import Game, { GameList } from '../../../../service/model/Game';
import playButton from './images/play.svg';
import Star from './Star';
import Tournament from '../../../../service/model/Tournament';

import './GameCard.scss';

type GameCardProps = {
    game: Game;
    hover?: boolean;
    tournament: Tournament | null;
};

type GameCardState = {
    loading: boolean;
    favorite: boolean;
};

class GameCard extends RxComponent<GameCardProps, GameCardState> {
    handler = {
        checkFavorite: this.checkFavorite.bind(this),
        checkLoading: this.checkLoading.bind(this),
    };

    state = {
        loading: false,
        favorite: false,
    };

    componentDidMount() {
        this.subscribe(FavoriteGameService.list, { next: this.handler.checkFavorite });
    }

    checkFavorite(games: GameList) {
        const { game } = this.props;
        const favorite = games.includes(game);

        this.setState({ favorite });
    }

    checkLoading(loadingGame: Game) {
        const { game } = this.props;
        const loading = loadingGame === game;

        this.setState({ loading });
    }

    render() {
        const { game, hover, tournament } = this.props;
        const { favorite, loading } = this.state;

        const className = classNames({
            'game-card': true,
            'game-card____loading': loading,
        });
        const classHover = classNames({
            'game-card__container': !hover,
        });

        if (!game) return null;

        return (
            <div className={className}>
                <div className={classHover}>
                    <div className="game-card__image-box">
                        <ImageLoader className="game-card__image" src={game.image} />
                    </div>
                    <div className="game-card__text">
                        <div className="game-card__info-title">{game.name}</div>
                        <div className="game-card__info-description">{game.provider.name}</div>
                    </div>
                    <div className="game-card__loading">
                        <LoadingSpinner />
                    </div>
                    <div className="game-card__overlay">
                        <button className="game-card__play" onClick={() => GameHelper.loadGame(game, SlotegratorGameMode.REAL, tournament)}>
                            <img src={playButton} alt="playButton" />
                        </button>

                        {!hover && game.provider.isDemo ? (
                            <button
                                className="game-card__demo"
                                onClick={() => GameHelper.loadGame(game, SlotegratorGameMode.DEMO, tournament)}
                            >
                                {t('casino.lobby.demo')}
                            </button>
                        ) : null}
                    </div>
                    <button
                        className="game-card__star-toggle"
                        onClick={() =>
                            favorite
                                ? FavoriteGameService.removeFavoriteGame(game)
                                : FavoriteGameService.addFavoriteGame(game)
                        }
                    >
                        <Star favorite={favorite} />
                    </button>
                </div>
            </div>
        );
    }
}

export default GameCard;
