import { useLocation } from 'react-router-dom';

import CloseIcon from './images/close.svg';
import Prizes from './images/prizes.webp';

import { HistoryService } from '../../service/HistoryService';
import { translate as t } from 'service/Language';

import useObservable from '../../utils/useObservable';
import UserDataService from '../../service/UserData';
import GoogleService from '../../service/Google';
import BalanceService from '../../service/Balance';

import './NewYearSuccess.scss';


function NewYearSuccess() {
    const userData = useObservable(UserDataService.data, UserDataService.data.getValue());
    const balance = useObservable(BalanceService.balance, BalanceService.balance.getValue()).toString();
    const location = useLocation();
    const userId = userData ? userData.id.toString() : '';
    const email = userData ? userData.email.toString() : '';
    const path = location.pathname;

    const onClose = () => {
        HistoryService.clearHash();
    };

    const onClickPlay = () => {
        HistoryService.history.push('/slots');
        GoogleService.newYearPlayPopup(userId, email, path, balance);
    };

    return (
        <div className="NewYearSuccess">
            <div className="NewYearSuccess__close">
                <button className="NewYearSuccess__close_btn" onClick={onClose} >
                    <img className="NewYearSuccess__close_img" src={CloseIcon} alt="close" />
                </button>
            </div>
            <img className="NewYearSuccess__prize" src={Prizes} alt="prizes" />
            <div className="NewYearSuccess__title">{t('year.popup.title')}</div>
            <div className="NewYearSuccess__desc">{t('year.popup.desc')}</div>
                <button className="NewYearSuccess__btn" onClick={onClickPlay}>
                    {t('profile.game.button')}
                </button>
        </div>
    )
}

export default NewYearSuccess;
