import './ProgressBar.scss';

type Props = { width: number };

function ProgressBar({ width }: Props) {
    const currentWidth = width.toFixed(1);

    return (
        <div className="progress">
            <div className="progress__back">{currentWidth}%</div>
            <div
                className="progress__front"
                style={{
                    clipPath: `inset(0 0 0 ${width}%)`,
                }}
            >
                {currentWidth}%
            </div>
        </div>
    );
}

export default ProgressBar;
