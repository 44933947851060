import Validator from '../interface/Validator';

class BirthDay implements Validator {
    validate(value: string): boolean {
        return value.length === 10;
    }
}
const BirthDayService = new BirthDay();

export { BirthDayService };
export default BirthDay;
