import { Direction, Status } from '@ay_tsarbet/newbet-core/dist/types';
import { STATUS_NAME } from 'appConstants/operations';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useState } from 'react';
import Collapsible from 'react-collapsible';
import { translate as t } from 'service/Language';
import History from 'service/model/History';
import SvgImage from 'svg';
import { formatCurrencySign } from 'utils/format';

import './ListItem.scss';

type HistoryItemProps = {
    history: History;
};

const ListItem = (props: HistoryItemProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const { id, amount, type, status, currency, created, confirmed, paymentMethod, accountNumber, trxID } =
        props.history;

    const itemClass = classNames('list-item', {
        active: isOpen,
    });
    const statusClass = classNames('list-item__status', {
        success: status === Status.SUCCESS,
        error: status === Status.FAIL || status === Status.CANCELED,
        pending: status === Status.CREATED,
    });
    const additionalClass = classNames('list-item__additional', {
        active: isOpen,
    });

    return (
        <>
            <div className={itemClass}>
                <div className="list-item__id">{id}</div>
                <div className="list-item__created">{format(created, 'HH:mm dd/MM')}</div>
                <div className="list-item__method">
                    <img
                        alt={paymentMethod ? t(paymentMethod?.title) : 'Payment Method'}
                        src={paymentMethod?.icon}
                    />
                    {paymentMethod ? t(paymentMethod?.title) : ''}
                </div>
                {currency && (
                    <div className="list-item__amount">
                        {type === Direction.WITHDRAW ? '-' : ''}
                        {formatCurrencySign(amount, 2, currency)}
                    </div>
                )}
                <div className={statusClass}>{t(STATUS_NAME[status])}</div>
                <div className="list-item__control" onClick={() => setIsOpen(!isOpen)}>
                    <SvgImage name={!isOpen ? 'ArrowDown' : 'ArrowUp'}/>
                </div>
            </div>
            <Collapsible open={isOpen} transitionTime={200} trigger="">
                <div className={additionalClass}>
                    <div/>
                    <div className="list-item__additional-item">
                        <div className="list-item__additional-item__title">
                            {t('profile.balance.history.confirmed')}
                        </div>
                        <div className="list-item__additional-item__value">
                            {status === Status.SUCCESS || status === Status.FAIL ? format(confirmed, 'HH:mm dd/MM') : 'n/a'}
                        </div>
                    </div>
                    <div>
                        {accountNumber ? <div className="list-item__additional-item">
                            <div className="list-item__additional-item__title">
                                {t('profile.balance.deposit.input.account.number')}{' '}
                            </div>
                            <div className="list-item__additional-item__value">{accountNumber}</div>
                        </div> : null}
                        {(trxID && status === Status.SUCCESS) ? <div className="list-item__additional-item">
                            <div className="list-item__additional-item__title">
                                {t('profile.balance.history.id')}{' '}
                            </div>
                            <div className="list-item__additional-item__value">{trxID}</div>
                        </div> : null}
                    </div>
                    <div/>
                    <div/>
                    <div/>
                </div>
            </Collapsible>
            <div className="list-item__border"/>
        </>
    );
};

export default ListItem;
