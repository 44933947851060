import { useEffect } from 'react';
import { translate as t } from 'service/Language';

import BalanceBlock from '../../../component/Header/Menu/List/BalanceBlock/BalanceBlock';
import GeoDataService from '../../../service/GeoData';
import GoogleService from '../../../service/Google';
import { useIsDesktop } from '../../../utils/useBreakpoints';
import useObservable from '../../../utils/useObservable';
import { profileRouteList } from '../Profile';
import ProfileHeader from '../ProfileHeader/ProfileHeader';
import Email from './image/email.svg';
import Id from './image/id.svg';
import Lock from './image/lock.svg';
import Map from './image/map.svg';
import Time from './image/time.svg';
import Earth from './image/earth.svg';
import PersonalDataFormService from './PersonalData/service/PersonalDataForm';
import PreviewBlock from './PreviewBlock/PreviewBlock';
import TimeZoneService from '../../../service/TimeZone';

import './Account.scss';

type RowType = {
    img: string;
    title: string | null;
};

export type PreviewBlockType = {
    header: string;
    block: RowType[];
    path: string;
};

function Account() {
    const userData = useObservable(PersonalDataFormService.userData, PersonalDataFormService.userData.getValue());
    const geoData = useObservable(GeoDataService.data, GeoDataService.data.getValue());
    const isDesktop = useIsDesktop();

    useEffect(() => {
        if (userData) {
            GoogleService.openUserPage(userData.id.toString());
        }
    }, [userData]);

    const countryName = userData.country.name !== 'unknown' ? t(userData.country.name) : geoData ? t(geoData.country.name) : null;

    const previewBlockPerson: PreviewBlockType = {
        header: 'profile.account.personal',
        block: [
            { img: Id, title: userData ? userData.id.toString() : null },
            { img: Map, title: countryName },
            { img: Email, title: userData ? userData.email : null },
        ],
        path: profileRouteList.personal,
    };

    const previewBlockSafety: PreviewBlockType = {
        header: 'profile.account.safety',
        block: [
            { img: Lock, title: '************' },
            { img: Earth, title: t('profile.settings.language.name') },
            { img: Time, title: TimeZoneService.get() },
        ],
        path: profileRouteList.safety,
    };

    return (
        <>
            <ProfileHeader />
            <div className="account">
                {!isDesktop && <BalanceBlock />}
                <PreviewBlock data={previewBlockPerson} />
                <PreviewBlock data={previewBlockSafety} />
            </div>
        </>
    );
}

export default Account;
