import { formatCurrencySign } from 'utils/format';

import Input from '../../../../../../../component/Input/Input';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { translate as t } from '../../../../../../../service/Language';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import AmountFormService, { PayfixErrorTip, PayfixInputTip } from '../../service/AmountForm';
import Back from './image/back.png';
import Submit from '../Button/Submit';
import SumSelector from '../SummSelector/SumSelector';
import { createRef } from 'react';
import { InstapayErrorTip } from '../../../Instapay/service/AmountForm';

import './Amount.scss';
import Attention from '../../../Apay/Attention/Attention';


type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {};

class Amount extends RxComponent<AmountProps, AmountState> {
    state = {
        errorType: PayfixInputTip.NONE,
        errorLetters: PayfixErrorTip.NONE,
    };

    handler = {
        onErrorType: this.onErrorType.bind(this),
        onErrorLetters: this.errorLetters.bind(this),
        clickOutside: this.clickOutside.bind(this),
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    componentDidMount() {
        const {paymentMethod} = this.props;

        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setAmount(paymentMethod.defaultValue);
        this.subscribe(AmountFormService.errorType, {next: this.handler.onErrorType});
        this.subscribe(AmountFormService.errorLetters, {next: this.handler.onErrorLetters});
        document.addEventListener('pointerdown', this.handler.clickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    close() {
        this.setState({errorLetters: InstapayErrorTip.NONE});
    }

    clickOutside(event: PointerEvent) {
        if (this.ref.self.current && !this.ref.self.current.contains(event.target as Node)) {
            this.close();
        }
    }

    onErrorType(errorType: PayfixInputTip) {
        this.setState({errorType});
    };

    errorLetters(errorLetters: PayfixErrorTip) {
        this.setState({errorLetters});
    }

    onTermsAgreement(termsAgreement: boolean) {
        this.setState({ termsAgreement });
    }

    render() {
        const {paymentMethod, lock} = this.props;
        const { errorType, errorLetters } = this.state;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        return (
            <div className="deposit-amount-payfix">
                <form className="deposit-amount-payfix-form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    <div className="deposit-amount-payfix-form-block">
                        <Input
                            className="deposit-amount-payfix-form-block__input"
                            inputService={AmountFormService.name}
                            name="name"
                            enabled={!lock}
                        />
                        {errorType === PayfixInputTip.NAME && (
                            <span className="deposit-amount-payfix-form__help">
                                {t('popup.payment.name.hint')}
                            </span>
                        )}
                        {errorLetters === PayfixErrorTip.NAME ?
                            <div className="deposit-amount-payfix-form-letters" ref={this.ref.self}>
                                <img className="deposit-amount-payfix-form-letters__img" src={Back} alt="back"/>
                                <span
                                    className="deposit-amount-payfix-form-letters__text">{t('popup.payment.turkey.error.letters')}</span>
                            </div> : null}
                    </div>
                    <div className="deposit-amount-payfix-form-block">
                        <Input
                            className="deposit-amount-payfix-form-block__input"
                            inputService={AmountFormService.surname}
                            name="surname"
                            enabled={!lock}
                        />

                        {errorType === PayfixInputTip.SURNAME && (
                            <span className="deposit-amount-payfix-form__help">
                                {t('popup.payment.name.hint')}
                            </span>
                        )}
                        {errorLetters === PayfixErrorTip.SURNAME ?
                            <div className="deposit-amount-payfix-form-letters" ref={this.ref.self}>
                                <img className="deposit-amount-payfix-form-letters__img" src={Back} alt="back"/>
                                <span
                                    className="deposit-amount-payfix-form-letters__text">{t('popup.payment.turkey.error.letters')}</span>
                            </div> : null}
                    </div>
                    <div className="deposit-amount-payfix-form-block">
                        <MaskedInput
                            className="deposit-amount-payfix-form-block__input"
                            inputService={AmountFormService.amount}
                            name="amount"
                            mask="0000000"
                            hint={hint}
                            enabled={!lock}
                        />
                        <span
                            className="deposit-amount-payfix-form-block__input_currency">{paymentMethod.currency.sign}</span>
                    </div>
                </form>
                <div className="deposit-amount-payfix__sum">
                    <SumSelector paymentMethod={paymentMethod} disabled={lock}/>
                </div>
                <div className="deposit-amount-payfix-check">
                    <Attention title="popup.payment.attention" type="attention" />
                </div>
                <div className="deposit-amount-payfix__submit">
                    <Submit/>
                </div>
            </div>
        );
    }
}

export default Amount;
