import { translate as t } from 'service/Language';

import { PhoneCardError } from '../../../../../Withdrawal/Paykassma/service/PhoneForm';
import { TransactionError } from '../../../service/TransactionForm';

import './ErrorTip.scss';
import { PayfixInputTip } from '../../../../Payfix/service/AmountForm';

type ErrorTipProps = {
    error: TransactionError | PhoneCardError | PayfixInputTip;
};
function ErrorTip({ error }: ErrorTipProps) {
    return (
        <div>
            {error === TransactionError.SYMBOL && (
                <div className="error-tip__symbol">
                    <div>{t('profile.balance.deposit.input.validation.regex.paykassma')}</div>
                </div>
            )}
            {error === TransactionError.NUMBER || error === TransactionError.LETTER ? (
                <div className="error-tip__first">
                    <div>{t('profile.balance.deposit.input.validation.first')}</div>
                </div>
            ) : null}
        </div>
    );
}

export default ErrorTip;
