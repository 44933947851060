import useObservable from 'utils/useObservable';

import FailedPage from './ResultPage/FailedPage/FailedPage';
import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import BonusFailedPage from '../../../../../../component/BonusFailedPage/BonusFailedPage';
import Directa24WithdrawService, { Directa24WithdrawState } from '../service/Directa24Withdraw';

import './ResultForm.scss';


function ResultForm() {
    const state = useObservable(Directa24WithdrawService.state, Directa24WithdrawService.state.getValue());

    switch (state) {
        case Directa24WithdrawState.ACCEPT: {
            return <SuccessPage />;
        }
        case Directa24WithdrawState.REJECT: {
            return <FailedPage />;
        }
        case Directa24WithdrawState.BONUS_REJECT: {
            return <BonusFailedPage />;
        }
        default: {
            return null;
        }
    }
}

export default ResultForm;
