import { Direction } from '@ay_tsarbet/newbet-core/dist/types';
import { translate as t } from 'service/Language';

import TransactionHistoryService from '../service/TransactionHistoryService';

import './Tab.scss';

type HistoryTabProps = {
    type: Direction;
};

const Tab = (props: HistoryTabProps) => {
    return (
        <div className="history-tab">
            <div className="history-tab__btn" onClick={() => TransactionHistoryService.toggle(Direction.REFILL)}>
                {props.type === Direction.REFILL && (
                    <div className="history-tab__btn_active">{t('profile.navigation.deposit')}</div>
                )}
                {t('profile.navigation.deposit')}
            </div>
            <div className="history-tab__btn" onClick={() => TransactionHistoryService.toggle(Direction.WITHDRAW)}>
                {props.type === Direction.WITHDRAW && (
                    <div className="history-tab__btn_active">{t('profile.navigation.withdraw')}</div>
                )}
                {t('profile.navigation.withdraw')}
            </div>
        </div>
    );
};

export default Tab;
