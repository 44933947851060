import createHistory from 'history/createBrowserHistory';

class HistoryCreator {
    history = createHistory();

    clearHash() {
        this.history.push(this.history.location.pathname + this.history.location.search);
    }

    setHash(hash: string) {
        this.history.push({
            hash,
        });
    }

    redirect(url: string) {
        this.history.push(url);
    }
}

export const HistoryService = new HistoryCreator();
