import { READY_STATES } from 'enums/ws';
import { BehaviorSubject } from 'rxjs';

import PopupHelper from './PopupHelper';
import WSBet from './WSBet';

class WSConnection {
    isVisible = new BehaviorSubject<boolean>(true);

    isOffline = new BehaviorSubject<boolean>(true);

    private handler = {
        addEventListener: this.addEventListener.bind(this),
        onOnlineEvent: this.onOnlineEvent.bind(this),
        onVisibleEvent: this.onVisibleEvent.bind(this),
    };

    public onVisibleEvent() {
        // Ловим событие блокировки экрана телефона чтобы НЕ выводить LostConnectionPopup
        if (document.visibilityState === 'hidden') {
            console.log('BLOCK SCREEN');
            this.isVisible.next(false);
        }

        if ('wakeLock' in navigator) {
        } else {
            const reconnects = WSBet.reconnectsCount();
            console.log('WSConnection reconnects', reconnects);
            if (reconnects < 5) {
                WSBet.websocketInstance.close();
            }
        }
    }

    public onOnlineEvent() {
        console.log('BLOCK SCREEN ONLINE EVENT');
        if (navigator.onLine) {
            console.log('BLOCK SCREEN ONLINE EVENT - ONLINE');
            this.isOffline.next(false);
            PopupHelper.closeLostConnection();
        } else {
            console.log('BLOCK SCREEN ONLINE EVENT - OFFLINE');
            this.isOffline.next(true);
            if (this.isVisible.value) {
                PopupHelper.showLostConnection();
            }
        }
    }

    public addEventListener() {
        // Событие блокировки экрана телефона
        document.addEventListener('visibilitychange', this.handler.onVisibleEvent);
        // Ниже костыль для Safari
        window.addEventListener('offline', this.handler.onOnlineEvent);
        window.addEventListener('online', this.handler.onOnlineEvent);
    }

    public removeEventListener() {
        document.removeEventListener('visibilitychange', this.handler.onVisibleEvent);
        window.removeEventListener('offline', this.handler.onOnlineEvent);
        window.removeEventListener('online', this.handler.onOnlineEvent);
    }

    public checkNetwork() {
        WSBet.state.subscribe(state => {
            if (state === READY_STATES.ERROR) {
                if (this.isVisible.value && this.isOffline.value) {
                    PopupHelper.showLostConnection();
                }
            }
            if (state === READY_STATES.CLOSED) {
                if (this.isVisible.value && this.isOffline.value) {
                    PopupHelper.showLostConnection();
                }
            }
            if (state === READY_STATES.OPEN) {
                // PopupHelper.closeLostConnection();
                this.isVisible.next(true);
            }
        });
    }
}

const WSConnectionService = new WSConnection();

export default WSConnectionService;
