import { ClientInfo } from '@ay_tsarbet/newbet-core/dist/types';
import { BehaviorSubject } from 'rxjs';

import AuthService from './Auth';
import OperationsService from './Operations';

class Ads {
    firstDepositBanner = new BehaviorSubject(false);

    handler = {
        onAuthChange: this.onAuthChange.bind(this),
        onSuccessTransactionUpdate: this.onSuccessTransactionUpdate.bind(this),
    };

    init() {
        AuthService.auth.subscribe(this.handler.onAuthChange);
        OperationsService.haveSuccessTransaction.subscribe(this.handler.onSuccessTransactionUpdate);
    }

    checkTransaction() {
        OperationsService.list.getValue();
    }

    onSuccessTransactionUpdate(haveTransaction: boolean) {
        this.firstDepositBanner.next(haveTransaction);
    }

    onAuthChange(clientInfo: ClientInfo | null) {
        if (clientInfo) {
            this.checkTransaction();
        }
    }
}

const AdsService = new Ads();

export default AdsService;
