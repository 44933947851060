import { translate as t } from 'service/Language';
import Failed from './image/failed.svg';
import CashixWithdrawService, {CashixWithdrawState} from '../../../service/CashixWithdraw';
import CashixFormService from '../../../service/CashixForm';

import './FailedPage.scss';

function FailedPage() {
    function onClickHandler() {
        CashixWithdrawService.state.next(CashixWithdrawState.INIT)
        CashixFormService.phone.autoCompleted.next(true);
        CashixFormService.amount.autoCompleted.next(true);

    }
    return (
        <div className="result-payer">
            <div className="result-payer-container">
                <img className="result-payer-container__img" src={Failed} alt="failed" />
                <p className="result-payer-container__title ">{t('popup.payment.error.title')}</p>
                <span className="result-payer-container__text">{t('popup.payment.error.text')}</span>
                <button className="result-payer-container__btn" onClick={onClickHandler}>
                    {t('popup.payment.error.back')}
                </button>
            </div>
        </div>
    );
}

export default FailedPage;
