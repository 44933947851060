import classNames from 'classnames';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';

import Spinner from '../../../Deposit/Paykassma/Other/Button/image/Spinner.svg';
import { PaymentMethod } from '../../../PaymentMethod/types';
import { PaykassmaWithdrawState } from '../service/PaykassmaWithdraw';
import PhoneFormService from '../service/PhoneForm';

import './WithdrawalButton.scss';


type WithdrawalButtonType = {
    buttonTitle: string;
    variation?: boolean;
    paymentMethod?: PaymentMethod;
    amount?: string;
    state: PaykassmaWithdrawState;
};

function WithdrawalButton(props: WithdrawalButtonType) {
    const { buttonTitle, variation, paymentMethod, amount, state } = props;
    const valid = useObservable(PhoneFormService.valid, PhoneFormService.valid.getValue());

    const min = paymentMethod && paymentMethod.limit.withdraw.min;
    const sumToWithdraw = amount && parseInt(amount) - parseInt(amount) * 0.2;
    const disabledFee = sumToWithdraw && min && sumToWithdraw >= min;

    const className = classNames('withdrawal-button-paykassma', {
        'withdrawal-button-paykassma__active': valid || (!variation && disabledFee),
        'withdrawal-button-paykassma__variated': variation && !disabledFee,
    });

    return (
        <button type="submit" className={className} disabled={!valid || (variation && !disabledFee)}>
            {state === PaykassmaWithdrawState.AWAIT ? (
                <img className="withdrawal-button-paykassma__wait" src={Spinner} alt="" />
            ) : (
                t(buttonTitle)
            )}
        </button>
    );
}

export default WithdrawalButton;
