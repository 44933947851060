import { add, getUnixTime } from 'date-fns';
import { BehaviorSubject } from 'rxjs';

import GameHistoryInterface from '../interface/GameHistoryInterface';
import { GameList } from '../model/Game';

class GameHistory {
    public gameHistoryList = new BehaviorSubject<GameList>([]);

    handler: GameHistoryInterface;

    constructor(handler: GameHistoryInterface) {
        this.handler = handler;
    }

    request() {
        const dateFrom = 0;
        const dateTo = getUnixTime(
            add(new Date(), {
                hours: 300, // Из-за того, что старые пользователи регистрировались с часовым поясом -250 часов (Андрей)
            })
        );

        this.handler.getGameHistory({ dateFrom, dateTo });
    }
}

export default GameHistory;
