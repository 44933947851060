import Filter from './Filter/Filter';
import PlatformHelper from './PlatformHelper';
import Sort from './Sort/Sort';

import './SortAndFilterMenu.scss';

function SortAndFilterMenu() {
    return (
        <div className="sort-and-filter-menu">
            <Filter />
            <Sort />
            <PlatformHelper />
        </div>
    );
}

export default SortAndFilterMenu;
