import { translate as t } from 'service/Language';
import UIService from 'service/UI';

import PopupService from '../../../../../service/Popup';
import Plane from './images/Plane.png';

import './WheelWinAviator.scss';

type WheelWinProps = {
    spin: () => void;
};

function WheelWinAviator({ spin }: WheelWinProps) {
    const spinAgain = () => {
        UIService.unlockScroll();
        PopupService.close();
        spin();
    };
    return (
        <div className="win-aviator">
            <div className="win-aviator__header">{t('wheel.aviator.win.header')}</div>
            <div className="win-aviator__description">{t('wheel.aviator.win.description')}</div>
            <div className="win-aviator__title">{t('wheel.aviator.bonus.125')}</div>
            <div className="win-aviator__subtitle">{t('wheel.aviator.bonus.first')}</div>
            <div className="win-aviator__again">{t('wheel.aviator.win.again')}</div>
            <button className="win-aviator__button" onClick={spinAgain}>
                {t('wheel.aviator.win.button.again')}
            </button>
            <img className="win-aviator__plane" src={Plane} alt="plane" />
        </div>
    );
}

export default WheelWinAviator;
