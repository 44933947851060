import Time from './image/time.svg';
import { translate as t } from 'service/Language';

import './AttentionWithdrawalTime.scss';

function AttentionWithdrawalTime() {
    return (
        <div className="AttentionWithdrawalTime">
            <img  className="AttentionWithdrawalTime__img" src={Time} alt="time" />
            <span className="AttentionWithdrawalTime__text">{t('popup.payment.attention.application')}
                <strong> {t('popup.payment.attention.time')}</strong>
            </span>
        </div>
    )
}

export default AttentionWithdrawalTime;