import { InstapayWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendInstapayWithdrawRequest';

class InstapayWithdraw {
    currencyId: number;

    channelType: number;

    amount: number;

    name: string;

    surname: string;

    tcNumber: string;

    paparaNumber: string;

    phone: string;

    iban: string;

    bankName: string;

    branchCode: string;

    accountNumber: string;

    bankId: number;

    dueDate: string;

    constructor(data: InstapayWithdrawRequest) {
        this.currencyId = data.currencyId;
        this.channelType = data.channelType;
        //сумма обязательно для каждого канала. Можно float
        this.amount = data.amount;
        //имя клиента //  обязательное для всех
        this.name = data.name;
        //фамилия клиента//  обязательное для всех
        this.surname = data.surname;
        //персональный код клиента. Если придет пусто -значит сгенерится на бэке
        this.tcNumber = data.tcNumber;
        // номер кошелька для папары. Обязательное для 191 канала. //Для 190 пусто
        this.paparaNumber = data.paparaNumber;
        //Обязательное для 190 канала. Для остальных пусто
        this.phone = data.phone;
        //Обязательное для 190 канала. Для остальных пусто.
        this.iban = data.iban;
        //Обязательное для 190 канала. Для остальных пусто.
        this.bankName = data.bankName;
        //Обязательное для 190 канала. Для остальных пусто.
        this.branchCode = data.branchCode;
        //Обязательное для 190 канала. Для остальных пусто.
        this.accountNumber = data.accountNumber;
        // обязательное поле для 196 канала. Для остальных ноль.
        this.bankId = data.bankId;
        // дата годен до документа удоств. личность "YYYY-MM-DD" //Обязательное для 194 195 каналов
        this.dueDate = data.dueDate;
    }
}

export default InstapayWithdraw;
