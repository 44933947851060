import { BehaviorSubject } from 'rxjs';

import PaymentService from '../../../../../../service/Payment';
import { PaymentMethod } from '../../../PaymentMethod/types';
import AmountFormService from './AmountForm';

enum DepositState {
    AMOUNT,
    WAIT,
    FAILED,
    SUCCESS,
}

class InstapayDeposit {
    state = new BehaviorSubject<DepositState>(DepositState.AMOUNT);

    request(
        paymentMethod: PaymentMethod,
        name: string,
        surname: string,
        tcNumber: string,
        birth: string,
        email: string,
        amount: number
    ) {
        this.state.next(DepositState.WAIT);

        PaymentService.instapay.deposit({
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
            name,
            surname,
            tcNumber,
            birth,
            email,
            amount,
        });
    }

    reset() {
        this.state.next(DepositState.AMOUNT);
        AmountFormService.reset();
    }

    onError() {
        this.state.next(DepositState.FAILED);
    }

    onReceive(link: string) {
        const state = this.state.getValue();
        if (state === DepositState.WAIT) {
            window.open(link, '_self');
        }
    }

    back() {
        this.reset();
    }
}

const InstapayDepositService = new InstapayDeposit();

export { DepositState };
export default InstapayDepositService;
