import { SystemTypes } from "enums/payment";

import { PaymentMethod } from "../PaymentMethod/types";
import AnindaHavale from "./AnindaHavale/Form/Form";
import ApayForm from "./Apay/Form/Form";
import AzarpayForm from "./Azarpay/Form/Form";
import CorefyForm from "./Corefy/Form/Form";
import Epul from "./Epul/Form/Form";
import HavaleHera from "./HavaleHera/Form/Form";
import Instapay from "./Instapay/Form/Form";
import Paycos from "./Paycos/Form/Form";
import Payfix from "./Payfix/Form/Form";
import PaykassmaForm from "./Paykassma/Form/Form";
import Directa24Form from "./Directa24/Form/Form";
import PayesForm from "./Payes/Form/Form";
import ConstantposForm from "./Constantpos/Form/Form";
import MpayForm from "./Mpay/Form/Form";
import CoinspaidForm from  "./Coinspaid/Form";
import PaycordForm from  "./Paycord/Form";
import WellxPayForm from  "./Wellxpay/Form/Form";
import CashixForm from  "./Cashix/Form/Form";

type DepositProps = {
  method: PaymentMethod;
};

const Deposit = (props: DepositProps) => {
  console.log('Deposit', props.method.system)
  switch (props.method.system) {
    case SystemTypes.PAYHERA: {
      return <HavaleHera paymentMethod={props.method} />;
    }
    case SystemTypes.ANINDA: {
      return <AnindaHavale paymentMethod={props.method} />;
    }
    case SystemTypes.PAYKASSMA: {
      return <PaykassmaForm paymentMethod={props.method} />;
    }
    case SystemTypes.EPUL: {
      return <Epul paymentMethod={props.method} />;
    }
    case SystemTypes.PAYCOS: {
      return <Paycos paymentMethod={props.method} />;
    }
    case SystemTypes.INSTAPAYS: {
      return <Instapay paymentMethod={props.method} />;
    }
    case SystemTypes.PAYFIX: {
      return <Payfix paymentMethod={props.method} />;
    }
    case SystemTypes.COREFY: {
      return <CorefyForm paymentMethod={props.method} />;
    }
    case SystemTypes.AZARPAY: {
      return <AzarpayForm paymentMethod={props.method} />;
    }
    case SystemTypes.VALLERPAY: {
      return <AzarpayForm paymentMethod={props.method} />;
    }
    case SystemTypes.APAY: {
      return <ApayForm paymentMethod={props.method} />;
    }
    case SystemTypes.DIRECT24: {
      return <Directa24Form paymentMethod={props.method} />;
    }
    case SystemTypes.PAYES: {
      return <PayesForm paymentMethod={props.method} />;
    }
    case SystemTypes.CONSTANTPOS: {
      return <ConstantposForm paymentMethod={props.method} />;
    }
    case SystemTypes.MPAY: {
      return <MpayForm paymentMethod={props.method} />;
    }
    case SystemTypes.COINSPAID: {
      return <CoinspaidForm paymentMethod={props.method} />;
    }
    case SystemTypes.PAYCORD: {
      return <PaycordForm paymentMethod={props.method} />;
    }
    case SystemTypes.WELLXPAY: {
      return <WellxPayForm paymentMethod={props.method} />;
    }
    case SystemTypes.CASHIX: {
      return <CashixForm paymentMethod={props.method} />;
    }
    default:
      return null;
  }
};

export default Deposit;
