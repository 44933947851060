import classNames from 'classnames';
import AutologinIframe from 'component/AutologinIframe/AutologinIframe';
import ScrollTopButton from 'component/ScrollTopButton/ScrollTopButton';
import { useEffect } from 'react';
import { BrowserRouter, Router } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';
import AuthService from 'service/Auth';
import LanguageService, { translate as t } from 'service/Language';

import Ban from '../../component/Ban/Ban';
import Preloader from '../../component/common/Preloader/Preloader';
import HashRoute from '../../component/HashRoute/HashRoute';
import Notification from '../../component/Notification/Notification';
import Popup from '../../component/Popup/Popup';
import { Sprite } from '../../component/Sprite/Sprite';
import CountryRestricted from '../../page/CountryRestricted/CountryRestricted';
import AppService from '../../service/App';
import IntercomService from 'service/Intercom';
import CountryPermissionService, { GeoPermission } from '../../service/CountryPermission';
import GoogleService from '../../service/Google';
import { HistoryService } from '../../service/HistoryService';
import ThemeService, { Themes } from '../../service/Theme';
import useObservable from '../../utils/useObservable';
import Viewport from '../../Viewport/Viewport';

import './Casino.scss';
import '../../scss/global.scss';
import ChatwootLoader from 'component/ChatwootLoader';
// import { useIntercom } from 'react-use-intercom';

function Casino() {
    const permission = useObservable(
        CountryPermissionService.result,
        CountryPermissionService.result.getValue(),
    );
    const theme = useObservable(ThemeService.theme, ThemeService.theme.getValue());

    useObservable(AuthService.auth, AuthService.auth.getValue());
    useObservable(LanguageService.current, LanguageService.current.getValue());

    const chapter = document.getElementsByClassName('hoverl_abed')[0];

    // const intercomContext = useIntercom();

    document.title = t('title');

    useEffect(() => {
        AppService.bind();
        return () => {
            AppService.unbind();
        };
    }, []);

    // useEffect(() => {
    //     if (intercomContext) {
    //         IntercomService.init(intercomContext);
    //     }
    // }, [intercomContext]);

    useEffect(() => {
        if (document.contains(chapter)) {
            chapter.addEventListener('click', () => GoogleService.openLiveChat());
        }
        return () => {
            if (document.contains(chapter)) {
                chapter.removeEventListener('click', () => GoogleService.openLiveChat());
            }
        };
    }, [chapter]);

    const className = classNames({
        casino: true,
        casino____dark: theme === Themes.dark,
    });

    console.log('process.env', process.env.NODE_ENV);

    return permission === GeoPermission.DENY ? (
        <CountryRestricted />
    ) : (
        <div className={className}>
            <Preloader />
            <Ban />
            {/* <ChatwootWidget /> */}
            <Notification />
            <ScrollTopButton />
            <BrowserRouter>
                <Router history={HistoryService.history}>
                    <Viewport />
                    {/* {process.env.NODE_ENV === 'development' ? <Utils /> : null} */}
                    {process.env.NODE_ENV === 'production' ? (
                        <YMInitializer accounts={[86814023]} />
                    ) : null}
                    <Popup />
                    <div className='app__hidden'>
                        <Sprite />
                    </div>
                    <HashRoute />
                </Router>
            </BrowserRouter>
            <AutologinIframe />
            <ChatwootLoader />
        </div>
    );
}

export default Casino;
