import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import './NewTimer.scss';


type TimerProps = {
    finishDate: string;
    mask?: 'DD:HH:mm:ss' | 'HH:mm:ss' | 'mm:ss' | 'ss';
    isBonus?: boolean;
};

let INITIAL_STATE = '00:00';

const NewTimer = (props: TimerProps) => {
    const { finishDate, mask = 'mm:ss' } = props;
    const [timer, setTimer] = useState<string>(INITIAL_STATE);

    useEffect(()=> {
        if(mask) {
            if('DD:HH:mm:ss') {
                INITIAL_STATE = '00:00:00:00'
            }
            if('HH:mm:ss') {
                INITIAL_STATE = '00:00:00'
            }
            if('ss') {
                INITIAL_STATE = '00'
            }
        }
    },[])

    useEffect(() => {
        if (finishDate) {
            const interval: NodeJS.Timeout = setInterval(() => {
                const now = dayjs();
                const finish = dayjs(finishDate);
                dayjs.extend(duration);
                const diffInMilliseconds = dayjs(finish.diff(now)).format(mask);

                if (diffInMilliseconds <= INITIAL_STATE) {
                    setTimer(INITIAL_STATE);
                    clearInterval(interval);
                    return;
                }
                setTimer(diffInMilliseconds);
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [finishDate, mask]);

    return (
        <div className="Timer">
            {timer}
        </div>
    )
};

export default NewTimer;
