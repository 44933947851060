import { useEffect } from 'react';
import GoogleService from 'service/Google';
import { translate as t } from 'service/Language';
import OperationsService from 'service/Operations';
import useObservable from 'utils/useObservable';

import { PaymentMethod } from '../../../PaymentMethod/types';
import FailedPage from '../../ResultPage/FailedPage/FailedPage';
import TelegramSuccessPage from '../../ResultPage/TelegramSuccessPage/TelegramSuccessPage';
import PaycosDepositService, { DepositState } from '../service/PaycosDeposit';
import Amount from './Amount/Amount';

import './Form.scss';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(PaycosDepositService.state, PaycosDepositService.state.getValue());

    useEffect(() => {
        const gaSub = OperationsService.invoice.subscribe({
            next: (data) => {
                const clientId = GoogleService.getClientId();
                const invoiceId = data.id.toString();
                OperationsService.sendGAClientRelation(clientId, invoiceId);
            },
        });
        return () => {
            gaSub.unsubscribe();
        };
    }, []);

    return (
        <div className="deposit-form">
            {(state === DepositState.AMOUNT || state === DepositState.WAIT) && (
                <>
                    <h2 className="deposit-form__header">{t(paymentMethod.title)}</h2>
                    <Amount paymentMethod={paymentMethod} lock={state !== DepositState.AMOUNT} />
                </>
            )}

            {state === DepositState.SUCCESS ? <TelegramSuccessPage /> : null}
            {state === DepositState.FAILED ? <FailedPage /> : null}
        </div>
    );
}

export default Form;
