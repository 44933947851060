import Input from 'component/Input/service/Input';

import Validator from '../../../../../../../service/Validator/Validator';

const EmailInputService = new Input({
    title: 'registration.email',
    validator: Validator.email,
    validatorText: 'recovery.error.email',
});

export default EmailInputService;
