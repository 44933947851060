import { useMemo } from 'react';

import RouteConfig from '../../appConstants/RouteConfig';
import CategoryHeader from '../../component/CategoryHeader';
import AuthService from '../../service/Auth';
import GamesService from '../../service/Games';
import useObservable from '../../utils/useObservable';
import GameList from '../Casino/GameList/GameList';
import CountryBlocked from '../Casino/Provider/CountryBlocked/CountryBlocked';
import CurrencyBlocked from '../Casino/Provider/CurrencyBlocked/CurrencyBlocked';
import Game from '../../service/model/Game';

function LiveCasino() {
    const providers = useObservable(GamesService.allowedProviders, GamesService.cache.allowedProvider);
    const games = useObservable(GamesService.games, GamesService.games.getValue());
    const isAuth = useObservable(AuthService.auth, AuthService.auth.getValue());

    const blockedProvider = isAuth ? <CurrencyBlocked/> : <CountryBlocked/>;

    const pragmaticList = useMemo(() => {
        const pragmaticProvider = providers.find(item => item.name.trim() === 'PragmaticPlayLive');
        if (pragmaticProvider && pragmaticProvider.gameList) {
            const pragmaticGameList = ['Sweet Bonanza CandyLand', 'Sweet Bonanza CandyLand Mobile', 'Mega Wheel', 'Mega Wheel Mobile', 'Speed Baccarat 9', 'Speed Baccarat 9 Mobile', 'Mega Sic Bo', 'Mega Sic Bo Mobile', 'Lucky 6 Roulette', 'Lucky 6 Roulette Mobile', 'PowerUp Roulette', 'PowerUp Roulette Mobile', 'Boom City', 'Boom City Mobile']
            return pragmaticProvider.gameList.filter(
                (game) => pragmaticGameList.some((name) => name === game.name.trim()))
        }
        return []
    }, [providers])

    console.log('pragmaticList', pragmaticList)

    const ezugiList = useMemo(() => {
        const ezugiProvider = providers.find(item => item.name.trim() === 'Ezugi');

        if (ezugiProvider && ezugiProvider.gameList) {
            const ezugiGameList = ['Bet on Teen Patti', 'LUCKY 7'];
            return ezugiProvider.gameList.filter(
                (game) => ezugiGameList.some((name) => name === game.name))
        }
        return []
    }, [providers])

    const evolutionList = useMemo(() => {
        const evolutionProvider = providers.find(item => item.name.trim() === 'Evolution');
        if (evolutionProvider && evolutionProvider.gameList) {
            return evolutionProvider.gameList
        }
        return []
    }, [providers])

    const topGameList = [ 'Boom City', 'Boom City Mobile', 'Bac Bo', 'Video Poker' ,'Lucky 7', 'Bet on Teen Patti', 'Türkçe Lightning Rulet', 'Monopoly Big Baller', 'turkish mega roulette mobile', 'turkish mega roulette', 'Lucky 6 Roulette', 'Lucky 6 Roulette Mobile', 'Auto-Roulette', 'Mega Sic Bo', 'Mega Sic Bo Mobile', 'PowerUp Roulette', 'PowerUp Roulette Mobile', 'Sweet Bonanza CandyLand', 'Sweet Bonanza CandyLand Mobile','Funky Time', 'MONOPOLY Live', 'Mega Wheel', 'Mega Wheel Mobile', 'Crazy Time'];

    const finalTopList = useMemo(() => {
        const bothList: Game[] = pragmaticList.concat(evolutionList).concat(ezugiList);
        topGameList.map((name) => {
            const currentGame = bothList.find(item => item.name.trim().toLowerCase() === name.trim().toLowerCase())
            if (currentGame) {
                const index = (bothList).indexOf(currentGame)
                if (index !== -1) {
                    bothList.splice(index, 1);
                }
                return bothList.unshift(currentGame)
            } else return bothList
        })
        return bothList
    }, [pragmaticList, evolutionList])

    const game = games.find(item => item.name.toLowerCase() === ('PowerUP Roulette').toLowerCase())
    console.log('game', game)
    
    return finalTopList.length ? (
        <div>
            <CategoryHeader title="casino.top.livecasino" backUrl={RouteConfig.casino.root} />
            <GameList games={finalTopList}/>
        </div>
    ) : (
        blockedProvider
    );
}
export default LiveCasino;
