import { translate as t } from 'service/Language';

import Logo from '../../assets/svg/LogoGlory.svg';
import Background from './images/Background.svg';

import './CountryRestricted.scss';

function CountryRestricted() {
    return (
        <div className='not-available'>
            <div className='not-available__header'>
                <img className='not-available__header__logo' src={Logo} alt='logo' />
            </div>
            <div className='not-available__main'>
                <div className='not-available__background'>
                    <img className='not-available__image' src={Background} alt='Background' />
                </div>
                <div className='not-available__description'>{t('notavailable.title')}</div>
            </div>
            <div className='not-available__footer'>
                <noindex>{t('footer')}</noindex>
            </div>
        </div>
    );
}

export default CountryRestricted;
