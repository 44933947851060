import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import EpulWithdraw from 'service/model/payment/EpulWithdraw';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';
import { PaymentMethod } from '../../../PaymentMethod/types';
import WithdrawService from '../../service/Withdrawal';
import EpulWithdrawService, { EpulWithdrawState } from './EpulWithdraw';

export enum EpulInputTip {
    NONE,
    NON_EMPTY,
}

class CardForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(0, 1);

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.withdraw.input.error',
    });

    card = new InputService({
        title: 'profile.balance.withdraw.input.card',
        validator: Validator.maskCardNumber,
    });

    valid = new BehaviorSubject<boolean>(false);

    error = new BehaviorSubject<EpulInputTip>(EpulInputTip.NONE);

    handler = {
        onAmount: this.onAmount.bind(this),
        onCard: this.onCard.bind(this),
        valid: this.checkValid.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.card.value.subscribe(this.handler.onCard);
    }

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        const limit = this.paymentMethod.limit.withdraw;
        this.limitValidator.setLimit(limit.min, limit.max);
    }

    onAmount(value: string) {
        this.checkValid();
        if (value && value.length <= 0) {
            this.error.next(EpulInputTip.NON_EMPTY);
            this.valid.next(false);
        } else {
            this.error.next(EpulInputTip.NONE);
        }
    }

    onCard(value: string) {
        this.checkValid();
        if (value && value.length <= 0) {
            this.error.next(EpulInputTip.NON_EMPTY);
            this.valid.next(false);
        } else {
            this.error.next(EpulInputTip.NONE);
        }
    }

    checkValid() {
        const valid = this.card.isValid() && this.amount.isValid();
        if (valid) {
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const status = EpulWithdrawService.state.getValue();

        const fee = WithdrawService.fee.getValue();
        console.log('SUBMIT fee', fee);
        if (!!fee) {
            if (status === EpulWithdrawState.HAS_FEE) {
                this.send();
            } else {
                EpulWithdrawService.state.next(EpulWithdrawState.HAS_FEE);
            }
        } else {
            this.send();
        }
    }

    send() {
        const valid = this.valid.getValue();

        if (valid && this.paymentMethod) {
            const amount = parseInt(this.amount.getValue());
            const card = this.card.getValue().split(' ').join('');
            const request = new EpulWithdraw(amount, this.paymentMethod, card);
            PaymentService.epul.withdraw(request);
            EpulWithdrawService.state.next(EpulWithdrawState.AWAIT);
            this.reset();
        }
    }

    reset() {
        this.card.value.next('');
        this.amount.value.next('');
        this.amount.error.next(null);
        this.card.error.next(null);
    }
}

const CardFormService = new CardForm();

export default CardFormService;
