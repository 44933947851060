import { PasswordData } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendChangePassword';
import RegistrationResponseStatus from '@ay_tsarbet/newbet-core/dist/models/registrationResponseStatus';
import { Step10Data } from '@ay_tsarbet/newbet-core/dist/types';
import { BehaviorSubject } from 'rxjs';

import WSBet from './WSBet';

class ChangePassword {
    public status = new BehaviorSubject<boolean>(false);

    change(data: PasswordData) {
        WSBet.userData.sendChangePassword(data);
    }

    onReceive(data: Step10Data) {
        const success = data.status === RegistrationResponseStatus.passwordChangeSuccess;

        if (success) {
            this.status.next(success);
        }
    }
}

const ChangePasswordService = new ChangePassword();

export default ChangePasswordService;
