import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { translate as t } from 'service/Language';

import useObservable from '../../../../../../../utils/useObservable';
import Spinner from '../../Other/Button/image/Spinner.svg';
import AmountFormService from '../../service/AmountForm';
import PaykassmaDepositService, { DepositState } from '../../service/PaykassmaDeposit';

import './Submit.scss';

type SubmitProps = {
    className?: string;
};

function Submit(props: SubmitProps) {
    const { className } = props;
    const [valid, setValid] = useState(false);
    const status = useObservable(PaykassmaDepositService.state, PaykassmaDepositService.state.getValue());
    const isDisabled = status === DepositState.WAITINVOICE || status === DepositState.WAIT

    useEffect(() => {
        const validate = AmountFormService.amount.valid.subscribe({
            next: (isValid) => {
                setValid(isValid);
            },
        });
        return () => {
            validate.unsubscribe();
        };
    }, []);

    const _className = classNames({
        [className || '']: !!className,
        'deposit-submit': true,
        'deposit-submit__active': valid ,
    });


    return (
        <button type="submit" className={_className} disabled={!valid || isDisabled}>
            { isDisabled ? (
                <img className="deposit-submit__wait" src={Spinner} alt="" />
            ) : (
                t('profile.balance.deposit.button.next')
            )}
        </button>
    );
}

export default Submit;
