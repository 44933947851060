import { PayfixWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPayFixWithdrawal';

class PayfixWithdraw {
    currencyId: number;

    channelType: number;

    amount: number;

    name: string;

    surname: string;

    accountName: string;

    constructor(data: PayfixWithdrawalRequest) {
        this.currencyId = data.currencyId;
        this.channelType = data.channelType;
        //сумма обязательно для каждого канала. Можно float
        this.amount = data.amount;
        //имя клиента //  обязательное для всех
        this.name = data.name;
        //фамилия клиента//  обязательное для всех
        this.surname = data.surname;
        //Обязательное для 190 канала. Для остальных пусто.
        this.accountName = data.accountName;
    }
}

export default PayfixWithdraw;
