import classNames from 'classnames';

import GamesService from '../../../service/Games';
import { translate as t } from '../../../service/Language';
import useObservable from '../../../utils/useObservable';
import arrow from '../image/arrow.svg';
import SortAndFilterService from '../service/SortAndFilter';

import './Button.scss';

function Button() {
    const open = useObservable(SortAndFilterService.toggle.filter, SortAndFilterService.toggle.filter.getValue());
    const filter = useObservable(GamesService.filter, GamesService.filter.getValue());
    const provider = filter.provider;

    const _className = classNames({
        'game-filter': true,
        'game-filter____open': open,
    });

    let content = <span className="game-filter__text">{t('casino.providers.header')}</span>;

    if (provider) {
        content = (
            <>
                <img className="game-filter__provider-logo" src={provider.logoImage} alt={provider.name} />
                <span className="game-filter__text">{provider.name}</span>
            </>
        );
    }

    return (
        <button
            className={_className}
            onClick={() => SortAndFilterService.toggleFilter()}
            ref={(ref) => SortAndFilterService.setButtonRef(ref)}
        >
            <div className="game-filter__title">{content}</div>
            <img className="game-filter__arrow" src={arrow} alt="" />
        </button>
    );
}

export default Button;
