import Validator from '../interface/Validator';

class Email implements Validator {
    validate(value: string): boolean {
        // const singleAt = value.includes('@');
        // const hasDot = value.includes('.');
        // const hasSymbols = true;

        return new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$").test(value);
        // singleAt && hasDot && hasSymbols;
    }
}

export default Email;
