import { Currency as CurrencyIso } from "@ay_tsarbet/newbet-core/dist/types";

enum CurrencySign {
  INR = "₹",
  BDT = "৳",
  EUR = "€",
  USD = "$",
  RUR = "₽",
  TRY = "₺",
  UZS = "UZS",
  PKR = "Rs",
  KZT = "₸",
  AZN = "₼",
  MMK = "MMK",
  KES = "Kes",
  BRL = "R$",
  LKR = "LKR",
}

enum CurrencySignPosition {
  BEFORE,
  AFTER,
}

export enum CurrencySeparator {
  POINT = ".",
  COMMA = ",",
}

class Currency {
  public ISO = 0;

  public name = "";

  public sign = "";

  public signPosition: CurrencySignPosition = CurrencySignPosition.BEFORE;

  public separator: CurrencySeparator = CurrencySeparator.POINT;

  public dateFormat = "dd/MM/yyyy";

  constructor(data: Currency) {
    Object.assign(this, data);
  }
}

const unknownCurrency = new Currency({
  ISO: -1000,
  name: "unknown",
  sign: "",
  signPosition: CurrencySignPosition.AFTER,
  separator: CurrencySeparator.POINT,
  dateFormat: "dd/MM/yyyy",
});

const list: Currency[] = [
  // {
  //   ISO: CurrencyIso.INR,
  //   name: "INR",
  //   sign: CurrencySign.INR,
  //   signPosition: CurrencySignPosition.BEFORE,
  //   separator: CurrencySeparator.POINT,
  //   dateFormat: "dd/MM/yyyy",
  // },
  // {
  //   ISO: CurrencyIso.BDT,
  //   name: "BDT",
  //   sign: CurrencySign.BDT,
  //   signPosition: CurrencySignPosition.BEFORE,
  //   separator: CurrencySeparator.POINT,
  //   dateFormat: "dd/MM/yyyy",
  // },
  {
    ISO: CurrencyIso.EUR,
    name: "EUR",
    sign: CurrencySign.EUR,
    signPosition: CurrencySignPosition.BEFORE,
    separator: CurrencySeparator.COMMA,
    dateFormat: "dd.MM.yyyy",
  },
  {
    ISO: CurrencyIso.USD,
    name: "USD",
    sign: CurrencySign.USD,
    signPosition: CurrencySignPosition.AFTER,
    separator: CurrencySeparator.COMMA,
    dateFormat: "MM-dd-yyyy",
  },
  // {
  //   ISO: CurrencyIso.RUR,
  //   name: "RUR",
  //   sign: CurrencySign.RUR,
  //   signPosition: CurrencySignPosition.AFTER,
  //   separator: CurrencySeparator.COMMA,
  //   dateFormat: "dd.MM.yyyy",
  // },
  // {
  //     ISO: CurrencyIso.UZS,
  //     name: 'UZS',
  //     sign: CurrencySign.UZS,
  //     signPosition: CurrencySignPosition.AFTER,
  //     separator: CurrencySeparator.COMMA,
  //     dateFormat: 'dd.MM.yyyy',
  // },
  // {
  //   ISO: CurrencyIso.TRY,
  //   name: "TRY",
  //   sign: CurrencySign.TRY,
  //   signPosition: CurrencySignPosition.BEFORE,
  //   separator: CurrencySeparator.COMMA,
  //   dateFormat: "dd.MM.yyyy",
  // },
  {
    ISO: CurrencyIso.PKR,
    name: "PKR",
    sign: CurrencySign.PKR,
    signPosition: CurrencySignPosition.AFTER,
    separator: CurrencySeparator.POINT,
    dateFormat: "dd.MM.yyyy",
  },
  // {
  //   ISO: CurrencyIso.KZT,
  //   name: "KZT",
  //   sign: CurrencySign.KZT,
  //   signPosition: CurrencySignPosition.AFTER,
  //   separator: CurrencySeparator.POINT,
  //   dateFormat: "dd/MM/yyyy",
  // },
  {
    ISO: CurrencyIso.AZN,
    name: "AZN",
    sign: CurrencySign.AZN,
    signPosition: CurrencySignPosition.AFTER,
    separator: CurrencySeparator.POINT,
    dateFormat: "dd/MM/yyyy",
  },
  {
    ISO: CurrencyIso.MMK,
    name: "MMK",
    sign: CurrencySign.MMK,
    signPosition: CurrencySignPosition.AFTER,
    separator: CurrencySeparator.POINT,
    dateFormat: "dd/MM/yyyy",
  },
  // {
  //   ISO: CurrencyIso.KES,
  //   name: "KES",
  //   sign: CurrencySign.KES,
  //   signPosition: CurrencySignPosition.AFTER,
  //   separator: CurrencySeparator.POINT,
  //   dateFormat: "dd/MM/yyyy",
  // },
  {
    ISO: CurrencyIso.BRL,
    name: "BRL",
    sign: CurrencySign.BRL,
    signPosition: CurrencySignPosition.BEFORE,
    separator: CurrencySeparator.POINT,
    dateFormat: "dd/MM/yyyy",
  },
  // {
  //   ISO: CurrencyIso.LKR,
  //   name: "LKR",
  //   sign: CurrencySign.LKR,
  //   signPosition: CurrencySignPosition.AFTER,
  //   separator: CurrencySeparator.POINT,
  //   dateFormat: "dd/MM/yyyy",
  // },
];

const currencyList: Currency[] = list.map((data) => new Currency(data));

const currencyMap = new Map<number, Currency>(
  currencyList.map((currency) => [currency.ISO, currency])
);

function getCurrency(id: number): Currency {
  return currencyMap.get(id) || unknownCurrency;
}

export { currencyList, currencyMap, getCurrency, unknownCurrency };
export { CurrencySignPosition };

export default Currency;
