/* eslint-disable max-len */

import { Icon } from 'types';

function RegisterIcon({ color = '#3959D9', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.64346 9.14721C8.22818 6.50076 11.0609 4.75 14.2808 4.75C19.2144 4.75 23.25 8.87109 23.25 14C23.25 19.1289 19.2144 23.25 14.2808 23.25C11.0609 23.25 8.22818 21.4992 6.64346 18.8528C6.43066 18.4974 5.97006 18.3818 5.61469 18.5946C5.25932 18.8074 5.14374 19.268 5.35654 19.6234C7.19368 22.6914 10.4984 24.75 14.2808 24.75C20.0828 24.75 24.75 19.9168 24.75 14C24.75 8.08322 20.0828 3.25 14.2808 3.25C10.4984 3.25 7.19368 5.30862 5.35654 8.3766C5.14374 8.73197 5.25932 9.19256 5.61469 9.40536C5.97006 9.61816 6.43066 9.50259 6.64346 9.14721ZM11 16C11 16.412 11.4704 16.6472 11.8 16.4L14.4667 14.4C14.7333 14.2 14.7333 13.8 14.4667 13.6L11.8 11.6C11.4704 11.3528 11 11.588 11 12V13.25H4C3.58579 13.25 3.25 13.5858 3.25 14C3.25 14.4142 3.58579 14.75 4 14.75H11V16Z"
                fill={color}
            />
        </svg>
    );
}

export default RegisterIcon;
