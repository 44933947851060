import GeneralInfo from './GeneralInfo/GeneralInfo';
import Licence from './Licence/Licence';
import Partners from './Partners/Partners';

import './About.scss';

function About() {
    return (
        <div className="about-company">
            <GeneralInfo />
            <Licence />
            <Partners />
        </div>
    );
}

export default About;
