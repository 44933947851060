import { translate as t } from 'service/Language';

import { BlockType } from '../service/BlockAviator';

import './ItemBlock.scss';

type ItemBlockProps = {
    list: BlockType[];
};

function ItemBlock({ list }: ItemBlockProps) {
    return (
        <div className="item-block">
            <div className="item-block-column">
                {list.map((item) => (
                    <img className="item-block-column__logo" src={item.logo} />
                ))}
            </div>
            <div className="item-block-column">
                {list.map((item) => (
                    <div className="item-block-column__title">{t(item.title)}</div>
                ))}
            </div>
        </div>
    );
}

export default ItemBlock;
