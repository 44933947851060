import RouteConfig from 'appConstants/RouteConfig';
import { useMemo, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { translate as t } from 'service/Language';
import useAspectRatio from 'utils/useAspectRatio';

import GameHelper from '../../../../service/GameHelper';
import GoogleService from '../../../../service/Google';
import GameProvider from '../../../../service/model/GameProvider';
import { useIsDesktop } from '../../../../utils/useBreakpoints';
import GamePreview from './GamePreview';

import './ProviderCard.scss';

const GAMES_PREVIEW_COUNT = 3;

type ProviderCardProps = {
    provider: GameProvider;
};

function ProviderCard(props: ProviderCardProps) {
    const { provider } = props;

    const isDesktop = useIsDesktop();

    const randomGames = useMemo(() => {
        const shuffled = [...provider.gameList].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, GAMES_PREVIEW_COUNT);
    }, [provider.gameList]);

    const cardRef = useRef<HTMLAnchorElement>(null);
    useAspectRatio({ ratio: 1.7, element: cardRef });

    const gameCount = provider.gameList.filter((game) => GameHelper.matchPlatform(game, isDesktop)).length;

    return (
        <NavLink
            to={`${RouteConfig.casino.providers}/${provider.route}`}
            className="provider-card"
            ref={cardRef}
            onClick={() => GoogleService.choiceProvider(provider.name)}
        >
            <img className="provider-card__logo" src={provider.image} alt={provider.name} />
            <div className="provider-card__games-count">
                {gameCount} {t('casino.providers.games')}
            </div>
            <div className="provider-card__preview-container">
                {randomGames.map((game) => (
                    <GamePreview game={game} key={game.id} />
                ))}
            </div>
        </NavLink>
    );
}

export default ProviderCard;
