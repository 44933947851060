// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ChannelTypeCripto } from '@ay_tsarbet/newbet-core/dist/types';
import { SystemTypes } from 'enums/payment';
import { unknownCurrency } from '../../../../service/model/Currency';
import CriptoBTC from '../../../../assets/payIcons/crypto/BTC.svg';
import CriptoETH from '../../../../assets/payIcons/crypto/ETH.svg';
import CriptoUSDTT from '../../../../assets/payIcons/crypto/USDTT.svg';
import CriptoUSDTE from '../../../../assets/payIcons/crypto/USDTE.svg';
import CriptoBNB from '../../../../assets/payIcons/crypto/BNB.svg';
import CriptoXRP from '../../../../assets/payIcons/crypto/XRP.svg';
import CriptoUSDC from '../../../../assets/payIcons/crypto/USDC.svg';
import CriptoADA from '../../../../assets/payIcons/crypto/ADA.svg';
import CriptoDOGE from '../../../../assets/payIcons/crypto/DOGE.svg';
import CriptoTRX from '../../../../assets/payIcons/crypto/TRX.svg';
import CriptoDAI from '../../../../assets/payIcons/crypto/DAI.svg';
import CriptoLTC from '../../../../assets/payIcons/crypto/LTC.svg';
import CriptoBCH from '../../../../assets/payIcons/crypto/BCH.svg';
import CriptoBUSD from '../../../../assets/payIcons/crypto/BUSD.svg';
import CriptoBNB_BSC from '../../../../assets/payIcons/crypto/BNB_BSC.svg';
import CriptoERC20 from '../../../../assets/payIcons/crypto/ERC20.svg';
import CriptoCSC from '../../../../assets/payIcons/crypto/CSC.svg';
import CriptoSNACK from '../../../../assets/payIcons/crypto/SNACK.svg';
import CriptoVERSE from '../../../../assets/payIcons/crypto/VERSE.svg';


export const cryptoPaymentMethod  = {
    BTC: {
        id: ChannelTypeCripto.BTC,
        icon: CriptoBTC,
        title: 'BTC',
        slug: 'bitcoin',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    ETH: {
        id: ChannelTypeCripto.ETH,
        icon: CriptoETH,
        title: 'ETH',
        slug: 'ethereum',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    USDTT: {
        id: ChannelTypeCripto.USDTT,
        icon: CriptoUSDTT,
        title: 'USDTT (TRC20)',
        slug: 'tether',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    USDTE: {
        id: ChannelTypeCripto.USDTE,
        icon: CriptoUSDTE,
        title: 'USDTE (ERC20)',
        slug: 'tether',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    BNB: {
        id: ChannelTypeCripto.BNB,
        icon: CriptoBNB,
        title: 'BNB',
        slug: 'binancecoin',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    XRP: {
        id: ChannelTypeCripto.XRP,
        icon: CriptoXRP,
        title: 'XRP',
        slug: 'casinocoin',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    USDC: {
        id: ChannelTypeCripto.USDC,
        icon: CriptoUSDC,
        title: 'USDC',
        slug: 'usdcoin',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    ADA: {
        id: ChannelTypeCripto.ADA,
        icon: CriptoADA,
        title: 'ADA',
        slug: 'cardano',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    DOGE: {
        id: ChannelTypeCripto.DOGE,
        icon: CriptoDOGE,
        title: 'DOGE',
        slug: 'dogecoin',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    TRX: {
        id: ChannelTypeCripto.TRX,
        icon: CriptoTRX,
        title: 'TRX',
        slug: 'tron',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    DAI: {
        id: ChannelTypeCripto.DAI,
        icon: CriptoDAI,
        title: 'DAI',
        slug: 'dai',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    LTC: {
        id: ChannelTypeCripto.LTC,
        icon: CriptoLTC,
        title: 'LTC',
        slug: 'litecoin',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    BCH: {
        id: ChannelTypeCripto.BCH,
        icon: CriptoBCH,
        title: 'BCH',
        slug: 'bitcoincash',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    BUSD: {
        id: ChannelTypeCripto.BUSD,
        icon: CriptoBUSD,
        title: 'BUSD',
        slug: 'binanceusd',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    BNB_BSC: {
        id: ChannelTypeCripto.BNB_BSC,
        icon: CriptoBNB_BSC,
        title: 'BNB_BSC',
        slug: 'binancecoin',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    ERC20: {
        id: ChannelTypeCripto.ERC20,
        icon: CriptoERC20,
        title: 'ERC20',
        slug: 'tether',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    CSC: {
        id: ChannelTypeCripto.CSC,
        icon: CriptoCSC,
        title: 'CSC',
        slug: 'casinocoin',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    SNACK: {
        id: ChannelTypeCripto.SNACK,
        icon: CriptoSNACK,
        title: 'SNACK',
        slug: 'snack',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
    VERSE: {
        id: ChannelTypeCripto.VERSE,
        icon: CriptoVERSE,
        title: 'VERSE',
        slug: 'veritaseum',
        currency: unknownCurrency,
        requireEmail: false,
        getLink: (): string => '',
        system: SystemTypes.COINSPAID,
        limit: {
            deposit: { min: 0, max: 0 },
            withdraw: { min: 0, max: 0 },
        },
        amountPreset: [],
        defaultValue: '0',
    },
};

export default cryptoPaymentMethod;
