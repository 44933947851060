import RouteConfig from 'appConstants/RouteConfig';
import { useEffect, useRef, useState } from 'react';
import AuthService from 'service/Auth';
import GamesService from 'service/Games';
import GoogleService from 'service/Google';
import { HistoryService } from 'service/HistoryService';
import LanguageService from 'service/Language';
import useObservable from 'utils/useObservable';

import PersonalDataFormService from '../Account/PersonalData/service/PersonalDataForm';

import './Betting.scss';
import { setHostname } from 'utils/setHostname';
import UserDataService from 'service/UserData';

type GoogleEventMessage = {
    event: string;
    category: string | null;
};

enum IFRAME_MESSAGES_TYPES {
    SocketUrl = 'socketUrl',
    SetLanguage = 'setLanguage',
    LoginClient = 'loginClient',
    LogoutClient = 'logoutClient',
    GoogleEvent = 'google-event',
}

enum IFRAME_MESSAGES_RESPONSE_STATUS {
    Redirect = 'redirect',
    Connected = 'connected',
    CommandStatus = 'commandStatus',
    Ready = 'ready',
    Error = 'error',
}

enum REDIRECT_MESSAGES {
    casino = 'casino',
    aviator = 'aviator',
}

enum ERROR_MESSAGES {
    notAuth = 'need to authorization',
    permissions = '403 forbidden',
    balance = 'balance not valid',
}

enum DEPOSIT_MESSAGES {
    open = 'openDeposit',
}

const setUrl = () => {
    const host =
        window.location.host.includes('localhost') || window.location.host.includes('test')
            ? 'glory.casino'
            : window.location.host;
    return `https://betting.${host}`;
};

const BettingPage = () => {
    const iframeRef = useRef(null);
    const isFirstRun = useRef(true);

    const userData = useObservable(
        PersonalDataFormService.userData,
        PersonalDataFormService.userData.getValue(),
    );
    const language = useObservable(LanguageService.current, LanguageService.current.getValue());
    const isAuth = useObservable(AuthService.auth, AuthService.auth.getValue());
    const currency = UserDataService.data.getValue()?.currency.name;

    const [isSocketAuthorized, setIsSockerAuthorized] = useState(false);

    const loginClient = () => {
        // eslint-disable-next-line
        // @ts-ignore eslint-disable-next-line
        const iframe = iframeRef.current.contentWindow;
        const accessToken = localStorage.getItem('accessToken')?.replaceAll('"', '');
        const refreshToken = localStorage.getItem('refreshToken')?.replaceAll('"', '');
        setTimeout(() => {
            iframe.postMessage(
                {
                    type: IFRAME_MESSAGES_TYPES.LoginClient,
                    message: {
                        accessToken,
                        refreshToken,
                    },
                },
                setUrl(),
            );
            setIsSockerAuthorized(true);
        }, 100);
    };

    const logoutClient = () => {
        // eslint-disable-next-line
        // @ts-ignore eslint-disable-next-line
        const iframe = iframeRef.current.contentWindow;
        setTimeout(() => {
            iframe.postMessage(
                {
                    type: IFRAME_MESSAGES_TYPES.LogoutClient,
                    message: 'logout',
                },
                setUrl(),
            );
        }, 100);
    };

    const setSocket = () => {
        // eslint-disable-next-line
        // @ts-ignore eslint-disable-next-line
        const iframe = iframeRef.current.contentWindow;
        if (iframe && iframe.postMessage) {
            setTimeout(() => {
                iframe.postMessage(
                    {
                        type: IFRAME_MESSAGES_TYPES.SocketUrl,
                        // eslint-disable-next-line
                        // @ts-ignore eslint-disable-next-line
                        message: setHostname().replace('/casino', ''),
                    },
                    setUrl(),
                );
            }, 100);
        }
    };

    const setLanguage = () => {
        // eslint-disable-next-line
        // @ts-ignore eslint-disable-next-line
        const iframe = iframeRef.current.contentWindow;
        if (iframe && iframe.postMessage) {
            setTimeout(() => {
                iframe.postMessage(
                    {
                        type: IFRAME_MESSAGES_TYPES.SetLanguage,
                        message: language.title.toLowerCase(),
                    },
                    setUrl(),
                );
            }, 100);
        }
    };

    const parseMessages = (event: any) => {
        if (event.origin !== window.location.origin) {
            console.log('event:', event.data);
            if (event.data.type === IFRAME_MESSAGES_RESPONSE_STATUS.Ready) {
                if (userData) {
                    setSocket();
                }
            }
            if (event.data.type === IFRAME_MESSAGES_RESPONSE_STATUS.Connected) {
                const accessToken = localStorage.getItem('accessToken');
                const refreshToken = localStorage.getItem('refreshToken');
                if (accessToken && refreshToken) {
                    loginClient();
                }
            }
            if (event.data.type === IFRAME_MESSAGES_RESPONSE_STATUS.Redirect) {
                if (event.data.message === REDIRECT_MESSAGES.casino) {
                    GoogleService.viewMainPage(userData.id ? userData.id.toString() : '');
                    HistoryService.history.push('/');
                }
                if (event.data.message === REDIRECT_MESSAGES.aviator) {
                    GamesService.loadAviatorGame();
                }
                if (event.data.message === DEPOSIT_MESSAGES.open) {
                    HistoryService.setHash(RouteConfig.hash.deposit);
                }
            }
            if (event.data.type === IFRAME_MESSAGES_RESPONSE_STATUS.Error) {
                if (event.data.message === ERROR_MESSAGES.balance) {
                    HistoryService.setHash(RouteConfig.hash.deposit);
                }
                if (event.data.message === ERROR_MESSAGES.notAuth) {
                    HistoryService.setHash(RouteConfig.hash.auth);
                }
            }
            if (event.data.type === IFRAME_MESSAGES_TYPES.GoogleEvent) {
                const message: GoogleEventMessage = JSON.parse(event.data.message);
                GoogleService.bettingEvent({ ...message, currency: currency });
            }
        }
    };

    useEffect(() => {
        window.addEventListener('message', parseMessages, false);
        return () => {
            window.removeEventListener('message', parseMessages);
        };
    }, []);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if (!isFirstRun.current) {
            setLanguage();
        }
    }, [language]);

    useEffect(() => {
        console.log('isAuth, isSocketAuthorized', isAuth, isSocketAuthorized);
        if (!isAuth && isSocketAuthorized) {
            logoutClient();
        }
        if (isAuth && !isSocketAuthorized) {
            loginClient();
        }
    }, [isAuth]);

    const setHeight = () => {
        const windowHeight = window.innerHeight;
        const result = windowHeight - 70;
        return `${result > 900 ? 900 : windowHeight - 70}px`;
    };

    return (
        <div className='BettingPage'>
            <iframe
                title='betting'
                src={`${setUrl()}/?lang=${localStorage
                    .getItem('language')
                    ?.replaceAll('"', '')
                    ?.toLowerCase()}`}
                style={{ width: '100%', height: setHeight(), border: 'none' }}
                ref={iframeRef}
            />
        </div>
    );
};

export default BettingPage;
