import { useEffect } from 'react';

import useObservable from '../../../../../../utils/useObservable';
import { PaymentMethod } from '../../../PaymentMethod/types';
import Balance from '../../Balance/Balance';
import ResultForm from '../ResultForm/ResultForm';
import PaykassmaWithdrawService, { PaykassmaWithdrawState } from '../service/PaykassmaWithdraw';
import Easypaisa from './Easypaisa/Easypaisa';
import IMPS from './IMPS/IMPS';
import Phone from './Phone/Phone';
import Upay from './Upay/Upay';
import Jazzcash from './Jazzcash/Jazzcash';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const status = useObservable(PaykassmaWithdrawService.state, PaykassmaWithdrawService.state.getValue());

    useEffect(() => {
        PaykassmaWithdrawService.reset();
    }, []);

    if (status === PaykassmaWithdrawState.INIT || status === PaykassmaWithdrawState.HAS_FEE || status === PaykassmaWithdrawState.AWAIT) {
        if (paymentMethod.id === ChannelType.IMPS) {
            return (
                <>
                    <Balance />
                    <IMPS paymentMethod={paymentMethod} status={status} />
                </>
            );
        }
        if (paymentMethod.id === ChannelType.Easypaisa_2) {
            return (
                <>
                    <Balance />
                    <Easypaisa paymentMethod={paymentMethod} status={status} />
                </>
            );
        }
        if (paymentMethod.id === ChannelType.Upay || paymentMethod.id === ChannelType.Banktransfer || paymentMethod.id === ChannelType.Ipay) {
            return (
                    <Upay paymentMethod={paymentMethod} status={status} />
            );
        }
        if (paymentMethod.id === ChannelType.JAZZCASH_PAYKASSMA)  {
            return (
                    <Jazzcash paymentMethod={paymentMethod} status={status} />
            );
        } else {
            return (
                <>
                    <Balance />
                    <Phone paymentMethod={paymentMethod} status={status} />
                </>
            );
        }
    } else {
        return <ResultForm />;
    }
}

export default Form;
