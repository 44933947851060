import { SlotegratorGameMode } from '@ay_tsarbet/newbet-core/dist/connection/actions/actionEnums';
import { useEffect, useRef, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import RouteConfig from '../../appConstants/RouteConfig';
import AuthService from '../../service/Auth';
import BalanceService from '../../service/Balance';
import GameHelper from '../../service/GameHelper';
import GamesService from '../../service/Games';
import { unknownCurrency } from '../../service/model/Currency';
import Storage from '../../service/Storage';
import UserDataService from '../../service/UserData';
import { formatCurrencySign } from '../../utils/format';
import useObservable from '../../utils/useObservable';
import FavoriteButton from './FavoriteButton/FavoriteButton';
import back from './image/back_icon.svg';
import button from './image/button_icon.svg';
import logo from './image/casino_icon.svg';

import './GamePage.scss';

type frameType = {
    width: number;
    height: number;
};

export type StorageGame = {
    gameId: number;
    mode: number;
};

function GamePage() {
    const gameUrl = useObservable(GamesService.currentGameUrl, GamesService.currentGameUrl.getValue());
    const balance = useObservable(BalanceService.balance, BalanceService.balance.getValue());
    const userData = useObservable(UserDataService.data, UserDataService.data.getValue());
    const history = useHistory();
    const gameList = useObservable(GamesService.games, GamesService.games.getValue());
    const loadingGame = useObservable(GamesService.loadingGame, GamesService.loadingGame.getValue());
    const providerList = useObservable(GamesService.providers, GamesService.providers.getValue());
    const isAuth = useObservable(AuthService.auth, AuthService.auth.getValue());
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const location = useLocation();
    const isLoadedGame = useObservable(GamesService.loadedGame, GamesService.loadedGame.getValue());

    const currency = userData ? userData.currency : unknownCurrency;

    useEffect(() => {
        // Костыль для сафари
        setTimeout(() => {
            window.scrollTo(0, 1);
        }, 100);
        return ()=> {
            // GamesService.resetGameLoaded();
        }
    }, []);

    useEffect(() => {
        const storageGame: StorageGame = Storage.get('currentGameId') as StorageGame;
        if (storageGame.mode === SlotegratorGameMode.REAL) {
            if (isAuth && balance && !loadingGame && !isLoadedGame) {
                console.log('GamePage_useEffect')
                const currentGame = GamesService.findGame(storageGame.gameId);
                GameHelper.loadGame(currentGame, SlotegratorGameMode.REAL, null);
            }
        } else {
            if (gameList.length && providerList.length && !loadingGame && !isLoadedGame) {
                const currentGame = GamesService.findGame(Number(storageGame.gameId));
                GameHelper.loadGame(currentGame, SlotegratorGameMode.DEMO, null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth, gameList, providerList, isLoadedGame]);

    function goBack() {
        history.push(RouteConfig.casino.root);
    }

    return (
        <div className="game-page">
            <iframe className="game-page__iframe" src={gameUrl} ref={iframeRef} />
            <div className="game-page__bottom-panel">
                <div onClick={goBack} className="game-page__go-back">
                    <img src={back} alt="back" />
                    <img src={logo} alt="logo" />
                </div>
                <div className="game-page__info">
                    {isAuth ? (
                        <>
                            <div className="game-page__text">{formatCurrencySign(balance, 2, currency)}</div>
                            <NavLink
                                className="game-page__deposit"
                                to={{ hash: RouteConfig.hash.deposit, search: location.search }}
                            >
                                <img src={button} alt="button" />
                            </NavLink>
                            <FavoriteButton />
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default GamePage;
