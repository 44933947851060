import RecoveryService from '../../../service/Recovery';
import EmailInputService from './EmailInput';

class RecoveryForm {
    submit() {
        RecoveryService.byEmail(EmailInputService.getValue());
    }
}

const RecoveryFormService = new RecoveryForm();

export default RecoveryFormService;
