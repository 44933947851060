import { InstapayDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendInstapayDepositRequest';
import { InstapayWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendInstapayWithdrawRequest';

import InstapayInterface from '../../interface/payment/Instapay';

interface InstapayHandler {
    deposit(request: InstapayDepositRequest): void;
    withdraw(request: InstapayWithdrawRequest): void;
}

class InstapayProvider implements InstapayInterface {
    handler: InstapayHandler;

    constructor(handler: InstapayHandler) {
        this.handler = handler;
    }

    deposit(request: InstapayDepositRequest): void {
        this.handler.deposit(request);
    }

    withdraw(request: InstapayWithdrawRequest): void {
        this.handler.withdraw(request);
    }
}

export type { InstapayHandler };
export default InstapayProvider;
