import { translate as t } from 'service/Language';
import UIService from 'service/UI';

import PopupService from '../../../../../service/Popup';

import './WheelWinGirl.scss';

type WheelWinProps = {
    spin: () => void;
};

function WheelWinGirl({ spin }: WheelWinProps) {
    const spinAgain = () => {
        UIService.unlockScroll();
        PopupService.close();
        spin();
    };
    return (
        <div className="win-girl">
            <div className="win-girl__header">{t('wheel.popup.again.header')}</div>
            <div className="win-girl__description">{t('wheel.popup.again.desc')}</div>
            <div className="win-girl__title">{t('wheel.popup.again.win')}</div>
            <div className="win-girl__again">{t('wheel.popup.again.win.desc')}</div>
            <button className="win-girl__button" onClick={spinAgain}>
                {t('wheel.popup.again.button')}
            </button>
            <div className="win-girl__things" />
        </div>
    );
}

export default WheelWinGirl;
