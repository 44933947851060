import { useEffect } from 'react';
import { translate as t } from 'service/Language';

import Input from '../../../../../component/Input/Input';
import PasswordFormService from '../service/PasswordFormService';
import NewPasswordService from './service/NewPassword';

import './NewPasswordInput.scss';

function NewPasswordInput() {
    useEffect(() => {
        const newPassword = NewPasswordService.value.subscribe(PasswordFormService.handler.onNewPasswordChange);

        return () => {
            newPassword.unsubscribe();
        };
    }, []);

    return (
        <div className="new-password-input">
            <span className="new-password-input__header">{t('profile.account.personal.change.password')}</span>
            <Input inputService={NewPasswordService} className="new-password-input__field" type="password" />
        </div>
    );
}

export default NewPasswordInput;
