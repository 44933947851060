import { PaykassmaWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaWithdraw';
import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import AuthService from 'service/Auth';
import GoogleService from 'service/Google';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';

import { translate as t } from '../../../../../../service/Language';
import { BankCodeService } from '../../../../../../service/Validator/BankCode';
import { OnlyLetterService } from '../../../../../../service/Validator/OnlyLetter';
import { CheckPersonalNameService } from '../../../../../../service/Validator/PersonalName';
import paymentMethod from '../../../PaymentMethod/methods';
import { PaymentMethod } from '../../../PaymentMethod/types';
import WithdrawService from '../../service/Withdrawal';
import PaykassmaWithdrawService, { PaykassmaWithdrawState } from './PaykassmaWithdraw';
import BonusService from '../../../../../../service/Bonus';
import StatusFormPopupService, {StatusForm} from '../../../Deposit/DepositPopup/service/StatusForm';

enum IMPSFormError {
    NOERROR,
    NAME,
}

class IMPSForm {
    paymentMethod: PaymentMethod = paymentMethod.IMPS;

    limitValidator = new Validator.limit(0, 1);

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.withdraw.input.amount',
    });

    accountNumber = new InputService({
        title: 'popup.payment.imps.bill',
        validator: Validator.nonEmpty,
        validatorText: 'popup.payment.easypaisa.wallet.error',
    });

    accountName = new InputService({
        title: 'popup.payment.imps.name',
        validator: Validator.personalName,
        validatorText: 'popup.payment.imps.name',
    });

    bankCode = new InputService({
        title: 'popup.payment.imps.code',
        validator: Validator.backCode,
        validatorText: 'popup.payment.imps.code.error',
    });

    valid = new BehaviorSubject<boolean>(false);

    errorTip = new BehaviorSubject<IMPSFormError>(IMPSFormError.NOERROR);

    error = new BehaviorSubject<string>('');

    handler = {
        onAmount: this.onAmount.bind(this),
        onAccountNumber: this.onAccountNumber.bind(this),
        onName: this.onName.bind(this),
        onBankCode: this.onBankCode.bind(this),
        onNameActive: this.onNameActive.bind(this),
        onActive: this.onActive.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onActive);
        this.accountNumber.value.subscribe(this.handler.onAccountNumber);
        this.accountNumber.active.subscribe(this.handler.onActive);
        this.accountName.value.subscribe(this.handler.onName);
        this.accountName.active.subscribe(this.handler.onNameActive);
        this.bankCode.value.subscribe(this.handler.onBankCode);
        this.bankCode.active.subscribe(this.handler.onActive);
    }

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        const limit = this.paymentMethod.limit.withdraw;
        const isFee = WithdrawService.fee.getValue();
        const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
        this.limitValidator.setLimit(isFee ? isMin : limit.min, limit.max);
    }

    onActive() {
        this.errorTip.next(IMPSFormError.NOERROR);
    }

    onAmount(value: string) {
        const isValue = this.amount.value.getValue();
       if (isValue) {
            if(!value.indexOf('0', 0)) {
                const current = value.length;
                const done = value.slice(0, current - 1);
                this.amount.value.next(done);
            }
            const isFee = WithdrawService.fee.getValue();
            const isMin = this.paymentMethod.limit.withdraw.min * 1.25;
            if(isFee && +isValue < isMin) {
                this.amount.error.next('profile.balance.withdraw.input.amount');
                this.error.next( 'popup.payment.min.fee')
            } else {
               this.amount.error.next(null);
               this.error.next('');
               this.checkValid();
           }
        } else {
           this.amount.error.next(null);
           this.error.next('');
           this.checkValid();
        }
    }

    onAccountNumber(value: string) {
        this.checkValid();
    }

    onName(value: string) {
        this.checkValid();
        if (this.accountName.getValue() && !CheckPersonalNameService.validate(value)) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.accountName.value.next(name);
        }
    }

    onBankCode(value: string) {
        if (this.bankCode.getValue()) {
            if (value.length < 5 && !OnlyLetterService.validate(value)) {
                const current = value.length;
                const name = value.slice(0, current - 1);
                this.bankCode.value.next(name);
                this.bankCode.error.next(t('popup.payment.imps.code.error'));
                this.valid.next(false);
            }
            if (value.length === 5 && !BankCodeService.numberCheck(value)) {
                const current = value.length;
                const name = value.slice(0, current - 1);
                this.bankCode.value.next(name);
                this.bankCode.error.next(t('popup.payment.imps.code.error'));
            }
            if (value.length > 5 && !BankCodeService.symbolCheck(value)) {
                const current = value.length;
                const name = value.slice(0, current - 1);
                this.bankCode.value.next(name);
                this.bankCode.error.next(t('popup.payment.imps.code.error'));
            }
            if (value.length > 11) {
                const current = value.length;
                const name = value.slice(0, current - 1);
                this.bankCode.value.next(name);
            } else {
                this.checkValid();
                this.bankCode.error.next(null);
            }
        }
    }

    onNameActive(active: boolean) {
        if (active) {
            this.errorTip.next(IMPSFormError.NAME);
        } else {
            this.errorTip.next(IMPSFormError.NOERROR);
        }
    }

    checkValid() {
        const isAmount = this.amount.isValid();
        const isAccountName = this.accountName.getValue() && this.accountName.isValid();
        const isAccountNumber = this.accountNumber.isValid();
        const isBank = this.bankCode.isValid();
        if (isAmount && isAccountNumber && isAccountName && isBank) {
            this.valid.next(true);
        } else this.valid.next(false);
    }

    send() {
        if (this.valid.getValue() && this.paymentMethod) {
            const currencyId = this.paymentMethod.currency.ISO;
            const channelType = this.paymentMethod.id;
            const accountNumber = this.accountNumber.getValue();
            const accountName = this.accountName.getValue().trim();
            const bankCode = this.bankCode.getValue();
            const amount = parseInt(this.amount.getValue());
            const request: PaykassmaWithdrawRequest = {
                currencyId,
                channelType,
                accountNumber,
                accountName,
                bankCode,
                amount,
            };
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            GoogleService.sentWithdraw(
                userId,
                this.paymentMethod.title,
                amount.toString(),
                this.paymentMethod.currency.name,
                request.accountNumber
            );
            console.log('send', request);
            PaymentService.paykassma.withdraw(request);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();
        const isBonuses = BonusService.list.getValue().length;

        if (isBonuses) {
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.BONUS_REJECT);
            StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
        } else {
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.AWAIT);
            this.send();
        }
    }

    reset() {
        this.amount.value.next('');
        this.accountNumber.value.next('');
        this.accountName.value.next('');
        this.bankCode.value.next('');

        this.amount.error.next(null);
        this.accountNumber.error.next(null);
        this.accountName.error.next(null);
        this.bankCode.error.next(null);

        this.amount.active.next(false);
        this.accountNumber.active.next(false);
        this.accountName.active.next(false);
        this.bankCode.active.next(false);

        this.error.next('');
    }
}

const IMPSFormService = new IMPSForm();
export { IMPSFormError };
export default IMPSFormService;
