import colors from 'appConstants/colors';
import classNames from 'classnames';
import { useState } from 'react';
import Collapsible from 'react-collapsible';
import { translate as t } from 'service/Language';
import SvgImage from 'svg';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import { PaymentMethod } from '../../../../PaymentMethod/types';

import './VideoInstruction.scss';

type VideoInstructionProps = {
    method: PaymentMethod;
};

enum VideoInstructionIdEnum {
    BKash = 'https://diswdgcu9cfva.cloudfront.net/video/bkash.mp4',
    BKashP2C = 'https://diswdgcu9cfva.cloudfront.net/video/bkashP2C.mp4',
    NagadP2C = 'https://diswdgcu9cfva.cloudfront.net/video/nagadP2C.mp4',
    Rocket = 'https://diswdgcu9cfva.cloudfront.net/video/rocket.mp4',
    CepBank = 'https://diswdgcu9cfva.cloudfront.net/video/cepbank.mp4',
    PhonePe = 'https://diswdgcu9cfva.cloudfront.net/video/phonePe.mp4',
    PayTM = 'https://diswdgcu9cfva.cloudfront.net/video/payTM.mp4',
    PaykassmaEasyPaisa = 'https://diswdgcu9cfva.cloudfront.net/video/paykassma_EasyPaisa.mp4',
    Upi = 'https://diswdgcu9cfva.cloudfront.net/video/upi.mp4',
    SmartPay = 'https://diswdgcu9cfva.cloudfront.net/video/smartpay.mp4',
}

const VideoInstruction = (props: VideoInstructionProps) => {
    const { method } = props;
    const [isOpen, setIsOpen] = useState(false);

    const classToggleIcon = classNames('VideoInstruction__toggle_icon', {
        open: isOpen,
    });

    let videoId = '';
    if (method.id === ChannelType.bKash) {
        videoId = VideoInstructionIdEnum.BKash;
    }
    if (method.id === ChannelType.BKASH_API) {
        videoId = VideoInstructionIdEnum.BKashP2C;
    }
    if (method.id === ChannelType.NAGAD_API) {
        videoId = VideoInstructionIdEnum.NagadP2C;
    }
    if (method.id === ChannelType.Rocket) {
        videoId = VideoInstructionIdEnum.Rocket;
    }
    if (method.id === ChannelType.TRY_CEP_BANK) {
        videoId = VideoInstructionIdEnum.CepBank;
    }
    if (method.id === ChannelType.PhonePe) {
        videoId = VideoInstructionIdEnum.PhonePe;
    }
    if (method.id === ChannelType.PayTM) {
        videoId = VideoInstructionIdEnum.PayTM;
    }
    if (method.id === ChannelType.Easypaisa_2) {
        videoId = VideoInstructionIdEnum.PaykassmaEasyPaisa;
    }
    if (method.id === ChannelType.UPI) {
        videoId = VideoInstructionIdEnum.Upi;
    }
    if (method.id === ChannelType.PAYES_SMART_PAY) {
        videoId = VideoInstructionIdEnum.SmartPay;
    }

    return (
        <div className="VideoInstruction">
            <button className="VideoInstruction__toggle" onClick={() => setIsOpen(!isOpen)}>
                {t('profile.balance.deposit.video.hint')}
                <div className={classToggleIcon}>
                    <SvgImage color={colors.royalBlue} width={16} height={16} name="RightFlatArrow" />
                </div>
            </button>
            <Collapsible trigger="" open={isOpen}>
                <video controls className="VideoInstruction__video">
                    <source src={videoId}
                            type="video/mp4"/>
                </video>
            </Collapsible>
        </div>
    );
};

export default VideoInstruction;
