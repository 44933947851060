import { translate as t } from 'service/Language';

import build from './build.svg';
import stars from './stars.svg';

import './GeneralInfo.scss';

function GeneralInfo() {
    return (
        <div className="general-info">
            <span className="general-info__title">{t('info.about.general.header')}</span>
            <div className="general-info__card">
                <div className="general-row">
                    <img alt="icon" src={build} />
                    <span className="general-row__title">
                        <span className="general-row__title__header">{t('info.about.general.name')}</span>
                        {t('info.about.general.name.description')}
                    </span>
                    <div className="general-row__divider" />
                </div>
                <div className="general-row">
                    <img alt="icon" src={stars} />
                    <span className="general-row__title">
                        <p className="general-row__title__header">{t('info.about.general.whyname')}</p>
                        {t('info.about.general.whyname.description')}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default GeneralInfo;
