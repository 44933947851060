import classnames from 'classnames';
import { createRef } from 'react';
import { translate as t } from 'service/Language';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import Input from '../../../../../../../component/Input/Input';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import { DepositState, InvoiceState } from '../../service/PaykassmaDeposit';
import TransactionFromService, { TransactionError } from '../../service/TransactionForm';
import Submit from '../Button/Submit';
import ErrorTip from './ErrorTip/ErrorTip';
import question from './image/tip.svg';
import { formatCurrencySign } from '../../../../../../../utils/format';

import './Transaction.scss';


type TransactionProps = {
    paymentMethod: PaymentMethod;
    showHint?: boolean;
    state: DepositState;
    transactionState?: InvoiceState;
};

type TransactionState = {
    tip: boolean;
    errorTip: TransactionError;
};

class Transaction extends RxComponent<TransactionProps, TransactionState> {
    state = {
        tip: false,
        errorTip: TransactionError.NOERROR,
    };

    handler = {
        toggleTip: this.toggleTip.bind(this),
        clickOutside: this.clickOutside.bind(this),
        onErrorTip: this.onErrorTip.bind(this),
    };

    ref = {
        tip: createRef<HTMLButtonElement>(),
        screen: createRef<HTMLDivElement>(),
    };

    onErrorTip(errorTip: TransactionError) {
        if (errorTip === TransactionError.NOERROR) {
            this.setState({ errorTip });
        } else {
            this.setState({ errorTip });
        }
    }

    toggleTip() {
        const {tip} = this.state;
        if (tip) {
            this.hideTip();
        } else {
            this.showTip();
        }
    }

    showTip() {
        this.setState({tip: true}, () => {
            const screenRect = this.ref.screen.current?.getBoundingClientRect();

            if (screenRect && screenRect?.bottom > window.innerHeight) {
                this.ref.screen.current?.classList.add('top');
            }
        });
        document.addEventListener('pointerdown', this.handler.clickOutside);
    }

    hideTip() {
        this.setState({tip: false});
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    clickOutside(event: PointerEvent) {
        if (this.ref.tip.current && !this.ref.tip.current.contains(event.target as Node)) {
            this.hideTip();
        }
    }

    componentDidMount() {
        const {paymentMethod} = this.props;
        TransactionFromService.reset();
        TransactionFromService.setPaymentMethod(paymentMethod);
        this.subscribe(TransactionFromService.errorTip, {next: this.handler.onErrorTip});
    }

    componentWillUnmount() {
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    render() {
        const { tip, errorTip } = this.state;
        const { paymentMethod, showHint = false, transactionState, state } = this.props;

        const buttonClassName = classnames({
            'deposit-transaction__info': true,
            'deposit-transaction__info__tip': tip,
        });

        let screen = null;
        if (paymentMethod.id === ChannelType.bKash) {
            screen = <div className="deposit-transaction__bkash-screen"/>;
        }
        if (paymentMethod.id === ChannelType.Nagad) {
            screen = <div className="deposit-transaction__nagad-screen"/>;
        }

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        return (
            <form className="deposit-transaction" onSubmit={(e) => TransactionFromService.onSubmit(e)}>
                {paymentMethod.id === ChannelType.PhonePe && state === DepositState.AMOUNT ?
                    <div className="deposit-transaction__input-box">
                        <Input
                            inputService={TransactionFromService.amount}
                            className="deposit-transaction__input"
                            name="amount"
                            type="number"
                            hint={hint}
                            enabled={transactionState === InvoiceState.TRANSACTION}
                        />
                        <span className="deposit-transaction__input__currency">{paymentMethod.currency.sign}</span>
                        {errorTip === TransactionError.AMOUNT ?
                            <span className="deposit-transaction__input__help">
                        {t('popup.payment.transaction.amount.hint')}
                            </span> : null}
                    </div> : null}
                <div className="deposit-transaction__input-box">
                    <Input
                        className="deposit-transaction__input"
                        inputService={TransactionFromService.transaction}
                        name="transaction"
                        type="text"
                        autocomplete="off"
                        isUpperCase
                    />
                    <button
                        ref={this.ref.tip}
                        type="button"
                        className={buttonClassName}
                        onClick={this.handler.toggleTip}
                    >
                        {paymentMethod.id === ChannelType.bKash || paymentMethod.id === ChannelType.Nagad ? (
                            <img src={question} alt=""/>
                        ) : null}
                        <div className="deposit-transaction__tip" ref={this.ref.screen}>
                            {screen}
                        </div>
                    </button>

                    {errorTip === TransactionError.SYMBOL || errorTip === TransactionError.NUMBER  || errorTip === TransactionError.LETTER ? <ErrorTip error={TransactionFromService.errorTip.getValue()}/> : null}
                </div>
                {(transactionState === InvoiceState.TRANSACTION || transactionState === InvoiceState.WAIT) &&
                    showHint && (
                        <div className="deposit-transaction__hint">{t('profile.balance.deposit.transaction.hint')}</div>
                    )}
                <Submit className="deposit-transaction__submit"/>
            </form>
        );
    }
}

export default Transaction;
