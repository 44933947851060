import { translate as t } from 'service/Language';

import Form from '../../Form/Form';
import LoginButton from '../../Form/LoginButton/LoginButton';
import WheelRegistrationFormService from '../../Form/service/registration';
import SignUpButton from '../../Form/SignUpButton/SignUpButton';

import './WheelRegistrationGirl.scss';

function WheelRegistrationGirl() {
    return (
        <div className="girl-registration">
            <div className="girl-registration__things" />
            <div className="girl-registration__top">
                <div className="girl-registration__header">{t('wheel.popup.reg.header')}</div>
                <div className="girl-registration__description">{t('wheel.popup.reg.title')}</div>
                <div className="girl-registration__title">{t('wheel.popup.reg.win')}</div>
            </div>
            <form className="girl-registration__form" onSubmit={(event) => WheelRegistrationFormService.submit(event)}>
                <Form />
                <SignUpButton type="girl" />
                <LoginButton type="girl" />
            </form>
        </div>
    );
}

export default WheelRegistrationGirl;
