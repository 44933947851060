import { BehaviorSubject, Subject } from 'rxjs';
import MpayInterface from '../interface/payment/Mpay';
import WSBet from '../WSBet';
import { MpayDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sentMpayDepositRequest';
import MpayDepositService from '../../page/Profile/Balance/Deposit/Mpay/service/MpayDeposit';
import { MpayDepositResponse } from '@ay_tsarbet/newbet-core/dist/types';

enum MpayError {
    UNKNOWN,
    DEPOSIT,
    WITHDRAW,
}

class Mpay implements MpayInterface {
    error = new Subject<MpayError>();

    redirect = new BehaviorSubject<string | null>(null);

    withdrawResult = new Subject<void>();

    deposit(request: MpayDepositRequest) {
        console.log('MPAY | DEPOSIT REQUEST', request);
        WSBet.payment.mpay.deposit(request);
    }

    onDepositReceive(data: MpayDepositResponse) {
        console.log('MPAY | DEPOSIT RESPONSE', data);
        if (!data.code) {
            MpayDepositService.onReceive(data.message);
        } else {
            MpayDepositService.onError();
        }
    }
}

export { MpayError };
export default Mpay;
