import { NavLink } from 'react-router-dom';
import { translate as t } from 'service/Language';

import RouteConfig from '../../../../appConstants/RouteConfig';

import './HaveAccountSection.scss';

function HaveAccountSection() {
    return (
        <div className="have-account-section">
            <span> {t('registration.account')} </span>
            <NavLink to={{ hash: RouteConfig.hash.auth }} className="have-account-section__link">
                {t('registration.login')}
            </NavLink>
        </div>
    );
}

export default HaveAccountSection;
