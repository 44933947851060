import { translate as t } from 'service/Language';
import PayesDepositService, { DepositState } from '../service/PayesDeposit';
import AmountFormService from '../service/AmountForm';
import Failed from './image/failed.svg';

import './FailedPage.scss';


function FailedPage() {
    function onClickHandler() {
        PayesDepositService.state.next(DepositState.AMOUNT)
        AmountFormService.amount.autoCompleted.next(true);
        AmountFormService.name.autoCompleted.next(true);
        AmountFormService.surname.autoCompleted.next(true);
    }
    return (
        <div className="result-pix">
            <div className="result-pix-container">
                <img className="result-pix-container__img" src={Failed} alt="failed" />
                <p className="result-pix-container__title ">{t('popup.payment.error.title')}</p>
                <span className="result-pix-container__text">{t('popup.payment.error.text')}</span>
                <button className="result-pix-container__btn" onClick={onClickHandler}>
                    {t('popup.payment.error.back')}
                </button>
            </div>
        </div>
    );
}

export default FailedPage;
