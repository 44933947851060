import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import {PaymentMethod} from '../../../PaymentMethod/types';
import Validator from '../../../../../../service/Validator/Validator';
import AuthService from '../../../../../../service/Auth';

export enum ConstantposInputTip {
    NONE,
    AMOUNT,
    VPA,
}

export enum ConstantposError {
    NONE,
    VPA,
}
export enum AmountError {
    NONE,
    AMOUNT_MIN,
    AMOUNT_MAX,
    AMOUNT_WITHDRAWAL,
    AMOUNT_FEE,
}

class AmountForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(1, 1000000);

    valid = new BehaviorSubject<boolean>(false);

    setLimit(min: number, max: number) {
        if (this.limitValidator) {
            this.limitValidator.setLimit(min, max);
        }
    }

    setMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        this.setLimit(paymentMethod.limit.deposit.min, paymentMethod.limit.deposit.max);
    }

    reset() {
        this.valid.next(false);
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        if (this.paymentMethod) {
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
        }
    }
}

const AmountFormService = new AmountForm();

export default AmountFormService;
