import { ListType } from 'enums/payment';
import AuthService from 'service/Auth';
import GoogleService from 'service/Google';
import { translate as t } from 'service/Language';

import DepositService from '../Deposit/service/Deposit';
import WithdrawService from '../Withdrawal/service/Withdrawal';
import { PaymentMethod as PaymentMethodModel } from './types';
import Visa from 'assets/svg/payments/visa.svg';
import { ChannelType, Currency as CurrencyType } from '@ay_tsarbet/newbet-core/dist/types';

import './PaymentMethod.scss';

type PaymentMethodItemProps = {
    paymentMethod: PaymentMethodModel;
    type: ListType;
};

function PaymentMethod(props: PaymentMethodItemProps) {
    const { paymentMethod, type } = props;

    const handlePickMethod = () => {
        const clientInfo = AuthService.auth.getValue();
        if (clientInfo) {
            if (type === ListType.DEPOSIT) {
                GoogleService.pickDepositMethod(clientInfo.userId.toString(), paymentMethod.title);
            } else {
                GoogleService.pickWithdrawMethod(clientInfo.userId.toString(), paymentMethod.title);
            }
        }

        if (type === ListType.DEPOSIT) {
            DepositService.pickMethod(paymentMethod);
        } else {
            WithdrawService.pickMethod(paymentMethod);
        }
    };
    const updatedPaymentMethod = (type === ListType.DEPOSIT && paymentMethod.id === ChannelType.KZT_COREFY && paymentMethod.currency.ISO === CurrencyType.KZT)
        ? { ...paymentMethod, title: 'Visa', icon: Visa }
        : paymentMethod;

    return (
        <button onClick={handlePickMethod} className="payment-method-item">
            <div >
                <img className="payment-method-item__icon" alt="payIcon" src={updatedPaymentMethod.icon} />
            </div>
            <div className="payment-method-item__title">{t(updatedPaymentMethod.title)}</div>
        </button>
    );
}

export default PaymentMethod;
