import GoogleService from './Google';
import UserDataModel from './model/UserData';

class SocialLink {
    onClickSocial(social: string, userData: UserDataModel | null) {
        const userId = userData?.id.toString();
        if (social === 'telegram') {
            GoogleService.clickTelegram(userId ? userId : '');
        }
        if (social === 'instagram') {
            GoogleService.clickInstagram(userId ? userId : '');
        }
    }
}

const SocialLinkService = new SocialLink();

export default SocialLinkService;
