import classNames from 'classnames';
import { MouseEvent, useState } from 'react';

import SvgImage from '../../svg';
import Input, { InputSize } from '../Input/Input';
import InputService from '../Input/service/Input';

import './ToggleInput.scss';

type ToggleInputProps = {
    service: InputService;
};

function ToggleInput(props: ToggleInputProps) {
    const { service } = props;
    const [type, setType] = useState('password');

    const toggle = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setType(type === 'password' ? 'text' : 'password');
    };

    const className = classNames({
        'toggle-input': true,
        'toggle-input____password': type === 'password',
    });

    return (
        <div className={className}>
            <Input inputService={service} type={type} className="recovery-form__input" size={InputSize.small} />
            <button type="button" className="toggle-input__toggle" onClick={toggle}>
                <SvgImage name={type === 'password' ? 'LockEye' : 'OpenEye'} />
            </button>
        </div>
    );
}

export default ToggleInput;
