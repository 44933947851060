import Validator from '../interface/Validator';

class CheckSymbol implements Validator {
    validate(value: string): boolean {
        return new RegExp('^[a-zA-Z0-9]+$').test(value);
    }
}

const CheckSymbolService = new CheckSymbol();

export { CheckSymbolService };
export default CheckSymbol;
