import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { NavLink } from 'react-router-dom';

import RouteConfig from '../../../appConstants/RouteConfig';
import GameHelper from '../../../service/GameHelper';
import GoogleService from '../../../service/Google';
import LanguageService, { translate as t } from '../../../service/Language';
import Tournament from '../../../service/model/Tournament';
import { useIsDesktop } from '../../../utils/useBreakpoints';
import GameCard from '../../Casino/GameList/GameCard/GameCard';
import arrowBack from './image/arrow_back.svg';
import arrowDown from './image/arrow_down.svg';

import './Info.scss';

type InfoProps = {
    tournament: Tournament;
};

function Info(props: InfoProps) {
    const { tournament } = props;
    const [open, setOpen] = useState(false);
    const platform = useIsDesktop();
    const gameList = tournament.gameList.filter((game) => GameHelper.matchPlatform(game, platform));

    const lang = LanguageService.current.getValue();
    const imageSet = tournament.getLocalImageSet(lang.title);

    return (
        <div className="tournament-info">
            <div className="tournament-info__wrapper">
                <div className="tournament-info__header">
                    <NavLink to={RouteConfig.casino.tournament} className="tournament-info__back">
                        <img src={arrowBack} alt="" />
                        <span className="tournament-info__back-text">{t('tournament.view.back')}</span>
                    </NavLink>
                    <div className="tournament-info__name">{tournament.name}</div>
                </div>
                <div className="tournament-info__main">
                    <div className="tournament-info__scale-wrap">
                        <div className="tournament-info__image-box">
                            <img className="tournament-info__image" src={imageSet.main} alt={tournament.name} />
                        </div>
                    </div>
                    <div className="tournament-info__chip-red" />
                    <div className="tournament-info__chip-green" />
                    <div className="tournament-info__chip-blue" />
                    <div className="tournament-info__chip-dice" />
                </div>
                <div className="tournament-info__sub">{t('tournament.view.game_list')}</div>
                <div className="tournament-info__game-list">
                    {gameList.map((game) => (
                        <GameCard key={game.id} game={game} tournament={tournament} />
                    ))}
                </div>
                <div className={classNames({ 'tournament-info__rules': true, 'tournament-info__rules__open': open })}>
                    <button className="tournament-info__toggle" onClick={() => setOpen(!open)}>
                        <div className="tournament-info__toggle-text">{t('tournament.view.full_rules')}</div>
                        <img alt="" src={arrowDown} className="tournament-info__arrow" />
                    </button>
                    <Collapsible open={open} transitionTime={200} trigger="">
                        <div className="tournament-info__rules-text">{tournament.rules}</div>
                    </Collapsible>
                </div>
            </div>
        </div>
    );
}

export default Info;
