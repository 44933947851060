import Input, { InputSize } from '../../../../../component/Input/Input';
import SvgImage from '../../../../../svg';
import useObservable from '../../../../../utils/useObservable';
import WheelRegistrationFormService from '../service/registration';

import './Password.scss';

type PasswordProps = {
    type?: string;
};

function Password({ type }: PasswordProps) {
    const visible = useObservable(
        WheelRegistrationFormService.visible,
        WheelRegistrationFormService.visible.getValue(),
    );

    return (
        <div className='password-input'>
            {type && type !== 'crazytime' && type !== 'roulette' && type !== 'bonanza' && type !== 'olympus' ? (
                <Input
                    type={visible ? 'text' : 'password'}
                    className='password-input__field'
                    name='password'
                    inputService={WheelRegistrationFormService.password}
                    size={InputSize.xxsmall}
                />
            ) : (
                <Input
                    type={visible ? 'text' : 'password'}
                    className='password-input__field'
                    name='password'
                    inputService={WheelRegistrationFormService.password}
                    size={InputSize.small}
                />
            )}
            <button
                type='button'
                className='password-input__eye'
                onClick={() => WheelRegistrationFormService.togglePassword()}
            >
                <SvgImage name={visible ? 'OpenEye' : 'LockEye'} />
            </button>
        </div>
    );
}

export default Password;
