import { PaymentMethod } from "../../../PaymentMethod/types";
import { ChannelType } from "@ay_tsarbet/newbet-core/dist/types";
import BKashP2C from "../BkashP2C/Form";
import Kpay from "../Kpay/Form";
import Mpesa from "../Mpesa/Form";
import NagadP2C from "../NagadP2C/Form";
import Other from "../Other/Form";
import UpiS from "../UpiS/Form";
import Wavepay from "../Wavepay/Form";
import Upay from "../Upay/Form";
import Jazzcash from "../Jazzcash/Form";
import Bankalfallah from "../Bankalfallah/Form";

import "./Form.scss";

type FormProps = {
  paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
  const { paymentMethod } = props;

  if (paymentMethod.id === ChannelType.NAGAD_API) {
    return <NagadP2C paymentMethod={paymentMethod} />;
  }
  if (paymentMethod.id === ChannelType.BKASH_API || paymentMethod.id === ChannelType.BKASH_APAY) {
    return <BKashP2C paymentMethod={paymentMethod} />;
  }
  if (paymentMethod.id === ChannelType.Wavepay) {
    return <Wavepay paymentMethod={paymentMethod} />;
  }
  if (paymentMethod.id === ChannelType.Kpay) {
    return <Kpay paymentMethod={paymentMethod} />;
  }
  if (paymentMethod.id === ChannelType.UPI_S) {
    return <UpiS paymentMethod={paymentMethod} />;
  }
  if (paymentMethod.id === ChannelType.MPESA) {
    return <Mpesa paymentMethod={paymentMethod} />;
  }
  if (paymentMethod.id === ChannelType.JAZZCASH_PAYKASSMA ) {
    return <Jazzcash paymentMethod={paymentMethod} />;
  }
  if (paymentMethod.id === ChannelType.Bankalfalah) {
    return <Bankalfallah paymentMethod={paymentMethod} />;
  }
  if (
    paymentMethod.id === ChannelType.Upay ||
    paymentMethod.id === ChannelType.Banktransfer ||
    paymentMethod.id === ChannelType.Ipay
  ) {
    return <Upay paymentMethod={paymentMethod} />;
  } else {
    return <Other paymentMethod={paymentMethod} />;
  }
}

export default Form;
