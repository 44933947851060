import RouteConfig from '../../../appConstants/RouteConfig';
import { route } from '../../../page/Info/Info';

type LinkListType = {
    id: number;
    title: string;
    path: string;
};

const welcomeBonus = RouteConfig.info.root + RouteConfig.info.promo.root;
const tournaments = RouteConfig.casino.tournament;

type DocumentListType = {
    link: string;
    title: string;
};
const routeAbout = RouteConfig.info.root + RouteConfig.info.about;

class Footer {
    linkList: Array<LinkListType> = [
        { id: 1, title: 'footer.live', path: RouteConfig.casino.live },
        { id: 2, title: 'footer.loyalty', path: welcomeBonus },
        { id: 3, title: 'footer.tournaments', path: tournaments },
    ];

    documentList: LinkListType[] = [
        { id: 1, title: 'info.navigation.about', path: route.about },
        { id: 2, title: 'info.navigation.rules', path: route.rules },
        { id: 3, title: 'info.navigation.KYCAML', path: route.kycaml },
        { id: 4, title: 'info.navigation.privacy', path: route.privacy },
        { id: 5, title: 'info.navigation.responsiblegame', path: route.responsiblegame },
        { id: 6, title: 'info.navigation.cookies', path: route.cookies },
    ];
}

const FooterService = new Footer();
export { routeAbout };
export type { DocumentListType, LinkListType };
export default FooterService;
