import { default as ActiveSearch } from './ActiveSearch';
import { default as AffiliateProgramIcon } from './AffiliateProgramIcon';
import { default as AgeIcon } from './AgeIcon';
import { default as AppMenu } from './AppMenu';
import { default as ArrowDown } from './ArrowDown';
import { default as ArrowUp } from './ArrowUp';
import { default as Ball } from './Ball';
import { default as BigAllSports } from './BigAllSports';
import { default as BigAmericanFootball } from './BigAmericanFootball';
import { default as BigAussieRules } from './BigAussieRules';
import { default as BigBadminton } from './BigBadminton';
import { default as BigBandy } from './BigBandy';
import { default as BigBaseball } from './BigBaseball';
import { default as BigBasketball } from './BigBasketball';
import { default as BigBeachSoccer } from './BigBeachSoccer';
import { default as BigBeachVolley } from './BigBeachVolley';
import { default as BigBoxing } from './BigBoxing';
import { default as BigChampionsLeague } from './BigChampionsLeague';
import { default as BigCricket } from './BigCricket';
import { default as BigCurling } from './BigCurling';
import { default as BigCycling } from './BigCycling';
import { default as BigDarts } from './BigDarts';
import { default as BigFieldHockey } from './BigFieldHockey';
import { default as BigFloorball } from './BigFloorball';
import { default as BigGolf } from './BigGolf';
import { default as BigHandball } from './BigHandball';
import { default as BigHockey } from './BigHockey';
import { default as BigKibersport } from './BigKibersport';
import { default as BigMma } from './BigMma';
import { default as BigMotorsport } from './BigMotorsport';
import { default as BigOlympics } from './BigOlympics';
import { default as BigOther } from './BigOther';
import { default as BigPesapallo } from './BigPesapallo';
import { default as BigRegbieLeague } from './BigRegbieLeague';
import { default as BigRegbieUnion } from './BigRegbieUnion';
import { default as BigRegby } from './BigRegby';
import { default as BigSnooker } from './BigSnooker';
import { default as BigSoccer } from './BigSoccer';
import { default as BigSquash } from './BigSquash';
import { default as BigTableTennis } from './BigTableTennis';
import { default as BigTennis } from './BigTennis';
import { default as BigVideo } from './BigVideo';
import { default as BigVolleyball } from './BigVolleyball';
import { default as BigWaterPolo } from './BigWaterPolo';
import { default as BigWinterSports } from './BigWinterSports';
import { default as Bonus } from './Bonus';
import { default as BonusIcon } from './BonusIcon';
import { default as BorderPlayButton } from './BorderPlayButton';
import { default as CallIcon } from './CallIcon';
import { default as Cart } from './Cart';
import { default as CasinoArcade } from './CasinoArcade';
import { default as CasinoBaccarat } from './CasinoBaccarat';
import { default as CasinoBetting } from './CasinoBetting';
import { default as CasinoBingo } from './CasinoBingo';
import { default as CasinoBlackjack } from './CasinoBlackjack';
import { default as CasinoDealer } from './CasinoDealer';
import { default as CasinoFavorite } from './CasinoFavorite';
import { default as CasinoInstant } from './CasinoInstant';
import { default as CasinoKeno } from './CasinoKeno';
import { default as CasinoLottery } from './CasinoLottery';
import { default as CasinoNew } from './CasinoNew';
import { default as CasinoOther } from './CasinoOther';
import { default as CasinoPoker } from './CasinoPoker';
import { default as CasinoPopular } from './CasinoPopular';
import { default as CasinoRoulette } from './CasinoRoulette';
import { default as CasinoScratchGame } from './CasinoScratchGame';
import { default as CasinoSlots } from './CasinoSlots';
import { default as CasinoTableGames } from './CasinoTableGames';
import { default as CasinoVideoPoker } from './CasinoVideoPoker';
import { default as ChangeIcon } from './ChangeIcon';
import { default as Check } from './Check';
import { default as CheckStatus } from './CheckStatus';
import { default as Coins } from './Coins';
import { default as ConnectedIcon } from './ConnectedIcon';
import { default as Corner } from './Corner';
import { default as DashboardIcon } from './DashboardIcon';
import { default as Discount } from './Discount';
import { default as ErrorStatus } from './ErrorStatus';
import { default as ExitIcon } from './ExitIcon';
import { default as Expand } from './Expand';
import { default as FacebookIcon } from './FacebookIcon';
import { default as FireIcon } from './FireIcon';
import { default as FireLoupe } from './FireLoupe';
import { default as GiftBox } from './GiftBox';
import { default as HeaderBigCross } from './HeaderBigCross';
import { default as IncomeArrow } from './IncomeArrow';
import { default as InfoIcon } from './InfoIcon';
import { default as InstagramIcon } from './InstagramIcon';
import { default as LeftFlatArrow } from './LeftFlatArrow';
import { default as LibraIcon } from './LibraIcon';
import { default as Lightning } from './Lightning';
import { default as LightningActive } from './LightningActive';
import { default as Live } from './Live';
import { default as LiveIcon } from './LiveIcon';
import { default as Loader } from './Loader';
import { default as Lock } from './Lock';
import { default as LockEye } from './LockEye';
import { default as MailIcon } from './MailIcon';
import { default as MessageIcon } from './MessageIcon';
import { default as MiniSwitchArrowLeft } from './MiniSwitchArrowLeft';
import { default as MiniSwitchArrowRight } from './MiniSwitchArrowRight';
import { default as Money } from './Money';
import { default as MoneyBank } from './MoneyBank';
import { default as Offside } from './Offside';
import { default as OpenEye } from './OpenEye';
import { default as OrderIcon } from './OrderIcon';
import { default as OrdersIcon } from './OrdersIcon';
import { default as Penalty } from './Penalty';
import { default as PendingStatus } from './PendingStatus';
import { default as PhotoIcon } from './PhotoIcon';
import { default as PlayArrow } from './PlayArrow';
import { default as PlayGameIcon } from './PlayGameIcon';
import { default as PlayIcon } from './PlayIcon';
import { default as Profile } from './Profile';
import { default as QrCode } from './QrCode';
import { default as QuestionIcon } from './QuestionIcon';
import { default as RedCard } from './RedCard';
import { default as RegisterIcon } from './RegisterIcon';
import { default as ReverseIcon } from './ReverseIcon';
import { default as RightFlatArrow } from './RightFlatArrow';
import { default as ScoreCard } from './ScoreCard';
import { default as Search } from './Search';
import { default as SelectArrow } from './SelectArrow';
import { default as Settings } from './Settings';
import { default as SettingsActive } from './SettingsActive';
import { default as ShieldIcon } from './ShieldIcon';
import { default as SmallArrowDown } from './SmallArrowDown';
import { default as SmallArrowUp } from './SmallArrowUp';
import { default as SmallCross } from './SmallCross';
import { default as Sports } from './Sports';
import { default as Star } from './Star';
import { default as StarActive } from './StarActive';
import { default as Stats } from './Stats';
import { default as SuccessCheck } from './SuccessCheck';
import { default as SupportIcon } from './SupportIcon';
import { default as TelegramIcon } from './TelegramIcon';
import { default as TimeIcon } from './TimeIcon';
import { default as TimeIcon2 } from './TimeIcon2';
import { default as TimeIcon4 } from './TimeIcon4';
import { default as TimeIcon6 } from './TimeIcon6';
import { default as TimeIcon8 } from './TimeIcon8';
import { default as TimeIcon12 } from './TimeIcon12';
import { default as TimeIcon24 } from './TimeIcon24';
import { default as Trash } from './Trash';
import { default as TVGames } from './TVGames';
import { default as TwitterIcon } from './TwitterIcon';
import { default as Union } from './Union';
import { default as UploadDocuments } from './UploadDocuments';
import { default as UserCardIcon } from './UserCardIcon';
import { default as UserPlaceholder } from './UserPlaceholder';
import { default as VkIcon } from './VkIcon';
import { default as VSport } from './VSport';
import { default as WhatsUpIcon } from './WhatsUpIcon';
import { default as Whistle } from './Whistle';
import { default as GloryGames } from './GloryGames';

export default {
    CallIcon,
    MailIcon,
    MessageIcon,
    TelegramIcon,
    WhatsUpIcon,
    AppMenu,
    ActiveSearch,
    AgeIcon,
    ArrowDown,
    ArrowUp,
    Bonus,
    Ball,
    BigAllSports,
    BigAmericanFootball,
    BigAussieRules,
    BigBadminton,
    BigBandy,
    BigBaseball,
    BigBasketball,
    BigBeachSoccer,
    BigBeachVolley,
    BigBoxing,
    BigChampionsLeague,
    BigCricket,
    BigCurling,
    BigCycling,
    BigDarts,
    BigFieldHockey,
    BigFloorball,
    BigGolf,
    BigHandball,
    BigHockey,
    BigKibersport,
    BigMma,
    BigMotorsport,
    BigOlympics,
    BigOther,
    BigPesapallo,
    BigRegbieLeague,
    BigRegbieUnion,
    BigRegby,
    BigSnooker,
    BigSoccer,
    BigSquash,
    BigTableTennis,
    BigTennis,
    BigVideo,
    BigVolleyball,
    BigWaterPolo,
    BigWinterSports,
    BonusIcon,
    Cart,
    ChangeIcon,
    Check,
    ConnectedIcon,
    Corner,
    DashboardIcon,
    Discount,
    ExitIcon,
    Expand,
    FacebookIcon,
    FireIcon,
    FireLoupe,
    HeaderBigCross,
    InstagramIcon,
    LeftFlatArrow,
    LibraIcon,
    Lightning,
    LightningActive,
    Live,
    LiveIcon,
    Loader,
    Lock,
    LockEye,
    MiniSwitchArrowLeft,
    MiniSwitchArrowRight,
    Money,
    MoneyBank,
    Offside,
    OpenEye,
    OrderIcon,
    OrdersIcon,
    Penalty,
    PhotoIcon,
    Profile,
    PlayArrow,
    PlayGameIcon,
    PlayIcon,
    QrCode,
    QuestionIcon,
    RedCard,
    RegisterIcon,
    ReverseIcon,
    RightFlatArrow,
    ScoreCard,
    Search,
    SelectArrow,
    Settings,
    SettingsActive,
    ShieldIcon,
    SmallArrowDown,
    SmallArrowUp,
    SmallCross,
    Sports,
    Star,
    StarActive,
    Stats,
    SuccessCheck,
    TimeIcon,
    TimeIcon2,
    TimeIcon4,
    TimeIcon6,
    TimeIcon8,
    TimeIcon12,
    TimeIcon24,
    Trash,
    TVGames,
    TwitterIcon,
    Union,
    UploadDocuments,
    UserCardIcon,
    UserPlaceholder,
    VkIcon,
    Whistle,
    BorderPlayButton,
    Coins,
    IncomeArrow,
    AffiliateProgramIcon,
    GiftBox,
    InfoIcon,
    SupportIcon,
    CasinoArcade,
    CasinoBaccarat,
    CasinoBetting,
    CasinoBingo,
    CasinoBlackjack,
    CasinoDealer,
    CasinoFavorite,
    CasinoInstant,
    CasinoKeno,
    CasinoLottery,
    CasinoNew,
    CasinoOther,
    CasinoPoker,
    CasinoPopular,
    CasinoRoulette,
    CasinoScratchGame,
    CasinoSlots,
    CasinoTableGames,
    CasinoVideoPoker,
    PendingStatus,
    CheckStatus,
    ErrorStatus,
    VSport,
    GloryGames,
};
