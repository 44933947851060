import ING from '../../page/Profile/Balance/Withdrawal/Instapay/images/ING.svg';
import İşbank from '../../page/Profile/Balance/Withdrawal/Instapay/images/İşbank.svg';
import Kuveyttürk from '../../page/Profile/Balance/Withdrawal/Instapay/images/Kuveyttürk.svg';
import Finans from '../../page/Profile/Balance/Withdrawal/Instapay/images/Türkiye Finans.svg';
import Vakıfbank from '../../page/Profile/Balance/Withdrawal/Instapay/images/Vakıfbank.svg';
import Yapıkredi from '../../page/Profile/Balance/Withdrawal/Instapay/images/Yapıkredi.svg';
import Ziraat from '../../page/Profile/Balance/Withdrawal/Instapay/images/Ziraat Bankası.svg';
import Odea from '../../page/Profile/Balance/Withdrawal/Instapay/images/Odeabank.svg';


import ZiraatBankası from 'assets/svg/payments/turkish/ZiraatBankası.svg';
import TürkiyeİşBankası from 'assets/svg/payments/turkish/İşBankası.svg';
import Garanti from 'assets/svg/payments/turkish/Garanti.svg';
import Akbank from 'assets/svg/payments/turkish/Akbank.svg';
import YapıKredi from 'assets/svg/payments/turkish/YapıKredi.svg';
import Halkbank from 'assets/svg/payments/turkish/Halkbank.svg';
import VakıfBank from 'assets/svg/payments/turkish/Vakıfbank.svg';
import Denizbank from 'assets/svg/payments/turkish/DenizBank.svg';
import QNBFinansbank from 'assets/svg/payments/turkish/QNBFinansbank.svg';
import Anadolubank from 'assets/svg/payments/turkish/Anadolubank.svg';
import Enpara from 'assets/svg/payments/turkish/Enpara.svg';
import ZIRAATKatilim from 'assets/svg/payments/turkish/ZIRAATKatilim.svg';
import OdeaBank from 'assets/svg/payments/turkish/OdeaBank.svg';
import TurkiyeFinansKatilim from 'assets/svg/payments/turkish/TürkiyeFinans.svg';
import TEB from 'assets/svg/payments/turkish/TEB.svg';
import Şekerbank from 'assets/svg/payments/turkish/Şekerbank.svg';
import KuveytTürk from 'assets/svg/payments/turkish/KuveytTürk.svg';
import INGBank from 'assets/svg/payments/turkish/INGBank.svg';
import HSBCBank from 'assets/svg/payments/turkish/HSBC.svg';
import Fibabanka from 'assets/svg/payments/turkish/Fibabanka.svg';
import AlbarakaTurkKatilimBankasi from 'assets/svg/payments/turkish/AlbarakaTürk.svg';
import AlternatifBank from 'assets/svg/payments/turkish/AlternatifBank.svg';
import BurganBank from 'assets/svg/payments/turkish/BurganBank.svg';
import Pttbank from 'assets/svg/payments/turkish/Pttbank.svg';
import Aktifbank from 'assets/svg/payments/turkish/Aktifbank.svg';

export type BankTRY = {
    id: number;
    name: string;
    img?: string;
    sendName?: string;
};

class BankCardsTRY {
    public id = 0;

    public name = '';

    constructor(data: BankTRY) {
        Object.assign(this, data);
    }
}

export const bankList: BankTRY[] = [
    {
        id: 3,
        name: 'Denizbank',
        img: Denizbank,
    },
    {
        id: 19,
        name: 'Kuveyttürk',
        img: Kuveyttürk,
    },
    {
        id: 13,
        name: 'Ziraat Bankası',
        img: Ziraat,
    },
    {
        id: 1,
        name: 'Garanti Bankası',
        img: Garanti,
    },
    {
        id: 32,
        name: 'Enpara',
        img: Enpara,
    },
    {
        id: 25,
        name: 'Türkiye Finans',
        img: Finans,
    },
    {
        id: 15,
        name: 'ING',
        img: ING,
    },
    {
        id: 9,
        name: 'Vakıfbank',
        img: Vakıfbank,
    },
    {
        id: 5,
        name: 'İşbank',
        img: İşbank,
    },
    {
        id: 2,
        name: 'Akbank',
        img: Akbank,
    },
    {
        id: 11,
        name: 'Yapıkredi',
        img: Yapıkredi,
    },
    {
        id: 19,
        name: 'TEB',
        img: TEB,
    },
    {
        id: 27,
        name: 'Odea Bank',
        img: Odea,
    },
];

const bankListHavaleHera: BankTRY[] = [
    {
        id: 18,
        name: 'Ziraat Bankası',
        img: ZiraatBankası,
    },
    {
        id: 16,
        name: 'İş Bankası',
        img: TürkiyeİşBankası,
    },
    {
        id: 2,
        name: 'Garanti',
        img: Garanti,
    },
    {
        id: 1,
        name: 'Akbank',
        img: Akbank,
    },
    {
        id: 21,
        name: 'Yapı Kredi',
        img: YapıKredi,
    },
    {
        id: 15,
        name: 'Halkbank',
        img: Halkbank,
    },
    {
        id: 17,
        name: 'Vakıfbank',
        img: VakıfBank,
    },
    {
        id: 8,
        name: 'DenizBank',
        img: Denizbank,
    },
    {
        id: 13,
        name: 'QNB Finansbank',
        img: QNBFinansbank,
    },
    {
        id: 6,
        name: 'Anadolubank',
        img: Anadolubank,
    },
    {
        id: 27,
        name: 'OdeaBank',
        img: OdeaBank,
    },
    {
        id: 26,
        name: 'Aktifbank',
        img: Aktifbank,
    },
    {
        id: 25,
        name: 'Enpara',
        img: Enpara,
    },
    {
        id: 22,
        name: 'ZIRAAT Katilim',
        img: ZIRAATKatilim,
    },
    {
        id: 20,
        name: 'Türkiye Finans',
        img: TurkiyeFinansKatilim,
    },
    {
        id: 19,
        name: 'TEB',
        img: TEB,
    },
    {
        id: 14,
        name: 'Şekerbank',
        img: Şekerbank,
    },
    {
        id: 12,
        name: 'KuveytTürk',
        img: KuveytTürk,
    },
    {
        id: 11,
        name: 'ING Bank',
        img: INGBank,
    },
    {
        id: 10,
        name: 'HSBC',
        img: HSBCBank,
    },
    {
        id: 9,
        name: 'Fibabanka',
        img: Fibabanka,
    },
    {
        id: 4,
        name: 'Albaraka Türk',
        img: AlbarakaTurkKatilimBankasi,
    },
    {
        id: 5,
        name: 'Alternatif Bank',
        img: AlternatifBank,
    },
    {
        id: 7,
        name: 'Burgan Bank',
        img: BurganBank,
    },
];
export const bankListAnindaHavale: BankTRY[] = [
    {
        id: 1,
        name: 'Garanti',
        img: Garanti,
    },
    {
        id: 2,
        name: 'Akbank',
        img: Akbank,
    },
    {
        id: 3,
        name: 'Denizbank',
        img: Denizbank,
    },
    {
        id: 4,
        name: 'QNB Finansbank',
        img: QNBFinansbank,
    },
    {
        id: 5,
        name: 'İş Bankası',
        img: TürkiyeİşBankası,
    },
    {
        id: 7,
        name: 'TEB',
        img: TEB,
    },
    {
        id: 9,
        name: 'VakıfBank',
        img: VakıfBank,
    },
    {
        id: 11,
        name: 'Yapı Kredi',
        img: YapıKredi,
    },
    {
        id: 13,
        name: 'Ziraat Bankası',
        img: ZiraatBankası,
    },
    {
        id: 15,
        name: 'ING Bank',
        img: INGBank,
    },
    {
        id: 17,
        name: 'Şekerbank',
        img: Şekerbank,
    },
    {
        id: 19,
        name: 'KuveytTürk',
        img: KuveytTürk,
    },
    {
        id: 23,
        name: 'Pttbank',
        img: Pttbank,
    },
    {
        id: 25,
        name: 'Türkiye Finans',
        img: TurkiyeFinansKatilim,
    },
    {
        id: 27,
        name: 'Halkbank',
        img: Halkbank,
    },
    {
        id: 29,
        name: 'OdeaBank',
        img: OdeaBank,
    },
    {
        id: 30,
        name: 'Albaraka Türk',
        img: AlbarakaTurkKatilimBankasi,
    },
    {
        id: 32,
        name: 'Enpara',
        img: Enpara,
    },
    {
        id: 33,
        name: 'AktifBank',
        img: Aktifbank,
    },
];

export const bankListHavaleVIPExpress: BankTRY[] = [
    {
        id: 1,
        name: 'Akbank',
        img: Akbank,
        sendName: 'Akbank',
    },
    {
        id: 8,
        name: 'Denizbank',
        img: Denizbank,
        sendName: 'Deniz BANK',
    },
    {
        id: 13,
        name: 'QNB Finansbank',
        img: QNBFinansbank,
        sendName: 'Qnb Finans',
    },
    {
        id: 11,
        name: 'ING Bank',
        img: INGBank,
        sendName: 'ING',
    },
    {
        id: 18,
        name: 'Ziraat Bankası',
        img: ZiraatBankası,
        sendName: 'Ziraat Bankası',
    },
    {
        id: 19,
        name: 'TEB',
        img: TEB,
        sendName: 'TEB',
    },
    {
        id: 16,
        name: 'İş Bankası',
        img: TürkiyeİşBankası,
        sendName: 'İş bankası',
    },
    {
        id: 17,
        name: 'VakıfBank',
        img: VakıfBank,
        sendName: 'VakıfBank',
    },
    {
        id: 2,
        name: 'Garanti',
        img: Garanti,
        sendName: 'Garanti',
    },
    {
        id: 15,
        name: 'Halkbank',
        img: Halkbank,
        sendName: 'Halkbank',
    },
    {
        id: 21,
        name: 'Yapı Kredi',
        img: YapıKredi,
        sendName: 'YapıKredi',
    },
    {
        id: 12,
        name: 'KuveytTürk',
        img: KuveytTürk,
        sendName: 'Kuveyt Türk',
    },
    {
        id: 25,
        name: 'Enpara',
        img: Enpara,
        sendName: 'ENPARA',
    },
    {
        id: 20,
        name: 'Türkiye Finans',
        img: TurkiyeFinansKatilim,
        sendName: 'Türkiye Finans',
    },
    {
        id: 9,
        name: 'Fibabanka',
        img: Fibabanka,
        sendName: 'Fibabank',
    },
];


export const defaultBankAninda: BankTRY = {
    id: 13,
    name: 'Ziraat Bankası',
    img: ZiraatBankası,
};
export const defaultBank: BankTRY = {
    id: 18,
    name: 'Ziraat Bankası',
    img: ZiraatBankası,
    sendName: 'Ziraat Bankası',
};

export { bankListHavaleHera };
export default BankCardsTRY;
