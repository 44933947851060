/* eslint-disable max-len */

import { Icon } from 'types';

function LockEye({ color = '#3F4250', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.47653 6.33972C4.59357 6.3214 4.7131 6.32675 4.82804 6.35544C4.94198 6.38389 5.04911 6.43471 5.14322 6.50493L23.8824 19.7595C23.9799 19.8248 24.0635 19.909 24.1283 20.0069C24.1936 20.1058 24.2384 20.2167 24.2601 20.3332C24.2818 20.4496 24.2799 20.5693 24.2545 20.685C24.2291 20.8007 24.1808 20.9101 24.1124 21.0069C24.0439 21.1036 23.9568 21.1856 23.8561 21.248C23.7555 21.3104 23.6433 21.352 23.5263 21.3704C23.4092 21.3887 23.2897 21.3833 23.1748 21.3546C23.0608 21.3262 22.9537 21.2754 22.8596 21.2051L20.5568 19.5764C18.8662 20.8286 16.6747 21.8268 14.0014 21.8268C10.7999 21.8268 8.29242 20.395 6.50496 18.8142C4.72133 17.2361 3.60585 15.4711 3.15748 14.6836C3.01236 14.4307 2.9364 14.1446 2.93658 13.853C2.93677 13.5613 3.0134 13.2748 3.15883 13.022C3.94609 11.6578 4.91973 10.4103 6.05108 9.31559L4.12043 7.95063C4.02287 7.88526 3.93928 7.80113 3.87452 7.70314C3.80921 7.60431 3.76438 7.49338 3.7427 7.37691C3.72102 7.26045 3.72292 7.14082 3.74829 7.0251C3.77367 6.90938 3.82199 6.79993 3.89042 6.70323C3.95884 6.60652 4.04597 6.52452 4.14664 6.46208C4.24732 6.39964 4.35949 6.35803 4.47653 6.33972ZM7.52614 10.3607C6.06783 11.6921 5.12603 13.1576 4.72376 13.855C5.13855 14.5748 6.13103 16.118 7.67879 17.4869C9.25498 18.882 11.3631 20.0553 14.0014 20.0553C15.9718 20.0553 17.6427 19.4014 19.0212 18.4901L16.1701 16.4734C15.6168 16.9323 14.9311 17.2049 14.2109 17.2497C13.4368 17.2978 12.6695 17.0799 12.0363 16.632C11.4031 16.1841 10.942 15.5332 10.7296 14.7872C10.532 14.0933 10.5607 13.3559 10.809 12.6814L7.52614 10.3607Z"
                fill={color}
            />
            <path
                d="M14.0014 5.8833C12.4214 5.8833 11.0043 6.23208 9.75878 6.78076C9.54491 6.87611 9.3767 7.05262 9.29235 7.27105C9.20801 7.48949 9.21359 7.73244 9.30788 7.94677C9.40216 8.1611 9.57748 8.32938 9.7955 8.4148C9.92258 8.46459 10.0581 8.48387 10.1917 8.47283L10.4729 8.40284C11.5837 7.90925 12.7858 7.6544 14.0014 7.65478C16.6397 7.65478 18.7468 8.82799 20.324 10.2232C21.8718 11.5921 22.8643 13.1362 23.2791 13.8554C22.8804 14.5407 22.4284 15.1936 21.9273 15.8082C21.8503 15.8972 21.7919 16.0007 21.7554 16.1126C21.7186 16.2255 21.7049 16.3447 21.7151 16.4631C21.7252 16.5815 21.7591 16.6966 21.8147 16.8016C21.8703 16.9066 21.9464 16.9993 22.0386 17.0743C22.1308 17.1493 22.2371 17.205 22.3512 17.238C22.4653 17.271 22.5849 17.2808 22.7029 17.2666C22.8209 17.2525 22.9348 17.2148 23.0379 17.1557C23.1399 17.0973 23.2292 17.0191 23.3007 16.9258C23.8743 16.2229 24.3905 15.4748 24.8439 14.689C24.9896 14.436 25.0664 14.1491 25.0666 13.8571C25.0668 13.5653 24.9904 13.2785 24.8452 13.0254C24.396 12.2381 23.2815 10.474 21.4978 8.8968C19.7095 7.31509 17.2029 5.8833 14.0014 5.8833Z"
                fill={color}
            />
        </svg>
    );
}

export default LockEye;
