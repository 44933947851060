import MpayInterface from '../../interface/payment/Mpay';
import {MpayDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sentMpayDepositRequest';

interface MpayHandler {
    deposit(request: MpayDepositRequest): void;
}

class MpayProvider implements MpayInterface {
    handler: MpayHandler;

    constructor(handler: MpayHandler) {
        this.handler = handler;
    }

    deposit(request: MpayDepositRequest): void {
        this.handler.deposit(request);
    }
}

export type { MpayHandler };
export default MpayProvider;
