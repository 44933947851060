import { SlotegratorGame, SlotegratorGameImageType } from '@ay_tsarbet/newbet-core/dist/types';

import { GAME_IMAGE_HOST } from '../../appConstants/image';
import GameProvider, { unknownProvider } from './GameProvider';

export const imageExtensionMap = {
    [SlotegratorGameImageType.TYPE_0]: 'png',
    [SlotegratorGameImageType.TYPE_1]: 'jpg',
    [SlotegratorGameImageType.TYPE_2]: 'jpeg',
};

class Game {
    provider: GameProvider = unknownProvider;

    static unrealId = -1000;

    constructor(public id: number, public name: string, public image: string, public isMobile: number) {}

    setProvider(provider: GameProvider) {
        this.provider = provider;
    }

    update(record: SlotegratorGame) {
        const image = `${GAME_IMAGE_HOST}${record.id}.${imageExtensionMap[record.imageType]}`;

        this.name = record.name;
        this.image = image;
        this.isMobile = record.isMobile;
    }

    isReal() {
        return this.id !== Game.unrealId;
    }

    static fromRecord(record: SlotegratorGame): Game {
        const image = `${GAME_IMAGE_HOST}${record.id}.${imageExtensionMap[record.imageType]}`;

        return new Game(record.id, record.name, image, record.isMobile);
    }
}

const unknownGame = Game.fromRecord({
    groupId: 0,
    name: '',
    id: Game.unrealId,
    imageType: 0,
    providerId: 0,
    isMobile: 0,
});

type GameList = Game[];

export type { GameList };
export { unknownGame };
export default Game;
