import Validator from '../interface/Validator';

class Limit implements Validator {
    constructor(private min: number, private max: number) {}

    setLimit(min: number, max: number) {
        this.min = min;
        this.max = max;
    }

    validate(value: string): boolean {
        const number = parseFloat(value);

        return number >= this.min && number <= this.max;
    }
}

export default Limit;
