import PaycordInterface from '../../interface/payment/Paycord';
import {PaycordDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaycordDepositRequest';

interface PaycordHandler {
    deposit(request: PaycordDepositRequest): void;
}

class PaycordProvider implements PaycordInterface {
    handler: PaycordHandler;

    constructor(handler: PaycordHandler) {
        this.handler = handler;
    }

    deposit(request: PaycordDepositRequest): void {
        this.handler.deposit(request);
    }
}

export type { PaycordHandler };
export default PaycordProvider;
