/* eslint-disable max-len */

import { Icon } from 'types';

function CasinoVideoPoker({ color, width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                d="M1.5 2.66526V19.428C1.5 20.8232 3.0549 21.6553 4.21574 20.8814L16.7878 12.5001C17.8249 11.8086 17.8249 10.2846 16.7878 9.59321L4.21574 1.21184C3.0549 0.437945 1.5 1.2701 1.5 2.66526Z"
                fill={color ?? 'url(#paint0_linear)'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.04664 19.6858L9.42911 18.2583L12.5336 16.2004C12.5574 16.2589 12.584 16.3165 12.6135 16.3732C12.985 17.0864 13.7281 17.4702 14.497 17.3458L13.8539 18.2036C13.8063 18.2675 13.7916 18.3495 13.808 18.4223C13.819 18.472 13.8442 18.5176 13.883 18.5504C13.9095 18.5717 13.9389 18.5871 13.97 18.595C13.9854 18.5992 14.0009 18.6014 14.0169 18.6016C14.0281 18.602 14.0396 18.6013 14.0511 18.5996C14.4441 18.5419 14.8331 18.6461 15.145 18.8927C15.1651 18.9085 15.187 18.92 15.2096 18.9276C15.2527 18.9421 15.299 18.9418 15.3423 18.9287C15.3901 18.9139 15.4344 18.8828 15.4665 18.8372C15.5046 18.7831 15.5228 18.7151 15.5123 18.6521L15.3891 17.6148C16.2208 18.2839 17.4257 18.0745 18.0831 17.1477C18.559 16.4727 18.6299 15.5775 18.2609 14.869L17.3149 13.0311L18.116 12.5001C19.1582 11.8092 19.1582 10.279 18.116 9.58811L13.7443 6.69026L21.5177 8.77315C22.0222 8.90834 22.3123 9.45792 22.1665 10.0022L18.8667 22.3171C18.7209 22.8614 18.1949 23.1923 17.6903 23.0571L9.69546 20.9148C9.19091 20.7796 8.9008 20.2301 9.04664 19.6858Z"
                fill={color ?? 'url(#paint1_linear)'}
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="1.97055"
                    y1="5.7825"
                    x2="19.5421"
                    y2="9.69842"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="1.97055"
                    y1="5.7825"
                    x2="19.5421"
                    y2="9.69842"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default CasinoVideoPoker;
