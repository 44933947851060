import { format } from 'date-fns';
import TagManager from 'react-gtm-module';

import { LanguageType } from './Language';
import TrackData from './model/TrackData';
import UserData from './model/UserData';

type GoogleEventRecord = Record<string, any>;

declare global {
    interface Window {
        dataLayer: GoogleEventRecord[];
    }
}

class Google {
    initTagManager() {
        console.log('initTagManager', !window.location.hostname.includes("preprod"))
        console.log('initTagManager', window.location.hostname)
        if (process.env.NODE_ENV === 'production' &&
        !window.location.hostname.includes("preprod")) {
            console.log('initTagManager_if')
            const tagManagerArgs = {
                gtmId: 'GTM-58WZWBS',
            };

            TagManager.initialize(tagManagerArgs);
        }
    }

    addEvent(data: GoogleEventRecord) {
        // this is how Google tag works, I can`t fix it
        const timeData = Object.assign(data, { timestamp: Math.floor(Date.now() / 1000) });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(timeData);
    }

    addBettingEvent(data: GoogleEventRecord) {
        // this is how Google tag works, I can`t fix it
        const timeData = Object.assign(data, { timestamp: Math.floor(Date.now() / 1000) });
        const gtmEventId = this.getClientId();

        window.dataLayer = window.dataLayer || [];

        const result = {
            timedata: timeData,
            'Gtm.uniqueEventId': gtmEventId,
        };
        window.dataLayer.push({ ...result });
    }

    viewMainPage(id: string) {
        this.addEvent({
            event: 'page-view',
            category: 'main-page',
            id,
        });
    }

    openGameCategory(name: string) {
        this.addEvent({
            event: 'choice-category',
            category: name,
        });
    }

    openGame(gameName: string, providerName: string, userId: string, email: string) {
        this.addEvent({
            event: 'start-game',
            name_of_game: gameName,
            provider_name: providerName,
            id: userId,
            email: email,
        });
    }

    openGameTournament(gameName: string, providerName: string, tournamentName: string, tournamentId: number, userId: string, email: string) {
        this.addEvent({
            event: 'start-game-tournament',
            name_of_game: gameName,
            name_of_provider: providerName,
            tournamentName,
            tournamentId,
            id: userId,
            email: email,
        });
    }

    openDemoGame(gameName: string, providerName: string, userId: string, email: string) {
        this.addEvent({
            event: 'start-demo',
            name_of_game: gameName,
            provider_name: providerName,
            id: userId,
            email: email,
        });
    }

    openDemoGameTournament(gameName: string, providerName: string, tournamentName: string, tournamentId: number, userId: string, email: string) {
        this.addEvent({
            event: 'start-demo-tournament',
            name_of_game: gameName,
            name_of_provider: providerName,
            tournamentName,
            tournamentId,
            id: userId,
            email: email,
        });
    }

    addFavorite(gameName: string, providerName: string) {
        this.addEvent({
            event: 'add-favorites',
            'name of game': gameName,
            'name of provider': providerName,
        });
    }

    removeFavorite(gameName: string, providerName: string) {
        this.addEvent({
            event: 'delete-favorites',
            'name of game': gameName,
            'name of provider': providerName,
        });
    }

    search(request: string, userId: string) {
        this.addEvent({
            event: 'search',
            userId: userId,
            keyword: request,
        });
    }

    openProviderList() {
        this.addEvent({
            event: 'open-list-providers',
        });
    }

    choiceProvider(name: string) {
        this.addEvent({
            event: 'choice-provider',
            'name of provider': name,
        });
    }

    filterProvider(name: string) {
        this.addEvent({
            event: 'filter-provider',
            'name of provider': name,
        });
    }

    openVSport() {
        // see openGameCategory();
    }

    openLive() {
        this.addEvent({
            event: 'choice-category',
            category: 'live-casino',
        });
    }
    openGloryGames() {
        this.addEvent({
            event: 'choice-category',
            category: 'glory-games',
        });
    }

    openJackpot() {
        this.addEvent({
            event: 'open-jackpot',
        });
    }

    openAviator(gameName: string, providerName: string, userId: string, email: string) {
        this.addEvent({
            event: 'start-game',
            'name of game': gameName,
            'name of provider': providerName,
            id: userId,
            email: email,
        });
    }

    openCrazy() {
        this.addEvent({
            event: 'choice-category',
            category: 'crazy', //name of category
        });
    }

    openLiveChat() {
        this.addEvent({
            event: 'contact',
            method: 'livechat',
        });
    }

    registration() {
        this.addEvent({
            event: 'page-view',
            category: 'registration',
        });
    }

    registrationComplete(
        userEmail: string,
        userId: string,
        currency: string,
        url: string,
        trackData: TrackData,
    ) {
        this.addEvent({
            event: 'registration',
            email: userEmail,
            id: userId,
            currency: currency,
            landingpageurl: url,
            'utm-source': trackData.utmSource,
            'utm-medium': trackData.utmMedium,
            'utm-campaign': trackData.utmCampaign,
            clickid: trackData.clickId,
        });
    }

    login() {
        this.addEvent({
            event: 'page-view',
            category: 'login',
        });
    }

    loginComplete(userEmail: string, userId: string,  url: string,) {
        this.addEvent({
            event: 'login',
            referer : url,
            email: userEmail,
            id: userId,
        });
    }

    openUserPage(userId: string) {
        this.addEvent({
            event: 'page-view',
            category: 'account',
            id: userId,
        });
    }

    openBonusPageView(userId: string) {
        this.addEvent({
            event: 'page-view',
            category: 'bonus',
            id: userId,
        });
    }

    saveUserData(userData: UserData) {
        this.addEvent({
            event: 'update-userinfo',
            id: userData.id.toString(),
            email: userData.email,
            name: userData.firstName,
            surname: userData.secondName,
            'birth-date': userData.birthDate ? format(userData.birthDate, 'yyyy-MM-dd') : '',
            country: userData.country.name,
            'phone-number': userData.phone ? userData.phone.toString() : '',
            currency: userData.currency.name,
        });
    }

    openBalance(userId: string, balance: string) {
        this.addEvent({
            event: 'page-view',
            id: userId,
            balance: balance,
            category: 'transaction-history',
        });
    }

    openDeposit(userId: string) {
        this.addEvent({
            event: 'deposit',
            id: userId,
            category: 'dep-step-0',
        });
    }

    pickDepositMethod(userId: string, methodName: string) {
        this.addEvent({
            event: 'deposit',
            id: userId,
            'payment-method': methodName,
            category: 'dep-step-1',
        });
    }

    sentDepositAmount(userId: string, methodName: string, amount: string, currency: string) {
        this.addEvent({
            event: 'deposit',
            id: userId,
            'payment-method': methodName,
            amount: amount,
            currency: currency,
            category: 'dep-step-2',
        });
    }

    sentTransactionId(
        userId: string,
        methodName: string,
        invoice: string,
        amount: string,
        currency: string,
        transactionId: string,
    ) {
        this.addEvent({
            event: 'deposit',
            id: userId,
            'payment-method': methodName,
            amount: amount,
            currency: currency,
            'payment-account': invoice,
            'payment-system': 'paykassma',
            'transaction-number': transactionId,
            category: 'dep-step-3',
        });
    }

    openWithdraw(userId: string) {
        this.addEvent({
            event: 'page-view',
            id: userId,
            category: 'withdrawl',
        });
    }

    pickWithdrawMethod(userId: string, methodName: string) {
        this.addEvent({
            event: 'withdrawl',
            id: userId,
            'payment-method': methodName,
            category: 'choice-method',
        });
    }

    sentWithdraw(
        userId: string,
        methodName: string,
        amount: string,
        currency: string,
        phone: string,
    ) {
        this.addEvent({
            event: 'withdrawl',
            id: userId,
            'payment-method': methodName,
            amount: amount,
            currency: currency,
            'phone-number': phone,
            category: 'payout',
        });
    }

    logout(userId: string, email: string) {
        this.addEvent({
            event: 'logout',
            email: email,
            id: userId,
        });
    }

    getClientId(): string {
        let match = /(?:^|;)\s*_ga=([^;]*)/.exec(document.cookie);
        const raw = match ? decodeURIComponent(match[1]) : null;
        if (raw) {
            match = /(\d+\.\d+)$/.exec(raw);
        }
        return match ? match[1] : '';
    }

    openBonusPage(userId: string) {
        this.addEvent({
            event: 'bonus-page',
            id: userId,
        });
    }

    openBonusCancel(userId: string) {
        this.addEvent({
            event: 'cancel-bonus',
            id: userId,
        });
    }

    changeLanguage(userId: string | '', language: LanguageType) {
        this.addEvent({
            event: 'change-language',
            category: 'change',
            id: userId,
            language: language,
        });
    }

    clickTelegram(userId: string | '') {
        this.addEvent({
            event: 'goto',
            category: 'telegram',
            id: userId,
        });
    }

    clickInstagram(userId: string | '') {
        this.addEvent({
            event: 'goto',
            category: 'instagram',
            id: userId,
        });
    }

    openSlideMenu(userId: string | '') {
        this.addEvent({
            event: 'open-burger-menu',
            category: 'step-1',
            id: userId,
        });
    }

    slideMenuRegistration() {
        this.addEvent({
            event: 'page-view',
            category: 'registration',
        });
    }

    slideMenuLogin() {
        this.addEvent({
            event: 'page-view',
            category: 'login',
        });
    }

    slideMenu(title: string, id: string) {
        switch (title) {
            case 'sidebar.auth.home':
                this.slideMenuHome(id);
                break;
            case 'sidebar.auth.account.jackpot':
                this.slideMenuJackpot();
                break;
            case 'sidebar.auth.account.bonus.program':
                this.slideMenuBonusProgram();
                break;
            case 'landingAPK.page.mobile':
                this.slideMenuMobileApp();
                break;
        }
    }

    slideMenuHome(id: string) {
        this.addEvent({
            event: 'page-view',
            category: 'main-page',
            id,
        });
    }

    onOpenTournaments(id: string) {
        this.addEvent({
            event: 'page-view',
            category: 'tournaments',
            id,
        });
    }

    startTournament(name: string, tournamentId: number, id: string, email: string) {
        this.addEvent({
            event: 'start-tournament',
            category: 'tournaments',
            tournamentName: name,
            tournamentId,
            id,
            email,
        });
    }

    slideMenuJackpot() {
        this.addEvent({
            event: 'page-view',
            category: 'jackpot',
        });
    }

    slideMenuBonusProgram() {
        this.addEvent({
            event: 'page-view',
            category: 'bonus-program',
        });
    }

    slideMenuMobileApp() {
        this.addEvent({
            event: 'page-view',
            category: 'mobile-app',
        });
    }

    gameHistory(userId?: number) {
        this.addEvent({
            event: 'page-view',
            category: 'game-history',
            id: userId,
        });
    }

    tvGames() {
        this.addEvent({
            event: 'choice-category',
            category: 'tv-games',
        });
    }

    betting() {
        this.addEvent({
            event: 'choice-category',
            category: 'betting',
        });
    }

    tournament(userId?: number) {
        this.addEvent({
            event: 'choice-category',
            category: 'tournament',
            id: userId,
        });
    }

    notification(userId?: number | null) {
        this.addEvent({
            event: 'notification',
            id: userId,
        });
    }

    goProfile(userId?: number | null) {
        this.addEvent({
            event: 'profile',
            category: 'transaction-history',
            id: userId,
        });
    }

    myProfile(userId?: number | null) {
        this.addEvent({
            event: 'profile',
            category: 'account',
            id: userId,
        });
    }

    gameHistoryProfile(userId?: number | null) {
        this.addEvent({
            event: 'profile',
            category: 'game-history',
            id: userId,
        });
    }

    bonusProfile(userId?: number | null) {
        this.addEvent({
            event: 'profile',
            category: 'bonus',
            id: userId,
        });
    }

    authError(email: string) {
        this.addEvent({
            event: 'login',
            category: 'error',
            email: email,
        });
    }

    landingOpen(userId: string) {
        this.addEvent({
            event: 'landing_page_load',
            category: 'step-1',
            id: userId,
        });
    }

    landingSpin(userId: string) {
        this.addEvent({
            event: 'landing_page_spin1',
            category: 'step-2',
            id: userId,
        });
    }

    landingSpinAgain(userId: string) {
        this.addEvent({
            event: 'landing_page_spin2',
            category: 'step-3',
            id: userId,
        });
    }

    landingRegistration(userId: string) {
        this.addEvent({
            event: 'landing_page_registration',
            category: 'step-4',
            id: userId,
        });
    }

    bettingOpen() {
        this.addEvent({
            event: 'page-view',
            category: 'sports',
        });
    }

    bettingEvent(event: any) {
        const options: GoogleEventRecord = {
            event,
        };
        this.addBettingEvent(options);
    }

    newYearSideOpen(email: string, userId: string, path: string) {
        this.addEvent({
            event: 'button_click',
            category: 'page_ny',
            referer : path,
            id : userId,
            email: email,
        });
    }

    participateNonAuth(email: string, userId: string, path: string, order: string) {
        this.addEvent({
            event: 'button_click',
            category: `ny_participate_registration${order}`,
            referer : path, //referer,
        });
    }

    participateAuth(email: string, userId: string, path: string, order: string) {
        this.addEvent({
            event: 'button_click',
            category: `ny_participate${order}`,
            referer : path,
            id : userId,
            email: email,
        });
    }
    newYearInst( userId: string, path: string) {
        this.addEvent({
            event: 'button_click',
            category: `ny_instagram`,
            referer : path,
            id : userId,
        });
    }
    newYearTeleg(userId: string, path: string) {
        this.addEvent({
            event: 'button_click',
            category: `ny_telegram`,
            referer : path,
            id : userId,
        });
    }
    newYearPlayPopup( userId: string, email: string, path: string, balance: string) {
        this.addEvent({
            event: 'button_click',
            category: `ny_play_popup`,
            referer : path,
            id : userId,
            email: email,
            balance : balance,
        });
    }
    newYearPlay( userId: string, email: string, path: string, balance: string, order: string) {
        this.addEvent({
            event: 'button_click',
            category: `ny_play${order}`,
            referer : path,
            id : userId,
            email: email,
            balance : balance,
        });
    }

    newYearEmail( userId: string, email: string, path: string ) {
        this.addEvent({
            event: 'button_click',
            category: `ny_correct_email`,
            referer : path,
            id : userId,
            email: email,
        });
    }
    newYearChangeEmailClick( userId: string, email: string, path: string ) {
        this.addEvent({
            event: 'button_click',
            category: `ny_change_email1`,
            referer : path,
            id : userId,
            email: email,
        });
    }
    newYearChangeEmailRequest( userId: string, email: string, newEmail: string,  path: string ) {
            this.addEvent({
            event: 'button_click',
            category: `ny_change_email2`,
            referer : path,
            email: email,
            'new email': newEmail,
            id : userId,
        });
    }
    newYearInfo( userId: string, email: string, path: string, name: string) {
        this.addEvent({
            event: 'button_click',
            category: `ny_${name}`,
            referer : path,
            id : userId,
            email: email,
        });
    }
    newYearJoin( userId: string, email: string, path: string, order: string ) {
        this.addEvent({
            event: 'button_click',
            category: `ny_join${order}`,
            referer : path,
            id : userId,
            email: email,
        });
    }
    leaderBoardSupport( userId: string, email: string, path: string ) {
        this.addEvent({
            event: 'button_click',
            category: `ny_support`,
            referer : path,
            id : userId,
            email: email,
        });
    }
    newYearBanner( userId: string, email: string, path: string ) {
        this.addEvent({
            event: 'button_click',
            category: `ny_banner`,
            referer : path,
            id : userId,
            email: email,
        });
    }
    clickBanner(eventName: string, elementUrl: string, userId: string, position: number) {
        this.addEvent({
            event: 'button_click',
            category: 'head_banner',
            elementUrl,
            event_name: eventName,
            id: userId,
            position,
        });
    }
    viewBanner(eventName: string, email: string, userId: string, position: number) {
        this.addEvent({
            event: 'banner-view',
            name_of_banner: eventName,
            id: userId,
            email,
            position,
        });
    }
}

const GoogleService = new Google();

export default GoogleService;
