import { PaymentMethod } from 'page/Profile/Balance/PaymentMethod/types';

class PaycosWithdraw {
    amount: number;

    currencyId: number;

    channelType: number;

    card: string;

    constructor(amount: number, method: PaymentMethod, card: string) {
        this.amount = amount;
        this.currencyId = method.currency.ISO;
        this.channelType = method.id;
        this.card = card;
    }
}

export default PaycosWithdraw;
