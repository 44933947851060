import classnames from 'classnames';
import {ListType} from 'enums/payment';

import RxComponent from '../../../../component/RxComponent/RxComponent';
import Currency, {unknownCurrency} from '../../../../service/model/Currency';
import UserData from '../../../../service/model/UserData';
import UserDataService from '../../../../service/UserData';
import {PaymentMethodsListType} from '../../../../utils/payment';
import PaymentService from '../../../../service/Payment';
import {PaymentMethod as PaymentMethodType, PaymentMethod} from '../PaymentMethod/types';
import CriptoPaymentMethod from '../PaymentMethod/CriptoPaymentMethod';
import {translate as t} from '../../../../service/Language';

import './СryptocurrencyList.scss';


type PaymentListProps = {
    type: ListType;
    className?: string;
};

type PaymentListState = {
    userCurrency: Currency;
    cryptoDepositList: PaymentMethodType[];
    cryptoWithdrawalList: PaymentMethodType[];
};

class CryptoCurrencyList extends RxComponent<PaymentListProps, PaymentListState> {
    handler = {
        setUserData: this.setUserData.bind(this),
        getList: this.getList.bind(this),
    };

    state = {
        userCurrency: unknownCurrency,
        cryptoDepositList: [],
        cryptoWithdrawalList: [],
    };

    setUserData(userData: UserData | null) {
        if (userData) {
            this.setState({userCurrency: userData.currency});
        }
    }

    getList({cryptoDepositList, cryptoWithdrawalList}: PaymentMethodsListType) {
        this.setState({cryptoDepositList});
        this.setState({cryptoWithdrawalList});
    }

    componentDidMount() {
        this.subscribe(UserDataService.data, {next: this.handler.setUserData});
        // @ts-ignore
        this.subscribe(PaymentService.paymentList, {next: this.handler.getList});
    }

    render() {
        const { type, className } = this.props;
        const { userCurrency, cryptoDepositList, cryptoWithdrawalList } = this.state;

        // @ts-ignore
        const currentDepositList = cryptoDepositList.filter(item => item.currency.ISO === userCurrency.ISO)
        // @ts-ignore
        const currentWithdrawalList = cryptoWithdrawalList.filter(item => item.currency.ISO === userCurrency.ISO)

        const list: PaymentMethod[] =
            type === ListType.DEPOSIT ? currentDepositList : currentWithdrawalList;

        const classNames = classnames({
            [className || '']: !!className,
            'cryptopayment-list': true,
        });

        return (
            !!currentDepositList.length
                ? <>
                    <div className="cryptopayment-list__hint">{t('popup.payment.crypto.title')}</div>
                    <div className={classNames}>
                        {list
                            .map((method) => {
                                // console.log("---------- METHOD ----------", method);
                                return (
                                    <CriptoPaymentMethod paymentMethod={method} key={method.id} type={type} userCurrency={userCurrency} />
                            );
                            })}
                    </div>
                </>
                : null
        );
    }
}

export default CryptoCurrencyList;
