import Validator from '../interface/Validator';

class CheckBrazilLetters implements Validator {
    validate(value: string): boolean {
        return new RegExp('[ãáàâç]+$').test(value);
    }
}
const CheckBrazilLettersService = new CheckBrazilLetters();

export { CheckBrazilLettersService };
export default CheckBrazilLetters;
