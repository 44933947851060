import { Link } from 'react-router-dom';
import { translate as t } from 'service/Language';

import curacao from './curacao.png';

import './LicenceCard.scss';

function LicenceCard() {
    return (
        <div className="licence-card">
            <div className="licence-card__title">
                <span className="licence-card__title__header">{t('info.about.licence.name')}</span>{' '}
                {t('info.about.licence.name.description1')}&nbsp;
                <Link to="/" className="licence-card__title__link">
                    {t('info.about.licence.name.link')}
                </Link>
                &nbsp; {t('info.about.licence.name.description2')}
            </div>
            <div className="licence-card__row">
                <div>
                    <img alt="curacao" src={curacao} />
                </div>
                <span className="licence-card__description">{t('info.about.licence.curacao')}</span>
            </div>
        </div>
    );
}

export default LicenceCard;
