import { translate as t } from 'service/Language';

import CasinoLinkItem from './CasinoLinkItem/CasinoLinkItem';

import './СasinoLinkList.scss';

function CasinoLinkList() {
    return (
        <div className="casino-list">
            <p className="casino-list__title">{t('footer.casino')}</p>
            <div className="casino-list__link">
                <CasinoLinkItem className="casino-list__value" />
            </div>
        </div>
    );
}

export default CasinoLinkList;
