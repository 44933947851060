import { translate as t } from 'service/Language';

import Input from '../../../../../../../component/Input/Input';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { formatCurrencySign } from '../../../../../../../utils/format';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import PaykassmaWithdrawService, { PaykassmaWithdrawState } from '../../../Paykassma/service/PaykassmaWithdraw';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import EasypaisaFormService, { EasypaisaFormError } from '../service/EasypaisaForm';
import WithdrawalButtonEasypaisa from './WithdrawalButtonEasypaisa/WithdrawalButtonEasypaisa';
import WithdrawService from '../../../service/Withdrawal';

import './Easypaisa.scss';
import Attention from '../../../../../../../assets/svg/attention.svg';


type EasypaisaProps = {
    paymentMethod: PaymentMethod;
    status: PaykassmaWithdrawState;
};
type EasypaisaState = {
    value: string;
    tip: EasypaisaFormError;
    error: string;
};

class Easypaisa extends RxComponent<EasypaisaProps, EasypaisaState> {
    state = {
        value: '',
        tip: EasypaisaFormError.NOERROR,
        error: '',
    };

    handler = {
        getValue: this.getValue.bind(this),
        onError: this.onError.bind(this),
        onTip: this.onTip.bind(this),
    };

    onError(error: string) {
        this.setState({ error });
    }

    onTip(tip: EasypaisaFormError) {
        this.setState({ tip });
    }

    getValue(value: string) {
        this.setState({ value });
    }

    componentDidMount() {
        const { paymentMethod } = this.props;

        EasypaisaFormService.setPaymentMethod(paymentMethod);
        this.subscribe(EasypaisaFormService.error, { next: this.handler.onError });
        this.subscribe(EasypaisaFormService.tip, { next: this.handler.onTip });
        this.subscribe(EasypaisaFormService.amount.value, { next: this.handler.getValue });
    }

    render() {
        const { paymentMethod, status } = this.props;

        const { value, tip, error } = this.state;
        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        const fee = WithdrawService.fee.getValue();

        const isInit = status === PaykassmaWithdrawState.INIT;
        if (fee && isInit) {
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.HAS_FEE)
        };
        const amountFee = formatCurrencySign(paymentMethod.limit.withdraw.min * 1.25, 0, paymentMethod.currency);
        const allFeeText = `${amountFee} ${t('popup.payment.min.fee')}`;

        return (
            <div className="easypaisa-withdrawal">
                <div className="easypaisa-withdrawal-header">
                    <img
                        className="easypaisa-withdrawal-header__img"
                        src={paymentMethod.icon}
                        alt={paymentMethod.slug}
                    />
                    <h2 className="easypaisa-withdrawal-header__title">{t(paymentMethod.title)}</h2>
                </div>
                <form className="easypaisa-withdrawal-form" onSubmit={(e) => EasypaisaFormService.onSubmit(e)}>
                    <div className="easypaisa-withdrawal-form-block">
                        <Input
                            className="easypaisa-withdrawal-form-block__amount"
                            inputService={EasypaisaFormService.amount}
                            name="amount"
                            type="number"
                            hint={hint}
                        />
                        <span className="easypaisa-withdrawal-form-block__currency">{paymentMethod.currency.sign}</span>
                        {!!error ? (
                            <div className="easypaisa-withdrawal-form-block__error">
                                <img src={Attention} alt="attention"/>
                                <span
                                    className="easypaisa-withdrawal-form-block__error_text">
                                {allFeeText}
                            </span>
                            </div>
                        ) : null}
                    </div>

                    <div className="easypaisa-withdrawal-form-block">
                        <MaskedInput
                            className="easypaisa-withdrawal-form-block__wallet"
                            mask="00000000000"
                            inputService={EasypaisaFormService.accountNumber}
                            name="accountNumber"
                        />
                        {tip === EasypaisaFormError.ACCOUNT && (
                            <span className="easypaisa-withdrawal-form-block__tip">
                                {t('popup.payment.easypaisa.wallet.hint')}
                            </span>
                        )}
                    </div>
                    <div className="easypaisa-withdrawal-form-block">
                        <Input
                            className="easypaisa-withdrawal-form-block__wallet"
                            inputService={EasypaisaFormService.accountName}
                            name="name"
                        />
                    </div>

                    {(status === PaykassmaWithdrawState.HAS_FEE || status === PaykassmaWithdrawState.AWAIT) && fee ? <FeeNotice amount={value} fee={fee} />: null}
                    {status === PaykassmaWithdrawState.HAS_FEE || status === PaykassmaWithdrawState.AWAIT ? (
                        <>
                            <WithdrawalButtonEasypaisa
                                buttonTitle="profile.balance.withdraw.fee.agree"
                                variation={true}
                                paymentMethod={paymentMethod}
                                amount={value}
                            />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel" />
                        </>
                    ) : (
                        <WithdrawalButtonEasypaisa buttonTitle="profile.balance.withdraw.button" />
                    )}
                </form>
            </div>
        );
    }
}

export default Easypaisa;
