import { HavaleHeraDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendHavaleHeraDepositRequest';
import { HavaleHeraWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendHavaleHeraWithdrawal';

interface HavaleHeraHandler {
    deposit(request: HavaleHeraDepositRequest): void;
    withdraw(request: HavaleHeraWithdrawalRequest): void;
}

class HavaleHeraProvider implements HavaleHeraHandler {
    handler: HavaleHeraHandler;

    constructor(handler: HavaleHeraHandler) {
        this.handler = handler;
    }

    deposit(request: HavaleHeraDepositRequest): void {
        this.handler.deposit(request);
    }

    withdraw(request: HavaleHeraWithdrawalRequest): void {
        this.handler.withdraw(request);
    }
}

export type { HavaleHeraHandler };
export default HavaleHeraProvider;
