import JSON from './JSON';

export type CountryType = {
    name: string;
    code: string;
    currency: {
        code: string;
        name: string;
        symbol: string;
    };
    language: {
        code: string;
        iso639_2: string;
        name: string;
        nativeName: string;
    };
    isoCode: string;
};

class Countries extends JSON {
    public getList(): Promise<CountryType[]> {
        return this.get<CountryType[]>('/countries/countries.json', {}).then(
            // return this.get<PaymentRecordList>('/backoffice/payments_glory.json', {}).then(
            // response => response.data,
            response => {
                console.log('response.data', response.data);
                return response.data;
            },
        );
    }
}

const CountriesService = new Countries('https://diswdgcu9cfva.cloudfront.net');

export default CountriesService;
