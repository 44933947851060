/* eslint-disable max-len */

import { Icon } from 'types';

function PlayIcon({ color = '#3959D9', width = 36, height = 36 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.5 18C33.5 26.5604 26.5604 33.5 18 33.5C9.43959 33.5 2.5 26.5604 2.5 18C2.5 9.43959 9.43959 2.5 18 2.5C26.5604 2.5 33.5 9.43959 33.5 18ZM36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18ZM15.9603 9.34769C15.5132 8.97508 14.8908 8.89476 14.3638 9.14163C13.8367 9.3885 13.5 9.91799 13.5 10.5V25.5C13.5 26.082 13.8367 26.6115 14.3638 26.8584C14.8908 27.1053 15.5132 27.025 15.9603 26.6523L24.9603 19.1523C25.3023 18.8674 25.5 18.4452 25.5 18C25.5 17.5548 25.3023 17.1327 24.9603 16.8477L15.9603 9.34769Z"
                fill={color}
            />
        </svg>
    );
}

export default PlayIcon;
