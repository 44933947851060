import classNames from 'classnames';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';

import Spinner from '../../../Deposit/Paykassma/Other/Button/image/Spinner.svg';
import { PaymentMethod } from '../../../PaymentMethod/types';
import PaykassmaWithdrawService, { PaykassmaWithdrawState } from '../service/PaykassmaWithdraw';
import PhoneFormService from '../service/PhoneForm';

import './WithdrawalButtonMpesa.scss';
import {ChannelType} from '@ay_tsarbet/newbet-core/dist/types';

type WithdrawalButtonMpesaType = {
    buttonTitle: string;
    variation?: boolean;
    paymentMethod?: PaymentMethod;
    amount?: string;
};

function WithdrawalButtonMpesa(props: WithdrawalButtonMpesaType) {
    const { buttonTitle, variation, paymentMethod, amount } = props;
    const valid = useObservable(PhoneFormService.validMpesa, PhoneFormService.validMpesa.getValue());
    const state = useObservable(PaykassmaWithdrawService.state, PaykassmaWithdrawService.state.getValue());

    const min = paymentMethod && paymentMethod.limit.withdraw.min;
    const sumToWithdraw = amount && parseInt(amount) - parseInt(amount) * 0.2;
    const roundTo10 =  sumToWithdraw && Math.ceil(sumToWithdraw/10)*10;
    const withdrawalByMethod = paymentMethod && paymentMethod.id === ChannelType.PayTM ? roundTo10 : sumToWithdraw;
    const disabledFee = withdrawalByMethod && min && withdrawalByMethod >= min;

    const className = classNames('withdrawal-button-paykassma', {
        'withdrawal-button-paykassma__active': valid || (!variation && disabledFee),
        'withdrawal-button-paykassma__variated': variation && !disabledFee,
    });

    return (
        <button type="submit" className={className} disabled={!valid || (variation && !disabledFee)}>
            {state === PaykassmaWithdrawState.AWAIT ? (
                <img className="withdrawal-button-paykassma__wait" src={Spinner} alt="" />
            ) : (
                t(buttonTitle)
            )}
        </button>
    );
}

export default WithdrawalButtonMpesa;
