import { translate as t } from "service/Language";
import WelcomeBanner from "../images/welcome.png";
import useObservable from "utils/useObservable";
import UserDataService from "service/UserData";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import Timer from "component/BonusWidget/Timer/Timer";
import { HistoryService } from "service/HistoryService";
import RouteConfig from "appConstants/RouteConfig";
import { format, secondsToHours } from "date-fns";

import "./WelcomeBonusItem.scss";
import classNames from "classnames";
import { finished } from "stream";

const WelcomeBonusItem = () => {
  const userData = useObservable(
    UserDataService.data,
    UserDataService.data.getValue()
  );
  const [isOpen, setOpenCollapse] = useState(false);
  const createdAt = useMemo(() => {
    if (userData && userData.registrationDate) {
      return dayjs(new Date(userData.registrationDate * 1000))
        .add(1, "hour")
        .toDate();
    }
    return "";
  }, [userData]);

  const bonusClass = classNames("BonusItem", {
    flip: isOpen,
    inactive: !isOpen,
  });

  const currentTime = dayjs().unix();

  const finishDate = currentTime - dayjs(createdAt).unix();

  const isOverTime = finishDate > 3600;

  const handleGet = () => {
    HistoryService.setHash(RouteConfig.hash.deposit);
  };

  console.log("finishDate", finishDate);

  return (
    <div className={bonusClass}>
      {!isOpen ? (
        <div className="WelcomeBonusItem">
          <div className="WelcomeBonusItem__header">
            <div className="WelcomeBonusItem__header_title">
              {t("bonus.welcome.card.header")}
            </div>
            <div className="BonusItem__header_more">
              {true && (
                <button className="tip" onClick={() => setOpenCollapse(true)} />
              )}
            </div>
          </div>
          <div className="WelcomeBonusItem__body">
            <div className="WelcomeBonusItem__info">
              <div className="WelcomeBonusItem__info_game">
                <img src={WelcomeBanner} alt={t("bonus.welcome.card.header")} />
              </div>
              <div className="WelcomeBonusItem__info_data">
                <div className="WelcomeBonusItem__title">
                  {t("bonus.welcome.card.name")}
                </div>
                <div className="WelcomeBonusItem__game">
                  {t("bonus.info.anyslots")}
                </div>

                <div className="WelcomeBonusItem__expiration">
                  {!isOverTime && (
                    <Timer
                      finish={createdAt ? createdAt : null}
                      className="WelcomeBonusItem__expiration_timer"
                    />
                  )}
                </div>

                <div className="WelcomeBonusItem__bonus">
                  {!isOverTime && (
                    <div className="WelcomeBonusItem__bonus_new">125%</div>
                  )}
                  <div className={"WelcomeBonusItem__bonus_line"}>
                    <div
                      className={
                        !isOverTime
                          ? "WelcomeBonusItem__bonus_old"
                          : "WelcomeBonusItem__bonus_new"
                      }
                    >
                      100%
                    </div>
                    <div className="WelcomeBonusItem__bonus_text">
                      +250 Free Spins
                    </div>
                  </div>
                </div>

                <div className="WelcomeBonusItem__desc">
                  {t("bonus.welcome.card.deposit.description")}
                </div>
              </div>
            </div>
            <div className="WelcomeBonusItem__controls">
              <button
                className="WelcomeBonusItem__controls_primary"
                onClick={handleGet}
              >
                {t("bonus.welcome.card.get")}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="BonusItem__back">
          <div className="BonusItem__back_title">
            {t("bonus.info.header")}{" "}
            <button className="close" onClick={() => setOpenCollapse(false)} />
          </div>
          <div className="BonusItem__back_active">
            <div className="BonusItem__back_desc_bold">
              {t("bonus.info.valid")}
            </div>
            <div className="BonusItem__back_desc_text text">
              {finishDate &&
                `${dayjs(finishDate * 1000).format("HH:mm DD.MM")}`}
            </div>
          </div>
          <div className="BonusItem__back_desc">
            <span className="BonusItem__back_desc_text">
              {t("bonus.info.desc.1")} {t("bonus.info.desc.2")}&nbsp;
            </span>
            <span className="BonusItem__back_desc_bold">
              {/* {t("bonus.info.desc.3.dep")} {getRemainderRolled()} */}
              &nbsp;
            </span>
            <span className="BonusItem__back_desc_text">
              {t("bonus.info.desc.4")} {t("bonus.info.desc.5")}
              &nbsp;
              {t("bonus.info.desc.6")}&nbsp;
            </span>
            <span className="BonusItem__back_desc_bold">
              {t("bonus.info.desc.7")}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default WelcomeBonusItem;
