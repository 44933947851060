import classnames from 'classnames';
import { differenceInCalendarDays, intervalToDuration, isBefore } from 'date-fns';
import { Component } from 'react';
import { generatePath, NavLink } from 'react-router-dom';

import RouteConfig from '../../../appConstants/RouteConfig';
import LanguageService, { translate as t } from '../../../service/Language';
import Tournament from '../../../service/model/Tournament';
import { formatCurrencySign } from '../../../utils/format';
import Period from './Period';
import Timer from './Timer';
import { RemainingTime } from './type';
import GoogleService from '../../../service/Google';
import UserDataService from '../../../service/UserData';

import './Face.scss';

const zeroTime: RemainingTime = {
    day: 0,
    hour: 0,
    minute: 0,
    second: 0,
};

type CardProps = {
    tournament: Tournament;
    index: number;
    className?: string;
};
type CardState = {
    remainingTime: RemainingTime;
    flip: boolean;
    disabled: boolean;
};

class Face extends Component<CardProps, CardState> {
    timer: {
        tick: NodeJS.Timeout | null;
    } = {
        tick: null,
    };

    handler = {
        tick: this.tick.bind(this),
    };

    constructor(props: CardProps) {
        super(props);

        const remainingTime = this.getRemainingTime();

        this.state = {
            remainingTime: remainingTime ?? zeroTime,
            flip: false,
            disabled: remainingTime === null,
        };
    }

    getRemainingTime(): RemainingTime | null {
        const start = new Date();
        const end = this.props.tournament.endDate;

        if (isBefore(end, start)) {
            return null;
        }

        const duration = Object.assign(
            { days: 0, hours: 0, minutes: 0, seconds: 0 },
            intervalToDuration({ start, end })
        );

        return {
            day: differenceInCalendarDays(end, start),
            hour: duration.hours,
            minute: duration.minutes,
            second: duration.seconds,
        };
    }

    tick() {
        const remainingTime = this.getRemainingTime();
        if (remainingTime === null) {
            this.stopTimer();
            this.setState({
                remainingTime: zeroTime,
                disabled: true,
            });
        } else {
            this.setState({ remainingTime });
        }
    }

    startTimer() {
        this.stopTimer();
        this.timer.tick = setInterval(this.handler.tick, 1000);
    }

    stopTimer() {
        if (this.timer.tick !== null) {
            clearInterval(this.timer.tick);
            this.timer.tick = null;
        }
    }

    componentDidMount() {
        this.startTimer();
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    onClickHandler(name: string, tournamentId: number, userId: string, email: string) {
        GoogleService.startTournament(name, tournamentId, userId ? userId : '', email ? email : '');
    }

    render() {
        const { tournament, className, index } = this.props;
        const { remainingTime, disabled } = this.state;

        if (!tournament) {
            return null;
        }

        const oddBgClass = ['card-face__1of4', 'card-face__2of4', 'card-face__3of4', 'card-face__4of4'][index % 4];
        const oddButtonClass = ['card-face__1of3', 'card-face__2of3', 'card-face__3of3'][index % 3];

        const _className = classnames({
            [className || '']: !!className,
            'card-face': true,
            'card-face____disabled': disabled,
            [oddBgClass]: true,
            [oddButtonClass]: true,
        });

        const lang = LanguageService.current.getValue();

        const imageSet = tournament.getLocalImageSet(lang.title);

        const style = { backgroundImage: 'url("' + imageSet.mainPreview + '")' };

        const link = generatePath(RouteConfig.casino.tournamentId, { id: tournament.id });

        const userData = UserDataService.data.getValue();
        const userId = userData ? userData.id.toString() : '';
        const email = userData ? userData.email : '';

        return (
            <div className={_className}>
                <div className="card-face__image-box" style={style} />
                <div className="card-face__title">{tournament.name}</div>
                <div className="card-face__hint">{t('tournament.card.prize_fund')}</div>
                <div className="card-face__fund">
                    {formatCurrencySign(tournament.getPrizeFund(), 0, tournament.currency)}
                </div>
                {disabled ? <Period tournament={tournament} /> : <Timer remaining={remainingTime} />}
                <div className="card-face__controls">
                    <NavLink to={link} className="card-face__participate"
                             onClick={() => this.onClickHandler(tournament.name, tournament.id, userId, email)}>
                        {t('tournament.card.participate')}
                    </NavLink>
                </div>
            </div>
        );
    }
}

export default Face;
