import { BehaviorSubject } from 'rxjs';

import GamesService from './Games';
import GoogleService from './Google';
import Game, { GameList } from './model/Game';
import WSBet from './WSBet';

class FavoriteGame {
    public list = new BehaviorSubject<GameList>([]);

    public idList = new BehaviorSubject<number[]>([]);

    addFavoriteGame(game: Game) {
        const list = this.list.getValue();
        if (!list.includes(game)) {
            list.push(game);
            GoogleService.addFavorite(game.name, game.provider.name);
        }
        this.list.next(list);
        this.sent(list);
    }

    removeFavoriteGame(game: Game) {
        const list = this.list.getValue();
        const index = list.indexOf(game);
        if (index !== -1) {
            GoogleService.removeFavorite(game.name, game.provider.name);
            list.splice(index, 1);
        }
        this.list.next(list);
        this.sent(list);
    }

    onReceive(favorite: string) {
        if (favorite) {
            const idList = JSON.parse(favorite) as number[];
            this.idList.next(idList);
            const games = GamesService.games.getValue().filter((game) => idList.includes(game.id));
            this.list.next(games);
        } else {
            this.idList.next([]);
        }
    }

    sent(list: GameList) {
        const idList = list.map((game) => game.id);
        WSBet.storage.sent(idList);
    }
}
const FavoriteGameService = new FavoriteGame();
export default FavoriteGameService;
