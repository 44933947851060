import { KeyboardEvent } from 'react';

import ForgotPassword from './ForgotPassword/ForgotPassword';
import Header from './Header/Header';
import Email from './Input/Email';
import Password from './Input/Password';
import Register from './Register/Register';
import Submit from './service/Submit';
import SignInButton from './SignInButton/SignInButton';

import './LogIn.scss';

function LogIn() {
    function submitByEnter(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            Submit(event);
        }
    }

    return (
        <form className="log-in" onKeyDown={(event: KeyboardEvent) => submitByEnter(event)} onSubmit={Submit}>
            <Header />
            <Email />
            <Password />
            <SignInButton />
            <ForgotPassword />
            <Register />
        </form>
    );
}

export default LogIn;
