import { translate as t } from '../../../../../../service/Language';
import useObservable from '../../../../../../utils/useObservable';
import PaykassmaDepositService from '../../Paykassma/service/PaykassmaDeposit';
import { ButtonPayments } from '../../../../../../appConstants/ButtonPayments';

import './PaymentHeader.scss';

function PaymentHeader() {
    const paymentType = useObservable(PaykassmaDepositService.paymentType, PaykassmaDepositService.paymentType.getValue());
    const payment = ButtonPayments.find(item => item.id === paymentType);

    return (
        payment ?
            <div className="PaymentHeader">
                <img className="PaymentHeader__img" src={payment.logo} alt={payment.title}/>
                <h2 className="PaymentHeader__title">{t(payment.title)}</h2>
            </div>
            : null
    )
}

export default PaymentHeader;
