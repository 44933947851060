import Input from 'component/Input/service/Input';

import Validator from '../../../service/Validator/Validator';

const ConfirmPasswordInputService = new Input({
    title: 'recovery.input.password.again',
    validator: Validator.nonEmpty,
});

export default ConfirmPasswordInputService;
