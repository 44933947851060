import {SvgNames} from '../../types';
import Game from './Game';

export enum GameCategoryId {
    All = -4,
    Popular = -3,
    New = -2,
    Favorite = -1,
    Other = 0,
    Slots = 1,
    Table = 2,
    Lottery = 3,
    VideoPoker = 4,
    Roulette = 5,
    BlackJack = 6,
    Scratch = 7,
    Poker = 8,
    Bingo = 9,
    Bakkara = 11,
    Arcade = 12,
    Keno = 13,
    Instant = 14,
    Glory = 15,
}

class GameCategory {
    gameList: Game[] = [];

    constructor(public id: number, public name: string, public route: string, public image: SvgNames) {
    }

    getView() {
        return {
            id: this.id,
            name: this.name,
            route: this.route,
            gameList: this.gameList.length,
        };
    }

    addGame(game: Game) {
        if (!this.gameList.includes(game)) {
            this.gameList.push(game);
        }
    }
}

const gameCategory = {
    Popular: new GameCategory(GameCategoryId.Popular, 'casino.sidebar.popular', '', 'CasinoPopular'),
    All: new GameCategory(GameCategoryId.All, 'casino.sidebar.all', 'all', 'CasinoNew'),
    New: new GameCategory(GameCategoryId.New, 'casino.sidebar.new', 'new', 'CasinoNew'),
    Favorite: new GameCategory(GameCategoryId.Favorite, 'casino.sidebar.favorite', 'favorite', 'CasinoFavorite'),
    Glory: new GameCategory(GameCategoryId.Glory, 'Glory Games', 'glory', 'GloryGames'),
    Slots: new GameCategory(GameCategoryId.Slots, 'casino.sidebar.slots', 'slots', 'CasinoSlots'),
    Table: new GameCategory(GameCategoryId.Table, 'casino.sidebar.table', 'tableGames', 'CasinoTableGames'),
    Lottery: new GameCategory(GameCategoryId.Lottery, 'casino.sidebar.lottery', 'lottery', 'CasinoLottery'),
    VideoPoker: new GameCategory(
        GameCategoryId.VideoPoker,
        'casino.sidebar.poker.video',
        'videoPoker',
        'CasinoVideoPoker'
    ),
    Roulette: new GameCategory(GameCategoryId.Roulette, 'casino.sidebar.poker.roulette', 'roulette', 'CasinoRoulette'),
    BlackJack: new GameCategory(
        GameCategoryId.BlackJack,
        'casino.sidebar.poker.blackjack',
        'blackjack',
        'CasinoBlackjack'
    ),
    Scratch: new GameCategory(
        GameCategoryId.Scratch,
        'casino.sidebar.poker.scratch',
        'scratchGame',
        'CasinoScratchGame'
    ),
    Poker: new GameCategory(GameCategoryId.Poker, 'casino.sidebar.poker.poker', 'poker', 'CasinoPoker'),
    Bingo: new GameCategory(GameCategoryId.Bingo, 'casino.sidebar.poker.bingo', 'bingo', 'CasinoBingo'),
    // Sport: new GameCategory(GameCategoryId.Sport, 'casino.sidebar.poker.sport', 'v-sport', 'CasinoBetting'),
    Bakkara: new GameCategory(GameCategoryId.Bakkara, 'casino.sidebar.poker.bakkara', 'baccarat', 'CasinoBaccarat'),
    Arcade: new GameCategory(GameCategoryId.Arcade, 'casino.sidebar.poker.arcade', 'arcade', 'CasinoArcade'),
    Keno: new GameCategory(GameCategoryId.Keno, 'casino.sidebar.poker.keno', 'keno', 'CasinoKeno'),
    Instant: new GameCategory(GameCategoryId.Instant, 'casino.sidebar.poker.instant', 'instant', 'CasinoInstant'),
    Other: new GameCategory(GameCategoryId.Other, 'casino.sidebar.poker.other', 'other', 'CasinoOther'),
};

const gameCategoryOrder = [
    gameCategory.Popular,
    gameCategory.Favorite,
    gameCategory.Glory,
    gameCategory.Slots,
    gameCategory.Table,
    gameCategory.Lottery,
    gameCategory.VideoPoker,
    gameCategory.Roulette,
    gameCategory.BlackJack,
    gameCategory.Scratch,
    gameCategory.Poker,
    gameCategory.Bingo,
    gameCategory.Bakkara,
    gameCategory.Arcade,
    gameCategory.Keno,
    gameCategory.Instant,
    gameCategory.Other,
];
// Список всех категорий, включая скрытые
const gameCategoryList = [gameCategory.All, ...gameCategoryOrder];

const gameCategoryMap = new Map<number, GameCategory>(gameCategoryOrder.map((category) => [category.id, category]));

export { gameCategory, gameCategoryList, gameCategoryMap, gameCategoryOrder };
export default GameCategory;
