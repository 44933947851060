
const insertInArray = (arr: string[], index: number, item: string)=> {
    return [
        ...arr.slice(0, index),     // первая половина
        item,                       // элементы для вставки
        ...arr.slice(index)         // Вторая половина
    ];
};

export { insertInArray };
