/* eslint-disable max-len */

import { Icon } from 'types';

function SuccessCheck({ color = '#2EBA4D', width = 19, height = 14 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                d="M17.4266 0.262695L18.7328 1.54698C18.9109 1.72211 19 1.93616 19 2.18913C19 2.44209 18.9109 2.65614 18.7328 2.83127L9.47031 11.938C9.43073 12.1521 9.35156 12.3272 9.23281 12.4634L7.92656 13.7477C7.70885 13.9618 7.44167 14.0396 7.125 13.9812C6.80833 14.0396 6.54115 13.9618 6.32344 13.7477L5.01719 12.4634C4.87865 12.3272 4.79948 12.1521 4.77969 11.938L0.267188 7.5306C0.0890625 7.33601 0 7.11224 0 6.85927C0 6.6063 0.0890625 6.39226 0.267188 6.21713L1.57344 4.93284C1.75156 4.75771 1.96927 4.67014 2.22656 4.67014C2.48385 4.67014 2.70156 4.75771 2.87969 4.93284L7.125 9.07759L16.0906 0.262695C16.2885 0.0875652 16.5112 0 16.7586 0C17.006 0 17.2286 0.0875652 17.4266 0.262695Z"
                fill={color}
            />
        </svg>
    );
}

export default SuccessCheck;
