import { HistoryService } from 'service/HistoryService';
import { translate as t } from 'service/Language';

import './WithdrawCloseButton.scss';

type WithdrawCloseButtonProps = {
    title: string;
};

const WithdrawCloseButton = (props: WithdrawCloseButtonProps) => {
    return (
        <button className="withdraw-close-button" onClick={() => HistoryService.clearHash()}>
            {t(props.title)}
        </button>
    );
};

export default WithdrawCloseButton;
