import { ChannelType, Direction, Status } from '@ay_tsarbet/newbet-core/dist/types';

export const OPERATIONS = {
    SLOTEGRATOR_DEPOSIT: ChannelType.SLOTEGRATOR_DEPOSIT,
    SLOTEGRATOR_FREESPIN: ChannelType.SLOTEGRATOR_FREESPIN,
    // SLOTEGRATOR_NEWYEAR: ChannelType.SLOTEGRATOR_NEWYEAR,
};

export const DIRECTIONS_NAME = {
    [Direction.REFILL]: 'profile.balance.history.model.type.deposit',
    [Direction.WITHDRAW]: 'profile.balance.history.model.type.withdraw',
    [Direction.UNDEFINED]: '',
};

export const STATUS_NAME = {
    [Status.CANCELED]: 'profile.balance.history.model.result.canceled',
    [Status.CREATED]: 'profile.balance.history.model.result.pending',
    [Status.SUCCESS]: 'profile.balance.history.model.result.success',
    [Status.FAIL]: 'profile.balance.history.model.result.error',
};
