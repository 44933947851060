import { BehaviorSubject } from 'rxjs';

import az from '../lang/az.json';
import bn from '../lang/bn.json';
import en from '../lang/en.json';
import flb from '../lang/flb.json';
import kz from '../lang/kz.json';
import mm from '../lang/mm.json';
import ru from '../lang/ru.json';
import tr from '../lang/tr.json';
import si from '../lang/si.json';
import uz from '../lang/uz.json';
import pt from '../lang/pt.json';
import Storage from './Storage';

type Dictionary = Record<string, string>;

export type LanguageType = {
    title: string;
    dictionary: Dictionary;
};

class Language {
    public STORAGE_KEY = 'language';
    public LINK_STORAGE_KEY = 'languageFromLink';

    public current: BehaviorSubject<LanguageType>;

    list: LanguageType[] = [
        { title: 'EN', dictionary: en as Dictionary },
        { title: 'RU', dictionary: ru as Dictionary },
        { title: 'BN', dictionary: bn as Dictionary },
        { title: 'TR', dictionary: tr as Dictionary },
        { title: 'UZ', dictionary: uz as Dictionary },
        { title: 'KZ', dictionary: kz as Dictionary },
        { title: 'MM', dictionary: mm as Dictionary },
        { title: 'SI', dictionary: si as Dictionary },
        { title: 'AZ', dictionary: az as Dictionary },
        { title: 'PT', dictionary: pt as Dictionary },
    ];

    constructor() {
        let locale = this.getLang();

        try {
            locale = Storage.get(this.STORAGE_KEY) as string;
        } catch (e) {
            Storage.set(this.STORAGE_KEY, locale);
        }

        const map = new Map<string, LanguageType>(
            this.list.map(language => [language.title, language]),
        );
        const language = map.get(locale) ?? this.list[0];

        this.current = new BehaviorSubject(language);
    }

    getLang() {
        const locale = navigator.language;
        let [lang] = locale.split('-');

        const searchParams = new URLSearchParams(window.location.search);
        const urlLang = searchParams.get('lang');

        if (urlLang) {
            lang = urlLang;
        }

        return lang.toUpperCase();
    }

    setLanguageFromLink(language: string) {
        Storage.set(this.LINK_STORAGE_KEY, language);
    }

    setLanguage(language: LanguageType) {
        Storage.set(this.STORAGE_KEY, language.title);
        this.current.next(language);
    }

    translateInto(key: string, language: LanguageType): string {
        let string = '';
        const fallback = flb as Dictionary;

        if (language.dictionary.hasOwnProperty(key)) {
            string = language.dictionary[key];
        } else {
            string = fallback.hasOwnProperty(key) ? fallback[key] : key;
        }

        return string;
    }

    translate(key: string) {
        return this.translateInto(key, this.current.getValue());
    }
}

const LanguageService = new Language();

function translate(key: string): string {
    return LanguageService.translate(key);
}

export { translate };
export default LanguageService;
