import { translate as t } from 'service/Language';

import './Privacy.scss';

type ListType = {
    id: number;
    header: string;
    title: string;
    description: string;
};

const textList: ListType[] = [
    { id: 1, header: 'privacy.header', title: '', description: 'privacy.description' },
    { id: 2, header: 'privacy.collected.header', title: '', description: 'privacy.collected.description' },
    { id: 3, header: 'privacy.collecting.header', title: '', description: 'privacy.collecting.description' },
    { id: 4, header: 'privacy.information.header', title: '', description: 'privacy.information.description' },
    { id: 5, header: 'privacy.certain.header', title: '', description: 'privacy.certain.description' },
    { id: 6, header: 'privacy.access.header', title: '', description: 'privacy.access.description' },
    {
        id: 7,
        header: 'privacy.cookies.header',
        title: 'privacy.cookies.device.title',
        description: 'privacy.cookies.device.description',
    },
    {
        id: 71,
        header: '',
        title: 'privacy.cookies.strictly.title',
        description: 'privacy.cookies.strictly.description',
    },
    {
        id: 72,
        header: '',
        title: 'privacy.cookies.registration.title',
        description: 'privacy.cookies.registration.description',
    },
    {
        id: 73,
        header: '',
        title: 'privacy.cookies.website.title',
        description: 'privacy.cookies.website.description',
    },
    {
        id: 74,
        header: '',
        title: 'privacy.cookies.flash.title',
        description: 'privacy.cookies.flash.description',
    },
    { id: 8, header: 'privacy.providers.header', title: '', description: 'privacy.providers.description' },
    { id: 9, header: 'privacy.consent.header', title: '', description: 'privacy.consent.description' },
    { id: 10, header: 'privacy.security.header', title: '', description: 'privacy.security.description' },
    { id: 11, header: 'privacy.protection.header', title: '', description: 'privacy.protection.description' },
    { id: 12, header: 'privacy.transfers.header', title: '', description: 'privacy.transfers.description' },
    { id: 13, header: 'privacy.practices.header', title: '', description: 'privacy.practices.description' },
    { id: 14, header: 'privacy.disclaimer.header', title: '', description: 'privacy.disclaimer.description' },
    { id: 15, header: 'privacy.policy.header', title: '', description: 'privacy.policy.description' },
    { id: 16, header: 'privacy.other.header', title: '', description: 'privacy.other.description' },
];

function Privacy() {
    return (
        <div className="privacy">
            {textList.map((item) => (
                <>
                    {item.header && <p className="privacy__header">{t(item.header)}</p>}
                    {item.title && <p className="privacy__title">{t(item.title)}</p>}
                    <p className="privacy__description">{t(item.description)}</p>
                </>
            ))}
        </div>
    );
}

export default Privacy;
