import { translate as t } from 'service/Language';

import CasinoLinkList from './CasinoLinkList/CasinoLinkList';
import CasinoLinkMenu from './CasinoLinkMenu/CasinoLinkMenu';
import Contacts from './Contacts/Contacts';
import Information from './Information/Information';
import InformationMenu from './InformationMenu/InformationMenu';
import Licence from './License/License';
import LogoPartners from './LogoPartners/LogoPartners';
import PaymentPartners from './Partners/PaymentPartners';
import SocialNetwork from './SocialNetwork/SocialNetwork';

import './Footer.scss';
import classnames from 'classnames';

type FooterProps = {
    isNewYearPromo?: boolean;
}

function Footer({ isNewYearPromo }: FooterProps) {
    const wrapperClass =  classnames("footer-wrapper", {
        "footer-wrapper_year": isNewYearPromo,
    })
    return (
        <div className="footer">
            <div className={wrapperClass}>
                <div className="footer__contacts____tablet">
                    <Contacts />
                </div>
                <div className="footer__container">
                    <div className="footer__desktop">
                        <CasinoLinkList />
                        <Information />
                        <div className="footer-contacts__desktop">
                            <span className="footer-contacts__header">{t('footer.support.header')}</span>
                            <Contacts />
                            <SocialNetwork />
                        </div>
                    </div>
                    <div className="footer__tablet">
                        <CasinoLinkMenu />
                        <InformationMenu />
                    </div>
                </div>
                <PaymentPartners />
                <LogoPartners />
                <Licence />
            </div>
        </div>
    );
}

export default Footer;
