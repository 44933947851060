import { translate as t } from 'service/Language';

import LicenceCard from './LicenceCard/LicenceCard';

import './Licence.scss';

function Licence() {
    return (
        <div className="licence">
            <span className="licence__title">{t('info.about.licence.header')}</span>
            <LicenceCard />
        </div>
    );
}

export default Licence;
