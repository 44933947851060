import Validator from '../interface/Validator';

class ZoftValue implements Validator {
    validate(value: string): boolean {
        return new RegExp('^[A-Za-z]+$').test(value);
    }
}
const ZoftValueService = new ZoftValue();

export { ZoftValueService };
export default ZoftValue;
