import { useEffect, useMemo, useState } from 'react';
import { translate as t } from 'service/Language';

import { WalletType } from '../../../service/PaykassmaDeposit';
import Cash from './image/cash.svg';
import Money from './image/money.svg';
import Payment from './image/payment.svg';

import './Wallet.scss';

type InfoWallet = {
    img: string;
    title: string;
};

type WalletProps = {
    type: WalletType;
};

function Wallet({ type }: WalletProps) {
    const infoBlock = {
        agent: {
            img: Cash,
            title: 'profile.balance.deposit.transaction.wallet.cash',
        },
        personal: {
            img: Money,
            title: 'profile.balance.deposit.transaction.wallet.money',
        },
        merchant: {
            img: Payment,
            title: 'profile.balance.deposit.transaction.wallet.payment',
        },
    };

    const info = useMemo<InfoWallet>(() => {
        if (type === WalletType.AGENT) {
            return infoBlock.agent;
        } else if (type === WalletType.PERSONAL) {
            return infoBlock.personal;
        } else {
            return infoBlock.merchant;
        }
    }, [infoBlock.agent, infoBlock.merchant, infoBlock.personal, type]);

    return (
        <div className="wallet">
            <div className="wallet-block">
                <div className="wallet-block__text">{t('profile.balance.deposit.transaction.wallet.before')}</div>
                <div className="wallet-block-after">
                    <span className="wallet-block__text">
                        {t('profile.balance.deposit.transaction.wallet.after.1')}
                    </span>
                    <span className="wallet-block-after__method">&nbsp;{t(info.title)}&nbsp;</span>
                    <span className="wallet-block__text">
                        {t('profile.balance.deposit.transaction.wallet.after.2')}
                    </span>
                </div>
            </div>
            <img className="wallet__img" src={info.img} alt="type" />
            <span className="wallet__title">{t(info.title)}</span>
        </div>
    );
}

export default Wallet;
