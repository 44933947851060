import RouteConfig from "appConstants/RouteConfig";
import CategoryHeader from "component/CategoryHeader";
import { VFC } from "react";
import GamesService from "service/Games";
import useObservable from "utils/useObservable";

import ProviderCard from "./ProviderCard/ProviderCard";

import "./ProviderList.scss";

const ProviderList: VFC = () => {
  const providerList = useObservable(
    GamesService.allowFilter,
    GamesService.allowFilter.getValue()
  );

  return (
    <div className="casino-category-list">
      <CategoryHeader
        title="casino.providers.header"
        backUrl={RouteConfig.casino.root}
      />
      <div className="providers-list">
        {providerList.map((provider) => (
          <ProviderCard provider={provider} key={provider.id} />
        ))}
      </div>
    </div>
  );
};

export default ProviderList;
