import classNames from 'classnames';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';

import RegistrationFormService from '../service/RegistrationForm';

import './SignUpButton.scss';
function SignUpButton() {
    const correct = useObservable(RegistrationFormService.correct, false);

    const className = classNames({
        'sign-up-button': true,
        'sign-up-button____active': correct,
    });

    return (
        <button type="submit" className={className}>
            {t('registration.button')}
        </button>
    );
}

export default SignUpButton;
