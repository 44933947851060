import { useHistory } from 'react-router-dom';
import GoogleService from 'service/Google';
import UserDataService from 'service/UserData';

import RouterConfig from '../../../../../../appConstants/RouteConfig';
import NotificationService from '../../../../../../service/Notifications';
import useObservable from '../../../../../../utils/useObservable';
import image from '../../image';

import './NotificationIcon.scss';

function NotificationIcon() {
    const isNotification = useObservable(NotificationService.info, NotificationService.info.getValue()).filter(
        (item) => item.notice === 0
    ).length;
    const userData = useObservable(UserDataService.data, UserDataService.data.getValue());
    const history = useHistory();
    const notificationRoot = RouterConfig.profile.root + RouterConfig.profile.notification.root;
    const handleClick = () => {
        GoogleService.notification(userData?.id);
        history.push(notificationRoot);
    };
    return (
        <>
            <img onClick={handleClick} src={image.Notification} className="notification-icon" alt="notification" />
            {!!isNotification ? <div className="notification-icon__point" /> : null}
        </>
    );
}

export default NotificationIcon;
