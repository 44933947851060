import { translate as t } from 'service/Language';
import Directa24WithdrawService, { Directa24WithdrawState } from '../../../service/Directa24Withdraw';
import PixFormService from '../../../service/PixForm';
import Failed from './image/failed.svg';

import './FailedPage.scss';

function FailedPage() {
    function onClickHandler() {
        Directa24WithdrawService.state.next(Directa24WithdrawState.INIT)
        PixFormService.amount.autoCompleted.next(true);
        PixFormService.numberCPF.autoCompleted.next(true);
        PixFormService.name.autoCompleted.next(true);
    }
    return (
        <div className="result-pix">
            <div className="result-pix-container">
                <img className="result-pix-container__img" src={Failed} alt="failed" />
                <p className="result-pix-container__title ">{t('popup.payment.error.title')}</p>
                <span className="result-pix-container__text">{t('popup.payment.error.text')}</span>
                <button className="result-pix-container__btn" onClick={onClickHandler}>
                    {t('popup.payment.error.back')}
                </button>
            </div>
        </div>
    );
}

export default FailedPage;
