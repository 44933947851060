import { useState } from 'react';

// Images
import Fruit1 from '../images/fruit-1.webp';
import Fruit2 from '../images/fruit-2.webp';
import Fruit3 from '../images/fruit-3.webp';
import Fruit4 from '../images/fruit-4.webp';
import Fruit5 from '../images/fruit-5.webp';
import Fruit6 from '../images/fruit-6.webp';

import './SlotMachine.scss';
import classNames from 'classnames';
import PopupHelper from 'service/PopupHelper';

type SlotMachineProps = {};

const FRUITS = [
    [Fruit5, Fruit6, Fruit1, Fruit2, Fruit3, Fruit4, Fruit5, Fruit6],
    [Fruit1, Fruit4, Fruit2, Fruit3, Fruit5, Fruit1, Fruit2, Fruit4],
    [Fruit5, Fruit6, Fruit4, Fruit1, Fruit2, Fruit3, Fruit4, Fruit2],
    [Fruit1, Fruit5, Fruit1, Fruit3, Fruit3, Fruit4, Fruit1, Fruit6],
    [Fruit2, Fruit6, Fruit3, Fruit4, Fruit5, Fruit2, Fruit3, Fruit5],
    [Fruit5, Fruit4, Fruit5, Fruit4, Fruit1, Fruit2, Fruit3, Fruit2],
];

const SlotMachine = (props: SlotMachineProps) => {
    const {} = props;

    const [grid, setGrid] = useState<string[][]>(FRUITS);
    const [start, setStart] = useState<boolean | null>(null);
    const [win, setWin] = useState(false);

    const setWinBorder = () => {
        setTimeout(() => {
            setWin(true);
        }, 3300);

        setTimeout(() => {
            PopupHelper.showWheelRegistration('slots');
        }, 3900);
    };

    const handleStart = () => {
        setStart(true);
        setWinBorder();
    };

    const winClass = classNames('SlotMachine__win', {
        show: win,
    });

    return (
        <div className='SlotMachine'>
            <div className='SlotMachine__block'>
                <div className={winClass}></div>
                <div className='SlotMachine__row'>
                    {grid.map((row, i) => {
                        const colClass = classNames('SlotMachine__col', {
                            animate: start,
                        });
                        return (
                            <div
                                key={i}
                                // animate={{ y: '-100px' }}
                                className={colClass}
                            >
                                {row.map((symbol, j) => (
                                    // <div key={`${i}${j}`} className='SlotMachine__img'>
                                    <img key={`${i}${j}`} src={symbol} />
                                    // </div>
                                ))}
                            </div>
                        );
                    })}
                </div>
            </div>
            <button onClick={handleStart} className='SlotMachine__btn'>
                Spin
            </button>
        </div>
    );
};

export default SlotMachine;
