import { useHistory } from 'react-router-dom';
import { translate as t } from 'service/Language';

import AuthService from '../../../service/Auth';
import GoogleService from '../../../service/Google';
import ExitLogo from './image/Exit.svg';

import './Exit.scss';

function Exit() {
    const history = useHistory();
    function handleLogout() {
        const clientInfo = AuthService.auth.getValue();
        if (clientInfo) {
            GoogleService.logout(clientInfo.userId.toString(), clientInfo.email || '');
        }
        AuthService.logout();
        history.push('/');
    }

    return (
        <div className="profile-exit-icon" onClick={handleLogout}>
            <img src={ExitLogo} alt="exit" />
            <span className="profile-exit-icon__title">{t('profile.navigation.exit')}</span>
        </div>
    );
}

export default Exit;
