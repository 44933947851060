import Alliance from "../../assets/upay_logos/AllianceFinanceCompanyPLC.png";
import Amana from "../../assets/upay_logos/AmanaBankPLC.png";
import Asia from "../../assets/upay_logos/AsiaAssetFinancePLC.png";
import BankofCeylon from "../../assets/upay_logos/BankofCeylon.png";
import China from "../../assets/upay_logos/BankofChinaLimited.png";
import Bimputh from "../../assets/upay_logos/BimputhFinancePLC.png";
import CBC from "../../assets/upay_logos/CBCFinanceLimited.png";
import Cargills from "../../assets/upay_logos/CargillsBankLimited.png";
import Central from "../../assets/upay_logos/CentralFinancePLC.png";
import Citi from "../../assets/upay_logos/CitibankNA.png";
import Citizen from "../../assets/upay_logos/CitizenDevelopmentBank.png";
import Commercial from "../../assets/upay_logos/CommercialBankPLC.png";
import DFCC from "../../assets/upay_logos/DFCC Bank PLC.png";
import Deutsche from "../../assets/upay_logos/DeutscheBankAG.png";
import Dialog from "../../assets/upay_logos/DialogFinancePLC.png";
import HDFC from "../../assets/upay_logos/HDFCBank.png";
import HNB from "../../assets/upay_logos/HNBFinancePLC.png";
import HSBC from "../../assets/upay_logos/HSBC.png";
import Habib from "../../assets/upay_logos/HabibBankLimited.png";
import Hatton from "../../assets/upay_logos/HattonNationalBankPLC.png";
import Indian from "../../assets/upay_logos/IndianBank.png";
import Overseas from "../../assets/upay_logos/IndianOverseasBank.png";
import Kanrich from "../../assets/upay_logos/KanrichFinanceLimited.png";
import LBFinance from "../../assets/upay_logos/LBFinancePLC.png";
import LOLC from "../../assets/upay_logos/LOLCDevelopmentFinance.png";
import Lanka from "../../assets/upay_logos/LankaCreditFinanceLTD.png";
import MCB from "../../assets/upay_logos/MCBBankLimited.png";
import Mercantile from "../../assets/upay_logos/MercantileInvestments.png";
import Merchant from "../../assets/upay_logos/MerchantBankPLC.png";
import NationalDevelopment from "../../assets/upay_logos/NationalDevelopmentBank.png";
import NationalSavings from "../../assets/upay_logos/NationalSavingsBank.png";
import People from "../../assets/upay_logos/PeopleLeasingFinance.png";
import Polgahawela from "../../assets/upay_logos/PolgahawelaCo-opBank.png";
import Regional from "../../assets/upay_logos/RegionalDevelopmentBank.png";
import Richard from "../../assets/upay_logos/RichardPiersFinance.png";
import Sampath from "../../assets/upay_logos/SampathBankPLC.png";
import Sarvodhaya from "../../assets/upay_logos/SarvodhayaFinance.png";
import Senkadagala from "../../assets/upay_logos/SenkadagalaFinancePLC.png";
import Seylan from "../../assets/upay_logos/SeylanBankPLC.png";
import Singer from "../../assets/upay_logos/SingerFinancePLC.png";
import Softologic from "../../assets/upay_logos/SoftologicFinancePLC.png";
import StandardChartered from "../../assets/upay_logos/StandardCharteredBank.png";
import StateBank from "../../assets/upay_logos/StateBankofIndia.png";
import StateMortgage from "../../assets/upay_logos/StateMortgageBank.png";
import Colombo from "../../assets/upay_logos/UnionBankOfColomboPLC.png";
import Vallibel from "../../assets/upay_logos/VallibelFinancePLC.png";
import Sanasa from "../../assets/upay_logos/SanasaDevelopmentBank.png";
import Fintrex from "../../assets/upay_logos/FintrexFinanceLimited.png";
import PanAsia from "../../assets/upay_logos/Pan-asia.webp";
import Peoples from "../../assets/upay_logos/Peoples-bank.webp";
import Public from "../../assets/upay_logos/Public-bank.webp";
import LOLCFinance from "../../assets/upay_logos/Lolc-finance.webp";

export type BankType = {
  id: number;
  name: string;
  img: string;
};

export const BANK_LIST_UPAY: BankType[] = [
  {
    id: 1,
    name: "Alliance Finance Company PLC",
    img: Alliance,
  },
  {
    id: 2,
    name: "Amana Bank PLC",
    img: Amana,
  },
  {
    id: 3,
    name: "Asia Asset Finance PLC",
    img: Asia,
  },
  {
    id: 4,
    name: "Bank of Ceylon",
    img: BankofCeylon,
  },
  {
    id: 5,
    name: "Bank of China Limited",
    img: China,
  },
  {
    id: 6,
    name: "Bimputh Finance PLC",
    img: Bimputh,
  },
  {
    id: 7,
    name: "CBC Finance Limited",
    img: CBC,
  },
  {
    id: 8,
    name: "Cargills Bank Limited",
    img: Cargills,
  },
  {
    id: 9,
    name: "Central Finance PLC",
    img: Central,
  },
  {
    id: 10,
    name: "Citi bank N. A.",
    img: Citi,
  },
  {
    id: 11,
    name: "Citizen Development Bank",
    img: Citizen,
  },
  {
    id: 12,
    name: "Commercial Bank PLC",
    img: Commercial,
  },
  {
    id: 13,
    name: "DFCC Bank PLC",
    img: DFCC,
  },
  {
    id: 14,
    name: "Deutsche Bank AG",
    img: Deutsche,
  },
  {
    id: 15,
    name: "Dialog Finance PLC",
    img: Dialog,
  },
  {
    id: 16,
    name: "HDFC Bank",
    img: HDFC,
  },
  {
    id: 17,
    name: "HNB Finance PLC",
    img: HNB,
  },
  {
    id: 18,
    name: "HSBC",
    img: HSBC,
  },
  {
    id: 19,
    name: "Habib Bank Limited",
    img: Habib,
  },
  {
    id: 20,
    name: "Hatton National Bank PLC",
    img: Hatton,
  },
  {
    id: 21,
    name: "Indian Bank",
    img: Indian,
  },
  {
    id: 22,
    name: "Indian Overseas Bank",
    img: Overseas,
  },
  {
    id: 23,
    name: "Kanrich Finance Limited",
    img: Kanrich,
  },
  {
    id: 24,
    name: "L B Finance PLC",
    img: LBFinance,
  },
  {
    id: 25,
    name: "LOLC Development Finance",
    img: LOLC,
  },
  {
    id: 26,
    name: "Lanka Credit Finance LTD",
    img: Lanka,
  },
  {
    id: 27,
    name: "MCB Bank Limited",
    img: MCB,
  },
  {
    id: 28,
    name: "Mercantile Investments",
    img: Mercantile,
  },
  {
    id: 29,
    name: "Merchant Bank PLC",
    img: Merchant,
  },
  {
    id: 30,
    name: "National Development Bank",
    img: NationalDevelopment,
  },
  {
    id: 31,
    name: "National Savings Bank",
    img: NationalSavings,
  },
  {
    id: 32,
    name: "People Leasing Finance",
    img: People,
  },
  {
    id: 33,
    name: "Polgahawela Co-op Bank",
    img: Polgahawela,
  },
  {
    id: 34,
    name: "Regional Development Bank",
    img: Regional,
  },
  {
    id: 35,
    name: "Richard Piers Finance",
    img: Richard,
  },
  {
    id: 36,
    name: "Sampath Bank PLC",
    img: Sampath,
  },
  {
    id: 37,
    name: "Sarvodhaya Finance",
    img: Sarvodhaya,
  },
  {
    id: 38,
    name: "Senkadagala Finance PLC",
    img: Senkadagala,
  },
  {
    id: 39,
    name: "Seylan Bank PLC",
    img: Seylan,
  },
  {
    id: 40,
    name: "Singer Finance PLC",
    img: Singer,
  },
  {
    id: 41,
    name: "Softologic Finance PLC",
    img: Softologic,
  },
  {
    id: 42,
    name: "Standard Chartered Bank",
    img: StandardChartered,
  },
  {
    id: 43,
    name: "State Bank of India",
    img: StateBank,
  },
  {
    id: 44,
    name: "State Mortgage Bank",
    img: StateMortgage,
  },
  {
    id: 45,
    name: "Union Bank Of Colombo PLC",
    img: Colombo,
  },
  {
    id: 46,
    name: "Vallibel Finance PLC",
    img: Vallibel,
  },
  {
    id: 47,
    name: "Sanasa Development Bank",
    img: Sanasa,
  },
  {
    id: 48,
    name: "Fintrex Finance Limited",
    img: Fintrex,
  },
  {
    id: 49,
    name: "LOLC Finance PLC",
    img: LOLCFinance,
  },
  {
    id: 50,
    name: "Pan Asia Bank PLC",
    img: PanAsia,
  },
  {
    id: 51,
    name: "Peoples Bank",
    img: Peoples,
  },
  {
    id: 52,
    name: "Public Bank Berha”",
    img: Public,
  },
];

/*
1 Alliance Finance Company PLC
2 Amana Bank PLC
3 Asia Asset Finance PLC
4 Bank of Ceylon
5 Bank of China Limited
6 Bimputh Finance PLC
7 CBC Finance Limited
8 Cargills Bank Limited
9 Central Finance PLC
10 Citi bank N. A.
11 Citizen Development Bank
12 Commercial Bank PLC
13 DFCC Bank PLC
14 Deutsche Bank AG
15 Dialog Finance PLC
16 HDFC Bank
17 HNB Finance PLC
18 HSBC
19 Habib Bank Limited
20 Hatton National Bank PLC
21 Indian Bank
22 Indian Overseas Bank
23 Kanrich Finance Limited
24 L B Finance PLC
25 LOLC Development Finance
26 Lanka Credit Finance LTD
27 MCB Bank Limited
28 Mercantile Investments
29 Merchant Bank PLC
30 National Development Bank
31 National Savings Bank
32

*/
