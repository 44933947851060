import { PaymentMethod } from '../../../PaymentMethod/types';
import { PaykassmaWithdrawState } from '../../Paykassma/service/PaykassmaWithdraw';
import Bankalfallah from './Bankalfallah/Bankalfallah';
import Easypaisa from './Easypaisa/Easypaisa';
import ApayBDT from './ApayBDT/ApayBDT';
import ApayIMPS from './IMPS/IMPS';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';

type FormProps = {
    paymentMethod: PaymentMethod;
    status: PaykassmaWithdrawState;
};

function Form(props: FormProps) {
    const { paymentMethod, status } = props;

    if (paymentMethod.id === ChannelType.Easypaisa) {
        return <Easypaisa paymentMethod={paymentMethod} status={status} />;
    }
    if (paymentMethod.id === ChannelType.Bankalfalah) {
        return <Bankalfallah paymentMethod={paymentMethod} status={status} />;
    }
    if (paymentMethod.id === ChannelType.APAY_BDT || paymentMethod.id === ChannelType.UPAY_APAY || paymentMethod.id === ChannelType.BKASH_APAY|| paymentMethod.id === ChannelType.NAGAD_APAY) {
        return <ApayBDT paymentMethod={paymentMethod} status={status} />;
    }
    if (paymentMethod.id === ChannelType.APAY_IMPS) {
        return <ApayIMPS paymentMethod={paymentMethod} status={status} />;
    } else return null;
}

export default Form;
