/* eslint-disable max-len */

import { Icon } from 'types';

function UserPlaceholder({ color = '#9FA0A8', secondColor = '#FFFFFF', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
            <path
                d="M22.75 0H5.25C2.35051 0 0 2.35051 0 5.25V22.75C0 25.6495 2.35051 28 5.25 28H22.75C25.6495 28 28 25.6495 28 22.75V5.25C28 2.35051 25.6495 0 22.75 0Z"
                fill={color}
            />
            <path
                d="M14 5.25C11.4557 5.25 9.38574 7.31997 9.38574 9.86426C9.38574 12.4086 11.4557 14.4785 14 14.4785C16.5443 14.4785 18.6143 12.4086 18.6143 9.86426C18.6143 7.31997 16.5443 5.25 14 5.25Z"
                fill={secondColor}
            />
            <path
                d="M19.741 17.493C18.4778 16.2103 16.8031 15.5039 15.0254 15.5039H12.9746C11.197 15.5039 9.52226 16.2103 8.259 17.493C7.00191 18.7694 6.30957 20.4542 6.30957 22.2373C6.30957 22.5204 6.53914 22.75 6.82229 22.75H21.1777C21.4609 22.75 21.6905 22.5204 21.6905 22.2373C21.6905 20.4542 20.9981 18.7694 19.741 17.493Z"
                fill={secondColor}
            />
        </svg>
    );
}

export default UserPlaceholder;
