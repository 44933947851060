import RouteConfig from 'appConstants/RouteConfig';
import { HistoryService } from 'service/HistoryService';
import { translate as t } from 'service/Language';
import PopupService from '../../service/Popup';
import Email from './images/email.webp';

import back from '../../page/Profile/Balance/Deposit/DepositPopup/image/back.svg';
import Close from '../Close/Close';
import UserDataService from '../../service/UserData';
import ChangeEmail from '../../page/Profile/Account/PersonalData/ChangeEmail/ChangeEmail';
import AuthService from '../../service/Auth';

import './CheckEmailPopup.scss';

function CheckEmailPopup() {

    function onClickYes() {
        HistoryService.history.push({ hash: RouteConfig.hash.successSentEmail });
        AuthService.sendConfirmEmail();
    }
    function onClickChange() {
        PopupService.show(<ChangeEmail />);
    }

    return (
        <div className="CheckEmailPopup">
            <div className="CheckEmailPopup-header">
                    <button className="CheckEmailPopup-header__back" onClick={(e) => HistoryService.history.goBack()}>
                        <img className="CheckEmailPopup-header__img" src={back} alt="back" />
                    </button>
                <div className="CheckEmailPopup-header__title">
                    {t('check.email.header')}
                </div>
                <Close size="s" />
            </div>
            <div className="CheckEmailPopup-container">
                <img className="CheckEmailPopup-container__img" src={Email} alt="email" />
                <div className="CheckEmailPopup-container-email">
                    <span className="CheckEmailPopup-container-email__title">{t('check.email.your')}</span>
                    <span
                        className="CheckEmailPopup-container-email__desc">{UserDataService.data.getValue()?.email}
                    </span>
                </div>
                <span className="CheckEmailPopup-container__desc">{t('check.email.text')}</span>
                <button className="CheckEmailPopup-container__change" onClick={onClickYes}>
                    {t('check.email.yes')}
                </button>
                <button className="CheckEmailPopup-container__btn" onClick={onClickChange}>
                    {t('check.email.change')}
                </button>
            </div>
        </div>
    );
}

export default CheckEmailPopup;
