import {translate as t} from 'service/Language';
import PopupService from '../../service/Popup';
import {HistoryService} from '../../service/HistoryService';

import './TransactionTimeFailedPage.scss';
import NewTimer from '../NewTimer/NewTimer';
import useObservable from '../../utils/useObservable';
import CardFormService from '../../page/Profile/Balance/Withdrawal/Payfix/service/CardForm';


function TransactionTimeFailedPage() {
    const time = useObservable(CardFormService.tranSuccess, CardFormService.tranSuccess.getValue());

    function onClickHandler() {
        PopupService.close();
        HistoryService.history.push('/');
    }
    return (
        <div className="TransactionTimeFailedPage">
            <div className="TransactionTimeFailedPage-container">

                <p className="TransactionTimeFailedPage-container__title ">{t('popup.success.transaction.title')}</p>
                <span className="TransactionTimeFailedPage-container__text">{t('popup.success.transaction.desc')}</span>
                <div className="TransactionTimeFailedPage-container__timer"><NewTimer finishDate={time} mask="mm:ss"/></div>
                <button className="TransactionTimeFailedPage-container__btn" onClick={onClickHandler}>
                    {t('popup.back.main.button')}
                </button>
            </div>
        </div>
    );
}

export default TransactionTimeFailedPage;
