/* eslint-disable max-len */

import { Icon } from 'types';

function PhotoIcon({ color = '#ffffff', width = 15, height = 13 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 15 13" fill="none">
            <path
                d="M9.91914 7.51224C9.91914 8.84621 8.83397 9.93138 7.5 9.93138C6.16603 9.93138 5.08127 8.84621 5.08127 7.51224C5.08127 6.17827 6.16603 5.0931 7.5 5.0931C8.83397 5.0931 9.91914 6.17868 9.91914 7.51224ZM15 4.07717V10.9481C15 11.8641 14.2573 12.6068 13.3414 12.6068H1.65865C0.74266 12.6068 0 11.8641 0 10.9481V4.07717C0 3.16118 0.74266 2.41852 1.65865 2.41852H3.69879V1.84463C3.69879 1.04309 4.34815 0.393311 5.15011 0.393311H9.84989C10.6518 0.393311 11.3012 1.04309 11.3012 1.84463V2.41811H13.3414C14.2573 2.41852 15 3.16118 15 4.07717ZM11.1631 7.51224C11.1631 5.49242 9.51982 3.84911 7.5 3.84911C5.48059 3.84911 3.83729 5.49242 3.83729 7.51224C3.83729 9.53206 5.48059 11.1754 7.5 11.1754C9.51982 11.1754 11.1631 9.53206 11.1631 7.51224Z"
                fill={color}
            />
        </svg>
    );
}

export default PhotoIcon;
