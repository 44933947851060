import { formatCurrencySign } from 'utils/format';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import AmountFormService from '../../../Paykassma/service/AmountForm';
import SumSelector from '../../../Paykassma/SummSelector/SumSelector';
import Submit from '../Button/Submit';
import Attention from '../../Attention/Attention';

import './Amount.scss';

type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {};

class Amount extends RxComponent<AmountProps, AmountState> {

    componentDidMount() {
        const { paymentMethod } = this.props;

        AmountFormService.reset();
        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setValue(paymentMethod.defaultValue);
    }

    render() {
        const { paymentMethod, lock } = this.props;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        return (
            <div className="deposit-amount-ars">
                <form className="deposit-amount-ars-form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    <div className="deposit-amount-ars-form-block">
                        <MaskedInput
                            mask="00000000000"
                            inputService={AmountFormService.amount}
                            className="deposit-amount-ars-form-block__input"
                            name="amount"
                            hint={hint}
                            enabled={!lock}
                        />
                        <span className="deposit-amount-ars-form-block__input_currency">{paymentMethod.currency.sign}</span>
                    </div>
                    <div className="deposit-amount-ars__sum">
                        <SumSelector paymentMethod={paymentMethod} disabled={lock} />
                    </div>
                    <div className="deposit-amount-ars-check">
                        <Attention title="popup.payment.attention" type="attention" />
                    </div>
                    <div className="deposit-amount-ars__submit"><Submit/></div>
                </form>
            </div>
        );
    }
}

export default Amount;
