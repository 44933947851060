import Validator from '../interface/Validator';

class OnlyLetter implements Validator {
    validate(value: string): boolean {
        return new RegExp('^[A-Za-z]+$').test(value);
    }
}
const OnlyLetterService = new OnlyLetter();

export { OnlyLetterService };
export default OnlyLetter;
