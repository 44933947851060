import { combineLatestWith, map } from 'rxjs';

import RecoveryService from '../../../service/Recovery';
import Validator from '../../../service/Validator/Validator';
import ConfirmPasswordInputService from './ConfirmPassword';
import PasswordInputService from './Password';

class ResetForm {
    public match = PasswordInputService.value
        .pipe(combineLatestWith(ConfirmPasswordInputService.value))
        .pipe(map(([a, b]) => Validator.equal(a, b)));

    public valid = this.match.pipe(combineLatestWith(PasswordInputService.valid)).pipe(map(([a, b]) => a && b));

    submit() {
        const isEqual = Validator.equal(PasswordInputService.getValue(), ConfirmPasswordInputService.getValue());
        if (PasswordInputService.isValid() && isEqual) {
            RecoveryService.setPassword(PasswordInputService.value.getValue());
        }
    }
}

const ResetFormService = new ResetForm();

export default ResetFormService;
