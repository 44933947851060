import { PaymentMethod } from 'page/Profile/Balance/PaymentMethod/types';

class Directa24Withdraw {
    currencyId: number;

    channelType: number;

    name: string;

    surname: string;

    email: string;

    numberCPF: string;

    amount: number;


    constructor(method: PaymentMethod, surname: string, name: string, email: string, numberCPF: string, amount: number) {
        this.currencyId = method.currency.ISO;
        this.channelType = method.id;
        this.name = name;
        this.surname = surname;
        this.email = email;
        this.numberCPF = numberCPF;
        this.amount = amount;
    }
}

export default Directa24Withdraw;
