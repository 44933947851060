import { FormEvent } from 'react';

import RxComponent from '../../../../component/RxComponent/RxComponent';
import GeoDataService from '../../../../service/GeoData';
import { translate as t } from '../../../../service/Language';
import Country, { unknownCountry } from '../../../../service/model/Country';
import GeoDataModel from '../../../../service/model/GeoData';
import UserData from '../../../../service/model/UserData';
import UserDataService, { UserDataResponse } from '../../../../service/UserData';
import { useIsDesktop } from '../../../../utils/useBreakpoints';
import ProfileHeader from '../../ProfileHeader/ProfileHeader';
import ChangeButtonPopUp from '../../Settings/Password/ChangeButtonPopUp/ChangeButtonPopUp';
import SaveButton from '../SaveButton/SaveButton';
import EmailInputService from './ChangeEmail/NewEmail/service/NewEmail';
import CountryList from './CountryList/CountryList';
import PersonalDetailsField from './PersonalDetailsField/PersonalDetailsField';
import PersonalDataFormService from './service/PersonalDataForm';

import './PersonalData.scss';

type PersonalDataProps = {};

type PersonalDataState = {
    status: UserDataResponse | null;
    mask: string;
    userData: UserData | null;
    country: Country;
};

class PersonalData extends RxComponent<PersonalDataProps, PersonalDataState> {
    state = {
        status: null,
        mask: '',
        userData: null,
        country: unknownCountry,
    };

    handler = {
        onStatusChange: this.onStatusChange.bind(this),
        checkGeoData: this.checkGeoData.bind(this),
        setUserData: this.setUserData.bind(this),
        onCountryChange: this.onCountryChange.bind(this),
    };

    componentDidMount() {
        PersonalDataFormService.init();
        this.subscribe(PersonalDataFormService.status, { next: this.handler.onStatusChange });
        this.subscribe(PersonalDataFormService.country, { next: this.handler.onCountryChange });
        this.subscribe(PersonalDataFormService.userData, { next: this.handler.setUserData });
        this.subscribe(GeoDataService.data, { next: this.handler.checkGeoData });
        this.subscribe(UserDataService.data, { next: this.handler.setUserData });
    }

    checkGeoData(geoData: GeoDataModel | null) {
        if (geoData) {
            this.state.country = geoData.country;

            if (geoData.country.id === 50) {
                this.setState({ mask: 'd/m/y' });
            } else {
                this.setState({ mask: 'd.m.y' });
            }
        }
    }

    setUserData(userData: UserData | null) {
        if (userData) {
            PersonalDataFormService.name.value.next(userData.firstName);
            PersonalDataFormService.id.value.next(userData.id.toString());
            PersonalDataFormService.currency.value.next(userData.currency.name.toString());
            EmailInputService.value.next(userData.email);

            const country = this.state.country;
            const countryForm = PersonalDataFormService.userData.getValue().country;

            if (userData.birthDate) {
                PersonalDataFormService.birthDate.value.next(userData.birthDate.toLocaleDateString());
            }
            if (userData.country === unknownCountry) {
                userData.country = country;
            }
            if (countryForm) {
                this.setState({ country: countryForm });
            }
            if (userData.country !== unknownCountry) {
                this.setState({ country: userData.country });
            }

            this.setState({ userData });
        }
    }

    onCountryChange(country: Country) {
        this.setState({ country });
    }

    onStatusChange(status: UserDataResponse) {
        this.setState({ status });
    }

    render() {
        const { status, mask, country, userData } = this.state;

        return (
            <>
                <ProfileHeader />
                <div className="personal-details-info-list">
                    {userData && (
                        <form onSubmit={(e: FormEvent) => PersonalDataFormService.onSubmit(e)}>
                            <PersonalDetailsField
                                title={t('profile.account.id')}
                                disabled
                                inputService={PersonalDataFormService.id}
                            />
                            <PersonalDetailsField
                                title={t('profile.account.firstname')}
                                inputService={PersonalDataFormService.name}
                            />
                            <PersonalDetailsField
                                placeholder={t('profile.account.birthday.type')}
                                title={t('profile.account.birthday')}
                                error={PersonalDataFormService.birthdayError(status)}
                                mask={mask}
                                inputService={PersonalDataFormService.birthDate}
                            />
                            <PersonalDetailsField
                                title={t('profile.account.email')}
                                disabled
                                inputService={EmailInputService}
                            />
                            <div className="personal-details-info-list__button">
                                <ChangeButtonPopUp type="email" />
                            </div>
                            <CountryList current={country} error={PersonalDataFormService.countryError(status)} />
                            <PersonalDetailsField
                                disabled
                                title={t('profile.account.currency.title')}
                                inputService={PersonalDataFormService.currency}
                            />
                            <SaveButton />
                        </form>
                    )}
                </div>
            </>
        );
    }
}

export default PersonalData;
