import { translate as t } from 'service/Language';

import PopupService from '../../../../service/Popup';
import PasswordFormService from '../../Settings/ChangePassword/service/PasswordFormService';
import Success from './image/success.svg';

import './SuccessProfilePage.scss';

type SuccessProfilePageProps = {
    title: string;
};
function SuccessProfilePage({ title }: SuccessProfilePageProps) {
    function onClick() {
        PopupService.close();
        PasswordFormService.reset();
    }
    return (
        <div className="success-password-page">
            <img src={Success} alt="success" />
            <span className="success-password-page__title">{t(title)}</span>
            <button onClick={onClick} className="success-password-page__btn" type="button">
                {t('profile.settings.password.change.success.button')}
            </button>
        </div>
    );
}

export default SuccessProfilePage;
