import RouteConfig from 'appConstants/RouteConfig';
import Footer from 'component/Footer/Footer';
import { Route, Switch } from 'react-router-dom';
import { useIsDesktop } from 'utils/useBreakpoints';

import LiveCasino from '../LiveCasino/LiveCasino';
import TvGames from '../TvGames/TvGames';
import MainBanner from './Banners/MainBanner/MainBanner';
import Category from './Category/Category';
import CustomProvider from './CustomProvider/CustomProvider';
import Favorite from './Favorite/Favorite';
import Provider from './Provider/Provider';
import ProviderList from './ProviderList/ProviderList';
import RouteListener from './RouteListener/RouteListener';
import TopProviderList from './TopProviderList/TopProviderList';
import GloryGames from '../GloryGames/GloryGames';

import './Casino.scss';

function Casino() {
    const isDesktop = useIsDesktop();

    return (
        <div className="casino">
            <Route path={RouteConfig.casino.category} component={RouteListener} />
            <Route path={RouteConfig.casino.root} exact component={RouteListener} />
            <Route path={RouteConfig.casino.filter} exact component={RouteListener} />
            <Switch>
                <Route path={RouteConfig.casino.root} exact>
                    {isDesktop && <MainBanner />}
                    <Category />
                    <TopProviderList />
                </Route>
                <Route path={RouteConfig.casino.live} exact component={LiveCasino} />
                <Route path={RouteConfig.casino.gloryGames} exact component={GloryGames} />
                <Route path={RouteConfig.casino.tvGames} exact component={TvGames} />
                <Route path={RouteConfig.casino.providers} exact component={ProviderList} />
                <Route path={RouteConfig.casino.provider} component={Provider} />
                <Route path={RouteConfig.casino.games} component={CustomProvider} />
                <Route path={RouteConfig.casino.favorite} component={Favorite} />
                <Route path={RouteConfig.casino.category} component={Category} />
            </Switch>
            <Footer />
        </div>
    );
}

export default Casino;
