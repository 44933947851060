import { useEffect } from 'react';

import useObservable from '../../../../../utils/useObservable';
import { PaymentMethod } from '../../PaymentMethod/types';
import Balance from '../Balance/Balance';

import PaykassmaWithdrawService, { PaykassmaWithdrawState } from '../Paykassma/service/PaykassmaWithdraw';
import ApayForm from './Form/Form';
import ResultForm from './ResultForm/ResultForm';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const status = useObservable(PaykassmaWithdrawService.state, PaykassmaWithdrawService.state.getValue());

    useEffect(() => {
        PaykassmaWithdrawService.reset();
    }, []);

    if (
        status === PaykassmaWithdrawState.INIT ||
        status === PaykassmaWithdrawState.AWAIT ||
        status === PaykassmaWithdrawState.HAS_FEE
    ) {
        return (
            <>
                <Balance />
                <ApayForm paymentMethod={paymentMethod} status={status} />
            </>
        );
    } else return <ResultForm />;
}

export default Form;
