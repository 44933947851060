import classNames from 'classnames';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';
import { PaymentMethod } from '../../../PaymentMethod/types';
import Spinner from '../../../../../../svg/spinner.svg';
import IMPSFormService from '../service/IMPSForm';
import PaykassmaWithdrawService, { PaykassmaWithdrawState } from '../service/PaykassmaWithdraw';

import './WithdrawalButtonIMPS.scss';


type WithdrawalButtonType = {
    buttonTitle: string;
    variation?: boolean;
    paymentMethod?: PaymentMethod;
    amount?: string;
    state: PaykassmaWithdrawState;
};

function WithdrawalButtonIMPS(props: WithdrawalButtonType) {
    const { buttonTitle, variation, paymentMethod, amount, state } = props;
    const valid = useObservable(IMPSFormService.valid, IMPSFormService.valid.getValue());

    const min = paymentMethod && paymentMethod.limit.withdraw.min;
    const sumToWithdraw = amount && parseInt(amount) - parseInt(amount) * 0.2;
    const disabledFee = sumToWithdraw && min && sumToWithdraw >= min;

    const className = classNames('withdrawal-button-paykassma', {
        'withdrawal-button-paykassma__active': valid || (!variation && disabledFee),
        'withdrawal-button-paykassma__variated': variation && !disabledFee,
    });

    return (
        <button type="submit" className={className} disabled={!valid || (variation && !disabledFee)}>
            {state === PaykassmaWithdrawState.AWAIT ? (
                <img className="withdrawal-button-paykassma__wait" src={Spinner} alt="" />
            ) : (
                t(buttonTitle)
            )}
        </button>
    );
}

export default WithdrawalButtonIMPS;
