import { translate as t } from 'service/Language';

import Failed from './images/Failed.svg';

import WellxPayDepositService from '../../service/WellxpayDeposit';

import './FailedTransactionPage.scss';


function FailedTransactionPage() {
    function skipUnusedForm() {
        if (WellxPayDepositService.state.getValue()) {
            WellxPayDepositService.showPreviousForm();
        }
        if (WellxPayDepositService.transaction.getValue()) {
            WellxPayDepositService.showTransaction();
        }
    }
    return (
        <div className="transaction-failed">
            <div className="transaction-failed__container">
                <img src={Failed} alt="failed" />
                <span className="transaction-failed__title ">{t('profile.balance.deposit.transaction.failed')}</span>
                <button className="transaction-failed__btn" onClick={skipUnusedForm}>
                    {t('profile.balance.deposit.transaction.failed.button')}
                </button>
            </div>
        </div>
    );
}

export default FailedTransactionPage;
