import React from "react";
import Form from "../Form/Form";
import circle from "../circle.svg";
import cross from "../cross.svg";
import { translate as t } from "../../../service/Language";
import pattern from "../../../page/LandingCricket/images/pattern-landing.png";
import "./CricketForm.scss";
import { useHistory } from "react-router-dom";

export default function CricketForm() {
  const history = useHistory();

  return (
    <div className="registration__container">
      <div className="registration__circle" />
      <div className="registration__wrapper">
        <div className="registration__wrapper-title">
          <div />
          <img src={circle} alt="circle" />
          <img src={cross} alt="cross" onClick={() => history.goBack()} />
        </div>
        <div className="registration__title">
          {t("info.landing.cricket.title.popup")}{" "}
        </div>
        <div className="registration__fs">125% + 250 FS</div>
      </div>

      <div className="registration__landing">
        <Form isVisibleHeader={false} />
        <img
          className="registration__landing-pattern"
          src={pattern}
          alt="pattern"
        />
      </div>
    </div>
  );
}
