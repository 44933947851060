import { useEffect } from 'react';
import { translate as t } from 'service/Language';

import QRcode from '../../../../../../component/QRcode/QRcode';
import GoogleService from '../../../../../../service/Google';
import OperationsService from '../../../../../../service/Operations';
import useObservable from '../../../../../../utils/useObservable';
import { PaymentMethod } from '../../../PaymentMethod/types';
import PaymentHeader from './PaymentHeader/PaymentHeader';
import FailedTransactionPage from '../../Paykassma/ResultPage/FailedPage/FailedPage';
import PaymentChoice from '../../../../../../component/PaymentChoice/PaymentChoice';
import ConstantposDepositService, {DepositState, PaymentType} from '../service/ConstantposDeposit';
import Amount from './Amount/Amount';

import './Form.scss';

import {useIsDesktop} from '../../../../../../utils/useBreakpoints';



type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(ConstantposDepositService.state, ConstantposDepositService.state.getValue());
    const link = useObservable(ConstantposDepositService.link, ConstantposDepositService.link.getValue());
    const isDesktop = useIsDesktop();

    useEffect(() => {
        const gaSub = OperationsService.invoice.subscribe({
            next: (data) => {
                const clientId = GoogleService.getClientId();
                const invoiceId = data.id.toString();
                OperationsService.sendGAClientRelation(clientId, invoiceId);
            },
        });
        return () => {
            gaSub.unsubscribe();
            ConstantposDepositService.reset();
        };
    }, []);

    useEffect(()=> {
        link && openPaymentApp(paymentMethod.title)
    }, [link])

    const openPaymentApp = (payApp: string) => {
        let url = '';
        switch (payApp) {
            case 'PayTM Fast':
                url = 'paytmmp://';
                ConstantposDepositService.paymentType.next(PaymentType.PAYTM);
                break;
            case 'PhonePe Fast':
                url = 'phonepe://';
                ConstantposDepositService.paymentType.next(PaymentType.PHONE_PEE);
                break
            case 'GooglePay':
                url = 'tez://upi/';
                ConstantposDepositService.paymentType.next(PaymentType.GOOGLE_PAY);
                break;
        }
        url = link.replace('upi://', url);
        if (isDesktop) {
            ConstantposDepositService.link.next(url);
            ConstantposDepositService.state.next(DepositState.QR);
        } else {
            window.open(url, '_top');
        }
    };

    return (
        <div className="deposit-form">
            {state === DepositState.QR ?
                <PaymentHeader />
                :
                <div className="deposit-form__header">
                    <img className="deposit-form__img" src={paymentMethod.icon} alt={paymentMethod.title}/>
                    <h2 className="deposit-form__title">{t(paymentMethod.title)}</h2>
                </div>
                }
            {(state === DepositState.AMOUNT || state === DepositState.WAIT) ? (
                <Amount paymentMethod={paymentMethod} lock={state !== DepositState.AMOUNT}/>
            ) : null}
            {state === DepositState.QR ? (
                <div className="deposit-form__qr">
                    <QRcode link={link} />
                </div>
            ) : null}
            {state === DepositState.FAILED ? <FailedTransactionPage /> : null}
        </div>
    );
}

export default Form;
