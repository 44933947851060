import Validator from '../interface/Validator';

class CheckAzerLetters implements Validator {
    validate(value: string): boolean {
        return new RegExp('[üəşğöıç]+$').test(value);
    }
}
const CheckAzerLettersService = new CheckAzerLetters();

export { CheckAzerLettersService };
export default CheckAzerLetters;
