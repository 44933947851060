import { translate as t } from '../../../../../../service/Language';

import './TableBonus.scss';

function TableBonus() {
    return (
        <table className="table-bonus">
            <tr className="table-bonus__row">
                <td className="table-bonus__cell">{t('info.promo.welcome.table.currency')}</td>
                <td className="table-bonus__cell">{t('info.promo.welcome.table.min')}</td>
                <td className="table-bonus__cell">{t('info.promo.welcome.table.max')}</td>
            </tr>
            <tr>
                <td>RUB</td>
                <td>200</td>
                <td>20 000</td>
            </tr>
            <tr>
                <td>UZS</td>
                <td>30 000</td>
                <td>3 000 000</td>
            </tr>
            <tr>
                <td>USD</td>
                <td>5</td>
                <td>300</td>
            </tr>
            <tr>
                <td>EUR</td>
                <td>5</td>
                <td>300</td>
            </tr>
            <tr>
                <td>BDT</td>
                <td>500</td>
                <td>30000</td>
            </tr>
            <tr>
                <td>INR</td>
                <td>200</td>
                <td>20000</td>
            </tr>
        </table>
    );
}

export default TableBonus;
