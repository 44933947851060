import './DepositFrame.scss';

type DepositFrameProps = {
    link: string;
};

function DepositFrame({ link }: DepositFrameProps) {
    return <iframe className="deposit-frame-wavepay" src={link} />;
}

export default DepositFrame;
