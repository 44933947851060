import { IMaskInput } from 'react-imask';

import Input, { InputProps, InputState } from './Input';
import { createRef } from 'react';

interface MaskedInputProps extends InputProps {
    mask: string;
    unmask?: boolean;
    pattern?: string;
    blocks?: any;
}

interface MaskedInputState extends InputState {
}

class MaskedInput extends Input<MaskedInputProps, MaskedInputState> {

    ref = {
        input: createRef<HTMLInputElement>(),
    };
    handler = {
        onClick: this.onClick.bind(this),
    };

    getInputProps() {
        const inputProps = super.getInputProps();
        // eslint-disable-next-line
        const {mask, unmask, pattern, blocks} = this.props;
        // eslint-disable-next-line
        return Object.assign(inputProps, {
            mask,
            unmask,
            pattern,
            // eslint-disable-next-line
            blocks,
        });
    }

    onClick() {
        this.getInputProps().onFocus()
    }

    getInput() {
        const inputProps = this.getInputProps();
        // @ts-ignore
        return <IMaskInput {...inputProps} onAccept={(value, QMask) => inputProps.onChange(value)}  onClick={this.handler.onClick} />;
    }
}

export default MaskedInput;
