import Currency, { currencyList } from 'service/model/Currency';

import RxComponent from '../../../RxComponent/RxComponent';
import Select, { SelectSize } from '../../../Select/Select';
import { OptionInterface } from '../../../Select/Select.type';
import RegistrationFormService from '../service/RegistrationForm';
import CurrencyOption from './CurrencyOption';

import './CurrencyList.scss';

type SelectProps = {};
type SelectState = {
    activeCurrency: Currency | null;
};

class CurrencyList extends RxComponent<SelectProps, SelectState> {
    handler = {
        setCurrency: this.setCurrency.bind(this),
    };

    state: SelectState = {
        activeCurrency: null,
    };

    setCurrency(activeCurrency: Currency | null) {
        this.setState({ activeCurrency });
    }

    componentDidMount() {
        this.subscribe(RegistrationFormService.activeCurrency, { next: this.handler.setCurrency });
    }

    render() {
        const { activeCurrency } = this.state;

        const options = new Map(currencyList.map((currency) => [currency, new CurrencyOption(currency)]));
        const onSelect = (currencyOption: OptionInterface<Currency>) => {
            RegistrationFormService.selectCurrency(currencyOption.getValue());
        };
        const current = activeCurrency ? options.get(activeCurrency) : null;
        return (
            <Select
                className="currency-list"
                onSelect={onSelect}
                list={Array.from(options.values())}
                current={current || null}
                size={SelectSize.small}
            />
        );
    }
}

export default CurrencyList;
