import { ReactElement } from 'react';
import { BehaviorSubject, Subject } from 'rxjs';

import UIService from './UI';

class Popup {
    component = new BehaviorSubject<ReactElement | null>(null);

    onChange = new Subject<void>();

    show(component: ReactElement) {
        const currentComponent = this.component.getValue();
        if (component !== currentComponent) {
            this.onChange.next();
        }
        this.component.next(component);

        UIService.lockScroll();
    }

    close() {
        this.component.next(null);
        this.onChange.next();
        UIService.unlockScroll();
    }
}

const PopupService = new Popup();

export default PopupService;
