import { translate as t } from 'service/Language';

import image from './images/fav-zero-image.png';

import './ZeroScreen.scss';

const ZeroScreen = () => {
    return (
        <div className="zero-screen__wrapper">
            <div className="zero-screen__artwork">
                <img src={image} alt="image" />
            </div>
            <div className="zero-screen__text">
                {t('casino.favorite.description1')}
                <br />
                {t('casino.favorite.description2')}
            </div>
        </div>
    );
};

export default ZeroScreen;
