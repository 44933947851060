import { ReactComponent as ArrowDropdownIcon } from 'assets/svg/arrow_dropdown.svg';

import { translate as t } from 'service/Language';

import './styles.scss';
import CountrySupportService from 'service/CountrySupport';
import useObservable from 'utils/useObservable';
import CountrySelectList from 'component/CountrySelectList';

type CountrySelectProps = {
    onSelect?: () => void;
};

const CountrySelect = (props: CountrySelectProps) => {
    const { onSelect } = props;

    return (
        <div className='CountrySelect__countries'>
            <div className='CountrySelect__countries_arrow'>
                <ArrowDropdownIcon />
            </div>
            <div className='CountrySelect__countries_title'>{t('casino.countries.select')}</div>
            <div className='CountrySelect__countries_list'>
                <CountrySelectList onSelect={onSelect} />
            </div>
        </div>
    );
};

export default CountrySelect;
