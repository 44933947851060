/* eslint-disable max-len */

import { Icon } from 'types';

function CasinoDealer({ color = '#3959D9', width = 24, height = 32 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.10977 23.2441L11.0042 24.4018C10.815 24.6139 10.7001 24.8937 10.7001 25.2002C10.7001 25.5068 10.815 25.7865 11.0042 25.9986L8.10977 27.1563C8.05722 27.1774 8.00006 27.1387 8.00006 27.0821V23.3184C8.00006 23.2618 8.05722 23.2231 8.10977 23.2441Z"
                fill={color}
            />
            <path
                d="M13.2001 25.2C13.2001 25.8628 12.6628 26.4 12.0001 26.4C11.3373 26.4 10.8001 25.8628 10.8001 25.2C10.8001 24.5373 11.3373 24 12.0001 24C12.6628 24 13.2001 24.5373 13.2001 25.2Z"
                fill={color}
            />
            <path
                d="M12.996 24.4018C13.1851 24.6139 13.3001 24.8937 13.3001 25.2002C13.3001 25.5068 13.1851 25.7865 12.996 25.9986L15.8904 27.1563C15.9429 27.1774 16.0001 27.1387 16.0001 27.0821V23.3184C16.0001 23.2618 15.9429 23.2231 15.8904 23.2441L12.996 24.4018Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 0C6.69807 0 2.4 4.65626 2.4 10.4001C2.26667 13.6001 1.68 20.3201 0.4 21.6001C1.95027 22.4489 3.38449 23.1495 4.74417 23.7024L2.01155 24.7953C0.796643 25.2813 0 26.458 0 27.7665V31.2C0 31.6418 0.358173 32 0.800001 32H23.2C23.6418 32 24 31.6418 24 31.2V27.7665C24 26.458 23.2034 25.2813 21.9885 24.7953L19.1922 23.677C19.5638 23.5285 19.9414 23.3695 20.3256 23.2001L21.2 22.8022C21.9703 22.4412 22.7683 22.0405 23.6 21.6001C22.32 20.3201 21.7333 13.6001 21.6 10.4001C21.6 4.65626 17.3019 0 12 0ZM16.3981 19.5214L14.2988 21.2007C12.8379 22.3694 10.7621 22.3694 9.30126 21.2007L7.20199 19.5214C5.68381 18.3069 4.80004 16.4681 4.80004 14.5238V9.91184C7.9981 10.7406 13.9894 8.26622 16.8 6.7998C16.8 7.37869 17.2223 8.64121 18.8 9.59218V14.5238C18.8 16.4681 17.9163 18.3069 16.3981 19.5214ZM3.47541 12.872L3.76408 14.4458C3.83705 14.8436 4.09841 15.1594 4.43921 15.3179C3.8991 13.3612 4.21417 11.9408 4.43921 11.4752C3.78735 11.5948 3.35584 12.2202 3.47541 12.872ZM19.836 14.4458L20.1247 12.872C20.2443 12.2202 19.8128 11.5948 19.1609 11.4752C19.386 11.9408 19.701 13.3612 19.1609 15.3179C19.5017 15.1594 19.7631 14.8436 19.836 14.4458ZM14.5487 21.5132L15.6 20.6721V22.6707L19.681 24.303L11.9999 31.5999L4.31904 24.303L8.40004 22.6707V20.9921L9.05138 21.5132C10.6583 22.7988 12.9417 22.7988 14.5487 21.5132ZM20.8 29.5999H16.8V29.9999H20.8V29.5999Z"
                fill={color}
            />
        </svg>
    );
}

export default CasinoDealer;
