import classNames from 'classnames';
import { createRef } from 'react';

import AuthService from '../../../service/Auth';
import GoogleService from '../../../service/Google';
import LanguageService, { LanguageType } from '../../../service/Language';
import SvgImage from '../../../svg';
import RxComponent from '../../RxComponent/RxComponent';
import { AZ, BN, EN, KZ, MM, RU, TR, SI, UZ, PT } from '../images';
import ButtonSelect from './ButtonSelect/ButtonSelect';

import './LanguageSelect.scss';

type LanguageSelectProps = {
    className: string;
    showArrow?: boolean;
    size?: 'small' | 'big';
    isOpenUp?: boolean;
};

type LanguageSelectState = {
    active: boolean;
    language: LanguageType;
};

export type LanguageImageTitle = {
    img: string;
    title: string;
};

class LanguageSelect extends RxComponent<LanguageSelectProps, LanguageSelectState> {
    state = {
        active: false,
        language: LanguageService.current.getValue(),
    };

    handler = {
        toggle: this.toggle.bind(this),
        select: this.select.bind(this),
        onLanguageChange: this.onLanguageChange.bind(this),
        clickOutside: this.clickOutside.bind(this),
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    list: LanguageImageTitle[] = [
        { img: RU, title: 'RU' },
        { img: BN, title: 'BN' },
        { img: EN, title: 'EN' },
        { img: TR, title: 'TR' },
        { img: UZ, title: 'UZ' },
        { img: KZ, title: 'KZ' },
        { img: MM, title: 'MM' },
        { img: SI, title: 'SI' },
        { img: AZ, title: 'AZ' },
        { img: PT, title: 'PT' },
    ];

    close() {
        this.setState({ active: false });
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    open() {
        this.setState({ active: true });
        document.addEventListener('pointerdown', this.handler.clickOutside);
    }

    toggle() {
        const { active } = this.state;

        if (active) {
            this.close();
        } else {
            this.open();
        }
    }

    select(language: LanguageType) {
        this.close();
        LanguageService.setLanguage(language);
        const userId = AuthService.auth.getValue()?.userId.toString();
        GoogleService.changeLanguage(userId ? userId : '', language);

    }

    onLanguageChange(language: LanguageType) {
        this.setState({ language });
    }

    clickOutside(event: PointerEvent) {
        if (this.ref.self.current && !this.ref.self.current.contains(event.target as Node)) {
            this.close();
        }
    }

    componentDidMount() {
        this.subscribe(LanguageService.current, { next: this.handler.onLanguageChange });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    render() {
        const { className, showArrow = false, size = 'big', isOpenUp = false } = this.props;
        const { language, active } = this.state;

        const _className = classNames(className, {
            'language-select': true,
            'language-select____active': active,
            'language-select____small': size === 'small',
            [className]: !!className,
        });

        const classNameByList = classNames({
            'language-select__list': true,
            'language-select__list-up': isOpenUp,
        });

        let match = this.list.find(item => item.title === language.title);
        if (!match) {
            match = this.list[0];
        }
        const LanguageImage = match.img;

        return (
            <div className={_className} ref={this.ref.self}>
                <button
                    type='button'
                    className='language-select__current'
                    onClick={this.handler.toggle}
                >
                    <img
                        className='language-select__img'
                        width={size === 'big' ? 28 : 20}
                        height={size === 'big' ? 28 : 20}
                        src={LanguageImage}
                        alt={language.title}
                    />
                    <span className='language-select__title'>{language.title}</span>
                    {showArrow ? <SvgImage name='SelectArrow' /> : null}
                </button>

                <div className={classNameByList}>
                    {LanguageService.list
                        .sort((a, b) =>
                            a.title === language.title ? -1 : b.title === language.title ? 1 : 0,
                        )
                        .map(language => (
                            <ButtonSelect
                                language={language}
                                size={size}
                                onClick={this.handler.select}
                                key={language.title}
                                list={this.list}
                            />
                        ))}
                </div>
            </div>
        );
    }
}

export default LanguageSelect;
