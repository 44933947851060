import { useLocation } from 'react-router-dom';
import { translate as t } from 'service/Language';

import { list } from '../Info';

import './InfoHeader.scss';

function InfoHeader() {
    const location = useLocation();
    const title = list.filter((item) => item.path === location.pathname)[0];
    return <div className="info-header">{t(title ? title.title : '')}</div>;
}

export default InfoHeader;
