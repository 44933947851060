import { SlotegratorGameMode } from '@ay_tsarbet/newbet-core/dist/connection/actions/actionEnums';
import { HistoryService } from 'service/HistoryService';

import RouteConfig from '../appConstants/RouteConfig';
import AuthService from './Auth';
import BalanceService from './Balance';
import GamesService from './Games';
import GoogleService from './Google';
import LanguageService from './Language';
import Game from './model/Game';
import Storage from './Storage';
import Tournament from './model/Tournament';

class GameHelper {
    public static loadGame(game: Game, mode: SlotegratorGameMode, tournament: Tournament | null) {
        const location = window.location.href;
        const language = LanguageService.current.getValue();
        const lang = language.title;

        const auth = AuthService.auth.getValue();
        const balance = BalanceService.balance.getValue();
        const userId = auth ? auth.userId.toString() : '';
        const email = auth && auth.email ? auth.email : '';
        tournament && GoogleService.openGameTournament(game.name, game.provider.name, tournament.name, tournament.id, userId, email);

        if (mode === SlotegratorGameMode.REAL) {
            GoogleService.openGame(game.name, game.provider.name, userId, email);
            if (auth) {
                if (!!balance) {
                    Storage.set('currentGameId', {gameId: game.id, mode: SlotegratorGameMode.REAL});
                    GamesService.loadGame(game, mode, lang, location);
                } else {
                    HistoryService.setHash(RouteConfig.hash.deposit);
                }
            } else {
                HistoryService.history.push({hash: RouteConfig.hash.registration});
            }
        } else {
            Storage.set('currentGameId', {gameId: game.id, mode: SlotegratorGameMode.DEMO});
            GamesService.loadGame(game, mode, lang, location);

            GoogleService.openDemoGame(game.name, game.provider.name, userId, email);
            tournament && GoogleService.openDemoGameTournament(game.name, game.provider.name, tournament.name, tournament.id, userId, email);
        }
    }

    public static isMobileGame(game: Game) {
        if (
            game.name.toLowerCase().includes('mobile') ||
            (game.isMobile === 1 && !game.name.toLowerCase().includes('mobile'))
        ) {
            return true;
        } else if (game.isMobile === 0 && !game.name.toLowerCase().includes('mobile')) {
            return false;
        }
    }

    public static isDesktopGame(game: Game) {
        if (game.name.toLowerCase().includes('mobile')) {
            return false;
        } else if (
            (game.isMobile === 0 && !game.name.toLowerCase().includes('mobile')) ||
            (game.isMobile === 1 && !game.name.toLowerCase().includes('mobile'))
        ) {
            return true;
        }
    }

    public static matchPlatform(game: Game, isDesktop: boolean) {
        if (isDesktop) {
            return this.isDesktopGame(game);
        } else {
            return this.isMobileGame(game);
        }
    }
}

export default GameHelper;
