import { Operation } from '@ay_tsarbet/newbet-core/dist/types';
import { BehaviorSubject } from 'rxjs';

import { OPERATIONS } from '../appConstants/operations';
import BonusModel from './model/Bonus';

interface BonusActions {
    sendCancelBonus: (operationId: number) => void;
}

const EmptyBonusActions: BonusActions = {
    sendCancelBonus: () => {},
};

class Bonus {
    private actions: BonusActions = EmptyBonusActions;

    balance = new BehaviorSubject(0);

    list = new BehaviorSubject<BonusModel[]>([]);

    public bonusAcum: Operation[];

    constructor() {
        this.bonusAcum = [];
        this.accumulationBonus.bind(this);
    }

    onReceive(operations: Operation[]) {
        if (operations.length) {
            const filteredBonusList = operations.filter(
                (bonusItem: Operation) =>
                    bonusItem.channelType === OPERATIONS.SLOTEGRATOR_DEPOSIT ||
                    bonusItem.channelType === OPERATIONS.SLOTEGRATOR_FREESPIN
                // bonusItem.channelType === OPERATIONS.SLOTEGRATOR_NEWYEAR
            );
            this.bonusAcum = filteredBonusList;
            const mappedCurrentOperations = filteredBonusList.map((operation) => BonusModel.fromObject(operation));
            this.list.next(mappedCurrentOperations);
        }
    }

    setActions(actions: BonusActions) {
        this.actions = actions;
    }

    setBalance(balance: number) {
        this.balance.next(balance);
    }

    onRemoveOperation(id: number) {
        this.list.next(this.list.getValue().filter((operation) => operation.id !== id));
        this.bonusAcum = this.bonusAcum.filter((operation) => operation.id !== id);
    }

    onCancelBonus(bonus: BonusModel) {
        this.actions.sendCancelBonus(bonus.id);
    }

    accumulationBonus(operation: Operation) {
        if (this.bonusAcum.find((item) => item.id === operation.id)) {
            const newBonusAcum = this.bonusAcum.filter((item) => item.id !== operation.id);
            newBonusAcum.unshift(operation);
            this.onReceive(newBonusAcum);
        } else {
            this.bonusAcum.push(operation);
            if (this.bonusAcum.length >= 7) {
                this.onReceive(this.bonusAcum);
            }
        }
    }
}

const BonusService = new Bonus();

export default BonusService;
