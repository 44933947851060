import Validator from '../interface/Validator';

class AccountPayfix implements Validator {
    validate(value: string): boolean {
        return value.length === 10;
    }
}
const AccountPayfixService = new AccountPayfix();

export { AccountPayfixService };

export default AccountPayfix;
