import Validator from '../interface/Validator';

class RocketTransaction implements Validator {
    validate(value: string): boolean {
        return this.numberCheck(value) && this.symbolCheck(value) && this.lengthCheck(value);
    }

    numberCheck(value: string): boolean {
        return new RegExp('^[0-9]+$').test(value);
    }

    symbolCheck(value: string): boolean {
        return new RegExp('^[A-Za-z0-9]+$').test(value);
    }

    lengthCheck(value: string): boolean {
        return value.length >= 10;
    }
}

const RocketTransactionService = new RocketTransaction();

export { RocketTransactionService };
export default RocketTransaction;
