import { NavLink } from 'react-router-dom';

import { translate as t } from '../../../../service/Language';
import ScrollService from '../../../ScrollTopButton/service/Scroll';
import FooterService from '../../service/Footer';

type InformationItemProps = {
    className: string;
};

function InformationItem({ className }: InformationItemProps) {
    return (
        <>
            {FooterService.documentList.map((item) => (
                <NavLink
                    key={item.id}
                    to={item.path}
                    className={className}
                    onClick={() => ScrollService.scrollToSmoothly(0, 500)}
                >
                    {t(item.title)}
                </NavLink>
            ))}
            <a href="https://glory.partners/" className={className}>
                {t('footer.partners')}
            </a>
        </>
    );
}

export default InformationItem;
