import { Route, Switch } from 'react-router-dom';

import RouteConfig from '../../appConstants/RouteConfig';
import Footer from '../../component/Footer/Footer';
import Header from '../../component/Header/Header';
import About from './About/About';
import Aml from './AML/Aml';
import Cookies from './Cookies/Cookies';
import Gamble from './Gamble/Gamble';
import InfoHeader from './InfoHeader/InfoHeader';
import Navigation from './Navigation/Navigation';
import Privacy from './Privacy/Privacy';
import Promo from './Promo/Promo';
import Rule from './Rule/Rule';
import Support from './Support/Support';
import SwitchList from './SwitchList/SwitchList';

import './Info.scss';

export const route = {
    about: RouteConfig.info.root + RouteConfig.info.about,
    promo: RouteConfig.info.root + RouteConfig.info.promo.root,
    rules: RouteConfig.info.root + RouteConfig.info.rules,
    kycaml: RouteConfig.info.root + RouteConfig.info.kycaml,
    privacy: RouteConfig.info.root + RouteConfig.info.privacy,
    responsiblegame: RouteConfig.info.root + RouteConfig.info.responsiblegame,
    cookies: RouteConfig.info.root + RouteConfig.info.cookies,
    support: RouteConfig.info.root + RouteConfig.info.support,
    bonus: RouteConfig.info.root + RouteConfig.info.promo.root,
};

export const list = [
    { title: 'info.navigation.about', path: route.about },
    { title: 'info.navigation.rules', path: route.rules },
    { title: 'info.navigation.KYCAML', path: route.kycaml },
    { title: 'info.navigation.privacy', path: route.privacy },
    { title: 'info.navigation.responsiblegame', path: route.responsiblegame },
    { title: 'info.navigation.cookies', path: route.cookies },
    { title: 'info.navigation.support', path: route.support },
    { title: 'info.navigation.bonus', path: route.bonus },
];

function InfoPage() {
    return (
        <div className="info-page">
            <Header />
            <div className="info-page__container">
                <div className="info-page__nav">
                    <Navigation />
                </div>
                <div className="info-page__main">
                    <div className="info-page__content">
                        <div className="info-page__center">
                            <div className="info-page__switch-list">
                                <SwitchList />
                            </div>
                            <div className="info-page__info">
                                <InfoHeader />
                                <Switch>
                                    <Route path={route.support} component={Support} />
                                    <Route path={route.about} component={About} />
                                    <Route path={route.rules} component={Rule} />
                                    <Route path={route.kycaml} component={Aml} />
                                    <Route path={route.privacy} component={Privacy} />
                                    <Route path={route.responsiblegame} component={Gamble} />
                                    <Route path={route.cookies} component={Cookies} />
                                    <Route path={route.promo} component={Promo} />
                                </Switch>
                            </div>
                        </div>
                        <div className="info-page__right" />
                    </div>
                    <div className="info-page__footer" />
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default InfoPage;
