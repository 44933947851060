import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import AmountFormService from '../../service/AmountForm';
import Submit from '../Button/Submit';
import Attention from '../../../Apay/Attention/Attention';

import './Amount.scss';

type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {
};

class Amount extends RxComponent<AmountProps, AmountState> {

    componentDidMount() {
        const { paymentMethod } = this.props;

        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setValue(paymentMethod.defaultValue);
    }

    render() {
        return (
            <div className="deposit-amount-azarvisa">
                <form className="deposit-amount-azarvisa-form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                </form>
                <div className="deposit-amount-azarvisa__check">
                    <Attention title="popup.payment.attention" type="attention" />
                </div>
                <div className="deposit-amount-azarvisa__submit">
                    <Submit />
                </div>
            </div>
        );
    }
}

export default Amount;
