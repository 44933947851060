import { BehaviorSubject } from 'rxjs';
import PaymentService from 'service/Payment';
import CardFormService from './CardForm';

enum PayfixWithdrawState {
    CARD,
    AWAIT,
    HAS_FEE,
    ACCEPT,
    REJECT,
    BONUS_REJECT,
    TIME_REJECT,
}

class PayfixWithdraw {
    state = new BehaviorSubject<PayfixWithdrawState>(PayfixWithdrawState.CARD);

    handler = {
        onWithdraw: this.onWithdraw.bind(this),
        onError: this.onError.bind(this),
    };

    constructor() {
        PaymentService.payfix.withdrawResult.subscribe(this.handler.onWithdraw);
        PaymentService.payfix.error.subscribe(this.handler.onError);
    }

    onWithdraw() {
        this.state.next(PayfixWithdrawState.ACCEPT);
    }

    onError() {
        this.state.next(PayfixWithdrawState.REJECT);
    }

    reset() {
        this.state.next(PayfixWithdrawState.CARD);
        CardFormService.reset();
    }
}

const PayfixWithdrawService = new PayfixWithdraw();

export { PayfixWithdrawState };
export default PayfixWithdrawService;
