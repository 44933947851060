import Validator from '../interface/Validator';

class ExpMonthFullYear implements Validator {
    validate(value: string): boolean {
        return this.checkLength(value) && this.checkDifference(value);
    }

    checkLength(value: string): boolean {
        return value.length === 7;
    }

    checkDifference(value: string): boolean {
        const today = new Date();
        const currentYear = today.getFullYear().toString();
        const currentMonth = +today.getMonth() + 1;

        const month = +value.slice(0, 2);
        const year = value.slice(3, 7);

        if (month && year) {
            if(year > currentYear) {
                return true
            }
            return year === currentYear && month >= currentMonth;
        } else return false;

    }
}

const ExpMonthFullYearService = new ExpMonthFullYear();

export { ExpMonthFullYearService };
export default ExpMonthFullYear;
