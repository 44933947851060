/* eslint-disable max-len */

import { Icon } from 'types';

function ActiveSearch({ color = '#F31F1F', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 28C21.7374 28 28 21.7385 28 14C28 6.26257 21.7385 0 14 0C6.26257 0 0 6.26151 0 14C0 21.7374 6.26151 28 14 28ZM23.7454 7.71718C23.8377 7.64235 23.9746 7.66394 24.0374 7.76484C25.1627 9.57392 25.8125 11.7103 25.8125 14C25.8125 20.5285 20.5294 25.8125 14 25.8125C10.8263 25.8125 7.9468 24.564 5.82539 22.5308C5.73957 22.4486 5.7467 22.3101 5.83904 22.2352L23.7454 7.71718ZM4.13752 20.5079C4.20185 20.6053 4.33604 20.6246 4.42667 20.5511L22.3864 5.9868C22.4771 5.91326 22.4859 5.77789 22.4038 5.69486C20.263 3.52874 17.2895 2.1875 14 2.1875C7.47061 2.1875 2.1875 7.47147 2.1875 14C2.1875 16.4058 2.90486 18.6424 4.13752 20.5079Z"
                fill={color}
            />
        </svg>
    );
}

export default ActiveSearch;
