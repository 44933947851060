/* eslint-disable max-len */

import { Icon } from 'types';

function TimeIcon8({ color = '#3959D9', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                d="M19.0563 8.81274L20.5826 7.04086C20.7308 6.86892 20.7078 6.61242 20.5313 6.46853L19.3004 5.46247C19.1239 5.31816 18.8605 5.34011 18.7127 5.51247L17.292 7.162C16.1179 6.32585 14.7313 5.76286 13.2246 5.56124V4.09911H15.0394C15.2426 4.09911 15.4079 3.93814 15.4079 3.74018V2.35893C15.4079 2.16097 15.2426 2 15.0394 2H8.96021C8.75653 2 8.59166 2.16097 8.59166 2.35893V3.74018C8.59166 3.93814 8.75653 4.09911 8.96021 4.09911H10.775V5.56124C9.26824 5.76246 7.88169 6.32585 6.70802 7.16159L5.28683 5.51247C5.13866 5.34011 4.87571 5.31776 4.69874 5.46247L3.4683 6.46812C3.29133 6.61202 3.26838 6.86851 3.41655 7.04046L4.94291 8.81193C3.73042 10.3058 3 12.1874 3 14.2349C3 19.068 7.03733 23 12 23C16.9627 23 21 19.068 21 14.2349C20.9996 12.1874 20.2692 10.3062 19.0563 8.81274ZM11.9996 21.5773C7.84288 21.5773 4.46042 18.2835 4.46042 14.2349C4.46042 10.1867 7.84288 6.89249 11.9996 6.89249C16.1567 6.89249 19.5387 10.1867 19.5387 14.2349C19.5387 18.2839 16.1567 21.5773 11.9996 21.5773Z"
                fill={color}
            />
            <path
                d="M11.9951 10.7686C12.6722 10.7686 13.2077 10.9248 13.6016 11.2373C13.9954 11.5465 14.1924 11.9616 14.1924 12.4824C14.1924 13.2148 13.7529 13.7975 12.874 14.2305C13.4339 14.5104 13.8311 14.805 14.0654 15.1143C14.3031 15.4235 14.4219 15.7686 14.4219 16.1494C14.4219 16.7386 14.2054 17.2106 13.7725 17.5654C13.3395 17.9202 12.7536 18.0977 12.0146 18.0977C11.2399 18.0977 10.6393 17.9316 10.2129 17.5996C9.78646 17.2676 9.57324 16.7972 9.57324 16.1885C9.57324 15.7913 9.68392 15.4349 9.90527 15.1191C10.1299 14.8001 10.4961 14.5169 11.0039 14.2695C10.5677 14.0091 10.2568 13.734 10.0713 13.4443C9.88574 13.1546 9.79297 12.8291 9.79297 12.4678C9.79297 11.9502 9.99642 11.5384 10.4033 11.2324C10.8102 10.9232 11.3408 10.7686 11.9951 10.7686ZM10.6719 16.1494C10.6719 16.488 10.7907 16.7516 11.0283 16.9404C11.266 17.126 11.5882 17.2188 11.9951 17.2188C12.415 17.2188 12.7406 17.1227 12.9717 16.9307C13.2061 16.7354 13.3232 16.4717 13.3232 16.1396C13.3232 15.876 13.2158 15.6351 13.001 15.417C12.7861 15.1989 12.4606 14.9971 12.0244 14.8115L11.8828 14.748C11.4531 14.9368 11.1439 15.1452 10.9551 15.373C10.7663 15.5977 10.6719 15.8564 10.6719 16.1494ZM11.9854 11.6523C11.6598 11.6523 11.3978 11.7337 11.1992 11.8965C11.0007 12.056 10.9014 12.2741 10.9014 12.5508C10.9014 12.7201 10.9372 12.8714 11.0088 13.0049C11.0804 13.1383 11.1846 13.2604 11.3213 13.3711C11.458 13.4785 11.6891 13.6087 12.0146 13.7617C12.4053 13.5892 12.6803 13.4085 12.8398 13.2197C13.0026 13.0277 13.084 12.8047 13.084 12.5508C13.084 12.2741 12.9831 12.056 12.7812 11.8965C12.5827 11.7337 12.3174 11.6523 11.9854 11.6523Z"
                fill={color}
            />
        </svg>
    );
}

export default TimeIcon8;
