/* eslint-disable max-len */

import { Icon } from 'types';

function TVGames({ color = '#3959D9', width = 36, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.0338 10.8009C15.9806 11.3571 15.4244 11.715 14.873 11.5602C14.6457 11.4974 14.4523 11.3716 14.2588 11.2217C14.2428 11.2057 14.2268 11.193 14.208 11.1781L14.196 11.1685C14.3652 11.8843 14.844 12.542 15.7582 12.5662C15.7941 12.5662 15.83 12.567 15.8659 12.5678C15.9558 12.5698 16.0456 12.5718 16.1354 12.5614C16.2418 12.5517 16.2853 12.5952 16.2998 12.692C15.1681 12.9725 14.0363 13.2482 12.8997 13.5238C12.8804 13.4416 12.8756 13.3836 12.9771 13.3401C13.1174 13.282 13.248 13.2046 13.3785 13.1273C13.9105 12.808 14.1475 12.3243 14.133 11.715C14.1292 11.5796 14.1129 11.4441 14.0967 11.3087C14.0926 11.2749 14.0885 11.241 14.0847 11.2071C14.0515 11.2629 14.02 11.319 13.9892 11.3741C13.9211 11.4955 13.8549 11.6134 13.7751 11.7198C13.3785 12.2325 12.653 12.2422 12.2951 11.7392C12.0581 11.4054 12.0436 11.0282 12.2177 10.6703C12.3225 10.4576 12.4481 10.2554 12.574 10.0526C12.6399 9.9464 12.7059 9.84005 12.7691 9.73198C12.8997 9.51919 13.0303 9.30637 13.1415 9.0839C13.2963 8.78404 13.3543 8.45032 13.364 8.11177C13.364 8.09981 13.3659 8.08576 13.3684 8.06668C13.37 8.05494 13.3719 8.04122 13.3737 8.02471C13.6058 8.4842 13.9347 8.8179 14.3507 9.0694C14.4668 9.14 14.5841 9.20637 14.7009 9.27243C14.8321 9.34664 14.9627 9.42054 15.0907 9.49986C15.2793 9.61593 15.4728 9.73684 15.642 9.8771C15.9274 10.1141 16.0725 10.4285 16.0338 10.8009Z"
                fill={color}
            />
            <path
                d="M20.516 11.3798L20.4739 11.2653C20.4062 11.0834 20.3369 10.9015 20.2677 10.7196C20.1638 10.4468 20.0599 10.1741 19.9613 9.90142C19.8452 9.58702 19.7823 9.26298 19.8113 8.92443C19.8645 8.31503 20.3482 7.86523 20.9334 7.87974C21.4219 7.89424 21.8475 8.21346 22.0023 8.70196C22.0435 8.82909 22.0677 8.96116 22.0921 9.09459C22.102 9.14894 22.112 9.20357 22.1232 9.25816C22.1619 9.20012 22.2054 9.13724 22.2538 9.0792C22.515 8.74547 22.839 8.53749 23.2743 8.54233C23.9127 8.54715 24.4157 9.07916 24.3625 9.7176C24.3335 10.0658 24.1594 10.356 23.9417 10.6172C23.6806 10.9268 23.3662 11.1686 23.0421 11.4056L22.9119 11.5006C22.452 11.8356 21.9937 12.1695 21.596 12.576C21.44 12.7353 21.297 12.9099 21.1585 13.0791C21.0908 13.1618 21.0242 13.2431 20.9576 13.3209C20.9529 13.2669 20.9488 13.2099 20.9445 13.1512C20.9354 13.0256 20.9257 12.8916 20.9092 12.7598C20.8468 12.2783 20.6816 11.8296 20.516 11.3798Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.4 0C1.07452 0 0 1.07451 0 2.39999V19.2C0 20.5255 1.07452 21.6 2.4 21.6H33.6C34.9254 21.6 36 20.5255 36 19.2V2.39999C36 1.07451 34.9254 0 33.6 0H2.4ZM17.2284 4.10274C17.0591 3.41596 16.3674 2.99517 15.6758 3.15962L9.0787 4.7702C8.39192 4.93947 7.97112 5.63111 8.13557 6.32271L10.757 17.0359C10.9021 17.6211 11.4245 18.0129 12.0048 18.0129C12.1064 18.0129 12.208 18.0032 12.3095 17.9742L18.9018 16.3633C19.2355 16.2811 19.5161 16.0779 19.695 15.7829C19.874 15.4879 19.9272 15.1444 19.845 14.8107L17.2284 4.10274ZM28.0736 5.95394C27.9043 5.65408 27.6335 5.44125 27.3046 5.34455L20.7609 3.50182C20.432 3.40993 20.0838 3.44862 19.7839 3.6179C19.484 3.78717 19.2712 4.05802 19.1745 4.38692L18.4198 7.06178L20.3593 14.9936C20.4415 15.3273 20.3883 15.6707 20.2094 15.9658C20.0304 16.2608 19.7499 16.4639 19.4162 16.5462L18.0909 16.8705L23.6237 18.4279C23.7398 18.4617 23.8559 18.4762 23.972 18.4762C24.1896 18.4762 24.4024 18.4231 24.5958 18.3118C24.8957 18.1425 25.1085 17.8717 25.2052 17.5428L28.1942 6.92645C28.2813 6.59756 28.238 6.25379 28.0736 5.95394Z"
                fill={color}
            />
            <path
                d="M25.2 22.8H10.8C10.4686 22.8 10.2 23.0686 10.2 23.4C10.2 23.7314 10.4686 24 10.8 24H25.2C25.5313 24 25.8 23.7314 25.8 23.4C25.8 23.0686 25.5313 22.8 25.2 22.8Z"
                fill={color}
            />
        </svg>
    );
}

export default TVGames;
