import { BehaviorSubject } from 'rxjs';
import PaymentService from 'service/Payment';
import PixFormService from './PixForm';
import StatusFormPopupService, { StatusForm } from '../../../Deposit/DepositPopup/service/StatusForm';

enum Directa24WithdrawState {
    INIT,
    COMPLETE,
    AWAIT,
    HAS_FEE,
    ACCEPT,
    REJECT,
    BONUS_REJECT,
}

class Directa24Withdraw {
    state = new BehaviorSubject<Directa24WithdrawState>(Directa24WithdrawState.INIT);

    handler = {
        onWithdraw: this.onWithdraw.bind(this),
        onError: this.onError.bind(this),
    };

    constructor() {
        PaymentService.directa24.withdrawResult.subscribe(this.handler.onWithdraw);
        PaymentService.directa24.error.subscribe(this.handler.onError);
    }

    onWithdraw() {
        this.state.next(Directa24WithdrawState.ACCEPT);
        StatusFormPopupService.status.next(StatusForm.SUCCESS);
    }

    onError() {
        this.state.next(Directa24WithdrawState.REJECT);
        StatusFormPopupService.status.next(StatusForm.FAILED);
    }

    reset() {
        this.state.next(Directa24WithdrawState.INIT);
        StatusFormPopupService.status.next(StatusForm.INIT);
        PixFormService.reset();
    }
}

const Directa24WithdrawService = new Directa24Withdraw();

export { Directa24WithdrawState };
export default Directa24WithdrawService;
