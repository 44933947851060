import classNames from 'classnames';
import { PaymentMethod } from 'page/Profile/Balance/PaymentMethod/types';
import { formatCurrency } from 'utils/format';
import useObservable from 'utils/useObservable';

import LanguageService from '../../../../../../../service/Language';
import AmountFormService from '../../service/AmountForm';

import './SumSelector.scss';

type SumSelectorProps = {
    paymentMethod: PaymentMethod;
    disabled: boolean;
};

const SumSelector = (props: SumSelectorProps) => {
    const amount = useObservable(AmountFormService.amount.value, AmountFormService.amount.value.getValue());

    return (
        <div className="sum-selector-epul">
            {props.paymentMethod.amountPreset.map((preset, index) => {
                const itemClass = classNames('sum-selector-epul__item', `item-${index}`, {
                    active: Number(amount) === Number(preset),                });
                return (
                    <button
                        className={itemClass}
                        onClick={() => AmountFormService.setValue(preset)}
                        disabled={props.disabled}
                        key={index}
                    >
                        {formatCurrency(Number(preset), 0)}
                    </button>
                );
            })}
        </div>
    );
};

export default SumSelector;
