import classNames from 'classnames';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';

import CardFormService from '../service/CardForm';

import './WithdrawalButton.scss';

type WithdrawalButtonType = {
    buttonTitle: string;
    variation?: boolean;
};

function WithdrawalButton(props: WithdrawalButtonType) {
    const { buttonTitle, variation } = props;
    const disabled = useObservable<boolean>(CardFormService.valid, false);
    const className = classNames('withdrawal-button', {
        'withdrawal-button__active': !disabled,
        'withdrawal-button__variated': variation,
    });

    return (
        <button type="submit" className={className}>
            {t(buttonTitle)}
        </button>
    );
}

export default WithdrawalButton;
