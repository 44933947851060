import RouteConfig from 'appConstants/RouteConfig';
import classNames from 'classnames';
import Close from 'component/Close/Close';
import { ListType } from 'enums/payment';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AuthService from 'service/Auth';
import GoogleService from 'service/Google';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';

import PaymentList from '../../PaymentList/PaymentList';
import PaymentTabs from '../../PaymentTabs/PaymentTabs';
import Deposit from '../Deposit';
import DepositService from '../service/Deposit';
import StatusFormPopupService, { StatusForm } from './service/StatusForm';
import back from './image/back.svg';
import CryptoCurrencyList from '../../СryptocurrencyList/CryptoCurrencyList';

import './DepositPopup.scss';


function DepositPopup() {
    const auth = useObservable(AuthService.auth, AuthService.auth.getValue());
    const method = useObservable(DepositService.method, DepositService.method.getValue());
    const status = useObservable(StatusFormPopupService.status, StatusFormPopupService.status.getValue());
    const location = useLocation();

    useEffect(() => {
        if (location.hash !== `#${RouteConfig.hash.deposit}`) {
            DepositService.back();
            StatusFormPopupService.status.next(StatusForm.INIT);
        }
        return ()=> {
            StatusFormPopupService.status.next(StatusForm.INIT)
        }
    }, [location]);

    useEffect(() => {
        if (auth && method === null) {
            GoogleService.openDeposit(auth.userId.toString());
        }
    }, [auth, method]);

    if (!auth) return null;

    const classNameTitle = classNames('payment-popup__title', {
        small: !method,
        'payment-popup__title_result': status === StatusForm.SUCCESS || status === StatusForm.FAILED,
    });

    return (
        <div className="payment-popup">
            <div className="payment-popup__header">
                {method && status !== StatusForm.SUCCESS && status !== StatusForm.FAILED && (
                    <button className="payment-popup__back" onClick={(e) => DepositService.back()}>
                        <img className="payment-popup__img" src={back} alt="back" />
                    </button>
                )}
                <div className={classNameTitle}>
                    {t(method ? 'profile.balance.deposit.title' : 'profile.balance.popup.title')}
                </div>
                <Close size="s" />
            </div>
            {!method && (
                <>
                    <div className="payment-popup__tab">
                        <PaymentTabs />
                    </div>
                    <div className="payment-popup__hint">{t('profile.balance.deposit.select')}</div>
                    <PaymentList className="payment-popup__list" type={ListType.DEPOSIT} />
                    {auth.currencyCode !== 104 ? <CryptoCurrencyList className="payment-popup__list" type={ListType.DEPOSIT}/>: null}
                </>
            )}
            {method && <Deposit method={method} />}

        </div>
    );
}

export default DepositPopup;
