import Input from 'component/Input/service/Input';

import Validator from '../../../service/Validator/Validator';

const PasswordInputService = new Input({
    title: 'login.password',
    validator: Validator.nonEmpty,
});

export default PasswordInputService;
