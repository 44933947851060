import { formatCurrencySign } from 'utils/format';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { translate as t } from '../../../../../../../service/Language';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import AmountFormService, { AmountError, CashixInputTip } from '../../service/AmountForm';
import Submit from '../Button/Submit';

import { createRef } from 'react';
import AttentionIcon from '../../../../../../../assets/svg/attention.svg'
import SumSelector from '../../SummSelector/SumSelector';

import './Amount.scss';


type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {};

class Amount extends RxComponent<AmountProps, AmountState> {
    state = {
        tip: CashixInputTip.NONE,
        amountLimit: AmountError.NONE,
        errorAmount: null,
    };

    handler = {
        onTip: this.onTip.bind(this),
        onErrorAmount: this.onErrorAmount.bind(this),
        onAmountLimit: this.onAmountLimit.bind(this),
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    onTip(tip: CashixInputTip) {
        this.setState({ tip });
    }

    onAmountLimit(amountLimit: AmountError) {
        this.setState({amountLimit});
    }

    onErrorAmount(errorAmount: string | null) {
        this.setState({errorAmount});
    }

    componentDidMount() {
        const {paymentMethod} = this.props;

        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setValue(paymentMethod.defaultValue);
        this.subscribe(AmountFormService.amount.error, {next: this.handler.onErrorAmount});
        this.subscribe(AmountFormService.amountLimit, {next: this.handler.onAmountLimit});
        this.subscribe(AmountFormService.tip, {next: this.handler.onTip});
    }

    render() {
        const { paymentMethod, lock } = this.props;
        const { errorAmount, amountLimit, tip } = this.state;

        const min =  formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency);
        const max =  formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency);

        const hint = `${t('popup.payment.amount.min')} ${min} — ${t('popup.payment.amount.max')} ${max}`;

        return (
            <div className="deposit-amount-cashix">
                <form className="deposit-amount-cashix-form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    <div className="deposit-amount-cashix-form-container">
                        <MaskedInput
                            className="deposit-amount-cashix-form-container__input"
                            inputService={AmountFormService.amount}
                            name="amount"
                            mask="00000000000"
                            enabled={!lock}
                        />
                        <span
                            className="deposit-amount-cashix-form-container__currency">{paymentMethod.currency.sign}</span>
                        {!!errorAmount && (
                            <div className="deposit-amount-cashix-form-container__error">
                                <img src={AttentionIcon} alt="attention"/>
                                <span className="deposit-amount-cashix-form-container__error_text">
                        {amountLimit === AmountError.AMOUNT_MIN && t('popup.payment.amount.min') + ` ${min}`}
                                    {amountLimit === AmountError.AMOUNT_MAX && t('popup.payment.amount.max') + ` ${max}` }
                                    </span>
                            </div>
                        )}
                        {tip === CashixInputTip.AMOUNT && !errorAmount ? (
                            <span className="deposit-amount-cashix-form-container__tip">
                                {hint}
                            </span>
                        ) : null}
                    </div>
                    <div className="deposit-amount-cashix__sum">
                        <SumSelector paymentMethod={paymentMethod} disabled={lock}/>
                    </div>
                    <div className="deposit-amount-cashix__submit">
                        <Submit/>
                    </div>
                </form>
            </div>
        );
    }
}

export default Amount;
