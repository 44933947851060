import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';

import AuthService from '../../../../../../service/Auth';
import GoogleService from '../../../../../../service/Google';
import Validator from '../../../../../../service/Validator/Validator';
import { PaymentMethod } from '../../../PaymentMethod/types';
import AzarpayDepositService, { DepositState } from './AzarpayDeposit';
import { translate as t } from '../../../../../../service/Language';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import { OnlyLetterService } from '../../../../../../service/Validator/OnlyLetter';
import { CheckAzerLettersService } from '../../../../../../service/Validator/CheckAzerLetters';
import WithdrawService from '../../../Withdrawal/service/Withdrawal';
import { AzarpayError } from '../../../Withdrawal/Azarpay/service/CardForm';
import BonusService from '../../../../../../service/Bonus';
import StatusFormPopupService, { StatusForm } from '../../DepositPopup/service/StatusForm';
import BalanceService from '../../../../../../service/Balance';

export enum AzarInputTip {
    NONE,
    AMOUNT,
    NAME,
    SURNAME,
}

export enum AzarLettersTip {
    NONE,
    NAME,
    SURNAME,
}

export enum AmountError {
    NONE,
    AMOUNT_MIN,
    AMOUNT_MAX,
    AMOUNT_WITHDRAWAL,
    AMOUNT_FEE,
}

class AmountForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(1, 1000000);

    amount = new InputService({
        title: 'profile.balance.deposit.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.deposit.input.amount',
    });

    name = new InputService({
        title: 'popup.payment.pix.name',
        validator: Validator.OnlyLetter,
        validatorText: 'popup.payment.pix.name',
    });

    surname = new InputService({
        title: 'popup.payment.pix.surname',
        validator: Validator.OnlyLetter,
        validatorText: 'popup.payment.pix.surname',
    });

    tip = new BehaviorSubject<AzarInputTip>(AzarInputTip.NONE);

    errorLetters = new BehaviorSubject<AzarLettersTip>(AzarLettersTip.NONE);

    valid = new BehaviorSubject<boolean>(false);

    error = new BehaviorSubject<AzarpayError>(AzarpayError.NONE);

    amountLimit = new BehaviorSubject<AmountError>(AmountError.NONE);

    balance = new BehaviorSubject(0);

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        onName: this.onName.bind(this),
        onNameActive: this.onNameActive.bind(this),
        onSurname: this.onSurname.bind(this),
        onSurnameActive: this.onSurnameActive.bind(this),
        onBalance: this.onBalance.bind(this),
    }

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
        this.name.value.subscribe(this.handler.onName);
        this.name.active.subscribe(this.handler.onNameActive);
        this.surname.value.subscribe(this.handler.onSurname);
        this.surname.active.subscribe(this.handler.onSurnameActive);
        BalanceService.balance.subscribe(this.handler.onBalance);
    }

    setLimit(min: number, max: number) {
        if (this.limitValidator) {
            this.limitValidator.setLimit(min, max);
        }
    }

    setValue(value: number) {
        this.amount.onFocus();
        this.amount.value.next(value.toString());
    }

    setMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        this.setLimit(paymentMethod.limit.deposit.min, paymentMethod.limit.deposit.max);
    }

    onBalance(balance: number) {
        this.balance.next(balance)
    }

    onAmount(value: string) {
        const isValue = this.amount.value.getValue();
        this.amount.error.next(null);
        this.amountLimit.next(AmountError.NONE)
        this.error.next(AzarpayError.NONE);

        if (this.paymentMethod && isValue) {
            if (!value.indexOf('0', 0)) {
                const current = value.length;
                const done = value.slice(0, current - 1);
                this.amount.value.next(done);
            }
                if (this.paymentMethod.id === ChannelType.VALERPAY_WITHDRAWAL) {
                const isFee = WithdrawService.fee.getValue();
                const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
                const isMax = Math.ceil(this.paymentMethod.limit.withdraw.max * 1.25);
                const min = Math.ceil(this.paymentMethod.limit.withdraw.min);
                const max = Math.ceil(this.paymentMethod.limit.withdraw.max);

                if(isFee) {
                    if (this.balance.getValue() < +isValue) {
                        this.amount.error.next('profile.balance.withdraw.input.amount');
                        this.amountLimit.next(AmountError.AMOUNT_WITHDRAWAL)
                        this.valid.next(false)
                    }
                    else if (+isValue < +isMin) {
                        this.amount.error.next('profile.balance.withdraw.input.amount');
                        this.amountLimit.next(AmountError.AMOUNT_MIN)
                        this.error.next(AzarpayError.AMOUNT_FEE)
                        this.valid.next(false)
                    } else if (+isValue > isMax) {
                        this.amount.error.next('profile.balance.withdraw.input.amount');
                        this.amountLimit.next(AmountError.AMOUNT_MAX)
                        this.valid.next(false)
                    } else {
                        this.amount.error.next(null);
                        this.amountLimit.next(AmountError.NONE)
                        this.error.next(AzarpayError.NONE);
                        this.valid.next(true)
                    }
                } else {
                    if (this.balance.getValue() < +isValue) {
                        this.amount.error.next('profile.balance.withdraw.input.amount');
                        this.amountLimit.next(AmountError.AMOUNT_WITHDRAWAL)
                        this.valid.next(false)
                    }
                    else if (+isValue < +min) {
                        this.amount.error.next('profile.balance.withdraw.input.amount');
                        this.amountLimit.next(AmountError.AMOUNT_MIN)
                        this.error.next(AzarpayError.AMOUNT_FEE)
                        this.valid.next(false)
                    } else if (+isValue > max) {
                        this.amount.error.next('profile.balance.withdraw.input.amount');
                        this.amountLimit.next(AmountError.AMOUNT_MAX)
                        this.valid.next(false)
                    } else {
                        this.amount.error.next(null);
                        this.amountLimit.next(AmountError.NONE)
                        this.error.next(AzarpayError.NONE);
                        this.valid.next(true)
                    }
                }

            } else {
                if (!this.limitValidator.validate(value) && this.amount.getValue()) {
                    this.amount.error.next(t('profile.balance.deposit.input.amount'));
                    this.valid.next(false);
                } else {
                    this.checkValid();
                    this.amount.error.next(null);
                }
            }
        }  else {
            this.amount.error.next(null);
            this.checkValid();
        }
    }

    onAmountActive(active: boolean) {
        if (active) {
            this.tip.next(AzarInputTip.AMOUNT);
        } else {
            this.tip.next(AzarInputTip.NONE);
        }
    }

    onName(value: string) {
        if (this.name.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.name.value.next(name);
            this.valid.next(false);
            if (CheckAzerLettersService.validate(value)) {
                this.errorLetters.next(AzarLettersTip.NAME)
            } else {
                this.errorLetters.next(AzarLettersTip.NONE)
            }

        } else {
            this.name.error.next(null);
            this.errorLetters.next(AzarLettersTip.NONE)
        }
        this.checkValid();
    }

    onNameActive(active: boolean) {
        if (active) {
            this.tip.next(AzarInputTip.NAME);
        } else {
            this.tip.next(AzarInputTip.NONE);
        }
    }

    onSurname(value: string) {
        if (this.surname.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.surname.value.next(name);
            this.valid.next(false);
            if (CheckAzerLettersService.validate(value)) {
                this.errorLetters.next(AzarLettersTip.SURNAME)
            } else {
                this.errorLetters.next(AzarLettersTip.NONE)
            }
        } else {
            this.name.error.next(null);
            this.errorLetters.next(AzarLettersTip.NONE)
        }
        this.checkValid();
    }

    onSurnameActive(active: boolean) {
        if (active) {
            this.tip.next(AzarInputTip.SURNAME);
        } else {
            this.tip.next(AzarInputTip.NONE);
        }
    }

    checkValid() {
        if (this.paymentMethod) {
            if (this.paymentMethod.id === ChannelType.AZN_AZARPAY || this.paymentMethod.id === ChannelType.VALERPAY_DEPOSIT || this.paymentMethod.id === ChannelType.VALERPAY_WITHDRAWAL ) {
                const isAmount = this.amount.isValid();
                this.valid.next(isAmount);
                if(this.valid.getValue()) {
                    this.amount.error.next(null);
                    this.amountLimit.next(AmountError.NONE)
                    this.error.next(AzarpayError.NONE);
                }
            }
            else if(this.paymentMethod.id === ChannelType.AZARPAY_VISA) {
                this.valid.next(true);
            }
        }
    }

    reset() {
        this.amount.value.next('');
        this.name.value.next('');
        this.surname.value.next('');

        this.amount.error.next(null);
        this.name.error.next(null);
        this.surname.error.next(null);

        this.valid.next(false);
        this.amount.error.next(null);
        this.amountLimit.next(AmountError.NONE)
        this.error.next(AzarpayError.NONE);
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        if ( this.paymentMethod && this.valid.getValue() ) {
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            const amount = parseFloat(this.amount.getValue());

            GoogleService.sentDepositAmount(
                userId,
                this.paymentMethod.title,
                amount.toString(),
                this.paymentMethod.currency.name
            );
            AzarpayDepositService.state.next(DepositState.WAIT);
            if (this.paymentMethod.id === ChannelType.VALERPAY_WITHDRAWAL) {
                const fee = WithdrawService.fee.getValue();
                console.log('SUBMIT fee', fee);
                const isBonuses = BonusService.list.getValue().length;
                if (isBonuses) {
                    AzarpayDepositService.state.next(DepositState.BONUS_REJECT);
                    StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
                } else {
                    AzarpayDepositService.depositBank(this.paymentMethod, amount);
                }
            } else {
                if (this.paymentMethod.id === ChannelType.AZN_AZARPAY || this.paymentMethod.id === ChannelType.VALERPAY_DEPOSIT) {
                    AzarpayDepositService.depositBank(this.paymentMethod, amount);
                } else if (this.paymentMethod.id === ChannelType.AZARPAY_VISA) {
                    AzarpayDepositService.depositVisa(this.paymentMethod);
                }
            }
        }
    }
}

const AmountFormService = new AmountForm();

export default AmountFormService;
