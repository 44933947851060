import { NavLink } from 'react-router-dom';
import { translate as t } from 'service/Language';

import { list } from '../Info';

import './Navigation.scss';

function Navigation() {
    return (
        <div className="info-navigation">
            <div className="info-navigation__category">{t('info.navigation.info')}</div>
            {list.map((item) => (
                <NavLink
                    className="info-navigation__link"
                    activeClassName="info-navigation__link____active"
                    to={item.path}
                >
                    {t(item.title)}
                </NavLink>
            ))}
        </div>
    );
}

export default Navigation;
