import {
    WellxpayBkashP2CDepositRequest
} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendWellxpayBkashP2CDepositRequest';
import { WellxpayWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendWellxpayWithdraw';
import { WellxPayInvoiceRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendWellxPayInvoiceRequest';
import { WellxPayDepositStepTwo } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendWellxPayDepositStepTwo';

interface WellxPayHandler {
    depositBKashP2C(request: WellxpayBkashP2CDepositRequest): void;
    withdraw(withdraw: WellxpayWithdrawRequest): void;
    createInvoice(invoice: WellxPayInvoiceRequest): void;
    depositStepTwo(payment: WellxPayDepositStepTwo): void;
}

class WellxPayProvider {
    handler: WellxPayHandler;

    constructor(handler: WellxPayHandler) {
        this.handler = handler;
    }

    depositBKashP2C(request: WellxpayBkashP2CDepositRequest): void {
        this.handler.depositBKashP2C(request);
    }

    withdraw(withdraw: WellxpayWithdrawRequest) {
        this.handler.withdraw(withdraw);
    }

    createInvoice(invoiceRequest: WellxPayInvoiceRequest) {
        this.handler.createInvoice(invoiceRequest);
    }

    depositStepTwo(payment: WellxPayDepositStepTwo) {
        this.handler.depositStepTwo(payment);
    }
}

export type { WellxPayHandler };
export default WellxPayProvider;
