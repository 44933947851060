import classNames from 'classnames';
import { translate as t } from 'service/Language';

import useObservable from '../../../../../utils/useObservable';
import EmailFormService from '../ChangeEmail/service/EmailFormService';

import './ChangeButton.scss';

function ChangeButton() {
    const active = useObservable<boolean>(EmailFormService.active, EmailFormService.active.value);

    const buttonClass = classNames({
        'change-button__title': true,
        'change-button__title____active': active,
    });

    return (
        <div className="change-button">
            <button className={buttonClass}>{t('profile.setting.logout.accept')}</button>
        </div>
    );
}

export default ChangeButton;
