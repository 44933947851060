import {ConstantposDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendConstantposDepositRequest';
import ConstantposInterface from '../../interface/payment/Constantpos';
import {ConstantposWithdrawRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendConstantposWithdraw';

interface ConstantposHandler {
    deposit(request: ConstantposDepositRequest): void;

    withdraw(request: ConstantposWithdrawRequest): void;
}

class ConstantposProvider implements ConstantposInterface {
    handler: ConstantposHandler;

    constructor(handler: ConstantposHandler) {
        this.handler = handler;
    }

    deposit(request: ConstantposDepositRequest): void {
        this.handler.deposit(request);
    }

    withdraw(request: ConstantposWithdrawRequest): void {
        this.handler.withdraw(request);
    }
}

export type { ConstantposHandler };
export default ConstantposProvider;
