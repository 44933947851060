import {
    ConstantposDepositCode,
    ConstantposDepositResponse,
    ConstantposWithdrawResponse
} from '@ay_tsarbet/newbet-core/dist/types';
import {Subject} from 'rxjs';
import WSBet from '../WSBet';
import ConstantposInterface from '../interface/payment/Constantpos';
import {ConstantposDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendConstantposDepositRequest';
import {ConstantposWithdrawRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendConstantposWithdraw';
import ConstantposDepositService from '../../page/Profile/Balance/Deposit/Constantpos/service/ConstantposDeposit';

enum ConstantposError {
    UNKNOWN,
    DEPOSIT,
    WITHDRAW,
}

class Constantpos implements ConstantposInterface {
    error = new Subject<ConstantposError>();

    withdrawResult = new Subject<void>();

    deposit(request: ConstantposDepositRequest) {
        console.log('deposit', request);
        WSBet.payment.constantpos.deposit(request);
    }

    withdraw(withdraw: ConstantposWithdrawRequest) {
        console.log('withdraw', withdraw);
        WSBet.payment.constantpos.withdraw(withdraw);
    }

    onDepositReceive(data: ConstantposDepositResponse) {
        console.log('Constantpos | DEPOSIT RESPONSE', data);
     if (data.code >= 0) {
         ConstantposDepositService.onReceive(data.message);
         console.log('data.message', data.message)
        } else {
         ConstantposDepositService.onError();
        }
    }
    onWithdrawReceive(response: ConstantposWithdrawResponse) {
        console.log('Constantpos  | WITHDRAW RESPONSE', response);
        if (response.errorCode >= ConstantposDepositCode.success) {
            this.withdrawResult.next();
        } else {
            this.error.next(ConstantposError.WITHDRAW);
        }
    }
}

export { ConstantposError };
export default Constantpos;
