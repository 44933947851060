/* eslint-disable max-len */

import { Icon } from 'types';

function Stats({ color = '#3F4250', width = 28, height = 18 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 18" fill="none">
            <path
                d="M27.7143 18H0.285714C0.12779 18 0 17.8746 0 17.7196V0.280352C0 0.125392 0.12779 0 0.285714 0H27.7143C27.8722 0 28 0.125392 28 0.280352V17.7196C28 17.8746 27.8722 18 27.7143 18ZM1 17H27V1H1V17Z"
                fill={color}
            />
            <rect x="3" y="13.0002" width="6" height="1" rx="0.5" fill={color} />
            <rect x="3" y="10.0002" width="6" height="1" rx="0.5" fill={color} />
            <rect x="3" y="7.00024" width="6" height="1" rx="0.5" fill={color} />
            <rect x="3" y="4.00024" width="6" height="1" rx="0.5" fill={color} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.0706 9.46894C13.0743 9.55517 13.1169 9.6354 13.1871 9.68695C13.196 9.69371 13.2054 9.69977 13.2146 9.70507C13.2799 9.74274 13.3573 9.75364 13.4306 9.73494L18.0972 8.53159C18.2487 8.49255 18.3372 8.33915 18.2953 8.1885L17.009 3.5482C16.9874 3.47062 16.9328 3.40337 16.8614 3.36849C16.7887 3.33155 16.7044 3.32684 16.6292 3.35551C16.5524 3.38481 14.7422 4.08823 13.8935 5.55481C12.9858 7.12482 13.0668 9.37408 13.0706 9.46894ZM14.7592 6.05535C15.0709 5.51694 15.5877 5.07912 16.089 4.75901C16.1527 4.71834 16.215 4.68028 16.2753 4.64483L17.1352 7.74695L14.0981 8.5301C14.1071 8.4212 14.1185 8.30543 14.1331 8.18457C14.2191 7.46986 14.4038 6.67028 14.7591 6.05567M17.8476 7.56325L17.8475 7.56326L17.8476 7.56325ZM16.4151 4.26333C16.4141 4.26283 16.4131 4.26232 16.4121 4.26181L16.4183 4.26491"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.7676 14.5468C24.4894 13.8446 26.0906 11.0668 25.3359 8.35392C24.972 7.04558 24.1159 5.94588 22.9245 5.25712L22.9221 5.25572C21.7304 4.56766 20.3489 4.37551 19.0331 4.71497C18.8816 4.75401 18.7931 4.90741 18.835 5.05806L20.0455 9.42569L15.6523 10.5583C15.5796 10.5771 15.5179 10.6237 15.4807 10.6882C15.4435 10.7526 15.434 10.8294 15.4541 10.9017C15.8314 12.2582 16.7278 13.3451 17.8712 14.0053C19.0146 14.6654 20.4041 14.8983 21.7676 14.5468ZM20.9113 9.92608C20.777 10.1584 20.555 10.327 20.2951 10.394L16.6786 11.3264C17.0509 12.0971 17.6482 12.7218 18.3712 13.1392C19.3002 13.6756 20.4216 13.8611 21.5179 13.5784C23.6965 13.0163 24.9776 10.7969 24.3725 8.62195C23.7478 6.37577 21.5 5.5 20.0125 5.56267L21.0091 9.1586C21.0808 9.4172 21.0456 9.69375 20.9113 9.92608Z"
                fill={color}
            />
        </svg>
    );
}

export default Stats;
