import paymentMethod from 'page/Profile/Balance/PaymentMethod/methods';
import { PaymentMethod } from 'page/Profile/Balance/PaymentMethod/types';
import Currency, { currencyList, unknownCurrency } from 'service/model/Currency';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import PayTM from '../assets/payIcons/PayTM.svg';
import PhonePe from '../assets/payIcons/PhonePe.svg';
import GPay from '../assets/svg/payments/gpay.svg';
import { SystemTypes } from '../enums/payment';
import cryptoPaymentMethod from '../page/Profile/Balance/PaymentMethod/cryptoMethods';
import UserDataService from '../service/UserData';

export type PaymentMethodsListType = {
    deposits: PaymentMethod[];
    withdraws: PaymentMethod[];
    cryptoDepositList: PaymentMethod[];
    cryptoWithdrawalList: PaymentMethod[];
};

interface ConvertedData {
    amountPreset: number[];
    currency: Currency;
    defaultValue: number;
    getLink: () => string;
    icon: string;
    id: number;
    limit: {
        deposit: { min: number; max: number };
        withdraw: { min: number; max: number };
    };
    requireEmail: boolean;
    slug: string;
    system: number;
    title: string;
}

export const mapPaymentsFromBack = (
    data: Record<string, any>,
    userCurrency: number,
    // userDepositCount: number, // User's number of successful deposits
): PaymentMethodsListType => {
    const result: PaymentMethodsListType = {
        deposits: [],
        withdraws: [],
        cryptoDepositList: [],
        cryptoWithdrawalList: [],
    };

    // Function to select a method by percentage
    const selectMethodByPercent = (methods: any[]) => {
        const totalPercent = methods.reduce((sum, method) => sum + method.shuffle.percent, 0);
        let randomValue = Math.random() * totalPercent;
        for (const method of methods) {
            randomValue -= method.shuffle.percent;
            if (randomValue <= 0) return method;
        }
        return methods[0];
    };

    Object.values(data).forEach(currencyData => {
        currencyData.forEach((methodData: any) => {
            if (!methodData.methods || !Array.isArray(methodData.methods)) {
                return;
            }

            let methods = [];
            // Filter and process methods based on type_in
            // if (methodData.type_in === 1) {
            //     methods = methodData.methods.filter((method: any) => method.is_active);
            //     methods = [selectMethodByPercent(methods)];
            // } else if (methodData.type_in === 2) {
            //     methods = methodData.methods.filter(
            //         (method: any) =>
            //             method.is_active && userDepositCount >= method.shuffle.dep_from,
            //     );
            // } else {
            //     methods = methodData.methods.filter((method: any) => method.is_active);
            // }
            methods = methodData.methods.filter((method: any) => method.id_currency === userCurrency && method.is_active);
            methods.forEach((method: any) => {
                const idCurrency = method.id_currency;
                const currentCurrency =
                    currencyList.find(item => item.ISO === idCurrency) || unknownCurrency;
                const foundedMethod = Object.values(paymentMethod).find(
                    item => item.id === method.id_channel,
                );
                const foundedCryptoMethod = Object.values(cryptoPaymentMethod).find(
                    item => item.id === method.id_channel,
                );

                const getIcon = (): string => {
                    if (
                        foundedMethod &&
                        (foundedMethod.id === ChannelType.CONSTANTPOS_UPI ||
                            foundedMethod.id === ChannelType.UPI_FAST_APAY ||
                            foundedMethod.id === ChannelType.UPI_S)
                    ) {
                        switch (method.channel_name) {
                            case 'PayTM Fast':
                                return PayTM;
                            case 'PhonePe Fast':
                                return PhonePe;
                            case 'GooglePay':
                                return GPay;
                            default:
                                return foundedMethod ? foundedMethod.icon : '';
                        }
                    } else if (foundedCryptoMethod) {
                        return foundedCryptoMethod ? foundedCryptoMethod.icon : '';
                    } else {
                        return foundedMethod ? foundedMethod.icon : '';
                    }
                };

                const getSystem = (): number => {
                    if (
                        foundedMethod &&
                        foundedMethod.system === SystemTypes.PAYFIX &&
                        foundedMethod.id === ChannelType.TRY_PAYFIX
                    ) {
                        return SystemTypes.PAYFIX;
                    }
                    if (
                        foundedMethod &&
                        foundedMethod.system === SystemTypes.ANINDA &&
                        foundedMethod.id === ChannelType.BANK_TRANSFER_TRY
                    ) {
                        return SystemTypes.ANINDA;
                    }
                    if (foundedCryptoMethod) {
                        return SystemTypes.COINSPAID;
                    } else {
                        return method.id_system;
                    }
                };

                const mappedMethod: ConvertedData = {
                    id: method.id_channel,
                    title: method.channel_name,
                    limit: {
                        deposit: {
                            min: method.limit.deposit.min,
                            max: method.limit.deposit.max,
                        },
                        withdraw: {
                            min: method.limit.withdraw.min,
                            max: method.limit.withdraw.max,
                        },
                    },
                    amountPreset: method.amountPreset,
                    currency: currentCurrency,
                    defaultValue: method.defaultValue,
                    getLink: foundedMethod ? foundedMethod.getLink : ()=> '',
                    icon: getIcon(),
                    requireEmail: true,
                    slug: foundedCryptoMethod ? foundedCryptoMethod.slug : method.channel_name,
                    system: getSystem(),
                };

                if (method.is_crypto === 1) {
                    if (method.has_deposit && foundedCryptoMethod) {
                        result.cryptoDepositList.push(mappedMethod);
                    }
                    if (method.has_withdraw && foundedCryptoMethod) {
                        result.cryptoWithdrawalList.push(mappedMethod);
                    }
                } else {
                    if (method.has_deposit && foundedMethod) {
                        result.deposits.push(mappedMethod);
                    }
                    if (method.has_withdraw && foundedMethod) {
                        result.withdraws.push(mappedMethod);
                    }
                }
            });
        });
    });
    return result;
};
