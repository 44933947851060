/* eslint-disable max-len */

import { Icon } from 'types';

function CallIcon({ width = 100, height = 100 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="15" fill="#F3F3F6" />
            <path
                opacity="0.5"
                d="M76.9985 62.9372V70.1632C77.0012 70.834 76.8636 71.498 76.5943 72.1126C76.325 72.7272 75.9301 73.2789 75.4348 73.7324C74.9395 74.1859 74.3548 74.5312 73.7181 74.7461C73.0813 74.961 72.4067 75.0408 71.7372 74.9804C64.3107 74.1751 57.177 71.6424 50.9094 67.5859C45.0781 63.8878 40.1342 58.9537 36.4288 53.134C32.3501 46.8504 29.8118 39.6962 29.0196 32.2511C28.9593 31.585 29.0386 30.9137 29.2525 30.2799C29.4664 29.6461 29.8102 29.0637 30.2619 28.5698C30.7137 28.0758 31.2636 27.6812 31.8766 27.411C32.4896 27.1407 33.1522 27.0009 33.8223 27.0002H41.0626C42.2338 26.9887 43.3693 27.4027 44.2574 28.1649C45.1455 28.9271 45.7255 29.9856 45.8894 31.1431C46.195 33.4556 46.7618 35.7261 47.5788 37.9114C47.9036 38.7735 47.9738 39.7104 47.7813 40.6112C47.5889 41.5119 47.1417 42.3388 46.4928 42.9936L43.4277 46.0526C46.8634 52.0828 51.8662 57.0756 57.9083 60.5045L60.9734 57.4455C61.6295 56.7979 62.458 56.3516 63.3605 56.1595C64.2631 55.9674 65.2019 56.0376 66.0657 56.3616C68.2553 57.1771 70.5304 57.7427 72.8474 58.0477C74.0198 58.2127 75.0905 58.8021 75.8558 59.7036C76.6212 60.6051 77.0279 61.756 76.9985 62.9372Z"
                fill="url(#paint0_linear_1:225)"
            />
            <path
                d="M67.9985 62.9372V70.1632C68.0012 70.834 67.8636 71.498 67.5943 72.1126C67.325 72.7272 66.9301 73.2789 66.4348 73.7324C65.9395 74.1859 65.3548 74.5312 64.7181 74.7461C64.0813 74.961 63.4067 75.0408 62.7372 74.9804C55.3107 74.1751 48.177 71.6424 41.9094 67.5859C36.0781 63.8878 31.1342 58.9537 27.4288 53.134C23.3501 46.8504 20.8118 39.6962 20.0196 32.2511C19.9593 31.585 20.0386 30.9137 20.2525 30.2799C20.4664 29.6461 20.8102 29.0637 21.2619 28.5698C21.7137 28.0758 22.2636 27.6812 22.8766 27.411C23.4896 27.1407 24.1522 27.0009 24.8223 27.0002H32.0626C33.2338 26.9887 34.3693 27.4027 35.2574 28.1649C36.1455 28.9271 36.7255 29.9856 36.8894 31.1431C37.195 33.4556 37.7618 35.7261 38.5788 37.9114C38.9036 38.7735 38.9738 39.7104 38.7813 40.6112C38.5889 41.5119 38.1417 42.3388 37.4928 42.9936L34.4277 46.0526C37.8634 52.0828 42.8662 57.0756 48.9083 60.5045L51.9734 57.4455C52.6295 56.7979 53.458 56.3516 54.3605 56.1595C55.2631 55.9674 56.2019 56.0376 57.0657 56.3616C59.2553 57.1771 61.5304 57.7427 63.8474 58.0477C65.0198 58.2127 66.0905 58.8021 66.8558 59.7036C67.6212 60.6051 68.0279 61.756 67.9985 62.9372Z"
                fill="url(#paint1_linear_1:225)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1:225"
                    x1="30.0909"
                    y1="37.5366"
                    x2="70.5472"
                    y2="47.1916"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1:225"
                    x1="21.0909"
                    y1="37.5366"
                    x2="61.5472"
                    y2="47.1916"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default CallIcon;
