import Validator from '../interface/Validator';

class AccountEasypaisa implements Validator {
    validate(value: string): boolean {
        return value.length === 11;
    }
}
const AccountEasypaisaService = new AccountEasypaisa();

export { AccountEasypaisaService };

export default AccountEasypaisa;
