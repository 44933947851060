import { Operation, Status } from '@ay_tsarbet/newbet-core/dist/types';
import paymentMethods from 'page/Profile/Balance/PaymentMethod/methods';
import { BehaviorSubject, combineLatestWith } from 'rxjs';

import WSBet from './WSBet';

class Notifications {
    news = new BehaviorSubject<Operation[]>([]);

    adds = new BehaviorSubject<Operation[]>([]);

    info = new BehaviorSubject<Operation[]>([]);

    allNotifications = new BehaviorSubject<Operation[]>([])
        .pipe(combineLatestWith(this.info))
        .pipe(combineLatestWith(this.adds))
        .pipe(combineLatestWith(this.news));

    filterNotification(operation: Operation[]) {
        const allowedNotification: number[] = Object.values(paymentMethods).map((item): number => item.id);
        const operationsNotification = operation
            .filter(
                (operation) =>
                    [...allowedNotification, 0].includes(operation.channelType) && operation.status === Status.SUCCESS
                // все текущие платежки + 400.kk.club + Status.success
            )
            .sort((a, b) => a.creationDate - b.creationDate);
        this.info.next(operationsNotification);
    }

    updateNotice(invoiceId: string) {
        WSBet.notice.updateNotice(invoiceId);
    }
}

const NotificationService = new Notifications();

export default NotificationService;
