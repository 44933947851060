import { Currency as CurrencyType, Direction, Status } from '@ay_tsarbet/newbet-core/dist/types';
import { DIRECTIONS_NAME, STATUS_NAME } from 'appConstants/operations';
import classNames from 'classnames';
import { format } from 'date-fns';
import {useEffect, useState} from 'react';
import Collapsible from 'react-collapsible';
import { translate as t } from 'service/Language';
import History from 'service/model/History';
import SvgImage from 'svg';
import { formatCurrencySign } from 'utils/format';
import UserDataService from '../../../../../service/UserData';
import _paymentMethod from '../../PaymentMethod/methods';
import OperationsService from '../../../../../service/Operations';

import './HistoryItem.scss';

type HistoryItemProps = {
    history: History;
};

function HistoryItem(props: HistoryItemProps) {
    const { id, amount, type, status, currency, created, confirmed, idTransaction, accountNumber, trxID,  paymentMethod } =
        props.history;

    const [isOpened, setIsOpened] = useState(false);

    const onClick = () => setIsOpened(!isOpened);

    const statusClass = classNames('history-item__status', {
        success: status === Status.SUCCESS,
        error: status === Status.FAIL || status === Status.CANCELED,
        pending: status === Status.CREATED,
    });

    function getIcon() {
        if (paymentMethod && paymentMethod.id === 186) {
            const currency = UserDataService.data.getValue()?.currency;

            if (currency && currency.ISO === CurrencyType.TRY) {
                paymentMethod.title = _paymentMethod.Corefy_TRY.title
                return _paymentMethod.Corefy_TRY.icon;
            }
            if (currency && currency.ISO === CurrencyType.KZT) {
                paymentMethod.title = _paymentMethod.Corefy_KZN.title
                return _paymentMethod.Corefy_KZN.icon;
            }
            if (currency && currency.ISO === CurrencyType.AZN) {
                paymentMethod.title = _paymentMethod.Corefy_AZN.title
                return _paymentMethod.Corefy_AZN.icon;
            }
        } else {
            return paymentMethod?.icon;
        }
    }

    useEffect(()=>{
        OperationsService.getSuccessDep()
    },[])

    return (
        <>
            <div className='history-item' onClick={onClick}>
                <div className='history-item__icon'>
                    <img alt='paymentMethod' src={getIcon()} />
                </div>

                <div className='history-item__text'>
                    <div className='history-item__text-title'>
                        {paymentMethod ? t(paymentMethod?.title) : ''}
                    </div>
                    <div className='history-item__text-type'>{t(DIRECTIONS_NAME[type])}</div>
                </div>

                <div className='history-item__amount'>
                    {currency && (
                        <div className='history-item__amount-value'>
                            {type === Direction.WITHDRAW ? '-' : ''}
                            {formatCurrencySign(amount, 2, currency)}
                        </div>
                    )}
                    <div className={statusClass}>{t(STATUS_NAME[status])}</div>
                </div>

                <SvgImage name={!isOpened ? 'ArrowDown' : 'ArrowUp'} />
            </div>
            <Collapsible open={isOpened} transitionTime={200} trigger=''>
                <div className='history-item__info'>
                    <div className='history-item__info_row'>
                        <span>{t('profile.balance.history.id')} Glory</span>
                        <span>{id}</span>
                    </div>
                    <div className='history-item__info_row'>
                        <span>
                            {t('profile.balance.history.id')}{' '}
                            {t('transaction.history.remoted')}
                        </span>
                        <span>{idTransaction}</span>
                    </div>
                    {accountNumber ? <div className="history-item__info_row">
                        <span>
                            {t('profile.balance.deposit.input.account.number')}{' '}
                        </span>
                        <span>{accountNumber}</span>
                    </div>: null}
                    {trxID ? <div className="history-item__info_row">
                        <span>
                            {t('profile.balance.history.id')}{' '}
                            {paymentMethod ? t(paymentMethod?.title) : ''}
                        </span>
                        <span>{trxID}</span>
                    </div>: null}
                    <div className='history-item__info_row'>
                        <span>{t('profile.balance.history.created.long')}</span>
                        <span>{format(created, 'HH:mm dd/MM')}</span>
                    </div>
                    <div className='history-item__info_row'>
                        <span>{t('profile.balance.history.confirmed.long')}</span>
                        {status === Status.SUCCESS || status === Status.FAIL ? (
                            <span>{format(confirmed, 'HH:mm dd/MM')}</span>
                        ) : (
                            <span>n/a</span>
                        )}
                    </div>
                </div>
            </Collapsible>
        </>
    );
}

export default HistoryItem;
