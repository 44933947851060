import { IMaskInput } from 'react-imask';

import CardFormService from '../../page/Profile/Balance/Withdrawal/Instapay/service/CardForm';
import Input, { InputProps, InputState } from './Input';

interface CodePhoneInputProps extends InputProps {
    code?: string;
    mask: string;
    pattern?: string;
    blocks?: any;
}
interface CodePhoneInputState extends InputState {}

class CodePhoneInput extends Input<CodePhoneInputProps, CodePhoneInputState> {
    getInputProps() {
        const inputProps = super.getInputProps();
        const { mask, pattern, blocks, code } = this.props;
        return Object.assign(inputProps, {
            mask,
            pattern,
            code,
            blocks,
        });
    }

    onClick() {}

    getInput() {
        const inputProps = this.getInputProps();
        return <IMaskInput {...inputProps} />;
    }

    componentDidMount(): void {
        const inputProps = this.getInputProps();
        if (inputProps.code) {
            console.log('inputProps.code', inputProps.code);
            CardFormService.phoneCode.value.next(inputProps.code);
            console.log('inputProps', inputProps);
        }
    }
}

export default CodePhoneInput;
