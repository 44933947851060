import { Route } from 'react-router-dom';

import RouteConfig from '../../appConstants/RouteConfig';
import Footer from '../../component/Footer/Footer';
import Header from '../../component/Header/Header';
import Tournament from './Tournament';
import TournamentId from './TournamentId';

import './TournamentLayout.scss';

function TournamentLayout() {
    return (
        <div className="tournament-layout">
            <Header />

            <Route path={RouteConfig.casino.tournament} exact component={Tournament} />
            <Route path={RouteConfig.casino.tournamentId} component={TournamentId} />

            <Footer />
        </div>
    );
}

export default TournamentLayout;
