import { translate as t } from 'service/Language';
import AcceptCheckbox from '../../Form/AcceptCheckbox/AcceptCheckbox';

import Form from '../../Form/Form';
import LoginButton from '../../Form/LoginButton/LoginButton';
import PromotionCheckbox from '../../Form/PromotionCheckbox/PromotionCheckbox';
import WheelRegistrationFormService from '../../Form/service/registration';
import SignUpButton from '../../Form/SignUpButton/SignUpButton';
import Ball from '../images/ball.png';

import './WheelRegistationCriket.scss';

function WheelRegistationCriket() {
    return (
        <div className='criket-registration'>
            <div className='criket-registration__top'>
                <div className='criket-registration__title'>
                    {t('wheel.bonus.get.125')} <span>{t('wheel.bonus.get.deposit')}</span>
                </div>
            </div>
            <form
                className='criket-registration__form'
                onSubmit={event => WheelRegistrationFormService.submit(event)}
            >
                <Form type='criket' />
                <SignUpButton type='criket' title='wheel.aviator.reg.button' />
                <LoginButton type='criket' />
                <div>
                    <AcceptCheckbox type='criket' />
                    <PromotionCheckbox type='criket' />
                </div>
            </form>
            <img className='criket-registration__ball' src={Ball} alt='Ball' />
        </div>
    );
}

export default WheelRegistationCriket;
