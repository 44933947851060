import { translate as t } from 'service/Language';
import PopupHelper from 'service/PopupHelper';

import './WheelCriket.scss';

type WheelCriketProps = {};

const WheelCriket = (props: WheelCriketProps) => {
    const {} = props;

    const handleClick = () => {
        PopupHelper.showWheelRegistration('criket');
    };

    return (
        <div className='WheelCriket'>
            <div className='WheelCriket__dark left'></div>
            <div className='WheelCriket__dark right'></div>
            <div className='WheelCriket__header'>
                <div className='WheelCriket__logo_glory'></div>
                <div className='WheelCriket__logo_glory'></div>
            </div>
            <div className='WheelCriket__logo'></div>

            <div className='WheelCriket__title'>{t('wheel.bonus.125')}</div>
            <div className='WheelCriket__subtitle'>{t('wheel.ipl.bets')}</div>

            <div className='WheelCriket__block'>
                <div className='WheelCriket__block_country'>
                    <div className='WheelCriket__block_flag'>
                        <div className='WheelCriket__block_cup'></div>
                    </div>
                    <div className='WheelCriket__block_date'>April 13 - June 5</div>
                </div>

                <div className='WheelCriket__block_signup'>{t('wheel.aviator.reg.title')}</div>
                <div className='WheelCriket__block_bonus'>
                    {t('wheel.bonus.get.125')} <span>{t('wheel.bonus.get.deposit')}</span>
                </div>
                <div className='WheelCriket__block_start'>
                    <button className='WheelCriket__block_btn' onClick={handleClick}>
                        {t('wheel.aviator.reg.button')}
                    </button>
                </div>
                <div className='WheelCriket__block_ball'></div>
            </div>

            <div className='WheelCriket__field'></div>
            <div className='WheelCriket__player_left'></div>
            <div className='WheelCriket__player_right'></div>
        </div>
    );
};

export default WheelCriket;
