import { isBefore } from 'date-fns';

import Currency, { unknownCurrency } from './Currency';
import { GameList } from './Game';

type Prize = number;

type Player = {
    name: string;
};

enum TournamentType {
    LOCAL,
    PROVIDER,
}

enum TournamentStatus {
    CREATED,
    ACTIVE,
    RESULT,
    CLOSED,
}

type ImageRecord = {
    main: string;
    locale: string;
    mainPreview: string;
    mobile: string;
    mobilePreview: string;
};

type TournamentRecord = {
    id: number;
    name: string;
    currencyId: number;
    type: number;
    status: number;
    startDate: string;
    endDate: string;
    rules: string;
    image: ImageRecord[];
    gameList: number[];
    prizePool: number[];
};

type TournamentRecordList = TournamentRecord[];

class TournamentImage {
    locale = '';

    main = '';

    mainPreview = '';

    mobile = '';

    mobilePreview = '';

    constructor(data: TournamentImage) {
        Object.assign(this, data);
    }
}

const defaultTournamentImage = new TournamentImage({
    locale: '',
    main: '',
    mainPreview: '',
    mobile: '',
    mobilePreview: '',
});

class TournamentStructure {
    id: number | null = null;

    currency: Currency = unknownCurrency; // id валюта

    name = ''; // название

    type: TournamentType = TournamentType.LOCAL; // тип турнира (внешний/внутренний)

    status: TournamentStatus = TournamentStatus.CREATED; // статус создан/активен/результаты/закрыт

    imageList: TournamentImage[] = []; // список картинок

    startDate: Date = new Date(); // дата начала

    endDate: Date = new Date(); // дата окончания

    rules = ''; // правила

    gameList: GameList = []; // список участвующих игр

    prizePool: Prize[] = []; // список призовых мест

    ladder: Player[] = []; // турнирная таблица

    constructor(data: TournamentStructure) {
        Object.assign(this, data);
    }
}

let tournamentId = -1;

class Tournament extends TournamentStructure {
    id: number;

    constructor(data: TournamentStructure) {
        super(data);

        this.id = data.id ?? tournamentId--;
    }

    getPrizeFund() {
        return this.prizePool.reduce((a, b) => a + b, 0);
    }

    isFinished() {
        const start = new Date();
        const end = this.endDate;

        return isBefore(end, start);
    }

    getLocalImageSet(lang: string): TournamentImage {
        return this.imageList.find((set) => set.locale === lang.toLowerCase()) || defaultTournamentImage;
    }
}

type TournamentList = Tournament[];

export { defaultTournamentImage };
export { TournamentImage, TournamentStatus, TournamentStructure, TournamentType };
export type { Player, TournamentList, TournamentRecord, TournamentRecordList };
export default Tournament;
