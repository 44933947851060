import { differenceInHours, differenceInMilliseconds, format } from "date-fns";
import { useEffect, useState } from "react";
import classNames from "classnames";

import "./Timer.scss";
import dayjs from "dayjs";

const INITIAL_STATE = "00:00:00";

const Timer = (props: { finish: Date | number | null; className?: string }) => {
  const { finish } = props;

  const [time, setTime] = useState(INITIAL_STATE);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = dayjs();
      const finishDayjs = dayjs(finish);

      const diffInSeconds = finishDayjs.unix() - now.unix();

      if (diffInSeconds <= 0) {
        setTime(INITIAL_STATE);
        clearInterval(interval);
        return;
      }

      const hours = Math.floor(diffInSeconds / 3600);
      const minutes = Math.floor((diffInSeconds % 3600) / 60);
      const seconds = diffInSeconds % 60;

      setTime(
        `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [finish]);

  const classTimer = classNames("bonus-widget__timer", props.className);

  return <span className={classTimer}>{time}</span>;
};

export default Timer;
