import { BehaviorSubject } from 'rxjs';

import AmountFormService from './AmountForm';
import { PaymentMethod } from '../../../PaymentMethod/types';
import PaymentService from '../../../../../../service/Payment';
import StatusFormPopupService, { StatusForm } from '../../DepositPopup/service/StatusForm';

import WellxPayInvoice from '../../../../../../service/model/payment/WellxPayInvoice';
import {WellxPayDepositStepTwo} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendWellxPayDepositStepTwo';
import TransactionFromService from '../../Paykassma/service/TransactionForm';


enum DepositState {
    AMOUNT,
    WAIT_INVOICE,
    INVOICE,
    WAIT,
    LINK,
    FAILED,
    SUCCESS,
}


enum WalletType {
    AGENT,
    PERSONAL,
    MERCHANT,
    NONE,
}

enum InvoiceState {
    INITIAL,
    TRANSACTION,
    WAIT,
    FAILED,
    SUCCESS,
}

const emptyInvoice = new WellxPayInvoice('');

class WellxPayDeposit {
    amount = 0;

    link = new BehaviorSubject('');

    state = new BehaviorSubject<DepositState>(DepositState.AMOUNT);

    walletType = new BehaviorSubject<WalletType>(WalletType.NONE);

    invoice: WellxPayInvoice = emptyInvoice;

    transaction = new BehaviorSubject<InvoiceState>(InvoiceState.INITIAL);


    request(paymentMethod: PaymentMethod, amount: number) {
        this.state.next(DepositState.WAIT);
        PaymentService.wellxpay.depositBKashP2C({
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
            amount,
        });
    }

    setAmount(amount: number) {
        this.amount = amount;
    }

    getAmount(): number {
        return this.amount;
    }

    reset() {
        this.amount = 0;
        this.link.next('');
        this.state.next(DepositState.AMOUNT);
        AmountFormService.reset();
    }

    onError() {
        this.state.next(DepositState.FAILED);
        StatusFormPopupService.status.next(StatusForm.FAILED);
    }

    onReceive(link: string) {
        const state = this.state.getValue();
        if (state === DepositState.WAIT) {
            this.link.next(link);
            window.open(link, '_self')
            StatusFormPopupService.status.next(StatusForm.SUCCESS);
        }
    }

    setWalletType(type: number) {
        if (type === 0) {
            this.walletType.next(WalletType.AGENT);
        } else if (type === 1) {
            this.walletType.next(WalletType.PERSONAL);
        } else if (type === 2) {
            this.walletType.next(WalletType.MERCHANT);
        } else {
            this.walletType.next(WalletType.AGENT);
        }
    }

    requestInvoice(paymentMethod: PaymentMethod, amount: number) {
        PaymentService.wellxpay.createInvoice({
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
            amount,
        });
    }


    depositStepTwo(payment: WellxPayDepositStepTwo) {
        PaymentService.wellxpay.depositStepTwo(payment);

        if (this.state.getValue() === DepositState.INVOICE) {
            this.transaction.next(InvoiceState.INITIAL);
            this.state.next(DepositState.WAIT);
        }
        if (this.transaction.getValue() === InvoiceState.TRANSACTION) {
            this.state.next(DepositState.AMOUNT);
            this.transaction.next(InvoiceState.WAIT);
        }
    }

    showInvoiceWait() {
        this.state.next(DepositState.WAIT_INVOICE);
    }

    resetInvoice() {
        this.invoice = emptyInvoice;
    }
    onReceiveInvoice(invoice: WellxPayInvoice) {
        this.invoice = invoice;
        const state = this.state.getValue();
        if (state === DepositState.WAIT_INVOICE) {
            this.state.next(DepositState.INVOICE);
        }
    }
    showPreviousForm() {
        this.state.next(DepositState.INVOICE);
    }
    showTransaction() {
        TransactionFromService.reset();
        this.state.next(DepositState.AMOUNT);
        this.transaction.next(InvoiceState.TRANSACTION);
    }
    back() {
        this.reset();
    }
}

const WellxPayDepositService = new WellxPayDeposit();

export { DepositState, WalletType, InvoiceState };
export default WellxPayDepositService;
