/* eslint-disable max-len */

import { Icon } from 'types';

function Lock({ color = '#3F4250', width = 16, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                d="M14.058 9.79937V5.98387H14.0569C13.9942 2.6736 11.3012 0 8.00002 0C4.69875 0 2.00578 2.6736 1.94312 5.98387L1.94227 9.79931C0.840843 9.99035 0 10.9588 0 12.1218V21.6426C0 22.9425 1.04993 24 2.34047 24H13.6595C14.9501 24 16 22.9425 16 21.6426V12.1218C16 10.9589 15.1593 9.99053 14.058 9.79937ZM3.11224 7.16255L3.11329 7.12504V6.20018C3.11265 6.16733 3.11224 6.1345 3.11224 6.10174C3.11224 6.07409 3.11282 6.04657 3.11329 6.01905V6.01649H3.11332C3.1587 3.34112 5.3332 1.17868 8.00002 1.17868C10.6142 1.17868 12.7556 3.25664 12.8818 5.85881H12.8878V7.16255V8.98231V9.76448H3.11224V7.16255ZM14.8298 21.6426C14.8298 22.2926 14.3048 22.8213 13.6595 22.8213H2.34047C1.6952 22.8213 1.17023 22.2926 1.17023 21.6426V12.1218C1.17023 11.4719 1.6952 10.9432 2.34047 10.9432H13.6595C14.3048 10.9432 14.8298 11.4719 14.8298 12.1218V21.6426Z"
                fill="#3F4250"
            />
            <circle cx="8" cy="15.5" r="1.5" fill={color} />
            <path d="M7 16H9V19C9 19.5523 8.55228 20 8 20C7.44772 20 7 19.5523 7 19V16Z" fill={color} />
        </svg>
    );
}

export default Lock;
