import { PaymentMethod } from '../../../PaymentMethod/types';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import Emanat from '../../Payes/eManat/Form';
import M10 from '../../Payes/M10/Form';
import SmartPay from '../../Payes/SmartPay/Form';

import './Form.scss';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;

    if (paymentMethod.id === ChannelType.PAYES_EMANAT) {
        return <Emanat paymentMethod={paymentMethod} />;
    }
    if (paymentMethod.id === ChannelType.PAYES_M10) {
        return <M10 paymentMethod={paymentMethod} />;
    }
    if (paymentMethod.id === ChannelType.PAYES_SMART_PAY) {
        return <SmartPay paymentMethod={paymentMethod} />;
    } else return null;
}

export default Form;
