import { translate as t } from 'service/Language';

import Input from '../../../../../../../component/Input/Input';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { formatCurrencySign } from '../../../../../../../utils/format';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import PaykassmaWithdrawService, { PaykassmaWithdrawState } from '../../../Paykassma/service/PaykassmaWithdraw';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import BankalfallahFormService, { WalletFormError } from '../service/BankalfallahForm';
import WithdrawalButtonBankalfallah from './WithdrawalButtonBankalfallah/WithdrawalButtonBankalfallah';
import WithdrawService from '../../../service/Withdrawal';

import './Bankalfallah.scss';
import Attention from '../../../../../../../assets/svg/attention.svg';

type BankalfallahProps = {
    paymentMethod: PaymentMethod;
    status: PaykassmaWithdrawState;
};
type BankalfallahState = {
    value: string;
    tip: WalletFormError;
    error: string;
};

class Bankalfallah extends RxComponent<BankalfallahProps, BankalfallahState> {
    state = {
        value: '',
        tip: WalletFormError.NOERROR,
        error: '',
    };

    handler = {
        onError: this.onError.bind(this),
        getValue: this.getValue.bind(this),
        onTip: this.onTip.bind(this),
    };

    onError(error: string) {
        this.setState({error});
    }

    onTip(tip: WalletFormError) {
        this.setState({tip});
    }

    getValue(value: string) {
        this.setState({value});
    }

    componentDidMount() {
        const {paymentMethod} = this.props;

        BankalfallahFormService.setPaymentMethod(paymentMethod);
        this.subscribe(BankalfallahFormService.error, {next: this.handler.onError});
        this.subscribe(BankalfallahFormService.tip, {next: this.handler.onTip});
        this.subscribe(BankalfallahFormService.amount.value, {next: this.handler.getValue});
    }

    render() {
        const {paymentMethod, status} = this.props;

        const {value, tip, error} = this.state;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        const fee = WithdrawService.fee.getValue();

        const isInit = status === PaykassmaWithdrawState.INIT;
        if (fee && isInit) {
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.HAS_FEE)
        }
        ;
        const amountFee = formatCurrencySign(paymentMethod.limit.withdraw.min * 1.25, 0, paymentMethod.currency);
        const allFeeText = `${amountFee} ${t('popup.payment.min.fee')}`;

        return (
            <div className="alfallah-withdrawal">
                <div className="alfallah-withdrawal-header">
                    <img src={paymentMethod.icon} alt={paymentMethod.slug}/>
                    <h2 className="easy-withdrawal-header__title">{t(paymentMethod.title)}</h2>
                </div>
                <form className="alfallah-withdrawal-form" onSubmit={(e) => BankalfallahFormService.onSubmit(e)}>
                    <div className="alfallah-withdrawal-form-block">
                        <Input
                            className="alfallah-withdrawal-form-block__input"
                            inputService={BankalfallahFormService.amount}
                            name="amount"
                            type="number"
                            hint={hint}
                        />
                        <span className="alfallah-withdrawal-form-block__currency">{paymentMethod.currency.sign}</span>
                        {!!error ? (
                            <div className="alfallah-withdrawal-form-block__error">
                                <img src={Attention} alt="attention"/>
                                <span
                                className="alfallah-withdrawal-form-block__error_text">
                                {allFeeText}
                            </span>
                            </div>
                        ) : null}
                    </div>

                    <div className="alfallah-withdrawal-form-block">
                        <MaskedInput
                            className="alfallah-withdrawal-form-block__input"
                            mask="00000000000000"
                            inputService={BankalfallahFormService.accountNumber}
                            name="account"
                        />
                        {tip === WalletFormError.ACCOUNT && (
                            <span className="alfallah-withdrawal-form-block__tip">
                                {t('popup.payment.bankalfallah.wallet.hint')}
                            </span>
                        )}
                    </div>
                    <div className="alfallah-withdrawal-form-block">
                        <Input
                            className="alfallah-withdrawal-form-block__input"
                            inputService={BankalfallahFormService.accountName}
                            name="name"
                        />
                    </div>
                    <div className="alfallah-withdrawal-form-block">
                        <Input
                            className="alfallah-withdrawal-form-block__input"
                            placeholder="PKXXXXXXXXXXXXXXXXXXXXXXXX"
                            inputService={BankalfallahFormService.iban}
                            name="iban"
                        />
                        {/*<MaskedInput*/}
                        {/*    className="wallet-withdrawal-block__wallet"*/}
                        {/*    placeholder="PKXXXXXXXXXXXXXXXXXXXXXXXX"*/}
                        {/*    mask="PK000000000000000000000000"*/}
                        {/*    inputService={BankalfallahFormService.iban}*/}
                        {/*    name="iban"*/}
                        {/*/>*/}
                        {/*{errorTip === WalletFormError.IBAN && (*/}
                        {/*    <span className="wallet-withdrawal-block__help">*/}
                        {/*        {t('profile.balance.withdraw.input.help.iban')}*/}
                        {/*    </span>*/}
                        {/*)}*/}
                    </div>
                    {(status === PaykassmaWithdrawState.HAS_FEE || status === PaykassmaWithdrawState.AWAIT) && fee ?
                        <FeeNotice amount={value} fee={fee}/> : null}
                    {status === PaykassmaWithdrawState.HAS_FEE || status === PaykassmaWithdrawState.AWAIT ? (
                        <>
                            <WithdrawalButtonBankalfallah
                                buttonTitle="profile.balance.withdraw.fee.agree"
                                variation={true}
                                paymentMethod={paymentMethod}
                                amount={value}
                            />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel"/>
                        </>
                    ) : (
                        <WithdrawalButtonBankalfallah buttonTitle="profile.balance.withdraw.button"/>
                    )}
                </form>
            </div>
        );
    }
}

export default Bankalfallah;
