import { translate as t } from 'service/Language';

import AmountFormService from '../../../../Paykassma/service/AmountForm';

import './DisabledAmount.scss';

type DisabledAmountProps = {
    hint: string;
};

function DisabledAmount({ hint }: DisabledAmountProps) {
    return (
        <div className="disable-amount">
            <span className="disable-amount__hint">{`${t('profile.balance.deposit.input.amount')} ${t(hint)}`}</span>
            <span className="disable-amount__value">{AmountFormService.amount.getValue()}</span>
        </div>
    );
}

export default DisabledAmount;
