/* eslint-disable max-len */

import { Icon } from 'types';

function TelegramIcon({ width = 100, height = 100 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" rx="15" fill="#F3F3F6" />
            <path
                opacity="0.5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.5118 65.1925L66.4606 74.0204C68.6597 75.2338 70.2468 74.6056 70.7946 71.9788L78.6388 35.0137C79.442 31.7938 77.4114 30.3334 75.3077 31.2885L42.8188 45C39.6747 46.2611 42.7652 49.7184 45.3179 50.5L43.3951 52.9056L40 54.9197L44.5556 69.9127C44.6713 70.2322 44.7496 70.5098 44.817 70.7489C45.0609 71.6137 45.1621 71.9727 46.3758 71.9727L46.3771 71.9711L46.377 71.9732C47.54 71.9732 48.0714 71.4558 48.7138 70.8304L48.7679 70.7778L54.5118 65.1925Z"
                fill="url(#paint0_linear_1:201)"
            />
            <path
                d="M41.5576 66.9731C40.0084 66.9731 40.2717 66.3881 39.7374 64.9131L35.1818 49.9201L70.2488 29.1166"
                fill="#69448F"
            />
            <path
                d="M41.5586 66.9728C42.754 66.9728 43.2822 66.426 43.9495 65.7773L50.3253 59.5776L42.3723 54.7818"
                fill="#624CB0"
            />
            <path
                d="M42.3713 54.7828L61.6422 69.0204C63.8413 70.2338 65.4284 69.6056 65.9762 66.9788L73.8205 30.0137C74.6236 26.7938 72.5931 25.3334 70.4893 26.2885L24.4279 44.0496C21.2838 45.3107 21.3021 47.0648 23.8548 47.8464L35.6752 51.5358L63.0407 34.2712C64.3326 33.4878 65.5182 33.909 64.5451 34.7726"
                fill="url(#paint1_linear_1:201)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1:201"
                    x1="40.8822"
                    y1="40.5739"
                    x2="73.9705"
                    y2="47.6022"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1:201"
                    x1="23.1818"
                    y1="35.5739"
                    x2="66.0361"
                    y2="47.7676"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default TelegramIcon;
