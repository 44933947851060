import { Direction } from '@ay_tsarbet/newbet-core/dist/types';
import classNames from 'classnames';
import { translate as t } from 'service/Language';

import TransactionHistoryService from '../service/TransactionHistoryService';

import './ListTab.scss';

type ListTabProps = {
    type: Direction;
};

const ListTab = (props: ListTabProps) => {
    const classDepositBtn = classNames('list-tab__btn', {
        active: props.type === Direction.REFILL,
    });
    const classWithdrawBtn = classNames('list-tab__btn', {
        active: props.type === Direction.WITHDRAW,
    });
    return (
        <div className="list-tab">
            <button className={classDepositBtn} onClick={() => TransactionHistoryService.toggle(Direction.REFILL)}>
                {t('profile.navigation.deposit')}
            </button>
            <button className={classWithdrawBtn} onClick={() => TransactionHistoryService.toggle(Direction.WITHDRAW)}>
                {t('profile.navigation.withdraw')}
            </button>
        </div>
    );
};

export default ListTab;
