/* eslint-disable max-len */

import { Icon } from 'types';

function ActiveSearch({ color = '#3F4250', width = 18, height = 18 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6902 11.5949C13.0012 11.5949 13.2995 11.7186 13.5192 11.9388L17.6514 16.0792C18.0848 16.5135 18.0844 17.2167 17.6506 17.6506C17.2167 18.0844 16.5135 18.0848 16.0792 17.6514L11.9388 13.5192C11.7186 13.2995 11.5949 13.0012 11.5949 12.6902V12.3433L11.3103 12.0481C10.1086 13.0811 8.54859 13.703 6.85152 13.703C3.06737 13.703 0 10.6357 0 6.85152C0 3.06737 3.06737 0 6.85152 0C10.6357 0 13.703 3.06737 13.703 6.85152C13.703 8.54859 13.0811 10.1086 12.0481 11.3103L12.3433 11.5949H12.6902ZM1.18635 6.85079C1.18635 9.9851 3.71646 12.5152 6.85077 12.5152C9.98508 12.5152 12.5152 9.9851 12.5152 6.85079C12.5152 3.71648 9.98508 1.18637 6.85077 1.18637C3.71646 1.18637 1.18635 3.71648 1.18635 6.85079Z"
                fill={color}
            />
        </svg>
    );
}

export default ActiveSearch;
