import RouteConfig from 'appConstants/RouteConfig';
import { useEffect } from 'react';
import { HistoryService } from 'service/HistoryService';
import { translate as t } from 'service/Language';
import PopupService from '../../../service/Popup';
import Success from './images/Success.svg';

import './SuccessPage.scss';

function SuccessPage() {
    useEffect(() => {
        return () => {
            PopupService.close();
        };
    }, []);
    function onClickHandler() {
        PopupService.close();
        HistoryService.history.push(RouteConfig.casino.root);
    }

    return (
        <div className="payment-success-page">
            <div className="payment-success-page-container">
                <img className="payment-success-page-container__img" src={Success} alt="Success" />
                <span className="payment-success-page-container__title">{t('profile.balance.deposit.transaction.accept')}</span>
                <button className="payment-success-page-container__btn" onClick={onClickHandler}>
                    {t('profile.balance.deposit.transaction.success.button')}
                </button>
            </div>
        </div>
    );
}

export default SuccessPage;
