import { translate as t } from '../../../../../service/Language';
import PopupService from '../../../../../service/Popup';
import ChangeEmail from '../../../Account/PersonalData/ChangeEmail/ChangeEmail';
import ChangePassword from '../../ChangePassword/ChangePassword';

import './ChangeButtonPopUp.scss';

type ChangeButtonPopUpProps = {
    type: 'email' | 'password';
};

function ChangeButtonPopUp({ type }: ChangeButtonPopUpProps) {
    const title = type === 'email' ? 'profile.account.personal.change.button' : 'profile.account.block.button';

    function onClick() {
        if (type === 'email') {
            PopupService.show(<ChangeEmail />);
        }
        if (type === 'password') {
            PopupService.show(<ChangePassword />);
        }
    }

    return (
        <button className="change-popup__btn" onClick={onClick} type="button">
            {t(title)}
        </button>
    );
}

export default ChangeButtonPopUp;
