import { PaymentMethod } from '../../PaymentMethod/types';

import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import PaycordUPI from './Form/Form';

import './Form.scss';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;

    if (paymentMethod.id === ChannelType.PAYCORD_UPI_INTENT || paymentMethod.id === ChannelType.PAYCORD_UPI_COLLECT || paymentMethod.id === ChannelType.PAYCORD_UPI_P2P) {
        return <PaycordUPI paymentMethod={paymentMethod} />;
    } else return null;
}

export default Form;
