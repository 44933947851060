import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { translate as t } from 'service/Language';

import RouteConfig from '../../../../../appConstants/RouteConfig';
import BalanceService from '../../../../../service/Balance';
import BonusService from '../../../../../service/Bonus';
import { unknownCurrency } from '../../../../../service/model/Currency';
import UI from '../../../../../service/UI';
import UserDataService from '../../../../../service/UserData';
import { formatCurrency, formatCurrencySign } from '../../../../../utils/format';
import useObservable from '../../../../../utils/useObservable';
import NotificationIcon from './NotificationIcon/NotificationIcon';

import './BalanceBlock.scss';

type BalanceBlockProps = {
    isSide?: boolean;
};

function BalanceBlock({ isSide }: BalanceBlockProps) {
    const balance = useObservable(BalanceService.balance, BalanceService.balance.getValue());
    const bonus = useObservable(BonusService.balance, BonusService.balance.getValue());
    const userData = useObservable(UserDataService.data, UserDataService.data.getValue());
    const currency = userData ? userData.currency : unknownCurrency;
    const location = useLocation();

    const sideClassName = classNames({
        'balance-block': true,
        'balance-block__side': isSide,
    });

    return (
        <div className={sideClassName}>
            <div className="balance-block__account">
                <div className="balance-block__account-title">{t('sidebar.auth.account')}</div>
                <div className="balance-block__account-balance">
                    {formatCurrencySign(balance, 2, currency)}
                    {isSide && <NotificationIcon />}
                </div>
            </div>
            <div className="balance-block__account">
                <div className="balance-block__account-title">{t('sidebar.auth.account.bonus')}</div>
                <div className="balance-block__account-bonus">{formatCurrency(bonus)}</div>
            </div>
            <div className="balance-block__deposit-wrapper" onClick={() => UI.slideMenu.next(false)}>
                <NavLink
                    className="balance-block__deposit-button"
                    to={{ hash: RouteConfig.hash.deposit, search: location.search }}
                >
                    {t('sidebar.auth.deposit')}
                </NavLink>
            </div>
        </div>
    );
}

export default BalanceBlock;
