import { formatCurrencySign } from 'utils/format';
import { createRef } from 'react';

import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import {PaymentMethod} from '../../../../PaymentMethod/types';
import AmountFormService, {CorefyInputTip, CorefyLettersTip} from '../../service/AmountForm';
import Submit from '../Button/Submit';
import SumSelector from '../SummSelector/SumSelector';
import {ChannelType, Currency as CurrencyType} from '@ay_tsarbet/newbet-core/dist/types';
import Attention from '../../../Apay/Attention/Attention';
import Input from '../../../../../../../component/Input/Input';
import { translate as t } from '../../../../../../../service/Language';
import AttentionIcon from '../../../../../../../assets/svg/attention.svg';
import Back from '../../../Payes/M10/Amount/image/back.png';

import './Amount.scss';


type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {
    tip: CorefyInputTip,
    errorName: string | null,
    errorSurname: string | null,
    errorLetters: CorefyLettersTip,
};

class Amount extends RxComponent<AmountProps, AmountState> {
    state = {
        tip: CorefyInputTip.NONE,
        errorLetters: CorefyLettersTip.NONE,
        errorName: null,
        errorSurname: null,
    };

    handler = {
        onTip: this.onTip.bind(this),
        onErrorLetters: this.ErrorLetters.bind(this),
        onNameError: this.onNameError.bind(this),
        onSurnameError: this.onSurnameError.bind(this),
        clickOutside: this.clickOutside.bind(this),
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    close() {
        this.setState({errorLetters: CorefyLettersTip.NONE});
    }

    clickOutside(event: PointerEvent) {
        if (this.ref.self.current && !this.ref.self.current.contains(event.target as Node)) {
            this.close();
        }
    }

    onTip(tip: CorefyInputTip) {
        this.setState({tip});
    }

    ErrorLetters(errorLetters: CorefyLettersTip) {
        this.setState({errorLetters});
    }

    onNameError(errorName: string | null) {
        this.setState({errorName});
    }

    onSurnameError(errorSurname: string | null) {
        this.setState({errorSurname});
    }

    componentDidMount() {
        const {paymentMethod} = this.props;

        AmountFormService.reset();
        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setValue(paymentMethod.defaultValue);
        this.subscribe(AmountFormService.tip, {next: this.handler.onTip});
        this.subscribe(AmountFormService.errorLetters, {next: this.handler.onErrorLetters});
        this.subscribe(AmountFormService.name.error, {next: this.handler.onNameError});
        this.subscribe(AmountFormService.surname.error, {next: this.handler.onSurnameError});
        document.addEventListener('pointerdown', this.handler.clickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('pointerdown', this.handler.clickOutside);
    }

    render() {
        const {paymentMethod, lock} = this.props;
        const {tip, errorLetters, errorName, errorSurname} = this.state;
        const isAZN = paymentMethod.currency.ISO === CurrencyType.AZN;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        return (
            <div className="deposit-amount-corefy">
                <form className="deposit-amount-corefy-form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    <div className="deposit-amount-corefy-form__container">
                        <MaskedInput
                            className="deposit-amount-corefy-form__container__input"
                            inputService={AmountFormService.amount}
                            name="amount"
                            mask="0000000"
                            hint={hint}
                            enabled={!lock}
                        />
                        <span
                            className="deposit-amount-corefy-form__container_currency">{paymentMethod.currency.sign}</span>
                    </div>

                    <div className="deposit-amount-corefy__sum">
                        <SumSelector paymentMethod={paymentMethod} disabled={lock}/>
                    </div>
                    {isAZN ?
                        <>
                            <div className="deposit-amount-corefy-form__container">
                                <Input
                                    className="deposit-amount-azarvisa-form__container__input"
                                    inputService={AmountFormService.name}
                                    name="name"
                                    enabled={!lock}
                                />
                                {tip === CorefyInputTip.NAME && !errorName && (
                                    <span className="deposit-amount-azarvisa-form__container_tip">
                                {t('popup.payment.name.hint')}
                            </span>
                                )}
                                {!!errorName && (
                                    <div className="deposit-amount-azarvisa-form__container__error">
                                        <img src={AttentionIcon} alt="attention"/>
                                        <span className="deposit-amount-azarvisa-form__container__error_text">
                        {t('popup.payment.name.hint')}
                                </span>
                                    </div>
                                )}
                                {errorLetters === CorefyLettersTip.NAME ?
                                    <div className="deposit-amount-azarvisa-form__container-letters"
                                         ref={this.ref.self}>
                                        <img className="deposit-amount-azarvisa-form__container-letters__img" src={Back}
                                             alt="back"/>
                                        <span
                                            className="deposit-amount-azarvisa-form__container-letters__text">{t('popup.payment.azer.error.letters')}</span>
                                    </div>
                                    : null}
                            </div>
                            <div className="deposit-amount-corefy-form__container">
                                <Input
                                    className="deposit-amount-azarvisa-form__container_input"
                                    inputService={AmountFormService.surname}
                                    name="name"
                                    enabled={!lock}
                                />
                                {tip === CorefyInputTip.SURNAME && !errorSurname && (
                                    <span className="deposit-amount-azarvisa-form__container_tip">
                                {t('popup.payment.name.hint')}
                            </span>
                                )}
                                {!!errorSurname && (
                                    <div className="deposit-amount-azarvisa-form__container__error">
                                        <img src={AttentionIcon} alt="attention"/>
                                        <span className="deposit-amount-azarvisa-form__container__error_text">
                        {t('popup.payment.name.hint')}
                                </span>
                                    </div>
                                )}
                                {errorLetters === CorefyLettersTip.SURNAME ?
                                    <div className="deposit-amount-azarvisa-form__container-letters"
                                         ref={this.ref.self}>
                                        <img className="deposit-amount-azarvisa-form__container-letters__img" src={Back}
                                             alt="back"/>
                                        <span
                                            className="deposit-amount-azarvisa-form__container-letters__text">{t('popup.payment.azer.error.letters')}</span>
                                    </div>
                                    : null}
                            </div>
                            <div className="deposit-amount-corefy-form__container">
                                <Attention title="popup.payment.corefy.info" type="info"/>
                            </div>
                        </>
                        : null}
                    {paymentMethod.id === ChannelType.KZT_COREFY ?
                        <div className="deposit-amount-corefy-form__container">
                            <Attention title="popup.payment.azarpay.attention" type="attention"/>
                        </div> : null}
                    <div className="deposit-amount-corefy__submit">
                        <Submit/>
                    </div>
                </form>
            </div>
        );
    }
}

export default Amount;
