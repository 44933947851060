/* eslint-disable max-len */

import { Icon } from 'types';

function QuestionIcon({ color = '#3F4250', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                d="M11.268 15.4286C10.9371 15.4286 10.6689 15.1727 10.6689 14.8571C10.6689 14.0317 10.802 13.3545 11.0683 12.8254C11.3346 12.2892 11.8301 11.7249 12.5549 11.1323C13.5608 10.321 14.1932 9.71076 14.452 9.30159C14.7183 8.89242 14.8514 8.39859 14.8514 7.82011C14.8514 7.10053 14.6073 6.54674 14.1192 6.15873C13.6385 5.76367 12.9432 5.56614 12.0335 5.56614C11.4492 5.56614 10.8797 5.63316 10.325 5.7672C9.98258 5.84558 9.60916 5.96563 9.20475 6.12735C8.76965 6.30134 8.25781 6.1292 8.06888 5.71685C7.89614 5.33986 8.05711 4.89608 8.45311 4.73298C9.63956 4.24433 10.87 4 12.1444 4C13.5571 4 14.6554 4.33157 15.4394 4.99471C16.2234 5.65785 16.6154 6.59259 16.6154 7.79894C16.6154 8.31393 16.5414 8.76896 16.3935 9.16402C16.253 9.55203 16.0422 9.9224 15.7611 10.2751C15.4801 10.6208 14.8736 11.1817 13.9417 11.9577C13.1947 12.5644 12.6991 13.0688 12.4551 13.4709C12.2184 13.873 12.1001 14.4092 12.1001 15.0794C12.1001 15.2722 11.9361 15.4286 11.734 15.4286H11.268ZM10.1253 18.5714C10.1253 17.612 10.5691 17.1323 11.4566 17.1323C11.8856 17.1323 12.2147 17.2557 12.444 17.5026C12.6807 17.7496 12.799 18.1058 12.799 18.5714C12.799 19.0229 12.6807 19.3757 12.444 19.6296C12.2073 19.8765 11.8782 20 11.4566 20C11.072 20 10.754 19.8907 10.5025 19.672C10.251 19.4462 10.1253 19.0794 10.1253 18.5714Z"
                fill={color}
            />
        </svg>
    );
}

export default QuestionIcon;
