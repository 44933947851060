import { InstapayDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendInstapayDepositRequest';
import { InstapayWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendInstapayWithdrawRequest';
import {
    InstapayDepositResponse,
    InstapayWithdrawCode,
    InstapayWithdrawResponse,
} from '@ay_tsarbet/newbet-core/dist/types';
import InstapayDepositService from 'page/Profile/Balance/Deposit/Instapay/service/InstapayDeposit';
import InstapayWithdrawService from 'page/Profile/Balance/Withdrawal/Instapay/service/InstapayWithdraw';
import { BehaviorSubject, Subject } from 'rxjs';

import InstapayInterface from '../interface/payment/Instapay';
import WSBet from '../WSBet';
import StatusFormPopupService, { StatusForm } from '../../page/Profile/Balance/Deposit/DepositPopup/service/StatusForm';

enum InstapayError {
    UNKNOWN,
    DEPOSIT,
    WITHDRAW,
}

class Instapay implements InstapayInterface {
    error = new Subject<InstapayError>();

    redirect = new BehaviorSubject<string | null>(null);

    withdrawResult = new Subject<void>();

    deposit(request: InstapayDepositRequest) {
        console.log('INSTAPAY | DEPOSIT REQUEST', request);
        WSBet.payment.instapay.deposit(request);
    }

    withdraw(withdraw: InstapayWithdrawRequest) {
        console.log('INSTAPAY | WITHDRAW REQUEST', withdraw);
        WSBet.payment.instapay.withdraw(withdraw);
    }

    onWithdraw(response: InstapayWithdrawResponse) {
        console.log('INSTAPAY | WITHDRAW RESPONSE', response);
        if (response.errorCode >= InstapayWithdrawCode.success) {
            this.withdrawResult.next();
            StatusFormPopupService.status.next(StatusForm.SUCCESS);
        } else {
            this.error.next(InstapayError.WITHDRAW);
            StatusFormPopupService.status.next(StatusForm.FAILED);
        }
    }

    onReceiveDeposit(data: InstapayDepositResponse) {
        console.log('INSTAPAY | DEPOSIT RESPONSE', data);
        if (!data.errorCode) {
            InstapayDepositService.onReceive(data.message);
            StatusFormPopupService.status.next(StatusForm.SUCCESS);
        } else {
            InstapayDepositService.onError();
            StatusFormPopupService.status.next(StatusForm.FAILED);
        }
    }
}

export { InstapayError };
export default Instapay;
