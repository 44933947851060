import RegistrationResponseStatus from '@ay_tsarbet/newbet-core/dist/models/registrationResponseStatus';
import { Step10Data } from '@ay_tsarbet/newbet-core/dist/types';
import { Subject } from 'rxjs';

import GoogleService from './Google';
import RegistrationInterface from './interface/Registration';
import Currency, { unknownCurrency } from './model/Currency';
import UserTrackService from './UserTrack';
import WSBet from './WSBet';

enum RegistrationState {
    NONE,
    AWAIT,
}

enum RegistrationError {
    NONE,
    UNKNOWN,
    ACCOUNT_USED,
    PHONE_USED,
    EMAIL_USED,
    PHONE_NOTFOUND,
    TOKEN_EXPIRED,
    RESET_ERROR,
    PASSWORD_CHANGED,
    PASSWORD_NOT_VALID,
}
enum AccountError {
    NONE,
    UNKNOWN,
}

export { RegistrationError };

type RegistrationData = {
    email: string;
    password: string;
    currency: Currency;
    promo: boolean;
};

const fakeData: RegistrationData = {
    email: 'unknown@undefined.nan',
    password: 'password',
    currency: unknownCurrency,
    promo: true,
};

class Registration implements RegistrationInterface {
    login = '';

    state = RegistrationState.NONE;

    error = new Subject<RegistrationError>();

    complete = new Subject<Step10Data>();

    lastData: RegistrationData = fakeData;

    byEmailAndPassword(email: string, password: string, currency: Currency, promo: boolean) {
        const state = this.state;
        if (state === RegistrationState.NONE) {
            this.login = email;
            this.state = RegistrationState.AWAIT;
            this.lastData = {
                email,
                password,
                currency,
                promo,
            };
            WSBet.registration.byEmailAndPassword(email, password, currency, promo);
        }
    }

    onReceive(data: Step10Data) {
        switch (data.status) {
            case RegistrationResponseStatus.success:
                const trackData = UserTrackService.get();
                const url = document.location.host + document.location.pathname;
                GoogleService.registrationComplete(
                    this.lastData.email,
                    data.clientId,
                    this.lastData.currency.name,
                    url,
                    trackData
                );
                this.complete.next(data);
                break;
            case RegistrationResponseStatus.alreadyUsed:
                this.error.next(RegistrationError.ACCOUNT_USED);
                break;
            case RegistrationResponseStatus.phoneAlreadyRegistered:
                this.error.next(RegistrationError.PHONE_USED);
                break;
            case RegistrationResponseStatus.emailAlreadyRegistered:
                this.error.next(RegistrationError.EMAIL_USED);
                break;
            case RegistrationResponseStatus.passwordChangeSuccess:
                this.error.next(RegistrationError.EMAIL_USED);
                break;
            case RegistrationResponseStatus.passwordChangeSuccess:
                this.error.next(RegistrationError.PASSWORD_CHANGED);
                break;
            case RegistrationResponseStatus.newPasswordNotValid:
                this.error.next(RegistrationError.PASSWORD_NOT_VALID);
                break;
            default:
                this.error.next(RegistrationError.UNKNOWN);
        }

        this.state = RegistrationState.NONE;
    }
}

const RegistrationService = new Registration();

export default RegistrationService;
