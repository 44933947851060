import { LoginByLoginPasswordData } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendLoginByLoginPassword';
import { LoginByTokenData } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendLoginByToken';

import AuthService from '../Auth';
import AuthInterface from '../interface/Auth';
import LanguageService from '../Language';

interface AuthHandlerInterface {
    byPassword: (data: LoginByLoginPasswordData) => void;
    byToken: (data: LoginByTokenData) => void;
    betLogin: () => void;
    logout: () => void;
    sendNewYear: () => void;
    sendConfirmEmail: () => void;
}

class AuthProvider implements AuthInterface {
    handler: AuthHandlerInterface;

    constructor(handler: AuthHandlerInterface) {
        this.handler = handler;
    }

    login(login: string, password: string) {
        const language = LanguageService.current.getValue();

        const data = {
            login,
            password,
            domain: window.location.origin,
            timeZone: new Date().getTimezoneOffset() / -60,
            language: language.title,
            userAgent: navigator.userAgent,
        };

        this.handler.byPassword(data);
    }

    loginByToken(accessToken: string, refreshToken: string) {
        const language = LanguageService.current.getValue();

        const data = {
            accessToken,
            refreshToken,
            domain: window.location.origin,
            timeZone: new Date().getTimezoneOffset() / -60,
            language: language.title,
            userAgent: navigator.userAgent,
        };

        this.handler.byToken(data);
    }

    logout() {
        setTimeout(() => AuthService.onReceive(null), 200);
        this.handler.logout();
    }

    autoLogin(): void {}

    betLogin() {
        this.handler.betLogin();
    }
    sendNewYear() {
        this.handler.sendNewYear();
    }
    sendConfirmEmail() {
        this.handler.sendConfirmEmail();
    }
}

export default AuthProvider;
