import Input, { InputSize } from 'component/Input/Input';

import RxComponent from '../../RxComponent/RxComponent';
import AuthFormService from '../service/AuthForm';
import EmailInputService from '../service/EmailInput';

import './Email.scss';

type EmailProps = {};
type EmailState = {};

class Email extends RxComponent<EmailProps, EmailState> {
    componentDidMount() {
        EmailInputService.error.next(null);

        this.subscribe(EmailInputService.valid, AuthFormService.valid);
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        AuthFormService.changed.next(true);
        EmailInputService.onChange(event, false);
    }

    render() {
        return (
            <Input
                className="login-email"
                name="email"
                type="email"
                inputService={EmailInputService}
                size={InputSize.small}
                onChange={this.handleChange}
            />
        );
    }
}

export default Email;
