/* eslint-disable max-len */

import { Icon } from 'types';

function Live({ color = '#FC4665', width = 41, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.900024 18V9.80974C0.900024 9.3073 1.30733 8.89999 1.80977 8.89999C2.31221 8.89999 2.71952 9.3073 2.71952 9.80974V17.4978H6.11285C6.55528 17.4978 6.91394 17.8565 6.91394 18.2989C6.91394 18.7413 6.55528 19.1 6.11285 19.1H2.00002C1.39251 19.1 0.900024 18.6075 0.900024 18ZM8.42153 18.1902V9.80974C8.42153 9.3073 8.82883 8.89999 9.33127 8.89999C9.83371 8.89999 10.241 9.3073 10.241 9.80974V18.1902C10.241 18.6927 9.83371 19.1 9.33127 19.1C8.82883 19.1 8.42153 18.6927 8.42153 18.1902ZM26.3058 19.1H22.3962C21.7887 19.1 21.2962 18.6075 21.2962 18V9.99999C21.2962 9.39248 21.7887 8.89999 22.3962 8.89999H26.3092C26.746 8.89999 27.1 9.25406 27.1 9.69083C27.1 10.1276 26.746 10.4817 26.3092 10.4817H23.1157V13.004H26.0653C26.4983 13.004 26.8493 13.355 26.8493 13.788C26.8493 14.2209 26.4983 14.5719 26.0653 14.5719H23.1157V17.5115H26.3058C26.7444 17.5115 27.1 17.8671 27.1 18.3057C27.1 18.7444 26.7444 19.1 26.3058 19.1Z"
                fill="#9FA0A8"
            />
            <path
                d="M16.9467 18.3605L19.8187 10.0824C20.0194 9.50382 19.5898 8.89999 18.9774 8.89999C18.5942 8.89999 18.254 9.14507 18.1327 9.50849L16.2466 15.1577C16.1685 15.3804 16.0684 15.7206 15.9464 16.1766C15.8777 16.4334 15.8189 16.6734 15.77 16.8964C15.7323 16.7346 15.6862 16.5495 15.6316 16.3415C15.5094 15.8709 15.3935 15.4849 15.2837 15.1845L13.3959 9.50545C13.2757 9.14394 12.9376 8.89999 12.5566 8.89999C11.9489 8.89999 11.5222 9.49887 11.7207 10.0733L14.5842 18.3593C14.7374 18.8026 15.1548 19.1 15.6239 19.1H15.9075C16.376 19.1 16.7931 18.8032 16.9467 18.3605Z"
                fill="#9FA0A8"
            />
            <circle opacity="0.4" cx="36.5" cy="14.5" r="4.5" fill={color} />
            <circle cx="36.5" cy="14.5" r="2.5" fill={color} />
        </svg>
    );
}

export default Live;
