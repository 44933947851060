import { translate as t } from 'service/Language';

import { PromoType } from '../WelcomeBonus';

import './WelcomeItem.scss';

type WelcomeItemProps = {
    promo: PromoType;
};

function WelcomeItem({ promo }: WelcomeItemProps) {
    return (
        <div className="bonus-item">
            <div className="bonus-item__title">{promo.title}</div>
            <img className="bonus-item__img" src={promo.img} />
            <div className="bonus-item__description">{t(`${promo.description}`)}</div>
        </div>
    );
}

export default WelcomeItem;
