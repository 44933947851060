import { Redirect, Route, Switch } from 'react-router-dom';

import RouteConfig from '../../appConstants/RouteConfig';
import Footer from '../../component/Footer/Footer';
import Header from '../../component/Header/Header';
import BalanceBlock from '../../component/Header/Menu/List/BalanceBlock/BalanceBlock';
import Account from './Account/Account';
import PersonalData from './Account/PersonalData/PersonalData';
import History from './Balance/History/History';
import Bonus from './Bonus/Bonus';
import Exit from './Exit/Exit';
import GameHistory from './GameHistory/GameHistory';
import Navigation from './Navigation/Navigation';
import NotificationList from './Notification/NotificationList/NotificationList';
import Safety from './Settings/Safety';
import Pin from '../../component/Header/Menu/List/Pin/Pin';
import useObservable from '../../utils/useObservable';
import AuthService from '../../service/Auth';

import './Profile.scss';

const rc = RouteConfig;
const routeNotification = `${rc.profile.root}${rc.profile.notification.root}`;

const profileRouteList = {
    profile: rc.profile.root,
    balanceHistory: rc.profile.root + rc.profile.balance.root + rc.profile.balance.history,
    bonus: rc.profile.root + rc.profile.bonus,
    deposit: rc.profile.root + rc.profile.balance.root + rc.profile.balance.deposit,
    withdraw: rc.profile.root + rc.profile.balance.root + rc.profile.balance.withdrawal,
    account: rc.profile.root + rc.profile.account.root,
    personal: rc.profile.root + rc.profile.account.root + rc.profile.account.personal,
    safety: rc.profile.root + rc.profile.account.root + rc.profile.account.safety,
    gameHistory: rc.profile.root + rc.profile.history,
    notification: rc.profile.root + rc.profile.notification.root,
    notificationInfo: rc.profile.root + rc.profile.notification.root + rc.profile.notification.info,
    notificationAdds: rc.profile.root + rc.profile.notification.root + rc.profile.notification.adds,
    notificationNews: rc.profile.root + rc.profile.notification.root + rc.profile.notification.news,
};

export const titleMap = new Map<string, string>([
    [profileRouteList.profile, 'profile.header'],
    [profileRouteList.deposit, 'profile.balance.deposit.title'],
    [profileRouteList.withdraw, 'profile.balance.withdraw.title'],
    [profileRouteList.balanceHistory, 'profile.balance.history.title.mobile'],
    [profileRouteList.account, 'profile.account.title'],
    [profileRouteList.personal, 'profile.account.personal'],
    [profileRouteList.safety, 'profile.account.safety'],
    [profileRouteList.bonus, 'profile.balance.header.bonus'],
]);

function DesktopProfile() {
    const isConfirm = useObservable(AuthService.confirmEmail, AuthService.confirmEmail.getValue());
    return (
        <div className="profile-page__content">
            <div className="profile-page__nav">
                <BalanceBlock />
                {!isConfirm ? <Pin />: null}
                <Navigation />
                <Exit />
            </div>
            <div className="profile-page__main">
                <div className="profile-page__center">
                    <div className="profile-page__switch">
                        <Switch>
                            <Route path={profileRouteList.bonus} component={Bonus} />
                            <Route path={routeNotification} component={NotificationList} />
                            <Route path={profileRouteList.balanceHistory} component={History} />
                            <Route path={profileRouteList.account} component={Account} exact />

                            <Route path={profileRouteList.personal} component={PersonalData} />
                            <Route path={profileRouteList.safety} component={Safety} />
                            <Route path={profileRouteList.gameHistory} component={GameHistory} />
                            <Redirect from="*" to={RouteConfig.notFound} />
                        </Switch>
                    </div>
                    <div className="profile-page__bonus" />
                </div>
                <div className="profile-page__footer">
                    <Footer />
                </div>
            </div>
        </div>
    );
}

function ProfilePage() {
    return (
        <div className="profile-page">
            <Header />
            <DesktopProfile />
        </div>
    );
}

export { profileRouteList };
export default ProfilePage;
