import FavoriteListInterface from '../interface/FavoriteListInterface';

class FavoriteGameProvider {
    handler: FavoriteListInterface;

    constructor(handler: FavoriteListInterface) {
        this.handler = handler;
    }

    sent(favorite: number[]) {
        const idList = JSON.stringify(favorite);
        this.handler.sendFavoriteList(idList);
    }
}

export default FavoriteGameProvider;
