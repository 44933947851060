import { SlotegratorGameMode } from '@ay_tsarbet/newbet-core/dist/connection/actions/actionEnums';
import { ProviderListTopGameList, SlotegratorEntities } from '@ay_tsarbet/newbet-core/dist/types';
import enumGames from 'enums/games';
import { BehaviorSubject, combineLatestWith, map, Observable, tap } from 'rxjs';

import GameHelper from './GameHelper';
import GeoDataService from './GeoData';
import GoogleService from './Google';
import Currency from './model/Currency';
import Game, { GameList, unknownGame } from './model/Game';
import GameCategory, { gameCategory, gameCategoryMap } from './model/GameCategory';
import GameProvider, { unknownProvider } from './model/GameProvider';
import BalanceService from './Balance';
import { HistoryService } from './HistoryService';
import RouteConfig from '../appConstants/RouteConfig';

export const MIN_SEARCH_QUERY_LENGTH = 3;

interface SlotegratorActions {
    getSlotGameUrl: (gameId: number, gameMode: SlotegratorGameMode, lang: string, returnUrl: string) => void;
    getGeoProvider: () => void;
    getCurrencyProvider: (currencyId: number) => void;
}

const EmptySlotegratorActions: SlotegratorActions = {
    getSlotGameUrl: () => {},
    getGeoProvider: () => {},
    getCurrencyProvider: (currencyId: number) => {},
};

type AllowFilter = GameProvider[];

type GameFilter = {
    category: GameCategory | null;
    provider: GameProvider | null;
};

class Games {
    public games = new BehaviorSubject<GameList>([]);

    public providers = new BehaviorSubject<GameProvider[]>([]);

    public currentGameUrl = new BehaviorSubject<string>('');

    public searchResultGames = new BehaviorSubject<GameList>([]);

    public searchResultProviders = new BehaviorSubject<GameProvider[]>([]);

    private actions: SlotegratorActions = EmptySlotegratorActions;

    public loadingGame = new BehaviorSubject<Game | null>(null);

    public loadedGame = new BehaviorSubject<boolean>(false);

    public loadingGameInFrame = new BehaviorSubject<Game>(unknownGame);

    public searchText = new BehaviorSubject<string>('');

    public topGameList = new BehaviorSubject<GameList>([]);

    public cache = {
        provider: new Map<number, GameProvider>(),
        allowedProvider: new Array<GameProvider>(),
        game: new Map<number, Game>(),
    };

    public filter = new BehaviorSubject<GameFilter>({
        category: null,
        provider: null,
    });

    private premuteFilter = this.filter.pipe(map(Games.mutateFilter));

    public allowFilter = new BehaviorSubject<AllowFilter>([]);

    public allowedGames = this.games.pipe(combineLatestWith(this.allowFilter)).pipe(map(Games.applyAllowFilter));

    handler = {
        cacheAllowProvider: this.cacheAllowProvider.bind(this),
    };

    public allowedProviders: Observable<GameProvider[]> = this.providers
        .pipe(combineLatestWith(this.allowFilter))
        .pipe(map(Games.applyAllowProviderFilter))
        .pipe(tap(this.handler.cacheAllowProvider));

    public filteredGames = this.allowedGames.pipe(combineLatestWith(this.premuteFilter)).pipe(map(Games.applyFilter));

    updateData(entities: SlotegratorEntities) {
        if (entities.games.length === 0) {
            return;
        }

        const unknownCategory = new GameCategory(-100, 'unknown.game.category', 'unknown', 'CasinoSlots');

        const providerList = entities.providers.map((record) => GameProvider.fromRecord(record));
        const providerMap = new Map<number, GameProvider>(providerList.map((provider) => [provider.id, provider]));
        const gameList: GameList = [];

        entities.games.forEach((gameRecord) => {
            let game = this.cache.game.get(gameRecord.id);
            if (game) {
                game.update(gameRecord);
            } else {
                game = Game.fromRecord(gameRecord);
                this.cache.game.set(game.id, game);
            }

            const provider = providerMap.get(gameRecord.providerId) || unknownProvider;
            const category = gameCategoryMap.get(gameRecord.groupId) || unknownCategory;

            provider.addGame(game);
            category.addGame(game);
            gameList.push(game);
            gameCategory.All.addGame(game);
        });

        this.cache.provider = providerMap;

        entities.topGameIds.forEach((id) => {
            const game = this.findGame(id);
            if (game.isReal()) {
                gameCategory.Popular.addGame(game);
            }
        });

        this.games.next(gameList);

        this.providers.next(providerList);
    }

    setSearchText(text: string) {
        this.searchText.next(text);

        if (text.length < MIN_SEARCH_QUERY_LENGTH) {
            this.searchResultGames.next([]);
            this.searchResultProviders.next([]);
        } else {
            this.searchResultGames.next(
                this.games.value.filter((game) => {
                    const nameMatch = game.name.toLowerCase().includes(text.toLowerCase());
                    const allow = this.isAllowed(game.provider);
                    const assert = [nameMatch, allow];
                    return !assert.includes(false);
                })
            );
            this.searchResultProviders.next(
                this.providers.value.filter((provider) => {
                    const nameMatch = provider.name.toLowerCase().includes(text.toLowerCase());
                    const allow = this.isAllowed(provider);
                    const assert = [nameMatch, allow];

                    return !assert.includes(false);
                })
            );
        }
    }

    cacheAllowProvider(allowedProviderList: GameProvider[]) {
        this.cache.allowedProvider = allowedProviderList;
    }

    static mutateFilter(filter: GameFilter): GameFilter {
        if (filter.category === gameCategory.Popular && filter.provider) {
            filter.category = null;
        }
        console.log('filter', filter);
        return filter;
    }

    static applyFilter([gameList, filter]: [GameList, GameFilter]): GameList {
        let list = gameList;
        if (filter.category) {
            list = filter.category.gameList;
        }
        if (filter.provider) {
            list = list.filter((game) => game.provider === filter.provider);
        }

        return list;
    }

    static applyAllowFilter([gameList, allowFilter]: [GameList, AllowFilter]): GameList {
        return allowFilter.length
            ? gameList.filter((game) => game.provider && allowFilter.includes(game.provider))
            : gameList;
    }

    static applyAllowProviderFilter([providerList, allowFilter]: [GameProvider[], AllowFilter]): GameProvider[] {
        return allowFilter.length ? providerList.filter((provider) => allowFilter.includes(provider)) : providerList;
    }

    setActions(actions: SlotegratorActions) {
        this.actions = actions;
    }

    loadGame(game: Game, mode: SlotegratorGameMode, lang: string, returnUrl: string) {
        if (this.loadingGame.getValue() === game) {
            return;
        }
        lang = lang.toLowerCase();
        this.loadingGame.next(game);
        this.loadingGameInFrame.next(game);
        this.actions.getSlotGameUrl(game.id, mode, lang, returnUrl);
    }

    resetLoadingGame() {
        this.loadingGame.next(null);
    }

    findProvider(id: number): GameProvider | null {
        return this.cache.provider.get(id) || null;
    }

    findProviderByName(name: string): GameProvider | null {
        let id = null;
        this.cache.provider.forEach((provider) => {
            if (provider.name === name) {
                id = provider.id;
            }
        });
        return id ? this.cache.provider.get(id) || null : null;
    }

    findGame(id: number): Game {
        return this.cache.game.get(id) || unknownGame;
    }

    setFilter(filter: GameFilter) {
        const prevFilter = this.filter.getValue();
        if (prevFilter.category !== filter.category || prevFilter.provider !== filter.provider) {
            this.filter.next(filter);
        }
    }

    setProviderFilter(provider: GameProvider | null) {
        const filter = this.filter.getValue();

        this.setFilter({
            category: filter.category,
            provider: provider,
        });
    }

    resetProviderFilter(provider: GameProvider | null) {
        this.setFilter({
            category: null,
            provider: provider,
        });
    }

    setTopGamesByGeo(data: ProviderListTopGameList) {
        const geo = GeoDataService.data.getValue();
        if (geo && data) {
            if (geo.country.id === 50 || geo.country.id === 860 || geo.country.id === 792 || geo.country.id === 586) {
                const gameList: Game[] = [];
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                data.topGameList.forEach((topGame) => {
                    const game = this.findGame(topGame.id);
                    if (game) {
                        gameList.push(game);
                    }
                });
                this.topGameList.next(gameList);
            }
        }
    }

    updateAllowFilter(allowFilter: AllowFilter) {
        this.allowFilter.next(allowFilter);
    }

    setCurrency(currency: Currency) {
        this.actions.getCurrencyProvider(currency.ISO);
    }

    isAllowed(provider: GameProvider) {
        const list = this.allowFilter.getValue();

        return list.length ? list.includes(provider) : true;
    }

    requestGeoProvider() {
        this.actions.getGeoProvider();
    }

    setGameUrl(url: string) {
        this.currentGameUrl.next(url);
    }

    setGameLoaded() {
        this.loadedGame.next(true);
    }
    resetGameLoaded() {
        this.loadedGame.next(false);
    }

    loadAviatorGame() {
        const game = this.findGame(enumGames.AVIATOR);
        const balance = BalanceService.balance.getValue();
        if ( game && balance > 0 ) {
            GameHelper.loadGame(game, SlotegratorGameMode.REAL, null);
        } else {
            HistoryService.setHash(RouteConfig.hash.deposit);
        }
    }

    loadCrazyGame() {
        const game = this.findGame(enumGames.CRAZY);
        if (game) {
            GameHelper.loadGame(game, SlotegratorGameMode.REAL, null);
            GoogleService.openCrazy();
        }
    }
}

const GamesService = new Games();

export { Games };
export type { AllowFilter, GameFilter };
export default GamesService;
