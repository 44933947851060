import { useEffect } from 'react';

import { useIsDesktop } from '../../utils/useBreakpoints';
import ProviderFilterService from './service/ProviderFilter';

function PlatformHelper() {
    const isDesktop = useIsDesktop();

    useEffect(() => {
        ProviderFilterService.isDesktop.next(isDesktop);
    }, [isDesktop]);

    return null;
}

export default PlatformHelper;
