import RxComponent from 'component/RxComponent/RxComponent';
import { translate as t } from 'service/Language';
import { formatCurrencySign } from 'utils/format';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import CardFormService from '../../service/CardForm';
import InstapayWithdrawService, { InstapayWithdrawState } from '../../service/InstapayWithdraw';
import WithdrawService from '../../../service/Withdrawal';
import WithdrawalButton from '../../WithdrawalButton/WithdrawalButton';
import CepBank from './CepBank/CepBank';
import HavaleExpress from './HavaleExpress/HavaleExpress';
import HavaleVIP from './HavaleVIP/HavaleVIP';
import Mefete from './Mefete/Mefete';
import Papara from './Papara/Papara';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';

import './Card.scss';

type CardProps = {
    paymentMethod: PaymentMethod;
};
type CardState = {
    value: string;
    error: string;
};

class Card extends RxComponent<CardProps, CardState> {
    state = {
        value: '',
        error: '',
    };

    handler = {
        getValue: this.getValue.bind(this),
        onError: this.onError.bind(this),
    };

    componentDidMount() {
        const { paymentMethod } = this.props;

        CardFormService.setPaymentMethod(paymentMethod);
        this.subscribe(CardFormService.amount.value, { next: this.handler.getValue });
        this.subscribe(CardFormService.error, { next: this.handler.onError });
    }

    getValue(value: string) {
        this.setState({ value });
    };

    onError(error: string) {
        this.setState({ error });
    }

    render() {
        const { paymentMethod } = this.props;
        const { value, error } = this.state;

        const status = InstapayWithdrawService.state.getValue();

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        const fee = WithdrawService.fee.getValue();

        const isInit = status === InstapayWithdrawState.CARD;
        if (fee && isInit) {
            InstapayWithdrawService.state.next(InstapayWithdrawState.HAS_FEE)
        };
        const amountFee = formatCurrencySign(paymentMethod.limit.withdraw.min * 1.25, 0, paymentMethod.currency);
        const allFeeText = `${amountFee} ${t('popup.payment.min.fee')}`;

        return (
            <div className="card-withdrawal">
                <div className="card-withdrawal__header">
                    <img src={paymentMethod.icon} alt={paymentMethod.slug} />
                    <h2 className="card-withdrawal__title">{t(paymentMethod.title)}</h2>
                </div>
                <form className="card-withdrawal-form" onSubmit={(e) => CardFormService.onSubmit(e)}>
                    {paymentMethod.id === ChannelType.TRY_PAPARA && <Papara paymentMethod={paymentMethod} hint={hint} fee={allFeeText} error={error} />}
                    {paymentMethod.id === ChannelType.TRY_BANK && (
                        <HavaleVIP paymentMethod={paymentMethod} hint={hint} fee={allFeeText} error={error} />
                    )}
                    {paymentMethod.id === ChannelType.TRY_MFT && <Mefete paymentMethod={paymentMethod} hint={hint} fee={allFeeText} error={error} />}
                    {paymentMethod.id === ChannelType.TRY_FAST_BANK && (
                        <HavaleExpress paymentMethod={paymentMethod} hint={hint}  fee={allFeeText} error={error} />
                    )}
                    {paymentMethod.id === ChannelType.TRY_CEP_BANK && (
                        <CepBank paymentMethod={paymentMethod} hint={hint} fee={allFeeText} error={error} />
                    )}
                    {(status === InstapayWithdrawState.HAS_FEE || status === InstapayWithdrawState.AWAIT) && fee ? <FeeNotice amount={value} fee={fee} />: null}
                    {status === InstapayWithdrawState.HAS_FEE || status === InstapayWithdrawState.AWAIT ? (
                        <>
                            <WithdrawalButton
                                buttonTitle="profile.balance.withdraw.fee.agree"
                                variation={true}
                                amount={value}
                                minLimit={paymentMethod.limit.withdraw.min}
                            />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel" />
                        </>
                    ) : (
                        <WithdrawalButton
                            buttonTitle="profile.balance.withdraw.button"
                            minLimit={paymentMethod.limit.withdraw.min}
                        />
                    )}
                </form>
            </div>
        );
    }
}

export default Card;
