import classNames from 'classnames';
import { translate as t } from 'service/Language';

import useObservable from '../../../../../../../utils/useObservable';
import PaykassmaDepositService, { DepositState } from '../../../Paykassma/service/PaykassmaDeposit';
import Spinner from '../../Bankalfallah/Button/image/Spinner.svg';
import AmountFormService from '../service/AmountForm';

import './Submit.scss';

function Submit() {
    const valid = useObservable<boolean>(AmountFormService.valid, false);
    const status = useObservable(PaykassmaDepositService.state, PaykassmaDepositService.state.getValue());

    const _className = classNames({
        'deposit-submit': true,
        'deposit-submit__active': valid,
    });

    return (
        <button type="submit" className={_className} disabled={!valid}>
            {status === DepositState.WAITINVOICE || status === DepositState.WAIT ? (
                <img className="deposit-submit__wait" src={Spinner} alt="" />
            ) : (
                t('profile.balance.deposit.button.next')
            )}
        </button>
    );
}

export default Submit;
