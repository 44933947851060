import { translate as t } from 'service/Language';

import { SupportItemModel } from './types';

import './SupportItem.scss';

type SupportItemProps = {
    supportItem: SupportItemModel;
};

function SupportItem(props: SupportItemProps) {
    const { image, title, description, notice } = props.supportItem;

    return (
        <div className="support-item">
            <img className="support-item__logo" src={image} alt="" />
            <div className="support-item__text-block">
                <div className="support-item__title">{t(title)}</div>
                <div className="support-item__description">{t(description)}</div>
                <div className="support-item__notice">{t(notice)}</div>
            </div>
        </div>
    );
}

export default SupportItem;
