import { translate as t } from 'service/Language';

import PaykassmaDepositService from '../../service/PaykassmaDeposit';
import Failed from './images/Failed.svg';

import './FailedTransactionPage.scss';

function FailedTransactionPage() {
    function skipUnusedForm() {
        if (PaykassmaDepositService.state.getValue()) {
            PaykassmaDepositService.showPreviousForm();
        }
        if (PaykassmaDepositService.transaction.getValue()) {
            PaykassmaDepositService.showTransaction();
        }
    }
    return (
        <div className="transaction-failed">
            <div className="transaction-failed__container">
                <img src={Failed} alt="failed" />
                <span className="transaction-failed__title ">{t('profile.balance.deposit.transaction.failed')}</span>
                <button className="transaction-failed__btn" onClick={skipUnusedForm}>
                    {t('profile.balance.deposit.transaction.failed.button')}
                </button>
            </div>
        </div>
    );
}

export default FailedTransactionPage;
