/* eslint-disable max-len */

import { Icon } from 'types';

function RedCard({ color = '#FC4665', width = 7, height = 12 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 7 12" fill="none">
            <path d="M0 1L7.00001 0V12L0 11V1Z" fill={color} />
            <path d="M0 1L7.00001 0V7H0V1Z" fill={color} />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="1.94445"
                    y1="-2.94574e-08"
                    x2="2.62119"
                    y2="3.30854"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default RedCard;
