import InputService from 'component/Input/service/Input';
import {FormEvent} from 'react';
import {BehaviorSubject} from 'rxjs';
import AuthService from 'service/Auth';
import GoogleService from 'service/Google';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';
import {PaymentMethod} from '../../../PaymentMethod/types';
import WithdrawService from '../../service/Withdrawal';
import BonusService from '../../../../../../service/Bonus';

import StatusFormPopupService, {StatusForm} from '../../../Deposit/DepositPopup/service/StatusForm';
import {AmountError} from '../../../Deposit/Directa24/Pix/service/AmountForm';
import BalanceService from '../../../../../../service/Balance';
import {CoinsPaidWithdrawRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendCoinsPaidWithdraw';
import CoinspaidWithdrawService, {CryptoWithdrawState} from './CoinspaidWithdrawal';
import UserDataService from '../../../../../../service/UserData';
import {unknownCurrency} from '../../../../../../service/model/Currency';
import {LettersNumbersService} from '../../../../../../service/Validator/LettersNumbers';
import CoinspaidDepositService from '../../../Deposit/Coinspaid/service/CoinspaidDeposit';
import {CoinspaidDepositRateResponse} from '@ay_tsarbet/newbet-core/dist/types';
import cryptoPaymentMethod from '../../../PaymentMethod/cryptoMethods';


export enum CryptoTip {
    NOERROR,
    AMOUNT,
    WALLET,
    TAG,
}

class CryptoForm {
    paymentMethod = new BehaviorSubject<PaymentMethod | null>(null)

    limitValidator = new Validator.minLimit(0);

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.withdraw.input.amount',
    });

    wallet = new InputService({
        title: 'popup.payment.crypto.input.wallet',
        validator: Validator.nonEmpty,
        validatorText: 'popup.payment.crypto.input.wallet',
    });

    tag = new InputService({
        title: 'popup.payment.crypto.tag',
        validator: Validator.length9,
        validatorText: 'popup.payment.crypto.tag',
    });

    amountLimit = new BehaviorSubject<AmountError>(AmountError.NONE);

    valid = new BehaviorSubject<boolean>(false);

    tip = new BehaviorSubject<CryptoTip>(CryptoTip.NOERROR);

    balance = new BehaviorSubject(0);

    minWithdrawal = new BehaviorSubject<number>(0);

    handler = {
        onMethod: this.onMethod.bind(this),
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        onWallet: this.onWallet.bind(this),
        onWalletActive: this.onWalletActive.bind(this),
        onTag: this.onTag.bind(this),
        onTagActive: this.onTagActive.bind(this),
        onBalance: this.onBalance.bind(this),
        onRate: this.onRate.bind(this),
    };

    constructor() {
        // @ts-ignore
        CoinspaidDepositService.rate.subscribe(this.handler.onRate)
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
        this.wallet.value.subscribe(this.handler.onWallet);
        this.wallet.active.subscribe(this.handler.onWalletActive);
        this.tag.value.subscribe(this.handler.onTag);
        this.tag.active.subscribe(this.handler.onTagActive);
        WithdrawService.method.subscribe(this.handler.onMethod);
        BalanceService.balance.subscribe(this.handler.onBalance);
    }

    onMethod(method: PaymentMethod | null) {
        method && this.paymentMethod.next(method);
    }

    onRate(rate: CoinspaidDepositRateResponse) {
        if(rate) {
            this.minWithdrawal.next(Math.ceil(rate.minWithdraw));
            const min = Math.ceil(this.minWithdrawal.getValue());
            const isFee = WithdrawService.fee.getValue();
            const isMin = Math.ceil(min * 1.25);
            this.limitValidator.setLimit(isFee ? isMin : min);
        }
    }

    onBalance(balance: number) {
        this.balance.next(balance)
    }

    onAmountActive(isActive: boolean) {
        const tip = isActive ? CryptoTip.AMOUNT : CryptoTip.NOERROR
        this.tip.next(tip)
    }

    onAmount(value: string) {
        const isValue = this.amount.value.getValue();
        const min = Math.ceil(this.minWithdrawal.getValue());
        if (min && isValue) {
            const fee = WithdrawService.fee.getValue();
            if (fee && +value < min * 1.25) {
                this.amountLimit.next(AmountError.AMOUNT_FEE);
                this.amount.error.next('profile.balance.withdraw.input.amount');
            } else if (!fee && +value < min) {
                this.amountLimit.next(AmountError.AMOUNT_MIN);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            } else if (this.balance.getValue() < +value) {
                this.amountLimit.next(AmountError.AMOUNT_WITHDRAWAL);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            } else {
                this.amountLimit.next(AmountError.NONE);
                this.amount.error.next(null);
            }
        } else {
            this.amount.error.next(null);
        }
        this.checkValid();
    }

    onWallet(value: string) {
        if (this.wallet.getValue()) {
            if (!LettersNumbersService.validate(value)) {
                const current = value.length;
                const name = value.slice(0, current - 1);
                this.wallet.value.next(name);
            }
        } else {
            this.wallet.error.next(null);
        }
        this.checkValid();
    }

    onWalletActive(isActive: boolean) {
        const tip = isActive ? CryptoTip.WALLET : CryptoTip.NOERROR
        this.tip.next(tip)
    }

    onTag(value: string) {
        this.checkValid();
    }

    onTagActive(isActive: boolean) {
        const tag = isActive ? CryptoTip.TAG : CryptoTip.NOERROR
        this.tip.next(tag)
    }

    checkValid() {
        const method = this.paymentMethod.getValue();
        const isAmount = this.amount.isValid();
        const isWallet = this.wallet.isValid();
        const isTag = this.tag.isValid();
        if (method) {
            if (method?.id === cryptoPaymentMethod.XRP.id || this.paymentMethod.getValue()!.id === cryptoPaymentMethod.BNB.id
            ) {
                this.valid.next(isAmount && isWallet && isTag);
            } else {
                this.valid.next(isAmount && isWallet);
            }
        } else {
            this.valid.next(isAmount && isWallet);
        }
    }

    send() {
        if (this.paymentMethod.getValue() && UserDataService.data.getValue()) {
            const currencyId = UserDataService.data.getValue()?.currency.ISO ? UserDataService.data.getValue()!.currency.ISO : unknownCurrency.ISO;
            const channelType = this.paymentMethod.getValue()!.id;
            const wallet = this.wallet.getValue();
            const tag = this.tag.getValue();
            const amount = parseInt(this.amount.getValue());

            const request: CoinsPaidWithdrawRequest = {
                currencyId,
                channelType,
                address: wallet,
                tag,
                amount,
            };
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            GoogleService.sentWithdraw(
                userId,
                this.paymentMethod.getValue()!.title,
                amount.toString(),
                this.paymentMethod.getValue()!.currency.name,
                this.wallet.getValue(),
            );
            console.log('send', request);
            PaymentService.coinspaid.withdraw(request);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();
        const isBonuses = BonusService.list.getValue().length;

        if (isBonuses) {
            CoinspaidWithdrawService.state.next(CryptoWithdrawState.BONUS_REJECT);
            StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
        } else {
            CoinspaidWithdrawService.state.next(CryptoWithdrawState.AWAIT);
            this.send();
        }
    }

    reset() {
        this.amount.value.next('');
        this.wallet.value.next('');
        this.tag.value.next('');

        this.amount.error.next(null);
        this.wallet.error.next(null);
        this.tag.error.next(null);

        this.amount.active.next(false);
        this.wallet.active.next(false);
        this.tag.active.next(false);

        this.amountLimit.next(AmountError.NONE);
        this.tip.next(CryptoTip.NOERROR);
        this.valid.next(false);
        this.minWithdrawal.next(0)
    }
}

const CryptoFormService = new CryptoForm();
export default CryptoFormService;
