import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import { translate as t } from 'service/Language';

import EmailInputService from '../../../../../../component/LogIn/service/EmailInput';
import AuthService from '../../../../../../service/Auth';
import GoogleService from '../../../../../../service/Google';
import { PhoneMpesaService } from '../../../../../../service/Validator/PhoneMpesa';
import Validator from '../../../../../../service/Validator/Validator';
import { WalletEasypaisaService } from '../../../../../../service/Validator/WalletEasypaisa';
import { WalletZoftService } from '../../../../../../service/Validator/WalletZoft';
import { formatMpesaPhoneForBack } from '../../../../../../utils/format';
import { PaymentMethod } from '../../../PaymentMethod/types';
import PaykassmaDepositService from './PaykassmaDeposit';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';

export enum PaykassmaInputTip {
    NONE,
    EMAIL,
    WALLET_EASYPAISA,
    PHONE_MPESA,
    VPA,
}

class AmountForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(1, 1000000);

    walletNumber = new InputService({
        title: 'profile.balance.withdraw.input.wallet',
        validator: Validator.walletZoft,
        validatorText: 'profile.balance.withdraw.help.wallet',
    });

    amount = new InputService({
        title: 'profile.balance.deposit.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.deposit.input.amount',
    });

    walletEasypaisa = new InputService({
        title: 'popup.payment.easypaisa.wallet',
        validator: Validator.walletEasypaisa,
        validatorText: 'popup.payment.easypaisa.wallet.error',
    });

    name = new InputService({
        title: 'popup.payment.easypaisa.wallet',
        validator: Validator.walletEasypaisa,
        validatorText: 'popup.payment.easypaisa.wallet.error',
    });

    email = new InputService({
        title: 'popup.payment.easypaisa.email',
        validator: Validator.email,
        validatorText: 'recovery.error.email',
    });

    vpa = new InputService({
        title: 'VPA/UPI ID',
        validator: Validator.vpa,
        validatorText: 'VPA/UPI ID',
    });

    errorTip = new BehaviorSubject<PaykassmaInputTip>(PaykassmaInputTip.NONE);

    phone = new InputService({
        title: 'profile.balance.withdraw.input.phone',
        validator: Validator.phoneMpesa,
    });

    termsAgreement = new BehaviorSubject<boolean>(false);

    valid = new BehaviorSubject<boolean>(false);

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        onWallet: this.onWallet.bind(this),
        onWalletEasypaisa: this.onWalletEasypaisa.bind(this),
        onWalletActive: this.onWalletActive.bind(this),
        onEmail: this.onEmail.bind(this),
        onEmailActive: this.onEmailActive.bind(this),
        onPhone: this.onPhone.bind(this),
        onPhoneActive: this.onPhoneActive.bind(this),
        onVPA: this.onVPA.bind(this),
        onVPAActive: this.onVPAActive.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.walletNumber.value.subscribe(this.handler.onWallet);
        this.walletEasypaisa.value.subscribe(this.handler.onWalletEasypaisa);
        this.walletEasypaisa.active.subscribe(this.handler.onWalletActive);
        this.email.value.subscribe(this.handler.onEmail);
        this.email.active.subscribe(this.handler.onEmailActive);
        this.phone.value.subscribe(this.handler.onPhone);
        this.phone.active.subscribe(this.handler.onPhoneActive);
        this.vpa.value.subscribe(this.handler.onVPA);
        this.vpa.active.subscribe(this.handler.onVPAActive);
    }

    setLimit(min: number, max: number) {
        if (this.limitValidator) {
            this.limitValidator.setLimit(min, max);
        }
    }

    onAmount(value: string) {
        if (!this.limitValidator.validate(value) && this.amount.getValue()) {
            this.amount.error.next(t('profile.balance.deposit.input.amount'));
            this.valid.next(false);
        } else {
            this.checkValid();
            this.amount.error.next(null);
        }
    }

    onWallet(value: string) {
        if (!WalletZoftService.validate(value) && this.walletNumber.getValue()) {
            this.walletNumber.error.next(t('profile.balance.withdraw.help.wallet'));
            this.valid.next(false);
        } else {
            this.checkValid();
            this.walletNumber.error.next(null);
        }
    }
    onVPA(value: string) {
        if(value) {
            this.checkValid();
        } else this.vpa.error.next(null)
    }
    onVPAActive(active: boolean) {
        if (active) {
            this.errorTip.next(PaykassmaInputTip.VPA);
        } else {
            this.errorTip.next(PaykassmaInputTip.NONE);
        }
    }

    onWalletEasypaisa(value: string) {
        if (this.walletEasypaisa.getValue() && !WalletEasypaisaService.validate(value)) {
            this.walletEasypaisa.error.next(t('popup.payment.easypaisa.wallet.error'));
            this.valid.next(false);
        } else {
            this.checkValid();
            this.walletEasypaisa.error.next(null);
        }
    }

    onEmail(value: string) {
        if (value && !EmailInputService.validate(value)) {
            this.email.error.next(t('recovery.error.email'));
            this.valid.next(false);
        } else {
            this.checkValid();
            this.email.error.next(null);
        }
    }

    onPhone(value: string) {
        if (value && !PhoneMpesaService.validate(value)) {
            this.phone.error.next(t('profile.balance.withdraw.input.phone'));
            this.valid.next(false);
        } else {
            this.checkValid();
            this.phone.error.next(null);
        }
    }

    onAmountActive(active: boolean) {
        this.errorTip.next(PaykassmaInputTip.NONE);
    }

    onPhoneActive(active: boolean) {
        if (active) {
            this.errorTip.next(PaykassmaInputTip.PHONE_MPESA);
        } else {
            this.errorTip.next(PaykassmaInputTip.NONE);
        }
    }

    onWalletActive(active: boolean) {
        if (active) {
            this.errorTip.next(PaykassmaInputTip.WALLET_EASYPAISA);
        } else {
            this.errorTip.next(PaykassmaInputTip.NONE);
        }
    }

    onEmailActive(active: boolean) {
        if (active) {
            this.errorTip.next(PaykassmaInputTip.EMAIL);
        } else {
            this.errorTip.next(PaykassmaInputTip.NONE);
        }
    }

    toggleTermsAndCondition() {
        this.termsAgreement.next(!this.termsAgreement.value);
        this.checkValid()
    }

    validationUpiQR() {
        const valid = this.amount.isValid() && this.termsAgreement.getValue();

        if (valid) {
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    checkValid() {
        if (this.paymentMethod) {
            if (this.paymentMethod.id === ChannelType.Easypaisa) {
                const isAmount = this.amount.isValid();
                const isEmail = this.email.isValid();
                const isWallet = this.walletEasypaisa.isValid();

                if (isAmount && isEmail && isWallet) {
                    this.valid.next(true);
                } else this.valid.next(false);
            }
            if (this.paymentMethod.id === ChannelType.BKASH_API) {
                if (this.amount.isValid() && this.walletNumber.isValid()) {
                    this.valid.next(true);
                } else this.valid.next(false);
            }

            if (this.paymentMethod.id === ChannelType.MPESA) {
                if (this.amount.isValid() && this.phone.isValid()) {
                    this.valid.next(true);
                } else this.valid.next(false);
            }

            if (
                this.paymentMethod.id !== ChannelType.BKASH_API ||
                // @ts-ignore
                this.paymentMethod.id !== ChannelType.Easypaisa ||
                this.paymentMethod.id !== ChannelType.MPESA ||
                this.paymentMethod.id === ChannelType.UPI_FAST ||
                this.paymentMethod.id === ChannelType.UPI_S ||
                this.paymentMethod.id === ChannelType.UPI_ARS ||
                this.paymentMethod.id === ChannelType.PayTM ||
                this.paymentMethod.id === ChannelType.PhonePe ||
                this.paymentMethod.id === ChannelType.UPI
            ) {
                if (this.amount.isValid()) {
                    this.valid.next(true);
                } else this.valid.next(false);
            }
            if (this.paymentMethod.id === ChannelType.UPI_FAST_APAY) {
                this.valid.next(this.amount.isValid() && this.vpa.isValid());
            }
        }
    }

    setValue(value: number) {
        this.amount.onFocus();
        this.amount.value.next(value.toString());
    }

    setMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        this.setLimit(paymentMethod.limit.deposit.min, paymentMethod.limit.deposit.max);
    }

    skip() {
        PaykassmaDepositService.showTransaction();
    }

    reset() {
        this.amount.value.next('');
        this.phone.value.next('');
        this.walletEasypaisa.value.next('');
        this.email.value.next('');
        this.vpa.value.next('');
        this.walletNumber.value.next('');

        this.amount.error.next(null);
        this.vpa.error.next(null);
        this.termsAgreement.next(false);

        this.vpa.active.next(false);
        this.valid.next(false);
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();
        const isApayBDT = this.paymentMethod?.id === ChannelType.APAY_BDT;
        const isApayBkashNagad = this.paymentMethod?.id === ChannelType.BKASH_APAY || this.paymentMethod?.id === ChannelType.NAGAD_APAY;
        const valid = this.amount.isValid();

        if (valid && this.paymentMethod) {
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            const amount = parseFloat(this.amount.getValue());

            GoogleService.sentDepositAmount(
                userId,
                this.paymentMethod.title,
                amount.toString(),
                this.paymentMethod.currency.name
            );

            PaykassmaDepositService.setAmount(amount);
            if (
                this.paymentMethod.id === ChannelType.NAGAD_API ||
                this.paymentMethod.id === ChannelType.Wavepay ||
                this.paymentMethod.id === ChannelType.Kpay ||
                this.paymentMethod.id === ChannelType.Bankalfalah ||
                this.paymentMethod.id === ChannelType.UPI_S  ||
                this.paymentMethod.id === ChannelType.UPI_FAST ||
                this.paymentMethod.id === ChannelType.UPI_ARS ||
                this.paymentMethod.id === ChannelType.UPI_ARF ||
                this.paymentMethod.id === ChannelType.Ipay ||
                this.paymentMethod.id === ChannelType.UPAY_APAY
            ) {
                PaykassmaDepositService.depositNagadP2CRequest(this.paymentMethod, amount);
                PaykassmaDepositService.showWait();
            }
            if (this.paymentMethod.id === ChannelType.BKASH_API || isApayBkashNagad || isApayBDT) {
                PaykassmaDepositService.depositBKashP2CRequest(
                    this.paymentMethod,
                    amount,
                    isApayBDT ? '8808888888888' : this.walletNumber.getValue()
                );
                PaykassmaDepositService.showWait();
            }
            if (this.paymentMethod.id === ChannelType.UPI_FAST_APAY) {
                PaykassmaDepositService.depositBKashP2CRequest(
                    this.paymentMethod,
                    amount,
                    this.vpa.getValue()
                );
                PaykassmaDepositService.showWait();
            }
            if (this.paymentMethod.id === ChannelType.MPESA) {
                const accountNumber = formatMpesaPhoneForBack(this.phone.getValue());
                PaykassmaDepositService.depositBKashP2CRequest(this.paymentMethod, amount, accountNumber);
                PaykassmaDepositService.showWait();
            }
            if (this.paymentMethod.id === ChannelType.Easypaisa) {
                PaykassmaDepositService.depositPaykassmaEasypaisaRequest(
                    this.paymentMethod,
                    this.walletEasypaisa.getValue(),
                    this.email.getValue(),
                    amount
                );
                PaykassmaDepositService.showWait();
            }
            // if (this.paymentMethod === _paymentMethod.APAY_ROCKET ||
            //     this.paymentMethod === _paymentMethod.APAY_MOBILE_BANKING ||
            //     this.paymentMethod === _paymentMethod.APAY_BANK_CARDS) {
            //     PaykassmaDepositService.depositApayBDTRequest(
            //         this.paymentMethod,
            //         '8808888888888',
            //         'fake@glory.bet',
            //         'Fake',
            //         amount
            //     );
            //     PaykassmaDepositService.showWait();
            // }
            if (
                this.paymentMethod.id === ChannelType.bKash ||
                this.paymentMethod.id === ChannelType.Nagad ||
                this.paymentMethod.id === ChannelType.Rocket ||
                this.paymentMethod.id === ChannelType.PayTM ||
                this.paymentMethod.id === ChannelType.PhonePe ||
                this.paymentMethod.id === ChannelType.UPI ||
                this.paymentMethod.id === ChannelType.Easypaisa_2
            ) {
                PaykassmaDepositService.requestInvoice(this.paymentMethod);
                PaykassmaDepositService.showInvoiceWait();
            }
        }
    }
}

const AmountFormService = new AmountForm();

export default AmountFormService;
