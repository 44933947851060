import {
    AnindaHavaleDepositRequest
} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendAnindaHavaleDepositRequest';
import {
    AnindaHavaleWithdrawalRequest
} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendAnindaHavaleWithdrawal';
import { AzarpayDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendAzarpayDepositRequest';
import { AzarpayWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendAzarpayWithdrawal';
import { CorefyDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendCorefyDepositRequest';
import { CorefyWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendCorefyWithdrawal';
import { EpulDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendEpulDepositRequest';
import { EpulWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendEpulWithdraw';
import { HavaleHeraDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendHavaleHeraDepositRequest';
import { HavaleHeraWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendHavaleHeraWithdrawal';
import { InstapayDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendInstapayDepositRequest';
import { InstapayWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendInstapayWithdrawRequest';
import { PaycosDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaycosDepositRequest';
import { PaycosWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaycosWithdraw';
import { PayFixDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPayFixDepositRequest';
import { PayfixWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPayFixWithdrawal';
import {
    PaykassmaBkashP2CDepositRequest
} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaBkashP2CDepositRequest';
import { PaykassmaDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaDepositRequest';
import {
    PaykassmaEasypaisaDepositRequest
} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaEasypaisaDepositRequest';
import { PaykassmaInvoiceRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaInvoiceRequest';
import {
    PaykassmaNagadP2CDepositRequest
} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaNagadP2CDepositRequest';
import { PaykassmaPayment } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaPaymentCheck';
import { PaykassmaWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaWithdraw';

import AnindaHavaleProvider, { AnindaHavaleHandler } from './payment/AnindaHavale';
import AzarpayProvider from './payment/Azarpay';
import CorefyProvider from './payment/Corefy';
import EpulProvider, { EpulHandler } from './payment/Epul';
import HavaleHeraProvider, { HavaleHeraHandler } from './payment/HavaleHera';
import InstapayProvider, { InstapayHandler } from './payment/Instapay';
import PaycosProvider, { PaycosHandler } from './payment/Paycos';
import PayfixProvider, { PayfixHandler } from './payment/Payfix';
import PaykassmaProvider, { PaykassmaHandler } from './payment/Paykassma';
import Directa24Provider, { Directa24Handler } from './payment/Directa24';

import { Directa24WithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendDirecta24Withdrawal';
import PayesProvider, { PayesHandler } from './payment/Payes';
import { Directa24DepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendDirecta24DepositRequest';
import { PayesDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPayesDepositRequest';
import { PayesWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPayesWithdrawal';
import ConstantposProvider, {ConstantposHandler} from './payment/Constantpos';
import {ConstantposDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendConstantposDepositRequest';
import {ConstantposWithdrawRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendConstantposWithdraw';
import MpayProvider, { MpayHandler } from './payment/Mpay';
import { MpayDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sentMpayDepositRequest';
import CoinspaidDepositService from '../../page/Profile/Balance/Deposit/Coinspaid/service/CoinspaidDeposit';
import CoinspaidProvider, {CoinspaidHandler} from './payment/Coinspaid';
import {CoinsPaidRateRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sentCoinsPaidRateRequest';
import {CoinsPaidDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sentCoinsPaidDepositRequest';
import {CoinsPaidWithdrawRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendCoinsPaidWithdraw';
import {VisaDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendVisaDepositRequest';
import PaycordProvider, {PaycordHandler} from './payment/Paycord';
import {PaycordDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaycordDepositRequest';
import WellxPayProvider from './payment/WellxPay';
import {
    WellxpayBkashP2CDepositRequest
} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendWellxpayBkashP2CDepositRequest';
import {WellxpayWithdrawRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendWellxpayWithdraw';
import {WellxPayInvoiceRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendWellxPayInvoiceRequest';
import {WellxPayDepositStepTwo} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendWellxPayDepositStepTwo';
import {CashixDepositRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendCashixP2CDepositRequest';
import {CashixWithdrawRequest} from '@ay_tsarbet/newbet-core/dist/connection/actions/sendCashixWithdraw';
import CashixProvider from './payment/Cashix';


interface PaymentProviderHandler {
    anindaHavale: HavaleHeraHandler;
    havaleHera: AnindaHavaleHandler;
    paykassma: PaykassmaHandler;
    paycos: PaycosHandler;
    epul: EpulHandler;
    instapay: InstapayHandler;
    payfix: PayfixHandler;
    directa24: Directa24Handler;
    payes: PayesHandler;
    constantpos: ConstantposHandler;
    mpay: MpayHandler;
    coinspaid: CoinspaidHandler;
    paycord: PaycordHandler;
}

class PaymentProvider {
    havaleHera: HavaleHeraProvider;

    anindaHavale: AnindaHavaleProvider;

    paykassma: PaykassmaProvider;

    paycos: PaycosProvider;

    epul: EpulProvider;

    instapay: InstapayProvider;

    payfix: PayfixProvider;

    corefy: CorefyProvider;

    azarpay: AzarpayProvider;

    directa24: Directa24Provider;

    payes: PayesProvider;

    constantpos : ConstantposProvider;

    mpay : MpayProvider;

    coinspaid : CoinspaidProvider;

    paycord: PaycordProvider;

    wellxPay: WellxPayProvider;

    cashix: CashixProvider;

    constructor(handler: {
        havaleHera: {
            deposit: (data: HavaleHeraDepositRequest) => void;
            withdraw: (data: HavaleHeraWithdrawalRequest) => void;
        };
        anindaHavale: {
            deposit: (data: AnindaHavaleDepositRequest) => void;
            withdraw: (data: AnindaHavaleWithdrawalRequest) => void;
        };
        instapay: {
            deposit: (data: InstapayDepositRequest) => void;
            withdraw: (data: InstapayWithdrawRequest) => void;
        };
        payfix: {
            deposit: (data: PayFixDepositRequest) => void;
            withdraw: (data: PayfixWithdrawalRequest) => void;
        };
        paykassma: {
            byCard: (data: PaykassmaDepositRequest) => void;
            depositNagadP2C: (data: PaykassmaNagadP2CDepositRequest) => void;
            depositBKashP2C: (data: PaykassmaBkashP2CDepositRequest) => void;
            depositEasypaisa: (data: PaykassmaEasypaisaDepositRequest) => void;
            createInvoice: (data: PaykassmaInvoiceRequest) => void;
            checkPayment: (data: PaykassmaPayment) => void;
            withdraw: (data: PaykassmaWithdrawRequest) => void;
        };
        epul: { deposit: (data: EpulDepositRequest) => void; withdraw: (data: EpulWithdrawRequest) => void };
        paycos: { deposit: (data: PaycosDepositRequest) => void; withdraw: (data: PaycosWithdrawRequest) => void };
        corefy: {
            deposit: (data: CorefyDepositRequest) => void;
            withdraw: (data: CorefyWithdrawalRequest) => void;
        };
        azarpay: {
            depositBank: (data: AzarpayDepositRequest) => void;
            depositVisa: (data: VisaDepositRequest) => void;
            withdraw: (data: AzarpayWithdrawalRequest) => void;
        };
        directa24: {
            deposit: (data: Directa24DepositRequest) => void;
            withdraw: (data: Directa24WithdrawalRequest) => void;
        };
        payes: {
            deposit: (data: PayesDepositRequest) => void;
            withdraw: (data: PayesWithdrawalRequest) => void;
        };
        constantpos: {
            deposit: (data: ConstantposDepositRequest) => void;
            withdraw: (data: ConstantposWithdrawRequest) => void;
        };
        mpay: {
            deposit: (data: MpayDepositRequest) => void;
        };
        coinspaid: {
            requestRateMin: (data: CoinsPaidRateRequest) => void;
            deposit: (data: CoinsPaidDepositRequest) => void;
            withdraw: (data: CoinsPaidWithdrawRequest) => void;
        };
        paycord: {
            deposit: (data: PaycordDepositRequest) => void;
        };
        wellxpay : {
            depositBKashP2C: (data: WellxpayBkashP2CDepositRequest) => void;
            withdraw: (data: WellxpayWithdrawRequest) => void;
            createInvoice: (data: WellxPayInvoiceRequest) => void;
            depositStepTwo: (data: WellxPayDepositStepTwo) => void;
        };
        cashix : {
            depositCashixP2C: (data: CashixDepositRequest) => void;
            withdraw: (data: CashixWithdrawRequest) => void;
        };
    }) {
        this.havaleHera = new HavaleHeraProvider(handler.havaleHera);
        this.anindaHavale = new AnindaHavaleProvider(handler.anindaHavale);
        this.paykassma = new PaykassmaProvider(handler.paykassma);
        this.paycos = new PaycosProvider(handler.paycos);
        this.epul = new EpulProvider(handler.epul);
        this.instapay = new InstapayProvider(handler.instapay);
        this.payfix = new PayfixProvider(handler.payfix);
        this.corefy = new CorefyProvider(handler.corefy);
        this.azarpay = new AzarpayProvider(handler.azarpay);
        this.directa24 = new Directa24Provider(handler.directa24);
        this.payes = new PayesProvider(handler.payes);
        this.constantpos = new ConstantposProvider(handler.constantpos);
        this.mpay = new MpayProvider(handler.mpay);
        this.coinspaid = new CoinspaidProvider(handler.coinspaid);
        this.paycord = new PaycordProvider(handler.paycord);
        this.wellxPay = new WellxPayProvider(handler.wellxpay);
        this.cashix = new CashixProvider(handler.cashix);
    }
}

export default PaymentProvider;
