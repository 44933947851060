import classNames from 'classnames';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';

import WheelRegistrationFormService from '../service/registration';

import './SignUpButton.scss';

type SignUpButtonProps = {
    type: string;
    title?: string;
};

function SignUpButton({ type, title }: SignUpButtonProps) {
    const correct = useObservable(WheelRegistrationFormService.correct, false);

    const className: string = classNames({
        'wheel-sign-up': true,
        'wheel-sign-up____card': type === 'card',
        'wheel-sign-up____girl': type === 'girl',
        'wheel-sign-up____teen': type === 'teen',
        'wheel-sign-up____crazytime': type === 'crazytime' || type === 'roulette' || type === 'bonanza' || type === 'olympus',
        'wheel-sign-up____aviator': type === 'aviator',
        'wheel-sign-up____criket': type === 'criket',
        'wheel-sign-up____slots': type === 'slots',
        'wheel-sign-up____active': correct,
    });

    return (
        <button type='submit' className={className}>
            {title ? t(title) : t('wheel.popup.reg.sign')}
        </button>
    );
}

export default SignUpButton;
