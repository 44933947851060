import { BehaviorSubject, combineLatestWith, map } from 'rxjs';

import GameHelper from '../../../service/GameHelper';
import GamesService, { GameFilter } from '../../../service/Games';
import { gameCategory } from '../../../service/model/GameCategory';
import GameProvider from '../../../service/model/GameProvider';

class ProviderFilter {
    input = new BehaviorSubject('');

    isDesktop = new BehaviorSubject(false);

    list = GamesService.allowedProviders
        .pipe(combineLatestWith(this.input))
        .pipe(map(ProviderFilter.applySearch))
        .pipe(combineLatestWith(this.isDesktop))
        .pipe(map(ProviderFilter.applyPlatformFilter))
        .pipe(combineLatestWith(GamesService.filter))
        .pipe(combineLatestWith(this.isDesktop))
        .pipe(map(ProviderFilter.applyCategoryFilter));

    setInput(text: string) {
        this.input.next(text);
    }

    public static applySearch([providerList, search]: [GameProvider[], string]): GameProvider[] {
        const string = search.toLowerCase();

        return providerList.filter((provider) => {
            return provider.name.toLowerCase().includes(string);
        });
    }

    public static applyPlatformFilter([providerList, platform]: [GameProvider[], boolean]): GameProvider[] {
        return providerList.filter((provider) => {
            return provider.gameList.filter((game) => GameHelper.matchPlatform(game, platform)).length > 0;
        });
    }

    /* check whether picked Category has games of some Provider or not */
    public static applyCategoryFilter([[providerList, filter], isDesktop]: [
        [GameProvider[], GameFilter],
        boolean
    ]): GameProvider[] {
        const { category } = filter;

        if (category === null) {
            return providerList;
        }

        if (category === gameCategory.Popular) {
            return providerList.filter((provider) => {
                return (
                    provider.gameList
                        .filter((game) => GameHelper.matchPlatform(game, isDesktop))
                        .filter((game) => category.gameList.includes(game)).length > 0
                );
            });
        }

        return providerList.filter((provider) => {
            return provider.gameList.filter((game) => category.gameList.includes(game)).length > 0;
        });
    }
}

const ProviderFilterService = new ProviderFilter();

export default ProviderFilterService;
