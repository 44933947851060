import { translate as t } from 'service/Language';

import Failed from './images/Failed.svg';

import './FailedPage.scss';

function FailedPage() {
    return (
        <div className="paykassma-failed">
            <div className="paykassma-failed__container">
                <img src={Failed} alt="failed" />
                <span className="paykassma-failed__title ">{t('popup.payment.error')}</span>
            </div>
        </div>
    );
}

export default FailedPage;
