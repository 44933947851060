import useObservable from 'utils/useObservable';
import ConstantposWithdrawService, {ConstantposWithdrawState} from '../service/ConstantposWithdraw';

import FailedPage from './ResultPage/FailedPage/FailedPage';
import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import BonusFailedPage from '../../../../../../component/BonusFailedPage/BonusFailedPage';

import './ResultForm.scss';


function ResultForm() {
    const state = useObservable(ConstantposWithdrawService.state, ConstantposWithdrawService.state.getValue());

    switch (state) {
        case ConstantposWithdrawState.ACCEPT: {
            return <SuccessPage />;
        }
        case ConstantposWithdrawState.REJECT: {
            return <FailedPage />;
        }
        case ConstantposWithdrawState.BONUS_REJECT: {
            return <BonusFailedPage />;
        }
        default: {
            return null;
        }
    }
}

export default ResultForm;
