import { translate as t } from 'service/Language';

import { HistoryService } from '../../../../../service/HistoryService';
import RouteConfig from '../../../../../appConstants/RouteConfig';
import PinAnimated from '../../../../PinAnimated/PinAnimated';

import './Pin.scss';

function Pin() {
    const formatText = t('check.email.notice');
    const onClickHandle = ()=> {
        HistoryService.history.push({ hash: RouteConfig.hash.checkEmail });
    }

    return (
        <div className="Pin" onClick={onClickHandle}>
                <PinAnimated />
                <span className="Pin__text" dangerouslySetInnerHTML={{__html: formatText}}/>
        </div>
    )
}

export default Pin;
