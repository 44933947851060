import { translate as t } from '../../../service/Language';
import { RemainingTime } from './type';

type TimerProps = {
    remaining: RemainingTime;
};

function Timer(props: TimerProps) {
    const { remaining } = props;

    const _remaining = {
        day: remaining.day.toString().padStart(2, '0'),
        hour: remaining.hour.toString().padStart(2, '0'),
        minute: remaining.minute.toString().padStart(2, '0'),
        second: remaining.second.toString().padStart(2, '0'),
    };

    return (
        <>
            <div className="card-face__hint">{t('tournament.card.remaining')}</div>
            <div className="card-face__timer">
                <div className="card-face__timer-char">
                    <div className="card-face__timer-value">{_remaining.day}</div>
                    <div className="card-face__timer-hint">{t('tournament.card.timer.days')}</div>
                </div>
                <div className="card-face__timer-div" />
                <div className="card-face__timer-char">
                    <div className="card-face__timer-value">{_remaining.hour}</div>
                    <div className="card-face__timer-hint">{t('tournament.card.timer.minutes')}</div>
                </div>
                <div className="card-face__timer-div" />
                <div className="card-face__timer-char">
                    <div className="card-face__timer-value">{_remaining.minute}</div>
                    <div className="card-face__timer-hint">{t('tournament.card.timer.hours')}</div>
                </div>
                <div className="card-face__timer-div" />
                <div className="card-face__timer-char">
                    <div className="card-face__timer-value">{_remaining.second}</div>
                    <div className="card-face__timer-hint">{t('tournament.card.timer.seconds')}</div>
                </div>
            </div>
        </>
    );
}

export default Timer;
