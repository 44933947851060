import { useEffect } from 'react';
import { translate as t } from 'service/Language';

import Close from '../../../../component/Close/Close';
import ChangePasswordService from '../../../../service/ChangePassword';
import useObservable from '../../../../utils/useObservable';
import SuccessProfilePage from '../../Account/SuccessProfilePage/SuccessProfilePage';
import ChangeButton from './ChangeButton/ChangeButton';
import NewPasswordInput from './NewPasswordInput/NewPasswordInput';
import OldPasswordInput from './OldPasswordInput/OldPasswordInput';
import RepeatePasswordInput from './RepeatePasswordInput/RepeatePasswordInput';
import PasswordFormService from './service/PasswordFormService';

import './ChangePassword.scss';

function ChangePassword() {
    const success = useObservable<boolean>(ChangePasswordService.status, ChangePasswordService.status.value);
    useEffect(() => {
        PasswordFormService.reset();
        return () => {
            PasswordFormService.reset();
        };
    }, []);
    return (
        <form className="change-password" onSubmit={(e) => PasswordFormService.handler.onSubmit(e)}>
            <div className="change-password__header">
                <span className="change-password__title">{t('profile.settings.password.change.title')}</span>
                <Close />
            </div>
            {!success ? (
                <>
                    <OldPasswordInput />
                    <NewPasswordInput />
                    <RepeatePasswordInput />
                    <ChangeButton />
                </>
            ) : (
                <SuccessProfilePage title="profile.settings.password.change.success" />
            )}
        </form>
    );
}

export default ChangePassword;
