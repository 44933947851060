import Input, { InputSize } from 'component/Input/Input';
import MaskedInput from 'component/Input/MaskedInput';
import RxComponent from 'component/RxComponent/RxComponent';
import { translate as t } from 'service/Language';
import { formatCurrencySign } from 'utils/format';

import { PayfixErrorTip, PayfixInputTip } from '../../../../Deposit/Payfix/service/AmountForm';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import FeeNotice from '../../../FeeNotice/FeeNotice';
import WithdrawCloseButton from '../../../WithdrawCloseButton/WithdrawCloseButton';
import CardFormService from '../../service/CardForm';
import PayfixWithdrawService, { PayfixWithdrawState } from '../../service/PayfixWithdraw';
import WithdrawalButton from '../../WithdrawalButton/WithdrawalButton';
import WithdrawService from '../../../service/Withdrawal';
import Attention from '../../../../../../../assets/svg/attention.svg';

import './Card.scss';


export type BankTRY = {
    id: number;
    name: string;
    img?: string;
};

type CardProps = {
    paymentMethod: PaymentMethod;
};
type CardState = {
    value: string;
    tip: PayfixInputTip;
    errorLetters: PayfixErrorTip,
    error: string,
};

class Card extends RxComponent<CardProps, CardState> {
    state = {
        value: '',
        tip: PayfixInputTip.NONE,
        errorLetters: PayfixErrorTip.NONE,
        error: '',
    };

    handler = {
        getValue: this.getValue.bind(this),
        onTip: this.onTip.bind(this),
        onErrorLetters: this.errorLetters.bind(this),
        onError: this.onError.bind(this),
    };

    onTip(tip: PayfixInputTip) {
        this.setState({ tip });

    };

    errorLetters(errorLetters: PayfixErrorTip) {
        this.setState({ errorLetters });
    }

    onError(error: string) {
        this.setState({ error });
    }

    getValue(value: string) {
        this.setState({ value });
    }

    componentDidMount() {
        const { paymentMethod } = this.props;

        CardFormService.setPaymentMethod(paymentMethod);
        this.subscribe(CardFormService.amount.value, { next: this.handler.getValue });
        this.subscribe(CardFormService.tip, { next: this.handler.onTip });
        this.subscribe(CardFormService.errorLetters, { next: this.handler.onErrorLetters });
        this.subscribe(CardFormService.error, { next: this.handler.onError });
    }

    render() {
        const { paymentMethod } = this.props;
        const { value, tip, errorLetters, error } = this.state;

        const status = PayfixWithdrawService.state.getValue();

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.withdraw.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.withdraw.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        const fee = WithdrawService.fee.getValue();

        const isInit = status === PayfixWithdrawState.CARD;
        if (fee && isInit) {
            PayfixWithdrawService.state.next(PayfixWithdrawState.HAS_FEE)
        };

        const minFee = Math.ceil(paymentMethod.limit.withdraw.min * 1.25)
        const amountFee = formatCurrencySign(minFee, 0, paymentMethod.currency);
        const allFeeText = `${amountFee} ${t('popup.payment.min.fee')}`;

        return (
            <div className="card-withdrawal-payfix">
                <div className="card-withdrawal-payfix-header">
                    <img src={paymentMethod.icon} alt={paymentMethod.slug} />
                    <h2 className="card-withdrawal-payfix-header__title">{t(paymentMethod.title)}</h2>
                </div>
                <form className="card-withdrawal-payfix-form" onSubmit={(e) => CardFormService.onSubmit(e)}>
                    <div className="card-withdrawal-payfix-form-block">
                        <Input
                            className="card-withdrawal-payfix-form-block__input"
                            inputService={CardFormService.amount}
                            name="amount"
                            type="number"
                            hint={hint}
                            size={InputSize.medium}
                        />
                        <span className="card-withdrawal-payfix-form-block__input_currency">{paymentMethod.currency.sign}</span>
                        {!!error ? (
                            <div className="card-withdrawal-payfix-form-block__error">
                                <img src={Attention} alt="attention"/>
                                <span
                                    className="card-withdrawal-payfix-form-block__error_text">
                                {allFeeText}
                            </span>
                            </div>
                        ) : null}
                    </div>

                    <div className="card-withdrawal-payfix-form-block">
                        <Input
                            className="card-withdrawal-payfix-form-block__input"
                            inputService={CardFormService.name}
                            name="name"
                            size={InputSize.medium}
                        />
                        {tip === PayfixInputTip.NAME && (
                            <span className="card-withdrawal-payfix-form__help">
                                {t('popup.payment.name.hint')}
                            </span>
                        )}
                        {errorLetters === PayfixErrorTip.NAME ?
                            <div className="card-withdrawal-payfix-form__letters">
                                <div>{t('popup.payment.turkey.error.letters')}</div>
                            </div>
                             : null}
                    </div>
                    <div className="card-withdrawal-payfix-form-block">
                        <Input
                            className="card-withdrawal-payfix-form-block__input"
                            inputService={CardFormService.surname}
                            name="surname"
                            size={InputSize.medium}
                        />
                        {tip === PayfixInputTip.SURNAME && (
                            <span className="card-withdrawal-payfix-form__help">
                                {t('popup.payment.name.hint')}
                            </span>
                        )}
                        {errorLetters === PayfixErrorTip.SURNAME ?  <div className="card-withdrawal-payfix-form__letters">
                            <span className="card-withdrawal-payfix-form__letters_text">{t('popup.payment.turkey.error.letters')}</span>
                        </div> : null}
                    </div>
                    <div className="card-withdrawal-payfix-form-block">
                        <MaskedInput
                            className="card-withdrawal-payfix-form-block__input"
                            inputService={CardFormService.accountName}
                            name="accountName"
                            size={InputSize.medium}
                            mask="0000000000"
                        />
                        {tip === PayfixInputTip.ACCOUNT_NUMBER_PAYFIX && (
                            <span className="card-withdrawal-payfix-form__help">
                                {t('profile.balance.withdraw.input.help.account.payfix')}
                            </span>
                        )}
                    </div>
                    {(status === PayfixWithdrawState.HAS_FEE || status === PayfixWithdrawState.AWAIT) && fee ? <FeeNotice amount={value} fee={fee} />
                    : null}
                    {status === PayfixWithdrawState.HAS_FEE || status === PayfixWithdrawState.AWAIT ? (
                        <>
                            <WithdrawalButton
                                buttonTitle="profile.balance.withdraw.fee.agree" variation={true}
                                paymentMethod={paymentMethod}
                                amount={value}
                            />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel" />
                        </>
                    ) : (
                        <WithdrawalButton buttonTitle="profile.balance.withdraw.button" />
                    )}
                </form>
            </div>
        );
    }
}

export default Card;
