import { add, format, isBefore, parse } from 'date-fns';

type TrackDataRecord = {
    clickId: string | null;
    date: string;
    utmSource: string | null;
    utmMedium: string | null;
    utmCampaign: string | null;
};

class TrackData {
    static DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';

    clickId: string | null;

    date: Date;

    utmSource: string | null;

    utmMedium: string | null;

    utmCampaign: string | null;

    constructor(data: TrackDataRecord) {
        this.clickId = data.clickId;
        this.date = parse(data.date, TrackData.DATE_FORMAT, new Date());
        this.utmSource = data.utmSource;
        this.utmMedium = data.utmMedium;
        this.utmCampaign = data.utmCampaign;
    }

    serialize(): TrackDataRecord {
        return {
            clickId: this.clickId,
            date: format(this.date, TrackData.DATE_FORMAT),
            utmSource: this.utmSource,
            utmMedium: this.utmMedium,
            utmCampaign: this.utmCampaign,
        };
    }

    isValid() {
        const validThru = add(this.date, { months: 1 });

        return isBefore(new Date(), validThru);
    }
}

export type { TrackDataRecord };
export default TrackData;
