import { PayFixDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPayFixDepositRequest';
import { PayfixWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPayFixWithdrawal';
import { PayfixDepositCode, PayfixDepositResponse, PayfixWithdrawResponse } from '@ay_tsarbet/newbet-core/dist/types';
import PayfixDepositService from 'page/Profile/Balance/Deposit/Payfix/service/PayfixDeposit';
import { BehaviorSubject, Subject } from 'rxjs';

import PayfixInterface from '../interface/payment/Payfix';
import WSBet from '../WSBet';

enum PayfixError {
    UNKNOWN,
    DEPOSIT,
    WITHDRAW,
}

class Payfix implements PayfixInterface {
    error = new Subject<PayfixError>();

    redirect = new BehaviorSubject<string | null>(null);

    withdrawResult = new Subject<void>();

    deposit(request: PayFixDepositRequest) {
        console.log('PAYFIX | DEPOSIT REQUEST', request);
        WSBet.payment.payfix.deposit(request);
    }

    withdraw(withdraw: PayfixWithdrawalRequest) {
        console.log('PAYFIX | WITHDRAW REQUEST', withdraw);
        WSBet.payment.payfix.withdraw(withdraw);
    }

    onWithdrawReceive(data: PayfixWithdrawResponse) {
        console.log('PAYFIX | WITHDRAW RESPONSE', data);
        if (data.errorCode >= PayfixDepositCode.success) {
            this.withdrawResult.next();
        } else {
            this.error.next(PayfixError.WITHDRAW);
        }
    }

    onDepositReceive(data: PayfixDepositResponse) {
        console.log('PAYFIX | DEPOSIT RESPONSE', data);
        if (!data.code) {
            PayfixDepositService.onReceive(data.message);
        } else {
            PayfixDepositService.onError();
        }
    }
}

export { PayfixError };
export default Payfix;
