import { translate as t } from 'service/Language';

import './ListHeader.scss';

const ListHeader = () => {
    return (
        <div className="list-header">
            <div className="list-header__item">ID</div>
            <div className="list-header__item">{t('profile.balance.history.created')}</div>
            <div className="list-header__item">{t('profile.balance.history.method')}</div>
            <div className="list-header__item">{t('profile.balance.history.amount')}</div>
            <div className="list-header__item">{t('profile.balance.history.status')}</div>
            <div className="list-header__item" />
        </div>
    );
};

export default ListHeader;
