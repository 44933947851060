import classNames from 'classnames';
import { translate as t } from 'service/Language';

import useObservable from '../../../../../../../utils/useObservable';

import TransactionFromService from '../../service/TransactionForm';
import Spinner from './image/Spinner.svg';

import './Submit.scss';
import WellxPayDepositService, {DepositState, InvoiceState} from '../../service/WellxpayDeposit';

type SubmitProps = {
    className?: string;
};

function Submit(props: SubmitProps) {
    const { className } = props;
    const valid = useObservable<boolean>(TransactionFromService.valid, TransactionFromService.valid.value);
    const status = useObservable(WellxPayDepositService.state, WellxPayDepositService.state.getValue());
    const transactionStatus = useObservable(
        WellxPayDepositService.transaction,
        WellxPayDepositService.transaction.getValue()
    );
    const isDisabled = status === DepositState.WAIT_INVOICE || status === DepositState.WAIT || transactionStatus === InvoiceState.WAIT

    const _className = classNames({
        [className || '']: !!className,
        'deposit-submit': true,
        'deposit-submit__active': valid,
    });
    return (
        <button type="submit" className={_className} disabled={!valid || isDisabled}>
            {status === DepositState.WAIT ||
            status === DepositState.WAIT_INVOICE ||
            transactionStatus === InvoiceState.WAIT ? (
                <img className="deposit-submit__wait" src={Spinner} alt="" />
            ) : (
                t('profile.balance.deposit.button.next')
            )}
        </button>
    );
}

export default Submit;
