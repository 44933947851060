import classNames from 'classnames';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';
import Spinner from 'assets/svg/spinner.svg';
import {PaymentMethod} from '../../../PaymentMethod/types';
import CoinspaidWithdrawService, {CryptoWithdrawState} from '../service/CoinspaidWithdrawal';
import CryptoFormService from '../service/CryptoForm';

import './Submit.scss';


type SubmitType = {
    buttonTitle: string;
    variation?: boolean;
    paymentMethod?: PaymentMethod;
    amount?: string;
};

function Submit(props: SubmitType) {
    const { buttonTitle, variation, amount } = props;
    const valid = useObservable<boolean>(CryptoFormService.valid, false);
    const min = useObservable(CryptoFormService.minWithdrawal, CryptoFormService.minWithdrawal.getValue());
    const state = useObservable(CoinspaidWithdrawService.state, CoinspaidWithdrawService.state.getValue());

    const sumToWithdraw = amount && parseInt(amount) - parseInt(amount) * 0.2;
    const disabledFee = sumToWithdraw && min && sumToWithdraw >= Math.round(min);

    const className = classNames('withdrawal-button-easypaisa', {
        'withdrawal-button-easypaisa__active': valid || (!variation && disabledFee),
        'withdrawal-button-easypaisa__variated': variation && !disabledFee,
    });

    return (
        <button type="submit" className={className} disabled={!valid}>
            {state === CryptoWithdrawState.AWAIT ? (
                <img className="withdrawal-button-easypaisa__wait" src={Spinner} alt="" />
            ) : (
                t(buttonTitle)
            )}
        </button>
    );
}

export default Submit;
