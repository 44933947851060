import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';
import {PaymentMethod} from '../../../PaymentMethod/types';

import WithdrawService from '../../service/Withdrawal';
import { PayesWithdrawalRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPayesWithdrawal';
import { OnlyLetterService } from '../../../../../../service/Validator/OnlyLetter';
import BalanceService from '../../../../../../service/Balance';

import {CheckAzerLettersService} from '../../../../../../service/Validator/CheckAzerLetters';

import PayesWithdrawService, {PayesWithdrawState} from './PayesWithdraw';
import { AmountError, PayesInputTip, PayesLettersTip } from '../../../Deposit/Payes/service/AmountForm';
import { BankType } from '../../../../../../service/model/BankUpay';
import { formatEmanatPhone } from '../../../../../../utils/format';
import { ExpMonthFullYearService } from '../../../../../../service/Validator/ExpMonthFullYear';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import BonusService from '../../../../../../service/Bonus';
import StatusFormPopupService, {StatusForm} from '../../../Deposit/DepositPopup/service/StatusForm';

export enum PayesError{
    NONE,
    FULL_DUE,
    EXPIRED,
}

class PayesForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(1, 1000000);

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.withdraw.input.amount',
    });

    name = new InputService({
        title: 'profile.balance.deposit.input.name',
        validator: Validator.OnlyLetter,
        validatorText: 'profile.balance.deposit.input.name',
    });

    surname = new InputService({
        title: 'profile.balance.deposit.input.surname',
        validator: Validator.OnlyLetter,
        validatorText: 'profile.balance.deposit.input.surname',
    });

    phoneEmanat = new InputService({
        title: 'profile.balance.withdraw.input.phone',
        validator: Validator.phoneEmanat,
        validatorText: 'profile.balance.withdraw.input.phone',
    })

    walletNumber = new InputService({
        title: 'profile.balance.withdraw.input.wallet',
        validator: Validator.walletZoft,
        validatorText: 'profile.balance.withdraw.input.wallet',
    })

    card = new InputService({
        title: 'profile.balance.withdraw.input.card',
        validator: Validator.maskCardLunh,
        validatorText: 'profile.balance.withdraw.input.card',
    });

    expDate = new InputService({
        title: 'profile.balance.withdraw.input.duedate',
        validator: Validator.expMonthFullYear,
        validatorText: 'profile.balance.withdraw.input.duedate',
    });

    bank = new BehaviorSubject<BankType | null>(null);

    tip = new BehaviorSubject<PayesInputTip>(PayesInputTip.NONE);

    errorLetters = new BehaviorSubject<PayesLettersTip>(PayesLettersTip.NONE);

    amountLimit = new BehaviorSubject<AmountError>(AmountError.NONE);

    error = new BehaviorSubject<PayesError>(PayesError.NONE);

    autoCompleted = new BehaviorSubject<boolean>(false);

    valid = new BehaviorSubject<boolean>(false);

    balance = new BehaviorSubject(0);

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        onName: this.onName.bind(this),
        onNameActive: this.onNameActive.bind(this),
        onSurname: this.onSurname.bind(this),
        onSurnameActive: this.onSurnameActive.bind(this),
        onWallet: this.onWallet.bind(this),
        onWalletActive: this.onWalletActive.bind(this),
        onCard: this.onCard.bind(this),
        onCardActive: this.onCardActive.bind(this),
        onPhone: this.onPhone.bind(this),
        onPhoneActive: this.onPhoneActive.bind(this),
        onBalance: this.onBalance.bind(this),
        onBank: this.checkValid.bind(this),
        onExpDate: this.onExpDate.bind(this),
        onExpDateActive: this.onExpDateActive.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
        this.name.value.subscribe(this.handler.onName);
        this.name.active.subscribe(this.handler.onNameActive);
        this.surname.value.subscribe(this.handler.onSurname);
        this.surname.active.subscribe(this.handler.onSurnameActive);
        this.walletNumber.value.subscribe(this.handler.onWallet);
        this.walletNumber.active.subscribe(this.handler.onWalletActive);
        this.card.value.subscribe(this.handler.onCard);
        this.card.active.subscribe(this.handler.onCardActive);
        this.phoneEmanat.value.subscribe(this.handler.onPhone);
        this.phoneEmanat.active.subscribe(this.handler.onPhoneActive);
        this.bank.subscribe(this.handler.onBank);
        this.expDate.value.subscribe(this.handler.onExpDate);
        this.expDate.active.subscribe(this.handler.onExpDateActive);
        BalanceService.balance.subscribe(this.handler.onBalance);
    }

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        const limit = this.paymentMethod.limit.withdraw;
        const isFee = WithdrawService.fee.getValue();
        const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
        this.limitValidator.setLimit(isFee ? isMin : limit.min, limit.max);
    }

    onSelectBank(bank: BankType) {
        this.bank.next(bank);
    }

    onAmount(value: string) {
        const isValue = this.amount.value.getValue();
        if (this.paymentMethod && isValue) {
            const fee = WithdrawService.fee.getValue();
            if(fee && +value < this.paymentMethod.limit.withdraw.min * 1.25) {
                this.amountLimit.next(AmountError.AMOUNT_FEE);
                this.amount.error.next('profile.balance.withdraw.input.amount');
            }
            else {
                this.amountLimit.next(AmountError.NONE);
                this.amount.error.next(null);
            }
            if(!fee && +value < this.paymentMethod.limit.withdraw.min) {
                this.amountLimit.next(AmountError.AMOUNT_MIN);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }
            if(+value > this.paymentMethod.limit.withdraw.max) {
                this.amountLimit.next(AmountError.AMOUNT_MAX);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }
            if( this.balance.getValue() < +value) {
                this.amountLimit.next(AmountError.AMOUNT_WITHDRAWAL);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }

        } else {
            this.amount.error.next(null);
        }
        this.checkValid();
    }

    onBalance(balance: number) {
        this.balance.next(balance)
    }

    onAmountActive(active: boolean) {
        if (active) {
            this.tip.next(PayesInputTip.AMOUNT);
        } else {
            this.tip.next(PayesInputTip.NONE);
        }
    }

    onName(value: string) {
        if (this.name.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.name.value.next(name);
            this.valid.next(false);
            if (CheckAzerLettersService.validate(value)) {
                this.errorLetters.next(PayesLettersTip.NAME)
            } else {
                this.errorLetters.next(PayesLettersTip.NONE)
            }

        } else {
            this.name.error.next(null);
            this.errorLetters.next(PayesLettersTip.NONE)
        }
        this.checkValid();
    }

    onNameActive(active: boolean) {
        if (active) {
            this.tip.next(PayesInputTip.NAME);
        } else {
            this.tip.next(PayesInputTip.NONE);
        }
    }

    onSurname(value: string) {
        if (this.surname.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.surname.value.next(name);
            this.valid.next(false);
            if (CheckAzerLettersService.validate(value)) {
                this.errorLetters.next(PayesLettersTip.SURNAME)
            } else {
                this.errorLetters.next(PayesLettersTip.NONE)
            }
        } else {
            this.name.error.next(null);
            this.errorLetters.next(PayesLettersTip.NONE)
        }
        this.checkValid();
    }

    onSurnameActive(active: boolean) {
        if (active) {
            this.tip.next(PayesInputTip.SURNAME);
        } else {
            this.tip.next(PayesInputTip.NONE);
        }
    }

    onWallet(value: string) {
        this.checkValid();
    }

    onWalletActive(active: boolean) {
        if (active) {
            this.tip.next(PayesInputTip.WALLET);
        } else {
            this.tip.next(PayesInputTip.NONE);
        }
    }

    onPhone(value: string) {
        this.checkValid();
    }

    onPhoneActive(active: boolean) {
        if (active) {
            this.tip.next(PayesInputTip.PHONE);
        } else {
            this.tip.next(PayesInputTip.NONE);
        }
    }

    onCard(value: string) {
        this.checkValid();
    }

    Luhn(card: string): boolean {
        let checksum = 0;
        const cardnumbers = card.replaceAll(' ', '').split('').map(Number);
        // @ts-ignore
        for (const [index, num] of cardnumbers.entries()) {
            if (index % 2 === 0) {
                let buffer = num * 2;
                buffer > 9 ? checksum += buffer - 9 : checksum += buffer;
            }
            else {
                checksum += num;
            }
        }
        return checksum % 10 === 0;
    }

    onCardActive(active: boolean) {
        if(active) {
            this.tip.next(PayesInputTip.CARD);
        } else {
            this.tip.next(PayesInputTip.NONE);
        }
    }

    onExpDate(value: string) {
        const length = value.length;
        const expDate = this.expDate.getValue();
        if( length ) {
            this.checkValid();
            const month = value.slice(0, 2);

            if(+month[0] === 0 && +month[1] === 0) {
                const current = value.slice(0, length - 1);
                this.expDate.value.next(current);
                this.expDate.error.next('profile.balance.withdraw.input.duedate');
            }

            if(+month[0] === 1 && +month[1] > 2 ) {
                const current = value.slice(0, length - 1);
                this.expDate.value.next(current);
                this.expDate.error.next('profile.balance.withdraw.input.duedate');
            }

            if(+month[0] > 1) {
                const current = value.slice(0, length - 1);
                this.expDate.value.next(current);
                this.expDate.error.next('profile.balance.withdraw.input.duedate');
            }

            if(expDate && expDate.length < 7) {
                this.error.next(PayesError.FULL_DUE)
            }
            if(expDate.length === 7 && !ExpMonthFullYearService.checkDifference(value)) {
                this.error.next(PayesError.EXPIRED)
            }
            if(expDate.length === 7 && ExpMonthFullYearService.checkDifference(value)) {
                this.error.next(PayesError.NONE);
            }
        } else {
            this.expDate.error.next(null);
        }
    }

    onExpDateActive(active: boolean) {
        if(active) {
            this.tip.next(PayesInputTip.EXP_DATE);
        } else {
            this.tip.next(PayesInputTip.NONE);
        }
    }

    checkValid() {
        if (this.paymentMethod) {
            const isAmount = this.amount.isValid();
            const isName = this.name.isValid();
            const isSurname = this.surname.isValid();
            const isWallet = this.walletNumber.isValid();
            const isCard = this.card.isValid();
            const isExpDate = this.expDate.isValid();
            const isPhone = this.phoneEmanat.isValid();
            const isBank = this.bank.getValue() !== null;

            if(this.paymentMethod.id === ChannelType.PAYES_BANK_CARDS) {
                this.valid.next(isAmount && isName && isSurname && isCard && isExpDate && isBank);
            }
            else if(this.paymentMethod.id === ChannelType.PAYES_EMANAT) {
                this.valid.next(isAmount && isName && isSurname && isPhone);
            } else  {
                this.valid.next(isAmount && isName && isSurname && isWallet);
            }
        }
    }

    send() {
        const valid = this.valid.getValue();

        if (valid && this.paymentMethod) {
            const amount = parseInt(this.amount.getValue());
            const phoneWallet = this.paymentMethod.id === ChannelType.PAYES_EMANAT ? formatEmanatPhone(this.phoneEmanat.getValue()) : this.walletNumber.getValue().replaceAll(' ', '');
            const card = this.paymentMethod.id === ChannelType.PAYES_BANK_CARDS ? this.card.getValue().replaceAll(' ', '') :  phoneWallet;
            const bankId = this.paymentMethod.id === ChannelType.PAYES_BANK_CARDS ? this.bank.getValue()!.id : 0;
            const expMonth = this.paymentMethod.id === ChannelType.PAYES_BANK_CARDS ? this.expDate.getValue().split('/')[0] : '';
            const expYear = this.paymentMethod.id === ChannelType.PAYES_BANK_CARDS ? this.expDate.getValue().split('/')[1] : '';
            const request: PayesWithdrawalRequest = {
                currencyId: this.paymentMethod.currency.ISO,
                channelType: this.paymentMethod.id,
                name: this.name.getValue(),
                surname: this.surname.getValue(),
                card,
                expMonth,
                expYear,
                bankId,
                amount,
            }

            PaymentService.payes.withdraw(request);
            PayesWithdrawService.state.next(PayesWithdrawState.AWAIT);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const status = PayesWithdrawService.state.getValue();
        const isBonuses = BonusService.list.getValue().length;

        const fee = WithdrawService.fee.getValue();
        console.log('SUBMIT fee', fee);

        if (isBonuses) {
            PayesWithdrawService.state.next(PayesWithdrawState.BONUS_REJECT);
            StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
        } else {
            if (!!fee) {
                if (status === PayesWithdrawState.HAS_FEE) {
                    this.send();
                }
            } else {
                this.send();
            }
        }
    }

    reset() {
        this.amount.value.next('');
        this.name.value.next('');
        this.surname.value.next('');
        this.walletNumber.value.next('');
        this.card.value.next('');
        this.phoneEmanat.value.next('');
        this.bank.next(null);

        this.amount.error.next(null);
        this.name.error.next(null);
        this.surname.error.next(null);
        this.walletNumber.error.next(null);
        this.card.error.next(null);
        this.phoneEmanat.error.next(null);

        this.amount.active.next(false);
        this.name.active.next(false);
        this.surname.active.next(false);
        this.walletNumber.active.next(false);
        this.card.active.next(false);
        this.phoneEmanat.active.next(false);

        this.amount.autoCompleted.next(false);
        this.name.autoCompleted.next(false);
        this.surname.autoCompleted.next(false);
        this.walletNumber.autoCompleted.next(false);
        this.phoneEmanat.autoCompleted.next(false);
        this.amountLimit.next(AmountError.NONE);
        this.tip.next(PayesInputTip.NONE);
        this.errorLetters.next(PayesLettersTip.NONE);
    }
}

const PayesFormService = new PayesForm();

export default PayesFormService;
