import {translate as t} from '../../../../../../service/Language';
import {formatCurrencySign} from '../../../../../../utils/format';

import RxComponent from '../../../../../../component/RxComponent/RxComponent';
import {PaymentMethod} from '../../../PaymentMethod/types';
import AttentionIcon from 'assets/svg/attention.svg';
import {CoinspaidDepositRateResponse} from '@ay_tsarbet/newbet-core/dist/types';
import {AmountError} from '../../../Deposit/Directa24/Pix/service/AmountForm';
import BalanceService from '../../../../../../service/Balance';
import WithdrawService from '../../service/Withdrawal';
import cryptoPaymentMethod from '../../../PaymentMethod/cryptoMethods';
import UserDataService from '../../../../../../service/UserData';
import CryptoFormService, {CryptoTip} from '../service/CryptoForm';
import CoinspaidWithdrawService, {CryptoWithdrawState} from '../service/CoinspaidWithdrawal';
import Attention from '../../../Deposit/Apay/Attention/Attention';
import Input from '../../../../../../component/Input/Input';
import MaskedInput from '../../../../../../component/Input/MaskedInput';
import FeeNotice from '../../FeeNotice/FeeNotice';
import Submit from '../Submit/Submit';
import WithdrawCloseButton from '../../WithdrawCloseButton/WithdrawCloseButton';

import './Crypto.scss';


type CryptoProps = {
    paymentMethod: PaymentMethod;
    status: CryptoWithdrawState;
    data: CoinspaidDepositRateResponse;
};
type CryptoState = {};

class Crypto extends RxComponent<CryptoProps, CryptoState> {
    state = {
        value: '',
        tip: CryptoTip.NOERROR,
        amountLimit: AmountError.NONE,
        errorAmount: null,
        errorTag: null,
    };

    handler = {
        getValue: this.getValue.bind(this),
        onTip: this.onTip.bind(this),
        onAmountLimit: this.onAmountLimit.bind(this),
        onErrorAmount: this.onErrorAmount.bind(this),
        onErrorTag: this.onErrorTag.bind(this),
    };

    onErrorAmount(errorAmount: string | null) {
        this.setState({errorAmount});
    }

    onErrorTag(errorTag: string | null) {
        this.setState({errorTag});
    }

    onTip(tip: CryptoTip) {
        this.setState({ tip });
    }

    onAmountLimit(amountLimit: AmountError) {
        this.setState({amountLimit});
    }

    getValue(value: string) {
        this.setState({value});
    }

    componentDidMount() {
        this.subscribe(CryptoFormService.tip, {next: this.handler.onTip});
        this.subscribe(CryptoFormService.amount.value, {next: this.handler.getValue});
        this.subscribe(CryptoFormService.amount.error, {next: this.handler.onErrorAmount});
        this.subscribe(CryptoFormService.amountLimit, {next: this.handler.onAmountLimit});
        this.subscribe(CryptoFormService.tag.error, {next: this.handler.onErrorTag});
    }

    render() {
        const {paymentMethod, status, data} = this.props;
        const { errorTag, errorAmount, amountLimit, tip, value} = this.state;

        const balance = BalanceService.balance.getValue();
        const userData = UserDataService.data.getValue();
        const formatedBalance = userData ? formatCurrencySign(+balance, 2, userData.currency) : ''

        const roundWith = Math.ceil(data.minWithdraw);
        const minWithdraw = data && `${roundWith} ${paymentMethod.currency.name}`;

        const roundWithRate = (data.withdrawRate).toFixed(2);
        const showWithdrawalRate = data && `1 ${paymentMethod.slug} / ${roundWithRate} ${paymentMethod.currency.name}`
        const fee = WithdrawService.fee.getValue();

        const isInit = status === CryptoWithdrawState.INIT;
        if (fee && isInit) {
            CoinspaidWithdrawService.state.next(CryptoWithdrawState.HAS_FEE)
        }

        const isMin = roundWith ? Math.ceil(roundWith * 1.25) : 0;
        const hintMin = fee ? isMin : roundWith

        const min = formatCurrencySign(hintMin, 0, paymentMethod.currency);
        const hint = `${t('popup.payment.amount.min')} ${min}`;
        const allFeeText = `${min} ${t('popup.payment.min.fee')}`;

        return (
            <div className="withdrawal-crypto">
                <div className="withdrawal-crypto-header">
                    <img src={paymentMethod.icon} alt={paymentMethod.slug}/>
                    <h2 className="withdrawal-crypto-header__title">{t(paymentMethod.title)}</h2>
                </div>
                <div className="withdrawal-crypto-rate">
                    <span className="withdrawal-crypto-rate__title">{t('profile.balance.header.available')}</span>
                    <span className="withdrawal-crypto-rate__value">{formatedBalance}</span>
                </div>
                <div className="withdrawal-crypto-rate">
                    <span className="withdrawal-crypto-rate__title">{t('popup.payment.crypto.wdr.min')}</span>
                    <span className="withdrawal-crypto-rate__value">{minWithdraw}</span>
                </div>
                <div className="withdrawal-crypto-rate">
                    <span className="withdrawal-crypto-rate__title">{t('popup.payment.crypto.rate')}</span>
                    <span className="withdrawal-crypto-rate__value">{showWithdrawalRate}</span>
                </div>
                <form className="withdrawal-crypto-form" onSubmit={(e) => CryptoFormService.onSubmit(e)}>

                    <div className="withdrawal-crypto-form-block">
                        <Input
                            className="withdrawal-crypto-form-block__input"
                            inputService={CryptoFormService.amount}
                            name="amount"
                            type="number"
                        />
                        <span className="withdrawal-crypto-form-block__currency">{paymentMethod.currency.name}</span>
                        {!!errorAmount ? (
                            <div className="imps-withdrawal-form-block__error">
                                <img src={AttentionIcon} alt="attention"/>
                                <span className="imps-withdrawal-form-block__error_text">
                                    {amountLimit && amountLimit === AmountError.AMOUNT_MIN ? t('popup.payment.amount.min') + ` ${minWithdraw}` : null}
                                    {fee && amountLimit === AmountError.AMOUNT_FEE ? allFeeText : null}
                                    {amountLimit && amountLimit === AmountError.AMOUNT_WITHDRAWAL ? t('popup.payment.amount.withdrawal.max') : null}
                                    </span>
                            </div>
                        ) : null}
                        {tip === CryptoTip.AMOUNT && !errorAmount ? (
                            <span className="imps-withdrawal-form-block__tip">
                                {hint}
                            </span>
                        ) : null}
                    </div>
                    <div className="withdrawal-crypto-form-block">
                        <Input
                            className="withdrawal-crypto-form-block__input"
                            inputService={CryptoFormService.wallet}
                            name="wallet"
                        />
                        {tip === CryptoTip.WALLET && (
                            <span className="withdrawal-crypto-form-block__tip">
                                {t('popup.payment.crypto.wallet.hint')}
                            </span>
                        )}
                    </div>
                    {(paymentMethod.id === cryptoPaymentMethod.XRP.id || paymentMethod.id === cryptoPaymentMethod.BNB.id) && <div className="withdrawal-crypto-form-block">
                        <MaskedInput
                            className="withdrawal-crypto-form-block__input"
                            mask="000000000"
                            inputService={CryptoFormService.tag}
                            name="tag"
                        />
                        {tip === CryptoTip.TAG && !errorTag && (
                            <span className="withdrawal-crypto-form-block__tip">
                                {t('popup.payment.crypto.tag.hint')}
                            </span>
                        )}
                        {!!errorTag ? (
                            <div className="withdrawal-crypto-form-block__error">
                                <img src={AttentionIcon} alt="attention"/>
                                <span className="withdrawal-crypto-form-block__error_text">
                                    {t('popup.payment.crypto.tag.error')}
                                    </span>
                            </div>
                        ) : null}
                    </div>}
                    <div className="withdrawal-crypto-check">
                        <Attention title="popup.payment.crypto.withdrawal.commission" type="info"/>
                    </div>
                    {((status === CryptoWithdrawState.HAS_FEE || status === CryptoWithdrawState.AWAIT) && fee) ?
                        <FeeNotice amount={value} fee={fee}/> : null}

                    {(status === CryptoWithdrawState.HAS_FEE || status === CryptoWithdrawState.AWAIT) ? (
                        <>
                            <Submit
                                buttonTitle="profile.balance.withdraw.fee.agree"
                                variation={true}
                                paymentMethod={paymentMethod}
                                amount={value}
                            />
                            <WithdrawCloseButton title="profile.balance.withdraw.fee.cancel"/>
                        </>
                    ) : (
                        <Submit buttonTitle="profile.balance.withdraw.button" />
                    )}

                </form>
            </div>
        );
    }
}

export default Crypto;
