import { translate as t } from 'service/Language';

import InfoBlock from '../InfoBlock/InfoBlock';
import PartnersLogo from './PartnesrLogo/PartnersLogo';

import './AviatorElements.scss';

function AviatorElements() {
    return (
        <>
            <div className="aviator-elements__back" />
            <div className="aviator-elements__back-center" />
            <div className="aviator-elements__logo" />
            <div className="aviator-elements__aviator" />
            <div className="aviator-elements__cloud" />
            <PartnersLogo />
            <div className="aviator-elements__plane" />
            <div className="aviator-elements__ribbon" />
            <img className="aviator-elements__text" src={t('wheel.aviator.ribbon.old.text')} alt="img" />
            <InfoBlock />
        </>
    );
}

export default AviatorElements;
