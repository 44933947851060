import Country from './Country';
import Currency from './Currency';

class GeoData {
    country: Country;

    currencyList: Currency[];

    constructor(currencyList: Currency[], country: Country) {
        this.country = country;
        this.currencyList = currencyList;
    }
}

export default GeoData;
