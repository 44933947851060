import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import InstapayWithdraw from 'service/model/payment/InstapayWithdraw';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';

import { BankTRY, defaultBank } from '../../../../../../service/model/BankTRY';
import { OnlyLetterService } from '../../../../../../service/Validator/OnlyLetter';
import { InstapayErrorTip, InstapayInputTip } from '../../../Deposit/Instapay/service/AmountForm';
import { PaymentMethod } from '../../../PaymentMethod/types';
import WithdrawService from '../../service/Withdrawal';
import InstapayWithdrawService, { InstapayWithdrawState } from './InstapayWithdraw';
import { CheckTurkeyLettersService } from '../../../../../../service/Validator/CheckTurkeyLetters';
import BonusService from '../../../../../../service/Bonus';
import StatusFormPopupService, {StatusForm} from '../../../Deposit/DepositPopup/service/StatusForm';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';

class CardForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(0, 1);

    handler = {
        onName: this.onName.bind(this),
        onNameActive: this.onNameActive.bind(this),
        onSurname: this.onSurname.bind(this),
        onSurnameActive: this.onSurnameActive.bind(this),
        onAccount: this.onAccount.bind(this),
        onAccountActive: this.onAccountActive.bind(this),
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        onBranch: this.onBranch.bind(this),
        onBranchActive: this.onBranchActive.bind(this),
        onDueDateActive: this.onDueDateActive.bind(this),
        onDueDate: this.onDueDate.bind(this),
        onTcActive: this.onTcActive.bind(this),
        onIbanActive: this.onIbanActive.bind(this),
        onIban: this.onIban.bind(this),
        onPhone: this.onPhone.bind(this),
        onPhoneActive: this.onPhoneActive.bind(this),
        valid: this.checkValid.bind(this),
        active: this.checkValid.bind(this),
    };

    constructor() {
        this.accountNumber.value.subscribe(this.handler.onAccount);
        this.accountNumber.active.subscribe(this.handler.onAccountActive);
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
        this.paparaNumber.value.subscribe(this.handler.onAccount);
        this.paparaNumber.active.subscribe(this.handler.onAccountActive);
        this.name.value.subscribe(this.handler.onName);
        this.name.active.subscribe(this.handler.onNameActive);
        this.surname.value.subscribe(this.handler.onSurname);
        this.surname.active.subscribe(this.handler.onSurnameActive);
        this.phoneCode.value.subscribe(this.handler.valid);
        this.phone.value.subscribe(this.handler.onPhone);
        this.phone.active.subscribe(this.handler.onPhoneActive);
        this.bankName.value.subscribe(this.handler.valid);
        this.branchCode.value.subscribe(this.handler.onBranch);
        this.branchCode.active.subscribe(this.handler.onBranchActive);
        this.tcNumber.value.subscribe(this.handler.valid);
        this.tcNumber.active.subscribe(this.handler.onTcActive);
        this.dueDate.value.subscribe(this.handler.onDueDate);
        this.dueDate.active.subscribe(this.handler.onDueDateActive);
        this.iban.value.subscribe(this.handler.onIban);
        this.iban.active.subscribe(this.handler.onIbanActive);
        this.accountMefete.value.subscribe(this.handler.onAccount);
        this.accountMefete.active.subscribe(this.handler.onAccountActive);
        this.accountHavale.value.subscribe(this.handler.onAccount);
        this.accountHavale.active.subscribe(this.handler.onAccountActive);
        this.accountCepbank.value.subscribe(this.handler.onAccount);
        this.accountCepbank.active.subscribe(this.handler.onAccountActive);
    }

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.withdraw.input.amount',
    });

    name = new InputService({
        title: 'profile.balance.deposit.input.name',
        validator: Validator.OnlyLetter,
        validatorText: 'profile.balance.deposit.input.name',
    });

    surname = new InputService({
        title: 'profile.balance.deposit.input.surname',
        validator: Validator.OnlyLetter,
        validatorText: 'profile.balance.deposit.input.surname',
    });

    paparaNumber = new InputService({
        title: 'profile.balance.withdraw.pocket',
        validator: Validator.accountPapara,
        validatorText: 'profile.balance.withdraw.pocket',
    });

    phone = new InputService({
        title: 'profile.balance.deposit.input.phone',
        validator: Validator.phoneHavaleVip,
        validatorText: 'profile.balance.deposit.input.phone',
    });

    phoneCode = new InputService({
        title: 'profile.balance.deposit.input.phoneCode',
        validator: Validator.nonEmpty,
        validatorText: 'profile.balance.deposit.input.phoneCode',
    });

    bankName = new InputService({
        title: 'profile.balance.deposit.input.bank',
        validator: Validator.nonEmpty,
        validatorText: 'profile.balance.deposit.input.bank',
    });

    branchCode = new InputService({
        title: 'profile.balance.deposit.input.office',
        validator: Validator.nonEmpty,
        validatorText: 'profile.balance.deposit.input.office',
    });
    
    accountNumber = new InputService({
        title: 'profile.balance.deposit.input.account.number',
        validator: Validator.nonEmpty,
        validatorText: 'profile.balance.deposit.input.account.number',
    });

    accountMefete = new InputService({
        title: 'profile.balance.deposit.input.account.number',
        validator: Validator.accountMefete,
        validatorText: 'profile.balance.deposit.input.account.number',
    });

    accountHavale = new InputService({
        title: 'profile.balance.deposit.input.account.number',
        validator: Validator.accountHavale,
        validatorText: 'profile.balance.deposit.input.account.number',
    });

    accountCepbank = new InputService({
        title: 'profile.balance.deposit.input.account.number',
        validator: Validator.accountCepbank,
        validatorText: 'profile.balance.deposit.input.account.number',
    });

    iban = new InputService({
        title: 'profile.balance.deposit.input.iban',
        validator: Validator.turkeyIban,
        validatorText: 'profile.balance.deposit.input.iban',
    });

    tcNumber = new InputService({
        title: 'profile.balance.deposit.input.number.account',
        validator: Validator.nonEmpty,
        validatorText: 'profile.balance.deposit.input.number.account',
    });

    dueDate = new InputService({
        title: 'profile.balance.withdraw.input.duedate',
        validator: Validator.dueDate,
        validatorText: 'profile.balance.withdraw.input.duedate',
    });

    bank = new BehaviorSubject<BankTRY>(defaultBank);

    valid = new BehaviorSubject<boolean>(false);

    tip = new BehaviorSubject<InstapayInputTip>(InstapayInputTip.NONE);

    errorLetters = new BehaviorSubject<InstapayErrorTip>(InstapayErrorTip.NONE);

    error = new BehaviorSubject<string>('');

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        const limit = this.paymentMethod.limit.withdraw;
        const isFee = WithdrawService.fee.getValue();
        const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
        this.limitValidator.setLimit(isFee ? isMin : limit.min, limit.max);
    }

    onSelectBank(bank: BankTRY) {
        this.bank.next(bank);
    }

    onName(value: string) {
        if (this.name.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.name.value.next(name);
            this.valid.next(false);
            if (CheckTurkeyLettersService.validate(value)) {
                this.errorLetters.next(InstapayErrorTip.NAME)
            } else {
                this.errorLetters.next(InstapayErrorTip.NONE)
            }

        } else {
            this.name.error.next(null);
            this.errorLetters.next(InstapayErrorTip.NONE)
        }
        this.checkValid();
    }

    onNameActive(active: boolean) {
        if(active) {
            if(this.paymentMethod?.id === ChannelType.TRY_PAPARA) {
                this.tip.next(InstapayInputTip.PAPARA_NAME);
            } else {
                this.tip.next(InstapayInputTip.NAME);
            }
        } else {
            this.tip.next(InstapayInputTip.NONE);
        }
    }

    onSurname(value: string) {
        if (this.surname.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.surname.value.next(name);
            this.valid.next(false);
            if (CheckTurkeyLettersService.validate(value)) {
                this.errorLetters.next(InstapayErrorTip.SURNAME)
            } else {
                this.errorLetters.next(InstapayErrorTip.NONE)
            }
        } else {
            this.name.error.next(null);
            this.errorLetters.next(InstapayErrorTip.NONE)
        }
        this.checkValid()
    }

    onSurnameActive(active: boolean) {
        if(active) {
            if(this.paymentMethod?.id === ChannelType.TRY_PAPARA) {
                this.tip.next(InstapayInputTip.PAPARA_SURNAME);
            } else {
                this.tip.next(InstapayInputTip.SURNAME);
            }
        } else {
            this.tip.next(InstapayInputTip.NONE);
        }
    }

    onAccount(value: string) {
        this.checkValid();
        const havaleEXP = this.paymentMethod?.id === ChannelType.TRY_FAST_BANK && value.length < 8;
        const havaleVIP = this.paymentMethod?.id === ChannelType.TRY_BANK && value.length < 8;
        const cep = this.paymentMethod?.id === ChannelType.TRY_CEP_BANK && value.length < 7;
        const mefete = this.paymentMethod?.id === ChannelType.TRY_MFT && value.length < 5;
        const papara = this.paymentMethod?.id === ChannelType.TRY_PAPARA && value.length < 11;
        if (value) {
            if (mefete) {
                this.accountNumber.error.next('profile.balance.deposit.input.account.number')
                this.valid.next(false);
            } else {
                this.accountNumber.error.next(null);
            }
            if (havaleEXP) {
                this.accountNumber.error.next('profile.balance.deposit.input.account.number')
                this.valid.next(false);
            } else {
                this.accountNumber.error.next(null);
            }
            if (havaleVIP) {
                this.accountNumber.error.next('profile.balance.deposit.input.account.number')
                this.valid.next(false);
            } else {
                this.accountNumber.error.next(null);
            }
            if (cep) {
                this.accountNumber.error.next('profile.balance.deposit.input.account.number')
                this.valid.next(false);
            } else {
                this.accountNumber.error.next(null);
            }
            if (papara) {
                this.accountNumber.error.next('profile.balance.deposit.input.account.number')
                this.valid.next(false);
            } else {
                this.accountNumber.error.next(null);
            }
        }
    }

    onAccountActive(isActive: boolean) {
        if (isActive) {
            if(this.paymentMethod?.id === ChannelType.TRY_CEP_BANK) {
                this.tip.next(InstapayInputTip.ACCOUNT_NUMBER_CEP);
            }
            if(this.paymentMethod?.id === ChannelType.TRY_BANK || this.paymentMethod?.id === ChannelType.TRY_FAST_BANK) {
                this.tip.next(InstapayInputTip.ACCOUNT_NUMBER_HAVALE_EXP);
            }
            if(this.paymentMethod?.id === ChannelType.TRY_MFT) {
                this.tip.next(InstapayInputTip.ACCOUNT_NUMBER_MEFETE);
            }
            if(this.paymentMethod?.id === ChannelType.TRY_PAPARA) {
                this.tip.next(InstapayInputTip.PAPARA_NUMBER);
            }
        } else {
            this.tip.next(InstapayInputTip.NONE);
        }
    }

    onBranchActive(isActive: boolean) {
        if (isActive) {
            this.tip.next(InstapayInputTip.BRANCH);
        } else {
            this.tip.next(InstapayInputTip.NONE);
        }
    }

    onIbanActive(isActive: boolean) {
        if (isActive) {
            this.tip.next(InstapayInputTip.IBAN);
        } else {
            this.tip.next(InstapayInputTip.NONE);
        }
    }

    onTcActive(isActive: boolean) {
        if (isActive) {
            this.tip.next(InstapayInputTip.TC_NUMBER);
        } else {
            this.tip.next(InstapayInputTip.NONE);
        }
    }

    onDueDate(value: string) {
        if (value.length) {
            this.checkDate(value);
            this.checkValid();
        } else {
            this.dueDate.error.next(null);
            this.tip.next(InstapayInputTip.NONE);
        }
    }

    checkDate(value: string) {
        if (+value[5] > 1 || +value[6] > 2) {
            const current = value.length;
            const dueDate = value.slice(0, current - 1);
            this.dueDate.value.next(dueDate);
        } else if (+value[8] > 3 || +value[8] + +value[9] > 31) {
            const current = value.length;
            const dueDate = value.slice(0, current - 1);
            this.dueDate.value.next(dueDate);
        }
    }

    onDueDateActive(isActive: boolean) {
        if (isActive) {
            this.tip.next(InstapayInputTip.DUE_DATE);
        } else {
            this.tip.next(InstapayInputTip.NONE);
        }
    }

    onBranch(value: string) {
        this.checkValid();
        if (value && value.length > 30) {
            this.tip.next(InstapayInputTip.BRANCH);
            this.valid.next(false);
        } else {
            this.tip.next(InstapayInputTip.NONE);
        }
    }

    onIban(value: string) {
        this.checkValid();
        if (value && value.length !== 26) {
            this.valid.next(false);
        }
    }

    onPhone(value: string) {
        value && this.checkValid();
    }

    onPhoneActive(isActive: boolean) {
        if (isActive) {
            this.tip.next(InstapayInputTip.PHONE);
        } else {
            this.tip.next(InstapayInputTip.NONE);
        }
    }

    onAmount(value: string) {
        const isValue = this.amount.value.getValue();
        if (this.paymentMethod && isValue) {
            if(!value.indexOf('0', 0)) {
                const current = value.length;
                const done = value.slice(0, current - 1);
                this.amount.value.next(done);
            }
            const isFee = WithdrawService.fee.getValue();
            const isMin = this.paymentMethod.limit.withdraw.min * 1.25;
            if(isFee && +isValue < isMin) {
                this.amount.error.next('profile.balance.withdraw.input.amount');
                this.error.next( 'popup.payment.min.fee')
            } else {
                this.amount.error.next(null);
                this.error.next('');
                this.checkValid();
            }
        } else {
            this.amount.error.next(null);
            this.error.next('');
            this.checkValid();
        }
    }

    onAmountActive(active: boolean) {
        this.tip.next(InstapayInputTip.NONE);
    }

    checkValid() {
            if (this.paymentMethod?.id === ChannelType.TRY_PAPARA) {
                this.validationPapara();
            }
            if (this.paymentMethod?.id === ChannelType.TRY_BANK) {
                this.validationHavaleVIP();
            }
            if (this.paymentMethod?.id === ChannelType.TRY_MFT) {
                this.validationMefete();
            }
            if (this.paymentMethod?.id === ChannelType.TRY_FAST_BANK) {
                this.validationExpressHavale();
            }
            if (this.paymentMethod?.id === ChannelType.TRY_CEP_BANK) {
                this.validationCepBank();
            }
    }

    validationPapara() {
        const valid =
            this.paparaNumber.isValid() && this.name.isValid() && this.surname.isValid() && this.amount.isValid();
        if (valid) {
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    validationHavaleVIP() {
        const valid =
            this.name.isValid() &&
            this.surname.isValid() &&
            this.amount.isValid() &&
            this.branchCode.isValid() &&
            this.phoneCode.isValid() &&
            this.phone.isValid() &&
            this.accountHavale.isValid() &&
            this.iban.isValid();

        if (valid) {
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    validationMefete() {
        const valid =
            this.name.isValid() && this.surname.isValid() && this.accountMefete.isValid() && this.amount.isValid();
        if (valid) {
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    validationExpressHavale() {
        const valid =
            this.accountHavale.isValid() &&
            this.name.isValid() &&
            this.surname.isValid() &&
            this.amount.isValid() &&
            this.tcNumber.isValid() &&
            this.branchCode.isValid() &&
            this.dueDate.isValid() &&
            this.iban.isValid();
        if (valid) {
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    validationCepBank() {
        const valid =
            this.accountCepbank.isValid() &&
            this.name.isValid() &&
            this.surname.isValid() &&
            this.amount.isValid() &&
            this.branchCode.isValid() &&
            this.iban.isValid();
        if (valid) {
            this.valid.next(true);
        } else {
            this.valid.next(false);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();
        const status = InstapayWithdrawService.state.getValue();
        const fee = WithdrawService.fee.getValue();
        const isBonuses = BonusService.list.getValue().length;

        if (isBonuses) {
            InstapayWithdrawService.state.next(InstapayWithdrawState.BONUS_REJECT);
            StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
        } else {
            if (!!fee) {
                if (status === InstapayWithdrawState.HAS_FEE) {
                    this.send();
                }
            } else {
                this.send();
            }
        }
    }

    getAccount() {
        const havale = this.paymentMethod?.id === ChannelType.TRY_FAST_BANK || this.paymentMethod?.id === ChannelType.TRY_BANK;
        const cep = this.paymentMethod?.id === ChannelType.TRY_CEP_BANK;
        const mefete = this.paymentMethod?.id === ChannelType.TRY_MFT;
        if(havale) {
            return this.accountHavale.getValue();
        }
        if(cep) {
            return this.accountCepbank.getValue();
        }
        if(mefete) {
            return this.accountMefete.getValue();
        } else {
            return this.accountNumber.getValue();
        }
    }

    send() {
        const phone = this.phone.getValue ? this.phoneCode.getValue() + this.phone.getValue() : '';
        const valid = this.valid.getValue();
        if (valid && this.paymentMethod) {
            const amount = parseInt(this.amount.getValue());
            const bankName = (this.paymentMethod.id === ChannelType.TRY_BANK || this.paymentMethod.id === ChannelType.TRY_FAST_BANK) ? this.bank.getValue().sendName : this.bank.getValue().name;

            const data = {
                currencyId: this.paymentMethod.currency.ISO,
                channelType: this.paymentMethod.id,
                amount,
                name: this.name.getValue(),
                surname: this.surname.getValue(),
                tcNumber: '',
                paparaNumber: this.paparaNumber.getValue(),
                phone,
                iban: this.iban.getValue(),
                bankName: bankName,
                branchCode: this.branchCode.getValue(),
                accountNumber: this.getAccount(),
                bankId: this.bank.getValue().id,
                dueDate: this.dueDate.getValue(),
            };

            // @ts-ignore
            const request = new InstapayWithdraw(data);
            PaymentService.instapay.withdraw(request);
            InstapayWithdrawService.state.next(InstapayWithdrawState.AWAIT);
        }
    }

    reset() {
        this.amount.value.next('');
        this.name.value.next('');
        this.surname.value.next('');
        this.paparaNumber.value.next('');
        this.phone.value.next('');
        this.bankName.value.next('');
        this.branchCode.value.next('');
        this.accountNumber.value.next('');
        this.iban.value.next('');
        this.tcNumber.value.next('');
        this.dueDate.value.next('');
        this.accountMefete.value.next('');
        this.accountHavale.value.next('');
        this.accountCepbank.value.next('');

        this.amount.error.next(null);
        this.name.error.next(null);
        this.surname.error.next(null);
        this.paparaNumber.error.next(null);
        this.tcNumber.error.next(null);
        this.dueDate.error.next(null);
        this.phone.error.next(null);
        this.bankName.error.next(null);
        this.branchCode.error.next(null);
        this.accountNumber.error.next(null);
        this.iban.error.next(null);
        this.accountMefete.error.next(null);
        this.accountHavale.error.next(null);
        this.accountCepbank.error.next(null);

        this.amount.active.next(false);
        this.name.active.next(false);
        this.surname.active.next(false);
        this.paparaNumber.active.next(false);
        this.tcNumber.active.next(false);
        this.dueDate.active.next(false);
        this.phone.active.next(false);
        this.bankName.active.next(false);
        this.branchCode.active.next(false);
        this.accountNumber.active.next(false);
        this.iban.active.next(false);

        this.valid.next(false);
        this.tip.next(InstapayInputTip.NONE);
        this.errorLetters.next(InstapayErrorTip.NONE);
    }
}

const CardFormService = new CardForm();

export default CardFormService;
