import { ChangeEvent, createRef } from 'react';
import Collapsible from 'react-collapsible';

import GamesService from '../../../service/Games';
import GoogleService from '../../../service/Google';
import { translate as t } from '../../../service/Language';
import GameProvider from '../../../service/model/GameProvider';
import RxComponent from '../../RxComponent/RxComponent';
import close from '../image/close.svg';
import search from '../image/search.svg';
import searchActive from '../image/search-active.svg';
import ProviderFilterService from '../service/ProviderFilter';
import SortAndFilterService from '../service/SortAndFilter';
import Alphabet from './Alphabet';

import './List.scss';

type ProviderFilterProps = {
    className?: string;
};
type ProviderFilterState = {
    open: boolean;
    active: boolean;
};

class List extends RxComponent<ProviderFilterProps, ProviderFilterState> {
    state = {
        filter: GamesService.filter.getValue(),
        open: SortAndFilterService.toggle.filter.getValue(),
        active: false,
    };

    handler = {
        setOpen: this.setOpen.bind(this),
        onSelect: this.onSelect.bind(this),
        onChange: this.onChange.bind(this),
        onFocus: this.onFocus.bind(this),
        onBlur: this.onBlur.bind(this),
        onDocumentClick: this.onDocumentClick.bind(this),
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    onChange(event: ChangeEvent<HTMLInputElement>) {
        ProviderFilterService.setInput(event.target.value);
    }

    setOpen(open: boolean) {
        this.setState({ open });
    }

    close() {
        SortAndFilterService.toggleFilter();
    }

    onDocumentClick(event: PointerEvent) {
        SortAndFilterService.onDocumentClick(event);
    }

    componentDidMount() {
        document.addEventListener('pointerdown', this.handler.onDocumentClick);
        this.subscribe(SortAndFilterService.toggle.filter, { next: this.handler.setOpen });
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        document.removeEventListener('pointerdown', this.handler.onDocumentClick);
    }

    onSelect(provider: GameProvider) {
        GamesService.setProviderFilter(provider);
    }

    onFocus() {
        this.setState({ active: true });
    }

    onBlur() {
        this.setState({ active: false });
    }

    render() {
        const { open, active } = this.state;

        return (
            <div className="provider-filter-dropdown" ref={(ref) => SortAndFilterService.setListRef(ref)}>
                <Collapsible open={open} transitionTime={200} trigger="">
                    <div className="provider-filter-dropdown__box">
                        <div className="provider-filter-dropdown__header">
                            <div className="provider-filter-dropdown__search-box">
                                <img
                                    src={active ? searchActive : search}
                                    alt=""
                                    className="provider-filter-dropdown__search"
                                />
                                <input
                                    type="text"
                                    onChange={this.handler.onChange}
                                    className="provider-filter-dropdown__input"
                                    placeholder={t('casino.providers.header')}
                                    onFocus={this.handler.onFocus}
                                    onBlur={this.handler.onBlur}
                                />
                            </div>
                            <button
                                className="provider-filter-dropdown__close"
                                onClick={() => SortAndFilterService.toggleFilter()}
                            >
                                <img src={close} alt="" />
                            </button>
                        </div>
                        <button
                            className="provider-filter-dropdown__see-all"
                            onClick={() => {
                                GamesService.setProviderFilter(null);
                            }}
                        >
                            See all
                        </button>
                        <Alphabet />
                    </div>
                </Collapsible>
            </div>
        );
    }
}

export default List;
