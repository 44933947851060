import { format } from 'date-fns';

import TrackData, { TrackDataRecord } from './model/TrackData';
import Storage from './Storage';

class UserTrack {
    static STORAGE_KEY = 'TRACK_DATA';
    STORAGE_CURRENCY_LINK_KEY = 'currencyFromLink';

    renew() {
        const prevTrackData = this.get();

        const searchParams = new URLSearchParams(window.location.search);

        const clickId = searchParams.get('clid');

        const currencyFromLink = searchParams.get('currency');

        const trackData = new TrackData({
            clickId: clickId || prevTrackData.clickId,
            date: format(clickId ? new Date() : prevTrackData.date, TrackData.DATE_FORMAT),
            utmSource: searchParams.get('utm_source') || prevTrackData.utmSource,
            utmMedium: searchParams.get('utm_medium') || prevTrackData.utmMedium,
            utmCampaign: searchParams.get('utm_campaign') || prevTrackData.utmCampaign,
        });

        Storage.set(UserTrack.STORAGE_KEY, trackData.serialize());
        Storage.set(this.STORAGE_CURRENCY_LINK_KEY, currencyFromLink);
    }

    get(): TrackData {
        let trackData = new TrackData({
            clickId: null,
            date: format(new Date(), TrackData.DATE_FORMAT),
            utmSource: null,
            utmMedium: null,
            utmCampaign: null,
        });

        try {
            const record = Storage.get(UserTrack.STORAGE_KEY) as TrackDataRecord;
            trackData = new TrackData(record);
        } catch (e) {}

        return trackData;
    }
}

const UserTrackService = new UserTrack();

export default UserTrackService;
