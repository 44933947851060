import Validator from '../interface/Validator';

class DueDateMonthYear implements Validator {
    validate(value: string): boolean {
        return this.checkLength(value) && this.checkDifference(value);
    }

    checkLength(value: string): boolean {
        return value.length === 5;
    }

    checkDifference(value: string): boolean {
        const today = new Date();
        const currentYear = today.getFullYear().toString().slice(2, 4);
        const currentMonth = +today.getMonth() + 1;

        const month = +value.slice(0, 2);
        const year = value.slice(3, 5);

        if (month && year) {
            if(year > currentYear) {
                return true
            }
            return year === currentYear && month >= currentMonth;
        } else return false;

    }
}

const DueDateMonthYearService = new DueDateMonthYear();

export { DueDateMonthYearService };
export default DueDateMonthYear;
