import { useRef } from 'react';
import useAspectRatio from 'utils/useAspectRatio';

import ImageLoader from '../../../../component/ImageLoader/ImageLoader';
import Game from '../../../../service/model/Game';

import './GamePreview.scss';

type GamePreviewProps = {
    game: Game;
};

function GamePreview(props: GamePreviewProps) {
    const { game } = props;

    const previewRef = useRef<HTMLDivElement>(null);
    useAspectRatio({ ratio: 3 / 2, element: previewRef });

    return (
        <div className="provider-card-preview-item" ref={previewRef}>
            <ImageLoader src={game.image} />
        </div>
    );
}

export default GamePreview;
