import { useEffect } from 'react';
import { translate as t } from 'service/Language';

import Input from '../../../../../component/Input/Input';
import PasswordFormService from '../service/PasswordFormService';
import OldPasswordService from './service/OldPassword';

import './OldPasswordInput.scss';

function OldPasswordInput() {
    useEffect(() => {
        const oldPassword = OldPasswordService.value.subscribe(PasswordFormService.handler.onOldPasswordChange);

        return () => {
            oldPassword.unsubscribe();
        };
    }, []);

    return (
        <div className="old-password-input">
            <span className="old-password-input__header">{t('profile.settings.password.change.current')}</span>
            <Input inputService={OldPasswordService} className="old-password-input__field" type="password" />
        </div>
    );
}

export default OldPasswordInput;
