import { NavLink } from 'react-router-dom';
import { translate as t } from 'service/Language';

import Error from './images/Error.webp';
import Logo from './images/Glory.svg';

import './ErrorPage.scss';

function ErrorPage() {
    return (
        <div className='error'>
            <NavLink to='/' className='error__header'>
                <img className='error__header__logo' src={Logo} alt='Logo' />
            </NavLink>
            <div className='error__main'>
                <img className='error__background' src={Error} alt="error" />
                <span className='error__description'>{t('error.title')}</span>
                <NavLink to='/' className='error__button-slot'>
                    {t('error.button')}
                </NavLink>
            </div>

            <div className='error__footer'>
                <noindex>{t('error.footer')}</noindex>
            </div>
        </div>
    );
}

export default ErrorPage;
