import useObservable from 'utils/useObservable';
import { useEffect } from 'react';
import { PaymentMethod } from '../../../PaymentMethod/types';
import Balance from '../../Balance/Balance';
import ResultForm from '../ResultForm/ResultForm';
import InstapayWithdrawService, { InstapayWithdrawState } from '../service/InstapayWithdraw';
import Card from './Card/Card';

type FormProps = {
    paymentMethod: PaymentMethod;
};

export type BankTRYType = {
    id: number;
    name: string;
    value: number;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(InstapayWithdrawService.state, InstapayWithdrawService.state.getValue());

    useEffect(()=> {
        InstapayWithdrawService.reset();
    },[])

    if (paymentMethod) {
        if (state === InstapayWithdrawState.CARD || state === InstapayWithdrawState.AWAIT || state === InstapayWithdrawState.HAS_FEE) {
            return (
                <>
                    <Balance />
                    <Card paymentMethod={paymentMethod} />
                </>
            );
        } else {
            return <ResultForm />;
        }
    }

    return null;
}

export default Form;
