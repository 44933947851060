import { PaymentMethod } from "../../../PaymentMethod/types";
import BKashP2C from "../BkashP2C/Form";
import TwoSteps from '../TwoSteps/Form';
import Upay from '../Upay/Form';

import {ChannelType} from '@ay_tsarbet/newbet-core/dist/types';
import {useEffect} from 'react';
import WellxPayDepositService from '../service/WellxpayDeposit';

import "./Form.scss";


type FormProps = {
  paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
  const { paymentMethod } = props;

  useEffect(()=>{
    return ()=> {
      WellxPayDepositService.reset()
    }
  },[]);

  if( paymentMethod.id === ChannelType.WELLXPAY_H2H_NAGAD || paymentMethod.id === ChannelType.WELLXPAY_H2H_BKASH || paymentMethod.id === ChannelType.WELLXPAY_H2H_ROCKET || paymentMethod.id === ChannelType.WELLXPAY_H2H_UPAY) {
    return <TwoSteps paymentMethod={paymentMethod} />
  }
  // if( paymentMethod.id === ChannelType.WELLXPAY_H2H_UPAY) {
  //   return <Upay paymentMethod={paymentMethod} />
  // }
  else return <BKashP2C paymentMethod={paymentMethod} />;
}

export default Form;
