import classNames from 'classnames';
import { createRef } from 'react';

import UIService from '../../../../service/UI';
import RxComponent from '../../../RxComponent/RxComponent';
import List from '../List/List';

import './Slider.scss';

type SliderProps = {};
type SliderState = {
    visible: boolean;
    open: boolean;
};

class Slider extends RxComponent<SliderProps, SliderState> {
    state = {
        visible: UIService.slideMenu.getValue(),
        open: false,
    };

    handler = {
        setVisible: this.setVisible.bind(this),
        clickOutside: this.clickOutside.bind(this),
        hide: this.hide.bind(this),
    };

    timer: {
        transition: number | null;
    } = {
        transition: null,
    };

    ref = {
        self: createRef<HTMLDivElement>(),
    };

    setVisible(active: boolean) {
        if (active) {
            this.stop();
            this.setState({ visible: true, open: true });
        } else {
            this.close();
        }
    }

    clickOutside(event: any) {
        if (this.ref.self.current && !this.ref.self.current.contains(event.target as Node)) {
            UIService.closeSlideMenu();
        }
    }

    componentDidMount() {
        this.subscribe(UIService.slideMenu, { next: this.handler.setVisible });
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.stop();
    }

    close() {
        this.setState({ open: false });

        this.timer.transition = window.setTimeout(() => {
            this.stop();
            this.hide();
        }, 300);
    }

    stop() {
        if (this.timer.transition) {
            clearInterval(this.timer.transition);
        }
        this.timer.transition = null;
    }

    hide() {
        this.setState({ visible: false });
    }

    render() {
        const { visible, open } = this.state;

        const menuContainerClass = classNames({
            'slide-menu': true,
            'slide-menu____visible': visible,
            'slide-menu____open': open,
        });

        return (
            <div onClick={(event) => this.handler.clickOutside(event)} className={menuContainerClass}>
                <div className="slide-menu__anti-over-scroll" ref={this.ref.self}>
                    <List className="slide-menu__slider" />
                </div>
            </div>
        );
    }
}

export default Slider;
