import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { translate as t } from 'service/Language';

import useObservable from '../../../../../../../utils/useObservable';
import AmountFormService from '../../service/AmountForm';
import PaycosDepositService, { DepositState } from '../../service/PaycosDeposit';

import './Submit.scss';

type SubmitProps = {
    className?: string;
};

function Submit(props: SubmitProps) {
    const { className } = props;
    const [valid, setValid] = useState(false);
    const state = useObservable(PaycosDepositService.state, PaycosDepositService.state.getValue());

    useEffect(() => {
        const validate = AmountFormService.amount.valid.subscribe({
            next: (isValid) => {
                setValid(isValid);
            },
        });
        return () => {
            validate.unsubscribe();
        };
    }, []);

    const _className = classNames({
        [className || '']: !!className,
        'deposit-submit': true,
        'deposit-submit__active': valid,
    });

    return (
        <button
            type="submit"
            className={_className}
            disabled={!valid || state === DepositState.WAIT}
            onClick={(e) => AmountFormService.onSubmit(e)}
        >
            {t('profile.balance.deposit.button.next')}
        </button>
    );
}

export default Submit;
