/* eslint-disable max-len */

import { Icon } from 'types';

function HeaderBigCross({ color = '#3F4250', width = 28, height = 28 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none">
            <path
                d="M16.3455 13.9986L23.5042 6.85447C23.8177 6.54089 23.9938 6.11558 23.9938 5.67211C23.9938 5.22864 23.8177 4.80333 23.5042 4.48975C23.1907 4.17617 22.7655 4 22.3222 4C21.8789 4 21.4537 4.17617 21.1402 4.48975L13.9981 11.6505L6.85601 4.48975C6.54252 4.17617 6.11733 4 5.67399 4C5.23064 4 4.80545 4.17617 4.49196 4.48975C4.17847 4.80333 4.00235 5.22864 4.00235 5.67211C4.00235 6.11558 4.17847 6.54089 4.49196 6.85447L11.6507 13.9986L4.49196 21.1427C4.33592 21.2975 4.21207 21.4817 4.12755 21.6846C4.04303 21.8875 3.99951 22.1052 3.99951 22.3251C3.99951 22.5449 4.04303 22.7626 4.12755 22.9655C4.21207 23.1684 4.33592 23.3526 4.49196 23.5074C4.64673 23.6635 4.83086 23.7874 5.03373 23.8719C5.23661 23.9565 5.45421 24 5.67399 24C5.89376 24 6.11136 23.9565 6.31424 23.8719C6.51711 23.7874 6.70124 23.6635 6.85601 23.5074L13.9981 16.3466L21.1402 23.5074C21.2949 23.6635 21.4791 23.7874 21.6819 23.8719C21.8848 23.9565 22.1024 24 22.3222 24C22.542 24 22.7596 23.9565 22.9624 23.8719C23.1653 23.7874 23.3495 23.6635 23.5042 23.5074C23.6603 23.3526 23.7841 23.1684 23.8686 22.9655C23.9532 22.7626 23.9967 22.5449 23.9967 22.3251C23.9967 22.1052 23.9532 21.8875 23.8686 21.6846C23.7841 21.4817 23.6603 21.2975 23.5042 21.1427L16.3455 13.9986Z"
                fill={color}
            />
        </svg>
    );
}

export default HeaderBigCross;
