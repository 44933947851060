import CountrySelectList from 'component/CountrySelectList';
import { translate as t } from 'service/Language';
import Close from 'component/Close/Close';
import './styles.scss';

type ModalCountrySelectProps = {};

const ModalCountrySelect = (props: ModalCountrySelectProps) => {
    const {} = props;

    return (
        <div className='ModalCountrySelect'>
            <div className='ModalCountrySelect__header'>
                <div className='CountrySelect__countries_title'>{t('casino.countries.select')}</div>
                <div className='popup-cancel__close'>
                    <Close size='s' />
                </div>
            </div>

            <CountrySelectList />
        </div>
    );
};

export default ModalCountrySelect;
