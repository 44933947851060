import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import AuthService from '../../../../../../../service/Auth';
import GoogleService from '../../../../../../../service/Google';
import Validator from '../../../../../../../service/Validator/Validator';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import Directa24DepositService from './Directa24Deposit';
import { OnlyLetterService } from '../../../../../../../service/Validator/OnlyLetter';
import { CheckBrazilLettersService } from '../../../../../../../service/Validator/CheckBrazilLetters';

export enum PixInputTip {
    NONE,
    AMOUNT,
    CPF,
    NAME,
    SURNAME,
    EMAIL,
}

export enum PixErrorTip {
    NONE,
    NAME,
    SURNAME,
}
export enum AmountError {
    NONE,
    AMOUNT_MIN,
    AMOUNT_MAX,
    AMOUNT_WITHDRAWAL,
    AMOUNT_FEE,
}

class AmountForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(1, 1000000);

    amount = new InputService({
        title: 'profile.balance.deposit.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.deposit.input.amount',
    });

    numberCPF = new InputService({
        title: 'popup.payment.pix.cpf',
        validator: Validator.cpf,
        validatorText: 'popup.payment.pix.cpf',
    });

    name = new InputService({
        title: 'popup.payment.pix.name',
        validator: Validator.OnlyLetter,
        validatorText: 'popup.payment.pix.name',
    });

    surname = new InputService({
        title: 'popup.payment.pix.surname',
        validator: Validator.OnlyLetter,
        validatorText: 'popup.payment.pix.surname',
    });

    email = new InputService({
        title: 'profile.balance.deposit.input.email',
        validator: Validator.email,
        validatorText: 'profile.balance.deposit.input.email',
    });

    errorTip = new BehaviorSubject<PixInputTip>(PixInputTip.NONE);

    errorLetters = new BehaviorSubject<PixErrorTip>(PixErrorTip.NONE);

    amountLimit = new BehaviorSubject<AmountError>(AmountError.NONE);

    valid = new BehaviorSubject<boolean>(false);

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        onNumberCPF: this.onNumberCPF.bind(this),
        onNumberCPFActive: this.onNumberCPFtActive.bind(this),
        onName: this.onName.bind(this),
        onNameActive: this.onNameActive.bind(this),
        onSurname: this.onSurname.bind(this),
        onSurnameActive: this.onSurnameActive.bind(this),
        onEmail: this.onEmail.bind(this),
        onEmailActive: this.onEmailActive.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
        this.numberCPF.value.subscribe(this.handler.onNumberCPF);
        this.numberCPF.active.subscribe(this.handler.onNumberCPFActive);
        this.name.value.subscribe(this.handler.onName);
        this.name.active.subscribe(this.handler.onNameActive);
        this.surname.value.subscribe(this.handler.onSurname);
        this.surname.active.subscribe(this.handler.onSurnameActive);
        this.email.value.subscribe(this.handler.onEmail);
        this.email.active.subscribe(this.handler.onEmailActive);
    }

    setLimit(min: number, max: number) {
        if (this.limitValidator) {
            this.limitValidator.setLimit(min, max);
        }
    }

    onAmount(value: string) {
        if (this.paymentMethod && this.amount.getValue() && !this.limitValidator.validate(value)) {
            this.valid.next(false);
          if(+value < this.paymentMethod.limit.deposit.min) {
              this.amountLimit.next(AmountError.AMOUNT_MIN);
          } else {
              this.amountLimit.next(AmountError.NONE);
          }
          if(+value > this.paymentMethod.limit.deposit.max) {
              this.amountLimit.next(AmountError.AMOUNT_MAX);
          }
        } else {
            this.amount.error.next(null);
        }
        this.checkValid();
    }

    onAmountActive(active: boolean) {
        if (active) {
            this.errorTip.next(PixInputTip.AMOUNT);
        } else {
            this.errorTip.next(PixInputTip.NONE);
        }
    }

    onNumberCPF(value: string) {
        this.checkValid();
    }

    onNumberCPFtActive(active: boolean) {
        if (active) {
            this.errorTip.next(PixInputTip.CPF);
        } else {
            this.errorTip.next(PixInputTip.NONE);
        }
    }

    onName(value: string) {
        if (this.name.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.name.value.next(name);
            this.valid.next(false);
            if (CheckBrazilLettersService.validate(value)) {
                this.errorLetters.next(PixErrorTip.NAME)
            } else {
                this.errorLetters.next(PixErrorTip.NONE)
            }

        } else {
            this.name.error.next(null);
            this.errorLetters.next(PixErrorTip.NONE)
        }
        this.checkValid();
    }

    onNameActive(active: boolean) {
        if (active) {
            this.errorTip.next(PixInputTip.NAME);
        } else {
            this.errorTip.next(PixInputTip.NONE);
        }
    }

    onSurname(value: string) {
        if (this.surname.getValue() && !OnlyLetterService.validate(value)) {
            const current = value.length;
            const name = value.slice(0, current - 1);
            this.surname.value.next(name);
            this.valid.next(false);
            if (CheckBrazilLettersService.validate(value)) {
                this.errorLetters.next(PixErrorTip.SURNAME)
            } else {
                this.errorLetters.next(PixErrorTip.NONE)
            }
        } else {
            this.name.error.next(null);
            this.errorLetters.next(PixErrorTip.NONE)
        }
        this.checkValid();
    }

    onSurnameActive(active: boolean) {
        if (active) {
            this.errorTip.next(PixInputTip.SURNAME);
        } else {
            this.errorTip.next(PixInputTip.NONE);
        }
    }

    onEmail() {
        this.checkValid();
    }

    onEmailActive(active: boolean) {
        if (active) {
            this.errorTip.next(PixInputTip.EMAIL);
        } else {
            this.errorTip.next(PixInputTip.NONE);
        }
    }

    checkValid() {
        if (this.paymentMethod) {
            const isAmount = this.amount.isValid();
            const isnumberCPF = this.numberCPF.isValid();
            const isName = this.name.isValid();
            const isSurname = this.surname.isValid();
            const isEmail = this.email.isValid();

            if (isAmount && isnumberCPF && isName && isSurname && isEmail) {
                this.valid.next(true);
            } else this.valid.next(false);
        }
    }

    setValue(value: number) {
        this.amount.onFocus();
        this.amount.value.next(value.toString());
    }

    setMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        this.setLimit(paymentMethod.limit.deposit.min, paymentMethod.limit.deposit.max);
    }

    reset() {
        this.amount.value.next('');
        this.name.value.next('');
        this.surname.value.next('');
        this.email.value.next('');
        this.numberCPF.value.next('');

        this.amount.error.next(null);
        this.name.error.next(null);
        this.surname.error.next(null);
        this.email.error.next(null);
        this.numberCPF.error.next(null);

        this.amount.active.next(false);
        this.name.active.next(false);
        this.surname.active.next(false);
        this.numberCPF.active.next(false);
        this.email.active.next(false);

        this.amount.autoCompleted.next(false);
        this.name.autoCompleted.next(false);
        this.surname.autoCompleted.next(false);
        this.numberCPF.autoCompleted.next(false);
        this.email.autoCompleted.next(false);
        this.amountLimit.next(AmountError.NONE);
        this.errorTip.next(PixInputTip.NONE);
        this.errorLetters.next(PixErrorTip.NONE);
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const valid = this.amount.isValid();

        if (valid && this.paymentMethod) {
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            const amount = parseFloat(this.amount.getValue());
            const numberCPF = this.numberCPF.getValue();
            GoogleService.sentDepositAmount(
                userId,
                this.paymentMethod.title,
                amount.toString(),
                this.paymentMethod.currency.name
            );

            Directa24DepositService.request(
                this.paymentMethod,
                this.name.getValue(),
                this.surname.getValue(),
                this.email.getValue(),
                numberCPF,
                amount,
            );
        }
    }
}

const AmountFormService = new AmountForm();

export default AmountFormService;
