import CurrencyList from './CurrencyList/CurrencyList';
import EmailWheel from './EmailWheel/EmailWheel';
import Password from './Password/Password';

import './Form.scss';

type FormProps = {
    type?: string;
};

function Form({ type }: FormProps) {
    return (
        <div className='form-wheel'>
            <EmailWheel type={type || 'aviator'} />
            <Password type={type || 'aviator'} />
            <CurrencyList type={type || 'aviator'} />
        </div>
    );
}
export default Form;
