import classNames from 'classnames';
import Collapsible from 'react-collapsible';
import { NavLink, useLocation } from 'react-router-dom';
import GoogleService from 'service/Google';
import UserDataService from 'service/UserData';
import useObservable from 'utils/useObservable';

import { ProfileNavigationData, RouteNavigationType } from '../Navigation';

import './NavigationItem.scss';

function NavigationItem({ icon, title, route }: ProfileNavigationData) {
    const location = useLocation();
    const isRouteActive = !!route.find(r => location.pathname.includes(r.route));
    const userData = useObservable(UserDataService.data, UserDataService.data.getValue());
    const containerClassName = classNames('sidebar-navigation-item__title-container', {
        active: isRouteActive,
    });
    const titleClassName = classNames('sidebar-navigation-item__title', {
        active: isRouteActive,
    });

    return (
        <div className='sidebar-navigation-item'>
            <div className={containerClassName}>
                <div className='sidebar-navigation-item__icon-frame'>
                    <img src={icon} alt='icon' />
                </div>
                <NavLink to={route[0].route} className={titleClassName}>
                    {title}
                </NavLink>
            </div>

            <Collapsible trigger='' open={isRouteActive} transitionTime={200}>
                <div className='sidebar-navigation-item__collapse-container'>
                    {route.length > 1 &&
                        route.map((item: RouteNavigationType, index: number) => {
                            const handleClick = () => {
                                if (item.title === 'bonus') {
                                    GoogleService.openBonusPageView(userData?.id.toString() || '');
                                }
                            };
                            return (
                                <NavLink
                                    to={item.route}
                                    key={index}
                                    className={classNames(
                                        'sidebar-navigation-item__collapse-title',
                                        {
                                            active: location.pathname.includes(item.route),
                                        },
                                    )}
                                    onClick={handleClick}
                                >
                                    {item.title}
                                </NavLink>
                            );
                        })}
                </div>
            </Collapsible>
        </div>
    );
}

export default NavigationItem;
