import { useEffect } from 'react';
import { translate as t } from 'service/Language';

import Footer from '../../component/Footer/Footer';
import Header from '../../component/Header/Header';
import FakeJackpotService from '../../service/FakeJackpot';
import GoogleService from '../../service/Google';
import useObservable from '../../utils/useObservable';
import CarouselUser from './CarouselUser/CarouselUser';
import Timer from './Timer/Timer';

import './FakeJackpot.scss';

function FakeJackpot() {
    useEffect(() => {
        FakeJackpotService.actions.getFakeJackpot();

        GoogleService.openJackpot();
    }, []);

    const jackpot = useObservable(FakeJackpotService.jackpot, FakeJackpotService.jackpot.getValue());

    return (
        <div className="fake-jackpot">
            <Header />
            <div className="fake-jackpot__main">
                <div className="fake-jackpot__jack">
                    <div className="fake-jackpot__amount">
                        <div className="fake-jackpot__amount__value">{jackpot.amount} BDT</div>
                    </div>
                    <div className="fake-jackpot__count">
                        <Timer />
                    </div>
                    <div className="fake-jackpot__winners">
                        <div className="fake-jackpot__header">
                            <div className="fake-jackpot__header__line" />
                            <div className="fake-jackpot__header__title">{t('jackpot.wins.header')}</div>
                            <div className="fake-jackpot__header__line" />
                        </div>
                        <CarouselUser />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default FakeJackpot;
