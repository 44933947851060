/* eslint-disable max-len */

import { Icon } from 'types';

function IncomeArrow({ color = '#2EBA4D', width = 18, height = 18 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 18 18" fill="none">
            <path
                d="M9.79129 15.1383L16.5376 8.76556C16.7303 8.58352 17.0316 8.58352 17.2243 8.76556L17.6152 9.13485C17.8241 9.33215 17.8241 9.66449 17.6152 9.86179L9.34335 17.6757C9.15064 17.8577 8.84936 17.8577 8.65665 17.6757L0.384777 9.86179C0.175908 9.66449 0.175908 9.33215 0.384778 9.13485L0.775711 8.76556C0.968417 8.58352 1.2697 8.58352 1.46241 8.76556L8.2087 15.1383V0.499999C8.2087 0.223857 8.43256 0 8.7087 0H9.29129C9.56744 0 9.79129 0.223858 9.79129 0.5V15.1383Z"
                fill={color}
            />
        </svg>
    );
}

export default IncomeArrow;
