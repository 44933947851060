import RouteConfig from 'appConstants/RouteConfig';
import { useEffect } from 'react';
import { HistoryService } from 'service/HistoryService';
import { translate as t } from 'service/Language';

import PopupService from '../../../../../../service/Popup';
import DepositService from '../../service/Deposit';
import Success from './images/Success.svg';

import './SuccessPage.scss';

function SuccessPage() {
    useEffect(() => {
        return () => {
            DepositService.back();
            PopupService.close();
            HistoryService.history.push(RouteConfig.casino.root);
        };
    }, []);
    function onClickHandler() {
        DepositService.back();
        PopupService.close();
        HistoryService.history.push(RouteConfig.casino.root);
    }

    return (
        <div className="transaction-success">
            <div className="transaction-success__container">
                <img src={Success} alt="Success" />
                <span className="transaction-success__title">{t('profile.balance.deposit.transaction.success')}</span>
                <button className="transaction-success__btn" onClick={onClickHandler}>
                    {t('profile.balance.deposit.transaction.success.button')}
                </button>
            </div>
        </div>
    );
}

export default SuccessPage;
