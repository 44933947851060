import { ReactChild, ReactChildren } from 'react';

import Footer from '../../component/Footer/Footer';
import Header from '../../component/Header/Header';

interface AuxProps {
    children: ReactChild | ReactChildren;
}

function Landing({ children }: AuxProps) {
    return (
        <div>
            <Header />
            {children}
            <Footer />
        </div>
    );
}

export default Landing;
