import Mastercard from './image/Mastercard.svg';
import Visa from './image/Visa.svg';

import './PaymentPartners.scss';

function PaymentPartners() {
    const logoList: string[] = [Mastercard, Visa];
    return (
        <div className="payment-partners">
            <div className="payment-partners__wrapper">
                {logoList.map((logo) => (
                    <img key={logo} src={logo} alt="logo" />
                ))}
            </div>
        </div>
    );
}

export default PaymentPartners;
