import { RegistrationError } from '../../../../service/Registration';

class ErrorAdapter {
    public static toString(error: RegistrationError): string | null {
        const list = [
            { type: RegistrationError.ACCOUNT_USED, text: 'registration.error.account_registered' },
            { type: RegistrationError.EMAIL_USED, text: 'registration.error.email_registered' },
            { type: RegistrationError.PHONE_USED, text: 'registration.error.phone_registered' },
            { type: RegistrationError.PASSWORD_NOT_VALID, text: 'registration.error.phone_registered' },
        ];
        const map = new Map<RegistrationError, string>();
        list.forEach((error) => map.set(error.type, error.text));

        return map.get(error) || null;
    }
}

export default ErrorAdapter;
