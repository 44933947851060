import { ClientInfo } from '@ay_tsarbet/newbet-core/dist/types';
import AuthFormService from 'component/LogIn/service/AuthForm';
import EmailInputService from 'component/LogIn/service/EmailInput';
import { BehaviorSubject, Subject } from 'rxjs';
import { parseJwt } from 'utils/parser';
import GoogleService from './Google';
import AuthInterface from './interface/Auth';
import Storage from './Storage';
import WSBet from './WSBet';
import MOCK_PAYMENT from '../assets/mock/payments_glory_adv.json';
import PaymentService from './Payment';
import { mapPaymentsFromBack } from '../utils/payment';
import GloryAPI from './Api';
import { PaymentRecordList } from './api/Payments';

enum AuthState {
    NONE,
    AWAIT,
    LOGIN,
    LOGOUT,
}

export enum AuthError {
    NONE,
    PASSWORD,
}

export enum AuthMethod {
    LOGIN,
    TOKEN,
    LOGOUT,
    NOTHING,
}

class Auth implements AuthInterface {
    state: AuthState = AuthState.NONE;

    STORAGE_KEY_ACCESS_TOKEN = 'accessToken';

    STORAGE_KEY_REFRESH_TOKEN = 'refreshToken';

    success = new Subject<void>();

    result = new Subject<ClientInfo | null>();

    auth = new BehaviorSubject<ClientInfo | null>(null);

    error = new Subject<AuthError>();

    lastMethod: AuthMethod = AuthMethod.NOTHING;

    promo = new BehaviorSubject<boolean>(false);

    confirmEmail = new BehaviorSubject<boolean>(false);

    passed = new BehaviorSubject<boolean>(false);

    constructor() {
        this.auth.subscribe(clientInfo => {
            if (clientInfo) {
                Storage.set(this.STORAGE_KEY_ACCESS_TOKEN, clientInfo.accessToken);
                Storage.set(this.STORAGE_KEY_REFRESH_TOKEN, clientInfo.refreshToken);
            }
        });

        this.auth.subscribe(this.result);
    }

    autoLogin() {
        try {
            const accessToken = Storage.get(this.STORAGE_KEY_ACCESS_TOKEN) as string;
            const refreshToken = Storage.get(this.STORAGE_KEY_REFRESH_TOKEN) as string;

            if (accessToken && refreshToken) {
                const parsedAccessToken = parseJwt(accessToken);
                const parsedRefreshToken = parseJwt(refreshToken);

                const now = new Date();
                const expAccess = new Date(parsedAccessToken.exp * 1000);
                const expRefresh = new Date(parsedRefreshToken.exp * 1000);

                const diffAccess = expAccess.getTime() - now.getTime();
                const diffRefresh = expRefresh.getTime() - now.getTime();

                if (diffAccess > 0) {
                    if (diffRefresh > 0) {
                        this.loginByToken(accessToken, refreshToken);
                        return;
                    }
                }
                Storage.remove(this.STORAGE_KEY_ACCESS_TOKEN);
                Storage.remove(this.STORAGE_KEY_REFRESH_TOKEN);
            }
        } catch (e) {}
    }

    login(login: string, password: string) {
        console.log('login', this.state);
        this.lastMethod = AuthMethod.LOGIN;
        if (this.state === AuthState.NONE) {
            this.state = AuthState.AWAIT;
            WSBet.auth.login(login, password);
        }
    }

    loginByToken(accessToken: string, refreshToken: string): void {
        console.log('loginByToken', this.state);
        this.lastMethod = AuthMethod.TOKEN;
        if (this.state === AuthState.NONE) {
            this.state = AuthState.AWAIT;
            WSBet.auth.loginByToken(accessToken, refreshToken);
        }
    }

    logout() {
        WSBet.auth.logout();
        this.lastMethod = AuthMethod.LOGOUT;
        try {
            Storage.remove(this.STORAGE_KEY_ACCESS_TOKEN);
            Storage.remove(this.STORAGE_KEY_REFRESH_TOKEN);
        } catch (e) {}
    }

    sendNewYear() {
        WSBet.auth.sendNewYear();
        this.promo.next(true);
    }
    sendConfirmEmail() {
        WSBet.auth.sendConfirmEmail();
        this.confirmEmail.next(true);
    }

    async getPayments(userCurrency: number) {
        if (
            window.location.origin.includes('localhost') ||
            window.location.origin.includes('192.168')
        ) {
            if (MOCK_PAYMENT) {
                PaymentService.paymentList.next(
                    mapPaymentsFromBack(MOCK_PAYMENT, userCurrency),
                );
            }
        } else {
            await GloryAPI.payment
                .getList()
                .then((list: PaymentRecordList) =>
                    PaymentService.paymentList.next(mapPaymentsFromBack(list, userCurrency)),
                );
        }
    }

    onReceive(clientInfo: ClientInfo | null) {
        console.log('clientInfo', clientInfo, this.state);
        if (this.state === AuthState.NONE) {
            this.auth.next(clientInfo);
        }
        if (this.state === AuthState.AWAIT) {
            this.state = AuthState.NONE;
            if (clientInfo === null) {
                const email = EmailInputService.getValue();
                const isFormChanged = AuthFormService.changed.getValue();
                if (isFormChanged) {
                    GoogleService.authError(email);
                }
                this.error.next(AuthError.PASSWORD);
            } else {
                this.auth.next(clientInfo);
                this.success.next();
                this.confirmEmail.next(!!clientInfo.confirmEmail)

                if (this.lastMethod === AuthMethod.LOGIN) {
                    const url = document.location.host + document.location.pathname
                    GoogleService.loginComplete(
                        clientInfo.email || '',
                        clientInfo.userId.toString(), url
                    );
                }
                WSBet.auth.betLogin();
                this.getPayments(clientInfo.currencyCode);
            }
        }
        AuthFormService.changed.next(false);
    }

    checkAuthOnce() {
        try {
            Storage.get(this.STORAGE_KEY_ACCESS_TOKEN);
        } catch (e) {
            return false;
        }
        return true;
    }

    isAwait() {
        return this.state === AuthState.AWAIT;
    }

    auththorized(): boolean {
        return this.auth.getValue() !== null;
    }
}

const AuthService = new Auth();

export default AuthService;
