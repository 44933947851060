import Validator from '../interface/Validator';

class NagadTransaction implements Validator {
    validate(value: string): boolean {
        return this.symbolCheck(value) && this.lengthCheck(value);
    }

    symbolCheck(value: string): boolean {
        return new RegExp('^[A-Za-z0-9]+$').test(value);
    }

    lengthCheck(value: string): boolean {
        return value.length >= 8;
    }
}

const NagadTransactionService = new NagadTransaction();

export { NagadTransactionService };
export default NagadTransaction;
