import { formatCurrencySign } from 'utils/format';

import Input from '../../../../../../../component/Input/Input';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import AmountFormService from '../../service/AmountForm';
import Submit from '../Button/Submit';
import SumSelector from '../SummSelector/SumSelector';

import './Amount.scss';

type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {};

class Amount extends RxComponent<AmountProps, AmountState> {
    componentDidMount() {
        const { paymentMethod } = this.props;

        AmountFormService.reset();
        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setValue(paymentMethod.defaultValue);
    }

    render() {
        const { paymentMethod, lock } = this.props;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        return (
            <div className="deposit-amount-paycos">
                <form className="deposit-amount-paycos__form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    <div className="deposit-amount-paycos__input_wrapper">
                        <Input
                            inputService={AmountFormService.amount}
                            className="deposit-amount-paycos__input"
                            name="amount"
                            type="number"
                            hint={hint}
                            enabled={!lock}
                        />
                        <span className="deposit-amount-paycos__input_currency">{paymentMethod.currency.sign}</span>
                    </div>
                </form>
                <div className="deposit-amount-paycos__sum">
                    <SumSelector paymentMethod={paymentMethod} disabled={lock} />
                </div>
                <div className="deposit-amount-paycos__submit">
                    <Submit />
                </div>
            </div>
        );
    }
}

export default Amount;
