import { LanguageType } from '../../../../service/Language';
import { LanguageImageTitle } from '../LanguageSelect';

import './ButtonSelect.scss';

type ButtonSelectType = {
    language: LanguageType;
    onClick: (language: LanguageType) => void;
    size?: 'small' | 'big';
    list: LanguageImageTitle[];
};

function ButtonSelect({ language, onClick, list, size = 'big' }: ButtonSelectType) {
    let match = list.find((item) => item.title === language.title);
    if (!match) {
        match = list[0];
    }
    const LanguageImage = match.img;

    const px = size === 'big' ? 28 : 20;

    return (
        <button type="button" className="button-select" onClick={() => onClick(language)}>
            <img className="button-select__img" src={LanguageImage} width={px} height={px} alt={language.title} />
            <span className="button-select__title">{language.title}</span>
        </button>
    );
}

export default ButtonSelect;
