/* eslint-disable max-len */

import { Icon } from 'types';

function DashboardIcon({ color = '#FFFFFF', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                d="M10.125 9H4.125C3.50475 9 3 8.49525 3 7.875V4.125C3 3.50475 3.50475 3 4.125 3H10.125C10.7453 3 11.25 3.50475 11.25 4.125V7.875C11.25 8.49525 10.7453 9 10.125 9ZM4.125 3.75C3.91875 3.75 3.75 3.918 3.75 4.125V7.875C3.75 8.082 3.91875 8.25 4.125 8.25H10.125C10.3313 8.25 10.5 8.082 10.5 7.875V4.125C10.5 3.918 10.3313 3.75 10.125 3.75H4.125Z"
                fill={color}
            />
            <path
                d="M10.125 21H4.125C3.50475 21 3 20.4952 3 19.875V11.625C3 11.0048 3.50475 10.5 4.125 10.5H10.125C10.7453 10.5 11.25 11.0048 11.25 11.625V19.875C11.25 20.4952 10.7453 21 10.125 21ZM4.125 11.25C3.91875 11.25 3.75 11.418 3.75 11.625V19.875C3.75 20.082 3.91875 20.25 4.125 20.25H10.125C10.3313 20.25 10.5 20.082 10.5 19.875V11.625C10.5 11.418 10.3313 11.25 10.125 11.25H4.125Z"
                fill={color}
            />
            <path
                d="M19.875 21H13.875C13.2547 21 12.75 20.4952 12.75 19.875V16.125C12.75 15.5047 13.2547 15 13.875 15H19.875C20.4952 15 21 15.5047 21 16.125V19.875C21 20.4952 20.4952 21 19.875 21ZM13.875 15.75C13.6687 15.75 13.5 15.918 13.5 16.125V19.875C13.5 20.082 13.6687 20.25 13.875 20.25H19.875C20.0812 20.25 20.25 20.082 20.25 19.875V16.125C20.25 15.918 20.0812 15.75 19.875 15.75H13.875Z"
                fill={color}
            />
            <path
                d="M19.875 13.5H13.875C13.2547 13.5 12.75 12.9953 12.75 12.375V4.125C12.75 3.50475 13.2547 3 13.875 3H19.875C20.4952 3 21 3.50475 21 4.125V12.375C21 12.9953 20.4952 13.5 19.875 13.5ZM13.875 3.75C13.6687 3.75 13.5 3.918 13.5 4.125V12.375C13.5 12.582 13.6687 12.75 13.875 12.75H19.875C20.0812 12.75 20.25 12.582 20.25 12.375V4.125C20.25 3.918 20.0812 3.75 19.875 3.75H13.875Z"
                fill={color}
            />
        </svg>
    );
}

export default DashboardIcon;
