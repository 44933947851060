import useObservable from 'utils/useObservable';
import { useEffect } from 'react';
import { PaymentMethod } from '../../../PaymentMethod/types';
import ResultForm from '../ResultForm/ResultForm';
import AzarpayWithdrawService, { AzarpayWithdrawState } from '../service/AzarpayWithdraw';
import Card from './Card/Card';
import {ChannelType} from '@ay_tsarbet/newbet-core/dist/types';
import Balance from '../../Balance/Balance';
import Valerpay from './Valerpay/Valerpay';

type FormProps = {
    paymentMethod: PaymentMethod;
};

function Form(props: FormProps) {
    const { paymentMethod } = props;
    const state = useObservable(AzarpayWithdrawService.state, AzarpayWithdrawService.state.getValue());

    useEffect(() => {
        AzarpayWithdrawService.reset();
    }, []);


    if (paymentMethod) {
        if (state === AzarpayWithdrawState.CARD || state === AzarpayWithdrawState.AWAIT || state === AzarpayWithdrawState.HAS_FEE) {
            if (paymentMethod.id === ChannelType.AZN_AZARPAY) {
                return (
                    <>
                        <Balance/>
                        <Card paymentMethod={paymentMethod}/>
                    </>
                );
            }
            if (paymentMethod.id === ChannelType.VALERPAY_WITHDRAWAL) {
                return (
                    <>
                        <Balance/>
                        <Valerpay paymentMethod={paymentMethod}/>
                    </>
                );
            }
        } else {
            return <ResultForm />;
        }
    }

    return null;
}

export default Form;
