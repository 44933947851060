import classNames from 'classnames';
import { Component } from 'react';
import { translate as t } from 'service/Language';

import PopupHelper from '../../../../service/PopupHelper';
import AviatorElements from './AviatorElements/AviatorElements';
import WheelItem from './WheelItem/WheelItem';

import './WheelAviator.scss';

class WheelAviator extends Component {
    state = {
        angle: 0,
        idle: true,
        attempts: 2,
        disabled: false,
    };

    handler = {
        spin: this.spin.bind(this),
    };

    spin() {
        this.setState({ disabled: true, idle: false });
        this.setState({ angle: this.state.attempts === 2 ? 765 : 1440 });

        setTimeout(() => {
            if (this.state.attempts === 2) {
                this.setState({ attempts: 1, idle: true });
                PopupHelper.showWheelWin('aviator', this.handler.spin);
            } else {
                this.setState({ attempts: 0 });
                PopupHelper.showWheelRegistration('aviator');
            }
            this.setState({ disabled: false });
        }, 4000);
    }

    render() {
        const { attempts, disabled, angle, idle } = this.state;

        const _className = classNames({
            'wheel-aviator__scoreboard': true,
            'wheel-aviator__scoreboard_idle': idle,
        });

        const lightsFirstClassName = classNames({
            'wheel-aviator__lights': true,
            'wheel-aviator__lights_first': true,
            'wheel-aviator__lights__fast': !idle,
        });
        const lightsSecondClassName = classNames({
            'wheel-aviator__lights': true,
            'wheel-aviator__lights_second': true,
            'wheel-aviator__lights__fast': !idle,
        });
        return (
            <div className="wheel-aviator">
                <AviatorElements />
                <div className={_className}>
                    <WheelItem angle={angle} />
                </div>
                <div className="wheel-aviator__frame">
                    <div className={lightsFirstClassName} />
                    <div className={lightsSecondClassName} />
                </div>
                <div className="wheel-aviator__button-container">
                    <button
                        className="wheel-aviator__button"
                        onClick={this.handler.spin}
                        disabled={disabled || attempts === 0}
                    >
                        <span className="wheel-aviator__button_text">{t('wheel.aviator.button')}</span>
                    </button>
                </div>
            </div>
        );
    }
}

export default WheelAviator;
