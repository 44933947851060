import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import { translate as t } from 'service/Language';
import { formatCurrencySign } from 'utils/format';

import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import PaymentService from '../../../../../../../service/Payment';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import AmountFormService from '../../service/AmountForm';
import PaykassmaDepositService, { DepositState } from '../../service/PaykassmaDeposit';
import SumSelector from '../../SummSelector/SumSelector';
import SubmitAmount from '../Button/SubmitAmount';
import VideoInstruction from '../VideoInstruction/VideoInstruction';
import DisabledAmount from './DisabledAmount/DisabledAmount';
import Attention from '../../../Apay/Attention/Attention';

import './Amount.scss';

type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {};

class Amount extends RxComponent<AmountProps, AmountState> {
      handler = {
        onInvoiceError: this.onInvoiceError.bind(this),
           };

    onInvoiceError() {
        AmountFormService.amount.error.next(t('profile.balance.deposit.invoice.error'));
    }

    componentDidMount() {
        const { paymentMethod } = this.props;

        AmountFormService.reset();
        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setValue(paymentMethod.defaultValue);
        PaykassmaDepositService.resetInvoice();
        this.subscribe(PaymentService.paykassma.invoice, {
            next: (invoice) => PaykassmaDepositService.onReceiveInvoice(invoice),
        });
        this.subscribe(PaymentService.paykassma.error, { next: this.handler.onInvoiceError });
          }

    render() {
        const { paymentMethod, lock } = this.props;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        const isInvoiceWait = PaykassmaDepositService.state.getValue() === DepositState.WAITINVOICE;
        const isAmountState = PaykassmaDepositService.state.getValue() === DepositState.AMOUNT;
        const checkMethod = paymentMethod.id !== ChannelType.UPI;

        return (
            <div className="deposit-amount">
                <VideoInstruction method={paymentMethod} />
                {checkMethod && (
                    <>
                        {paymentMethod.id !== ChannelType.Easypaisa_2 && <div className="deposit-amount__description">
                            <span>{t('profile.balance.deposit.transaction.before')} </span>
                            <button
                                type="button"
                                className="deposit-amount__skip"
                                onClick={(e) => AmountFormService.skip()}
                            >
                                {t('profile.balance.deposit.transaction.link')}
                            </button>
                            <span> {t('profile.balance.deposit.transaction.after')}</span>
                        </div>}
                    </>
                )}
                <form className="deposit-amount__form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    <div className="deposit-amount-block">
                        {isAmountState ? (
                            <MaskedInput
                                mask="00000000000"
                                inputService={AmountFormService.amount}
                                className="deposit-amount__input"
                                name="amount"
                                hint={hint}
                                enabled={!lock}
                            />
                        ) : (
                            <DisabledAmount hint={hint} />
                        )}
                        <span className="deposit-amount-block__currency">{paymentMethod.currency.sign}</span>
                    </div>

                    {(isAmountState || isInvoiceWait) && (
                        <>
                            <div className="deposit-amount__sum">
                                <SumSelector paymentMethod={paymentMethod} disabled={lock} />
                            </div>
                            {(paymentMethod.id === ChannelType.UPI ||
                                paymentMethod.id === ChannelType.PayTM ||
                                paymentMethod.id === ChannelType.PhonePe) ?
                                <div className="deposit-amount__check">
                                    <Attention title="popup.payment.attention" type="attention" />
                            </div>: null}
                            <div className="deposit-amount__submit">
                                <SubmitAmount/>
                            </div>
                        </>
                    )}
                </form>
            </div>
        );
    }
}

export default Amount;
