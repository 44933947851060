import classNames from 'classnames';
import { translate as t } from 'service/Language';

import RouteConfig from '../../../../../appConstants/RouteConfig';

import './LoginButton.scss';

type LoginButtonProps = {
    type: string;
};

function LoginButton({ type }: LoginButtonProps) {
    if (type === 'criket') return null;
    const className = classNames({
        'login-button': true,
        'login-button____card': type === 'card',
        'login-button____girl': type === 'girl',
        'login-button____teen': type === 'teen',
        'login-button____aviator': type === 'aviator',
        'login-button____crazytime': type === 'crazytime' || type === 'roulette' || type === 'bonanza' || type === 'olympus',
        'login-button____slots': type === 'slots',
    });

    const url = new URL(window.location.href);
    url.pathname = RouteConfig.casino.root;
    url.hash = RouteConfig.hash.auth;

    const classComponent = classNames('LoginButton', {
        [type]: !!type,
    });

    return (
        <div className={classComponent}>
            {(type === 'crazytime' || type === 'roulette' || type === 'bonanza' || type === 'olympus') && <span>{t('registration.account')} </span>}
            <a className={className} href={url.toString()}>
                {t('registration.login')}
            </a>
        </div>
    );
}

export default LoginButton;
