import { PaykassmaWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaWithdraw';
import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import AuthService from 'service/Auth';
import GoogleService from 'service/Google';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';

import { translate as t } from '../../../../../../service/Language';
import UserDataService from '../../../../../../service/UserData';
import { PhoneMpesaService } from '../../../../../../service/Validator/PhoneMpesa';
import {
    formatBangladeshPhoneForBack,
    formatMpesaPhoneForBack,
    formatMyanmarForBack
} from '../../../../../../utils/format';
import paymentMethod from '../../../PaymentMethod/methods';
import { PaymentMethod } from '../../../PaymentMethod/types';
import WithdrawService from '../../service/Withdrawal';
import PaykassmaWithdrawService, { PaykassmaWithdrawState } from './PaykassmaWithdraw';
import BonusService from '../../../../../../service/Bonus';
import StatusFormPopupService, {StatusForm} from '../../../Deposit/DepositPopup/service/StatusForm';
import { ChannelType } from '@ay_tsarbet/newbet-core/dist/types';
import { PhoneMyanmarService } from '../../../../../../service/Validator/PhoneMyanmar';

enum PhoneCardError {
    NOERROR,
    AMOUNT_PAYTM,
    AMOUNT_PAYTM_FEE,
    PHONE_ZERO,
    PHONE_MPESA,
}

class PhoneForm {
    paymentMethod: PaymentMethod = paymentMethod.BKash;

    limitValidator = new Validator.limit(0, 1);

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.withdraw.input.amount',
    });

    phone = new InputService({
        title: 'profile.balance.withdraw.input.phone',
        validator: Validator.phonePaykassmaWith,
        validatorText: 'profile.balance.withdraw.input.phone',
    });
    phoneRocket = new InputService({
        title: 'profile.balance.withdraw.input.phone',
        validator: Validator.phonePaykassmaRocket,
        validatorText: 'profile.balance.withdraw.input.phone',
    });
    phoneMyanmar = new InputService({
        title: 'profile.balance.withdraw.input.phone',
        validator: Validator.phoneMyanmar,
        validatorText: 'profile.balance.withdraw.input.phone',
    });

    phonePTm = new InputService({
        title: 'profile.balance.withdraw.input.phone',
        validator: Validator.phonePtm,
    });

    phoneMpesa = new InputService({
        title: 'profile.balance.withdraw.input.phone',
        validator: Validator.phoneMpesa,
        validatorText: 'profile.balance.withdraw.input.phone',
    });

    valid = new BehaviorSubject<boolean>(false);

    validMpesa = new BehaviorSubject<boolean>(false);

    validPtm = new BehaviorSubject<boolean>(false);

    tip = new BehaviorSubject<PhoneCardError>(PhoneCardError.NOERROR);

    error = new BehaviorSubject<string>('');

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        onPhone: this.onPhone.bind(this),
        phoneRocket: this.onPhone.bind(this),
        onPhoneMpesa: this.onPhoneMpesa.bind(this),
        onPhoneMyanmar: this.onPhoneMyanmar.bind(this),
        onPhoneActive: this.onPhoneActive.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
        this.phone.value.subscribe(this.handler.onPhone);
        this.phoneRocket.value.subscribe(this.handler.phoneRocket);
        this.phoneMpesa.value.subscribe(this.handler.onPhoneMpesa);
        this.phoneMpesa.active.subscribe(this.handler.onPhoneActive);
        this.phoneMyanmar.value.subscribe(this.handler.onPhoneMyanmar);
        this.phoneMyanmar.active.subscribe(this.handler.onPhoneActive);
    }

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        const limit = this.paymentMethod.limit.withdraw;
        const isFee = WithdrawService.fee.getValue();
        const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
        this.limitValidator.setLimit(isFee ? isMin : limit.min, limit.max);
    }

    deleteInvalidValue(value: string) {
        const length = value.length;
        const current = value.slice(0, length - 1);
        this.amount.value.next(current);
    }

    onAmount(value: string) {
        this.checkValid();
        const isValue = this.amount.value.getValue();
        const isPayTm = this.paymentMethod.id === ChannelType.PayTM
        if (isValue) {
            if (!value.indexOf('0', 0)) {
                this.deleteInvalidValue(value);
            }
            if (isPayTm) {
                const isStateFee = PaykassmaWithdrawService.state.getValue() === PaykassmaWithdrawState.HAS_FEE;

                const fee = WithdrawService.fee.getValue();
                const amount = this.amount.getValue();
                if (fee && isStateFee) {
                    const sumToWithdraw = Math.round(parseInt(amount) - parseInt(amount) * 0.2);
                    if (sumToWithdraw % 10 !== 0) {
                        this.amount.error.next('profile.balance.withdraw.input.amount');
                    } else {
                        this.amount.error.next(null);
                    }
                } else {
                    if (+amount % 10 !== 0) {
                        this.amount.error.next('profile.balance.withdraw.input.amount');
                    } else {
                        this.amount.error.next(null);
                    }
                }
            }
            const isFee = WithdrawService.fee.getValue();
            const isMin = this.paymentMethod.limit.withdraw.min * 1.25;
            if (isFee && +isValue < isMin) {
                this.amount.error.next('profile.balance.withdraw.input.amount');
                this.error.next('popup.payment.min.fee')
            } else {
                this.amount.error.next(null);
                this.error.next('');
            }


        } else {
            this.amount.defaultTitle.valueOf();
            this.amount.error.next(null);
            this.error.next('');
        }
    }

    onAmountActive(active: boolean) {
        if (active && this.paymentMethod.id === ChannelType.PayTM) {
            const fee = WithdrawService.fee.getValue();
            this.tip.next(!!fee ? PhoneCardError.AMOUNT_PAYTM_FEE : PhoneCardError.AMOUNT_PAYTM);
        } else {
            this.tip.next(PhoneCardError.NOERROR);
        }
    }

    onPhone() {
        this.checkValid();
    }

    onPhoneMpesa(value: string) {
        if (value && !PhoneMpesaService.validate(value)) {
            this.phoneMpesa.error.next(t('profile.balance.withdraw.input.phone'));
            this.validMpesa.next(false);
        } else {
            this.checkValid();
            this.phoneMpesa.error.next(null);
        }
    }
    onPhoneMyanmar(value: string) {
        if (value && !PhoneMyanmarService.validate(value)) {
            this.phoneMyanmar.error.next(t('profile.balance.withdraw.input.phone'));
            this.valid.next(false);
        } else {
            this.checkValid();
            this.phoneMyanmar.error.next(null);
        }
    }

    onPhonePtm(value: string) {
        if (value && !PhoneMpesaService.validate(value)) {
            this.phonePTm.error.next(t('profile.balance.withdraw.input.phone'));
            this.validPtm.next(false);
        } else {
            this.checkValid();
            this.phonePTm.error.next(null);
        }
    }

    onPhoneActive(active: boolean) {
        if (active) {
            this.tip.next(PhoneCardError.PHONE_MPESA);
        } else {
            this.tip.next(PhoneCardError.NOERROR);
        }
    }

    checkValid() {
        if (this.paymentMethod) {
            if (this.paymentMethod.id === ChannelType.PayTM) {
                const isAmount = this.amount.isValid();
                const isPhone = this.phoneMpesa.isValid();
                let roundTo10 = false;
                const isStateFee = PaykassmaWithdrawService.state.getValue() === PaykassmaWithdrawState.HAS_FEE;
                const amount = this.amount.getValue();
                const fee = WithdrawService.fee.getValue();

                if (fee && isStateFee) {
                    const sumToWithdraw = Math.round(parseInt(amount) - parseInt(amount) * 0.2);
                    roundTo10 = sumToWithdraw % 10 === 0;
                } else {
                    roundTo10 = +amount % 10 === 0;
                }

                if (isAmount && isPhone && roundTo10) {
                    this.validMpesa.next(true);
                } else this.validMpesa.next(false);
            }
            if (this.paymentMethod.id === ChannelType.MPESA) {
                const isAmount = this.amount.isValid();
                const isPhone = this.phoneMpesa.isValid();
                if (isAmount && isPhone) {
                    this.validMpesa.next(true);
                } else this.validMpesa.next(false);
            }
            if (this.paymentMethod.id === ChannelType.Rocket) {
                const isFee = WithdrawService.fee.getValue();
                const isPhone = this.phoneRocket.isValid();
                if(isFee) {
                    const isValue = this.amount.value.getValue();
                    const isMin = this.paymentMethod.limit.withdraw.min * 1.25;
                    this.valid.next(+isValue >= isMin && isPhone);
                } else {
                    const isAmount = this.amount.isValid();
                    this.valid.next(isAmount && isPhone)
                }
            }
            if (this.paymentMethod.id === ChannelType.Wavepay || this.paymentMethod.id === ChannelType.Kpay) {
                const isFee = WithdrawService.fee.getValue();
                const isPhone = this.phoneMyanmar.isValid();
                if(isFee) {
                    const isValue = this.amount.value.getValue();
                    const isMin = this.paymentMethod.limit.withdraw.min * 1.25;
                    this.valid.next(+isValue >= isMin && isPhone);
                } else {
                    const isAmount = this.amount.isValid();
                    this.valid.next(isAmount && isPhone)
                }
            } else {
                if(this.amount.getValue() && this.phone.getValue()) {
                    const isFee = WithdrawService.fee.getValue();
                    const isPhone = this.phone.isValid();
                    if(isFee) {
                        const isValue = this.amount.value.getValue();
                        const isMin = this.paymentMethod.limit.withdraw.min * 1.25;
                        this.valid.next(+isValue >= isMin && isPhone);
                    } else {
                        const isAmount = this.amount.isValid();
                        this.valid.next(isAmount && isPhone)
                    }
                }
            }
        }
    }

    send() {
        let assert = [this.amount.isValid(), this.phone.isValid()];
        const isPayRocket = this.paymentMethod.id === ChannelType.Rocket;
        const isPayMyanmar = this.paymentMethod.id === ChannelType.Wavepay || this.paymentMethod.id === ChannelType.Kpay;

        if (this.paymentMethod.id === ChannelType.MPESA || this.paymentMethod.id === ChannelType.PayTM) {
            assert = [this.amount.isValid(), this.phoneMpesa.isValid()];
        }
        if(isPayRocket) {
            assert = [this.amount.isValid(), this.phoneRocket.isValid()];
        }
        if(isPayMyanmar) {
            assert = [this.amount.isValid(), this.phoneMyanmar.isValid()];
        }
        const valid = !assert.includes(false);

        if (valid && this.paymentMethod) {
            const currencyId = this.paymentMethod.currency.ISO;
            const channelType = this.paymentMethod.id;
            const geAccountNumber = (): string => {
                if (this.paymentMethod.id === ChannelType.MPESA) {
                    return formatMpesaPhoneForBack(this.phoneMpesa.getValue())
                } else if (this.paymentMethod.id === ChannelType.PayTM) {
                    return formatBangladeshPhoneForBack(this.phoneMpesa.getValue())
                } else if (this.paymentMethod.id === ChannelType.Wavepay || this.paymentMethod.id === ChannelType.Kpay) {
                    return formatMyanmarForBack(this.phoneMyanmar.getValue())
                } else {
                    const phone = isPayRocket ? this.phoneRocket.getValue() :this.phone.getValue()
                    return formatBangladeshPhoneForBack(phone);
                }
            }

            const accountNumber = geAccountNumber()
            const accountName = UserDataService.data.getValue()?.email.toString().trim() || '';
            const amount = parseInt(this.amount.getValue());

            const request: PaykassmaWithdrawRequest = {
                currencyId,
                channelType,
                accountNumber,
                accountName,
                bankCode: '',
                amount,
            };
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            GoogleService.sentWithdraw(
                userId,
                this.paymentMethod.title,
                amount.toString(),
                this.paymentMethod.currency.name,
                accountNumber
            );
            console.log('send', request);
            PaymentService.paykassma.withdraw(request);
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.AWAIT);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();
        const isBonuses = BonusService.list.getValue().length;

        if (isBonuses) {
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.BONUS_REJECT);
            StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
        } else {
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.AWAIT);
            this.send();
        }
    }

    reset() {
        this.amount.value.next('');
        this.amount.error.next(null);
        this.phone.value.next('');
        this.phoneMpesa.value.next('');
        this.phonePTm.value.next('');
        this.phoneRocket.value.next('');
        this.phoneMyanmar.value.next('');

        this.amount.error.next(null);
        this.phone.error.next(null);
        this.phonePTm.error.next(null);
        this.phonePTm.error.next(null);
        this.phoneRocket.error.next(null);
        this.phoneMyanmar.error.next(null);

        this.valid.next(false);
        this.validMpesa.next(false);
        this.validPtm.next(false);
        this.tip.next(PhoneCardError.NOERROR);

        PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.INIT);
    }
}

const PhoneFormService = new PhoneForm();
export { PhoneCardError };
export default PhoneFormService;
