import { TournamentList } from '../../service/model/Tournament';
import Card from './Card/Card';
import EmptyCard from './Card/EmptyCard';

import './Finished.scss';

type FinishedProps = {
    tournamentList: TournamentList;
};

function Finished(props: FinishedProps) {
    const { tournamentList } = props;
    const sortedSplitList = tournamentList
        .slice()
        .sort((a, b) => (a.endDate < b.endDate ? 1 : -1))
        .slice(0, 3);

    return (
        <div className="tournament-list-finished">
            {sortedSplitList.map((tournament, index) => (
                <Card key={tournament.id} index={index} tournament={tournament} />
            ))}
            {new Array(sortedSplitList.length > 2 ? 10 : 0).fill(null).map((v, i) => (
                <EmptyCard key={i} />
            ))}
        </div>
    );
}

export default Finished;
