import colors from 'appConstants/colors';
import { MouseEventHandler, ReactNode, VFC } from 'react';
import { NavLink } from 'react-router-dom';
import { translate as t } from 'service/Language';
import SvgImage from 'svg';
import { SvgNames } from 'types';

import GoogleService from '../../service/Google';
import Arrow from './image/arrow.svg';

import './categoryHeader.scss';

interface LinkProps {
    title: string;
    route?: string;
    onClick?: MouseEventHandler;
    icon?: SvgNames;
    className?: string;
}

interface CategoryHeaderProps {
    title: string;
    linkProps?: LinkProps;
    rightBlock?: ReactNode;
    backUrl?: string;
}

const renderLink = (linkProps?: LinkProps) => {
    if (!linkProps) {
        return null;
    }

    const linkContents = (
        <>
            <div className="category-header__link-text" onClick={() => GoogleService.openProviderList()}>
                {linkProps.title}
            </div>
            <img className={linkProps.className} src={Arrow} alt="arrow" />
        </>
    );
    return linkProps.route ? (
        <NavLink className="category-header__link" to={linkProps.route}>
            {linkContents}
        </NavLink>
    ) : (
        <div className="category-header__link" onClick={linkProps.onClick}>
            {linkContents}
        </div>
    );
};

const CategoryHeader: VFC<CategoryHeaderProps> = ({ title, linkProps, rightBlock, backUrl }) => (
    <div className="category-header">
        {backUrl && (
            <NavLink to={backUrl} className="category-header__backurl">
                <SvgImage color={colors.brightGray} width={24} height={24} name="LeftFlatArrow" />
            </NavLink>
        )}
        <h2 className="category-header__title">{t(title)}</h2>
        <span className="category-header__filler" />
        {renderLink(linkProps)}
        {rightBlock}
    </div>
);

export default CategoryHeader;
