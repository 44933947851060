import ProviderFilterService from 'component/SortAndFilterMenu/service/ProviderFilter';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import GamesService from '../../../service/Games';
import GoogleService from '../../../service/Google';
import GameCategoryModel, { gameCategory, gameCategoryList } from '../../../service/model/GameCategory';
import GameProvider from '../../../service/model/GameProvider';
import useObservable from '../../../utils/useObservable';

const categoryMap = new Map<string, GameCategoryModel>(gameCategoryList.map((category) => [category.route, category]));

type RouteListenerParams = {
    category: string;
    provider: string;
};

function RouteListener() {
    const routeParams = useParams<RouteListenerParams>();

    const providers = useObservable(GamesService.allowedProviders, GamesService.cache.allowedProvider);
    const providerMap = new Map<string, GameProvider>(providers.map((provider) => [provider.route, provider]));

    const category = categoryMap.get(routeParams.category) || gameCategory.Popular;
    const provider = providerMap.get(routeParams.provider) || null;

    useEffect(() => {
        ProviderFilterService.setInput('');
    }, [category, provider]);

    setTimeout(() => {
        GamesService.setFilter({
            category,
            provider,
        });
    }, 0);

    return null;
}

export default RouteListener;
