/* eslint-disable max-len */

import { Icon } from 'types';

function ExitIcon({ color = '#3F4250', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.84137 7.9372C7.16812 5.72157 9.53968 4.25581 12.2354 4.25581C16.3658 4.25581 19.7445 7.70603 19.7445 12C19.7445 16.294 16.3658 19.7442 12.2354 19.7442C9.53968 19.7442 7.16812 18.2784 5.84137 16.0628C5.66321 15.7653 5.2776 15.6685 4.98007 15.8467C4.68255 16.0248 4.58579 16.4104 4.76395 16.708C6.30202 19.2765 9.0688 21 12.2354 21C17.0929 21 21.0003 16.9536 21.0003 12C21.0003 7.04641 17.0929 3 12.2354 3C9.0688 3 6.30202 4.7235 4.76395 7.29203C4.58579 7.58955 4.68255 7.97517 4.98007 8.15333C5.2776 8.33148 5.66321 8.23472 5.84137 7.9372ZM6.06977 10.3255C6.06977 9.98056 5.67596 9.78366 5.4 9.99063L3.16744 11.665C2.94419 11.8325 2.94419 12.1674 3.16744 12.3348L5.4 14.0092C5.67596 14.2162 6.06977 14.0193 6.06977 13.6743V12.6279H11.9302C12.277 12.6279 12.5581 12.3468 12.5581 12C12.5581 11.6532 12.277 11.3721 11.9302 11.3721H6.06977V10.3255Z"
                fill={color}
            />
        </svg>
    );
}

export default ExitIcon;
