import { translate as t } from 'service/Language';

import Close from './Close/Close';

import './Header.scss';

type HeaderProps = {
    type?: string;
};

function Header({ type }: HeaderProps) {
    return (
        <div className="log-in-header">
            <div className="log-in-header__title">{t('login.header')}</div>
            {type ? null : <Close />}
        </div>
    );
}

export default Header;
