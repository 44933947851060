/* eslint-disable max-len */

import { Icon } from 'types';

function BigRegbieLeague({ color = '#3959D9', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path d="M10.3984 13.8532V8.91818H9.10794V15.0184H13.3641V13.8532H10.3984Z" fill={color} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.78 4.8742C11.4398 -0.449237 18.8201 -1.5916 22.2386 2.33814C23.9592 4.28017 24.4346 7.13609 23.597 10.3804C22.8046 13.4877 20.8803 16.5949 18.1862 19.131C14.3376 22.7408 9.69657 24.4315 6.05167 23.906C4.3311 23.6776 2.83691 22.9236 1.72759 21.667C-1.69091 17.7373 0.120218 10.1976 5.78 4.8742ZM14.564 15.9323C18.7975 10.8145 20.9935 4.64572 19.3635 2.49807C19.1371 2.17821 18.7975 1.97258 18.39 1.8355C18.3447 1.82407 18.3051 1.81265 18.2655 1.80123C18.2259 1.7898 18.1862 1.77838 18.141 1.76696C18.107 1.76696 18.0674 1.76125 18.0278 1.75553C17.9881 1.74982 17.9485 1.74411 17.9146 1.74411C17.1448 1.65272 16.1487 1.88119 15.0168 2.42953C12.934 3.45766 10.6021 5.42253 8.4967 7.98144C6.39126 10.5403 4.7386 13.3734 3.83303 15.9552C3.04066 18.2399 2.95011 20.1134 3.56137 21.1872C4.17262 21.6442 4.8518 21.9869 5.62153 22.1697C7.93072 22.0554 11.4398 19.7021 14.564 15.9323ZM14.9941 1.74411C12.368 2.15536 9.4249 3.66329 6.84404 6.08511C1.97663 10.7003 0.165495 16.9605 2.70108 20.2962C2.51996 19.0853 2.72372 17.5316 3.35761 15.7038C4.28582 13.0307 5.98375 10.1291 8.15711 7.50164C10.3305 4.8742 12.7076 2.86363 14.8583 1.81265L14.9941 1.74411ZM17.1222 17.8972C19.5899 15.5668 21.3331 12.7565 22.0575 9.94631C22.7593 7.27317 22.3971 4.96559 21.0388 3.43481C20.7445 3.11495 20.4275 2.84078 20.0653 2.58946C21.3331 5.37684 19.205 11.2258 14.9036 16.4121C12.4359 19.4051 9.80976 21.4842 7.61377 22.3753C10.5569 22.3067 14.0886 20.7531 17.1222 17.8972Z"
                fill={color}
            />
        </svg>
    );
}

export default BigRegbieLeague;
