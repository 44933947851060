import classNames from 'classnames';
import { createRef } from 'react';
import { IMaskInput } from 'react-imask';
import { translate as t } from 'service/Language';

import RxComponent from '../RxComponent/RxComponent';
import CodePhoneInput from './CodePhoneInput';
import InputService from './service/Input';

import './Input.scss';

enum InputSize {
    medium,
    small,
    xsmall,
}

type InputProps = {
    className?: string;
    placeholder?: string;
    type?: string;
    name?: string;
    inputService: InputService;
    codeInputService: InputService;
    hint?: string;
    size?: InputSize;
    enabled?: boolean;
    autocomplete?: string;
    isUpperCase?: boolean;
    code?: string;
    mask: string;
    pattern?: string;
    blocks?: any;
};

type InputState = {
    error: string | null;
    active: boolean;
    title: string;
    value: string;
};

class PhoneMaskedInput<P extends InputProps, S extends InputState> extends RxComponent<P, S> {
    ref = {
        input: createRef<HTMLInputElement>(),
    };

    handler = {
        onClick: this.onClick.bind(this),
    };

    constructor(props: P) {
        super(props);

        this.state = {
            error: null,
            active: false,
            title: '',
            value: '',
        } as S;
    }

    getInputProps() {
        const {
            inputService,
            codeInputService,
            placeholder,
            type = 'text',
            name = '',
            enabled = true,
            autocomplete = 'off',
            isUpperCase = false,
            code,
            mask,
            pattern,
            // eslint-disable-next-line
            blocks,
        } = this.props;

        const { error, value } = this.state;

        const fieldClassName = classNames({
            input__field: true,
            input__field__error: error !== null,
        });

        const onChange = (event: any) => inputService.onChange(event, isUpperCase);
        const onBlur = () => inputService.onBlur();
        const onFocus = () => inputService.onFocus();

        return {
            className: fieldClassName,
            name,
            type,
            autocomplete,
            onChange,
            onBlur,
            onFocus,
            placeholder,
            defaultValue: inputService.getValue(),
            value: inputService.getValue(),
            disabled: !enabled,
            ref: this.ref.input,
            mask,
            pattern,
            blocks,
        };
    }

    onClick() {}

    getInput() {
        const inputProps = this.getInputProps();
        return <IMaskInput {...inputProps} />;
    }

    componentDidMount() {
        const { inputService, code } = this.props;

        this.subscribe(inputService.error, { next: (error) => this.setState({ error }) });
        this.subscribe(inputService.active, { next: (active) => this.setState({ active }) });
        this.subscribe(inputService.title, { next: (title) => this.setState({ title }) });
        this.subscribe(inputService.value, { next: (value) => this.setState({ value }) });
    }

    render() {
        const {
            className = '',
            hint = '',
            size = InputSize.medium,
            enabled = true,
            code,
            inputService,
            codeInputService,
            mask,
        } = this.props;
        const { error, active, title, value } = this.state;

        const sizeMap = new Map<InputSize, string>([
            [InputSize.small, 'input____small'],
            [InputSize.xsmall, 'input____xsmall'],
        ]);

        const sizeClass = sizeMap.get(size) || 'input____unknown';

        const containerClassName = classNames({
            input: true,
            input__phone_wrapper: true,
            input____active: active,
            input____disabled: !enabled,
            [sizeClass]: true,
            [className]: !!className,
        });

        const titleClassName = classNames({
            input__title: true,
            input__title__error: error !== null,
            input__title__phone: true,
        });

        return (
            <div className={containerClassName}>
                <CodePhoneInput
                    className="input__phone"
                    inputService={codeInputService}
                    name="phone-code"
                    type="tel"
                    code={code}
                    mask="00"
                />
                {this.getInput()}
                <span className={titleClassName} onClick={this.handler.onClick}>
                    <span>{t(title)}</span>
                    {hint && !error ? <span className="input__hint">{t(hint)}</span> : null}
                </span>
            </div>
        );
    }
}

export { InputSize };
export type { InputProps, InputState };
export default PhoneMaskedInput;
