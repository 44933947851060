import { FormEvent } from 'react';
import { BehaviorSubject, combineLatestWith, map } from 'rxjs';

import GeoDataService from '../../../../service/GeoData';
import Currency from '../../../../service/model/Currency';
import RegistrationService from '../../../../service/Registration';
import Validator from '../../../../service/Validator/Validator';
import Input from '../../../Input/service/Input';

export type GeoCurrency = number[] | null;

class RegistrationForm {
    email = new Input({
        title: 'registration.email',
        validator: Validator.email,
    });

    password = new Input({
        title: 'registration.password',
        validator: Validator.password,
        validatorText: 'registration.password.error',
    });

    termsAgreement = new BehaviorSubject<boolean>(false);

    promotionAgreement = new BehaviorSubject<boolean>(false);

    ageAgreement = new BehaviorSubject<boolean>(false);

    visible = new BehaviorSubject<boolean>(false);

    activeCurrency = new BehaviorSubject<Currency | null>(null);

    correct = this.termsAgreement
        .pipe(combineLatestWith(this.email.valid, this.password.valid, this.activeCurrency, this.ageAgreement))
        .pipe(map(([agreement, email, password, currency, age]) => agreement && email && password && currency && age));

    constructor() {
        GeoDataService.data.subscribe((geoData) => {
            if (geoData && geoData.currencyList.length > 0) {
                this.selectCurrency(geoData.currencyList[0]);
            }
        });
    }

    selectCurrency(currency: Currency) {
        this.activeCurrency.next(currency);
    }

    toggleAge() {
        this.ageAgreement.next(!this.ageAgreement.value);
    }

    togglePromotions() {
        this.promotionAgreement.next(!this.promotionAgreement.value);
    }

    toggleTermsAndCondition() {
        this.termsAgreement.next(!this.termsAgreement.value);
    }

    togglePassword() {
        this.visible.next(!this.visible.getValue());
    }

    submit(event: FormEvent) {
        console.log('submit', event);
        event.preventDefault();

        const email = this.email.getValue();
        const password = this.password.getValue();
        const currency = this.activeCurrency.getValue();
        const promo = this.promotionAgreement.getValue();

        const valid = ![
            this.email.isValid(),
            this.password.isValid(),
            this.termsAgreement.getValue(),
            this.ageAgreement.getValue(),
        ].includes(false);

        if (valid && currency) {
            RegistrationService.byEmailAndPassword(email, password, currency, promo);
        }
    }
}

const RegistrationFormService = new RegistrationForm();

export default RegistrationFormService;
