// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  ChannelType,
  Currency as CurrencyType,
} from "@ay_tsarbet/newbet-core/dist/types";
import { SystemTypes } from "enums/payment";
import { getCurrency } from "service/model/Currency";
import Utils from "service/Utils";
import { PaymentMethodsType } from "./types";

import RouteConfig from "../../../../appConstants/RouteConfig";
import AnindaHavale from "../../../../assets/payIcons/aninda.svg";
import Bkash from "../../../../assets/payIcons/Bkash.svg";
import Easypaisa from "../../../../assets/payIcons/Easypaisa.svg";
import ExpressHavale from "../../../../assets/payIcons/ExpressHavale.svg";
import Havale from "../../../../assets/payIcons/Havale.svg";
import HavaleHera from "../../../../assets/payIcons/havaleHera.svg";
import Kpay from "../../../../assets/payIcons/Kpay.svg";
import Mefete from "../../../../assets/payIcons/Mefete.svg";
import Mpesa from "../../../../assets/payIcons/mpesa.svg";
import Manual from "../../../../assets/payIcons/Manual.svg";
import Nagad from "../../../../assets/payIcons/Nagad.svg";
import Papara from "../../../../assets/payIcons/papara.svg";
import Payfix from "../../../../assets/payIcons/Payfix.svg";
import PayTM from "../../../../assets/payIcons/PayTM.svg";
import PhonePe from "../../../../assets/payIcons/PhonePe.svg";
import Rocket from "../../../../assets/payIcons/Rocket.png";
import SepBank from "../../../../assets/payIcons/SepBank.svg";
import UPI from "../../../../assets/payIcons/upi.svg";
import UPI_QR from "../../../../assets/payIcons/upiQR.svg";
import VisaMaster from "../../../../assets/payIcons/visa_master.svg";
import Visa from "../../../../assets/payIcons/visa.svg";
import Wavepay from "../../../../assets/payIcons/Wavepay.svg";
import MasterVisaAround from "../../../../assets/payIcons/Visa_Master_around.svg";
import Pix from "../../../../assets/payIcons/pix.svg";
import Upay from "../../../../assets/payIcons/upay.svg";
import BankTransfer from "../../../../assets/payIcons/bankTransfer.svg";
import Boleto from "../../../../assets/payIcons/boleto.svg";
import Ipay from "../../../../assets/payIcons/Ipay.svg";
import EManat from "../../../../assets/payIcons/eManat.svg";
import M10 from "../../../../assets/payIcons/m10.svg";
import SmartPay from "../../../../assets/payIcons/SmartPay.svg";
import GPay from "../../../../assets/svg/payments/gpay.svg";
import APayUpay from "../../../../assets/payIcons/apay_upay.svg";
import APayRocket from "../../../../assets/payIcons/apay_rocket.svg";
import APayMobile from "../../../../assets/payIcons/apay_mobile.svg";
import Jazzcash from "../../../../assets/payIcons/Jazzcash.svg";
import Kapital from "../../../../assets/payIcons/kapitalBank.svg";
import BkashCashix from "../../../../assets/payIcons/bkashCashix.svg";
import NagadCashix from "../../../../assets/payIcons/nagadCashix.svg";

const paymentMethod: PaymentMethodsType = {
  // Bangladesh

  BKash: {
    id: ChannelType.bKash,
    icon: Bkash,
    title: "profile.balance.icon.bkash",
    slug: "bkash",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => RouteConfig.external.bkash,
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: [400, 600, 800, 1000, 1200, 1500],
    defaultValue: 600,
  },
  BKashP2C: {
    id: ChannelType.BKASH_API,
    icon: Bkash,
    title: "profile.balance.icon.bkash",
    slug: "bkash",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => RouteConfig.external.bkash,
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: [400, 600, 800, 1000, 1200, 1500],
    defaultValue: 600,
  },
  Nagad: {
    id: ChannelType.Nagad,
    icon: Nagad,
    title: "profile.balance.icon.nagad",
    slug: "nagad",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: false,
    getLink: (): string => {
      return Utils.isiOS()
        ? RouteConfig.external.nagad.ios
        : RouteConfig.external.nagad.android;
    },
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1200", "1500"],
    defaultValue: "600",
  },
  NagadP2C: {
    id: ChannelType.NAGAD_API,
    icon: Nagad,
    title: "profile.balance.icon.nagad",
    slug: "nagad",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: false,
    getLink: (): string => {
      return Utils.isiOS()
        ? RouteConfig.external.nagad.ios
        : RouteConfig.external.nagad.android;
    },
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 400, max: 100000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1200", "1500"],
    defaultValue: "600",
  },
  Rocket: {
    id: ChannelType.Rocket,
    icon: Rocket,
    title: "profile.balance.icon.rocket",
    slug: "rocket",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: false,
    getLink: (): string => {
      return Utils.isiOS()
        ? RouteConfig.external.nagad.ios
        : RouteConfig.external.nagad.android;
    },
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1200", "1500"],
    defaultValue: "600",
  },
  BKASH_APAY: {
      id: ChannelType.BKASH_APAY,
      icon: Bkash,
      title: 'Bkash',
      slug: 'bkash-apay',
      currency: getCurrency(CurrencyType.BDT),
      requireEmail: true,
      getLink: (): string => RouteConfig.external.bkash,
      system: SystemTypes.APAY,
      limit: {
          deposit: { min: 400, max: 20000 },
          withdraw: { min: 1000, max: 20000 },
      },
      amountPreset: ['400', '600', '800', '1000', '1200', '1500'],
      defaultValue: '600',
  },
  NAGAD_APAY: {
      id: ChannelType.NAGAD_APAY,
      icon: Nagad,
      title: 'Nagad',
      slug: 'bkash-apay',
      currency: getCurrency(CurrencyType.BDT),
      requireEmail: true,
      getLink: (): string => RouteConfig.external.nagad,
      system: SystemTypes.APAY,
      limit: {
          deposit: { min: 400, max: 20000 },
          withdraw: { min: 1000, max: 20000 },
      },
      amountPreset: ['400', '600', '800', '1000', '1200', '1500'],
      defaultValue: '600',
  },

  APAY_UPAY: {
    id: ChannelType.UPAY_APAY,
    icon: APayUpay,
    title: "Upay",
    slug: "apay_upay",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => "",
    system: SystemTypes.APAY,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1500", "2000"],
    defaultValue: "400",
  },
  APAY_ROCKET: {
    id: ChannelType.APAY_BDT,
    icon: APayRocket,
    title: "Rocket",
    slug: "apay_rocket",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => "",
    system: SystemTypes.APAY,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1500", "2000"],
    defaultValue: "400",
  },
  APAY_MOBILE_BANKING: {
    id: ChannelType.APAY_BDT,
    icon: APayMobile,
    title: "Mobile Banking",
    slug: "apay_mobile",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => "",
    system: SystemTypes.APAY,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1500", "2000"],
    defaultValue: "400",
  },
  APAY_BANK_CARDS: {
    id: ChannelType.APAY_BDT,
    icon: VisaMaster,
    title: "Bank Cards",
    slug: "apay_bank",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => "",
    system: SystemTypes.APAY,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1500", "2000"],
    defaultValue: "400",
  },
  WELLXPAY_BKASH_P2C_PLUS: {
    id: ChannelType.WELLXPAY_BKASH_P2C_PLUS,
    icon: Bkash,
    title: "Bkash plus",
    slug: "wellxpay-bkash",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => RouteConfig.external.bkash,
    system: SystemTypes.WELLXPAY,
    limit: {
      deposit: { min: 500, max: 50000 },
      withdraw: { min: 500, max: 30000 },
    },
    amountPreset: [500, 1000, 1500, 2000, 2500, 3000],
    defaultValue: 1000,
  },
  WELLXPAY_NAGAD_P2C_PLUS: {
    id: ChannelType.WELLXPAY_NAGAD_P2C_PLUS,
    icon: Nagad,
    title: "Nagad plus",
    slug: "wellxpay-nagad",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => RouteConfig.external.bkash,
    system: SystemTypes.WELLXPAY,
    limit: {
      deposit: { min: 500, max: 100000 },
      withdraw: { min: 500, max: 30000 },
    },
    amountPreset: [500, 1000, 1500, 2000, 2500, 3000],
    defaultValue: 1000,
  },
  WELLXPAY_BKASH_P2C: {
    id: ChannelType.WELLXPAY_BKASH_P2C,
    icon: Bkash,
    title: "Bkash wallet",
    slug: "wellxpay-bkash",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => RouteConfig.external.bkash,
    system: SystemTypes.WELLXPAY,
    limit: {
      deposit: { min: 500, max: 50000 },
      withdraw: { min: 500, max: 30000 },
    },
    amountPreset: [500, 1000, 1500, 2000, 2500, 3000],
    defaultValue: 1000,
  },
  WELLXPAY_NAGAD_P2C: {
    id: ChannelType.WELLXPAY_NAGAD_P2C,
    icon: Nagad,
    title: "Nagad wallet",
    slug: "wellxpay-nagad",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => RouteConfig.external.bkash,
    system: SystemTypes.WELLXPAY,
    limit: {
      deposit: { min: 500, max: 100000 },
      withdraw: { min: 500, max: 30000 },
    },
    amountPreset: [500, 1000, 1500, 2000, 2500, 3000],
    defaultValue: 1000,
  },

  WELLXPAY_H2H_NAGAD: {
    id: ChannelType.WELLXPAY_H2H_NAGAD,
    icon: Nagad,
    title: "profile.balance.icon.nagad",
    slug: "nagad",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: false,
    getLink: (): string => {
      return Utils.isiOS()
          ? RouteConfig.external.nagad.ios
          : RouteConfig.external.nagad.android;
    },
    system: SystemTypes.WELLXPAY,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1200", "1500"],
    defaultValue: "600",
  },
  WELLXPAY_H2H_BKASH: {
    id: ChannelType.WELLXPAY_H2H_BKASH,
    icon: Bkash,
    title: "bKash",
    slug: "bkash",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => RouteConfig.external.bkash,
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: [400, 600, 800, 1000, 1200, 1500],
    defaultValue: 600,
  },
  WELLXPAY_H2H_ROCKET: {
    id: ChannelType.WELLXPAY_H2H_ROCKET,
    icon: Rocket,
    title: "Rocket",
    slug: "Rocket",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.WELLXPAY,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1200", "1500"],
    defaultValue: "600",
  },
  WELLXPAY_ROCKET_P2P: {
    id: ChannelType.WELLXPAY_ROCKET_P2P,
    icon: Rocket,
    title: "Rocket",
    slug: "rocket",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => "",
    system: SystemTypes.WELLXPAY,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1500", "2000"],
    defaultValue: "400",
  },
  WELLXPAY_UPAY_P2P: {
    id: ChannelType.WELLXPAY_UPAY_P2P,
    icon: APayUpay,
    title: "Upay",
    slug: "upay",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => "",
    system: SystemTypes.WELLXPAY,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1500", "2000"],
    defaultValue: "400",
  },
  WELLXPAY_NAGAD_P2P: {
    id: ChannelType.WELLXPAY_NAGAD_P2P,
    icon: Nagad,
    title: "Nagad",
    slug: "Nagad",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => "",
    system: SystemTypes.WELLXPAY,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1500", "2000"],
    defaultValue: "400",
  },
  WELLXPAY_BKASH_P2P: {
    id: ChannelType.WELLXPAY_BKASH_P2P,
    icon: Bkash,
    title: "Bkash",
    slug: "Bkash",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => "",
    system: SystemTypes.WELLXPAY,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1500", "2000"],
    defaultValue: "400",
  },
  WELLXPAY_H2H_UPAY: {
    id: ChannelType.WELLXPAY_H2H_UPAY,
    icon: APayUpay,
    title: "Upay",
    slug: "upay",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => "",
    system: SystemTypes.WELLXPAY,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1500", "2000"],
    defaultValue: "400",
  },
  СASHIX_P2C_NAGAD: {
    id: ChannelType.СASHIX_P2C_NAGAD,
    icon: NagadCashix,
    title: "Nagad",
    slug: "cashix-bkash",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => RouteConfig.external.nagad,
    system: SystemTypes.CASHIX,
    limit: {
      deposit: { min: 500, max: 50000 },
      withdraw: { min: 500, max: 30000 },
    },
    amountPreset: [500, 1000, 1500, 2000, 2500, 3000],
    defaultValue: 1000,
  },
  СASHIX_P2C_BKASH: {
    id: ChannelType.СASHIX_P2C_BKASH,
    icon: BkashCashix,
    title: "Bkash",
    slug: "bkash",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: true,
    getLink: (): string => RouteConfig.external.bkash,
    system: SystemTypes.CASHIX,
    limit: {
      deposit: { min: 500, max: 100000 },
      withdraw: { min: 500, max: 30000 },
    },
    amountPreset: [500, 1000, 1500, 2000, 2500, 3000],
    defaultValue: 1000,
  },
  // Uzbekistan
  // UZCard: {
  //     id: ChannelType.Uzkard,
  //     icon: Uzcard,
  //     title: 'profile.balance.icon.uzcard',
  //     slug: 'uzcard',
  //     currency: getCurrency(CurrencyType.UZS),
  //     requireEmail: false,
  //     getLink: (): string => {
  //         return Utils.isiOS()
  //             ? RouteConfig.external.nagad.ios
  //             : RouteConfig.external.nagad.android;
  //     },
  //     system: SystemTypes.epul,
  //     limit: {
  //         deposit: { min: 25000, max: 1900000 },
  //         withdraw: { min: 30000, max: 2000000 },
  //     },
  //     amountPreset: ['25000', '100000', '150000', '300000', '750000', '1500000'],
  //     defaultValue: '100000',
  // },
  // UzcardTelegram: {
  //     id: ChannelType.Uzkard_Telegram,
  //     icon: UzcardTelegram,
  //     title: 'profile.balance.icon.uzcard.telegram',
  //     slug: 'uzcard',
  //     currency: getCurrency(CurrencyType.UZS),
  //     requireEmail: false,
  //     getLink: (): string => {
  //         return Utils.isiOS()
  //             ? RouteConfig.external.nagad.ios
  //             : RouteConfig.external.nagad.android;
  //     },
  //     system: SystemTypes.paycos,
  //     limit: {
  //         deposit: { min: 10000, max: 1900000 },
  //         withdraw: { min: 12000, max: 2000000 },
  //     },
  //     amountPreset: ['30000', '100000', '150000', '300000', '750000', '1500000'],
  //     defaultValue: '150000',
  // },
  // Corefy_UZS: {
  //     id: ChannelType.KZT_COREFY,
  //     icon: VisaMaster,
  //     title: '',
  //     slug: 'corefy-uzs',
  //     currency: getCurrency(CurrencyType.UZS),
  //     requireEmail: false,
  //     getLink: (): string => '',
  //     system: SystemTypes.corefy,
  //     limit: {
  //         deposit: { min: 25000, max: 2500000 },
  //         withdraw: { min: 25000, max: 2500000 },
  //     },
  //     amountPreset: ['25000', '100000', '150000', '300000', '750000', '1500000'],
  //     defaultValue: '100000',
  // },

  // Turkey
  HavaleVIP: {
    id: ChannelType.TRY_BANK,
    icon: Havale,
    title: "profile.balance.icon.havale",
    slug: "havale",
    currency: getCurrency(CurrencyType.TRY),
    requireEmail: false,
    getLink: (): string => {
      return Utils.isiOS()
        ? RouteConfig.external.nagad.ios
        : RouteConfig.external.nagad.android;
    },
    system: SystemTypes.INSTAPAYS,
    limit: {
      deposit: { min: 100, max: 20000 },
      withdraw: { min: 2000, max: 20000 },
    },
    amountPreset: ["100", "150", "200", "300", "400", "500"],
    defaultValue: "100",
  },
  Papara: {
    id: ChannelType.TRY_PAPARA,
    icon: Papara,
    title: "profile.balance.icon.papara",
    slug: "papara",
    currency: getCurrency(CurrencyType.TRY),
    requireEmail: false,
    getLink: (): string => {
      return Utils.isiOS()
        ? RouteConfig.external.nagad.ios
        : RouteConfig.external.nagad.android;
    },
    system: SystemTypes.INSTAPAYS,
    limit: {
      deposit: { min: 100, max: 10000 },
      withdraw: { min: 100, max: 10000 },
    },
    amountPreset: ["100", "150", "200", "300", "400", "500"],
    defaultValue: "100",
  },
  bankCardsTRY: {
    id: ChannelType.TRY_CREDIT_CARD,
    icon: VisaMaster,
    title: "profile.balance.icon.bankcard.try",
    slug: "bankCards",
    currency: getCurrency(CurrencyType.TRY),
    requireEmail: false,
    getLink: (): string => {
      return Utils.isiOS()
        ? RouteConfig.external.nagad.ios
        : RouteConfig.external.nagad.android;
    },
    system: SystemTypes.INSTAPAYS,
    limit: {
      deposit: { min: 100, max: 5000 },
      withdraw: { min: 100, max: 10000 },
    },
    amountPreset: ["100", "150", "200", "300", "400", "500"],
    defaultValue: "100",
  },
  Undefined: {
    id: 0,
    icon: Manual,
    title: "profile.balance.icon.manual",
    slug: "manual",
    currency: getCurrency(CurrencyType.BDT),
    requireEmail: false,
    getLink: (): string => {
      return Utils.isiOS()
        ? RouteConfig.external.nagad.ios
        : RouteConfig.external.nagad.android;
    },
    system: null,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 0, max: 0 },
    },
    amountPreset: ["400", "600", "800", "1000", "1200", "1500"],
    defaultValue: "100",
  },
  Mefete: {
    id: ChannelType.TRY_MFT,
    icon: Mefete,
    title: "profile.balance.icon.mefete",
    slug: "mefete",
    currency: getCurrency(CurrencyType.TRY),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.INSTAPAYS,
    limit: {
      deposit: { min: 5, max: 20000 },
      withdraw: { min: 100, max: 20000 },
    },
    amountPreset: ["100", "150", "200", "300", "400", "500"],
    defaultValue: "100",
  },
  ExpressHavale: {
    id: ChannelType.TRY_FAST_BANK,
    icon: ExpressHavale,
    title: "profile.balance.icon.havale.express",
    slug: "expressHavale",
    currency: getCurrency(CurrencyType.TRY),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.INSTAPAYS,
    limit: {
      deposit: { min: 100, max: 50000 },
      withdraw: { min: 100, max: 50000 },
    },
    amountPreset: ["250", "300", "500", "700", "1000"],
    defaultValue: "250",
  },
  CepBank: {
    id: ChannelType.TRY_CEP_BANK,
    icon: SepBank,
    title: "profile.balance.icon.сepbank",
    slug: "сepbank",
    currency: getCurrency(CurrencyType.TRY),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.INSTAPAYS,
    limit: {
      deposit: { min: 20, max: 1000 },
      withdraw: { min: 100, max: 20000 },
    },
    amountPreset: ["100", "150", "200", "300", "400", "500"],
    defaultValue: "100",
  },
  Payfix: {
    id: ChannelType.TRY_PAYFIX,
    icon: Payfix,
    title: "profile.balance.icon.payfix",
    slug: "payfix",
    currency: getCurrency(CurrencyType.TRY),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYFIX,
    limit: {
      deposit: { min: 25, max: 50000 },
      withdraw: { min: 25, max: 100000 },
    },
    amountPreset: ["100", "150", "200", "300", "400", "500"],
    defaultValue: "100",
  },
  AnindaHavale: {
    id: ChannelType.BANK_TRANSFER_TRY,
    icon: AnindaHavale,
    title: "profile.balance.icon.aninda",
    slug: "aninda",
    currency: getCurrency(CurrencyType.TRY),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.ANINDA,
    limit: {
      deposit: { min: 100, max: 30000 },
      withdraw: { min: 100, max: 30000 },
    },
    amountPreset: ["100", "150", "200", "300", "400", "500"],
    defaultValue: "100",
  },
  HavaleHera: {
    id: ChannelType.TRY_HAVALE_HERA,
    icon: HavaleHera,
    title: "profile.balance.icon.havalehera",
    slug: "hera",
    currency: getCurrency(CurrencyType.TRY),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYHERA,
    limit: {
      deposit: { min: 50, max: 100000 },
      withdraw: { min: 100, max: 100000 },
    },
    amountPreset: ["100", "150", "200", "300", "400", "500"],
    defaultValue: "100",
  },
  Corefy_TRY: {
    id: ChannelType.KZT_COREFY,
    icon: VisaMaster,
    title: "profile.balance.icon.bankcard.try",
    slug: "corefy-try",
    currency: getCurrency(CurrencyType.TRY),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.COREFY,
    limit: {
      deposit: { min: 100, max: 5000 },
      withdraw: { min: 100, max: 5000 },
    },
    amountPreset: ["100", "150", "200", "300", "400", "500"],
    defaultValue: "100",
  },
  Mpay_TRY: {
    id: ChannelType.MPAY_TRY,
    icon: VisaMaster,
    title: "Bank Cards",
    slug: "Bank Cards",
    currency: getCurrency(CurrencyType.TRY),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.MPAY,
    limit: {
      deposit: { min: 100, max: 5000 },
      withdraw: { min: 100, max: 5000 },
    },
    amountPreset: ["100", "150", "200", "300", "400", "500"],
    defaultValue: "100",
  },

  // Kazakhstan
  Corefy_KZN: {
    id: ChannelType.KZT_COREFY,
    icon: Visa,
    title: "Visa",
    slug: "corefy",
    currency: getCurrency(CurrencyType.KZT),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.COREFY,
    limit: {
      deposit: { min: 1500, max: 5000000 },
      withdraw: { min: 1500, max: 500000 },
    },
    amountPreset: ["1500", "3500", "5000", "8000", "11000", "15000"],
    defaultValue: "1500",
  },
  Constantpos: {
    id: ChannelType.CONSTANTPOS,
    icon: VisaMaster,
    title: "profile.balance.icon.bankcard.try",
    slug: "bankcards",
    currency: getCurrency(CurrencyType.KZT),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 1000, max: 50000 },
      withdraw: { min: 3000, max: 150000 },
    },
    amountPreset: ["2000", "5000", "7000", "15000", "25000", "50000"],
    defaultValue: "2000",
  },

  // Azerbaijan
  PayesBankCards: {
    id: ChannelType.PAYES_BANK_CARDS,
    icon: VisaMaster,
    title: "Bank Cards",
    slug: "payes-bank-cards",
    currency: getCurrency(CurrencyType.AZN),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYES,
    limit: {
      deposit: { min: 5, max: 5000 },
      withdraw: { min: 15, max: 5000 },
    },
    amountPreset: ["5", "15", "30", "45", "60", "90"],
    defaultValue: "5",
  },
  Corefy_AZN: {
    id: ChannelType.KZT_COREFY,
    icon: VisaMaster,
    title: "Bank Cards",
    slug: "corefy-azn",
    currency: getCurrency(CurrencyType.AZN),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.COREFY,
    limit: {
      deposit: { min: 15, max: 5000 },
      withdraw: { min: 30, max: 20000 },
    },
    amountPreset: ["15", "50", "70", "100", "150", "200"],
    defaultValue: "15",
  },
  Azarpay: {
    id: ChannelType.AZN_AZARPAY,
    icon: Kapital,
    title: "profile.balance.icon.bankcard.try",
    slug: "azarpay",
    currency: getCurrency(CurrencyType.AZN),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.AZARPAY,
    limit: {
      deposit: { min: 10, max: 5000 },
      withdraw: { min: 30, max: 20000 },
    },
    amountPreset: ["10", "50", "70", "100", "150", "200"],
    defaultValue: "10",
  },
  Azarviva: {
    id: ChannelType.AZARPAY_VISA,
    icon: Kapital,
    title: "profile.balance.icon.bankcard.try",
    slug: "azarpay",
    currency: getCurrency(CurrencyType.AZN),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.AZARPAY,
    limit: {
      deposit: { min: 10, max: 5000 },
      withdraw: { min: 30, max: 20000 },
    },
    amountPreset: ["10", "50", "70", "100", "150", "200"],
    defaultValue: "10",
  },
  VallerpayDeposit: {
    id: ChannelType.VALERPAY_DEPOSIT,
    icon: Kapital,
    title: "KapitalBank",
    slug: "vallerpay",
    currency: getCurrency(CurrencyType.AZN),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.VALLERPAY,
    limit: {
      deposit: { min: 5, max: 5000 },
      withdraw: { min: 30, max: 20000 },
    },
    amountPreset: ["5", "10", "20", "40", "50", "60"],
    defaultValue: "10",
  },
  VallerpayWithdrawal: {
    id: ChannelType.VALERPAY_WITHDRAWAL,
    icon: Kapital,
    title: "KapitalBank",
    slug: "vallerpay-withdrawal",
    currency: getCurrency(CurrencyType.AZN),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.VALLERPAY,
    limit: {
      deposit: { min: 5, max: 5000 },
      withdraw: { min: 30, max: 20000 },
    },
    amountPreset: ["5", "10", "20", "40", "50", "60"],
    defaultValue: "10",
  },
  eManat: {
    id: ChannelType.PAYES_EMANAT,
    icon: EManat,
    title: "Emanat",
    slug: "eManat",
    currency: getCurrency(CurrencyType.AZN),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYES,
    limit: {
      deposit: { min: 3, max: 500 },
      withdraw: { min: 5, max: 500 },
    },
    amountPreset: ["5", "10", "30", "50", "100", "150"],
    defaultValue: "5",
  },
  M10: {
    id: ChannelType.PAYES_M10,
    icon: M10,
    title: "M10",
    slug: "m10",
    currency: getCurrency(CurrencyType.AZN),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYES,
    limit: {
      deposit: { min: 5, max: 50000 },
      withdraw: { min: 5, max: 50000 },
    },
    amountPreset: ["5", "10", "30", "50", "100", "150"],
    defaultValue: "5",
  },
  SmartPay: {
    id: ChannelType.PAYES_SMART_PAY,
    icon: SmartPay,
    title: "Smart Pay",
    slug: "payes-smart-pay",
    currency: getCurrency(CurrencyType.AZN),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYES,
    limit: {
      deposit: { min: 15, max: 5000 },
      withdraw: { min: 15, max: 5000 },
    },
    amountPreset: ["15", "30", "50", "100", "150", "200"],
    defaultValue: "15",
  },

  // Myanmar
  Wavepay: {
    id: ChannelType.Wavepay,
    icon: Wavepay,
    title: "profile.balance.icon.wavepay",
    slug: "wavepay",
    currency: getCurrency(CurrencyType.MMK),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 6000, max: 1000000 },
      withdraw: { min: 20000, max: 630000 },
    },
    amountPreset: ["10000", "20000", "40000", "80000", "120000", "150000"],
    defaultValue: "10000",
  },
  Kpay: {
    id: ChannelType.Kpay,
    icon: Kpay,
    title: "profile.balance.icon.kpay",
    slug: "kpay",
    currency: getCurrency(CurrencyType.MMK),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 6000, max: 300000 },
      withdraw: { min: 20000, max: 630000 },
    },
    amountPreset: ["10000", "20000", "40000", "80000", "120000", "150000"],
    defaultValue: "10000",
  },

  // Pakistan
  ApayEasypaisa: {
    id: ChannelType.Easypaisa,
    icon: Easypaisa,
    title: "profile.balance.icon.easypaisa",
    slug: "apay-easypaisa",
    currency: getCurrency(CurrencyType.PKR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.APAY,
    limit: {
      deposit: { min: 900, max: 100000 },
      withdraw: { min: 500, max: 100000 },
    },
    amountPreset: ["1000", "1200", "1400", "1600", "1800", "2000"],
    defaultValue: "1000",
  },
  APayBankalfallah: {
    id: ChannelType.Bankalfalah,
    icon: VisaMaster,
    title: "profile.balance.icon.bankcard.try",
    slug: "bankalfallah",
    currency: getCurrency(CurrencyType.PKR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.APAY,
    limit: {
      deposit: { min: 500, max: 100000 },
      withdraw: { min: 500, max: 100000 },
    },
    amountPreset: ["500", "700", "900", "1100", "1300", "1500"],
    defaultValue: "500",
  },
  JAZZCASH_PAYKASSMA: {
    id: ChannelType.JAZZCASH_PAYKASSMA,
    icon: Jazzcash,
    title: "Jazzcash",
    slug: "jazzcash",
    currency: getCurrency(CurrencyType.PKR),
    requireEmail: true,
    getLink: (): string => "",
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 400, max: 20000 },
      withdraw: { min: 1000, max: 20000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1500", "2000"],
  },

  // India
  PayTM: {
    id: ChannelType.PayTM,
    icon: PayTM,
    title: "profile.balance.icon.paytm",
    slug: "paytm",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 300, max: 70000 },
      withdraw: { min: 5000, max: 40000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1200", "1500"],
    defaultValue: "600",
  },
  PhonePe: {
    id: ChannelType.PhonePe,
    icon: PhonePe,
    title: "profile.balance.icon.phonepe",
    slug: "phonepe",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 300, max: 50000 },
      withdraw: { min: 0, max: 0 },
    },
    amountPreset: ["400", "600", "800", "1000", "1200", "1500"],
    defaultValue: "600",
  },
  UPI_WALLET: {
    id: ChannelType.UPI,
    icon: UPI,
    title: "UPI Wallet",
    slug: "upi-wallet",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 300, max: 50000 },
      withdraw: { min: 500, max: 100000 },
    },
    amountPreset: ["300", "600", "800", "1000", "1500", "2000"],
    defaultValue: "300",
  },
  IMPS: {
    id: ChannelType.IMPS,
    icon: UPI,
    title: "profile.balance.icon.imps",
    slug: "upi",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 300, max: 50000 },
      withdraw: { min: 1000, max: 50000 },
    },
    amountPreset: ["300", "600", "800", "1000", "1500", "2000"],
    defaultValue: "300",
  },
  UPI: {
    id: ChannelType.UPI_FAST,
    icon: UPI,
    title: "UPI",
    slug: "upi",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.APAY,
    limit: {
      deposit: { min: 300, max: 50000 },
      withdraw: { min: 500, max: 100000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1500", "2000"],
    defaultValue: "600",
  },
  PAYTM_FAST: {
    id: ChannelType.UPI_S,
    icon: PayTM,
    title: "PayTM Fast",
    slug: "paytm-fast",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 300, max: 50000 },
      withdraw: { min: 2000, max: 50000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1500", "2000"],
    defaultValue: "600",
  },
  PHONEPE_FAST: {
    id: ChannelType.UPI_S,
    icon: PhonePe,
    title: "PhonePe Fast",
    slug: "phonepe-fast",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 300, max: 50000 },
      withdraw: { min: 2000, max: 50000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1500", "2000"],
    defaultValue: "600",
  },
  GOOGLE_PAY: {
    id: ChannelType.UPI_S,
    icon: GPay,
    title: "GooglePay",
    slug: "google-pay",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 300, max: 50000 },
      withdraw: { min: 2000, max: 50000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1500", "2000"],
    defaultValue: "600",
  },
  UPI_ARS: {
    id: ChannelType.UPI_ARS,
    icon: UPI_QR,
    title: "Upi ARS",
    slug: "upi-ars",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.APAY,
    limit: {
      deposit: { min: 300, max: 50000 },
      withdraw: { min: 500, max: 100000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1500", "2000"],
    defaultValue: "600",
  },
  UPI_ARF: {
    id: ChannelType.UPI_ARF,
    icon: UPI,
    title: "Upi ARF",
    slug: "upi-arf",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.APAY,
    limit: {
      deposit: { min: 300, max: 50000 },
      withdraw: { min: 500, max: 100000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1500", "2000"],
    defaultValue: "600",
  },
  APAY_UPI_FAST_V: {
    id: ChannelType.UPI_FAST_APAY,
    icon: UPI,
    title: "UPI",
    slug: "upi",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.APAY,
    limit: {
      deposit: { min: 500, max: 50000 },
      withdraw: { min: 500, max: 100000 },
    },
    amountPreset: ["600", "900", "1000", "1500", "2000", "2500"],
    defaultValue: "600",
  },
  CONSTANTPOS_PAYTM_FAST: {
    id: ChannelType.CONSTANTPOS_UPI,
    icon: PayTM,
    title: "PayTM Fast",
    slug: "paytm-fast",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.CONSTANTPOS,
    limit: {
      deposit: { min: 100, max: 100000 },
      withdraw: { min: 100, max: 200000 },
    },
    amountPreset: ["100", "300", "500", "700", "900", "1100"],
    defaultValue: "300",
  },
  CONSTANTPOS_PHONEPE_FAST: {
    id: ChannelType.CONSTANTPOS_UPI,
    icon: PhonePe,
    title: "PhonePe Fast",
    slug: "phonepe-fast",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.CONSTANTPOS,
    limit: {
      deposit: { min: 100, max: 100000 },
      withdraw: { min: 100, max: 200000 },
    },
    amountPreset: ["100", "300", "500", "700", "900", "1100"],
    defaultValue: "300",
  },
  CONSTANTPOS_GOOGLE_PAY: {
    id: ChannelType.CONSTANTPOS_UPI,
    icon: GPay,
    title: "GooglePay",
    slug: "google-pay",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.CONSTANTPOS,
    limit: {
      deposit: { min: 100, max: 100000 },
      withdraw: { min: 100, max: 200000 },
    },
    amountPreset: ["100", "300", "500", "700", "900", "1100"],
    defaultValue: "300",
  },
  // CONSTANTPOS_UPI: {
  //   id: ChannelType.CONSTANTPOS_UPI,
  //   icon: UPI,
  //   title: "UPI",
  //   slug: "constantpos-upi",
  //   currency: getCurrency(CurrencyType.INR),
  //   requireEmail: false,
  //   getLink: (): string => "",
  //   system: SystemTypes.CONSTANTPOS,
  //   limit: {
  //     deposit: { min: 100, max: 100000 },
  //     withdraw: { min: 100, max: 200000 },
  //   },
  //   amountPreset: ["100", "300", "500", "700", "900", "1100"],
  //   defaultValue: "300",
  // },
  CONSTANTPOS_IMPS: {
    id: ChannelType.CONSTANTPOS_IMPS,
    icon: UPI,
    title: "IMPS",
    slug: "constantpos-imps",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.CONSTANTPOS,
    limit: {
      deposit: { min: 100, max: 100000 },
      withdraw: { min: 1000, max: 500000 },
    },
    amountPreset: ["100", "300", "500", "700", "900", "1100"],
    defaultValue: "100",
  },
  APAY_IMPS: {
    id: ChannelType.APAY_IMPS,
    icon: UPI,
    title: "IMPS",
    slug: "apay-imps",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.APAY,
    limit: {
      deposit: { min: 300, max: 50000 },
      withdraw: { min: 1000, max: 50000 },
    },
    amountPreset: ["300", "600", "800", "1000", "1500", "2000"],
    defaultValue: "300",
  },
  PayTM_PAYCORD: {
    id: ChannelType.PAYCORD_UPI_INTENT,
    icon: PayTM,
    title: "PayTM",
    slug: "paytm",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYCORD,
    limit: {
      deposit: { min: 300, max: 70000 },
      withdraw: { min: 5000, max: 40000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1200", "1500"],
    defaultValue: "600",
  },
  PhonePe_PAYCORD: {
    id: ChannelType.PAYCORD_UPI_COLLECT,
    icon: PhonePe,
    title: "PhonePe",
    slug: "phonepe",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYCORD,
    limit: {
      deposit: { min: 300, max: 50000 },
      withdraw: { min: 0, max: 0 },
    },
    amountPreset: ["400", "600", "800", "1000", "1200", "1500"],
    defaultValue: "600",
  },
  UPI_PAYCORD: {
    id: ChannelType.PAYCORD_UPI_P2P,
    icon: UPI,
    title: "UPI",
    slug: "upi-paycord",
    currency: getCurrency(CurrencyType.INR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYCORD,
    limit: {
      deposit: { min: 300, max: 50000 },
      withdraw: { min: 500, max: 100000 },
    },
    amountPreset: ["300", "600", "800", "1000", "1500", "2000"],
    defaultValue: "300",
  },

  // Pakistan
  PayKassmaEasypaisTwo: {
    id: ChannelType.Easypaisa_2,
    icon: Easypaisa,
    title: "profile.balance.icon.easypaisa",
    slug: "easypaisa-2",
    currency: getCurrency(CurrencyType.PKR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 500, max: 150000 },
      withdraw: { min: 1500, max: 150000 },
    },
    amountPreset: ["1000", "1200", "1500", "1700", "2000", "2500"],
    defaultValue: "1000",
  },

  // Kenia
  Mpesa: {
    id: ChannelType.MPESA,
    icon: Mpesa,
    title: "profile.balance.icon.mpesa",
    slug: "mpesa",
    currency: getCurrency(CurrencyType.KES),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 400, max: 150000 },
      withdraw: { min: 1500, max: 150000 },
    },
    amountPreset: ["400", "600", "800", "1000", "1200", "1400"],
    defaultValue: "600",
  },

  // Brazil
  Pix: {
    id: ChannelType.Direct24_PIX,
    icon: Pix,
    title: "Pix",
    slug: "pix",
    currency: getCurrency(CurrencyType.BRL),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.DIRECT24,
    limit: {
      deposit: { min: 25, max: 50000 },
      withdraw: { min: 25, max: 15000 },
    },
    amountPreset: ["35", "50", "100", "150", "200", "300"],
    defaultValue: "35",
  },
  Boleto: {
    id: ChannelType.Direct24_Boleto,
    icon: Boleto,
    title: "Boleto",
    slug: "boleto",
    currency: getCurrency(CurrencyType.BRL),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.DIRECT24,
    limit: {
      deposit: { min: 25, max: 50000 },
      withdraw: { min: 25, max: 15000 },
    },
    amountPreset: ["35", "50", "100", "150", "200", "300"],
    defaultValue: "35",
  },

  // Sri Lanka
  Upay: {
    id: ChannelType.Upay,
    icon: Upay,
    title: "Upay",
    slug: "Upay",
    currency: getCurrency(CurrencyType.LKR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 1000, max: 180000 },
      withdraw: { min: 3000, max: 100000 },
    },
    amountPreset: ["2000", "5000", "7000", "15000", "50000", "150000"],
    defaultValue: "2000",
  },
  BankTransfer: {
    id: ChannelType.Banktransfer,
    icon: BankTransfer,
    title: "Bank Transfer",
    slug: "Banktransfer",
    currency: getCurrency(CurrencyType.LKR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 1000, max: 180000 },
      withdraw: { min: 3000, max: 100000 },
    },
    amountPreset: ["2000", "5000", "7000", "15000", "50000", "150000"],
    defaultValue: "2000",
  },
  Ipay: {
    id: ChannelType.Ipay,
    icon: Ipay,
    title: "Ipay",
    slug: "ipay",
    currency: getCurrency(CurrencyType.LKR),
    requireEmail: false,
    getLink: (): string => "",
    system: SystemTypes.PAYKASSMA,
    limit: {
      deposit: { min: 1000, max: 180000 },
      withdraw: { min: 3000, max: 100000 },
    },
    amountPreset: ["2000", "5000", "7000", "15000", "25000", "50000"],
    defaultValue: "2000",
  },
};

export default paymentMethod;
