import { BehaviorSubject } from 'rxjs';

import AmountFormService from './AmountForm';
import StatusFormPopupService, {StatusForm} from '../../DepositPopup/service/StatusForm';
import {CoinspaidDepositRateResponse, CoinspaidDepositResponse} from '@ay_tsarbet/newbet-core/dist/types';

enum DepositState {
    AMOUNT,
    WAIT,
    DATA,
    FAILED,
    SUCCESS,
}
enum PaymentType {
    NONE,
    PAYTM,
    PHONE_PEE,
    GOOGLE_PAY,
}

class CoinspaidDeposit {
    amount = 0;

    rate = new BehaviorSubject<CoinspaidDepositRateResponse | null>(null);

    wallet = new BehaviorSubject('');

    tag = new BehaviorSubject('');

    state = new BehaviorSubject<DepositState>(DepositState.AMOUNT);

    setAmount(amount: number) {
        this.amount = amount;
    }

    getAmount(): number {
        return this.amount;
    }

    reset() {
        this.amount = 0;
        this.wallet.next('');
        this.tag.next('');
        this.state.next(DepositState.AMOUNT);
        AmountFormService.reset();
    }

    onError() {
        this.state.next(DepositState.FAILED);
        StatusFormPopupService.status.next(StatusForm.FAILED);
    }

    onRateReceive(data: CoinspaidDepositRateResponse) {
        console.log('onRateReceive', data)
        this.state.next(DepositState.DATA)
        this.rate.next(data)
    }
    onReceive(data: CoinspaidDepositResponse) {
        console.log('onReceive', data);
        this.wallet.next(data.message);
        this.tag.next(data.tag);
    }

    back() {
        this.reset();
    }
}

const CoinspaidDepositService = new CoinspaidDeposit();

export { DepositState, PaymentType };
export default CoinspaidDepositService;
