import classNames from 'classnames';
import { createRef } from 'react';
import { translate as t } from 'service/Language';

import RxComponent from '../RxComponent/RxComponent';
import InputService from './service/Input';
import CopyImage from 'assets/svg/copy.svg';

import './Input.scss';

enum InputSize {
    medium,
    small,
    xsmall,
    xxsmall,
}

type InputProps = {
    className?: string;
    placeholder?: string;
    type?: string;
    name?: string;
    inputService: InputService;
    hint?: string;
    size?: InputSize;
    enabled?: boolean;
    autocomplete?: string;
    isUpperCase?: boolean;
    onChange?: (event: any) => void;
    copy?: boolean;
};

type InputState = {
    error: string | null;
    active: boolean;
    title: string;
    value: string;
    autoCompleted: boolean;
};

class Input<P extends InputProps, S extends InputState> extends RxComponent<P, S> {
    ref = {
        input: createRef<HTMLInputElement>(),
    };

    handler = {
        onClick: this.onClick.bind(this),
    };

    constructor(props: P) {
        super(props);

        this.state = {
            error: null,
            active: false,
            title: '',
            value: '',
            autoCompleted: false,
        } as S;
    }

    getInputProps() {
        const {
            inputService,
            placeholder,
            type = 'text',
            name = '',
            enabled = true,
            autocomplete = 'off',
            isUpperCase = false,
            copy,
            onChange,
        } = this.props;

        const { error } = this.state;

        const fieldClassName = classNames({
            input__field: true,
            input__field__error: error !== null,
            input__field_autocomplete: this.state.autoCompleted,
        });

        const onChangeHandle = onChange ? onChange : (event: any) => inputService.onChange(event, isUpperCase);
        const onBlur = () => inputService.onBlur();
        const onFocus = () => inputService.onFocus();
        return {
            className: fieldClassName,
            name,
            type,
            autocomplete,
            onChange: onChangeHandle,
            onBlur,
            onFocus,
            placeholder: this.state.active ? placeholder : '',
            defaultValue: inputService.getValue(),
            value: inputService.getValue(),
            disabled: !enabled,
            ref: this.ref.input,
        };
    }

    onClick() {
        if (this.ref.input.current) {
            this.ref.input.current.focus();
        }
    }

    handleCopy = () => {
        navigator.clipboard.writeText(this.state.value);
    };

    getInput() {
        const inputProps = this.getInputProps();
        return <input {...inputProps} />;
    }

    componentDidMount() {
        const { inputService } = this.props;

        this.subscribe(inputService.error, { next: (error) => this.setState({ error }) });
        this.subscribe(inputService.active, { next: (active) => this.setState({ active }) });
        this.subscribe(inputService.title, { next: (title) => this.setState({ title }) });
        this.subscribe(inputService.value, { next: (value) => this.setState({ value }) });
        this.subscribe(inputService.autoCompleted, { next: (autoCompleted) => this.setState({ autoCompleted }) });
      }

    render() {
        const { className = '', hint = '', size = InputSize.medium, enabled = true, copy } = this.props;
        const { error, active, title } = this.state;

        const sizeMap = new Map<InputSize, string>([
            [InputSize.small, 'input____small'],
            [InputSize.xsmall, 'input____xsmall'],
            [InputSize.xxsmall, 'input____xxsmall'],
        ]);

        const sizeClass = sizeMap.get(size) || 'input____unknown';

        const containerClassName = classNames({
            input: true,
            input____active: active,
            input____disabled: !enabled,
            [sizeClass]: true,
            [className]: !!className,
        });

        const titleClassName = classNames({
            input__title: true,
            input__title__error: error !== null,
        });

        const hintClassName = classNames({
            input__hint: true,
            input__hint_error: error !== null,
        });

        return (
            <div className={containerClassName}>
                {this.getInput()}
                <span className={titleClassName} onClick={this.handler.onClick}>
                    <span>{t(title)}</span>
                    {hint ? <span className={hintClassName}>{t(hint)}</span> : null}
                </span>
                {copy ? <img className="input__copy" src={CopyImage} alt="copy" onClick={this.handleCopy} />: null }
            </div>
        );
    }
}

export { InputSize };
export type { InputProps, InputState };
export default Input;
