import { useEffect, useMemo } from 'react';

import RouteConfig from '../../appConstants/RouteConfig';
import CategoryHeader from '../../component/CategoryHeader';
import AuthService from '../../service/Auth';
import GamesService from '../../service/Games';
import useObservable from '../../utils/useObservable';
import GameList from '../Casino/GameList/GameList';
import CountryBlocked from '../Casino/Provider/CountryBlocked/CountryBlocked';
import CurrencyBlocked from '../Casino/Provider/CurrencyBlocked/CurrencyBlocked';

function GloryGames() {
    const providers = useObservable(GamesService.allowedProviders, GamesService.cache.allowedProvider);
    const isAuth = useObservable(AuthService.auth, AuthService.auth.getValue());

    const blockedProvider = isAuth ? <CurrencyBlocked/> : <CountryBlocked/>;

    const gloryList = useMemo(() => {
        const gloryProvider = providers.find(item => item.name.trim() === 'Glory Games');

        if (gloryProvider && gloryProvider.gameList) {
            return gloryProvider.gameList
        }
        return []
    }, [providers])
    
    return gloryList.length ? (
        <div>
            <CategoryHeader title="Glory Games" backUrl={RouteConfig.casino.root} />
            <GameList games={gloryList}/>
        </div>
    ) : (
        blockedProvider
    );
}
export default GloryGames;
