import classNames from 'classnames';
import { translate as t } from 'service/Language';
import Spinner from '../../Other/Button/image/Spinner.svg';
import PaykassmaDepositService, { DepositState } from '../../service/PaykassmaDeposit';
import useObservable from '../../../../../../../utils/useObservable';
import AmountFormService from '../../service/AmountForm';

import './Submit.scss';

type SubmitProps = {
    className?: string;
};

function Submit(props: SubmitProps) {
    const { className } = props;
    const valid = useObservable(AmountFormService.valid, AmountFormService.valid.getValue());
    const status = useObservable(PaykassmaDepositService.state, PaykassmaDepositService.state.getValue());
    const isDisabled = status === DepositState.WAITINVOICE || status === DepositState.WAIT

    const _className = classNames({
        [className || '']: !!className,
        'deposit-submit': true,
        'deposit-submit__active': valid,
    });
    return (
        <button type="submit" className={_className}
            disabled={!valid || isDisabled}>
            { isDisabled ? (
                <img className="deposit-submit__wait" src={Spinner} alt="" />
            ) : (
                t('profile.balance.deposit.button.next')
            )}
        </button>
    );
}

export default Submit;
