import colors from 'appConstants/colors';
import RouteConfig from 'appConstants/RouteConfig';
import { NavLink } from 'react-router-dom';
import SvgImage from 'svg';

import FavoriteGameService from '../../../service/FavoriteGame';
import { translate as t } from '../../../service/Language';
import useObservable from '../../../utils/useObservable';
import ZeroScreen from '../Category/ZeroScreen/ZeroScreen';
import GameList from '../GameList/GameList';

import './Favorite.scss';

function Favorite() {
    const games = useObservable(FavoriteGameService.list, FavoriteGameService.list.getValue());
    return (
        <div className="favorite">
            <div className="favorite__header">
                <div className="favorite__header_left">
                    {window.location.pathname !== RouteConfig.casino.root && (
                        <NavLink to={RouteConfig.casino.root} className="favorite__backurl">
                            <SvgImage color={colors.brightGray} width={24} height={24} name="LeftFlatArrow" />
                        </NavLink>
                    )}
                    <h2 className="favorite__title">{t('casino.sidebar.favorite')}</h2>
                </div>
            </div>
            {games.length ? <GameList games={games} /> : <ZeroScreen />}
        </div>
    );
}

export default Favorite;
