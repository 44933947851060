import { PaycosDepositRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaycosDepositRequest';
import { PaycosWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaycosWithdraw';
import { PaycosDepositResponse, PaycosWithdrawCode, PaycosWithdrawResponse } from '@ay_tsarbet/newbet-core/dist/types';
import PaycosDepositService from 'page/Profile/Balance/Deposit/Paycos/service/PaycosDeposit';
import { BehaviorSubject, Subject } from 'rxjs';

import PaycosInterface from '../interface/payment/Paycos';
import WSBet from '../WSBet';

enum PaycosError {
    UNKNOWN,
    DEPOSIT,
    WITHDRAW,
}

class Paycos implements PaycosInterface {
    error = new Subject<PaycosError>();

    redirect = new BehaviorSubject<string | null>(null);

    withdrawResult = new Subject<void>();

    deposit(request: PaycosDepositRequest) {
        WSBet.payment.paycos.deposit(request);
    }

    withdraw(withdraw: PaycosWithdrawRequest) {
        WSBet.payment.paycos.withdraw(withdraw);
    }

    onWithdraw(response: PaycosWithdrawResponse) {
        if (response.errorCode >= PaycosWithdrawCode.success) {
            this.withdrawResult.next();
        } else {
            this.error.next(PaycosError.WITHDRAW);
        }
    }

    onReceiveDeposit(data: PaycosDepositResponse) {
        if (!data.errorCode) {
            PaycosDepositService.onReceive(data.message);
        }
    }
}

export { PaycosError };
export default Paycos;
