import { NavLink } from 'react-router-dom';
import LanguageService, { translate as t } from 'service/Language';
import SvgImage from 'svg';
import useObservable from 'utils/useObservable';

import colors from '../../../../../appConstants/colors';
import FooterService from '../../../../../component/Footer/service/Footer';
import RegistrationFormService from '../service/registration';

import './AcceptCheckbox.scss';

type AcceptCheckboxType = {
    type?: string;
};

function AcceptCheckbox(props: AcceptCheckboxType) {
    const { type } = props;

    const acceptCheckboxStatus = useObservable<boolean>(
        RegistrationFormService.termsAgreement,
        RegistrationFormService.termsAgreement.value,
    );

    const currentLang = useObservable(LanguageService.current, LanguageService.current.getValue());

    if (type !== 'criket') return null;
    return (
        <div className='registration-criket-accept-checkbox'>
            <button
                onClick={() => RegistrationFormService.toggleTermsAndCondition()}
                className='registration-criket-accept-checkbox__check'
                type='button'
            >
                {acceptCheckboxStatus && (
                    <SvgImage width={9} height={9} color={colors.royalBlue} name='Check' />
                )}
            </button>
            <div>
                {currentLang.title !== 'UZ' && (
                    <span>{t('registration.accept.checkbox.accept')} </span>
                )}
                <NavLink
                    to={FooterService.documentList[1].path}
                    className='registration-criket-accept-checkbox__link'
                >
                    {t('registration.accept.checkbox.privacy')}
                </NavLink>

                {currentLang.title === 'UZ' && (
                    <span>{t('registration.accept.checkbox.accept')}</span>
                )}
            </div>
        </div>
    );
}

export default AcceptCheckbox;
