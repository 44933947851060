import { BehaviorSubject } from 'rxjs';
import PaymentService from 'service/Payment';
import StatusFormPopupService, { StatusForm } from '../../../Deposit/DepositPopup/service/StatusForm';
import CashixFormService from './CashixForm';


enum CashixWithdrawState {
    INIT,
    COMPLETE,
    AWAIT,
    HAS_FEE,
    ACCEPT,
    REJECT,
    BONUS_REJECT,
}

class CashixWithdraw {
    state = new BehaviorSubject<CashixWithdrawState>(CashixWithdrawState.INIT);

    handler = {
        onWithdraw: this.onWithdraw.bind(this),
        onError: this.onError.bind(this),
    };

    constructor() {
        PaymentService.cashix.withdrawResult.subscribe(this.handler.onWithdraw);
        PaymentService.cashix.error.subscribe(this.handler.onError);
    }

    onWithdraw() {
        this.state.next(CashixWithdrawState.ACCEPT);
        StatusFormPopupService.status.next(StatusForm.SUCCESS);
    }

    onError() {
        this.state.next(CashixWithdrawState.REJECT);
        StatusFormPopupService.status.next(StatusForm.FAILED);
    }

    reset() {
        this.state.next(CashixWithdrawState.INIT);
        StatusFormPopupService.status.next(StatusForm.INIT);
        CashixFormService.reset();
    }
}

const CashixWithdrawService = new CashixWithdraw();

export { CashixWithdrawState };
export default CashixWithdrawService;
