/* eslint-disable max-len */

import { Icon } from 'types';

function CasinoTableGames({ color, width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 11.9999C0.895431 11.9999 0 12.8953 0 13.9999V21.9999C0 23.1045 0.895431 23.9999 2 23.9999H10C11.1046 23.9999 12 23.1045 12 21.9999V13.9999C12 12.8953 11.1046 11.9999 10 11.9999H2ZM6 18.9999C6.55228 18.9999 7 18.5522 7 17.9999C7 17.4476 6.55228 16.9999 6 16.9999C5.44772 16.9999 5 17.4476 5 17.9999C5 18.5522 5.44772 18.9999 6 18.9999ZM5 15.9999C5 16.5522 4.55228 16.9999 4 16.9999C3.44772 16.9999 3 16.5522 3 15.9999C3 15.4476 3.44772 14.9999 4 14.9999C4.55228 14.9999 5 15.4476 5 15.9999ZM8 16.9999C8.55229 16.9999 9 16.5522 9 15.9999C9 15.4476 8.55229 14.9999 8 14.9999C7.44772 14.9999 7 15.4476 7 15.9999C7 16.5522 7.44772 16.9999 8 16.9999ZM5 19.9999C5 20.5522 4.55228 20.9999 4 20.9999C3.44772 20.9999 3 20.5522 3 19.9999C3 19.4476 3.44772 18.9999 4 18.9999C4.55228 18.9999 5 19.4476 5 19.9999ZM8 20.9999C8.55229 20.9999 9 20.5522 9 19.9999C9 19.4476 8.55229 18.9999 8 18.9999C7.44772 18.9999 7 19.4476 7 19.9999C7 20.5522 7.44772 20.9999 8 20.9999Z"
                    fill={color ?? 'url(#paint0_linear)'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.27142 6.24362C8.49037 7.02467 8.49037 8.291 9.27142 9.07205L14.9283 14.7289C15.7093 15.5099 16.9757 15.5099 17.7567 14.7289L23.4136 9.07204C24.1946 8.29099 24.1946 7.02466 23.4136 6.24362L17.7567 0.586763C16.9756 -0.194286 15.7093 -0.194285 14.9283 0.586763L9.27142 6.24362ZM17.0498 8.36494C17.4403 7.97441 17.4403 7.34125 17.0498 6.95072C16.6593 6.5602 16.0261 6.5602 15.6356 6.95073C15.2451 7.34125 15.2451 7.97441 15.6356 8.36494C16.0261 8.75546 16.6593 8.75546 17.0498 8.36494ZM17.0498 4.12211C17.4403 4.51264 17.4403 5.1458 17.0498 5.53632C16.6593 5.92685 16.0261 5.92685 15.6356 5.53633C15.2451 5.1458 15.2451 4.51264 15.6356 4.12211C16.0261 3.73159 16.6593 3.73159 17.0498 4.12211ZM17.0498 11.1936C17.4403 10.803 17.4403 10.1699 17.0498 9.77934C16.6593 9.38881 16.0261 9.38881 15.6356 9.77934C15.2451 10.1699 15.2451 10.803 15.6356 11.1936C16.0261 11.5841 16.6593 11.5841 17.0498 11.1936Z"
                    fill={color ?? 'url(#paint1_linear)'}
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="0.545439"
                    y1="5.26903"
                    x2="20.773"
                    y2="10.0965"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="0.545439"
                    y1="5.26903"
                    x2="20.773"
                    y2="10.0965"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FC4665" />
                    <stop offset="0.994792" stopColor="#3959D9" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default CasinoTableGames;
