import { translate as t } from 'service/Language';

import './Cookies.scss';

type ListType = {
    id: number;
    header: string;
    title: string;
    description: string;
};

const textList: ListType[] = [
    {
        id: 1,
        header: 'cookies.header',
        title: 'cookies.information.title',
        description: 'cookies.information.description',
    },
    {
        id: 2,
        header: '',
        title: 'cookies.strictly.title',
        description: 'cookies.strictly.description',
    },
    {
        id: 3,
        header: '',
        title: 'cookies.during.title',
        description: 'cookies.during.description',
    },
    {
        id: 4,
        header: '',
        title: 'cookies.website.title',
        description: 'cookies.website.description',
    },
    {
        id: 5,
        header: '',
        title: 'cookies.flash.title',
        description: 'cookies.flash.description',
    },
];

function Cookies() {
    return (
        <div className="cookies">
            {textList.map((item) => (
                <>
                    {item.header && <p className="cookies__header">{t(item.header)}</p>}
                    {item.title && <p className="cookies__title">{t(item.title)}</p>}
                    <p className="cookies__description">{t(item.description)}</p>
                </>
            ))}
        </div>
    );
}

export default Cookies;
