import { GameSessionSlotegrator } from '@ay_tsarbet/newbet-core/dist/types';
import { BehaviorSubject } from 'rxjs';

import GamesService from './Games';
import Game from './model/Game';
import WSBet from './WSBet';

class GameHistory {
    gameHistoryList = new BehaviorSubject<(Game | null)[]>([]);

    gameHistory = new BehaviorSubject<Game | null>(null);

    onReceiveGame(data: GameSessionSlotegrator) {
        const gameHistory = GamesService.findGame(data.gameId);
        this.gameHistory.next(gameHistory);
    }

    onReceiveGameList(data: GameSessionSlotegrator[]) {
        const dateList = data.sort((a, b) => {
            return b.sessionDateStart - a.sessionDateStart;
        });
        const gameIdList = dateList.map((item) => item.gameId);
        const uniqueArray = gameIdList.filter(function (item, pos) {
            return gameIdList.indexOf(item) === pos;
        });
        const gameList = uniqueArray.map((id) => GamesService.findGame(id));

        this.gameHistoryList.next(gameList);
    }

    getGameHistory() {
        WSBet.slots.request();
    }
}

export const GameHistoryService = new GameHistory();
