import {
    PAYMENTS_CHANNEL_BY_CURRENCY_TYPE,
    PAYMENT_SYSTEMS_CHANNEL_TYPE,
    PAYMENT_SYSTEMS_LIMITS_TYPE,
    PAYMENT_SYSTEMS_TYPE,
} from 'types/payments';
import JSON from './JSON';

export type PaymentRecordList = {
    currency_map_channels: PAYMENTS_CHANNEL_BY_CURRENCY_TYPE[];
    payment_systems: PAYMENT_SYSTEMS_TYPE[];
    payment_systems_channels: PAYMENT_SYSTEMS_CHANNEL_TYPE[];
    payment_systems_limits: PAYMENT_SYSTEMS_LIMITS_TYPE[];
    payment_systems_add_props: [];
};

class Payment extends JSON {
    getList(): Promise<PaymentRecordList> {
        return this.get<PaymentRecordList>('/backoffice/payments_glory_adv.json', {}).then(
        // return this.get<PaymentRecordList>('/backoffice/payments_glory.json', {}).then(
            response => response.data,
        );
    }
}

export default Payment;
