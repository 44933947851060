/* eslint-disable max-len */

import { Icon } from 'types';

function ShieldIcon({ color = '#3F4250', width = 24, height = 24 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                d="M4.00021 6.55952L4.20693 12.7744C4.20693 18.405 11.276 20.875 11.5769 20.9776C11.6214 20.9924 11.6677 21 11.7143 21C11.7608 21 11.8072 20.9924 11.8517 20.9774C12.1528 20.875 19.2216 18.405 19.2214 12.787L19.4284 6.55932C19.435 6.35834 19.286 6.18376 19.0805 6.15156C18.2574 6.02337 15.561 5.48442 15.4215 3.92636C15.407 3.76358 15.2914 3.62559 15.1293 3.5782L14.5533 3.40901C12.6959 2.86366 10.7322 2.86366 8.8751 3.40901L8.29903 3.5782C8.13722 3.62559 8.02155 3.76358 8.00685 3.92636C7.8676 5.48442 5.17098 6.02337 4.34784 6.15156C4.14237 6.18376 3.99359 6.35834 4.00021 6.55952ZM8.78881 4.27053L9.11555 4.17454C10.8156 3.67559 12.6127 3.67559 14.3128 4.17454L14.6396 4.27053C14.9197 5.52662 16.332 6.46113 18.5895 6.88029L18.394 12.7742C18.394 17.4315 12.681 19.8043 11.7143 20.1721C10.7475 19.8043 5.03462 17.4305 5.03441 12.7614L4.83908 6.88029C7.0966 6.46113 8.50864 5.52662 8.78881 4.27053Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.67243 6.98988C7.23842 7.221 6.74442 7.4199 6.20172 7.58209C6.02356 7.63528 5.90437 7.79707 5.91037 7.97705L6.06805 12.722L6.06908 12.7738C6.06908 16.2387 9.89715 18.2931 11.543 19.0154C11.5974 19.0394 11.6558 19.0512 11.7141 19.0512C11.7725 19.0512 11.8308 19.0392 11.8853 19.0154C12.4508 18.7674 13.2607 18.3676 14.088 17.8104C14.3328 17.6631 14.8976 17.2247 15.3755 16.7903C15.3942 16.7777 15.4122 16.7635 15.429 16.7475C15.5029 16.6777 15.5746 16.6074 15.6443 16.5368C15.7663 16.4167 15.8747 16.3017 15.9601 16.1986L15.9595 16.1979C16.8888 15.1364 17.3592 13.9874 17.3592 12.7741L17.359 12.7579L17.5179 7.97731C17.5239 7.79732 17.4047 7.63574 17.2266 7.58234C15.5844 7.09139 14.4337 6.28106 13.8988 5.23896C13.845 5.13397 13.7461 5.05697 13.6284 5.02858C12.3779 4.7268 11.0499 4.7268 9.79948 5.02858C9.68195 5.05697 9.58304 5.13377 9.52904 5.23896C9.35245 5.58293 9.10693 5.90454 8.7982 6.19659C8.40722 6.56265 7.96411 6.83155 7.67349 6.98761C7.67314 6.98837 7.67278 6.98912 7.67243 6.98988ZM9.24224 6.89157C9.2379 6.89539 9.23356 6.8992 9.22921 6.903C9.22406 6.9075 9.21882 6.91185 9.21351 6.91605C8.83253 7.23829 8.28847 7.60754 7.88102 7.78882C7.87978 7.78934 7.87854 7.78985 7.8773 7.79035C7.52913 7.96284 7.15122 8.11708 6.74779 8.25123L6.89677 12.7676C6.89677 15.6643 10.1364 17.4924 11.7141 18.2105C12.2349 17.9734 12.9243 17.6202 13.6241 17.1478L13.6266 17.1461C13.7199 17.0831 13.8133 17.018 13.9065 16.9508C14.3398 16.6289 14.875 16.1582 15.1422 15.8843C15.1762 15.8485 15.2096 15.8125 15.2424 15.7765C15.2484 15.7695 15.254 15.7628 15.2593 15.7564C15.2639 15.7501 15.2691 15.7449 15.2748 15.7405C16.1093 14.8088 16.5315 13.8131 16.5315 12.7741V12.7451L16.6807 8.25148C15.0582 7.71173 13.88 6.85761 13.2499 5.76411C12.243 5.54773 11.1852 5.54773 10.1784 5.76411C10.003 6.06846 9.78423 6.35583 9.52472 6.62294C9.45162 6.70334 9.35471 6.79504 9.24224 6.89157Z"
                fill={color}
            />
        </svg>
    );
}

export default ShieldIcon;
