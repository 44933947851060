import Validator from '../interface/Validator';

class MaskCardLunh implements Validator {
    validate(value: string): boolean {
        return this.length(value) && this.lunh(value)
    }
    length(value: string): boolean {
        return value.length === 19;
    }
    lunh(value: string): boolean {
        let checksum = 0;
        const cardnumbers = value.replaceAll(' ', '').split('').map(Number);
        // @ts-ignore
        for (const [index, num] of cardnumbers.entries()) {
            if (index % 2 === 0) {
                let buffer = num * 2;
                buffer > 9 ? checksum += buffer - 9 : checksum += buffer;
            }
            else {
                checksum += num;
            }
        }
        return checksum % 10 === 0;
    }
}

export default MaskCardLunh;
