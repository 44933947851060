import { BehaviorSubject } from 'rxjs';

const activityEvents = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];

class ActivityDetection {
    handler = {
        onActivity: this.activity.bind(this),
    };

    isDeteсted = new BehaviorSubject<boolean>(false);

    activity() {
        if (this.isDeteсted && !this.isDeteсted.getValue()) {
            this.isDeteсted.next(true);
        }
    }

    watch() {
        activityEvents.forEach(eventName =>
            document.addEventListener(eventName, this.handler.onActivity, true),
        );
    }

    unwatch() {
        activityEvents.forEach(eventName =>
            document.removeEventListener(eventName, this.handler.onActivity),
        );
    }
}

const ActivityDetectionService = new ActivityDetection();

export default ActivityDetectionService;
