import BonusService from 'service/Bonus';
import { translate as t } from 'service/Language';
import Bonus from 'service/model/Bonus';
import PopupService from 'service/Popup';

import AuthService from '../../../../service/Auth';
import GoogleService from '../../../../service/Google';
import useObservable from '../../../../utils/useObservable';
import Close from '../../../../component/Close/Close';

import './PopupCancel.scss';

function PopupCancel(props: { bonus: Bonus }) {
    const userId = useObservable(AuthService.auth, AuthService.auth.getValue())?.userId.toString();

    const handleSubmit = () => {
        if (userId) {
            GoogleService.openBonusCancel(userId);
        }
        BonusService.onCancelBonus(props.bonus);
        PopupService.close();
    };
    return (
        <div className="popup-cancel">
            <div className="popup-cancel__close">
                <Close size="s"/>
            </div>
            <div className="popup-cancel__title"> {t('bonus.cancel.popup')}</div>
            <div className="popup-cancel-button">
                <div onClick={handleSubmit} className="popup-cancel-button__yes">
                    {t('bonus.cancel.popup.yes')}
                </div>
                <div onClick={(e) => PopupService.close()} className="popup-cancel-button__no">
                    {t('bonus.cancel.popup.no')}
                </div>
            </div>
        </div>
    );
}

export default PopupCancel;
