import { add, getUnixTime } from 'date-fns';

import OperationsProviderInterface from '../interface/OperationsProviderInterface';

class OperationsProvider {
    handler: OperationsProviderInterface;

    constructor(handler: OperationsProviderInterface) {
        this.handler = handler;
    }

    request() {
        const toTimeStamp = getUnixTime(
            add(new Date(), {
                hours: 300, // Из-за того, что старые пользователи регистрировались с часовым поясом -250 часов (Андрей)
            })
        );
        this.handler.sendGetOperations(0, toTimeStamp);
    }

    sendGAClientId(clientId: string, invoiceId: string) {
        this.handler.sendGAClientId(clientId, invoiceId);
    }
}

export default OperationsProvider;
