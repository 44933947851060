class Utils {
    public static isiOS() {
        return new RegExp('(iphone|ipad)', 'i').test(navigator.userAgent);
    }

    public static isSafari() {
        return navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
    }
}

export default Utils;
