import BonusWidget from 'component/BonusWidget/BonusWidget';
import useObservable from 'utils/useObservable';
import BonusService from '../../../../service/Bonus';
import BalanceService from '../../../../service/Balance';
import SideNavigation from './SideNavigation/SideNavigation';

import './SideBar.scss';

function SideBar() {
    const balance = useObservable(BalanceService.balance, BalanceService.balance.getValue());
    const bonuses = useObservable(BonusService.list, BonusService.list.getValue());
    const isFirstDepositBonus = bonuses.find(bonus => bonus.channelType === 50)

    return (
        <div className="side-bar">
            {balance && isFirstDepositBonus ? <BonusWidget /> : null}
            <SideNavigation />
        </div>
    );
}

export default SideBar;
