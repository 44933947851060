import { format } from 'date-fns';

import Tournament from '../../../service/model/Tournament';

import './Period.scss';

type PeriodProps = {
    tournament: Tournament;
};

function Period(props: PeriodProps) {
    const { tournament } = props;

    return (
        <div className="tournament-period">
            {format(tournament.startDate, 'dd/MM/yy')} &mdash; {format(tournament.endDate, 'dd/MM/yy')}
        </div>
    );
}

export default Period;
