import classNames from 'classnames';
import { translate as t } from 'service/Language';
import useObservable from 'utils/useObservable';
import { PaymentMethod } from '../../../PaymentMethod/types';
import Spinner from '../../../Deposit/Paykassma/Other/Button/image/Spinner.svg';
import PayfixWithdrawService, { PayfixWithdrawState } from '../service/PayfixWithdraw';
import CardFormService from '../service/CardForm';

import './WithdrawalButton.scss';


type WithdrawalButtonType = {
    buttonTitle: string;
    variation?: boolean;
    paymentMethod?: PaymentMethod;
    amount?: string;
};

function WithdrawalButton(props: WithdrawalButtonType) {
    const { buttonTitle, variation, paymentMethod, amount } = props;
    const valid = useObservable<boolean>(CardFormService.valid, false);
    const state = useObservable(PayfixWithdrawService.state, PayfixWithdrawService.state.getValue());

    const min = paymentMethod && paymentMethod.limit.withdraw.min;
    const sumToWithdraw = amount && parseInt(amount) - parseInt(amount) * 0.2;
    const disabledFee = sumToWithdraw && min && Math.floor(sumToWithdraw) >= min;

    const _className = classNames('withdrawal-button-payfix', {
        'withdrawal-button-payfix__active': valid || (!variation && disabledFee),
        'withdrawal-button-payfix__variated': variation && !disabledFee,
    });
    const isDisabled = variation ?  !disabledFee  : !valid

    return (
        <button type="submit" className={_className} disabled={isDisabled}>
            {state === PayfixWithdrawState.AWAIT ? (
                <img className="withdrawal-button-payfix__wait" src={Spinner} alt="spinner" />
            ) : (
                t(buttonTitle)
            )}
        </button>
    );
}

export default WithdrawalButton;
