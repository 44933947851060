import { translate as t } from 'service/Language';

import { formatCurrencySign } from '../../../../../../../../utils/format';
import useObservable from '../../../../../../../../utils/useObservable';
import { PaymentMethod } from '../../../../../PaymentMethod/types';
import AmountFormService from '../../../service/AmountForm';
import Attention from './image/attention.svg';

import './DepositFee.scss';

type DepositFeeProps = {
    paymentMethod: PaymentMethod;
};

function DepositFee({ paymentMethod }: DepositFeeProps) {
    const amountFee = useObservable(AmountFormService.currentAmount, AmountFormService.currentAmount.getValue());
    const showAmount = useObservable(AmountFormService.showAmount, AmountFormService.showAmount.getValue());

    return (
        <div className="deposit-fee">
            <img className="deposit-fee__img" src={Attention} alt="attention" />
            <div className="deposit-fee-block">
                <div className="deposit-fee-block__desc">{t('profile.balance.deposit.attention')}</div>
                {showAmount && (
                    <span className="deposit-fee-block__fee">
                        {t('profile.balance.deposit.attention.amount')}&nbsp;
                        {formatCurrencySign(amountFee, 0, paymentMethod.currency)}
                    </span>
                )}
            </div>
        </div>
    );
}

export default DepositFee;
