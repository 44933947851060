/* eslint-disable max-len */

import { Icon } from 'types';

function MiniSwitchArrowRight({ color = '#3959D9', width = 7, height = 8 }: Icon) {
    return (
        <svg width={width} height={height} viewBox="0 0 7 8" fill="none">
            <path
                d="M6.5 3.13397C7.16667 3.51887 7.16667 4.48113 6.5 4.86603L2 7.4641C1.33333 7.849 0.5 7.36788 0.5 6.59808L0.5 1.40192C0.5 0.632124 1.33333 0.150998 2 0.535898L6.5 3.13397Z"
                fill={color}
            />
        </svg>
    );
}

export default MiniSwitchArrowRight;
