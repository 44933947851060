import Input from 'component/Input/service/Input';

import Validator from '../../../../../../../service/Validator/Validator';

const OldPasswordService = new Input({
    title: '',
    validator: Validator.password,
    validatorText: 'registration.password.error',
});

export default OldPasswordService;
