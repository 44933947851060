import classNames from 'classnames';
import { translate as t } from 'service/Language';
import useObservable from '../../../../../../../utils/useObservable';
import AmountFormService from '../../service/AmountForm';
import Spinner from './image/Spinner.svg';
import WellxPayDepositService, {DepositState} from '../../service/WellxpayDeposit';

import './Submit.scss';


type SubmitProps = {
    className?: string;
};

function SubmitAmount(props: SubmitProps) {
    const { className } = props;
    const valid = useObservable<boolean>(AmountFormService.valid, AmountFormService.valid.value);
    const status = useObservable(WellxPayDepositService.state, WellxPayDepositService.state.getValue());
    const isDisabled = status === DepositState.WAIT_INVOICE || status === DepositState.WAIT

    const _className = classNames({
        [className || '']: !!className,
        'deposit-submit': true,
        'deposit-submit__active': valid,
    });
    return (
        <button type="submit" className={_className} disabled={!valid || isDisabled}>
            {status === DepositState.WAIT_INVOICE || status === DepositState.WAIT ? (
                <img className="deposit-submit__wait" src={Spinner} alt="" />
            ) : (
                t('profile.balance.deposit.button.next')
            )}
        </button>
    );
}

export default SubmitAmount;
