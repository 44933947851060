import Validator from '../interface/Validator';

class PhoneFull implements Validator {
    validate(value: string): boolean {
        // prettier-ignore
        const pattern = new RegExp('^(\\+[8]{2}) ?(\\d{11})$', 'g');

        return pattern.test(value);
    }
}

export default PhoneFull;
