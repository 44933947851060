import useObservable from 'utils/useObservable';
import CoinspaidWithdrawService, {CryptoWithdrawState} from '../../Coinspaid/service/CoinspaidWithdrawal';

import FailedPage from './ResultPage/FailedPage/FailedPage';
import SuccessPage from '../../../../../../component/ResultPage/SuccessPage/SuccessPage';
import BonusFailedPage from '../../../../../../component/BonusFailedPage/BonusFailedPage';

import './ResultForm.scss';


function ResultForm() {
    const state = useObservable(CoinspaidWithdrawService.state, CoinspaidWithdrawService.state.getValue());

    switch (state) {
        case CryptoWithdrawState.ACCEPT: {
            return <SuccessPage />;
        }
        case CryptoWithdrawState.REJECT: {
            return <FailedPage />;
        }
        case CryptoWithdrawState.BONUS_REJECT: {
            return <BonusFailedPage />;
        }
        default: {
            return null;
        }
    }
}

export default ResultForm;
