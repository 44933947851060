import { intervalToDuration } from 'date-fns';

import RxComponent from '../../../component/RxComponent/RxComponent';
import FakeJackpotService from '../../../service/FakeJackpot';

import './Timer.scss';

type TimerProps = {};
type TimerState = {
    now: number;
};

class Timer extends RxComponent<TimerProps, TimerState> {
    state = {
        now: new Date().getTime(),
    };

    private countDownInterval: NodeJS.Timeout | null = null;

    componentDidMount() {
        this.countDownInterval = setInterval(() => {
            const now = new Date().getTime();
            this.setState({ now });
        }, 1000);
    }

    componentWillUnmount() {
        if (this.countDownInterval) {
            clearInterval(this.countDownInterval);
        }
    }

    render() {
        const dueDate = FakeJackpotService.jackpot.getValue().dueDate;

        const { now } = this.state;

        const duration = Object.assign(
            { hours: 0, minutes: 0, seconds: 0 },
            intervalToDuration({
                start: now,
                end: dueDate,
            })
        );

        const time = [
            duration.hours.toString().padStart(2, '0'),
            duration.minutes.toString().padStart(2, '0'),
            duration.seconds.toString().padStart(2, '0'),
        ].join(' : ');

        return (
            <div className="timer">
                <div className="timer__value">{time}</div>
            </div>
        );
    }
}
export default Timer;
