import { BehaviorSubject } from 'rxjs';
import { PaymentMethod } from '../../../../PaymentMethod/types';
import PaymentService from '../../../../../../../service/Payment';
import StatusFormPopupService, { StatusForm } from '../../../DepositPopup/service/StatusForm';
import AmountFormService from './AmountForm';


enum DepositState {
    AMOUNT,
    WAIT,
    LINK,
    FAILED,
    SUCCESS,
}

class Directa24Deposit {
    amount = 0;

    link = new BehaviorSubject('');

    state = new BehaviorSubject<DepositState>(DepositState.AMOUNT);

    request(paymentMethod: PaymentMethod, name: string,  surname: string,  email: string, numberCPF: string, amount: number) {
        this.state.next(DepositState.WAIT);
        PaymentService.directa24.deposit({
            currencyId: paymentMethod.currency.ISO,
            channelType: paymentMethod.id,
            name,
            surname,
            email,
            phone: '',
            numberCPF,
            amount,
        });
    }

    setAmount(amount: number) {
        this.amount = amount;
    }

    getAmount(): number {
        return this.amount;
    }

    reset() {
        this.amount = 0;
        this.link.next('');
        this.state.next(DepositState.AMOUNT);
        AmountFormService.reset();
    }

    onError() {
        this.state.next(DepositState.FAILED);
        StatusFormPopupService.status.next(StatusForm.FAILED);
    }

    onReceive(link: string) {
        const state = this.state.getValue();
        if (state === DepositState.WAIT) {
            const currentArray = link.split('#');
            this.link.next(currentArray[0]);
            window.open(this.link.getValue(), '_self')
            StatusFormPopupService.status.next(StatusForm.SUCCESS);
        }
    }

    back() {
        this.reset();
    }
}

const Directa24DepositService = new Directa24Deposit();

export { DepositState };
export default Directa24DepositService;
