import {useEffect} from 'react';
import {translate as t} from 'service/Language';

import Input from '../../../../../../component/Input/Input';
import EmailFormService from '../service/EmailFormService';
import EmailInputService from './service/NewEmail';

import './NewEmail.scss';

function NewEmailInput() {
    useEffect(() => {
        const newEmail = EmailInputService.value.subscribe(EmailFormService.handler.onNewEmail);

        return () => {
            newEmail.unsubscribe();
        };
    }, []);

    return (
        <div className="new-email-input">
            <Input inputService={EmailInputService} className="new-email-input__field"
                   name="email"
                   type="email"/>
        </div>
    );
}

export default NewEmailInput;
