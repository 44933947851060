import SlotMachine from './SlotMachine/SlotMachine';
import { translate as t } from 'service/Language';
import './WheelSlots.scss';

type WheelSlotsProps = {};

const WheelSlots = (props: WheelSlotsProps) => {
    const {} = props;

    return (
        <div className='WheelSlots'>
            <div className='WheelSlots__stars'></div>
            <div className='WheelSlots__left'></div>
            <div className='WheelSlots__right'></div>
            <div className='WheelSlots__logo'></div>
            <div className='WheelSlots__title'>
                {t('wheel.slots.roll')} <span>{t('wheel.slots.get.bonus')}</span>
            </div>
            <div className='WheelSlots__slots'>
                <SlotMachine />
            </div>
        </div>
    );
};

export default WheelSlots;
