const colors = {
    white: '#FFFFFF',
    santasGray: '#9fa0a8',
    royalBlue: '#3959d9',
    pomegranate: '#f31f1f',
    brightGray: '#3f4250',
    athensGray: '#f3f3f6',
    porcelain: '#ecedef',
    chateauGreen: '#2eba4d',
    yellowOrange: '#fdb43c',
    radicalRed: '#fc4665',
    moonRake: '#c4cdf4',
    alto: '#dadada',
    primary: '#3959D9',
    textPrimary: '#3F4250',
};

export default colors;
