import { translate as t } from '../../service/Language';
import { useIsDesktop } from '../../utils/useBreakpoints';
import ButtonPayment from './ButtonPayment/ButtonPayment';
import { ButtonPayments } from '../../appConstants/ButtonPayments';
import PaykassmaDepositService, {
    DepositState, PaymentType
} from '../../page/Profile/Balance/Deposit/Paykassma/service/PaykassmaDeposit';
import ConstantposDepositService, { DepositState as DepositStateConst  , PaymentType as PaymentTypeConst
    ,  } from '../../page/Profile/Balance/Deposit/Constantpos/service/ConstantposDeposit';

import './PaymentChoice.scss';


type PaymentChoiceProps = {
    link: string;
    serviceType?: string;
};

function PaymentChoice({link, serviceType}: PaymentChoiceProps) {
    const isDesktop = useIsDesktop();
    const isConstantpos = !!serviceType
    const openPaymentApp = (payApp: string) => {
        let url = '';
        switch (payApp) {
            case 'PayTM':
                url = 'paytmmp://';
                isConstantpos ? ConstantposDepositService.paymentType.next(PaymentTypeConst.PAYTM)
                    : PaykassmaDepositService.paymentType.next(PaymentType.PAYTM);
                break;
            case 'PhonePe':
                url = 'phonepe://';
                isConstantpos ? ConstantposDepositService.paymentType.next(PaymentTypeConst.PAYTM)
                    : PaykassmaDepositService.paymentType.next(PaymentType.PAYTM);
                break
            case 'Google Pay':
                url = 'tez://upi/';
                isConstantpos ? ConstantposDepositService.paymentType.next(PaymentTypeConst.PAYTM)
                    : PaykassmaDepositService.paymentType.next(PaymentType.PAYTM);
                break;
        }
        url = link.replace('upi://', url);
        if (isDesktop) {
            isConstantpos ? ConstantposDepositService.link.next(url)
                : PaykassmaDepositService.link.next(url);
            PaykassmaDepositService.link.next(url);
            isConstantpos ? ConstantposDepositService.state.next(DepositStateConst.QR)
                : PaykassmaDepositService.state.next(DepositState.QR)
            ;
        } else {
            window.open(url, '_top');
        }
    };

    return (
        <div className="payment-choice">
            <span className="payment-choice__hint">{t('profile.balance.payment.choice')}</span>
            {ButtonPayments.map((item) =>
                <ButtonPayment
                    key={item.id}
                    title={item.title}
                    logo={item.logo}
                    onClick={() => openPaymentApp(item.title)}
                />
            )}
        </div>
    );
}

export default PaymentChoice;
