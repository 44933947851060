import {translate as t} from '../../../../../../service/Language';
import RxComponent from '../../../../../../component/RxComponent/RxComponent';
import {PaymentMethod} from '../../../PaymentMethod/types';
import CopyImage from 'assets/svg/copy.svg';
import {ChannelTypeCripto, CoinspaidDepositRateResponse} from '@ay_tsarbet/newbet-core/dist/types';
import AmountFormService from '../service/AmountForm';
import CoinspaidDepositService, {DepositState} from '../service/CoinspaidDeposit';
import UserDataService from '../../../../../../service/UserData';
import Attention from '../../Apay/Attention/Attention';
import Notification from '../../../../../../component/Notification/Notification';
import QRCode from 'qrcode.react';

import './Amount.scss';

type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
    data: CoinspaidDepositRateResponse;
    wallet: string;
    tag: string;
};
type AmountState = {};

class Amount extends RxComponent<AmountProps, AmountState> {
    state = {
        status: DepositState.AMOUNT,
    };

    handler = {
        onDeposit: this.onDeposit.bind(this),
        onStatus: this.onStatus.bind(this),
    };

    onDeposit(data: CoinspaidDepositRateResponse | null) {
        this.setState({data});
    }

    onStatus(status: DepositState) {
        this.setState({status})
    }

    onWallet(wallet: string) {
        this.setState({wallet});
    }

    onTag(tag: string) {
        this.setState({tag});
    }

    handleCopy = (value: string) => {
        navigator.clipboard.writeText(value);
        Notification({message: t('profile.balance.deposit.copied')});
    };

    componentDidMount() {
        const {paymentMethod} = this.props;

        AmountFormService.setMethod(paymentMethod);
        this.subscribe(CoinspaidDepositService.state, {next: this.handler.onStatus});
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        AmountFormService.reset();
    }

    render() {
        let { paymentMethod, data, wallet, tag } = this.props;
        const currency = UserDataService.data.getValue()?.currency.name

        const roundDep = (paymentMethod.id === ChannelTypeCripto.BTC || paymentMethod.id === ChannelTypeCripto.ETH || paymentMethod.id === ChannelTypeCripto.BCH || paymentMethod.id === ChannelTypeCripto.XRP) ? data.minDeposit : (data.minDeposit).toFixed(2);

        const minDeposit = data && `${roundDep} ${paymentMethod.title}`;
        const roundDepRate = (data.depositRate).toFixed(2);
        const showDepRate = data && `1 ${paymentMethod.title} / ${roundDepRate} ${currency} `;

        const isClickCrypto = paymentMethod.id === ChannelTypeCripto.BTC || paymentMethod.id === ChannelTypeCripto.ETH;

        let qrData = `${paymentMethod.slug}:${wallet}`;

        const isBranch = wallet.includes(':');
        if (paymentMethod.id === ChannelTypeCripto.BCH && isBranch) {
            const walletBCH = wallet.split(':')
            qrData = walletBCH.join(':')
        }

        return (
            <div className="deposit-crypto">
                <form className="deposit-crypto-form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    <div className="deposit-crypto-dep">
                        <span>{t('popup.payment.crypto.min')}</span>
                        <span>{minDeposit}</span>
                    </div>
                    <div className="deposit-crypto-dep">
                        <span className="deposit-crypto-dep__title">{t('popup.payment.crypto.rate')}</span>
                        <span className="deposit-crypto-dep__value">{showDepRate}</span>
                    </div>
                    <span className="deposit-crypto-dep__title">{t('popup.payment.crypto.input.wallet')}</span>
                    <div className="deposit-crypto-wallet">
                        <span className="deposit-crypto-wallet__wallet">{wallet}</span>
                        <img className="deposit-crypto-wallet__copy" src={CopyImage} alt="copy"
                             onClick={() => this.handleCopy(wallet)}/>
                    </div>
                    {tag ? <>
                        <span className="deposit-crypto-dep__title">{t('popup.payment.crypto.tag')}</span>
                        <div className="deposit-crypto-wallet">
                            <span className="deposit-crypto-wallet__wallet">{tag}</span>
                            <img className="deposit-crypto-wallet__copy" src={CopyImage} alt="copy"
                                 onClick={() => this.handleCopy(tag)}/>
                        </div>
                    </> : null}

                    <div className="deposit-crypto-check">
                        <Attention
                            title={tag ? 'popup.payment.crypto.wallet.tag.note' : 'popup.payment.crypto.wallet.note'}
                            type="wallet" />
                    </div>
                </form>
                <div className="deposit-crypto-block">
                    {isClickCrypto
                        ? <a href={`${paymentMethod.slug}:${wallet}`} target="_blank" rel="noreferrer">
                            <QRCode value={qrData} size={150} level="H" includeMargin={true} />
                        </a>
                        : <QRCode value={qrData} size={150} level="H" includeMargin={true} />
                    }
                </div>
                <span className="deposit-crypto__qr">{t(isClickCrypto ? 'popup.payment.crypto.qr.click' : 'popup.payment.crypto.qr')}</span>
            </div>
        );
    }
}

export default Amount;
