import InputService from 'component/Input/service/Input';
import { FormEvent } from 'react';
import { BehaviorSubject } from 'rxjs';
import PaymentService from 'service/Payment';
import Validator from 'service/Validator/Validator';
import { PaymentMethod } from '../../../../../PaymentMethod/types';
import { OnlyLetterService } from '../../../../../../../../service/Validator/OnlyLetter';
import { PaykassmaWithdrawRequest } from '@ay_tsarbet/newbet-core/dist/connection/actions/sendPaykassmaWithdraw';
import AuthService from '../../../../../../../../service/Auth';
import GoogleService from '../../../../../../../../service/Google';
import PaykassmaWithdrawService, { PaykassmaWithdrawState } from '../../../service/PaykassmaWithdraw';
import WithdrawService from '../../../../service/Withdrawal';
import BalanceService from '../../../../../../../../service/Balance';
import BonusService from '../../../../../../../../service/Bonus';
import StatusFormPopupService, {StatusForm} from '../../../../../Deposit/DepositPopup/service/StatusForm';


export enum JazzcashInputTip {
    NONE,
    AMOUNT,
    ACCOUNT,
    NON_EMPTY,
}

export enum AmountError {
    NONE,
    AMOUNT_MIN,
    AMOUNT_MAX,
    AMOUNT_WITHDRAWAL,
    AMOUNT_FEE,
}

class JazzcashForm {
    paymentMethod: PaymentMethod | null = null;

    limitValidator = new Validator.limit(1, 1000000);

    amount = new InputService({
        title: 'profile.balance.withdraw.input.amount',
        validator: this.limitValidator,
        validatorText: 'profile.balance.withdraw.input.amount',
    });

    accountNumber = new InputService({
        title: 'popup.payment.upay.account',
        validator: Validator.accountUpay,
        validatorText: 'popup.payment.upay.account',
    });

    tip = new BehaviorSubject<JazzcashInputTip>(JazzcashInputTip.NONE);

    amountLimit = new BehaviorSubject<AmountError>(AmountError.NONE);

    valid = new BehaviorSubject<boolean>(false);

    balance = new BehaviorSubject(0);

    handler = {
        onAmount: this.onAmount.bind(this),
        onAmountActive: this.onAmountActive.bind(this),
        onAccountNumber: this.onAccountNumber.bind(this),
        onAccountActive: this.onAccountActive.bind(this),
        onBalance: this.onBalance.bind(this),
        onBank: this.checkValid.bind(this),
    };

    constructor() {
        this.amount.value.subscribe(this.handler.onAmount);
        this.amount.active.subscribe(this.handler.onAmountActive);
        this.accountNumber.value.subscribe(this.handler.onAccountNumber);
        this.accountNumber.active.subscribe(this.handler.onAccountActive);
        BalanceService.balance.subscribe(this.handler.onBalance);
    }

    setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
        const limit = this.paymentMethod.limit.withdraw;
        const isFee = WithdrawService.fee.getValue();
        const isMin = Math.ceil(this.paymentMethod.limit.withdraw.min * 1.25);
        this.limitValidator.setLimit(isFee ? isMin : limit.min, limit.max);
    }

    onAmount(value: string) {
        const isValue = this.amount.value.getValue();
        if (this.paymentMethod && isValue) {
            const fee = WithdrawService.fee.getValue();
            if(fee && +value < this.paymentMethod.limit.withdraw.min * 1.25) {
                this.amountLimit.next(AmountError.AMOUNT_FEE);
                this.amount.error.next('profile.balance.withdraw.input.amount');
            }
            else {
                this.amountLimit.next(AmountError.NONE);
                this.amount.error.next(null);
            }
            if(!fee && +value < this.paymentMethod.limit.withdraw.min) {
                this.amountLimit.next(AmountError.AMOUNT_MIN);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }
            if(+value > this.paymentMethod.limit.withdraw.max) {
                this.amountLimit.next(AmountError.AMOUNT_MAX);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }
            if( this.balance.getValue() < +value) {
                this.amountLimit.next(AmountError.AMOUNT_WITHDRAWAL);
                this.amount.error.next('profile.balance.withdraw.input.amount')
            }

        } else {
            this.amount.error.next(null);
        }
        this.checkValid();
    }

    onBalance(balance: number) {
        this.balance.next(balance)
    }

    onAmountActive(active: boolean) {
        if (active) {
            this.tip.next(JazzcashInputTip.AMOUNT);
        } else {
            this.tip.next(JazzcashInputTip.NONE);
        }
    }

    onAccountNumber(value: string) {
        this.checkValid();
    }

    onAccountActive(active: boolean) {
        if (active) {
            this.tip.next(JazzcashInputTip.ACCOUNT);
        } else {
            this.tip.next(JazzcashInputTip.NONE);
        }
    }

    checkValid() {
        if (this.paymentMethod) {
            const isAmount = this.amount.isValid() && +this.amount.getValue() <= this.balance.getValue();
            const isAccountNumber = this.accountNumber.isValid();

            if (isAmount && isAccountNumber) {
                this.valid.next(true);
            } else this.valid.next(false);
        }else this.valid.next(false)
    }

    send() {
        const valid = this.valid.getValue();

        if (valid && this.paymentMethod) {
            const currencyId = this.paymentMethod.currency.ISO;
            const channelType = this.paymentMethod.id;
            const accountNumber = this.accountNumber.getValue();
            const amount = parseInt(this.amount.getValue());

            const request: PaykassmaWithdrawRequest = {
                currencyId,
                channelType,
                accountNumber,
                accountName: '',
                bankCode: '',
                amount,
            }
            const clientInfo = AuthService.auth.getValue();
            const userId = clientInfo ? clientInfo.userId.toString() : '';
            GoogleService.sentWithdraw(
                userId,
                this.paymentMethod.title,
                amount.toString(),
                this.paymentMethod.currency.name,
                accountNumber
            );
            console.log('send', request);
            PaymentService.paykassma.withdraw(request);
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.AWAIT);
        }
    }

    onSubmit(event: FormEvent) {
        event.preventDefault();

        const status = PaykassmaWithdrawService.state.getValue();
        const fee = WithdrawService.fee.getValue();

        const isBonuses = BonusService.list.getValue().length;
        if (isBonuses) {
            PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.BONUS_REJECT);
            StatusFormPopupService.status.next(StatusForm.BONUS_REJECT);
        } else {
            if (!!fee) {
                if (status === PaykassmaWithdrawState.HAS_FEE) {
                    this.send();
                }
            } else {
                this.send();
            }
        }
    }

    reset() {
        this.amount.value.next('');
        this.accountNumber.value.next('');

        this.amount.error.next(null);
        this.accountNumber.error.next(null);

        this.amount.active.next(false);
        this.accountNumber.active.next(false);

        this.amount.autoCompleted.next(false);
        this.accountNumber.autoCompleted.next(false);

        this.valid.next(false);
        this.tip.next(JazzcashInputTip.NONE);
        this.amountLimit.next(AmountError.NONE);
        PaykassmaWithdrawService.state.next(PaykassmaWithdrawState.INIT);
    }
}

const JazzcashFormService = new JazzcashForm();

export default JazzcashFormService;
