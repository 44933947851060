import Input from '../../../../../../component/Input/service/Input';
import Validator from '../../../../../../service/Validator/Validator';

const RepeatPasswordService = new Input({
    title: '',
    validator: Validator.password,
    validatorText: 'registration.password.error',
});

export default RepeatPasswordService;
