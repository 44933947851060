import { formatCurrencySign } from 'utils/format';
import { translate as t } from '../../../../../../../service/Language';

import Input from '../../../../../../../component/Input/Input';
import MaskedInput from '../../../../../../../component/Input/MaskedInput';
import RxComponent from '../../../../../../../component/RxComponent/RxComponent';
import { PaymentMethod } from '../../../../PaymentMethod/types';

import ErrorIcon from '../../../../../../../assets/svg/attention.svg'

import './Amount.scss';
import AmountFormService, {ConstantposInputTip} from '../../service/AmountForm';
import Attention from '../../../Apay/Attention/Attention';
import Submit from '../../Button/Submit';
import SumSelector from '../../SummSelector/SumSelector';


type AmountProps = {
    lock: boolean;
    paymentMethod: PaymentMethod;
};
type AmountState = {};

class Amount extends RxComponent<AmountProps, AmountState> {
    state = {
        tip: ConstantposInputTip.NONE,
        vpaError: null,
    };

    handler = {
        onTip: this.onTip.bind(this),
        onVPAError: this.onVPAError.bind(this),
    };

    onTip(tip: ConstantposInputTip) {
        this.setState({ tip });
    }
    onVPAError(vpaError: string | null) {
        this.setState({ vpaError });
    }
    componentDidMount() {
        const { paymentMethod } = this.props;

        AmountFormService.reset();
        AmountFormService.setMethod(paymentMethod);
        AmountFormService.setValue(paymentMethod.defaultValue);
        this.subscribe(AmountFormService.tip, { next: this.handler.onTip });
        this.subscribe(AmountFormService.vpa.error, { next: this.handler.onVPAError });
    }

    render() {
        const { paymentMethod, lock } = this.props;
        const { tip, vpaError } = this.state;

        const hint = [
            '(min.',
            formatCurrencySign(paymentMethod.limit.deposit.min, 0, paymentMethod.currency),
            '—',
            'max.',
            formatCurrencySign(paymentMethod.limit.deposit.max, 0, paymentMethod.currency) + ')',
        ].join(' ');

        return (
            <div className="deposit-amount-upiV">
                <form className="deposit-amount-upiV-form" onSubmit={(e) => AmountFormService.onSubmit(e)}>
                    <div className="deposit-amount-upiV-form-block">
                        <MaskedInput
                            mask="00000000000"
                            inputService={AmountFormService.amount}
                            className="deposit-amount-upiV-form-block__input"
                            name="amount"
                            hint={hint}
                            enabled={!lock}
                        />
                        <span className="deposit-amount-upiV-form-block__input_currency">{paymentMethod.currency.sign}</span>
                    </div>
                        <SumSelector paymentMethod={paymentMethod} disabled={lock} />
                    <div className="deposit-amount-upiV-form-block">
                        <Input
                          placeholder="XXXXXX@XXXXXX"
                          inputService={AmountFormService.vpa}
                          className="deposit-amount-upiV-form-block__input"
                          name="vpa"
                          enabled={!lock}
                        />
                        {tip === ConstantposInputTip.VPA && !vpaError && (
                            <span className="deposit-amount-upiV-form-block__input_tip">
                                {t('popup.payment.vpa.hint')}
                            </span>
                        )}
                        {!!vpaError && (
                            <div className="deposit-amount-upiV-form-block__error">
                                <img src={ErrorIcon} alt="attention"/>
                                <span className="deposit-amount-upiV-form-block__error_text">
                                {t('popup.payment.vpa.error')}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="deposit-amount-upiV-check">
                        <Attention title="popup.payment.attention" type="attention" />
                    </div>
                    <div className="deposit-amount-upiV__submit">
                        <Submit />
                    </div>
                </form>
            </div>
        );
    }
}

export default Amount;
