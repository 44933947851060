import { NavLink } from 'react-router-dom';
import { translate as t } from 'service/Language';

import ScrollService from '../../../../component/ScrollTopButton/service/Scroll';
import { PreviewBlockType } from '../Account';

import './PreviewBlock.scss';

type PreviewBlockProps = {
    data: PreviewBlockType;
};

function PreviewBlock({ data }: PreviewBlockProps) {
    return (
        <div className="preview-block">
            <span className="preview-block__header">{t(data.header)}</span>
            <div className="preview-block__data">
                {data.block.map((block) => (
                    <div className="preview-block__row">
                        <img src={block.img} alt="password" />
                        <span className="preview-block__title">{block.title}</span>
                    </div>
                ))}
                <NavLink
                    to={data.path}
                    className="preview-block__btn"
                    onClick={() => ScrollService.scrollToSmoothly(0, 500)}
                >
                    {t('profile.account.block.button')}
                </NavLink>
            </div>
        </div>
    );
}

export default PreviewBlock;
